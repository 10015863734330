import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { BslkRewardComponent } from '@bslk/components/reward/reward.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import {
    DonationCampaignViewModel,
    DonationRequestViewModel,
    EDonationCampaignWhitelistType,
    ETrackedActivityType,
    ProjectViewModel,
} from 'app/api';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { AlertActions } from 'app/store/alert/alert.actions';
import { DonationCampaignActions } from 'app/store/donation/donation-campaign/donation-campaign.action';
import { DonationCampaignSelectors } from 'app/store/donation/donation-campaign/donation-campaign.selectors';
import { TrackedActivityActions } from 'app/store/tracked-activity/tracked-activity.actions';
import { clone, isNil } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';

export type ChooseProjectDialogInput = {
    isPreview: boolean;
};

@Component({
    selector: 'choose-project-dialog',
    templateUrl: './choose-project-dialog.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        LetDirective,
        BaseDialogComponent,
        BslkClassicButtonComponent,
        BslkRewardComponent,
        BslkLengthInfoComponent,
        FuseAlertComponent,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        NgFor,
        NgIf,
        TranslocoModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        ClassicAvatarComponent,
    ],
})
export class ChooseProjectDialogComponent implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    EDonationCampaignWhitelistType = EDonationCampaignWhitelistType;

    project: ProjectViewModel;
    campaign: DonationCampaignViewModel;

    project$ = this.store
        .select(DonationCampaignSelectors.selectChosenProject)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((project) => {
            this.project = project;
        });
    campaign$ = this.store
        .select(DonationCampaignSelectors.selectDonationCampaign)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((campaign) => {
            this.campaign = campaign;
        });

    isLoading$ = this.store.select(
        DonationCampaignSelectors.selectIsLoadingDonationRequest
    );

    error$ = this.store.select(
        DonationCampaignSelectors.selectChosenProjectError
    );

    formGroup = new FormGroup(
        {
            email: new FormControl(null, [
                Validators.email,
                Validators.required,
            ]),
            firstName: new FormControl(null, Validators.required),
            familyName: new FormControl(null, Validators.required),
            comment: new FormControl(null, Validators.maxLength(300)),
            code: new FormControl(null),
        },
        { validators: this.dynamicValidator() }
    );
    submitted = false;

    constructor(
        private dialogRef: MatDialogRef<ChooseProjectDialogComponent>,
        private store: Store,
        @Inject(MAT_DIALOG_DATA) public data: ChooseProjectDialogInput
    ) {}

    ngOnInit(): void {
        if (this.data.isPreview) {
            this.store.dispatch(
                AlertActions.displayInfo({
                    key: 'donation-gift-preview',
                })
            );
        }
    }

    close() {
        this.dialogRef.close();
    }

    dynamicValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const formGroup = control as FormGroup;
            if (
                this.campaign.whitelistType ===
                    EDonationCampaignWhitelistType.Code &&
                isNil(formGroup.controls.code.value)
            ) {
                return { emptyCode: true };
            }
            return null;
        };
    }

    submit() {
        this.submitted = true;
        const donationRequest = clone(
            this.formGroup.value
        ) as DonationRequestViewModel;
        donationRequest.projectId = this.project.id;
        donationRequest.donationCampaignId = this.campaign.id;

        if (this.formGroup.valid) {
            if (this.data.isPreview) {
                this.store.dispatch(
                    DonationCampaignActions.fakeChooseProjectRequest({
                        donationRequest,
                    })
                );
            } else {
                this.store.dispatch(
                    DonationCampaignActions.chooseProjectRequest({
                        donationRequest,
                    })
                );
                this.store.dispatch(
                    TrackedActivityActions.logActivityRequest({
                        activity: {
                            trackedActivityType:
                                ETrackedActivityType.DonationGiftChooseRequest,
                            details: this.project.id.toString(),
                        },
                    })
                );
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
