import { NgFor, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { LOCAL_STORAGE_LANG_KEY } from 'app/core/transloco/transloco.provider';
import { Settings as LuxonSettings } from 'luxon';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor],
})
export class LanguagesComponent implements OnInit {
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
     */
    constructor(
        private _translocoService: TranslocoService,
        private dateAdapter: DateAdapter<Date>
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {
            // Get the active lang
            this.activeLang = activeLang;
            const luxonLocale = this.mapToLuxonLocale(activeLang);
            LuxonSettings.defaultLocale = luxonLocale;

            // Update Angular Material DateAdapter's locale
            this.dateAdapter.setLocale(activeLang);
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            en: 'en',
            fr: 'fr',
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {
        localStorage.setItem(LOCAL_STORAGE_LANG_KEY, lang);
        this._translocoService.setActiveLang(lang);
        this.dateAdapter.setLocale(lang);
        window.location.reload();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    private mapToLuxonLocale(lang: string): string {
        const luxonLocaleMap = {
            en: 'en-US',
            fr: 'fr-FR',
        };

        return luxonLocaleMap[lang] || lang; // Default to the original lang code if no mapping found
    }
}
