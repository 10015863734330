import { TextFieldModule } from '@angular/cdk/text-field';
import {
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    SecurityContext,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EActivityField, ETrackedActivityType } from 'app/api';
import { AssociationRatingComponent } from 'app/shared/components/association/association-rating/association-rating.component';
import { ElementHighlighterComponent } from 'app/shared/components/element-highlighter/element-highlighter.component';
import { EnumTagListComponent } from 'app/shared/components/enum-tag-list/enum-tag-list.component';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { OnboardingProgressComponent } from 'app/shared/components/onboarding-progress/onboarding-progress.component';
import { CoverTemplateComponent } from 'app/shared/components/templates/cover-template/cover-template.component';
import { SafePipe } from 'app/shared/pipes/safe.pipe';
import { UrlHelpersPipe } from 'app/shared/pipes/url-helpers.pipe';
import { ActivityService } from 'app/shared/services/activity/activity.service';
import { GoogleMapsApiService } from 'app/shared/services/api/google-maps-api.service';
import { initializeMapWithLocation } from 'app/shared/utils/google-maps.utils';
import { AssociationActions } from 'app/store/association/association/association.action';
import { AssociationSelectors } from 'app/store/association/association/association.selectors';
import { HighlightSelectors } from 'app/store/highlight/highlight.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, Subscription, map, takeUntil } from 'rxjs';

@Component({
    selector: 'asso-profile-view',
    standalone: true,
    styleUrls: ['./asso-profile-view.component.scss'],
    templateUrl: './asso-profile-view.component.html',
    imports: [
        AssociationRatingComponent,
        AsyncPipe,
        BslkClassicCardComponent,
        BslkTagComponent,
        CoverTemplateComponent,
        ElementHighlighterComponent,
        EnumTagListComponent,
        FuseCardComponent,
        LetDirective,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTooltipModule,
        NgClass,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        NgxSkeletonLoaderModule,
        OnboardingProgressComponent,
        RouterLink,
        SafePipe,
        TextFieldModule,
        TranslocoModule,
        UrlHelpersPipe,
    ],
})
export class AssoProfileViewComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild('googleMaps') mapRef: ElementRef;
    @ViewChild('logoButton', { read: ElementRef }) logoButtonRef: ElementRef;
    @ViewChild('coverButton', { read: ElementRef }) coverButtonRef: ElementRef;

    EActivityField = EActivityField;

    isLoading$ = this.store.select(AssociationSelectors.selectIsLoading);
    name$ = this.store.select(AssociationSelectors.selectName);
    activityFields$ = this.store.select(
        AssociationSelectors.selectActivityFields
    );
    association$ = this.store.select(AssociationSelectors.selectAssociation);
    hasMedia$ = this.store.select(AssociationSelectors.selectHasMedia);
    hasDescription$ = this.store.select(
        AssociationSelectors.selectHasDescription
    );
    hasAddress$ = this.store.select(AssociationSelectors.selectHasAddress);
    logo$ = this.store.select(AssociationSelectors.selectLogo);
    media$ = this.store.select(AssociationSelectors.selectMedia);
    description$ = this.store
        .select(AssociationSelectors.selectDescription)
        .pipe(
            map((description) =>
                this.sanitizer.sanitize(SecurityContext.HTML, description)
            )
        );
    address$ = this.store.select(AssociationSelectors.selectAddress);
    hasLocation$ = this.store.select(AssociationSelectors.selectHasLocation);
    location$ = this.store.select(AssociationSelectors.selectLocation);
    locationShort$ = this.store.select(
        AssociationSelectors.selectLocationShort
    );
    canEdit$ = this.store.select(AssociationSelectors.selectIsMyAssociation);

    onboardingTasks$ = this.store.select(
        AssociationSelectors.selectOnboardingTasks
    );
    isAssoLogoHighlighted$ = this.store.select(
        HighlightSelectors.selectIsElementHighlighted('association-logo')
    );
    isAssoCoverHighlighted$ = this.store.select(
        HighlightSelectors.selectIsElementHighlighted('association-cover')
    );

    showGoogleMaps = false;
    viewInitialized = false;

    private locationSubscription: Subscription;
    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private googleMapsApiService: GoogleMapsApiService,
        private fileUploadDialogService: FileUploadDialogService,
        private activityService: ActivityService
    ) {}

    ngOnInit(): void {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                if (params['id']) {
                    this.store.dispatch(
                        AssociationActions.loadRequest({ id: params['id'] })
                    );
                    this.activityService.sendActivityRequest({
                        trackedActivityType:
                            ETrackedActivityType.ViewAssociationPage,
                        details: params['id'],
                    });
                } else {
                    this.store.dispatch(
                        AssociationActions.loadMineRequest({
                            displayIsLoading: true,
                        })
                    );
                }
            });
    }

    ngAfterViewInit(): void {
        this.viewInitialized = true;
        this.subscribeToLocation();
        this.cdRef.detectChanges();
    }

    private subscribeToLocation(): void {
        this.locationSubscription = this.store
            .select(AssociationSelectors.selectLocation)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((location) => {
                if (
                    this.viewInitialized &&
                    location?.latitude &&
                    location?.longitude
                ) {
                    this.showGoogleMaps = true;
                    this.googleMapsApiService
                        .loadLibrary({
                            core: true,
                            maps: true,
                            marker: true,
                        })
                        .then((libraries) => {
                            if (this.mapRef) {
                                initializeMapWithLocation(
                                    libraries,
                                    this.mapRef,
                                    location
                                );
                            }
                        });
                }
            });
    }

    openUploadLogoDialog(): void {
        this.fileUploadDialogService.open({
            action: AssociationActions.updateLogoRequest,
            isLoading$: this.store.select(
                AssociationSelectors.selectIsLoadingFileUpload
            ),
            maxFileSize: 500 * 1024,
            infoTextKey: 'associationview.info-logo',
        });
    }

    openUploadCoverDialog(): void {
        this.fileUploadDialogService.open({
            action: AssociationActions.updateCoverRequest,
            isLoading$: this.store.select(
                AssociationSelectors.selectIsLoadingFileUpload
            ),
            maxFileSize: 2000 * 1024,
            infoTextKey: 'associationview.info-cover',
        });
    }

    ngOnDestroy(): void {
        if (this.locationSubscription) {
            this.locationSubscription.unsubscribe();
        }
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
