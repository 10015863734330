<div *ngrxLet="isRegular$ as isRegular" class="flex flex-col">
    <div class="mb-3">
        <span *ngIf="isRegular">{{
            "missionmanage.task-description-regular" | transloco
        }}</span>
        <span *ngIf="!isRegular">{{
            "missionmanage.task-description-asso" | transloco
        }}</span>
    </div>

    <div
        [formGroup]="formGroup"
        *ngFor="let formGroup of taskFormGroups; let i = index"
    >
        <mat-form-field floatLabel="always" class="w-full">
            <mat-label
                >{{ "missionmanage.task" | transloco }} {{ i + 1 }}</mat-label
            >
            <input
                matInput
                [placeholder]="'missionmanage.task-placeholder' | transloco"
                [formControlName]="'title'"
            />
            <button
                *ngIf="taskFormGroups.length > 1"
                matSuffix
                mat-icon-button
                (click)="removeTask(formGroup)"
            >
                <mat-icon
                    class="icon-size-5 text-black"
                    [svgIcon]="'heroicons_outline:x-mark'"
                ></mat-icon>
            </button>
            <mat-error>
                {{ "missionmanage.task-error" | transloco }}
            </mat-error>
        </mat-form-field>

        <div
            class="ml-5"
            *ngFor="
                let subtask of formGroup.controls.subtasks.controls;
                let y = index
            "
        >
            <mat-form-field appearance="outline" class="w-full">
                <mat-label
                    >{{ "missionmanage.subtask" | transloco }}
                    {{ y + 1 }}</mat-label
                >
                <input
                    matInput
                    [formControl]="getSubtaskTitleFormControl(subtask)"
                />
                <button
                    *ngIf="hasMoreThanOneSubtask(formGroup)"
                    matSuffix
                    mat-icon-button
                    (click)="removeSubtask(formGroup, y)"
                >
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_outline:x-mark'"
                    ></mat-icon>
                </button>
                <mat-error>
                    {{ "missionmanage.subtask-error" | transloco }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="flex justify-end">
            <button
                (click)="addSubtask(formGroup)"
                class="flex items-center"
                mat-button
            >
                <mat-icon
                    class="icon-size-5 text-default"
                    [svgIcon]="'heroicons_solid:plus'"
                >
                </mat-icon>
                <span class="ml-2 font-semibold">{{
                    "missionmanage.add-subtask" | transloco
                }}</span>
            </button>
        </div>
    </div>
    <div class="flex">
        <button (click)="addTask()" class="flex items-center" mat-button>
            <mat-icon
                class="icon-size-5 text-default"
                [svgIcon]="'heroicons_solid:plus'"
            >
            </mat-icon>
            <span class="ml-2 font-semibold">{{
                "missionmanage.add-task" | transloco
            }}</span>
        </button>
    </div>

    <div class="w-full flex justify-end">
        <button
            color="accent"
            [disabled]="!canSave"
            (click)="save()"
            mat-flat-button
            class="mt-3 text-white"
        >
            <span>{{ "missionmanage.save" | transloco }}</span>
        </button>
    </div>
</div>
