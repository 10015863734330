import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, takeUntil } from 'rxjs';

@Injectable()
export class TranslocoMatPaginatoIntl extends MatPaginatorIntl implements OnDestroy {

    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private translocoService: TranslocoService) {
        super();

        this.translocoService.langChanges$
            .pipe(
                takeUntil(this.unsubscribeAll)
            )
            .subscribe(() => {
                this.translateLabels();
        });
    }

    translateLabels() {
        this.translocoService.selectTranslateObject('paginator', {}, 'shared')
            .pipe(
                takeUntil(this.unsubscribeAll)
            )
            .subscribe(translations => {
                this.itemsPerPageLabel = translations['itemsPerPage'];
                this.nextPageLabel = translations['nextPage'];
                this.previousPageLabel = translations['previousPage'];
                this.firstPageLabel = translations['firstPage'];
                this.lastPageLabel = translations['lastPage'];
                this.getRangeLabel = this.getTranslatedRangeLabel.bind(this);
        });

        this.changes.next();
    }

    getTranslatedRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0 || pageSize === 0) { return '' }
    
        length = Math.max(length, 0);
    
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
    
        return this.translocoService.translate('shared.paginator.rangeLabel', {
            startIndex: startIndex + 1,
            endIndex,
            length
        });
      }
    

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
