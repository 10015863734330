<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
    <ng-container *ngIf="(unreadCount$ | async) > 0">
        <span
            class="absolute top-0 right-0 left-0 flex items-center justify-center h-3"
        >
            <span
                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-primary text-indigo-50 text-xs font-medium"
            >
                {{ unreadCount$ | async }}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>
    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg"
    >
        <!-- Header -->
        <div
            class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary"
        >
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:x-mark'"
                    ></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10 text-white">
                {{ "notifications.title" | transloco }}
            </div>
        </div>

        <!-- Content -->
        <div
            class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card"
        >
            <!-- Notifications -->
            <ng-container
                *ngFor="let userNotification of userNotifications$ | async"
            >
                <div
                    class="flex group hover:bg-gray-50"
                    [class.bg-accent]="!userNotification.isAcknowledged"
                >
                    <!-- Notification with a link -->
                    <ng-container *ngIf="userNotification.path">
                        <a
                            class="flex flex-auto py-5 pl-6 cursor-pointer"
                            [routerLink]="userNotification.path"
                        >
                            <ng-container
                                *ngTemplateOutlet="notificationContent"
                            ></ng-container>
                        </a>
                    </ng-container>

                    <!-- Notification without a link -->
                    <ng-container *ngIf="!userNotification.path">
                        <div class="flex flex-auto py-5 pl-6">
                            <ng-container
                                *ngTemplateOutlet="notificationContent"
                            ></ng-container>
                        </div>
                    </ng-container>

                    <!-- Actions -->
                    <div class="relative flex flex-col my-5 mr-6 ml-2">
                        <!-- Remove -->
                        <button
                            class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100"
                            mat-icon-button
                            (click)="delete(userNotification)"
                            [matTooltip]="'notifications.remove' | transloco"
                        >
                            <mat-icon
                                class="icon-size-4"
                                [svgIcon]="'heroicons_solid:x-mark'"
                            ></mat-icon>
                        </button>
                    </div>
                </div>

                <!-- Notification content template -->
                <ng-template #notificationContent>
                    <!-- Icon -->
                    <ng-container
                        *ngIf="!userNotification.notification.iconUrl"
                    >
                        <div
                            class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700"
                        >
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="userNotification.icon"
                            >
                            </mat-icon>
                        </div>
                    </ng-container>
                    <!-- Image -->
                    <ng-container *ngIf="userNotification.notification.iconUrl">
                        <img
                            class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
                            [src]="userNotification.notification.iconUrl"
                            [alt]="'Notification image'"
                        />
                    </ng-container>
                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto">
                        <div
                            class="font-semibold line-clamp-1"
                            innerHTML="{{
                                'notifications.list.' +
                                    userNotification.notification.type +
                                    '.title'
                                    | transloco : userNotification.variables
                            }}"
                        ></div>
                        <div
                            innerHTML="{{
                                'notifications.list.' +
                                    userNotification.notification.type +
                                    '.description'
                                    | transloco : userNotification.variables
                            }}"
                        ></div>
                        <div class="mt-2 text-sm leading-none text-secondary">
                            {{
                                userNotification.notification.dateCreated
                                    | date : "MMM dd, h:mm a"
                            }}
                        </div>
                    </div>
                </ng-template>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="!(hasNotification$ | async)">
                <div
                    class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8"
                >
                    <div
                        class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600"
                    >
                        <mat-icon
                            class="text-primary-700 dark:text-primary-50"
                            [svgIcon]="'heroicons_outline:bell'"
                        ></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">
                        {{ "notifications.no-notification-title" | transloco }}
                    </div>
                    <div
                        class="w-full max-w-60 mt-1 text-md text-center text-secondary"
                    >
                        {{
                            "notifications.no-notification-description"
                                | transloco
                        }}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>
