<div class="w-full flex justify-center">
    <div class="flex items-start space-x-4 relative">
        <div class="flex flex-col">
            <div class="flex flex-col">
                <!-- Speech Bubble -->
                <div
                    class="relative translate-y-18 translate-x-20 bg-white p-4 rounded-xl max-w-xs min-w-48 shadow-lg"
                >
                    <p
                        class="font-semibold font-italic text-center text-black text-2xl"
                    >
                        {{
                            "coursesection.basile-loading." + randomNumber
                                | transloco
                        }}
                    </p>
                    <div
                        class="w-0 h-0 border-8 border-transparent border-b-white absolute -bottom-4 transform left-1/4 -translate-x-1/2 rotate-180"
                    ></div>
                </div>
            </div>
            <img
                class="w-72 min-h-40 translate-x-20"
                [src]="'assets/basile-postures/reading.png'"
            />
            <div class="bounce-spinner mx-auto mt-0">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <span class="my-6 text-primary text-lg font-bold text-center">{{
                "coursesection.loading." + randomNumber | transloco
            }}</span>
        </div>
    </div>
</div>
