<div
    (click)="toggleIsExpanded()"
    class="cursor-pointer flex w-full justify-between"
>
    <span class="font-bold">{{ "missions.filters.dates" | transloco }} </span>
    <div>
        <mat-icon
            *ngIf="!isExpanded"
            class="font-bold icon-size-5 mr-1"
            [svgIcon]="'heroicons_solid:chevron-down'"
        >
        </mat-icon>
        <mat-icon
            *ngIf="isExpanded"
            class="font-bold icon-size-5 mr-1"
            [svgIcon]="'heroicons_solid:chevron-up'"
        >
        </mat-icon>
    </div>
</div>
<div *ngIf="isExpanded" class="flex flex-col w-full mt-2">
    <mat-form-field
        [subscriptSizing]="'dynamic'"
        (click)="pickerStart.open()"
        class="w-full"
    >
        <mat-label class="font-normal">{{
            "missions.filters.minimum-start-date" | transloco
        }}</mat-label>
        <input
            readonly
            [(ngModel)]="startDate"
            (ngModelChange)="onDatesChanged('start')"
            matInput
            [matDatepicker]="pickerStart"
        />
        <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerStart"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
    </mat-form-field>

    <mat-form-field
        [subscriptSizing]="'dynamic'"
        (click)="pickerEnd.open()"
        class="w-full mt-4"
    >
        <mat-label class="font-normal">{{
            "missions.filters.maximum-end-date" | transloco
        }}</mat-label>
        <input
            readonly
            [(ngModel)]="endDate"
            (ngModelChange)="onDatesChanged('end')"
            matInput
            [matDatepicker]="pickerEnd"
        />
        <mat-datepicker-toggle
            matIconSuffix
            [for]="pickerEnd"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
    </mat-form-field>

    <div *ngIf="!datesValid" class="text-sm text-primary mt-1">
        {{ "missions.filters.dates-error" | transloco }}
    </div>
</div>
