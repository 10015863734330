import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { CourseSectionViewModel } from 'app/api';
import { randomNumberFrom1To } from 'app/shared/utils/number.utils';
import { CourseActions } from 'app/store/course/course/course.action';
import { CourseSelectors } from 'app/store/course/course/course.selectors';
import { isNil } from 'lodash-es';
import { NextChapterSectionButtonComponent } from '../../next-button/next-button.component';

@Component({
    selector: 'true-false-question-component',
    templateUrl: './true-false.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        NgIf,
        MatIconModule,
        NextChapterSectionButtonComponent,
        LetDirective,
    ],
})
export class SectionTrueFalseQuestionComponent implements OnChanges {
    @Input() section: CourseSectionViewModel;

    isLastSection$ = this.store.select(
        CourseSelectors.selectIsActiveChapterSectionLastOfChapter
    );

    randomNum: number = randomNumberFrom1To(10);
    isCorrect: boolean | undefined;

    get hasAnswered() {
        return !isNil(this.isCorrect);
    }

    constructor(private store: Store) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.section) {
            this.isCorrect = this.section.trueFalseQuestion.isUserCorrect;
        }
    }

    nextSection(isLastSection: boolean) {
        this.store.dispatch(CourseActions.nextSection({ isLastSection }));
    }

    onAnswer(answer: boolean) {
        this.isCorrect =
            answer === this.section.trueFalseQuestion.correctAnswer;
        this.store.dispatch(
            CourseActions.sendAnswerRequest({
                sectionId: this.section.id,
                isCorrect: this.isCorrect,
            })
        );
    }
}
