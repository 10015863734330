import { createReducer, on } from '@ngrx/store';
import { updateLocalStorageCompanyAdminDashboardConfig } from 'app/shared/utils/local-storage.utils';
import { produce } from 'immer';
import { merge } from 'lodash-es';
import { CompanyAdminDashboardActions } from './company-admin-dashboard.actions';
import { CompanyAdminDashboardState } from './company-admin-dashboard.state';

export const companyAdminDashboardInitialState: CompanyAdminDashboardState = {
    activeTab: 'overview',
    isLoading: {
        'participation-rate': false,
        'satisfaction-rate': false,
        'gender-participation-repartition': false,
        'tracking-my-impact': false,
        'employees-on-assignment': false,
        'missions-by-goals': false,
        'all-company-missions': false,
        'awareness-progression': false,
        'awareness-rate': false,
        'dual-quiz-answers-by-sdg': false,
    },
    configuration: {
        overview: {
            'participation-rate': true,
            'satisfaction-rate': true,
            'gender-participation-repartition': true,
            'tracking-my-impact': true,
            'employees-on-assignment': true,
            'missions-by-goals': true,
            'all-company-missions': false,
            'awareness-rate': true,
            'awareness-progression': false,
            'dual-quiz-answers-by-sdg': false,
        },
        employee: {
            'participation-rate': true,
            'satisfaction-rate': true,
            'gender-participation-repartition': true,
            'employees-on-assignment': true,
            'awareness-progression': true,
            'awareness-rate': true,
            'dual-quiz-answers-by-sdg': true,
        },
        missions: {
            'tracking-my-impact': true,
            'missions-by-goals': true,
            'all-company-missions': true,
        },
        accounting: {},
    },
    kpiFilters: {
        'participation-rate': {},
        'missions-by-goals': {},
        'employees-on-assignment': {},
        'satisfaction-rate': {},
        'tracking-my-impact': {},
        'gender-participation-repartition': {},
        'all-company-missions': {},
        'awareness-rate': {},
        'awareness-progression': {},
        'dual-quiz-answers-by-sdg': {},
    },
    kpiResults: {},
    isLoadingExport: false,
};

export const companyAdminDashboardReducer = createReducer(
    companyAdminDashboardInitialState,

    on(CompanyAdminDashboardActions.initDashboard, (state) =>
        produce(state, (draftState) => {
            draftState.expandedKpi = null;
        })
    ),
    on(CompanyAdminDashboardActions.loadKpiRequest, (state, { kpi }) =>
        produce(state, (draftState) => {
            draftState.isLoading[kpi] = true;
        })
    ),
    on(
        CompanyAdminDashboardActions.loadKpiRequestSuccess,
        (state, { kpi, response }) =>
            produce(state, (draftState) => {
                draftState.isLoading[kpi] = false;
                draftState.kpiResults[kpi] = response;
            })
    ),
    on(CompanyAdminDashboardActions.loadKpiRequestFail, (state, { kpi }) =>
        produce(state, (draftState) => {
            draftState.isLoading[kpi] = false;
        })
    ),
    on(CompanyAdminDashboardActions.switchTab, (state, { tab }) =>
        produce(state, (draftState) => {
            draftState.activeTab = tab;
            draftState.expandedKpi = null;
        })
    ),
    on(CompanyAdminDashboardActions.expandKpi, (state, { kpi }) =>
        produce(state, (draftState) => {
            draftState.expandedKpi = kpi;
        })
    ),
    on(CompanyAdminDashboardActions.minimizeKpi, (state) =>
        produce(state, (draftState) => {
            draftState.expandedKpi = null;
        })
    ),
    on(CompanyAdminDashboardActions.updateConfiguration, (state, { config }) =>
        produce(state, (draftState) => {
            draftState.configuration = config;
        })
    ),
    on(
        CompanyAdminDashboardActions.updateKpiFilters,
        (state, { kpi, filters }) =>
            produce(state, (draftState) => {
                let newFilters = merge({}, state.kpiFilters[kpi], filters);
                draftState.kpiFilters[kpi] = newFilters;
            })
    ),
    on(
        CompanyAdminDashboardActions.configureKpiDisplay,
        (state, { kpi, tab, isDisplayed }) => {
            const configuration = structuredClone(state.configuration);
            configuration[tab][kpi] = isDisplayed;
            updateLocalStorageCompanyAdminDashboardConfig(configuration);
            return {
                ...state,
                configuration,
            };
        }
    ),
    on(CompanyAdminDashboardActions.exportDataRequest, (state) =>
        produce(state, (draftState) => {
            draftState.isLoadingExport = true;
        })
    ),

    on(CompanyAdminDashboardActions.exportDataRequestSuccess, (state) =>
        produce(state, (draftState) => {
            draftState.isLoadingExport = false;
        })
    ),

    on(CompanyAdminDashboardActions.exportDataRequestFail, (state) =>
        produce(state, (draftState) => {
            draftState.isLoadingExport = false;
        })
    )
);
