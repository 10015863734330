import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DemonstrationStep } from 'app/api';

export const DemonstrationActions = createActionGroup({
    source: 'Demonstration',
    events: {
        'Load Steps Request': emptyProps(),
        'Load Steps Request Success': props<{ steps: DemonstrationStep[] }>(),
        'Load Steps Request Fail': props<{ error: string }>(),

        'Execute Step Request': props<{ step: number }>(),
        'Execute Step Request Success': props<{ step: number }>(),
        'Execute Step Request Fail': props<{ error: string }>(),
    },
});
