import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { AssociationListActions } from './associations.actions';
import { AssociationListState } from './associations.state';

export const initialState: AssociationListState = ressourceListInitialState;

export const associationListReducer = createRessourceListReducer(
    ressourceListInitialState,
    AssociationListActions
);
