<div
    [class]="
        'w-' +
        size +
        ' h-' +
        size +
        ' rounded-full border-' +
        borderSize +
        ' relative  ' +
        borderColor
    "
>
    <img
        *ngIf="avatarUrl"
        [class]="
            'h-full w-full object-cover ring-tertiary bg-accent rounded-full ring-' +
            ringSize +
            ' '
        "
        [src]="avatarUrl"
        alt="Responsible user avatar avatar"
    />
    <div
        *ngIf="!avatarUrl"
        [class]="
            'h-full w-full rounded-full ring-' +
            ringSize +
            ' relative ' +
            backgroundColor +
            ' ' +
            ringColor
        "
    >
        <span
            [class]="
                'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-' +
                textSize +
                'xl'
            "
        >
            {{ fallbackLetterUppercase }}
        </span>
    </div>

    <ng-content></ng-content>
</div>
