<div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
    <ng-container *ngIf="!isLoading; else skeletons">
        <mission-grid-card *ngFor="let mission of missions" [mission]="mission">
        </mission-grid-card>
    </ng-container>
</div>

<ng-template #skeletons>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-96"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-96"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-96"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-96"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-96"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-96"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-96"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-96"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-96"
    ></ngx-skeleton-loader>
</ng-template>
