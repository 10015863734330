<base-dialog
    (onClose)="close()"
    [title]="'companyadmindashboard.export.title' | transloco"
    [showActions]="true"
    [hideBodyPadding]="true"
>
    <div class="w-full h-full flex flex-col justify-center py-3 px-6" body>
        <form
            [formGroup]="exportConfigurationFormGroup"
            class="flex flex-col gap-2"
        >
            <!-- Uncomment when API xlsx handling will be fixed -->
            <bslk-classic-toggle
                [label]="'companyadmindashboard.export.export-type' | transloco"
                [isLabelBold]="true"
                [control]="exportConfigurationFormGroup.controls.exportType"
                [color]="'warn'"
                [size]="'small'"
                [choices]="[
                    {
                        text: '.csv',
                        value: EExportType.Csv
                    },
                    {
                        text: '.xlsx',
                        value: EExportType.Xlsx
                    }
                ]"
            ></bslk-classic-toggle>

            <mat-checkbox [formControlName]="'exportMissions'" color="primary">
                <span class="font-semibold">{{
                    "companyadmindashboard.export.export-missions" | transloco
                }}</span>
            </mat-checkbox>
            <mat-checkbox
                [formControlName]="'exportMissionFeedbacks'"
                color="primary"
            >
                <span class="font-semibold">{{
                    "companyadmindashboard.export.export-mission-feedbacks"
                        | transloco
                }}</span>
            </mat-checkbox>
            <mat-checkbox
                [formControlName]="'exportAwarenessCompletion'"
                color="primary"
            >
                <span class="font-semibold">{{
                    "companyadmindashboard.export.export-awareness-completion"
                        | transloco
                }}</span>
            </mat-checkbox>
            <mat-checkbox
                [formControlName]="'exportDonationCampaigns'"
                color="primary"
            >
                <span class="font-semibold">{{
                    "companyadmindashboard.export.export-donation-campaigns"
                        | transloco
                }}</span>
            </mat-checkbox>
            <mat-checkbox
                [formControlName]="'exportDonationCampaignParticipations'"
                color="primary"
            >
                <span class="font-semibold">{{
                    "companyadmindashboard.export.export-donation-campaign-participations"
                        | transloco
                }}</span>
            </mat-checkbox>
        </form>
    </div>

    <div class="flex gap-4 justify-end" actions>
        <bslk-classic-button
            [isLoading]="isLoading$ | async"
            (onClick)="export()"
            [icon]="'heroicons_outline:arrow-down-tray'"
            >{{ "shared.common.download" | transloco }}</bslk-classic-button
        >
    </div>
</base-dialog>
