import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CourseSectionCommentViewModel } from 'app/api';
import { BasileAvatarComponent } from 'app/shared/components/avatar/basile/basile-avatar.component';

@Component({
    selector: 'course-section-comment',
    templateUrl: './comment.component.html',
    standalone: true,
    imports: [DatePipe, BasileAvatarComponent],
})
export class CourseSectionCommentComponent {
    @Input() comment: CourseSectionCommentViewModel;
    @Input() isPreview = false;
}
