import { createReducer, on } from '@ngrx/store';
import { isEmpty } from 'lodash-es';
import { AwarenessCampaignActions } from './awareness-campaign.action';
import { AwarenessCampaignState } from './awareness-campaign.state';

export const awarenessCampaignInitialState: AwarenessCampaignState = {
    isLoading: false,
    isLoadingThematicDetails: false,
    isLoadingActiveCampaign: false,
};

export const awarenessCampaignReducer = createReducer(
    awarenessCampaignInitialState,

    on(AwarenessCampaignActions.loadCurrentCampaignProgressRequest, (state) => {
        return {
            ...state,
            isLoading: isEmpty(state.thematicsProgress),
        };
    }),
    on(
        AwarenessCampaignActions.loadCurrentCampaignProgressRequestSuccess,
        (state, { thematicsProgress }) => {
            return {
                ...state,
                thematicsProgress,
                isLoading: false,
            };
        }
    ),
    on(
        AwarenessCampaignActions.loadCurrentCampaignProgressRequestFail,
        (state) => {
            return {
                ...state,
                isLoading: false,
            };
        }
    ),

    on(AwarenessCampaignActions.loadThematicDetailsRequest, (state) => {
        return {
            ...state,
            isLoadingThematicDetails: true,
        };
    }),
    on(
        AwarenessCampaignActions.loadThematicDetailsRequestSuccess,
        (state, { thematicDetails }) => {
            return {
                ...state,
                thematicDetails,
                isLoadingThematicDetails: false,
            };
        }
    ),
    on(AwarenessCampaignActions.loadThematicDetailsRequestFail, (state) => {
        return {
            ...state,
            isLoadingThematicDetails: false,
        };
    }),

    on(AwarenessCampaignActions.loadActiveCampaignRequest, (state) => {
        return {
            ...state,
            isLoadingActiveCampaign: true,
        };
    }),
    on(
        AwarenessCampaignActions.loadActiveCampaignRequestSuccess,
        (state, { awarenessCampaign }) => {
            return {
                ...state,
                activeCampaign: awarenessCampaign,
                isLoadingActiveCampaign: false,
            };
        }
    ),
    on(AwarenessCampaignActions.loadActiveCampaignRequestFail, (state) => {
        return {
            ...state,
            isLoadingActiveCampaign: false,
        };
    })
);
