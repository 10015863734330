<div
    #userRatingsOrigin
    (click)="openPanel()"
    class="flex cursor-pointer hover:scale-[1.03] duration-300"
>
    <div
        class="flex m-auto bg-white rounded-full bg-opacity-50 w-16 h-16 shadow"
    >
        <mat-icon
            class="text-tertiary m-auto w-10 h-10"
            [svgIcon]="'heroicons_outline:chat-bubble-bottom-center'"
        ></mat-icon>
    </div>
    <div class="flex flex-col justify-center pl-4">
        <span>{{ "regulardashboard.comments" | transloco }}</span>
        <span class="font-bold text-lg">{{ ratingsCount }}</span>
    </div>
</div>

<!-- User ratings panel -->
<ng-template #userRatingsPanel>
    <div
        *ngrxLet="isLoading$ as isLoading"
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-160 sm:w-160 sm:rounded-2xl overflow-hidden shadow-lg"
    >
        <!-- Header -->
        <div
            class="flex shrink-0 items-center justify-between py-4 pr-4 pl-6 bg-tertiary"
        >
            <div class="text-lg font-medium leading-10 text-white">
                {{ "regulardashboard.comments" | transloco }}
            </div>
            <button mat-icon-button (click)="closePanel()">
                <mat-icon
                    class="icon-size-5 text-white"
                    [svgIcon]="'heroicons_solid:x-mark'"
                ></mat-icon>
            </button>
        </div>

        <!-- Content -->
        <div
            class="relative flex flex-col flex-auto sm:max-h-120 overflow-y-auto bg-card"
        >
            <!-- Ratings -->
            <ng-container *ngIf="!isLoading; else loading">
                <rating-report
                    *ngFor="let rating of ratings$ | async"
                    [rating]="rating"
                ></rating-report>
            </ng-container>

            <!-- No ratings -->
            <ng-container *ngIf="!isLoading && (totalCount$ | async) == 0">
                <div
                    class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8"
                >
                    <div
                        class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-tertiary-100 dark:bg-tertiary-600"
                    >
                        <mat-icon
                            class="text-tertiary"
                            [svgIcon]="
                                'heroicons_outline:chat-bubble-bottom-center'
                            "
                        ></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">
                        {{ "regulardashboard.no-rating-title" | transloco }}
                    </div>
                    <div
                        class="w-full max-w-90 mt-1 text-md text-center text-secondary"
                    >
                        {{
                            "regulardashboard.no-rating-description" | transloco
                        }}
                    </div>
                </div>
            </ng-container>
        </div>

        <mat-paginator
            footer
            class="bg-tertiary-100"
            [ngClass]="{
                'pointer-events-none': isLoading
            }"
            [length]="totalCount$ | async"
            [pageIndex]="pageIndex$ | async"
            [pageSize]="pageSize$ | async"
            [hidePageSize]="true"
            (page)="pageChangeEvent($event)"
            [showFirstLastButtons]="false"
        ></mat-paginator>
    </div>
</ng-template>

<ng-template #loading>
    <div class="flex flex-col p-3 bg-gray-50 border justify-center">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-30 lg:h-40 xxl:h-50 mb-4"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader count="16" class="w-full"></ngx-skeleton-loader>
    </div>
</ng-template>
