import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BslkBadgeComponent } from '@bslk-business/components/badge/badge.component';
import { BslkRewardComponent } from '@bslk/components/reward/reward.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { EReward } from 'app/api';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { RewardValues, getRewardValues } from 'app/shared/utils/rewards.utils';

@Component({
    selector: 'trophy-chapter-dialog',
    standalone: true,
    templateUrl: './trophy-chapter-dialog.component.html',
    imports: [
        BaseDialogComponent,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        TranslocoModule,
        BslkRewardComponent,
        FuseAlertComponent,
        BslkBadgeComponent,
        NgIf,
    ],
})
export class TrophyChapterDialogComponent {
    reward: RewardValues = getRewardValues(
        EReward.CourseTrophyChapterCompleted
    );

    constructor(
        private dialogRef: MatDialogRef<TrophyChapterDialogComponent>
    ) {}

    close() {
        this.dialogRef.close();
    }
}
