<base-dialog
    [title]="'missionmanage.hours-estimations-validation' | transloco"
    (onClose)="close()"
    [showActions]="true"
>
    <div class="flex flex-col" body>
        <fuse-alert class="max-w-lg" [appearance]="'outline'" type="info" body>
            <span *ngIf="data.isAdmin" class="text-lg">{{
                "missionmanage.estimation-confirm-asso" | transloco
            }}</span>
            <span *ngIf="!data.isAdmin" class="text-lg">{{
                "missionmanage.estimation-confirm-regular" | transloco
            }}</span>
        </fuse-alert>

        <mat-form-field class="w-full mt-5 m-auto max-w-60">
            <mat-label>{{
                "missionmanage.hours-estimations" | transloco
            }}</mat-label>
            <div class="mb-1" matSuffix>
                {{ "missionmanage.hours" | transloco }}
            </div>
            <input [(ngModel)]="estimatedTimeInHours" matInput type="number" />
        </mat-form-field>
    </div>

    <div class="flex justify-end" actions>
        <button
            (click)="close()"
            mat-flat-button
            class="bg-white border border-black mr-2 text-black"
        >
            <span class="ml-1">{{ "missionmanage.cancel" | transloco }}</span>
        </button>
        <button (click)="confirm()" mat-flat-button class="bg-warn text-white">
            <span class="ml-1">{{ "missionmanage.confirm" | transloco }}</span>
        </button>
    </div>
</base-dialog>
