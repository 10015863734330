<!-- Sign up with external auth -->
<div class="external-auth flex flex-col mx-auto gap-4">
    <div *ngIf="enableGoogleSso" class="external-auth__google">
        <asl-google-signin-button
            [width]="328"
            class="external-auth__google"
            type="standard"
            size="large"
            shape="rectangular"
            text="signup_with"
            theme="filled_white"
        >
        </asl-google-signin-button>
    </div>

    <button
        (click)="signUpWithMicrosoft()"
        class="external-auth__microsoft bg-white border-gray-300"
        color="primary"
    >
        <img
            class="external-auth__microsoft--logo"
            src="/assets/icons/microsoft.svg"
        />
        <span class="external-auth__microsoft--text">{{
            "login.microsoft-signup" | transloco
        }}</span>
    </button>

    <button
        (click)="signUpWithLinkedIn()"
        class="external-auth__linkedin text-[#0e76a8] bg-white transition-all border border-gray-300 flex items-center justify-center gap-2"
    >
        <img
            class="external-auth__linkedin--logo h-6"
            src="/assets/icons/linkedin.png"
        />
        {{ "login.linkedin-signup" | transloco }}
    </button>
</div>

<!-- Separator -->
<div class="flex items-center mt-4">
    <div class="flex-auto mt-px border-t"></div>
    <div class="mx-4">
        {{ "signup.or" | transloco }}
    </div>
    <div class="flex-auto mt-px border-t"></div>
</div>

<!-- Classic account sign up form -->
<form [formGroup]="signUpFormGroup" (ngSubmit)="signUp()" class="mt-4">
    <!-- First name and family name -->
    <div class="w-full">
        <mat-form-field class="pr-2 w-1/2">
            <mat-label>{{ "signup.first-name" | transloco }}</mat-label>
            <input
                [placeholder]="'signup.first-name-placeholder' | transloco"
                autocomplete="given-name"
                matInput
                [formControlName]="'firstName'"
            />
            <mat-error>
                {{ "signup.first-name-required" | transloco }}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="pl-2 w-1/2">
            <mat-label>{{ "signup.family-name" | transloco }}</mat-label>
            <input
                [placeholder]="'signup.family-name-placeholder' | transloco"
                autocomplete="family-name"
                matInput
                [formControlName]="'familyName'"
            />
            <mat-error>
                {{ "signup.family-name-required" | transloco }}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- Email field -->
    <mat-form-field class="w-full">
        <mat-label>{{ "signup.email" | transloco }}</mat-label>
        <input
            autocomplete="email"
            [placeholder]="'signup.email-placeholder' | transloco"
            matInput
            [formControlName]="'email'"
        />
        <mat-error>
            {{ "signup.email-required" | transloco }}
        </mat-error>
    </mat-form-field>

    <!-- Password field -->
    <mat-form-field class="w-full">
        <mat-label>{{ "signup.password" | transloco }}</mat-label>
        <input
            [placeholder]="'signup.password-placeholder' | transloco"
            autocomplete="new-password"
            matInput
            type="password"
            [formControlName]="'password'"
            #passwordField
        />
        <button
            mat-icon-button
            type="button"
            (click)="
                passwordField.type === 'password'
                    ? (passwordField.type = 'text')
                    : (passwordField.type = 'password')
            "
            matSuffix
        >
            <mat-icon
                class="icon-size-5"
                *ngIf="passwordField.type === 'password'"
                [svgIcon]="'heroicons_solid:eye'"
            ></mat-icon>
            <mat-icon
                class="icon-size-5"
                *ngIf="passwordField.type === 'text'"
                [svgIcon]="'heroicons_solid:eye-slash'"
            ></mat-icon>
        </button>
        <mat-error>
            {{ "signup.password-error" | transloco }}
        </mat-error>
    </mat-form-field>

    <div *ngrxLet="signUpError$ as signUpError">
        <fuse-alert
            class="mb-4 flex justify-center"
            *ngIf="signUpError"
            [appearance]="'outline'"
            [showIcon]="true"
            [type]="'error'"
            [@shake]="true"
        >
            {{ "signup.errors." + signUpError | transloco }}
        </fuse-alert>
    </div>
    <ng-content></ng-content>
</form>
