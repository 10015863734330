import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MissionRatingService } from 'app/api';
import {
    createLoadListRequestEffect,
    createReloadEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { MissionRatingListActions } from './mission-ratings.actions';
import { MissionRatingListSelectors } from './mission-ratings.selectors';

@Injectable()
export class MissionRatingListEffects {
    loadMissions$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.missionRatingService,
        MissionRatingListActions,
        MissionRatingListSelectors,
        this.missionRatingService.missionRatingUserGet
    );

    reload$ = createReloadEffect(this.actions$, MissionRatingListActions);

    constructor(
        private actions$: Actions,
        private store: Store,
        private missionRatingService: MissionRatingService
    ) {}
}
