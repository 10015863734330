<div
    *ngrxLet="levelInformation$ as levelInformation"
    class="grow flex flex-col gap-2"
>
    <div class="text-lg font-bold">
        {{ levelInformation.pointsInCurrentLevel }}
        {{ "shared.experience.points" | transloco }}
    </div>
    <div class="bg-gray-200 shadow-inner w-full rounded-full h-5">
        <div
            #progressBar
            [ngClass]="{
                'from-tertiary-700 to-tertiary-600': barColor === 'tertiary',
                'from-primary to-warn': barColor === 'warn-gradient'
            }"
            class="h-full rounded-full bg-gradient-to-r"
            [style.width]="
                levelInformation.percentageProgressInCurrentLevel + '%'
            "
        ></div>
    </div>
    <div class="flex justify-between items-center flex-wrap gap-2">
        <span
            class="text-2xl xxl:text-3xl font-extrabold uppercase whitespace-nowrap"
        >
            {{ "shared.experience.level" | transloco }}
            {{ levelInformation.currentLevel }}</span
        >
        <div class="whitespace-nowrap">
            <span
                [class.text-hint]="secondaryColor === 'hint'"
                class="text-md xxl:text-lg"
            >
                {{ levelInformation.pointsToNextLevel }}
                {{ "shared.experience.before-next-level" | transloco }}</span
            >
            <span class="text-xl font-bold uppercase">
                &nbsp;{{ "shared.experience.level" | transloco }}
                {{ levelInformation.currentLevel + 1 }}</span
            >
        </div>
    </div>
</div>
