import { createSelector } from '@ngrx/store';
import { Int32UserRankingViewModel } from 'app/api';
import { AppState } from 'app/store';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import {
    RessourceListSelectors,
    createRessourceListSelectors,
} from '../../shared/ressource-list/ressource-list-selectors.creator';

const selectState = (state: AppState) => state.rankingAnswers;

const selectByRank = (rank: number) =>
    createSelector(selectState, (state) =>
        state.data.find((r) => r.rank === rank)
    );

const selectUserRank = createSelector(
    selectState,
    UserSelectors.selectUserId,
    (state, userId) => state.data.find((r) => r.user.id === userId)?.rank
);

interface AdditionalSelectors {
    selectByRank: (state: any) => any;
    selectUserRank: (state: any) => number;
}

type RankingAnswersSelectors =
    RessourceListSelectors<Int32UserRankingViewModel> & AdditionalSelectors;

export const RankingAnswersSelectors: RankingAnswersSelectors = {
    ...createRessourceListSelectors('rankingAnswers'),
    selectByRank,
    selectUserRank,
};
