import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { LocationViewModel } from 'app/api';
import { environment } from 'environments/environment';
import { isEmpty } from 'lodash-es';
export type GoogleMapsApiServiceInput = {
    core?: boolean;
    maps?: boolean;
    places?: boolean;
    marker?: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class GoogleMapsApiService {
    async loadLibrary(input: GoogleMapsApiServiceInput) {
        const loader = new Loader({
            apiKey: environment.googleMapsApiKey,
            version: 'weekly',
        });
        const core = input.core ? await loader.importLibrary('core') : null;
        const places = input.places
            ? await loader.importLibrary('places')
            : null;
        const maps = input.maps ? await loader.importLibrary('maps') : null;
        const marker = input.marker
            ? await loader.importLibrary('marker')
            : null;
        return {
            core,
            places,
            maps,
            marker,
        };
    }

    transformToLocationViewModel(placeResult: google.maps.places.PlaceResult) {
        return {
            longitude: placeResult.geometry.location.lng(),
            latitude: placeResult.geometry.location.lat(),
            zipCode: placeResult.address_components.find((pr) =>
                pr.types.includes('postal_code')
            )?.long_name,
            country: placeResult.address_components.find((pr) =>
                pr.types.includes('country')
            )?.long_name,
            city: this.findCityOrFallbackAreaLevels(
                placeResult.address_components
            ),
            address: placeResult.name,
            name: placeResult.name,
        } as LocationViewModel;
    }

    findCityOrFallbackAreaLevels(
        addressComponents: google.maps.GeocoderAddressComponent[]
    ) {
        const city = addressComponents.find((pr) =>
            pr.types.includes('locality')
        )?.long_name;
        if (!isEmpty(city)) {
            return city;
        }

        let areaLevel: number = 7;
        while (areaLevel > 1) {
            const administrativeAreaLevel = addressComponents.find((pr) =>
                pr.types.includes(
                    'administrative_area_level_' + areaLevel.toString()
                )
            )?.long_name;
            if (!isEmpty(administrativeAreaLevel)) {
                return administrativeAreaLevel;
            }
            areaLevel -= 1;
        }
        return null;
    }
}
