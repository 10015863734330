import { createAction, props } from '@ngrx/store';
import {
    MissionUnregisteredViewModel,
    MissionUnregisteredViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const MissionListActions = {
    ...createRessourceListActionGroup<
        MissionUnregisteredViewModel,
        MissionUnregisteredViewModelPaginatedListDto
    >('MissionList'),

    PublishRequest: createAction(
        `[MissionList] Publish Request`,
        props<{ id: number }>()
    ),
    UpdateCover: createAction(
        `[MissionList] Update Cover`,
        props<{ file: File; id: number }>()
    ),
    LoadCities: createAction(
        `[MissionList] Load Cities`,
        props<{ search?: string }>()
    ),
    LoadCitiesSuccess: createAction(
        `[MissionList] Load Cities Success`,
        props<{ cities: string[] }>()
    ),
    LoadCitiesFail: createAction(
        `[MissionList] Load Cities Fail`,
        props<{ error: string }>()
    ),
};
