/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReportConfigViewModel { 
    companyId?: number;
    lang?: string | null;
    logoAsBase64?: string | null;
    backgroundColor?: string | null;
    textOnBackgroundColor?: string | null;
    primaryColor?: string | null;
    textOnPrimaryColor?: string | null;
    secondaryColor?: string | null;
    textOnSecondaryColor?: string | null;
}

