import { DatePipe, NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import {
    DualQuizChallengeViewModel,
    EDualQuizChallengeResult,
    EDualQuizChallengeState,
} from 'app/api';
import { BasileAvatarComponent } from 'app/shared/components/avatar/basile/basile-avatar.component';
import { ESharedUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';

@Component({
    selector: 'dual-quiz-challenge-card',
    templateUrl: './card.component.html',
    standalone: true,
    imports: [
        BslkClassicButtonComponent,
        TranslocoModule,
        BasileAvatarComponent,
        NgIf,
        NgClass,
        MatIconModule,
        BslkTagComponent,
        DatePipe,
    ],
})
export class DualQuizChallengeCardComponent {
    EDualQuizChallengeState = EDualQuizChallengeState;

    @Input() challenge: DualQuizChallengeViewModel;

    constructor(private router: Router) {}

    get resultIcon() {
        if (this.challenge.result === EDualQuizChallengeResult.Draw) {
            return 'heroicons_outline:scale';
        } else if (this.hasWon) {
            return 'heroicons_outline:trophy';
        } else {
            return 'feather:frown';
        }
    }

    get resultColor() {
        if (this.ongoingChallenge) {
            return 'gray';
        } else if (this.hasDrawn) {
            return 'warn';
        } else if (this.hasWon) {
            return 'tertiary';
        } else {
            return 'primary';
        }
    }

    get hasWon() {
        return (
            (this.challenge.result === EDualQuizChallengeResult.ChallengerWon &&
                this.challenge.isChallenger) ||
            (this.challenge.result === EDualQuizChallengeResult.ChallengeeWon &&
                !this.challenge.isChallenger)
        );
    }

    get hasLost() {
        return (
            !this.hasWon &&
            this.challenge.result &&
            this.challenge.result !== EDualQuizChallengeResult.Draw
        );
    }

    get hasDrawn() {
        return this.challenge.result === EDualQuizChallengeResult.Draw;
    }

    get ongoingChallenge() {
        return !this.challenge.result;
    }

    get isPendingForUser() {
        return (
            (this.challenge.isChallenger &&
                this.challenge.state ===
                    EDualQuizChallengeState.PendingForChallenger) ||
            (!this.challenge.isChallenger &&
                this.challenge.state ===
                    EDualQuizChallengeState.PendingForChallengee)
        );
    }

    goToChallenge() {
        this.router.navigateByUrl(
            UrlHelpers.getSharedUrl(ESharedUrl.SharedDualQuizChallengePage, {
                id: this.challenge.id,
            })
        );
    }

    goToChallengeResults() {
        if (this.isPendingForUser) {
            return;
        }

        this.router.navigateByUrl(
            UrlHelpers.getSharedUrl(
                ESharedUrl.SharedDualQuizChallengeResultsPage,
                {
                    id: this.challenge.id,
                }
            )
        );
    }
}
