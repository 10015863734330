import {
    AsyncPipe,
    DatePipe,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { MissionSlotViewModel } from 'app/api';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { MissionManageActions } from 'app/store/mission/mission-manage/mission-manage.action';
import { MissionManageSelectors } from 'app/store/mission/mission-manage/mission-manage.selectors';

@Component({
    selector: 'mission-slot-picker',
    standalone: true,
    templateUrl: './mission-slot-picker.component.html',
    imports: [
        AsyncPipe,
        BslkClassicCardComponent,
        FormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatSelectModule,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        TranslocoModule,
    ],
})
export class MissionSlotPickerComponent {
    showPickerDropdown$ = this.store.select(
        MissionManageSelectors.selectShowMissionSlotsDropdown
    );

    selectedSlotId$ = this.store.select(
        MissionManageSelectors.selectSelectedSlotId
    );

    hasSelectedSlot$ = this.store.select(
        MissionManageSelectors.selectHasSelectedSlot
    );

    hasAnySlot$ = this.store.select(MissionManageSelectors.selectHasAnySlot);

    selectedSlot$ = this.store.select(
        MissionManageSelectors.selectSelectedSlot
    );

    missionSlots$ = this.store.select(
        MissionManageSelectors.selectMissionSlots
    );

    constructor(private datePipe: DatePipe, private store: Store) {}

    formatDateRange(missionSlot: MissionSlotViewModel) {
        return formatDateRange(
            this.datePipe,
            missionSlot.startDate,
            missionSlot.endDate
        );
    }

    onValueChange(slotId: number) {
        this.store.dispatch(
            MissionManageActions.selectMissionSlot({ id: slotId })
        );
    }
}
