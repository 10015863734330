import { AsyncPipe, DatePipe, DecimalPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkStarRatingComponent } from '@bslk/components/form/rating/star-rating.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AssociationViewModel } from 'app/api';
import { AssociationRatingListActions } from 'app/store/association/association-rating/association-rating.actions';
import { AssociationRatingListSelectors } from 'app/store/association/association-rating/association-rating.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'association-rating',
    templateUrl: './association-rating.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkClassicCardComponent,
        BslkStarRatingComponent,
        DatePipe,
        DecimalPipe,
        LetDirective,
        MatIconModule,
        MatPaginatorModule,
        NgFor,
        NgIf,
        NgxSkeletonLoaderModule,
        TranslocoModule,
    ],
})
export class AssociationRatingComponent implements OnInit {
    @Input() association: AssociationViewModel;

    ratings$ = this.store.select(AssociationRatingListSelectors.selectData);
    totalCount$ = this.store.select(
        AssociationRatingListSelectors.selectTotalCount
    );
    pageIndex$ = this.store.select(
        AssociationRatingListSelectors.selectPageIndex
    );
    pageSize$ = this.store.select(
        AssociationRatingListSelectors.selectPageSize
    );
    totalPages$ = this.store.select(
        AssociationRatingListSelectors.selectTotalPages
    );
    isLoading$ = this.store.select(
        AssociationRatingListSelectors.selectIsLoading
    );
    isInitialized$ = this.store.select(
        AssociationRatingListSelectors.selectIsInitialized
    );

    constructor(private store: Store) {}

    ngOnInit() {
        this.store.dispatch(
            AssociationRatingListActions.FilterListRequest({
                filters: [
                    {
                        key: 'associationId',
                        value: this.association.id,
                    },
                ],
            })
        );
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            AssociationRatingListActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
    }
}
