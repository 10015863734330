/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EGender = 'Male' | 'Female' | 'NonBinary' | 'Unknown';

export const EGender = {
    Male: 'Male' as EGender,
    Female: 'Female' as EGender,
    NonBinary: 'NonBinary' as EGender,
    Unknown: 'Unknown' as EGender
};

