<!-- Progress bar -->
<div class="w-full flex justify-between mb-1">
    <span class="text-base font-medium">{{
        "shared.onboarding-progress.title" | transloco
    }}</span>
    <span class="font-medium">{{ completedPercentage }}%</span>
</div>
<div class="w-full bg-gray-200 rounded-full h-2.5">
    <div
        [class]="'bg-' + barColor + ' h-2.5 rounded-full'"
        [style]="'width:' + completedPercentage + '%'"
    ></div>
</div>

<!-- Tasks details -->
<div
    *ngIf="showTasks"
    [class]="'w-full my-2 rounded-2xl p-4 ' + actionsBackground"
>
    <!-- Tasks to do -->
    <div class="mb-1" *ngIf="tasksToDo.length > 0">
        <div class="text-xl mb-1 font-bold text-primary">
            {{ "shared.onboarding-progress.actions-to-do" | transloco }}
        </div>
        <div
            (click)="onActionClick(task)"
            class="mb-1"
            [class.cursor-pointer]="task.action"
            *ngFor="let task of tasksToDo"
        >
            <div
                class="flex justify-between items-center text-lg font-semibold"
            >
                <div class="flex items-center">
                    <mat-icon
                        *ngIf="!lightMode"
                        class="mr-2 text-primary"
                        [svgIcon]="
                            'heroicons_outline:' +
                            (task.disabled
                                ? 'information-circle'
                                : 'exclamation-circle')
                        "
                    >
                    </mat-icon>
                    <span [class.opacity-50]="task.disabled">{{
                        "shared.onboarding-progress.tasks." + task.translocoKey
                            | transloco
                    }}</span>
                    <mat-icon
                        *ngIf="task.disabled"
                        [matTooltip]="
                            'shared.onboarding-progress.tasks.' +
                                task.disabledTooltipTranslocoKey | transloco
                        "
                        class="icon-size-4 text-blue-500 ml-1"
                        [svgIcon]="'heroicons_solid:information-circle'"
                    ></mat-icon>
                </div>
                <mat-icon
                    *ngIf="task.action"
                    [svgIcon]="'heroicons_outline:pencil-square'"
                >
                </mat-icon>
            </div>
            <bslk-reward
                class="flex ml-8"
                *ngIf="task.rewards"
                [purposeCoins]="task.rewards.purposeCoins"
                [experiencePoints]="task.rewards.experiencePoints"
                [size]="'small'"
            ></bslk-reward>
        </div>
    </div>

    <!-- Separator -->
    <div
        *ngIf="tasksToDo.length > 0 && tasksDone.length > 0"
        class="my-3 border-t"
    ></div>

    <!-- Tasks done -->
    <div *ngIf="tasksDone.length > 0">
        <div class="mb-1 text-xl font-bold text-primary">
            {{ "shared.onboarding-progress.actions-done" | transloco }}
        </div>
        <div class="mb-1" *ngFor="let task of tasksDone">
            <div class="flex items-center text-lg">
                <mat-icon
                    *ngIf="!lightMode"
                    class="mr-2 text-quarternary"
                    [svgIcon]="'heroicons_outline:check-circle'"
                >
                </mat-icon>
                <span>{{
                    "shared.onboarding-progress.tasks." + task.translocoKey
                        | transloco
                }}</span>
            </div>
            <bslk-reward
                class="flex ml-8"
                *ngIf="task.purposeCoinsReward"
                [purposeCoins]="task.purposeCoinsReward"
                [experiencePoints]="task.experiencePoints"
                [size]="'small'"
            ></bslk-reward>
        </div>
    </div>
</div>
