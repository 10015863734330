<div
    *ngrxLet="question$ as question"
    class="flex flex-col justify-center items-center gap-6"
>
    <div class="flex justify-center items-center gap-4">
        <bslk-tag
            [background]="'primary-light'"
            [textColor]="'primary'"
            [icon]="'bslk_thematics:' + question.thematic"
        >
            <div class="ml-2">
                {{
                    "enums.EAwarenessThematic." + question.thematic | transloco
                }}
            </div>
        </bslk-tag>

        <dual-quiz-challenge-round-timer
            class="md:hidden"
            *ngIf="isTimerRunning$ | async"
        ></dual-quiz-challenge-round-timer>
    </div>

    <span class="text-2xl font-bold text-center md:text-left">{{
        question.question
    }}</span>

    <div
        [class.grayscale]="!(questionEmittedResponseReceived$ | async)"
        class="flex-col gap-5 w-full transition-all duration-300"
    >
        <dual-quiz-challenge-answer
            [choice]="1"
            [answer]="question.answer1"
        ></dual-quiz-challenge-answer>
        <dual-quiz-challenge-answer
            [choice]="2"
            [answer]="question.answer2"
        ></dual-quiz-challenge-answer>
        <dual-quiz-challenge-answer
            *ngIf="question.answer3"
            [choice]="3"
            [answer]="question.answer3"
        ></dual-quiz-challenge-answer>
        <dual-quiz-challenge-answer
            *ngIf="question.answer4"
            [choice]="4"
            [answer]="question.answer4"
        ></dual-quiz-challenge-answer>
    </div>
</div>
