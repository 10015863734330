<base-dialog
    (onClose)="close()"
    [showFireworks]="true"
    [title]="
        'shared.new-badge.title-' + (badgeLength > 1 ? 'plural' : 'singular')
            | transloco
    "
    [showActions]="true"
    [color]="'tertiary'"
>
    <div class="w-full h-full flex flex-col justify-center relative" body>
        <div class="m-auto">
            <mat-icon
                *ngIf="currentBadgeIndex > 0"
                (click)="currentBadgeIndex = currentBadgeIndex - 1"
                [svgIcon]="'heroicons_solid:chevron-left'"
                class="text-tertiary icon-size-20 cursor-pointer absolute top-1/2 -translate-y-1/2 left-0"
            ></mat-icon>
            <bslk-business-badge [badge]="currentBadge"></bslk-business-badge>
            <mat-icon
                *ngIf="currentBadgeIndex + 1 < badgeLength"
                (click)="currentBadgeIndex = currentBadgeIndex + 1"
                [svgIcon]="'heroicons_solid:chevron-right'"
                class="text-tertiary icon-size-20 cursor-pointer absolute top-1/2 -translate-y-1/2 right-0"
            ></mat-icon>
        </div>
    </div>

    <div class="flex justify-end" actions>
        <button
            (click)="close()"
            class="z-10 text-white m-auto bg-tertiary"
            mat-flat-button
        >
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            <span class="ml-1">{{ "shared.common.close" | transloco }}</span>
        </button>
    </div>
</base-dialog>
