import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RankingService } from 'app/api';
import {
    createLoadListRequestEffect,
    createReloadEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { RankingAnswersActions } from './ranking-answers.actions';
import { RankingAnswersSelectors } from './ranking-answers.selectors';

@Injectable()
export class RankingAnswersEffects {
    loadRanking = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.rankingService,
        RankingAnswersActions,
        RankingAnswersSelectors,
        this.rankingService.rankingAnswersGet
    );
    reload$ = createReloadEffect(this.actions$, RankingAnswersActions);

    constructor(
        private actions$: Actions,
        private store: Store,
        private rankingService: RankingService
    ) {}
}
