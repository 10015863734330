import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { UserViewModel } from 'app/api';
import { ChatActions } from 'app/store/chat/chat.action';
import { MissionSelectors } from 'app/store/mission/mission/mission.selectors';

@Component({
    standalone: true,
    selector: 'registration-success',
    templateUrl: './registration-success.component.html',
    imports: [
        AsyncPipe,
        TranslocoModule,
        NgIf,
        BslkClassicButtonComponent,
        FuseAlertComponent,
        LetDirective,
    ],
})
export class RegistrationSuccessComponent {
    @Output() onChat: EventEmitter<void> = new EventEmitter<void>();

    hasResponsibleUser$ = this.store.select(
        MissionSelectors.selectHasResponsibleUser
    );
    responsibleUser$ = this.store.select(
        MissionSelectors.selectResponsibleUser
    );

    isTeamBuilding$ = this.store.select(MissionSelectors.selectIsTeamBuilding);

    constructor(private store: Store, private route: ActivatedRoute) {}

    buildChat(user: UserViewModel) {
        this.onChat.emit();
        this.store.dispatch(
            ChatActions.buildChat({
                users: [user],
                currentRoute: this.route.snapshot.toString(),
            })
        );
    }
}
