<div
    class="inline-block mb-1"
    [matTooltipDisabled]="!tooltip"
    [matTooltip]="tooltip"
>
    <ng-content></ng-content>
    <mat-icon
        *ngIf="tooltip"
        class="icon-size-4 text-tertiary ml-1"
        [svgIcon]="'heroicons_solid:information-circle'"
    ></mat-icon>
    <span *ngIf="isRequired">*</span>
</div>
