<ng-container *ngrxLet="isSignedIn$ as isSignedIn">
    <auth-template
        class="w-full"
        [title]="'signup.sign-up' | transloco"
        *ngrxLet="isLoading$ as isLoading"
    >
        <mat-stepper [linear]="false" [selectedIndex]="isSignedIn ? 1 : 0">
            <mat-step [completed]="isSignedIn" [editable]="false">
                <ng-template matStepLabel>{{
                    "signup.account-creation" | transloco
                }}</ng-template>

                <sign-up-auth [userRole]="EUserRole.AssoAdmin">
                    <ng-container
                        [ngTemplateOutlet]="continueButton"
                    ></ng-container>
                </sign-up-auth>
            </mat-step>

            <mat-step
                *ngrxLet="hasPendingRequest$ as hasPendingRequest"
                [editable]="false"
                [completed]="false"
            >
                <ng-template matStepLabel>
                    <span
                        *ngIf="
                            associationAction !== 'join' && !hasPendingRequest
                        "
                        >{{ "signup.organization-creation" | transloco }}</span
                    >
                    <span
                        *ngIf="
                            associationAction === 'join' || hasPendingRequest
                        "
                        >{{ "signup.organization-join" | transloco }}</span
                    >
                </ng-template>

                <ng-container
                    *ngIf="
                        associationAction === null || hasPendingRequest;
                        else createOrJoinAssociation
                    "
                >
                    <div class="p-3" *ngIf="!hasPendingRequest">
                        <bslk-choice-card
                            (click)="selectAssociationAction('create')"
                            [title]="
                                'signup.create-organization-title' | transloco
                            "
                            [description]="
                                'signup.create-organization-description'
                                    | transloco
                            "
                        >
                        </bslk-choice-card>

                        <div class="my-8"></div>

                        <bslk-choice-card
                            (click)="selectAssociationAction('join')"
                            [title]="
                                'signup.join-organization-title' | transloco
                            "
                            [description]="
                                'signup.join-organization-description'
                                    | transloco
                            "
                        >
                        </bslk-choice-card>
                    </div>
                    <ng-container
                        *ngIf="hasPendingRequest"
                        [ngTemplateOutlet]="joinSuccess"
                    ></ng-container>
                </ng-container>

                <ng-template #createOrJoinAssociation>
                    <div class="mt-4 mb-4">
                        <!-- Create Association -->
                        <form
                            *ngIf="associationAction === 'create'"
                            [formGroup]="associationFormGroup"
                            (ngSubmit)="createAssociation()"
                        >
                            <!-- Organization name -->
                            <mat-form-field floatLabel="always" class="w-full">
                                <mat-label>{{
                                    "signup.name" | transloco
                                }}</mat-label>
                                <mat-icon
                                    class="icon-size-5"
                                    [svgIcon]="'mat_solid:badge'"
                                    matPrefix
                                >
                                </mat-icon>
                                <input
                                    [placeholder]="
                                        'signup.name-placeholder' | transloco
                                    "
                                    matInput
                                    [formControlName]="'name'"
                                />
                                <mat-error>
                                    {{ "signup.name-required" | transloco }}
                                </mat-error>
                            </mat-form-field>

                            <!-- Tags -->
                            <bslk-advanced-select-sync
                                class="w-full mt-2"
                                *ngIf="translationsLoaded"
                                [items]="activityFieldsAdvancedSelectItems"
                                [multiple]="'multiple'"
                                [group]="associationFormGroup"
                                [icon]="'mat_solid:category'"
                                [controlName]="'tags'"
                                [label]="'signup.tags' | transloco"
                                [errorMessage]="
                                    'signup.tags-required' | transloco
                                "
                                [selectPlaceholder]="
                                    'signup.tags-placeholder' | transloco
                                "
                                [searchPlaceholder]="
                                    'signup.search-tags-placeholder' | transloco
                                "
                            ></bslk-advanced-select-sync>

                            <div
                                *ngrxLet="
                                    associationCreationError$ as associationCreationError
                                "
                            >
                                <fuse-alert
                                    class="mb-4"
                                    *ngIf="associationCreationError"
                                    [appearance]="'outline'"
                                    [showIcon]="true"
                                    [type]="'error'"
                                    [@shake]="true"
                                >
                                    {{
                                        "signup.errors." +
                                            associationCreationError | transloco
                                    }}
                                </fuse-alert>
                            </div>

                            <!-- Create organization button -->
                            <ng-container
                                [ngTemplateOutlet]="continueButton"
                            ></ng-container>
                        </form>

                        <!-- Join Association -->
                        <form
                            *ngIf="associationAction === 'join'"
                            [formGroup]="joinAssociationFormGroup"
                            (ngSubmit)="joinAssociation()"
                        >
                            <single-select-async
                                [control]="
                                    joinAssociationFormGroup.controls
                                        .associationId
                                "
                                [valueIsId]="true"
                                [label]="'users.association' | transloco"
                                [selectPlaceholder]="
                                    'users.association-placeholder' | transloco
                                "
                                [searchPlaceholder]="
                                    'users.filter-associations' | transloco
                                "
                                [actions]="associationActions"
                                [selectors]="associationSelectors"
                            >
                            </single-select-async>

                            <ng-container
                                [ngTemplateOutlet]="continueButton"
                            ></ng-container>
                        </form>
                    </div>
                </ng-template>
            </mat-step>
        </mat-stepper>

        <div footer>
            <ng-container *ngIf="!isSignedIn">
                <div class="flex justify-center items-baseline font-medium">
                    {{ "signup.already-registered" | transloco }} &nbsp;
                    <button mat-flat-button>
                        <a class="font-bold" [routerLink]="'/sign-in'">{{
                            "signup.sign-in" | transloco
                        }}</a>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="isSignedIn">
                <div class="flex justify-center items-baseline font-medium">
                    <a class="font-bold" [routerLink]="'/sign-out'">{{
                        "signup.sign-out" | transloco
                    }}</a>
                </div>
            </ng-container>

            <div
                class="flex justify-center items-baseline mt-0.5 text-sm text-gray-500"
            >
                *{{ "signup.required-information" | transloco }}
            </div>
        </div>
    </auth-template>
</ng-container>

<ng-template #joinSuccess>
    <fuse-alert
        [appearance]="'outline'"
        [dismissed]="false"
        [name]="'join-success'"
        [type]="'success'"
    >
        <span fuseAlertTitle>{{
            "signup.join-success-title" | transloco
        }}</span>
        {{ "signup.join-success-description" | transloco }}
    </fuse-alert>
</ng-template>

<ng-template #continueButton>
    <div class="flex m-1 mt-4 items-center">
        <button
            class="mr-5"
            *ngIf="associationAction"
            (click)="cancelAction()"
            mat-icon-button
        >
            <mat-icon
                class="icon-size-8 text-primary"
                [svgIcon]="'heroicons_outline:arrow-left'"
            ></mat-icon>
        </button>
        <button
            class="fuse-mat-button-large w-full text-accent"
            mat-flat-button
            [disabled]="isLoading"
            [color]="'primary'"
            type="submit"
        >
            <div class="flex items-center">
                <mat-progress-spinner
                    *ngIf="isLoading"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                ></mat-progress-spinner>
                <span class="ml-4">
                    {{ "signup.continue" | transloco }}
                </span>
            </div>
        </button>
    </div>
</ng-template>
