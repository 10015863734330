import { createSelector } from '@ngrx/store';
import { ResourceListFilter } from 'app/store/shared/ressource-list/ressource-list-state';
import { isEmpty } from 'lodash-es';
import { AppState } from '../..';

const selectState = (state: AppState) => state.projectListFilters;

const selectFiltersForApi = createSelector(selectState, (state) => {
    const results: Array<{ key: ResourceListFilter; value: any }> = [
        { key: 'nameContains', value: state.nameContains },
        { key: 'activityFields', value: state.activityFields },
        { key: 'locations', value: state.locations },
    ];
    return results;
});

const selectNameContains = createSelector(
    selectState,
    (state) => state.nameContains
);

const selectActivityFields = createSelector(
    selectState,
    (state) => state.activityFields
);

const selectActivityFieldsCount = createSelector(
    selectActivityFields,
    (fields) => fields?.length
);

const selectLocations = createSelector(selectState, (state) => state.locations);

const selectLocationsCount = createSelector(
    selectLocations,
    (locations) => locations?.length
);

const selectAvailableActivityFields = createSelector(selectState, (state) =>
    state.availableFilters?.activityFields.filter(
        (f) => !state.activityFields?.includes(f)
    )
);

const selectAvailableLocations = createSelector(
    selectState,
    (state) => state.availableFilters?.locations
);

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectHasAnyActiveFilter = createSelector(
    selectLocations,
    selectNameContains,
    selectActivityFields,
    (locations, nameContains, activityFields) => {
        return (
            !isEmpty(locations) ||
            !isEmpty(nameContains) ||
            !isEmpty(activityFields)
        );
    }
);

export const ProjectListFiltersSelectors = {
    selectFiltersForApi,
    selectHasAnyActiveFilter,
    selectNameContains,
    selectActivityFields,
    selectLocations,
    selectAvailableActivityFields,
    selectAvailableLocations,
    selectIsLoading,
    selectActivityFieldsCount,
    selectLocationsCount,
};
