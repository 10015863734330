/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DonationRequestViewModel } from './donationRequestViewModel';
import { EDonationCampaignWhitelistType } from './eDonationCampaignWhitelistType';
import { DonationCampaignCustomizationViewModel } from './donationCampaignCustomizationViewModel';
import { DonationCodeViewModel } from './donationCodeViewModel';
import { CompanyViewModel } from './companyViewModel';
import { EDonationCampaignState } from './eDonationCampaignState';
import { ProjectViewModel } from './projectViewModel';


export interface DonationCampaignViewModel { 
    id?: number;
    dateCreated?: string;
    dateUpdated?: string | null;
    dateArchived?: string | null;
    name?: string | null;
    expirationDate?: string | null;
    donationAmount?: number;
    numberOfDonations?: number | null;
    isOneEnvelope?: boolean;
    message?: string | null;
    accessToken?: string | null;
    isPaid?: boolean;
    onlyAllowProfessionalEmails?: boolean;
    whitelistType?: EDonationCampaignWhitelistType;
    company?: CompanyViewModel;
    companyId?: number;
    projects?: Array<ProjectViewModel> | null;
    projectIds?: Array<number> | null;
    requests?: Array<DonationRequestViewModel> | null;
    participantCount?: number | null;
    totalCost?: number;
    state?: EDonationCampaignState;
    customization?: DonationCampaignCustomizationViewModel;
    whitelistedEmails?: Array<string> | null;
    codesToGenerate?: number | null;
    isClientCampaign?: boolean;
    clientName?: string | null;
    whitelistedCodes?: Array<DonationCodeViewModel> | null;
}
export namespace DonationCampaignViewModel {
}


