import { FormGroup, Validators } from '@angular/forms';

export function removeAllValidators(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];

        if (control instanceof FormGroup) {
            this.removeAllValidators(control);
        } else {
            control.clearValidators();
            control.updateValueAndValidity();
        }
    });
}

export function addRequiredValidatorToAll(
    formGroup: FormGroup,
    controls?: string[]
) {
    Object.keys(formGroup.controls).forEach((key) => {
        if (controls == undefined || controls.includes(key)) {
            const control = formGroup.controls[key];
            if (control instanceof FormGroup) {
                this.addRequiredValidatorToAll(control);
            } else {
                control.setValidators([
                    Validators.required,
                    ...(control.validator ? [control.validator] : []),
                ]);
                control.updateValueAndValidity();
            }
        }
    });
}

export function patchWithNullValues(formGroup: FormGroup, newObject: any) {
    for (const field in formGroup.controls) {
        if (newObject[field] === undefined) {
            newObject[field] = null;
        }
    }

    formGroup.setValue(newObject);
}
