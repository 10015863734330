import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { BslkStarRatingComponent } from '@bslk/components/form/rating/star-rating.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { CourseActions } from 'app/store/course/course/course.action';
import { CourseSelectors } from 'app/store/course/course/course.selectors';

@Component({
    selector: 'course-rating-dialog',
    standalone: true,
    templateUrl: './course-rating-dialog.component.html',
    imports: [
        AsyncPipe,
        BaseDialogComponent,
        BslkClassicButtonComponent,
        BslkLengthInfoComponent,
        BslkStarRatingComponent,
        FuseAlertComponent,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class CourseReviewDialogComponent {
    isLoading$ = this.store.select(CourseSelectors.selectIsLoadingRating);

    formGroup = new FormGroup({
        rating: new FormControl(null, Validators.required),
        comment: new FormControl(null, Validators.maxLength(300)),
    });

    constructor(
        private dialogRef: MatDialogRef<CourseReviewDialogComponent>,
        private store: Store
    ) {}

    close() {
        this.dialogRef.close();
    }

    submit() {
        if (this.formGroup.valid) {
            this.store.dispatch(
                CourseActions.sendCourseRatingRequest({
                    rating: this.formGroup.get('rating').value,
                    comment: this.formGroup.get('comment').value,
                })
            );
        }
    }
}
