<ng-container *ngrxLet="isLoading$ as isLoading">
    <div
        *ngrxLet="equipedConfiguration$ as equipedConfiguration"
        class="bg-white flex flex-col md:flex-row flex-auto"
    >
        <div
            class="w-full md:w-3/12 border-r-2 text-2xl p-3 flex flex-col gap-3"
        >
            <asset-type-picker
                *ngFor="let assetType of assetTypes"
                [assetType]="assetType.type"
                [selectedAssetType]="selectedAssetType"
                (click)="selectAssetType(assetType.type, equipedConfiguration)"
                [title]="
                    'basile.asset-types-plural.' +
                        assetType.type.toString().toLowerCase() | transloco
                "
                [icon]="assetType.icon"
            >
            </asset-type-picker>
        </div>
        <asset-picker
            class="w-full md:w-5/12"
            (assetPreviewed)="onAssetPreviewed($event)"
            (assetEquiped)="onAssetEquiped($event)"
            (assetBought)="onAssetBought($event)"
            [isLoading]="isLoading"
            [assets]="assets$ | async"
            [selectedAssetType]="selectedAssetType"
            [purposeCoinsBalance]="currentBalance$ | async"
        >
        </asset-picker>
        <div class="w-full md:w-4/12 flex flex-col border-l-2">
            <div
                class="bg-primary justify-center text-center flex items-center text-white p-2 text-lg"
            >
                <div class="mr-3">
                    {{ "basile.current-balance" | transloco }}
                </div>
                <mat-icon
                    class="text-warn"
                    [svgIcon]="'bslk_core:purpose-coins'"
                ></mat-icon>
                <div class="font-bold text-2xl ml-1">
                    {{ currentBalance$ | async }}
                </div>
                <div>&nbsp;{{ "basile.purpose-coins" | transloco }}</div>
            </div>
            <my-basile
                class="grow p-4"
                [configuration]="previewConfiguration"
            ></my-basile>
        </div>
    </div>
</ng-container>
