import { Route } from '@angular/router';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { associationRoutes } from './modules/association/association.routes';
import { AuthForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { LinkedInSignInCallback } from './modules/auth/linkedin-sign-in-callback/linkedin-sign-in-callback.component';
import { LinkedInSignUpCallback } from './modules/auth/linkedin-sign-up-callback/linkedin-sign-up-callback.component';
import { AuthSignInComponent } from './modules/auth/sign-in/sign-in.component';
import { AuthSignOutComponent } from './modules/auth/sign-out/sign-out.component';
import { companyAdminRoutes } from './modules/company-admin/company-admin.routes';
import { externalRoutes } from './modules/external/external.routes';
import { regularRoutes } from './modules/regular/regular.routes';
import { sharedRoutes } from './modules/shared/shared.routes';
import { superAdminRoutes } from './modules/super-admin/super-admin.routes';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    { path: 'sa', pathMatch: 'full', redirectTo: 'sa/dashboard' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: 'forgot-password', component: AuthForgotPasswordComponent },
            { path: 'sign-in', component: AuthSignInComponent },
            {
                path: 'linkedin-sign-in-callback',
                component: LinkedInSignInCallback,
            },
            {
                path: 'linkedin-sign-up-callback',
                component: LinkedInSignUpCallback,
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                component: AuthSignOutComponent,
            },
        ],
    },

    ...sharedRoutes,
    ...regularRoutes,
    ...associationRoutes,
    ...companyAdminRoutes,
    ...externalRoutes,
    ...superAdminRoutes,
];
