/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ELoginType = 'Classic' | 'Google' | 'Microsoft' | 'LinkedIn';

export const ELoginType = {
    Classic: 'Classic' as ELoginType,
    Google: 'Google' as ELoginType,
    Microsoft: 'Microsoft' as ELoginType,
    LinkedIn: 'LinkedIn' as ELoginType
};

