import { Pipe, PipeTransform } from '@angular/core';
import {
    EAssociationUrl,
    ERegularUrl,
    UrlHelpers,
} from '../utils/url-helpers.utils';

@Pipe({
    name: 'urlHelper',
    standalone: true,
})
export class UrlHelpersPipe implements PipeTransform {
    transform(key: string, ...args: any): any {
        switch (key) {
            case 'getAssociationViewPage':
                return UrlHelpers.getAssociationUrl(
                    EAssociationUrl.AssociationViewPage
                );
            case 'getAssociationEditPage':
                return UrlHelpers.getAssociationUrl(
                    EAssociationUrl.AssociationEditPage
                );
            case 'getRegularCoursesPage':
                return UrlHelpers.getRegularUrl(ERegularUrl.RegularCoursesPage);
        }
    }
}
