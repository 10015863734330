import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import { MissionOrProjectDialogComponent } from './mission-or-project.component';

@Injectable({ providedIn: 'root' })
export class MissionOrProjectDialogService implements IDialogService {
    private dialogRef: MatDialogRef<MissionOrProjectDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open() {
        this.dialogRef = this.dialog.open(MissionOrProjectDialogComponent);
    }

    close() {
        this.dialogRef.close();
    }
}
