import { Component } from '@angular/core';
import { BaseRichTextBlockComponent } from '../base/rich-text-block/base-rich-text-block.component';
import { BslkParagraphComponent } from '../paragraph/paragraph.component';

@Component({
    selector: 'bslk-quote',
    templateUrl: './quote.component.html',
    standalone: true,
    imports: [BslkParagraphComponent],
})
export class BslkQuoteComponent extends BaseRichTextBlockComponent {}
