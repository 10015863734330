import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import {
    ConfirmationDialogComponent,
    ConfirmationDialogInput,
} from 'app/shared/components/dialog/confirmation/confirmation-dialog.component';
import { MissionManageComponent } from 'app/shared/components/mission/manage/mission-manage.component';
import { MissionManageActions } from 'app/store/mission/mission-manage/mission-manage.action';
import { MissionManageSelectors } from 'app/store/mission/mission-manage/mission-manage.selectors';
import { MissionSelectors } from 'app/store/mission/mission/mission.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'mission-manage-association',
    standalone: true,
    templateUrl: './mission-manage-association.component.html',
    imports: [
        AsyncPipe,
        BslkClassicButtonComponent,
        LetDirective,
        MatButtonModule,
        MatIconModule,
        MissionManageComponent,
        NgIf,
        TranslocoModule,
    ],
})
export class MissionManageAssociationComponent implements OnDestroy {
    private unsubscribeAll: Subject<any> = new Subject<any>();

    isLoading$ = this.store.select(MissionSelectors.selectIsLoading);
    canRemoveSlot$ = this.store.select(
        MissionManageSelectors.selectCanDeleteSlot
    );

    hasSelectedSlot$ = this.store.select(
        MissionManageSelectors.selectHasSelectedSlot
    );

    isEditingTasks = false;

    constructor(private store: Store, private dialog: MatDialog) {}

    removeSlot() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                textKey: 'missionmanage.delete-slot-confirmation',
            } as ConfirmationDialogInput,
        });

        dialogRef.componentInstance.onConfirm
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.store.dispatch(
                    MissionManageActions.deleteMissionSlotRequest()
                );
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
