/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EAssociationLegalDocument = 'BalanceSheet' | 'ProfitAndLossStatement' | 'UpdatedBylaws' | 'LegalRepresentativeId' | 'RegistrationNumber' | 'BankDetails' | 'OfficialJournalPublication' | 'LastGeneralMeetingMinutes' | 'LastActivityReport';

export const EAssociationLegalDocument = {
    BalanceSheet: 'BalanceSheet' as EAssociationLegalDocument,
    ProfitAndLossStatement: 'ProfitAndLossStatement' as EAssociationLegalDocument,
    UpdatedBylaws: 'UpdatedBylaws' as EAssociationLegalDocument,
    LegalRepresentativeId: 'LegalRepresentativeId' as EAssociationLegalDocument,
    RegistrationNumber: 'RegistrationNumber' as EAssociationLegalDocument,
    BankDetails: 'BankDetails' as EAssociationLegalDocument,
    OfficialJournalPublication: 'OfficialJournalPublication' as EAssociationLegalDocument,
    LastGeneralMeetingMinutes: 'LastGeneralMeetingMinutes' as EAssociationLegalDocument,
    LastActivityReport: 'LastActivityReport' as EAssociationLegalDocument
};

