import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BlockViewModel } from 'app/api';
import { BaseRichTextBlockComponent } from '../base/rich-text-block/base-rich-text-block.component';
import { RichTextTemplateComponent } from '../base/rich-text-block/rich-text-template/rich-text-template.component';

@Component({
    selector: 'bslk-paragraph',
    templateUrl: './paragraph.component.html',
    standalone: true,
    imports: [RichTextTemplateComponent, NgIf],
})
export class BslkParagraphComponent extends BaseRichTextBlockComponent {
    @Input() block: BlockViewModel;

    get children() {
        return this.block.children;
    }
}
