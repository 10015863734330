<ng-container *ngrxLet="correctAnswer$ as correctAnswer">
    <button
        (click)="onAnswer()"
        *ngrxLet="lastChoice$ as lastChoice"
        [ngClass]="{
            'bg-warn shadow-warn-300':
                !correctAnswer ||
                (correctAnswer !== this.choice && lastChoice !== this.choice),
            'bg-quaternary shadow-quaternary-300':
                correctAnswer === this.choice,
            'bg-primary shadow-primary-300':
                correctAnswer &&
                correctAnswer !== this.choice &&
                lastChoice === this.choice,
            'hover:shadow-none transform hover:translate-x-1 hover:translate-y-1':
                !lastChoice
        }"
        class="w-full transition-all duration-500 mt-3 shadow-light px-16 py-4 rounded-full flex text-center mx-auto relative text-white"
    >
        <span class="w-full text-xl font-bold">{{ answer }}</span>
        <mat-icon
            *ngIf="
                lastChoice === this.choice &&
                correctAnswer &&
                correctAnswer !== this.choice
            "
            [svgIcon]="'heroicons_outline:x-mark'"
            class="absolute left-10 w-6 h-6 top-1/2 -translate-y-1/2 text-current"
        ></mat-icon>
        <mat-icon
            *ngIf="correctAnswer === this.choice"
            [svgIcon]="'heroicons_outline:check'"
            class="absolute left-10 w-6 h-6 top-1/2 -translate-y-1/2 text-current"
        ></mat-icon>
    </button>
</ng-container>
