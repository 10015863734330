<div [formGroup]="formGroup" class="pr-3">
    <mat-form-field floatLabel="always" class="w-full mt-4">
        <mat-label>{{ "createmission.name" | transloco }}</mat-label>
        <mat-icon
            class="icon-size-5"
            [svgIcon]="'heroicons_solid:pencil'"
            matPrefix
        ></mat-icon>
        <input
            [placeholder]="'createmission.name-placeholder' | transloco"
            [formControlName]="'name'"
            matInput
        />
        <mat-error>
            {{ "createmission.name-error" | transloco }}
        </mat-error>
    </mat-form-field>
    <bslk-card-toggle
        (valueChange)="missionTypeChanged()"
        [label]="'createmission.mission-type' | transloco"
        [control]="formGroup.controls.type"
        [choices]="[
            {
                text: 'createmission.skills-based-sponsorship' | transloco,
                description:
                    'createmission.skills-based-sponsorship-description'
                    | transloco,
                icon: 'mat_outline:work_outline',
                value: EMissionType.SkillsBasedSponsorship
            },
            {
                text: 'createmission.flash-campaign' | transloco,
                description:
                    'createmission.flash-campaign-description' | transloco,
                icon: 'mat_outline:handyman',
                value: EMissionType.FlashCampaign
            },
            {
                text: 'createmission.mentoring' | transloco,
                description: 'createmission.mentoring-description' | transloco,
                icon: 'mat_outline:school',
                value: EMissionType.Mentoring
            }
        ]"
        [value]="formGroup.controls.type.value"
    ></bslk-card-toggle>

    <!-- Team Building -->
    <div class="flex flex-col gap-3">
        <div class="flex justify-between">
            <bslk-classic-toggle
                *ngIf="
                    formGroup.controls.type.value === EMissionType.FlashCampaign
                "
                (valueChange)="isTeamBuildingChanged($event)"
                [label]="'createmission.is-team-building' | transloco"
                [color]="'warn'"
                [size]="'small'"
                [choices]="[
                    { text: 'shared.common.yes' | transloco, value: true },
                    { text: 'shared.common.no' | transloco, value: false }
                ]"
                [control]="formGroup.controls.isTeamBuilding"
                class="flex items-center gap-2 mt-3"
            ></bslk-classic-toggle>

            <bslk-classic-toggle
                *ngIf="formGroup.controls.isTeamBuilding.value"
                (valueChange)="isPaidTeamBuildingChanged($event)"
                [label]="'createmission.is-paid-team-building' | transloco"
                [color]="'warn'"
                [size]="'small'"
                [choices]="[
                    { text: 'shared.common.yes' | transloco, value: true },
                    { text: 'shared.common.no' | transloco, value: false }
                ]"
                [control]="formGroup.controls.isPaidTeamBuilding"
                class="flex items-center gap-2 mt-3"
            ></bslk-classic-toggle>
        </div>

        <div
            *ngIf="formGroup.controls.isPaidTeamBuilding.value"
            class="grid grid-cols-2 gap-3"
        >
            <div class="flex">
                <mat-form-field class="grow" [subscriptSizing]="'dynamic'">
                    <mat-label
                        >{{ "createmission.price-per-person" | transloco }}
                    </mat-label>
                    <input
                        [formControl]="
                            formGroup.controls.paidTeamBuildingPricePerPerson
                        "
                        type="number"
                        min="1"
                        matInput
                    />
                </mat-form-field>
                <mat-form-field [subscriptSizing]="'dynamic'">
                    <mat-label>{{
                        "createmission.currency" | transloco
                    }}</mat-label>
                    <mat-select
                        [formControl]="
                            formGroup.controls.paidTeamBuildingCurrency
                        "
                    >
                        <mat-option
                            *ngFor="let currency of getEnumValues(ECurrency)"
                            [value]="currency"
                        >
                            {{ "enums.ECurrency." + currency | transloco }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-form-field [subscriptSizing]="'dynamic'">
                <mat-label
                    >{{ "createmission.min-person" | transloco }}
                </mat-label>
                <input
                    [formControl]="formGroup.controls.paidTeamBuildingMinPerson"
                    type="number"
                    min="1"
                    matInput
                />
            </mat-form-field>
        </div>
    </div>

    <div class="flex gap-3 mt-6">
        <!-- Skills Selection -->
        <bslk-advanced-select-sync
            class="w-1/2"
            *ngIf="skillsTranslationsLoaded"
            [items]="skillsAdvancedSelectItems"
            [multiple]="'multiple'"
            [group]="formGroup"
            [icon]="'heroicons_solid:wrench'"
            [controlName]="'skills'"
            [label]="'createmission.skills' | transloco"
            [errorMessage]="'createmission.skills-error' | transloco"
            [selectPlaceholder]="'createmission.skills-placeholder' | transloco"
            [searchPlaceholder]="
                'createmission.search-skills-placeholder' | transloco
            "
        ></bslk-advanced-select-sync>

        <div class="w-1/2 flex flex-col">
            <mat-form-field class="w-full">
                <mat-label>{{
                    "createmission.approximate-duration" | transloco
                }}</mat-label>
                <mat-select [formControl]="formGroup.controls.duration">
                    <mat-option
                        *ngFor="let duration of getEnumValues(EDuration)"
                        [value]="duration"
                    >
                        {{ "enums.EDuration." + duration | transloco }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="formGroup.controls.duration.hasError('required')"
                >
                    {{ "bslk.form.required" | transloco }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <!-- Location -->
    <div class="w-full mt-4 flex justify-between items-end">
        <div class="flex flex-col">
            <div class="text-xl font-bold">
                {{ "createmission.location-title" | transloco }}
            </div>

            <!-- Is On Site -->
            <bslk-classic-toggle
                [label]="'createmission.is-on-site' | transloco"
                [color]="'warn'"
                [size]="'small'"
                [choices]="[
                    { text: 'shared.common.yes' | transloco, value: true },
                    { text: 'shared.common.no' | transloco, value: false }
                ]"
                [control]="formGroup.controls.isOnSite"
                [value]="formGroup.controls.isOnSite.value"
                class="flex items-center gap-2"
                (valueChange)="isOnSiteChanged($event)"
            ></bslk-classic-toggle>

            <!-- Is Remote -->
            <bslk-classic-toggle
                [label]="'createmission.is-remote' | transloco"
                [color]="'warn'"
                [size]="'small'"
                [choices]="[
                    { text: 'shared.common.yes' | transloco, value: true },
                    { text: 'shared.common.no' | transloco, value: false }
                ]"
                [control]="formGroup.controls.isRemote"
                [value]="formGroup.controls.isRemote.value"
                class="flex items-center gap-2 mt-1"
                (valueChange)="isRemoteChanged($event)"
            ></bslk-classic-toggle>
        </div>

        <!-- Copy Association Location -->
        <ng-container *ngIf="isOnSite">
            <div
                *ngrxLet="hasSomeLocationSet$ as hasSomeLocationSet"
                [matTooltip]="
                    'createmission.copy-location-disabled' | transloco
                "
                [matTooltipDisabled]="hasSomeLocationSet"
            >
                <mat-checkbox
                    [subscriptSizing]="'dynamic'"
                    *ngrxLet="hasSomeLocationSet$ as hasSomeLocationSet"
                    [disabled]="!hasSomeLocationSet"
                    (change)="copyLocation($event)"
                    color="primary"
                >
                    <span class="font-semibold">{{
                        "createmission.copy-location" | transloco
                    }}</span>
                </mat-checkbox>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="isOnSite">
        <div class="mt-1 mb-3 border-t"></div>

        <location-selection
            class="pb-2"
            [formGroup]="formGroup.get('location')"
        ></location-selection>
    </ng-container>
</div>
