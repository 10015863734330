import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { uniqBy } from 'lodash-es';
import { ChatActions } from './chat.action';
import { ChatState } from './chat.state';
export const chatInitialState: ChatState = {
    chatPageIndex: 0,
    chats: [],
    chatTotalPages: 1,
    filteredChats: [],
    isLoading: false,
    selectedChatMessages: [],
    selectedChatMessagesPageIndex: 0,
    selectedChatMessagesToTalPages: 1,
};

export const chatReducer = createReducer(
    chatInitialState,

    on(ChatActions.loadChatsRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(ChatActions.loadChatsRequestSuccess, (state, { response }) => {
        return {
            ...state,
            isLoading: false,
            chatTotalPages: response.totalPages,
            chatPageIndex: response.pageIndex,
            chats: uniqBy(
                [...structuredClone(state.chats), ...response.data],
                'id'
            ),
        };
    }),

    on(ChatActions.loadChatsRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            error,
        };
    }),

    on(ChatActions.searchChatRequest, (state, { search }) => {
        return {
            ...state,
            isLoading: true,
            searchChat: search,
        };
    }),

    on(ChatActions.searchChatRequestSuccess, (state, { response }) => {
        return {
            ...state,
            isLoading: false,
            filteredChats: response.data,
        };
    }),

    on(ChatActions.searchChatRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            error,
        };
    }),

    on(ChatActions.selectChat, (state, { chatId }) =>
        produce(state, (draftState) => {
            draftState.selectedChatId = chatId;
            (draftState.selectedChatMessages = []),
                (draftState.selectedChatMessagesPageIndex = 0);
            draftState.selectedChatMessagesToTalPages = 0;
        })
    ),

    on(ChatActions.acknowledgeSelectedChat, (state) =>
        produce(state, (draftState) => {
            const chat = draftState.chats.find(
                (c) => c.id === state.selectedChatId
            );
            if (chat) {
                chat.isReaden = true;
            }
        })
    ),

    on(ChatActions.unselectChat, (state) => {
        return {
            ...state,
            selectedChatId: undefined,
        };
    }),

    on(ChatActions.loadChatMessagesRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(ChatActions.loadMoreChatMessages, (state) => {
        return {
            ...state,
            selectedChatMessagesPageIndex:
                state.selectedChatMessagesPageIndex + 1,
        };
    }),

    on(ChatActions.loadChatMessagesRequestSuccess, (state, { response }) => {
        return {
            ...state,
            isLoading: false,
            selectedChatMessages: uniqBy(
                [...response.data, ...state.selectedChatMessages],
                'id'
            ),
            selectedChatMessagesPageIndex: response.pageIndex,
            selectedChatMessagesToTalPages: response.totalPages,
        };
    }),

    on(ChatActions.loadChatMessagesRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            error,
        };
    }),

    on(ChatActions.sendMessageRequest, (state, { message }) =>
        produce(state, (draftState) => {
            draftState.selectedChatMessages.push(message);
            const chat = draftState.chats.find(
                (c) => c.id === state.selectedChatId
            );
            if (chat) {
                chat.lastMessage = message.text;
            }
        })
    ),

    on(ChatActions.sendMessageRequestSuccess, (state, { id }) =>
        produce(state, (draftState) => {
            const message = draftState.selectedChatMessages.find(
                (c) => c.id === 0
            );
            if (message) {
                message.id = id;
            }
        })
    ),

    on(ChatActions.createChatRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(ChatActions.createChatRequestSuccess, (state, { chatViewModel }) => {
        const createdChat = structuredClone(
            state.chats.find((c) => c.id === 0)
        );
        createdChat.id = chatViewModel.id;
        // Replace created chat with correct id
        const chats = structuredClone(state.chats.filter((c) => c.id !== 0));
        chats.push(createdChat);
        // Replace first message with correct id to be in sync
        const firstMessage = structuredClone(state.selectedChatMessages.at(0));
        firstMessage.id = chatViewModel.messages.at(0).id;
        return {
            ...state,
            isLoading: false,
            selectedChatId: chatViewModel.id,
            selectedChatMessages: [firstMessage],
            chats,
        };
    }),

    on(ChatActions.createChatRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            error,
        };
    }),

    on(ChatActions.buildChat, (state) => {
        const chats = structuredClone(state.chats.filter((c) => c.id !== 0));
        return {
            ...state,
            chats,
        };
    }),

    on(ChatActions.buildChatSuccess, (state, { chat }) => {
        const chats = structuredClone(state.chats);
        chats.push(chat);
        return {
            ...state,
            chats,
            selectedChatId: 0,
            selectedChatMessages: [],
            selectedChatMessagesPageIndex: 0,
            selectedChatMessagesToTalPages: 1,
        };
    }),

    on(ChatActions.redirectToChat, (state, { id }) => {
        return {
            ...state,
            selectedChatId: id,
        };
    })
);
