import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BslkChoiceCardComponent } from '@bslk/components/cards/choice/choice-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { DialogActions } from 'app/store/dialog/dialog.action';
import { MissionActions } from 'app/store/mission/mission/mission.action';
import { ProjectActions } from 'app/store/project/project/project.action';

@Component({
    selector: 'mission-or-project',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './mission-or-project.component.html',
    imports: [
        MatDialogModule,
        TranslocoModule,
        MatIconModule,
        BaseDialogComponent,
        BslkChoiceCardComponent,
    ],
})
export class MissionOrProjectDialogComponent {
    constructor(private store: Store) {}

    get closeAction() {
        return DialogActions.closeMissionOrProjectDialog();
    }

    openMissionDialog() {
        this.closeMissionOrProjectDialog();
        this.store.dispatch(
            MissionActions.openDialog({
                input: { isEdit: false, isFromEntityPage: false },
            })
        );
    }

    openProjectDialog() {
        this.closeMissionOrProjectDialog();
        this.store.dispatch(ProjectActions.loadCanCreateProjectRequest());
        // this.store.dispatch(
        //     ProjectActions.openDialog({
        //         input: { isEdit: false, isFromEntityPage: false },
        //     })
        // );
    }

    closeMissionOrProjectDialog() {
        this.store.dispatch(DialogActions.closeMissionOrProjectDialog());
    }
}
