import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BadgeViewModel } from 'app/api';

export const BadgeActions = createActionGroup({
    source: 'Badge',
    events: {
        'Load Badges Request': emptyProps(),
        'Load Badges Request Success': props<{ badges: BadgeViewModel[] }>(),
        'Load Badges Request Fail': props<{ error: string }>(),
    },
});
