import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { MissionUnregisteredViewModel } from 'app/api';
import { BslkTagComponent } from '../../../../../@bslk/components/tag/tag.component';

@Component({
    selector: 'mission-availability',
    standalone: true,
    templateUrl: './mission-availability.component.html',
    imports: [BslkTagComponent, NgIf, TranslocoModule],
})
export class MissionAvailabilityComponent {
    @Input() mission: MissionUnregisteredViewModel;
}
