<div
    *ngrxLet="hasActiveCampaign$ as hasActiveCampaign"
    class="p-4 md:p-6 xxl:p-8 w-full"
>
    <div class="flex justify-between mb-6">
        <div class="flex flex-col gap-1">
            <span class="text-3xl font-bold">{{
                "courses.campaign-creation.title" | transloco
            }}</span>
            <span class="text-lg">{{
                "courses.campaign-creation.subtitle" | transloco
            }}</span>
        </div>

        <bslk-classic-button
            *ngIf="!hasActiveCampaign"
            [isDisabled]="disableSaveButton"
            (onClick)="validateCampaign()"
        >
            {{
                "courses.campaign-creation.validate" | transloco
            }}</bslk-classic-button
        >
        <bslk-classic-button
            *ngIf="hasActiveCampaign"
            [isDisabled]="disableSaveButton"
            (onClick)="saveCampaign()"
        >
            {{
                "courses.campaign-creation.save" | transloco
            }}</bslk-classic-button
        >
    </div>

    <div class="grid grid-cols-12 gap-4">
        <awareness-campaign-configuration
            [showErrors]="disableSaveButton"
            class="col-span-12 md:col-span-6"
        ></awareness-campaign-configuration>
        <awareness-campaign-thematics-list
            class="col-span-12 md:col-span-6"
        ></awareness-campaign-thematics-list>
    </div>
</div>
