import { createSelector } from '@ngrx/store';
import { AppState } from '..';

const selectState = (state: AppState) => state.validation;

const selectEmail = createSelector(selectState, (state) => state.email);

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectStepIndex = createSelector(selectState, (state) => state.stepIndex);

const selectEmailError = createSelector(
    selectState,
    (state) => state.emailError
);

const selectCodeError = createSelector(selectState, (state) => state.codeError);

const selectIsEmailAlreadyValidated = createSelector(
    selectCodeError,
    (codeError) => codeError === 'EmailAlreadyValidated'
);

const selectPasswordError = createSelector(
    selectState,
    (state) => state.passwordError
);

export const ValidationSelectors = {
    selectState,
    selectEmail,
    selectIsLoading,
    selectEmailError,
    selectCodeError,
    selectPasswordError,
    selectStepIndex,
    selectIsEmailAlreadyValidated,
};
