/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EUnit = 'None' | 'CubicMeter' | 'Day' | 'Dollar' | 'Euro' | 'Gigawatt' | 'Hectare' | 'Hour' | 'Kg' | 'Kilometer' | 'Kilowatt' | 'Liter' | 'Meter' | 'Percentage' | 'SquareKilometer' | 'Country' | 'Project';

export const EUnit = {
    None: 'None' as EUnit,
    CubicMeter: 'CubicMeter' as EUnit,
    Day: 'Day' as EUnit,
    Dollar: 'Dollar' as EUnit,
    Euro: 'Euro' as EUnit,
    Gigawatt: 'Gigawatt' as EUnit,
    Hectare: 'Hectare' as EUnit,
    Hour: 'Hour' as EUnit,
    Kg: 'Kg' as EUnit,
    Kilometer: 'Kilometer' as EUnit,
    Kilowatt: 'Kilowatt' as EUnit,
    Liter: 'Liter' as EUnit,
    Meter: 'Meter' as EUnit,
    Percentage: 'Percentage' as EUnit,
    SquareKilometer: 'SquareKilometer' as EUnit,
    Country: 'Country' as EUnit,
    Project: 'Project' as EUnit
};

