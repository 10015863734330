<button
    *ngIf="label"
    [matMenuTriggerFor]="menu"
    class="flex bg-warn items-center mt-3 mx-auto text-white"
    mat-flat-button
>
    <mat-icon class="icon-size-5 text-white" [svgIcon]="icon"> </mat-icon>
    <span class="ml-2 text-white">{{ label }}</span>
</button>
<mat-icon
    *ngIf="!label"
    [matMenuTriggerFor]="menu"
    [svgIcon]="icon"
    [class.icon-size-10]="iconSize === 'large'"
    class="cursor-pointer hover:text-warn duration-200 hover:scale-110"
></mat-icon>

<mat-menu #menu="matMenu">
    <ng-container *ngFor="let item of items">
        <div *ngIf="!item.hide">
            <a
                *ngIf="item.routerLink"
                mat-menu-item
                [routerLink]="item.routerLink"
            >
                <mat-icon
                    *ngIf="item.icon"
                    class="icon-size-5 text-warn"
                    [svgIcon]="item.icon"
                >
                </mat-icon>
                <span [class.ml-2]="item.icon">{{ item.label }}</span>
            </a>

            <button
                *ngIf="item.action"
                (click)="onClick(item)"
                class="flex items-center mx-auto"
                mat-menu-item
            >
                <mat-icon
                    *ngIf="item.icon"
                    class="icon-size-5 text-warn"
                    [svgIcon]="item.icon"
                >
                </mat-icon>
                <span [class.ml-2]="item.icon">{{ item.label }}</span>
            </button>
        </div>
    </ng-container>
</mat-menu>
