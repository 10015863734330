<div
    class="create-donation-campaign flex flex-col max-h-[95vh] min-w-[90vw] max-w-[95vw] -m-6"
>
    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-white"
    >
        <div class="text-lg font-medium">
            {{
                "donationcampaigns." + (this.data.isEdit ? "update" : "create")
                    | transloco
            }}
        </div>
        <button (click)="close()" mat-icon-button [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x-mark'"
            ></mat-icon>
        </button>
    </div>

    <!-- Body -->
    <div
        class="px-6 py-3 flex flex-col items-center justify-center overflow-y-scroll"
    >
        <form
            #formDiv
            class="w-full overflow-y-scroll scroll-smooth"
            [formGroup]="formGroup"
        >
            <mat-horizontal-stepper #stepper linear>
                <!-- Step 1 -->
                <mat-step [completed]="isStepValid(0)">
                    <ng-template matStepLabel>{{
                        "donationcampaigns.step-1" | transloco
                    }}</ng-template>
                    <div class="mt-2">
                        <create-donation-campaign-step-0></create-donation-campaign-step-0>
                    </div>
                </mat-step>

                <!-- Step 2 -->
                <mat-step [completed]="isStepValid(1)">
                    <ng-template matStepLabel>{{
                        "donationcampaigns.step-2" | transloco
                    }}</ng-template>
                    <create-donation-campaign-step-1></create-donation-campaign-step-1>
                </mat-step>

                <!-- Step 3 -->
                <mat-step [completed]="isStepValid(2)">
                    <ng-template matStepLabel>{{
                        "donationcampaigns.step-3" | transloco
                    }}</ng-template>
                    <create-donation-campaign-step-2></create-donation-campaign-step-2>
                </mat-step>
            </mat-horizontal-stepper>
        </form>
    </div>
    <!-- Errors summary -->

    <div class="flex justify-center">
        <fuse-alert
            *ngIf="displayErrorsSummary"
            [appearance]="'outline'"
            [showIcon]="true"
            [type]="'error'"
            [@shake]="true"
        >
            <ul class="ml-2">
                <li *ngFor="let message of getCurrentStepErrors">
                    {{ message }}
                </li>
            </ul>
        </fuse-alert>
    </div>

    <!-- Actions -->

    <div class="flex p-4 justify-end bg-gray-50">
        <!-- Discard -->
        <button
            class="mr-3"
            (click)="previousStep()"
            *ngIf="hasPreviousStep"
            mat-stroked-button
        >
            {{ "donationcampaigns.previous-step" | transloco }}
        </button>
        <!-- Send -->
        <button
            *ngrxLet="isCreatingLoading$ as isCreatingLoading"
            class="text-accent"
            [disabled]="
                (displayErrorsSummary && !isCurrentStepValid) ||
                isCreatingLoading
            "
            (click)="continue()"
            mat-flat-button
            [color]="'primary'"
            type="submit"
        >
            <div class="flex items-center">
                <mat-progress-spinner
                    *ngIf="isCreatingLoading"
                    class="mr-3"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                ></mat-progress-spinner>
                <ng-container [ngSwitch]="stepper.selectedIndex">
                    <span *ngSwitchCase="0">
                        {{ "donationcampaigns.step-1-next" | transloco }}
                    </span>
                    <span *ngSwitchCase="1">
                        {{ "donationcampaigns.step-2-next" | transloco }}
                    </span>
                    <span *ngSwitchCase="2">
                        {{ "donationcampaigns.step-3-next" | transloco }}
                    </span>
                </ng-container>
            </div>
        </button>
    </div>
</div>
