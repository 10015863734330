import {
    AsyncPipe,
    JsonPipe,
    KeyValuePipe,
    NgFor,
    NgIf,
} from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EExportType } from 'app/api';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { CompanyAdminDashboardActions } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.actions';
import { CompanyAdminDashboardSelectors } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.selectors';

@Component({
    selector: 'company-admin-dashboard-export-dialog',
    templateUrl: './export-dialog.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BaseDialogComponent,
        BslkClassicButtonComponent,
        BslkClassicToggleComponent,
        JsonPipe,
        KeyValuePipe,
        LetDirective,
        MatButtonModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatIconModule,
        MatTabsModule,
        NgFor,
        NgIf,
        TranslocoModule,
    ],
})
export class CompanyAdminDashboardExportDialogComponent {
    EExportType = EExportType;

    exportConfigurationFormGroup = this.fb.group({
        exportType: [EExportType.Csv],
        exportMissions: [true],
        exportMissionFeedbacks: [true],
        exportAwarenessCompletion: [true],
        exportDonationCampaigns: [true],
        exportDonationCampaignParticipations: [true],
    });

    isLoading$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsLoadingExport
    );

    constructor(
        private store: Store,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<CompanyAdminDashboardExportDialogComponent>
    ) {}

    close() {
        this.dialogRef.close();
    }

    export() {
        this.store.dispatch(
            CompanyAdminDashboardActions.exportDataRequest({
                config: this.exportConfigurationFormGroup.value,
            })
        );
    }
}
