import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { CourseSectionViewModel } from 'app/api';
import { OrderByValueDescPipe } from 'app/shared/pipes/order-by-value-desc.pipe';
import { CourseActions } from 'app/store/course/course/course.action';
import { CourseSelectors } from 'app/store/course/course/course.selectors';
import { NextChapterSectionButtonComponent } from '../../next-button/next-button.component';

@Component({
    selector: 'survey-component',
    templateUrl: './survey.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        FormsModule,
        LetDirective,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NextChapterSectionButtonComponent,
        NextChapterSectionButtonComponent,
        NgFor,
        NgIf,
        OrderByValueDescPipe,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class SectionSurveyComponent {
    @Input() section: CourseSectionViewModel;

    userGuess: string = '';
    correctAnswersGuessedIds = [];
    triesLeft = 3;

    isLastSection$ = this.store.select(
        CourseSelectors.selectIsActiveChapterSectionLastOfChapter
    );

    hasAlreadyCompletedSurvey$ = this.store.select(
        CourseSelectors.selectHasAlreadyCompletedSection
    );

    constructor(private store: Store) {}

    get isDone() {
        return (
            this.triesLeft === 0 ||
            this.correctAnswersGuessedIds.length ===
                this.section.survey.answers.length
        );
    }

    validate() {
        if (this.userGuess.length < 1) {
            return;
        }
        const correctAnswersLeft = this.section.survey.answers.filter(
            (a) => !this.correctAnswersGuessedIds.includes(a.id)
        );
        let foundMatch = false;

        for (const answer of correctAnswersLeft) {
            for (const synonym of answer.synonyms) {
                if (this.levenshteinDistance(synonym, this.userGuess) <= 3) {
                    this.correctAnswersGuessedIds.push(answer.id);
                    foundMatch = true;
                    this.store.dispatch(
                        CourseActions.sendSurveyAnswerRequest({
                            sectionId: this.section.id,
                            answer: this.userGuess,
                            hasFoundAllAnswers:
                                this.correctAnswersGuessedIds.length ===
                                this.section.survey.answers.length,
                        })
                    );
                    break;
                }
            }
        }

        if (!foundMatch) {
            this.triesLeft--;
            this.store.dispatch(
                CourseActions.sendSurveyAnswerRequest({
                    sectionId: this.section.id,
                    answer: this.userGuess,
                    hasFoundAllAnswers: false,
                })
            );
        }

        this.userGuess = '';
    }

    levenshteinDistance(a: string, b: string): number {
        const matrix = [];

        for (let i = 0; i <= b.length; i++) {
            matrix[i] = [i];
        }

        for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
        }

        for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
                if (b.charAt(i - 1) === a.charAt(j - 1)) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                } else {
                    matrix[i][j] = Math.min(
                        matrix[i - 1][j - 1] + 1,
                        matrix[i][j - 1] + 1,
                        matrix[i - 1][j] + 1
                    );
                }
            }
        }

        return matrix[b.length][a.length];
    }

    nextSection(isLastSection: boolean) {
        this.store.dispatch(CourseActions.nextSection({ isLastSection }));
    }
}
