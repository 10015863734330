import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BslkColor, BslkSize } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';
import { Action, Store } from '@ngrx/store';

@Component({
    selector: 'base-dialog',
    standalone: true,
    styleUrls: ['./base-dialog.component.scss'],
    templateUrl: './base-dialog.component.html',
    imports: [MatDialogModule, TranslocoModule, MatIconModule, NgIf, NgClass],
})
export class BaseDialogComponent {
    @Input() title: string;
    @Input() closeAction: Action;
    @Input() showHeader = true;
    @Input() showActions = true;
    @Input() showFireworks = false;
    @Input() hideBodyPadding = false;
    @Input() scrollablePadding = false;
    @Input() size: BslkSize = 'medium';
    @Input() color: BslkColor = 'primary';

    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

    constructor(private store: Store) {}

    close() {
        this.onClose.emit();

        if (this.closeAction) {
            this.store.dispatch(this.closeAction);
        }
    }
}
