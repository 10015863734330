import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DemonstrationActions } from 'app/store/demonstration/demonstration.action';
import { DemonstrationSelectors } from 'app/store/demonstration/demonstration.selectors';

@Component({
    selector: 'demonstration',
    standalone: true,
    templateUrl: './demonstration.component.html',
    imports: [
        AsyncPipe,
        LetDirective,
        NgFor,
        BslkClassicButtonComponent,
        MatIconModule,
        FuseAlertComponent,
        NgClass,
        NgIf,
    ],
})
export class DemonstrationComponent implements OnInit {
    steps$ = this.store.select(DemonstrationSelectors.selectSteps);
    lastExecutedStep$ = this.store.select(
        DemonstrationSelectors.selectLastExecutedStep
    );
    isLoading$ = this.store.select(DemonstrationSelectors.selectIsLoading);
    error$ = this.store.select(DemonstrationSelectors.selectError);

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(DemonstrationActions.loadStepsRequest());
    }

    executeStep(step: number): void {
        this.store.dispatch(DemonstrationActions.executeStepRequest({ step }));
    }
}
