<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [transparentOverlay]="false"
    [name]="'drawer'"
    [position]="'right'"
    #drawer
>
    <div class="flex flex-col w-full bg-card">
        <div
            class="flex gap-2 flex-row items-center px-6 py-3 text-white bg-tertiary"
        >
            <mat-icon
                class="icon-size-6 text-current"
                [svgIcon]="'feather:map-pin'"
            ></mat-icon>
            <div class="ml-3 text-2xl font-semibold tracking-tight">
                {{ "projects.zones-actions" | transloco }}
            </div>
            <button
                class="flex ml-auto"
                mat-icon-button
                (click)="drawer.close()"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"
                ></mat-icon>
            </button>
        </div>

        <div class="flex flex-col gap-4 p-4 overflow-auto">
            <div
                *ngrxLet="filteredLocations$ as filteredLocations"
                class="flex flex-col gap-2"
            >
                <span class="font-bold text-lg">{{
                    "projects.filtered-action-zones" | transloco
                }}</span>
                <div class="flex flex-wrap gap-2">
                    <bslk-tag
                        *ngFor="let location of filteredLocations"
                        (click)="removeLocation(location)"
                        [isRemovable]="true"
                        [background]="'tertiary'"
                        >{{ location }}</bslk-tag
                    >
                </div>
                <span
                    class="flex items-center gap-2"
                    *ngIf="!filteredLocations || filteredLocations.length === 0"
                >
                    <mat-icon
                        class="text-current"
                        [svgIcon]="'feather:filter'"
                    ></mat-icon>
                    <span>{{
                        "projects.no-selected-action-zones" | transloco
                    }}</span>
                </span>
            </div>

            <div class="my-2 border-b"></div>

            <div class="my-auto flex flex-wrap gap-x-2 gap-y-1">
                <bslk-tag
                    class="cursor-pointer"
                    (click)="addLocation(location)"
                    [background]="'tertiary'"
                    [size]="'small'"
                    *ngFor="let location of availableLocations$ | async"
                    >{{ location }}</bslk-tag
                >
            </div>
        </div>
    </div>
</fuse-drawer>
