import { FormGroup } from '@angular/forms';
import { EMissionRecurrenceType } from 'app/api/model/eMissionRecurrenceType';
import { ETimePeriod } from 'app/api/model/eTimePeriod';
import { MissionSlotViewModel } from 'app/api/model/missionSlotViewModel';
import { DateTime } from 'luxon';
import { Subject, takeUntil } from 'rxjs';
import { SLOT_DEFAULT_HOURS } from '../../constants.utils';
import { computeWorkingHoursBetweenDates } from '../../date-helpers.utils';
import { generateGUID } from '../../guid.utils';

type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export class MissionSlotUtils {
    static generateRecurrentSlots(
        missionSlot: MissionSlotViewModel
    ): MissionSlotViewModel[] {
        const slots: MissionSlotViewModel[] = [];
        let startDate = new Date(missionSlot.startDate);
        const originalStartDayOfWeek = startDate.getDay() as DayOfWeek;
        const endDate = new Date(missionSlot.endDate);

        // Keep same start and end hours always (time change constraint)
        const startHours = startDate.getHours();
        const endHours = endDate.getHours();

        if (missionSlot.recurrenceType !== EMissionRecurrenceType.None) {
            const duration = endDate.getTime() - startDate.getTime();
            const oneYearLater = new Date(startDate.getTime());
            oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
            let interval = this.getInterval(
                missionSlot,
                originalStartDayOfWeek
            );
            startDate = new Date(startDate.getTime() + interval);
            while (startDate < oneYearLater) {
                // Calculate the end date for the current slot based on the duration
                const slotEndDate = new Date(startDate.getTime() + duration);
                slotEndDate.setHours(endHours);

                // Check if the current slot's end date surpasses the two-year limit
                if (slotEndDate > oneYearLater) {
                    break; // Exit the loop if the slot ends beyond the two-year limit
                }

                if (
                    missionSlot.recurrenceType === EMissionRecurrenceType.Daily
                ) {
                    // For daily recurrences, ensure the slot does not overlap into weekends if applicable
                    if (startDate.getDay() !== 6 && startDate.getDay() !== 0) {
                        slots.push(
                            MissionSlotUtils.createSlot(
                                missionSlot,
                                startDate,
                                slotEndDate
                            )
                        );
                    }
                    // Adjust the start date for the next iteration to ensure it's always moving forward
                } else {
                    // For other recurrences, adjust the start date based on the recurrence interval
                    slots.push(
                        MissionSlotUtils.createSlot(
                            missionSlot,
                            startDate,
                            slotEndDate
                        )
                    );
                }
                const interval = this.getInterval(
                    missionSlot,
                    originalStartDayOfWeek
                );
                startDate = new Date(startDate.getTime() + interval);
                startDate.setHours(startHours);
            }
        }
        return slots;
    }

    private static getInterval(
        missionSlot: MissionSlotViewModel,
        startDayOfWeek: DayOfWeek
    ): number {
        let daysToAdd: number = this.calculateBaseDaysToAdd(missionSlot);

        // Initial base interval in milliseconds
        let baseInterval = daysToAdd * 24 * 60 * 60 * 1000;

        // If recurrence is by day, logic is simple, we simply roll over x days
        if (
            missionSlot.recurrenceType === EMissionRecurrenceType.Daily ||
            (missionSlot.recurrenceType === EMissionRecurrenceType.Custom &&
                missionSlot.customRecurrenceType === ETimePeriod.Day)
        ) {
            return baseInterval;
        }

        let initialTargetDate = new Date(
            new Date(missionSlot.startDate).getTime() + baseInterval
        );

        // Adjust for the closest day of the week
        let adjustedTargetDate = this.adjustDateToClosestWeekday(
            initialTargetDate,
            startDayOfWeek
        );

        // Calculate final interval
        return (
            adjustedTargetDate.getTime() -
            new Date(missionSlot.startDate).getTime()
        );
    }

    private static adjustDateToClosestWeekday(
        targetDate: Date,
        desiredDayOfWeek: DayOfWeek
    ): Date {
        let dayDifference = desiredDayOfWeek - targetDate.getDay();
        let daysUntilNextDesiredDay = (7 + dayDifference) % 7;
        let daysSinceLastDesiredDay = (7 - daysUntilNextDesiredDay) % 7;

        // Calculate both potential dates
        let nextDesiredDate = new Date(
            targetDate.getTime() + daysUntilNextDesiredDay * 24 * 60 * 60 * 1000
        );
        let previousDesiredDate = new Date(
            targetDate.getTime() - daysSinceLastDesiredDay * 24 * 60 * 60 * 1000
        );

        // Determine which date is closer to the target date
        let isPreviousCloser =
            Math.abs(previousDesiredDate.getTime() - targetDate.getTime()) <
            Math.abs(nextDesiredDate.getTime() - targetDate.getTime());

        return isPreviousCloser ? previousDesiredDate : nextDesiredDate;
    }

    private static calculateBaseDaysToAdd(
        missionSlot: MissionSlotViewModel
    ): number {
        switch (missionSlot.recurrenceType) {
            case EMissionRecurrenceType.Daily:
                return 1;
            case EMissionRecurrenceType.Weekly:
                return 7;
            case EMissionRecurrenceType.BiMonthly:
                return 15;
            case EMissionRecurrenceType.Monthly:
                return 30;
            case EMissionRecurrenceType.Quaterly:
                return 91; // Approximate quarter of a year
            case EMissionRecurrenceType.BiAnnual:
                return 182; // Approximate half a year
            case EMissionRecurrenceType.Custom:
                if (
                    missionSlot.customRecurrenceUnit === undefined ||
                    missionSlot.customRecurrenceType === undefined
                ) {
                    throw new Error(
                        'Invalid recurrence configuration for Custom type'
                    );
                }
                return this.calculateDaysForCustomInterval(
                    missionSlot.customRecurrenceUnit,
                    missionSlot.customRecurrenceType
                );
            default:
                throw new Error('Invalid recurrence configuration');
        }
    }

    private static calculateDaysForCustomInterval(
        unit: number,
        type: ETimePeriod
    ): number {
        switch (type) {
            case ETimePeriod.Day:
                return unit;
            case ETimePeriod.Week:
                return unit * 7;
            case ETimePeriod.Month:
                return unit * 30;
            default:
                throw new Error('Invalid custom time period');
        }
    }

    private static createSlot(
        missionSlot: MissionSlotViewModel,
        startDate: Date,
        endDate: Date
    ) {
        return {
            ...missionSlot,
            id: 0,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            parentGuid: missionSlot.guid,
            guid: generateGUID(),
        };
    }

    static subscribeToFormGroupValueChanges(
        missionSlotFormGroup: FormGroup,
        unsubscribeAll: Subject<any>
    ) {
        missionSlotFormGroup.controls.startTime.valueChanges
            .pipe(takeUntil(unsubscribeAll))
            .subscribe((value) => {
                const [hours, minutes] = value.split(':').map(Number);
                const startDateFormControl =
                    missionSlotFormGroup.controls.startDate;
                startDateFormControl.setValue(
                    (startDateFormControl.value as DateTime).set({
                        hour: hours,
                        minute: minutes,
                    })
                );
                MissionSlotUtils.computeEstimatedTimeInHours(
                    missionSlotFormGroup
                );
            });

        // Update end time on start date
        missionSlotFormGroup.controls.endTime.valueChanges
            .pipe(takeUntil(unsubscribeAll))
            .subscribe((value) => {
                const [hours, minutes] = value.split(':').map(Number);
                const endDateFormControl =
                    missionSlotFormGroup.controls.endDate;
                endDateFormControl.setValue(
                    (endDateFormControl.value as DateTime).set({
                        hour: hours,
                        minute: minutes,
                    })
                );
                MissionSlotUtils.computeEstimatedTimeInHours(
                    missionSlotFormGroup
                );
            });

        // Update start day on start date
        missionSlotFormGroup.controls.startDay.valueChanges
            .pipe(takeUntil(unsubscribeAll))
            .subscribe((value: DateTime) => {
                missionSlotFormGroup.controls.startDate.setValue(
                    value.set({
                        year: value.year,
                        month: value.month,
                        day: value.day,
                        hour: SLOT_DEFAULT_HOURS.START,
                    })
                );
                missionSlotFormGroup.controls.startTime.setValue(
                    SLOT_DEFAULT_HOURS.START_AS_STRING,
                    { emitEvent: false }
                );
                MissionSlotUtils.computeEstimatedTimeInHours(
                    missionSlotFormGroup
                );
            });

        // Update end day on end date
        missionSlotFormGroup.controls.endDay.valueChanges
            .pipe(takeUntil(unsubscribeAll))
            .subscribe((value: DateTime) => {
                missionSlotFormGroup.controls.endDate.setValue(
                    value.set({
                        year: value.year,
                        month: value.month,
                        day: value.day,
                        hour: SLOT_DEFAULT_HOURS.END,
                    })
                );
                missionSlotFormGroup.controls.endTime.setValue(
                    SLOT_DEFAULT_HOURS.END_AS_STRING,
                    { emitEvent: false }
                );
                MissionSlotUtils.computeEstimatedTimeInHours(
                    missionSlotFormGroup
                );
            });
    }

    static computeEstimatedTimeInHours(missionSlotFormGroup: FormGroup) {
        const startDate = missionSlotFormGroup.controls.startDate
            .value as DateTime;
        const endDate = missionSlotFormGroup.controls.endDate.value as DateTime;
        const estimatedTimeInHours = computeWorkingHoursBetweenDates(
            startDate,
            endDate
        );
        missionSlotFormGroup.controls.estimatedTimeInHours.setValue(
            estimatedTimeInHours
        );
    }
}
