import {
    GoogleLoginProvider,
    SocialAuthServiceConfig,
} from '@abacritt/angularx-Social-login';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
    ENVIRONMENT_INITIALIZER,
    EnvironmentProviders,
    Provider,
    inject,
} from '@angular/core';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptorConfiguration,
    MsalService,
} from '@azure/msal-angular';
import {
    BrowserCacheLocation,
    IPublicClientApplication,
    InteractionType,
    LogLevel,
    PublicClientApplication,
} from '@azure/msal-browser';
import { fuseLoadingInterceptor } from '@fuse/services/loading';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';
import { translocoLanguageInterceptor } from '../transloco/transloco-language.interceptor';
import { authInterceptor } from './interceptors/auth.interceptor';
import { LinkedInLoginProvider } from './providers/linkedin-login.provider';

export const provideAuth = (): Array<Provider | EnvironmentProviders> => {
    return [
        {
            provide: ENVIRONMENT_INITIALIZER,
            useValue: () => inject(AuthService),
            multi: true,
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            environment.googleClientId,
                            {
                                scopes: ['email'],
                                oneTapEnabled: false,
                            }
                        ),
                    },
                    {
                        id: LinkedInLoginProvider.PROVIDER_ID,
                        provider: LinkedInLoginProvider,
                    },
                ],
                onError: (err) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        provideHttpClient(
            withInterceptors([
                fuseLoadingInterceptor,
                authInterceptor,
                translocoLanguageInterceptor,
            ])
        ),
    ];
};

function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.microsoftClientId,
            authority: `https://login.microsoftonline.com/common`,
            redirectUri: environment.redirectUri,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
        system: {
            allowNativeBroker: false, // Disables WAM Broker
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false,
            },
        },
    });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(`${environment.apiBaseUrl}/auth/microsoft`, [
        'openid',
        'profile',
        'email',
    ]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['openid', 'profile', 'email'],
        },
        loginFailedRoute: '/',
    };
}

function loggerCallback(logLevel: LogLevel, message: string) {
    if (!environment.production) {
        console.log(message);
    }
}
