/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EMissionTimelineEvent = 'Registration' | 'ManagerAcceptation' | 'AssociationAcceptation' | 'Start' | 'Rating' | 'UserValidation' | 'AssociationValidation';

export const EMissionTimelineEvent = {
    Registration: 'Registration' as EMissionTimelineEvent,
    ManagerAcceptation: 'ManagerAcceptation' as EMissionTimelineEvent,
    AssociationAcceptation: 'AssociationAcceptation' as EMissionTimelineEvent,
    Start: 'Start' as EMissionTimelineEvent,
    Rating: 'Rating' as EMissionTimelineEvent,
    UserValidation: 'UserValidation' as EMissionTimelineEvent,
    AssociationValidation: 'AssociationValidation' as EMissionTimelineEvent
};

