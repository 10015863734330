import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { BslkColor } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';
import { FormControlComponent } from '../../form-control-component';
export type BslkCardToggleComponentInput = {
    text: string;
    description: string;
    icon: string;
    value: any;
};

@Component({
    selector: 'bslk-card-toggle',
    standalone: true,
    templateUrl: './card-toggle.component.html',
    imports: [
        BslkTagComponent,
        BslkInfoLabelComponent,
        NgIf,
        MatButtonModule,
        NgFor,
        NgClass,
        MatTooltipModule,
        MatIconModule,
        TranslocoModule,
    ],
})
export class BslkCardToggleComponent
    extends FormControlComponent
    implements OnInit
{
    @Input() choices: BslkCardToggleComponentInput[];
    @Input() color: BslkColor = 'primary';
    @Input() hideSelectedTag = false;
    @Input() truncateDescription = false;
}
