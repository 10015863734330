<div
    *transloco="let t"
    class="w-full bg-white rounded-2xl shadow-lg p-4 md:p-6 xxl:p-8 flex flex-col gap-3"
>
    <div class="flex flex-col gap-2">
        <fuse-alert *ngIf="showErrors && formGroup.invalid" [type]="'error'">
            <div class="font-bold" fuseTitle>
                {{ "courses.campaign-creation.has-errors" | transloco }}
            </div>
            <ul class="ml-6">
                <li *ngIf="formGroup.controls.name.invalid">
                    {{ "courses.campaign-creation.name-required" | transloco }}
                </li>
                <li
                    *ngFor="
                        let invalidThematic of getInvalidThematicsControlIndices(
                            'thematic'
                        )
                    "
                >
                    {{
                        t("courses.campaign-creation.empty-thematic", {
                            number: invalidThematic + 1
                        })
                    }}
                </li>
                <li
                    *ngFor="
                        let invalidThematic of getInvalidThematicsControlIndices(
                            'startDate'
                        )
                    "
                >
                    {{
                        t("courses.campaign-creation.missing-start-date", {
                            number: invalidThematic + 1
                        })
                    }}
                </li>
                <li
                    *ngFor="
                        let invalidThematic of getInvalidThematicsControlIndices(
                            'endDate'
                        )
                    "
                >
                    {{
                        t("courses.campaign-creation.missing-end-date", {
                            number: invalidThematic + 1
                        })
                    }}
                </li>
            </ul>
        </fuse-alert>

        <div class="flex gap-3 items-center">
            <span class="font-bold text-lg whitespace-nowrap">{{
                "courses.campaign-creation.campaign-title" | transloco
            }}</span>
            <mat-form-field
                [subscriptSizing]="'dynamic'"
                [floatLabel]="'always'"
                class="w-full"
            >
                <input
                    [placeholder]="
                        'courses.campaign-creation.campaign-title-placeholder'
                            | transloco
                    "
                    matInput
                    [formControl]="formGroup.controls.name"
                    required
                />
            </mat-form-field>
        </div>
        <div
            *ngIf="!(isLoading$ | async); else loading"
            class="flex flex-col gap-4 mt-5"
        >
            <awareness-campaign-thematic-configuration
                *ngFor="
                    let thematicFormGroup of thematicsFormArray.controls;
                    let index = index
                "
                [thematic]="thematicFormGroup.controls.thematic.value"
                [index]="index"
            ></awareness-campaign-thematic-configuration>
        </div>
        <div class="flex mt-3 justify-end">
            <bslk-classic-button
                (onClick)="addThematic()"
                [icon]="'heroicons_outline:plus'"
            >
                <span>{{
                    "courses.campaign-creation.add-thematic" | transloco
                }}</span>
            </bslk-classic-button>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="flex flex-col gap-4 mt-5">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-48"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-48"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
