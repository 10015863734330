<bslk-classic-card [title]="'missionmanage.mission-slots' | transloco">
    <mat-form-field
        *ngIf="showPickerDropdown$ | async; else noDropdown"
        subscriptSizing="dynamic"
        class="w-full"
    >
        <mat-icon
            class="icon-size-5"
            [svgIcon]="'heroicons_solid:calendar'"
            matPrefix
        ></mat-icon>
        <mat-select
            (valueChange)="onValueChange($event)"
            [value]="selectedSlotId$ | async"
            [placeholder]="
                'missionview.registration.select-slot-placeholder' | transloco
            "
        >
            <mat-option
                [value]="slot.id"
                *ngFor="let slot of missionSlots$ | async"
                [value]="slot.id"
            >
                {{ formatDateRange(slot) }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <ng-template #noDropdown>
        <div
            *ngIf="hasSelectedSlot$ | async"
            class="font-bold text-lg flex items-center"
        >
            <mat-icon
                class="icon-size-5 mr-1"
                [svgIcon]="'heroicons_solid:calendar'"
            >
            </mat-icon>
            {{ formatDateRange(selectedSlot$ | async) }}
        </div>

        <div *ngIf="!(hasAnySlot$ | async)">
            {{ "missionmanage.no-slot-yet" | transloco }}
        </div>
    </ng-template>
</bslk-classic-card>
