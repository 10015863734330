import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ETrackedActivityType } from 'app/api';
import { IDialogService } from 'app/shared/services/dialog-service';
import { TrackedActivityActions } from 'app/store/tracked-activity/tracked-activity.actions';
import {
    ChooseProjectDialogComponent,
    ChooseProjectDialogInput,
} from './choose-project-dialog.component';

@Injectable({ providedIn: 'root' })
export class ChooseProjectDialogService implements IDialogService {
    private dialogRef: MatDialogRef<ChooseProjectDialogComponent>;

    constructor(private dialog: MatDialog, private store: Store) {}

    open(input: ChooseProjectDialogInput) {
        this.store.dispatch(
            TrackedActivityActions.logActivityRequest({
                activity: {
                    trackedActivityType:
                        ETrackedActivityType.DonationGiftChooseDialog,
                },
            })
        );

        this.dialogRef = this.dialog.open(ChooseProjectDialogComponent, {
            data: input,
            maxWidth: '100vw',
            maxHeight: '100vh',
        });
    }

    close() {
        this.dialogRef.close();
    }
}
