<div
    *ngrxLet="isLoading$ as isLoading"
    class="w-full flex flex-col items-center justify-center p-8 gap-6"
>
    <div *ngrxLet="review$ as review" class="min-w-192">
        <div
            class="bg-white shadow-solid-tertiary rounded-xl p-8 flex flex-col gap-y-3"
        >
            <span class="text-center font-bold text-3xl mb-2">{{
                "coursesection.recap" | transloco
            }}</span>

            <div class="w-full grid grid-cols-2 gap-4">
                <!-- Ranking -->
                <div
                    *ngIf="!isLoading; else columnLoading"
                    class="bg-primary bg-opacity-20 py-4 px-10 rounded-xl col-span-1 flex flex-col justify-center gap-3 min-w-72"
                >
                    <span class="text-center text-primary-700 font-bold">{{
                        "coursesection.course-ranking" | transloco
                    }}</span>
                    <div
                        class="mx-8 mt-4 relative flex justify-center min-w-52"
                    >
                        <basile-avatar
                            [configuration]="basileConfig$ | async"
                            class="absolute top-26 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-shrink-0 w-40 h-40"
                        ></basile-avatar>
                        <div
                            class="absolute top-56 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-1 text-white"
                        >
                            <span class="font-bold text-2xl"
                                >#{{ review.rank }}</span
                            >
                            <span class=""
                                >{{ "coursesection.of" | transloco }}
                                {{ review.totalUsers }}</span
                            >
                        </div>
                        <svg
                            class="w-52"
                            viewBox="0 0 156 223"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M128.485 136.303C145.024 122.089 155.5 101.018 155.5 77.5C155.5 34.6979 120.802 0 78 0C35.1979 0 0.5 34.6979 0.5 77.5C0.5 101.011 10.9692 122.077 27.5 136.29V223L77.9924 199L128.485 223V136.303Z"
                                fill="white"
                            />
                            <path
                                d="M128.485 136.303C145.024 122.089 155.5 101.018 155.5 77.5C155.5 34.6979 120.802 0 78 0C35.1979 0 0.5 34.6979 0.5 77.5C0.5 101.011 10.9692 122.077 27.5 136.29V223L77.9924 199L128.485 223V136.303Z"
                                fill="url(#paint0_linear_3325_31355)"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear_3325_31355"
                                    x1="156"
                                    y1="4.79751e-06"
                                    x2="0.999987"
                                    y2="223"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stop-color="#FFC0C0" />
                                    <stop offset="1" stop-color="#FE7777" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div
                    class="col-span-1 flex flex-col gap-3 justify-between min-w-72"
                >
                    <!-- Badges -->
                    <div
                        *ngIf="!isLoading; else columnLoading"
                        class="bg-tertiary bg-opacity-20 py-4 px-10 rounded-xl flex flex-col items-center gap-4 grow text-tertiary-700"
                    >
                        <span class="text-center text-tertiary-700 font-bold">
                            {{ "shared.common.badges" | transloco }}</span
                        >
                        <ng-container
                            *ngIf="obtainedBadges.length > 0; else noBadge"
                        >
                            <span class="text-2xl text-center"
                                >{{ "coursesection.you-obtained" | transloco }}
                                {{ review.obtainedBadges.length }}
                                {{
                                    (review.obtainedBadges.length > 1
                                        ? "coursesection.badge-plural"
                                        : "coursesection.badge-singular"
                                    ) | transloco
                                }}</span
                            >
                            <div
                                class="w-full flex flex-col justify-around grow"
                            >
                                <div class="flex justify-between items-center">
                                    <mat-icon
                                        (click)="previous()"
                                        [svgIcon]="
                                            'heroicons_outline:chevron-left'
                                        "
                                        class="icon-size-24"
                                        [ngClass]="
                                            hasPrevious
                                                ? 'cursor-pointer text-current'
                                                : 'text-transparent'
                                        "
                                    ></mat-icon>
                                    <bslk-business-badge
                                        class="w-64"
                                        [badge]="currentBadge"
                                    ></bslk-business-badge>
                                    <mat-icon
                                        class="icon-size-24"
                                        (click)="next()"
                                        [svgIcon]="
                                            'heroicons_outline:chevron-right'
                                        "
                                        [ngClass]="
                                            hasNext
                                                ? 'cursor-pointer text-current'
                                                : 'text-transparent'
                                        "
                                    ></mat-icon>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noBadge>
                            <div class="m-auto">
                                {{ "coursesection.no-badge" | transloco }}
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <!-- Performences -->
            <div
                *ngIf="!isLoading; else rowLoading"
                class="bg-quaternary bg-opacity-20 py-4 px-10 rounded-xl flex items-center gap-4"
            >
                <div
                    class="h-32 w-32 bg-quaternary rounded-full border-b-[16px] border-quaternary-800 shadow-xl flex items-center justify-center"
                >
                    <mat-icon
                        [svgIcon]="
                            getIconFromPercentage(
                                review.correctAnswersPercentage
                            )
                        "
                        class="text-white icon-size-16"
                    ></mat-icon>
                </div>
                <div class="flex flex-col justify-center grow">
                    <span class="text-center text-quaternary-700 font-bold">{{
                        "coursesection.performance" | transloco
                    }}</span>

                    <span
                        class="text-center text-3xl text-quaternary-800 font-bold"
                        >{{ review.correctAnswersPercentage }}
                        {{
                            "coursesection.good-answers-percentage" | transloco
                        }}</span
                    >
                </div>
            </div>
        </div>
    </div>
    <next-chapter-section-button
        (click)="goToCoursesPage()"
        [text]="'coursesection.continue' | transloco"
    ></next-chapter-section-button>
</div>

<ng-template #columnLoading>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-80"
    ></ngx-skeleton-loader>
</ng-template>

<ng-template #rowLoading>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-32"
    ></ngx-skeleton-loader>
</ng-template>
