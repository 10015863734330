import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    MissionSlotApprovalInfoViewModel,
    RegularRecapViewModel,
} from 'app/api';

export const RegularDashboardActions = createActionGroup({
    source: 'Dashboard',
    events: {
        'Load Recap Request': emptyProps(),
        'Load Recap Request Success': props<{ recap: RegularRecapViewModel }>(),
        'Load Recap Request Fail': props<{ error: string }>(),

        'Load Pending Manager Approvals Request': emptyProps(),
        'Load Pending Manager Approvals Request Success': props<{
            approvalsInfo: MissionSlotApprovalInfoViewModel[];
        }>(),
        'Load Pending Manager Approvals Request Fail': props<{
            error: string;
        }>(),

        'Check If Can Answer Approve Request': props<{
            isApproved: boolean;
            missionSlotId: number;
            userId: number;
        }>(),
        'Answer Approve Request': props<{
            isApproved: boolean;
            missionSlotId: number;
            userId: number;
        }>(),
        'Answer Approve Request Success': emptyProps(),
        'Answer Approve Request Fail': props<{ error: string }>(),
    },
});
