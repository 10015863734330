<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<ng-container *ngrxLet="layout$ as layout">
    <empty-layout *ngIf="layout === 'empty' || forceEmptyLayout"></empty-layout>

    <!-- Regular user layout -->
    <regular-layout
        *ngIf="layout === 'regular' && !forcedLayout"
    ></regular-layout>

    <!-- External user layout -->
    <external-layout
        *ngIf="layout === 'external' && !forcedLayout"
    ></external-layout>

    <!-- Company admin layout -->
    <company-admin-layout
        *ngIf="layout === 'company-admin' && !forcedLayout"
    ></company-admin-layout>

    <!-- Association admin layout -->
    <association-admin-layout
        *ngIf="layout === 'association-admin' && !forcedLayout"
    ></association-admin-layout>

    <!-- Admin layout -->
    <admin-layout *ngIf="layout === 'admin' && !forcedLayout"></admin-layout>
</ng-container>
