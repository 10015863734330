export type RessourceListState<T extends { id?: number }> = {
    isLoading: boolean;
    isInitialized: boolean;
    filters: RessourceListFilters;
    selectedItemId?: number;
} & IResourceList<T>;

export const ressourceListInitialState = {
    data: [],
    isInitialized: false,
    isLoading: false,
    filters: {},
    selectedItemId: null,
};

export interface IResourceList<T extends { id?: number }> {
    data?: T[] | undefined;
    pageIndex?: number;
    totalPages?: number;
    totalCount?: number;
    pageSize?: number;
    properties?: { [key: string]: string };
}

export type RessourceListFilters = {
    [key in ResourceListFilter]?: any;
};

export type ResourceListFilter =
    | 'activityFields'
    | 'associationId'
    | 'availabilities'
    | 'chapterId'
    | 'cities'
    | 'endDate'
    | 'excludeIds'
    | 'hasAnyAvailableSpotLeft'
    | 'isActive'
    | 'isRemote'
    | 'isTeamBuilding'
    | 'minimalIncludes'
    | 'minimumAvailabilityDaysAWeek'
    | 'missionTypes'
    | 'nameContains'
    | 'pageIndex'
    | 'pageSize'
    | 'questionContains'
    | 'sectionId'
    | 'skills'
    | 'sortColumn'
    | 'sortDirection'
    | 'thematic'
    | 'startDate'
    | 'locations';

export type ResourceListKeys<T extends { id?: number }> = {
    [K in keyof T]: T[K] extends IResourceList<T> ? K : never;
}[keyof T];

export const resourceCreationId = -1;
