<div
    class="flex items-start space-x-4"
    [class.flex-row-reverse]="textPostion === 'left'"
>
    <div class="other-div-content">
        <div class="basile h-24 w-24">
            <img src="assets/images/basile/05.svg" class="" />
        </div>
    </div>

    <div
        class="flex flex-col"
        [ngClass]="textPostion === 'right' ? 'ml-3' : 'mr-3'"
    >
        <!-- Speech Bubble -->
        <div
            class="relative bg-white p-4 rounded-lg max-w-xs min-w-48 shadow-2xl shadow-warn"
        >
            <p class="font-semibold font-italic text-center text-warn text-2xl">
                {{ text }}
            </p>
            <ng-container
                *ngIf="textPostion === 'right'"
                [ngTemplateOutlet]="leftTriangle"
            ></ng-container>
            <ng-container
                *ngIf="textPostion === 'left'"
                [ngTemplateOutlet]="rightTriangle"
            ></ng-container>
        </div>

        <!-- Answer Button -->
        <button
            (click)="onButtonClicked($event)"
            *ngIf="buttonText"
            [ngClass]="textPostion === 'right' ? 'ml-5' : 'mr-5'"
            class="relative shadow-2xl z-10 shadow-warn bg-white text-warn hover:text-primary font-bold p-4 mt-4 rounded-lg"
        >
            <p class="font-semibold font-italic text-center text-2xl">
                {{ buttonText }}
            </p>
            <ng-container
                *ngIf="textPostion === 'left'"
                [ngTemplateOutlet]="leftTriangle"
            ></ng-container>
            <ng-container
                *ngIf="textPostion === 'right'"
                [ngTemplateOutlet]="rightTriangle"
            ></ng-container>
        </button>
    </div>
</div>

<ng-template #leftTriangle>
    <div
        class="absolute -left-2 bottom-0 w-0 h-0 border-8"
        style="border-color: transparent transparent white transparent"
    ></div>
</ng-template>

<ng-template #rightTriangle>
    <div
        class="absolute -right-2 bottom-0 w-0 h-0 border-8"
        style="border-color: transparent transparent white transparent"
    ></div>
</ng-template>
