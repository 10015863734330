// dialog.service.ts

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import {
    CreateMissionDialogComponent,
    CreateMissionDialogInput,
} from './create-mission.component';

@Injectable({ providedIn: 'root' })
export class CreateMissionDialogService implements IDialogService {
    private dialogRef: MatDialogRef<CreateMissionDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open(input: CreateMissionDialogInput) {
        this.dialogRef = this.dialog.open(CreateMissionDialogComponent, {
            data: input,
            maxWidth: '100vw',
            maxHeight: '100vh',
            disableClose: true,
        });
    }

    close() {
        this.dialogRef.close();
    }
}
