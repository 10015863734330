<ng-container *ngrxLet="employees$ as employees">
    <base-kpi-panel
        *ngrxLet="isLoading$ as isLoading"
        [title]="
            'companyadmindashboard.kpis.employees-on-assignment' | transloco
        "
        [kpiName]="kpiName"
        [isExpandable]="employees?.length > 0"
        [isExpanded]="isExpanded$ | async"
        [isLoading]="isLoading"
    >
        <ng-container *ngIf="!isLoading">
            <div
                class="flex flex-col justify-around h-full"
                *ngIf="employees.length > 0"
            >
                <div class="flex justify-center items-center mt-3 xxl:mt-6">
                    <div class="flex flex-0 items-center -space-x-8">
                        <classic-avatar
                            *ngFor="
                                let employee of employeesOverview$ | async;
                                let isFirst = first;
                                let isLast = last
                            "
                            [matTooltip]="
                                employee.user.firstName +
                                ' ' +
                                employee.user.familyName
                            "
                            [size]="24"
                            [textSize]="4"
                            [fallbackLetter]="
                                employee.user.firstName?.at(0) +
                                employee.user.familyName?.at(0)
                            "
                        ></classic-avatar>
                    </div>
                </div>
                <div class="flex gap-4 items-center">
                    <span class="font-extrabold text-5xl w-1/3 text-right">{{
                        employees.length
                    }}</span>
                    <span class="text-xl text-hint w-2/3 text-left">{{
                        "companyadmindashboard.out-of-office" | transloco
                    }}</span>
                </div>
            </div>

            <div
                *ngIf="employees.length === 0"
                class="flex flex-col justify-center h-full"
            >
                <mat-icon
                    [svgIcon]="'heroicons_outline:face-frown'"
                    class="icon-size-16 mx-auto"
                ></mat-icon>
                <span class="text-xl font-weight mt-2 text-center">{{
                    "companyadmindashboard.no-out-of-office" | transloco
                }}</span>
            </div>
        </ng-container>

        <div *ngIf="!isLoading" expandedContent>
            <div class="relative overflow-x-scroll">
                <table class="w-full text-left mt-1">
                    <thead
                        class="bg-tertiary shadow-outside text-lg shadow-solid-tertiary-50 text-white"
                    >
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                {{
                                    "companyadmindashboard.employee" | transloco
                                }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{
                                    "companyadmindashboard.mission" | transloco
                                }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{ "companyadmindashboard.dates" | transloco }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{ "companyadmindashboard.skills" | transloco }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{ "companyadmindashboard.status" | transloco }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let employee of employees"
                            class="bg-white border-b"
                        >
                            <th
                                scope="row"
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                {{
                                    employee.user.firstName +
                                        " " +
                                        employee.user.familyName
                                }}
                            </th>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <div class="flex">
                                    <img
                                        class="w-10 h-10 my-auto rounded-lg mr-2"
                                        [src]="employee.coverUrl"
                                    />
                                    <div
                                        *ngIf="!employee.coverUrl"
                                        class="w-10 h-10 my-auto rounded-lg mr-2 bg-gray-200"
                                    ></div>
                                    <div class="flex flex-col truncate">
                                        <span class="font-bold truncate">{{
                                            employee.name
                                        }}</span>
                                        <span class="text-hint truncate">{{
                                            employee.association.name
                                        }}</span>
                                    </div>
                                </div>
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4 truncate"
                            >
                                {{
                                    formatDateRange(
                                        employee.startDate,
                                        employee.endDate
                                    )
                                }}
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <enum-tag-list
                                    *ngIf="employee.skills"
                                    [tags]="employee.skills"
                                    [enum]="ESkill"
                                    [maxTagsVisible]="1"
                                ></enum-tag-list>
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <mission-state
                                    [state]="employee.missionState"
                                    [showDescriptionAsTooltip]="false"
                                >
                                </mission-state>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </base-kpi-panel>
</ng-container>
