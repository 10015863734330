import { createAction, props } from '@ngrx/store';
import {
    ProjectImageViewModel,
    ProjectViewModel,
    ProjectViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const ProjectListActions = {
    ...createRessourceListActionGroup<
        ProjectViewModel,
        ProjectViewModelPaginatedListDto
    >('ProjectList'),

    PublishRequest: createAction(
        `[ProjectList] Publish Request`,
        props<{ id: number }>()
    ),
    UpdateCover: createAction(
        `[ProjectList] Update Cover`,
        props<{ file: File; id: number }>()
    ),
    AddImage: createAction(
        `[ProjectList] Add Image`,
        props<{ file: File; id: number }>()
    ),
    AddImageSuccess: createAction(
        `[ProjectList] Add Image Success`,
        props<{ projectId: number; image: ProjectImageViewModel }>()
    ),
    RemoveImage: createAction(
        `[ProjectList] Remove Image`,
        props<{ parentId: number; id: number }>()
    ),
    RemoveImageSuccess: createAction(`[ProjectList] Remove Image Success`),
    UpdateImageFail: createAction(`[ProjectList] Update Image Fail`),
    LoadRegions: createAction(
        `[ProjectList] Load Regions`,
        props<{ search?: string }>()
    ),
    LoadRegionsSuccess: createAction(
        `[ProjectList] Load Regions Success`,
        props<{ regions: string[] }>()
    ),
    LoadRegionsFail: createAction(
        `[ProjectList] Load Regions Fail`,
        props<{ error: string }>()
    ),
};
