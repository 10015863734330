<div *ngrxLet="isLoading$ as isLoading" class="flex flex-col gap-6">
    <div
        class="flex items-end justify-between pb-1 border-b-2 border-slate-300"
    >
        <span class="text-4xl truncate font-bold">{{
            "dualquiz.my-challenges" | transloco
        }}</span>
    </div>
    <ng-container *ngIf="!isLoading; else loading">
        <div
            *ngIf="(challenges$ | async).length > 0; else noChallenges"
            class="grid grid-cols-2 gap-8"
        >
            <dual-quiz-challenge-card
                class="col-span-2 lg:col-span-1"
                *ngFor="let challenge of challenges$ | async"
                [challenge]="challenge"
            ></dual-quiz-challenge-card>
        </div>
    </ng-container>
    <mat-paginator
        *ngIf="(totalPages$ | async) > 1"
        class="bg-white rounded-2xl"
        [ngClass]="{ 'pointer-events-none': isLoading$ | async }"
        [length]="totalCount$ | async"
        [pageIndex]="pageIndex$ | async"
        [pageSize]="pageSize$ | async"
        [hidePageSize]="true"
        (page)="pageChangeEvent($event)"
        [showFirstLastButtons]="true"
    >
    </mat-paginator>
</div>

<ng-template #noChallenges>
    <div class="flex flex-col items-center gap-6">
        <mat-icon
            class="text-current icon-size-10"
            svgIcon="feather:frown"
        ></mat-icon>
        <span class="font-bold text-lg">{{
            "dualquiz.no-challenges" | transloco
        }}</span>
        <button
            (click)="openNewChallengeDialog()"
            class="flex font-semibold p-6 border border-solid border-primary"
            mat-flat-button
        >
            {{ "dualquiz.new-challenge" | transloco }}
        </button>
    </div>
</ng-template>

<ng-template #loading>
    <div class="grid grid-cols-2 gap-8">
        <div
            class="col-span-2 lg:col-span-1 rounded-2xl shadow-lg h-48 overflow-hidden"
            *ngFor="let _ of [1, 2, 3, 4, 5, 6]"
        >
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full h-full"
            ></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
