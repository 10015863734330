import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { BlockViewModel } from 'app/api';
import { RichTextTemplateComponent } from '../base/rich-text-block/rich-text-template/rich-text-template.component';

@Component({
    selector: 'bslk-link-preview',
    templateUrl: './link-preview.component.html',
    standalone: true,
    imports: [NgIf, RichTextTemplateComponent, MatIconModule, TranslocoModule],
})
export class BslkLinkPreviewComponent {
    @Input() block: BlockViewModel;
}
