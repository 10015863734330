import { createActionGroup, emptyProps } from "@ngrx/store";

export const DialogActions = createActionGroup({
  source: 'Dialog',
  events: {
    'Open Mission Or Project Dialog': emptyProps(),
    'Close Mission Or Project Dialog': emptyProps(),

    'Open Confirmation Dialog': emptyProps()
  },
});