import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MatCheckboxChange,
    MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { MissionTaskViewModel } from 'app/api';
import { MissionManageActions } from 'app/store/mission/mission-manage/mission-manage.action';
import { MissionManageSelectors } from 'app/store/mission/mission-manage/mission-manage.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { MissionEditTasksComponent } from './edit/mission-edit-tasks.component';

@Component({
    selector: 'mission-tasks',
    standalone: true,
    templateUrl: './mission-tasks.component.html',
    imports: [
        AsyncPipe,
        BslkClassicCardComponent,
        FuseAlertComponent,
        LetDirective,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MissionEditTasksComponent,
        NgFor,
        NgIf,
        TranslocoModule,
    ],
})
export class MissionTasksComponent {
    isEditingTasks = false;

    tasks$ = this.store.select(MissionManageSelectors.selectMissionTasks);

    isAssoAdmin$ = this.store.select(UserSelectors.selectIsAssoAdmin);

    isLoadingTasks$ = this.store.select(
        MissionManageSelectors.selectIsLoadingTasks
    );

    canManageTasks$ = this.store.select(
        MissionManageSelectors.selectCanManageTasks
    );

    constructor(private store: Store) {}

    atLeastOneSubtaskCompleted(task: MissionTaskViewModel) {
        return task.subtasks.some((t) => t.isCompleted);
    }

    allSubtasksCompleted(task: MissionTaskViewModel) {
        return task.subtasks.every((t) => t.isCompleted);
    }

    onTaskStateChanged(id: number, event: MatCheckboxChange) {
        this.store.dispatch(
            MissionManageActions.updateMissionTaskCompletionStatusRequest({
                id,
                isCompleted: event.checked,
            })
        );
    }

    onTasksChanged(tasks: MissionTaskViewModel[]) {
        this.onEditTask(false);
        this.store.dispatch(
            MissionManageActions.updateMissionTasksRequest({ tasks })
        );
    }

    checkboxClicked(event: MouseEvent) {
        (event.target as HTMLElement).blur();
    }

    onEditTask(isEditingTask: boolean) {
        this.isEditingTasks = isEditingTask;
    }
}
