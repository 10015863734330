import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { BslkDragService } from '@bslk/services/drag.service';

@Directive({
    selector: '[bslkDraggable]',
    standalone: true,
})
export class BslkDraggableDirective {
    @Input() dragData: any;
    @Output() dragStart = new EventEmitter<any>();
    @Output() dragging = new EventEmitter<{ event: MouseEvent; data: any }>();
    @Output() dragEnd = new EventEmitter<any>();

    private isDragging = false;

    constructor(private el: ElementRef, private dragService: BslkDragService) {
        this.el.nativeElement.style.cursor = 'grab';
    }

    @HostListener('mousedown', ['$event'])
    onDragStart(event: MouseEvent): void {
        if (this.isDragDisabled(event.target)) {
            return;
        }

        this.isDragging = true;
        this.dragService.startDrag();
        this.dragService.setDragData(this.dragData);
        this.el.nativeElement.style.position = 'absolute';
        this.el.nativeElement.classList.add('z-99');
        this.el.nativeElement.style.pointerEvents = 'none';
        event.preventDefault();
    }

    @HostListener('document:mousemove', ['$event'])
    onDragging(event: MouseEvent): void {
        if (!this.isDragging) return;
        this.dragging.emit({ event: event, data: this.dragData });

        const scrollX = window.scrollX;
        const scrollY = window.scrollY;

        const offsetX = this.el.nativeElement.offsetWidth;
        const offsetY = this.el.nativeElement.offsetHeight;

        this.el.nativeElement.style.top = `${
            event.clientY + scrollY - offsetY
        }px`;
        this.el.nativeElement.style.left = `${
            event.clientX + scrollX - offsetX
        }px`;
    }

    @HostListener('document:mouseup')
    onDragEnd(): void {
        if (!this.isDragging) return;
        this.isDragging = false;
        this.dragService.endDrag();
        this.dragEnd.emit(this.dragData);
        this.el.nativeElement.style.removeProperty('position');
        this.el.nativeElement.style.removeProperty('top');
        this.el.nativeElement.style.removeProperty('left');
        this.el.nativeElement.classList.remove('z-99');
        this.el.nativeElement.style.pointerEvents = '';
    }

    private isDragDisabled(eventTarget: EventTarget): boolean {
        let targetElement = eventTarget as HTMLElement;

        while (targetElement && targetElement !== this.el.nativeElement) {
            if (
                targetElement instanceof HTMLButtonElement ||
                targetElement instanceof HTMLInputElement ||
                targetElement.classList.contains('no-drag')
            ) {
                return true;
            }
            targetElement = targetElement.parentElement;
        }
        return false;
    }
}
