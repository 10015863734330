import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { DualQuizChallengeActions } from './challenge.action';
import { DualQuizChallengeState } from './challenge.state';

export const dualQuizChallengeInitialState: DualQuizChallengeState = {
    isLoading: false,
    isAnswering: false,
    isTimerRunning: false,
    hasAnsweredOnce: false,
    questionEmittedResponseReceived: false,
    currentQuestionIndex: 0,
};

export const dualQuizChallengeReducer = createReducer(
    dualQuizChallengeInitialState,
    on(DualQuizChallengeActions.loadChallengeRequest, () => {
        return {
            ...dualQuizChallengeInitialState,
            isLoading: true,
        };
    }),

    on(
        DualQuizChallengeActions.loadChallengeRequestSuccess,
        (state, { challenge }) => {
            return {
                ...state,
                isLoading: false,
                challenge,
                currentQuestionIndex: challenge.questions.findIndex(
                    (q) => !q.hasAnswered
                ),
            };
        }
    ),

    on(DualQuizChallengeActions.loadChallengeRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(DualQuizChallengeActions.answerQuestionRequest, (state, { choice }) => {
        return {
            ...state,
            lastChoice: choice,
            isTimerRunning: false,
            timer: 0,
            hasAnsweredOnce: true,
        };
    }),

    on(
        DualQuizChallengeActions.answerQuestionRequestSuccess,
        (state, { response }) => {
            return {
                ...state,
                isAnswering: false,
                answerResponse: response,
            };
        }
    ),

    on(
        DualQuizChallengeActions.answerQuestionRequestSuccess,
        (state, { response }) =>
            produce(state, (draftState) => {
                draftState.isAnswering = false;
                draftState.answerResponse = response;
                draftState.challenge.questions[
                    draftState.currentQuestionIndex
                ].hasAnswered = true;
            })
    ),

    on(DualQuizChallengeActions.answerQuestionRequestFail, (state) => {
        return {
            ...state,
        };
    }),

    on(DualQuizChallengeActions.nextQuestion, (state, { isStarting }) => {
        return {
            ...state,
            currentQuestionIndex: isStarting
                ? state.currentQuestionIndex
                : state.currentQuestionIndex + 1,
            lastChoice: undefined,
            isAnswering: true,
            answerResponse: undefined,
            questionEmittedResponseReceived: false,
        };
    }),

    on(DualQuizChallengeActions.startTimer, (state, { duration }) => {
        return {
            ...state,
            isTimerRunning: true,
            timer: 0,
            timerDuration: duration,
        };
    }),

    on(DualQuizChallengeActions.stopTimer, (state) => {
        return {
            ...state,
            isTimerRunning: false,
        };
    }),

    on(DualQuizChallengeActions.updateTimer, (state, { timer }) => {
        return {
            ...state,
            timer,
        };
    }),

    on(DualQuizChallengeActions.emittedCurrentQuestionSuccess, (state) => {
        return {
            ...state,
            questionEmittedResponseReceived: true,
        };
    })
);
