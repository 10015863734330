<base-dialog
    [size]="'large'"
    (onClose)="close()"
    [title]="'missionview.registration.title' | transloco"
>
    <div body class="w-full flex flex-col items-center justify-center">
        <ng-container
            *ngIf="!showRegistrationSuccess; else registrationSuccess"
        >
            <propose-slot-calendar
                [expirationDate]="data.expirationDate"
            ></propose-slot-calendar>
        </ng-container>

        <ng-template #registrationSuccess>
            <registration-success (onChat)="close()"></registration-success>
        </ng-template>
    </div>

    <!-- Actions -->
    <div
        actions
        *ngrxLet="isLoading$ as isLoading"
        class="flex justify-end w-full"
    >
        <!-- Discard -->
        <button mat-stroked-button (click)="close()">
            <span *ngIf="!showRegistrationSuccess">{{
                "missionview.registration.cancel" | transloco
            }}</span>
            <span *ngIf="showRegistrationSuccess">{{
                "missionview.registration.close" | transloco
            }}</span>
        </button>

        <!-- Register -->
        <bslk-classic-button
            *ngIf="!showRegistrationSuccess"
            [label]="'missionview.registration.continue' | transloco"
            [isDisabled]="isSubmitDisabled"
            [isLoading]="isLoading"
            (onClick)="register()"
            class="ml-3"
            [type]="'submit'"
        ></bslk-classic-button>
        <a
            (click)="close()"
            class="ml-3"
            *ngIf="showRegistrationSuccess"
            [routerLink]="'/missions/' + missionId + '/manage'"
        >
            <bslk-classic-button
                [background]="'tertiary'"
                [label]="'missionview.manage' | transloco"
                [icon]="'heroicons_outline:play'"
            >
            </bslk-classic-button>
        </a>
    </div>
</base-dialog>
