<div [formGroup]="formGroup" class="pr-3">
    <div class="flex justify-between">
        <div class="w-full flex gap-6 justify-between">
            <div class="w-1/2 border-r-2 flex justify-center items-center">
                <bslk-classic-toggle
                    [label]="'createmission.is-active' | transloco"
                    [infoTooltip]="
                        'createmission.is-active-tooltip' | transloco
                    "
                    [color]="'warn'"
                    [size]="'small'"
                    [choices]="[
                        { text: 'shared.common.yes' | transloco, value: true },
                        { text: 'shared.common.no' | transloco, value: false }
                    ]"
                    [control]="formGroup.controls.isActive"
                    [value]="formGroup.controls.isActive.value"
                    class="flex items-center gap-2"
                ></bslk-classic-toggle>
            </div>
            <div class="w-1/2 flex justify-center items-center">
                <bslk-classic-toggle
                    [label]="'createmission.is-pmr-available' | transloco"
                    [infoTooltip]="
                        'createmission.is-pmr-available-tooltip' | transloco
                    "
                    [color]="'warn'"
                    [size]="'small'"
                    [choices]="[
                        { text: 'shared.common.yes' | transloco, value: true },
                        { text: 'shared.common.no' | transloco, value: false }
                    ]"
                    [control]="formGroup.controls.isPmrAvailable"
                    [value]="formGroup.controls.isPmrAvailable.value"
                    class="flex items-center gap-2"
                ></bslk-classic-toggle>
            </div>
        </div>
    </div>

    <div class="w-full">
        <span class="font-semibold">
            {{ "createmission.description" | transloco }}
            <mat-icon
                [matTooltip]="'createmission.description-tooltip' | transloco"
                class="icon-size-4 text-blue-500"
                [svgIcon]="'heroicons_solid:information-circle'"
            ></mat-icon>
        </span>
        <quill-editor
            class="mt-2 w-full"
            [formControlName]="'description'"
            [bounds]="'self'"
            [placeholder]="'createmission.description-placeholder' | transloco"
            [modules]="quillModules"
        ></quill-editor>
        <small class="text-primary" *ngIf="isDescriptionErrorDisplayed">
            {{ "createmission.description-error" | transloco }}
        </small>
    </div>

    <div *ngIf="isAssoAdmin$ | async" class="flex flex-col mt-6">
        <span class="text-xl font-bold">
            {{ "createmission.responsible-person" | transloco }}
            <mat-icon
                [matTooltip]="
                    'createmission.responsible-user-tooltip' | transloco
                "
                class="icon-size-4 text-blue-500"
                [svgIcon]="'heroicons_solid:information-circle'"
            ></mat-icon>
        </span>

        <div class="flex w-full items-center">
            <div class="w-1/2 ml-3 flex flex-col">
                <span>
                    {{ "createmission.is-responsible-person" | transloco }}
                </span>
                <mat-radio-group
                    [formControlName]="'isMissionCreatorResponsibleUser'"
                >
                    <mat-radio-button color="primary" [value]="true">{{
                        "createmission.yes" | transloco
                    }}</mat-radio-button>
                    <mat-radio-button color="primary" [value]="false">{{
                        "createmission.no" | transloco
                    }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div
                *ngIf="
                    !formGroup.controls.isMissionCreatorResponsibleUser.value
                "
                class="w-1/2 mr-3 flex flex-col"
            >
                <span>{{
                    "createmission.is-responsible-person-basilik-user"
                        | transloco
                }}</span>
                <mat-radio-group
                    [formControlName]="'isMissionCreatorResponsibleOnBasilik'"
                >
                    <mat-radio-button color="primary" [value]="true">{{
                        "createmission.yes" | transloco
                    }}</mat-radio-button>
                    <mat-radio-button color="primary" [value]="false">{{
                        "createmission.no" | transloco
                    }}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <bslk-advanced-select-sync
            class="w-1/2 mt-3"
            *ngIf="
                !formGroup.controls.isMissionCreatorResponsibleUser.value &&
                formGroup.controls.isMissionCreatorResponsibleOnBasilik.value
            "
            [items]="usersForAdvancedSelect$ | async"
            [group]="formGroup"
            [icon]="'heroicons_solid:user'"
            [controlName]="'responsibleUserId'"
            [label]="'createmission.basilik-user' | transloco"
            [errorMessage]="'createmission.basilik-user-error' | transloco"
            [selectPlaceholder]="
                'createmission.basilik-user-placeholder' | transloco
            "
            [searchPlaceholder]="
                'createmission.basilik-user-placeholder' | transloco
            "
        ></bslk-advanced-select-sync>

        <mat-form-field
            *ngIf="
                !formGroup.controls.isMissionCreatorResponsibleUser.value &&
                !formGroup.controls.isMissionCreatorResponsibleOnBasilik.value
            "
            floatLabel="always"
            class="w-1/2 mt-3"
            [subscriptSizing]="'dynamic'"
        >
            <mat-label>
                {{ "createmission.responsible-user-email" | transloco }}
            </mat-label>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:at-symbol'"
                matPrefix
            ></mat-icon>
            <input
                [formControlName]="'notRegisteredResponsibleUserEmail'"
                [placeholder]="
                    'createmission.responsible-user-email-placeholder'
                        | transloco
                "
                matInput
            />
            <mat-error>
                {{ "createmission.responsible-user-email" | transloco }}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="w-full mt-6 pb-2">
        <mat-form-field
            floatLabel="always"
            class="w-full"
            [subscriptSizing]="'dynamic'"
        >
            <mat-label>
                {{ "createmission.expected-impact" | transloco }}
                <mat-icon
                    [matTooltip]="
                        'createmission.expected-impact-tooltip' | transloco
                    "
                    class="icon-size-4 text-blue-500"
                    [svgIcon]="'heroicons_solid:information-circle'"
                ></mat-icon>
            </mat-label>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:rocket-launch'"
                matPrefix
            ></mat-icon>
            <input
                [formControlName]="'expectedImpact'"
                [placeholder]="
                    'createmission.expected-impact-placeholder' | transloco
                "
                matInput
            />
            <mat-error>
                {{ "createmission.expected-impact-error" | transloco }}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="w-full mt-6 pb-2">
        <bslk-info-label
            class="font-bold"
            [tooltip]="'createmission.sdg-impact-info' | transloco"
            >{{ "createmission.sdg-impact" | transloco }}</bslk-info-label
        >
        <bslk-business-sdg-picker
            [control]="formGroup.controls.sustainableDevelopmentGoals"
            [justify]="'start'"
            [displayValueInField]="true"
        ></bslk-business-sdg-picker>
    </div>

    <div class="w-full mt-6 pb-2">
        <mat-form-field
            floatLabel="always"
            class="w-full"
            [subscriptSizing]="'dynamic'"
        >
            <mat-label>
                {{ "createmission.additional-information" | transloco }}
                <mat-icon
                    [matTooltip]="
                        'createmission.additional-information-tooltip'
                            | transloco
                    "
                    class="icon-size-4 text-blue-500"
                    [svgIcon]="'heroicons_solid:information-circle'"
                ></mat-icon>
            </mat-label>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:information-circle'"
                matPrefix
            ></mat-icon>
            <input
                [formControlName]="'additionalInformation'"
                [placeholder]="
                    'createmission.additional-information-placeholder'
                        | transloco
                "
                matInput
            />
            <mat-error>
                {{ "createmission.additional-information-error" | transloco }}
            </mat-error>
        </mat-form-field>
    </div>
</div>
