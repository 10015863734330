import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { NavigationActions } from '../navigation/navigation.actions';
import { UserActions } from '../user/user/user.actions';
import { UserSelectors } from '../user/user/user.selectors';

@Injectable()
export class NavigationEffects {
    initNavigation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                UserActions.signInSuccess,
                UserActions.alreadySignedIn,
                UserActions.switchRoleRequestSuccess
            ),
            concatLatestFrom(() => [
                this.store.select(UserSelectors.selectUserRole),
                this.store.select(UserSelectors.selectAllowedFeatures),
            ]),
            map(([, userRole, allowedFeatures]) => {
                return NavigationActions.initNavigation({
                    userRole,
                    allowedFeatures,
                });
            })
        )
    );

    notFound$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NavigationActions.notFound),
                tap(() => {
                    this.router.navigateByUrl('/not-found');
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private router: Router
    ) {}
}
