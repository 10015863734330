import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { MissionManageViewModel, UserViewModel } from 'app/api';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { ChatActions } from 'app/store/chat/chat.action';

@Component({
    selector: 'mission-responsible-user',
    standalone: true,
    templateUrl: './mission-responsible-user.component.html',
    imports: [
        ClassicAvatarComponent,
        BslkClassicCardComponent,
        MatIconModule,
        MatButtonModule,
        NgIf,
        TranslocoModule,
    ],
})
export class MissionResponsibleUserComponent {
    @Input() mission: MissionManageViewModel;

    constructor(private store: Store, private route: ActivatedRoute) {}

    get registeredResponsibleUser(): UserViewModel {
        return this.mission.responsibleUser;
    }

    get notRegisteredResponsibleUserEmail(): string {
        return this.mission.notRegisteredResponsibleUserEmail;
    }

    get responsibleUserFirstLetter() {
        return (
            this.mission.responsibleUser?.firstName.at(0) ??
            this.notRegisteredResponsibleUserEmail.at(0)
        );
    }

    buildChat() {
        this.store.dispatch(
            ChatActions.buildChat({
                users: [this.registeredResponsibleUser],
                currentRoute: this.route.snapshot.toString(),
            })
        );
    }
}
