import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { CompanyUsersActions } from './company-users.actions';
import { CompanyUsersState } from './company-users.state';

export const initialState: CompanyUsersState = ressourceListInitialState;

export const companyUsersReducer = createRessourceListReducer(
    ressourceListInitialState,
    CompanyUsersActions
);
