<auth-template class="w-full" [title]="'login.login' | transloco">
    <div class="flex justify-center items-baseline mt-4 font-medium">
        {{ "login.welcome" | transloco }}
    </div>

    <div class="external-auth flex flex-col gap-4 mx-auto">
        <div
            *ngIf="showSecretGoogleSso || enableGoogleSso"
            class="external-auth__google mt-4"
        >
            <asl-google-signin-button
                [width]="328"
                class="external-auth__google"
                type="standard"
                size="large"
                shape="rectangular"
                text="signin_with"
                theme="filled_white"
            >
            </asl-google-signin-button>
        </div>

        <button
            (click)="microsoftSignIn()"
            class="external-auth__microsoft bg-white border-gray-300"
            color="primary"
        >
            <img
                class="external-auth__microsoft--logo"
                src="/assets/icons/microsoft.svg"
            />
            <span class="external-auth__microsoft--text">{{
                "login.microsoft" | transloco
            }}</span>
        </button>
        <button
            (click)="linkedInSignIn()"
            class="external-auth__linkedin text-[#0e76a8] bg-white transition-all border border-gray-300 flex items-center justify-center gap-2"
        >
            <img
                class="external-auth__linkedin--logo h-6"
                src="/assets/icons/linkedin.png"
            />
            {{ "login.linkedin" | transloco }}
        </button>
    </div>

    <!-- Separator -->
    <div class="flex items-center mt-4">
        <div class="flex-auto mt-px border-t"></div>
        <div class="mx-4">{{ "login.or" | transloco }}</div>
        <div class="flex-auto mt-px border-t"></div>
    </div>

    <!-- Sign Up form -->
    <form [formGroup]="loginFormGroup" (ngSubmit)="connect()" class="mt-4">
        <!-- Email field -->
        <mat-form-field class="w-full">
            <mat-label>{{ "login.email" | transloco }}</mat-label>
            <input
                autocomplete="email"
                [placeholder]="'login.email-placeholder' | transloco"
                matInput
                [formControlName]="'email'"
            />
            <mat-error>
                {{ "login.email-required" | transloco }}
            </mat-error>
        </mat-form-field>

        <!-- Password field -->
        <mat-form-field class="w-full">
            <mat-label>{{ "login.password" | transloco }}</mat-label>
            <input
                required
                [placeholder]="'login.password-placeholder' | transloco"
                autocomplete="current-password"
                matInput
                type="password"
                [formControlName]="'password'"
                #passwordField
            />
            <button
                mat-icon-button
                type="button"
                (click)="
                    passwordField.type === 'password'
                        ? (passwordField.type = 'text')
                        : (passwordField.type = 'password')
                "
                matSuffix
            >
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordField.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye'"
                ></mat-icon>
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordField.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye-slash'"
                ></mat-icon>
            </button>
            <mat-error>
                {{ "login.password-required" | transloco }}
            </mat-error>
        </mat-form-field>

        <!-- Forgot password -->
        <div class="inline-flex items-end w-full mb-4">
            <a
                class="text-sm underline font-bold ml-auto"
                [routerLink]="'/forgot-password'"
                >{{ "login.forgot-password" | transloco }}</a
            >
        </div>

        <div class="" *ngrxLet="loginError$ as loginError">
            <fuse-alert
                class="mb-4 flex justify-center"
                *ngIf="loginError"
                [appearance]="'outline'"
                [showIcon]="true"
                [type]="'error'"
                [@shake]="true"
            >
                {{ loginError | error : "login.errors." }}
            </fuse-alert>
        </div>

        <!-- Submit button -->
        <button
            class="fuse-mat-button-large w-full text-accent"
            mat-flat-button
            [color]="'primary'"
            [disabled]="false"
        >
            <span>
                {{ "login.continue" | transloco }}
            </span>
            <mat-progress-spinner
                *ngIf="false"
                [diameter]="24"
                [mode]="'indeterminate'"
            ></mat-progress-spinner>
        </button>
    </form>
    <div footer>
        <div class="flex justify-center items-baseline font-medium">
            {{ "login.no-account" | transloco }} &nbsp;

            <bslk-classic-button
                (onClick)="signup()"
                [icon]="'heroicons_outline:rocket-launch'"
                [label]="'login.sign-up' | transloco"
                [background]="'tertiary'"
            >
            </bslk-classic-button>
        </div>
        <div
            class="flex justify-center items-baseline mt-0.5 text-sm text-gray-500"
        >
            *{{ "login.required-information" | transloco }}
        </div>
    </div>
</auth-template>
