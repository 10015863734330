/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ESustainableDevelopmentGoal = 'NoPoverty' | 'ZeroHunger' | 'GoodHealthAndWellBeing' | 'QualityEducation' | 'GenderEquality' | 'CleanWaterAndSanitation' | 'AffordableAndCleanEnergy' | 'DecentWorkAndEconomicGrowth' | 'IndustryInnovationAndInfrastructure' | 'ReducedInequalities' | 'SustainableCitiesAndCommunities' | 'ResponsibleConsumptionAndProduction' | 'ClimateAction' | 'LifeBelowWater' | 'LifeOnLand' | 'PeaceJusticeAndStrongInstitutions' | 'PartnershipsForTheGoals';

export const ESustainableDevelopmentGoal = {
    NoPoverty: 'NoPoverty' as ESustainableDevelopmentGoal,
    ZeroHunger: 'ZeroHunger' as ESustainableDevelopmentGoal,
    GoodHealthAndWellBeing: 'GoodHealthAndWellBeing' as ESustainableDevelopmentGoal,
    QualityEducation: 'QualityEducation' as ESustainableDevelopmentGoal,
    GenderEquality: 'GenderEquality' as ESustainableDevelopmentGoal,
    CleanWaterAndSanitation: 'CleanWaterAndSanitation' as ESustainableDevelopmentGoal,
    AffordableAndCleanEnergy: 'AffordableAndCleanEnergy' as ESustainableDevelopmentGoal,
    DecentWorkAndEconomicGrowth: 'DecentWorkAndEconomicGrowth' as ESustainableDevelopmentGoal,
    IndustryInnovationAndInfrastructure: 'IndustryInnovationAndInfrastructure' as ESustainableDevelopmentGoal,
    ReducedInequalities: 'ReducedInequalities' as ESustainableDevelopmentGoal,
    SustainableCitiesAndCommunities: 'SustainableCitiesAndCommunities' as ESustainableDevelopmentGoal,
    ResponsibleConsumptionAndProduction: 'ResponsibleConsumptionAndProduction' as ESustainableDevelopmentGoal,
    ClimateAction: 'ClimateAction' as ESustainableDevelopmentGoal,
    LifeBelowWater: 'LifeBelowWater' as ESustainableDevelopmentGoal,
    LifeOnLand: 'LifeOnLand' as ESustainableDevelopmentGoal,
    PeaceJusticeAndStrongInstitutions: 'PeaceJusticeAndStrongInstitutions' as ESustainableDevelopmentGoal,
    PartnershipsForTheGoals: 'PartnershipsForTheGoals' as ESustainableDevelopmentGoal
};

