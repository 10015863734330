import { JsonPipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { EBlockType, PageViewModel } from 'app/api';
import { BslkHeadingComponent } from './blocks/heading/heading.component';
import { BslkImageComponent } from './blocks/image/image.component';
import { BslkParagraphComponent } from './blocks/paragraph/paragraph.component';
import { BslkQuoteComponent } from './blocks/quote/quote.component';
import { BslkPageContentComponent } from './content/content.component';

@Component({
    selector: 'bslk-page',
    templateUrl: './page.component.html',
    standalone: true,
    imports: [
        BslkHeadingComponent,
        BslkImageComponent,
        BslkParagraphComponent,
        BslkQuoteComponent,
        BslkPageContentComponent,
        JsonPipe,
        MatIconModule,
        NgFor,
        NgIf,
        NgSwitch,
        NgSwitchCase,
        TranslocoModule,
    ],
})
export class BslkPageComponent {
    @Input() page: PageViewModel;

    EBlockType = EBlockType;

    constructor() {}

    ngOnInit() {}
}
