import { AsyncPipe, JsonPipe, NgFor, NgIf } from '@angular/common';
import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { RessourceListActions } from 'app/store/shared/ressource-list/ressource-list-action-group-creator';
import { RessourceListSelectors } from 'app/store/shared/ressource-list/ressource-list-selectors.creator';
import { IResourceList } from 'app/store/shared/ressource-list/ressource-list-state';
import { isEmpty } from 'lodash-es';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'single-select-async',
    templateUrl: './single-select-async.component.html',
    styleUrls: ['./single-select-async.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        FuseAlertComponent,
        JsonPipe,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        NgFor,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
        LetDirective,
    ],
})
export class SingleSelectAsyncComponent<
    T extends { id?: number },
    TList extends IResourceList<T>
> implements OnInit, OnChanges
{
    @ViewChild('searchInput') searchInput: ElementRef;

    @Input() actions: RessourceListActions<T, TList>;
    @Input() selectors: RessourceListSelectors<T>;
    @Input() initialValue: string;
    @Input() showInitialValue: boolean = false;
    @Input() label: string;
    @Input() selectPlaceholder: string;
    @Input() searchPlaceholder: string;
    @Input() control: FormControl | null;
    @Input() valueIsId = false;
    @Input() excludedIds: number[] | null;
    @Input() noDataText: string | null;

    items$: Observable<T[]>;
    search: string;
    isLoading$: Observable<boolean>;

    constructor(private store: Store) {}

    get isSearchEmpty() {
        return isEmpty(this.search);
    }

    ngOnInit(): void {
        this.store.dispatch(this.actions.Initialize());
        this.isLoading$ = this.store.select(this.selectors.selectIsLoading);
        this.items$ = this.store.select(this.selectors.selectData).pipe(
            map((items) => {
                return items.filter(
                    (i) => !this.excludedIds || !this.excludedIds.includes(i.id)
                );
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.excludedIds && !changes.excludedIds.firstChange) {
            this.items$ = this.store.select(this.selectors.selectData).pipe(
                map((items) => {
                    return items.filter(
                        (i) =>
                            !this.excludedIds ||
                            !this.excludedIds.includes(i.id)
                    );
                })
            );
        }
    }

    onSearch(query: string): void {
        this.search = query;
        this.store.dispatch(
            this.actions.FilterListRequest({
                filters: [
                    {
                        key: 'nameContains',
                        value: query,
                    },
                ],
            })
        );
    }

    onChange() {
        this.showInitialValue = false;
    }

    onMatSelectOpenedChange(opened: boolean) {
        if (opened) {
            // Focus the input after a slight delay to ensure the element is visible
            setTimeout(() => this.searchInput.nativeElement.focus(), 100);
        }
    }
}
