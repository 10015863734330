import { NgClass } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { UserActions } from 'app/store/user/user/user.actions';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { tap } from 'rxjs';

@Component({
    selector: 'experience-bar',
    templateUrl: './experience-bar.component.html',
    standalone: true,
    imports: [MatIconModule, TranslocoModule, LetDirective, NgClass],
})
export class ExperienceBarComponent implements OnInit {
    @Input() barColor: 'tertiary' | 'warn-gradient' = 'tertiary';
    @Input() secondaryColor: 'hint' | 'white' = 'hint';

    oldLevel: number | null = null;
    oldPercentage: number = 0;

    @ViewChild('progressBar', { static: false })
    progressBar: ElementRef<HTMLDivElement>;

    levelInformation$ = this.store
        .select(UserSelectors.selectUserLevelInformation)
        .pipe(
            tap((levelInfo) => {
                if (
                    this.oldLevel !== null &&
                    this.oldLevel !== levelInfo.currentLevel
                ) {
                    // Animate to 100% with transition
                    this.animateLevelUp(this.oldPercentage, 100);
                    setTimeout(() => {
                        // Instant removal to 0% without transition
                        this.animateLevelUp(100, 0, false);
                        setTimeout(() => {
                            // Resume animation from 0% to new percentage with transition
                            this.animateLevelUp(
                                0,
                                levelInfo.percentageProgressInCurrentLevel
                            );
                        }, 50); // A very brief delay to apply the change
                    }, 1000); // Delay to allow the first transition to finish at 100%
                } else {
                    // Normal animation with transition
                    this.animateLevelUp(
                        this.oldPercentage,
                        levelInfo.percentageProgressInCurrentLevel
                    );
                }
                this.oldLevel = levelInfo.currentLevel;
                this.oldPercentage = levelInfo.percentageProgressInCurrentLevel;
            })
        );

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(UserActions.checkIfRewardsAreUpdated());
    }

    private animateLevelUp(
        startPercent: number,
        endPercent: number,
        useTransition: boolean = true
    ) {
        if (this.progressBar) {
            // Add or remove transition classes as needed
            const progressBarElement = this.progressBar.nativeElement;
            const transitionClasses = [
                'transition-width',
                'ease-out',
                'duration-1000',
            ];
            if (useTransition) {
                progressBarElement.classList.add(...transitionClasses);
            } else {
                progressBarElement.classList.remove(...transitionClasses);
            }
            if (useTransition) {
                progressBarElement.style.width = `${startPercent}%`;
                setTimeout(() => {
                    progressBarElement.style.width = `${endPercent}%`;
                }, 50); // A short delay to ensure the transition starts from startPercent
            } else {
                progressBarElement.style.width = `${endPercent}%`;
            }
        }
    }
}
