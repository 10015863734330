<base-dialog
    [title]="'shared.media-library.image-library' | transloco"
    (onClose)="close()"
    [showActions]="false"
>
    <div
        body
        class="pb-2 max-w-full w-full flex flex-col gap-4 items-center justify-center"
    >
        <fuse-alert *transloco="let t" class="flex mx-auto" [type]="'info'">
            {{
                t("shared.media-library.info", {
                    max: data.maximumImages
                })
            }}
        </fuse-alert>

        <div *ngrxLet="data.images as images" class="grid grid-cols-2 gap-4">
            <div
                [class.border-2]="!images[index]"
                *ngFor="let _ of loop; let index = index"
                class="relative h-64 w-64 overflow-hidden rounded-2xl border-dashed border-primary"
            >
                <div
                    *ngIf="!images[index]"
                    (click)="addImage()"
                    class="flex flex-col justify-center items-center w-full h-full cursor-pointer"
                >
                    <span class="text-sm">{{
                        "shared.media-library.add-image" | transloco
                    }}</span>
                    <div class="flex items-center gap-2 text-2xl">
                        <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                        <span class="font-extrabold">
                            {{ "shared.media-library.add" | transloco }}</span
                        >
                    </div>
                </div>

                <div *ngIf="images[index]" class="w-full h-full">
                    <div class="absolute z-10 top-2 left-2">
                        <bslk-icon-button
                            (click)="removeImage(images[index].id)"
                            [matTooltip]="
                                'shared.media-library.remove' | transloco
                            "
                            [icon]="'heroicons_solid:trash'"
                        ></bslk-icon-button>
                    </div>
                    <img
                        [src]="images[index].url"
                        class="h-full w-full object-cover"
                    />
                </div>
            </div>
        </div>
    </div>
</base-dialog>
