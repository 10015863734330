<div class="flex flex-col gap-6">
    <div
        class="flex items-end justify-between pb-1 border-b-2 border-slate-300"
    >
        <span class="text-4xl truncate font-bold">{{
            "dualquiz.my-opponents" | transloco
        }}</span>
    </div>
    <div
        *ngIf="!(isLoading$ | async); else loading"
        class="flex flex-col gap-4"
    >
        <div
            *ngIf="(matchUps$ | async).length > 0; else noOpponents"
            class="flex flex-col gap-4"
        >
            <div
                [ngClass]="{
                    'bg-tertiary': matchUp.victoryCount > matchUp.defeatCount,
                    'bg-warn': matchUp.victoryCount === matchUp.defeatCount,
                    'bg-primary': matchUp.victoryCount < matchUp.defeatCount
                }"
                class="flex shadow-lg rounded-2xl p-4 bg-opacity-60"
                *ngFor="let matchUp of matchUps$ | async"
            >
                <basile-avatar
                    [background]="getColor(matchUp)"
                    class="flex flex-shrink-0 w-20 h-20"
                    [configuration]="matchUp.basileConfiguration"
                ></basile-avatar>
                <div class="flex flex-col items-center justify-around grow">
                    <div class="text-xl font-bold text-white">
                        {{ matchUp.user.name }}
                    </div>
                    <div
                        [matTooltip]="
                            matchUp.victoryCount +
                            ' ' +
                            ('dualquiz.victories' | transloco) +
                            ', ' +
                            matchUp.drawCount +
                            ' ' +
                            ('dualquiz.draws' | transloco) +
                            ', ' +
                            matchUp.defeatCount +
                            ' ' +
                            ('dualquiz.defeats' | transloco)
                        "
                    >
                        <bslk-tag [background]="'white'">
                            <div class="text-lg text-black flex gap-3">
                                <div
                                    class="flex items-center gap-1 text-tertiary"
                                >
                                    <mat-icon
                                        [svgIcon]="'heroicons_outline:trophy'"
                                        class="text-current icon-size-5"
                                    ></mat-icon>
                                    <span class="font-bold">{{
                                        matchUp.victoryCount
                                    }}</span>
                                </div>

                                <div class="flex items-center gap-1 text-warn">
                                    <mat-icon
                                        [svgIcon]="'heroicons_outline:scale'"
                                        class="text-current icon-size-5"
                                    ></mat-icon>
                                    <span class="font-bold">{{
                                        matchUp.drawCount
                                    }}</span>
                                </div>

                                <div
                                    class="flex items-center gap-1 text-primary"
                                >
                                    <mat-icon
                                        [svgIcon]="'feather:frown'"
                                        class="text-current icon-size-5"
                                    ></mat-icon>
                                    <span class="font-bold">{{
                                        matchUp.defeatCount
                                    }}</span>
                                </div>
                            </div></bslk-tag
                        >
                    </div>
                </div>

                <div class="flex">
                    <bslk-icon-button
                        (click)="startChallenge(matchUp.user.id)"
                        [tooltip]="
                            ('dualquiz.challenge' | transloco) +
                            ' ' +
                            matchUp.user.name
                        "
                        [color]="getColor(matchUp)"
                        [icon]="'heroicons_outline:rocket-launch'"
                    ></bslk-icon-button>
                </div>
            </div>
        </div>

        <div
            *ngIf="totalPages$ > 1"
            class="overflow-hidden bg-white rounded-2xl"
        >
            <mat-paginator
                class="bg-transparent border-t"
                [ngClass]="{ 'pointer-events-none': isLoading$ | async }"
                [length]="totalCount$ | async"
                [pageIndex]="pageIndex$ | async"
                [pageSize]="pageSize$ | async"
                [hidePageSize]="true"
                (page)="pageChangeEvent($event)"
                [showFirstLastButtons]="true"
            >
            </mat-paginator>
        </div>
    </div>
</div>

<ng-template #noOpponents>
    <div class="flex flex-col items-center gap-6">
        <mat-icon
            class="text-current icon-size-10"
            svgIcon="heroicons_outline:users"
        ></mat-icon>
        <span class="font-bold text-lg">{{
            "dualquiz.no-opponents" | transloco
        }}</span>
    </div>
</ng-template>

<ng-template #loading>
    <div class="w-full rounded-2xl flex flex-col h-140 overflow-hidden">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-full"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
