import { Injectable } from '@angular/core';
import { TrackedActivityViewModel } from 'app/api';
import { TrackedActivityControlledService } from 'app/api/api/trackedActivityControlled.service';
import { first } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActivityService {
    constructor(
        private trackedActivityService: TrackedActivityControlledService
    ) {}

    sendActivityRequest(trackedActivityViewModel: TrackedActivityViewModel) {
        this.trackedActivityService
            .activityPost({ trackedActivityViewModel })
            .pipe(first())
            .subscribe();
    }
}
