<div class="bg-accent mt-2 rounded-xl border">
    <project-list
        (selectedProjectsChange)="onSelectedProjectsChange($event)"
        [selectedProjectIds]="selectedProjectIds"
        [canSelect]="true"
    >
        <div class="flex w-full items-center justify-between">
            <span class="text-3xl font-bold"
                >{{ selectedProjectsCount }}
                {{ "donationcampaigns.selected-projects" | transloco }}</span
            >
            <fuse-alert [type]="'info'">{{
                "donationcampaigns.choose-projects-info" | transloco
            }}</fuse-alert>
        </div>
    </project-list>
</div>
