<mat-checkbox
    (click)="checkboxClicked($event)"
    (change)="onTeamBuildingChanged($event)"
    [value]="isTeamBuilding"
    [checked]="isTeamBuilding"
    color="primary"
>
    <span class="font-semibold">{{
        "missions.filters.team-building" | transloco
    }}</span>
</mat-checkbox>
