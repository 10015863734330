<div
    #screen
    *ngrxLet="chosenProject$ as chosenProject"
    class="bg-transparent border border-primary rounded-2xl max-w-180 relative text-black"
>
    <img
        class="absolute inset-0 object-cover w-full h-full -z-10"
        src="assets/images/logo/background.svg"
    />

    <div class="w-24 h-24 absolute top-5 left-5 -z-1">
        <img
            style="object-fit: contain; display: block; margin: 0 auto"
            src="assets/images/logo/logo.png"
            alt="logo"
        />
    </div>

    <div
        *ngrxLet="request$ as request"
        class="p-2 m-2 border-4 border-warn rounded-2xl"
    >
        <div
            class="border-4 border-double border-primary rounded-2xl px-2 md:px-8 pt-4"
        >
            <div
                class="px-3 md:px-10 py-2 md:py-5 flex flex-col items-center justify-center"
            >
                <h1
                    class="text-2xl md:text-4xl mb-3 md:mb-0 font-bold text-center"
                >
                    {{ "donationgift.donation-certificate" | transloco }}
                </h1>
                <span class="flex justify-center text-center mt-2">{{
                    "donationgift.basilik-attests" | transloco
                }}</span>
                <span
                    class="font-bold text-xl md:text-2xl my-2 md:my-3 text-center"
                    >{{ request.firstName }} {{ request.familyName }}</span
                >
                <span class="mt-1 md:mt-2 text-center">
                    {{ "donationgift.to-the-association" | transloco }}
                    <span
                        class="text-xl md:text-2xl font-bold"
                        style="display: inline-block; vertical-align: bottom"
                    >
                        {{ chosenProject.association.name }}
                    </span>
                    {{ "donationgift.within-their-project" | transloco }}
                </span>
                <span class="font-bold text-lg md:text-xl text-center mt-1">{{
                    chosenProject.name
                }}</span>
                <span class="mt-0.5 md:mt-1 text-center">{{
                    "donationgift.funding-achieved" | transloco
                }}</span>
                <span
                    class="mt-0.5 md:mt-1 text-lg md:text-xl font-bold text-center"
                    >{{ contribution$ | async }}
                    {{ chosenProject.impactUnitDescription }}</span
                >
            </div>

            <div class="font-bold text-warn p-2 flex justify-end">
                basilik.io
            </div>
        </div>
    </div>
</div>

<div *ngIf="!hideActions" class="mt-3 flex gap-3 justify-center">
    <bslk-tag-button [icon]="'feather:download'" (click)="download()">{{
        "donationgift.download" | transloco
    }}</bslk-tag-button>
    <bslk-tag-button
        [isDisabled]="!(hasDonationRequestId$ | async)"
        [isDisabledTooltip]="'donationgift.preview-unavailable' | transloco"
        [icon]="'feather:linkedin'"
        (onClick)="shareOnLinkedIn()"
        >{{ "donationgift.share-on-linkedin" | transloco }}</bslk-tag-button
    >
</div>
