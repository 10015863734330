import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';

@Component({
    selector: 'bslk-classic-button',
    standalone: true,
    templateUrl: './classic-button.component.html',
    imports: [
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatTooltipModule,
        NgIf,
        NgClass,
        RouterModule,
    ],
})
export class BslkClassicButtonComponent {
    @Input() background = 'warn';
    @Input() textColor = 'white';
    @Input() disabledTooltip: string;
    @Input() href: string;
    @Input() icon: string;
    @Input() isDisabled = false;
    @Input() isLoading = false;
    @Input() label: string;
    @Input() size: 'normal' | 'large' = 'normal';
    @Input() type = 'button';

    @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

    constructor(private router: Router) {}

    get isDisabledOrIsLoading() {
        return this.isDisabled || this.isLoading;
    }

    onButtonClick() {
        if (this.href) {
            this.router.navigateByUrl(this.href);
        }
        this.onClick.emit();
    }
}
