export * from './association.service';
import { AssociationService } from './association.service';
export * from './associationDashboard.service';
import { AssociationDashboardService } from './associationDashboard.service';
export * from './associationJoinRequest.service';
import { AssociationJoinRequestService } from './associationJoinRequest.service';
export * from './associationLegalDocument.service';
import { AssociationLegalDocumentService } from './associationLegalDocument.service';
export * from './authentication.service';
import { AuthenticationService } from './authentication.service';
export * from './avatarAsset.service';
import { AvatarAssetService } from './avatarAsset.service';
export * from './awarenessCampaign.service';
import { AwarenessCampaignService } from './awarenessCampaign.service';
export * from './badge.service';
import { BadgeService } from './badge.service';
export * from './chat.service';
import { ChatService } from './chat.service';
export * from './company.service';
import { CompanyService } from './company.service';
export * from './companyAdminDashboard.service';
import { CompanyAdminDashboardService } from './companyAdminDashboard.service';
export * from './companyCsrApproach.service';
import { CompanyCsrApproachService } from './companyCsrApproach.service';
export * from './companyEmployee.service';
import { CompanyEmployeeService } from './companyEmployee.service';
export * from './course.service';
import { CourseService } from './course.service';
export * from './courseQuestionUserAnswer.service';
import { CourseQuestionUserAnswerService } from './courseQuestionUserAnswer.service';
export * from './courseSectionComment.service';
import { CourseSectionCommentService } from './courseSectionComment.service';
export * from './courseSurveyAnswer.service';
import { CourseSurveyAnswerService } from './courseSurveyAnswer.service';
export * from './demonstration.service';
import { DemonstrationService } from './demonstration.service';
export * from './donationCampaign.service';
import { DonationCampaignService } from './donationCampaign.service';
export * from './donationCampaignAnonymous.service';
import { DonationCampaignAnonymousService } from './donationCampaignAnonymous.service';
export * from './donationCampaignCustomization.service';
import { DonationCampaignCustomizationService } from './donationCampaignCustomization.service';
export * from './donationCampaignLegalDocument.service';
import { DonationCampaignLegalDocumentService } from './donationCampaignLegalDocument.service';
export * from './dualQuizChallenge.service';
import { DualQuizChallengeService } from './dualQuizChallenge.service';
export * from './dualQuizQuestion.service';
import { DualQuizQuestionService } from './dualQuizQuestion.service';
export * from './dynamicContent.service';
import { DynamicContentService } from './dynamicContent.service';
export * from './error.service';
import { ErrorService } from './error.service';
export * from './export.service';
import { ExportService } from './export.service';
export * from './feedback.service';
import { FeedbackService } from './feedback.service';
export * from './files.service';
import { FilesService } from './files.service';
export * from './importSheet.service';
import { ImportSheetService } from './importSheet.service';
export * from './message.service';
import { MessageService } from './message.service';
export * from './mission.service';
import { MissionService } from './mission.service';
export * from './missionRating.service';
import { MissionRatingService } from './missionRating.service';
export * from './missionSlot.service';
import { MissionSlotService } from './missionSlot.service';
export * from './missionTask.service';
import { MissionTaskService } from './missionTask.service';
export * from './notification.service';
import { NotificationService } from './notification.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './ranking.service';
import { RankingService } from './ranking.service';
export * from './registration.service';
import { RegistrationService } from './registration.service';
export * from './regularDashboard.service';
import { RegularDashboardService } from './regularDashboard.service';
export * from './reminderConfiguration.service';
import { ReminderConfigurationService } from './reminderConfiguration.service';
export * from './report.service';
import { ReportService } from './report.service';
export * from './share.service';
import { ShareService } from './share.service';
export * from './superAdminDashboard.service';
import { SuperAdminDashboardService } from './superAdminDashboard.service';
export * from './trackedActivity.service';
import { TrackedActivityService } from './trackedActivity.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './userNotification.service';
import { UserNotificationService } from './userNotification.service';
export * from './validation.service';
import { ValidationService } from './validation.service';
export const APIS = [AssociationService, AssociationDashboardService, AssociationJoinRequestService, AssociationLegalDocumentService, AuthenticationService, AvatarAssetService, AwarenessCampaignService, BadgeService, ChatService, CompanyService, CompanyAdminDashboardService, CompanyCsrApproachService, CompanyEmployeeService, CourseService, CourseQuestionUserAnswerService, CourseSectionCommentService, CourseSurveyAnswerService, DemonstrationService, DonationCampaignService, DonationCampaignAnonymousService, DonationCampaignCustomizationService, DonationCampaignLegalDocumentService, DualQuizChallengeService, DualQuizQuestionService, DynamicContentService, ErrorService, ExportService, FeedbackService, FilesService, ImportSheetService, MessageService, MissionService, MissionRatingService, MissionSlotService, MissionTaskService, NotificationService, ProjectService, RankingService, RegistrationService, RegularDashboardService, ReminderConfigurationService, ReportService, ShareService, SuperAdminDashboardService, TrackedActivityService, UserService, UserNotificationService, ValidationService];
