<div class="flex flex-col bg-gray-50 rounded-xl p-3 h-full">
    <!-- Help indications -->
    <fuse-alert
        class="flex m-auto"
        *ngIf="isDrawing || isSlotsEmpty"
        [type]="'info'"
        [appearance]="'outline'"
    >
        <span *ngIf="isSlotsEmpty && !isDrawing">
            {{ "createmission.click-to-define-start" | transloco }}
            <span class="font-bold">{{
                "createmission.the-beginning" | transloco
            }}</span>
            {{ "createmission.of-your-slot" | transloco }}
        </span>
        <span *ngIf="isDrawing">
            {{ "createmission.now-click-to-define-end" | transloco }}
            <span class="font-bold">{{
                "createmission.the-end" | transloco
            }}</span>
            {{ "createmission.of-your-slot" | transloco }}
        </span>
    </fuse-alert>

    <div
        *ngIf="editedMissionSlotFormGroup"
        class="flex flex-col gap-y-3 justify-between h-full"
    >
        <!-- Start and end date -->
        <div class="w-full flex gap-3 justify-between items-start">
            <mat-form-field
                [subscriptSizing]="'dynamic'"
                floatLabel="always"
                (click)="pickerStart.open()"
                class="w-full"
            >
                <mat-label>{{
                    "createmission.start-date" | transloco
                }}</mat-label>
                <input
                    class="cursor-pointer"
                    matInput
                    readonly
                    [matDatepicker]="pickerStart"
                    [matDatepickerFilter]="getDatesFilter"
                    [formControl]="editedMissionSlotFormGroup.controls.startDay"
                    [placeholder]="
                        'createmission.start-date-placeholder' | transloco
                    "
                />
                <mat-datepicker-toggle
                    matPrefix
                    [for]="pickerStart"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
            </mat-form-field>

            <mat-form-field
                [subscriptSizing]="'dynamic'"
                floatLabel="always"
                (click)="pickerEnd.open()"
                class="w-full"
            >
                <mat-label>{{
                    "createmission.end-date" | transloco
                }}</mat-label>
                <input
                    class="cursor-pointer"
                    [placeholder]="
                        'createmission.end-date-placeholder' | transloco
                    "
                    matInput
                    readonly
                    [matDatepicker]="pickerEnd"
                    [matDatepickerFilter]="getDatesFilter"
                    [formControl]="editedMissionSlotFormGroup.controls.endDay"
                />
                <mat-datepicker-toggle
                    matPrefix
                    [for]="pickerEnd"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
            </mat-form-field>
        </div>

        <span
            *ngIf="editedMissionSlotFormGroup.invalid"
            class="font-semibold text-primary"
        >
            {{ "createmission.date-mismatch-error" | transloco }}
        </span>

        <!-- Time picker -->
        <div
            *ngIf="displayTimePicker"
            class="w-full flex justify-between items-center"
        >
            <mat-form-field [subscriptSizing]="'dynamic'" class="w-1/2 pr-3">
                <mat-label>{{
                    "createmission.start-time" | transloco
                }}</mat-label>
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_outline:clock'"
                    matPrefix
                ></mat-icon>
                <input
                    [formControl]="
                        editedMissionSlotFormGroup.controls.startTime
                    "
                    type="time"
                    matInput
                />
                <mat-error>
                    {{ "createmission.start-time-error" | transloco }}
                </mat-error>
            </mat-form-field>

            <mat-form-field [subscriptSizing]="'dynamic'" class="w-1/2 pl-3">
                <mat-label>{{
                    "createmission.end-time" | transloco
                }}</mat-label>
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_outline:clock'"
                    matPrefix
                ></mat-icon>
                <input
                    [formControl]="editedMissionSlotFormGroup.controls.endTime"
                    type="time"
                    matInput
                />
                <mat-error>
                    {{ "createmission.end-time-error" | transloco }}
                </mat-error>
            </mat-form-field>
        </div>

        <!-- Recurrency -->
        <mat-form-field
            *ngIf="displayRecurrency"
            [subscriptSizing]="'dynamic'"
            class="w-full"
        >
            <mat-label>Récurrence</mat-label>
            <mat-select
                (selectionChange)="recurrencyChange()"
                [formControl]="
                    editedMissionSlotFormGroup.controls.recurrenceType
                "
            >
                <mat-option
                    *ngFor="
                        let recurrency of getEnumValues(EMissionRecurrenceType)
                    "
                    [value]="recurrency"
                >
                    {{
                        "enums.EMissionRecurrenceType." + recurrency | transloco
                    }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Custom Recurrency -->
        <mat-form-field
            *ngIf="displayCustomRecurrence"
            appearance="fill"
            floatLabel="always"
            [subscriptSizing]="'dynamic'"
            class="w-full"
        >
            <mat-label>Fréquence personnalisée</mat-label>
            <span class="px-3" matPrefix>Tout⋅es les</span>
            <input
                (input)="recurrencyChange()"
                [formControl]="
                    editedMissionSlotFormGroup.controls.customRecurrenceUnit
                "
                matInput
                [type]="number"
                placeholder="2"
                class="w-full text-center font-bold"
            />
            <mat-select
                [formControl]="
                    editedMissionSlotFormGroup.controls.customRecurrenceType
                "
                (selectionChange)="recurrencyChange()"
                placeholder="semaine⋅s"
            >
                <mat-option
                    *ngFor="let timePeriod of getEnumValues(ETimePeriod)"
                    [value]="timePeriod"
                >
                    {{ "enums.ETimePeriod." + timePeriod | transloco }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="flex justify-end">
            <bslk-classic-button
                [label]="'Supprimer le créneau'"
                (onClick)="removeSlot()"
            ></bslk-classic-button>
        </div>
    </div>
</div>
