import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkSize } from '@bslk/types/bslk-types';

@Component({
    selector: 'bslk-star',
    standalone: true,
    templateUrl: './star.component.html',
    imports: [MatIconModule, NgIf],
})
export class BslkStarComponent {
    @Input() isFilled: boolean;
    @Input() isReadonly: boolean;
    @Input() size: BslkSize = 'medium';
}
