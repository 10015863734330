import { AsyncPipe, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DualQuizChallengeSelectors } from 'app/store/dual-quiz/challenge/challenge.selectors';

@Component({
    selector: 'dual-quiz-challenge-bar-timer',
    templateUrl: './bar-timer.component.html',
    standalone: true,
    imports: [AsyncPipe, LetDirective, TranslocoModule, NgClass],
})
export class DualQuizChallengeBarTimerComponent {
    timerPercentage$ = this.store.select(
        DualQuizChallengeSelectors.selectTimerPercentage
    );

    constructor(private store: Store) {}
}
