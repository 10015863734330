export function setIntervalX(callback, delay, repetitions) {
    var x = 0;
    const intervalId = window.setInterval(function () {
        callback();

        if (++x === repetitions) {
            window.clearInterval(intervalId);
        }
    }, delay);
    return intervalId;
}
