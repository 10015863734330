import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CompanyCsrApproachViewModel } from 'app/api';

export const CompanyCsrApproachActions = createActionGroup({
    source: 'CompanyCsrApproach',
    events: {
        'Load Csr Approach Request': emptyProps(),
        'Load Csr Approach Request Success': props<{
            csrApproach: CompanyCsrApproachViewModel;
        }>(),
        'Load Csr Approach Request Fail': props<{ error: string }>(),

        'Update Csr Approach Request': emptyProps(),
        'Update Csr Approach Request Success': props<{
            csrApproach: CompanyCsrApproachViewModel;
        }>(),
        'Update Csr Approach Request Fail': props<{ error: string }>(),

        'Update Image Request': props<{ file: File }>(),
        'Update Image Request Success': props<{ url: string }>(),
        'Update Image Request Fail': props<{ error: string }>(),

        'Remove Image Request': emptyProps(),
        'Remove Image Request Success': emptyProps(),
        'Remove Image Request Fail': props<{ error: string }>(),

        'Update Pillar Image Request': props<{
            id: number;
            file: File;
        }>(),
        'Update Pillar Image Request Success': props<{
            pillarId: number;
            url: string;
        }>(),
        'Update Pillar Image Request Fail': props<{ error: string }>(),
    },
});
