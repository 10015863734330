<div class="bg-tertiary-200 rounded-xl">
    <div
        class="flex items-center p-3 bg-tertiary text-white rounded-tl-xl rounded-tr-xl"
    >
        <mat-icon
            [svgIcon]="'heroicons_outline:academic-cap'"
            class="text-current mr-1"
        ></mat-icon>

        {{ "coursesection.basile-anecdote" | transloco }}
    </div>
    <div class="p-3 relative">
        <ng-content></ng-content>
        <!-- <div class="absolute flex gap-4 right-5 -bottom-5">
            <bslk-round-icon
                [tooltip]="'J\'apprends quelque chose !' | transloco"
                [isButton]="true"
                [color]="'green'"
                [icon]="'heroicons_outline:hand-thumb-up'"
            ></bslk-round-icon>
            <bslk-round-icon
                [tooltip]="'Je le savais déjà' | transloco"
                [color]="'red'"
                [isButton]="true"
                [icon]="'heroicons_outline:hand-thumb-down'"
            ></bslk-round-icon>
        </div> -->
    </div>
</div>
