import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'basile',
    standalone: true,
    templateUrl: './basile.component.html',
    imports: [MatButtonModule, NgIf, NgClass, NgTemplateOutlet],
})
export class BasileComponent {
    @Output() readonly buttonClicked: EventEmitter<void> =
        new EventEmitter<void>();

    @Input() text: string;
    @Input() textPostion: 'left' | 'right' = 'right';
    @Input() buttonText: string;

    onButtonClicked(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.buttonClicked.emit();
    }
}
