import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { MissionListActions } from '../mission-list/mission-list.actions';
import { MissionManageActions } from '../mission-manage/mission-manage.action';
import { MissionActions } from './mission.action';
import { MissionState } from './mission.state';

export const missionInitialState: MissionState = {
    isLoading: false,
    isCreatingLoading: false,
    isUploadFileLoading: false,
};

export const missionReducer = createReducer(
    missionInitialState,

    on(MissionActions.openDialog, (state) => {
        return {
            ...state,
            coverFile: null,
        };
    }),

    on(MissionActions.setCoverRequest, (state, { file }) => {
        return {
            ...state,
            coverFile: file,
        };
    }),

    on(MissionActions.createMissionRequest, (state) => {
        return {
            ...state,
            isCreatingLoading: true,
        };
    }),

    on(MissionActions.createMissionRequestSuccess, (state, { id }) => {
        return {
            ...state,
            isCreatingLoading: false,
        };
    }),

    on(MissionActions.createMissionRequestFail, (state, { error }) => {
        return {
            ...state,
            isCreatingLoading: false,
            error,
        };
    }),

    on(MissionListActions.UpdateCover, (state) => {
        return {
            ...state,
            isUploadFileLoading: true,
        };
    }),

    on(MissionActions.updateCoverRequestFail, (state) => {
        return {
            ...state,
            isUploadFileLoading: false,
        };
    }),

    on(MissionActions.updateCoverRequestSuccess, (state) => {
        return {
            ...state,
            coverFile: null,
            isUploadFileLoading: false,
        };
    }),

    on(MissionActions.loadMissionRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(MissionActions.loadMissionRequestSuccess, (state, { mission }) => {
        return {
            ...state,
            mission,
            isLoading: false,
        };
    }),

    on(MissionActions.loadMissionRequestFail, (state, { error }) => {
        return {
            ...state,
            error,
            isLoading: false,
        };
    }),

    on(MissionActions.updateMissionRequest, (state) => {
        return {
            ...state,
            isCreatingLoading: true,
        };
    }),

    on(MissionActions.updateMissionRequestSuccess, (state) => {
        return {
            ...state,
            isCreatingLoading: false,
        };
    }),

    on(MissionActions.updateMissionRequestFail, (state, { error }) => {
        return {
            ...state,
            isCreatingLoading: false,
            error,
        };
    }),

    on(MissionActions.copyMissionRequest, (state) => {
        return {
            ...state,
            isCreatingLoading: true,
        };
    }),

    on(MissionActions.copyMissionRequestSuccess, (state) => {
        return {
            ...state,
            isCreatingLoading: false,
        };
    }),

    on(MissionActions.copyMissionRequestFail, (state, { error }) => {
        return {
            ...state,
            isCreatingLoading: false,
            error,
        };
    }),

    on(
        MissionManageActions.registerMissionSlotRequestSuccess,
        (state, { slotId, userId }) =>
            produce(state, (draftState) => {
                const slot = draftState.mission.missionSlots.find(
                    (s) => s.id === slotId
                );
                const user = { user: { id: userId } };
                slot.users = !slot.users ? [user] : [...slot.users, user];
                slot.availableSpotsLeft -= 1;
            })
    )
);
