/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AwarenessUserCampaignProgressionViewModelPaginatedListDto } from '../model/awarenessUserCampaignProgressionViewModelPaginatedListDto';
// @ts-ignore
import { DecimalPeriodChart } from '../model/decimalPeriodChart';
// @ts-ignore
import { DecimalValueChart } from '../model/decimalValueChart';
// @ts-ignore
import { EChartTimePeriod } from '../model/eChartTimePeriod';
// @ts-ignore
import { EGenderDecimalEnumChart } from '../model/eGenderDecimalEnumChart';
// @ts-ignore
import { ESustainableDevelopmentGoalInt32EnumChart } from '../model/eSustainableDevelopmentGoalInt32EnumChart';
// @ts-ignore
import { ESustainableDevelopmentGoalSustainableDevelopmentGoalMissionKpisEnumChart } from '../model/eSustainableDevelopmentGoalSustainableDevelopmentGoalMissionKpisEnumChart';
// @ts-ignore
import { MissionRegisteredViewModel } from '../model/missionRegisteredViewModel';
// @ts-ignore
import { MissionRegisteredViewModelPaginatedListDto } from '../model/missionRegisteredViewModelPaginatedListDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CompanyAdminDashboardAllCompanyMissionsGetRequestParams {
    companyId?: number;
    timePeriod?: EChartTimePeriod;
    nameContains?: string;
    /** Period offset, i.e if TimePeriod is Year and PeriodOffset is 1, then data from previous year will be returned */
    periodOffset?: number;
    isCumulative?: boolean;
    pageIndex?: number;
}

export interface CompanyAdminDashboardAwarenessProgressionGetRequestParams {
    companyId?: number;
    timePeriod?: EChartTimePeriod;
    nameContains?: string;
    /** Period offset, i.e if TimePeriod is Year and PeriodOffset is 1, then data from previous year will be returned */
    periodOffset?: number;
    isCumulative?: boolean;
    pageIndex?: number;
}

export interface CompanyAdminDashboardAwarenessRateGetRequestParams {
    companyId?: number;
    timePeriod?: EChartTimePeriod;
    nameContains?: string;
    /** Period offset, i.e if TimePeriod is Year and PeriodOffset is 1, then data from previous year will be returned */
    periodOffset?: number;
    isCumulative?: boolean;
    pageIndex?: number;
}

export interface CompanyAdminDashboardDualQuizAnswersBySdgGetRequestParams {
    companyId?: number;
    timePeriod?: EChartTimePeriod;
    nameContains?: string;
    /** Period offset, i.e if TimePeriod is Year and PeriodOffset is 1, then data from previous year will be returned */
    periodOffset?: number;
    isCumulative?: boolean;
    pageIndex?: number;
}

export interface CompanyAdminDashboardEmployeesOnAssignementGetRequestParams {
    companyId?: number;
    timePeriod?: EChartTimePeriod;
    nameContains?: string;
    /** Period offset, i.e if TimePeriod is Year and PeriodOffset is 1, then data from previous year will be returned */
    periodOffset?: number;
    isCumulative?: boolean;
    pageIndex?: number;
}

export interface CompanyAdminDashboardGenderParticipationRepartitionGetRequestParams {
    companyId?: number;
    timePeriod?: EChartTimePeriod;
    nameContains?: string;
    /** Period offset, i.e if TimePeriod is Year and PeriodOffset is 1, then data from previous year will be returned */
    periodOffset?: number;
    isCumulative?: boolean;
    pageIndex?: number;
}

export interface CompanyAdminDashboardMissionKpisBySdgGetRequestParams {
    companyId?: number;
    timePeriod?: EChartTimePeriod;
    nameContains?: string;
    /** Period offset, i.e if TimePeriod is Year and PeriodOffset is 1, then data from previous year will be returned */
    periodOffset?: number;
    isCumulative?: boolean;
    pageIndex?: number;
}

export interface CompanyAdminDashboardParticipationRateGetRequestParams {
    companyId?: number;
    timePeriod?: EChartTimePeriod;
    nameContains?: string;
    /** Period offset, i.e if TimePeriod is Year and PeriodOffset is 1, then data from previous year will be returned */
    periodOffset?: number;
    isCumulative?: boolean;
    pageIndex?: number;
}

export interface CompanyAdminDashboardSatisfactionRateGetRequestParams {
    companyId?: number;
    timePeriod?: EChartTimePeriod;
    nameContains?: string;
    /** Period offset, i.e if TimePeriod is Year and PeriodOffset is 1, then data from previous year will be returned */
    periodOffset?: number;
    isCumulative?: boolean;
    pageIndex?: number;
}


@Injectable({
  providedIn: 'root'
})
export class CompanyAdminDashboardService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * [CompanyAdmin] Get all missions done by company users
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyAdminDashboardAllCompanyMissionsGet(requestParameters: CompanyAdminDashboardAllCompanyMissionsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MissionRegisteredViewModelPaginatedListDto>;
    public companyAdminDashboardAllCompanyMissionsGet(requestParameters: CompanyAdminDashboardAllCompanyMissionsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MissionRegisteredViewModelPaginatedListDto>>;
    public companyAdminDashboardAllCompanyMissionsGet(requestParameters: CompanyAdminDashboardAllCompanyMissionsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MissionRegisteredViewModelPaginatedListDto>>;
    public companyAdminDashboardAllCompanyMissionsGet(requestParameters: CompanyAdminDashboardAllCompanyMissionsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const companyId = requestParameters.companyId;
        const timePeriod = requestParameters.timePeriod;
        const nameContains = requestParameters.nameContains;
        const periodOffset = requestParameters.periodOffset;
        const isCumulative = requestParameters.isCumulative;
        const pageIndex = requestParameters.pageIndex;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timePeriod, 'TimePeriod');
        }
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (periodOffset !== undefined && periodOffset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodOffset, 'PeriodOffset');
        }
        if (isCumulative !== undefined && isCumulative !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCumulative, 'IsCumulative');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/company-admin-dashboard/all-company-missions`;
        return this.httpClient.request<MissionRegisteredViewModelPaginatedListDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [CompanyAdmin] Get progression of campaign progression for all users
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyAdminDashboardAwarenessProgressionGet(requestParameters: CompanyAdminDashboardAwarenessProgressionGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AwarenessUserCampaignProgressionViewModelPaginatedListDto>;
    public companyAdminDashboardAwarenessProgressionGet(requestParameters: CompanyAdminDashboardAwarenessProgressionGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AwarenessUserCampaignProgressionViewModelPaginatedListDto>>;
    public companyAdminDashboardAwarenessProgressionGet(requestParameters: CompanyAdminDashboardAwarenessProgressionGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AwarenessUserCampaignProgressionViewModelPaginatedListDto>>;
    public companyAdminDashboardAwarenessProgressionGet(requestParameters: CompanyAdminDashboardAwarenessProgressionGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const companyId = requestParameters.companyId;
        const timePeriod = requestParameters.timePeriod;
        const nameContains = requestParameters.nameContains;
        const periodOffset = requestParameters.periodOffset;
        const isCumulative = requestParameters.isCumulative;
        const pageIndex = requestParameters.pageIndex;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timePeriod, 'TimePeriod');
        }
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (periodOffset !== undefined && periodOffset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodOffset, 'PeriodOffset');
        }
        if (isCumulative !== undefined && isCumulative !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCumulative, 'IsCumulative');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/company-admin-dashboard/awareness-progression`;
        return this.httpClient.request<AwarenessUserCampaignProgressionViewModelPaginatedListDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [CompanyAdmin] Get awareness rate
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyAdminDashboardAwarenessRateGet(requestParameters: CompanyAdminDashboardAwarenessRateGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DecimalPeriodChart>;
    public companyAdminDashboardAwarenessRateGet(requestParameters: CompanyAdminDashboardAwarenessRateGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DecimalPeriodChart>>;
    public companyAdminDashboardAwarenessRateGet(requestParameters: CompanyAdminDashboardAwarenessRateGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DecimalPeriodChart>>;
    public companyAdminDashboardAwarenessRateGet(requestParameters: CompanyAdminDashboardAwarenessRateGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const companyId = requestParameters.companyId;
        const timePeriod = requestParameters.timePeriod;
        const nameContains = requestParameters.nameContains;
        const periodOffset = requestParameters.periodOffset;
        const isCumulative = requestParameters.isCumulative;
        const pageIndex = requestParameters.pageIndex;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timePeriod, 'TimePeriod');
        }
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (periodOffset !== undefined && periodOffset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodOffset, 'PeriodOffset');
        }
        if (isCumulative !== undefined && isCumulative !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCumulative, 'IsCumulative');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/company-admin-dashboard/awareness-rate`;
        return this.httpClient.request<DecimalPeriodChart>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [CompanyAdmin] Get dual quiz answered questions
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyAdminDashboardDualQuizAnswersBySdgGet(requestParameters: CompanyAdminDashboardDualQuizAnswersBySdgGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ESustainableDevelopmentGoalInt32EnumChart>;
    public companyAdminDashboardDualQuizAnswersBySdgGet(requestParameters: CompanyAdminDashboardDualQuizAnswersBySdgGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ESustainableDevelopmentGoalInt32EnumChart>>;
    public companyAdminDashboardDualQuizAnswersBySdgGet(requestParameters: CompanyAdminDashboardDualQuizAnswersBySdgGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ESustainableDevelopmentGoalInt32EnumChart>>;
    public companyAdminDashboardDualQuizAnswersBySdgGet(requestParameters: CompanyAdminDashboardDualQuizAnswersBySdgGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const companyId = requestParameters.companyId;
        const timePeriod = requestParameters.timePeriod;
        const nameContains = requestParameters.nameContains;
        const periodOffset = requestParameters.periodOffset;
        const isCumulative = requestParameters.isCumulative;
        const pageIndex = requestParameters.pageIndex;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timePeriod, 'TimePeriod');
        }
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (periodOffset !== undefined && periodOffset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodOffset, 'PeriodOffset');
        }
        if (isCumulative !== undefined && isCumulative !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCumulative, 'IsCumulative');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/company-admin-dashboard/dual-quiz-answers-by-sdg`;
        return this.httpClient.request<ESustainableDevelopmentGoalInt32EnumChart>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [CompanyAdmin] Get all employees on assignment
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyAdminDashboardEmployeesOnAssignementGet(requestParameters: CompanyAdminDashboardEmployeesOnAssignementGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<MissionRegisteredViewModel>>;
    public companyAdminDashboardEmployeesOnAssignementGet(requestParameters: CompanyAdminDashboardEmployeesOnAssignementGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<MissionRegisteredViewModel>>>;
    public companyAdminDashboardEmployeesOnAssignementGet(requestParameters: CompanyAdminDashboardEmployeesOnAssignementGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<MissionRegisteredViewModel>>>;
    public companyAdminDashboardEmployeesOnAssignementGet(requestParameters: CompanyAdminDashboardEmployeesOnAssignementGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const companyId = requestParameters.companyId;
        const timePeriod = requestParameters.timePeriod;
        const nameContains = requestParameters.nameContains;
        const periodOffset = requestParameters.periodOffset;
        const isCumulative = requestParameters.isCumulative;
        const pageIndex = requestParameters.pageIndex;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timePeriod, 'TimePeriod');
        }
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (periodOffset !== undefined && periodOffset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodOffset, 'PeriodOffset');
        }
        if (isCumulative !== undefined && isCumulative !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCumulative, 'IsCumulative');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/company-admin-dashboard/employees-on-assignement`;
        return this.httpClient.request<Array<MissionRegisteredViewModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [CompanyAdmin] Get users participation rate by gender for a specific period
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyAdminDashboardGenderParticipationRepartitionGet(requestParameters: CompanyAdminDashboardGenderParticipationRepartitionGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EGenderDecimalEnumChart>;
    public companyAdminDashboardGenderParticipationRepartitionGet(requestParameters: CompanyAdminDashboardGenderParticipationRepartitionGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EGenderDecimalEnumChart>>;
    public companyAdminDashboardGenderParticipationRepartitionGet(requestParameters: CompanyAdminDashboardGenderParticipationRepartitionGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EGenderDecimalEnumChart>>;
    public companyAdminDashboardGenderParticipationRepartitionGet(requestParameters: CompanyAdminDashboardGenderParticipationRepartitionGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const companyId = requestParameters.companyId;
        const timePeriod = requestParameters.timePeriod;
        const nameContains = requestParameters.nameContains;
        const periodOffset = requestParameters.periodOffset;
        const isCumulative = requestParameters.isCumulative;
        const pageIndex = requestParameters.pageIndex;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timePeriod, 'TimePeriod');
        }
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (periodOffset !== undefined && periodOffset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodOffset, 'PeriodOffset');
        }
        if (isCumulative !== undefined && isCumulative !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCumulative, 'IsCumulative');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/company-admin-dashboard/gender-participation-repartition`;
        return this.httpClient.request<EGenderDecimalEnumChart>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [CompanyAdmin] Get mission kpis by SDG
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyAdminDashboardMissionKpisBySdgGet(requestParameters: CompanyAdminDashboardMissionKpisBySdgGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ESustainableDevelopmentGoalSustainableDevelopmentGoalMissionKpisEnumChart>;
    public companyAdminDashboardMissionKpisBySdgGet(requestParameters: CompanyAdminDashboardMissionKpisBySdgGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ESustainableDevelopmentGoalSustainableDevelopmentGoalMissionKpisEnumChart>>;
    public companyAdminDashboardMissionKpisBySdgGet(requestParameters: CompanyAdminDashboardMissionKpisBySdgGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ESustainableDevelopmentGoalSustainableDevelopmentGoalMissionKpisEnumChart>>;
    public companyAdminDashboardMissionKpisBySdgGet(requestParameters: CompanyAdminDashboardMissionKpisBySdgGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const companyId = requestParameters.companyId;
        const timePeriod = requestParameters.timePeriod;
        const nameContains = requestParameters.nameContains;
        const periodOffset = requestParameters.periodOffset;
        const isCumulative = requestParameters.isCumulative;
        const pageIndex = requestParameters.pageIndex;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timePeriod, 'TimePeriod');
        }
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (periodOffset !== undefined && periodOffset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodOffset, 'PeriodOffset');
        }
        if (isCumulative !== undefined && isCumulative !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCumulative, 'IsCumulative');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/company-admin-dashboard/mission-kpis-by-sdg`;
        return this.httpClient.request<ESustainableDevelopmentGoalSustainableDevelopmentGoalMissionKpisEnumChart>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [CompanyAdmin] Get users participation rate over time
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyAdminDashboardParticipationRateGet(requestParameters: CompanyAdminDashboardParticipationRateGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DecimalPeriodChart>;
    public companyAdminDashboardParticipationRateGet(requestParameters: CompanyAdminDashboardParticipationRateGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DecimalPeriodChart>>;
    public companyAdminDashboardParticipationRateGet(requestParameters: CompanyAdminDashboardParticipationRateGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DecimalPeriodChart>>;
    public companyAdminDashboardParticipationRateGet(requestParameters: CompanyAdminDashboardParticipationRateGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const companyId = requestParameters.companyId;
        const timePeriod = requestParameters.timePeriod;
        const nameContains = requestParameters.nameContains;
        const periodOffset = requestParameters.periodOffset;
        const isCumulative = requestParameters.isCumulative;
        const pageIndex = requestParameters.pageIndex;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timePeriod, 'TimePeriod');
        }
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (periodOffset !== undefined && periodOffset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodOffset, 'PeriodOffset');
        }
        if (isCumulative !== undefined && isCumulative !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCumulative, 'IsCumulative');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/company-admin-dashboard/participation-rate`;
        return this.httpClient.request<DecimalPeriodChart>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [CompanyAdmin] Get users satisfaction rate for all missions
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companyAdminDashboardSatisfactionRateGet(requestParameters: CompanyAdminDashboardSatisfactionRateGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DecimalValueChart>;
    public companyAdminDashboardSatisfactionRateGet(requestParameters: CompanyAdminDashboardSatisfactionRateGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DecimalValueChart>>;
    public companyAdminDashboardSatisfactionRateGet(requestParameters: CompanyAdminDashboardSatisfactionRateGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DecimalValueChart>>;
    public companyAdminDashboardSatisfactionRateGet(requestParameters: CompanyAdminDashboardSatisfactionRateGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const companyId = requestParameters.companyId;
        const timePeriod = requestParameters.timePeriod;
        const nameContains = requestParameters.nameContains;
        const periodOffset = requestParameters.periodOffset;
        const isCumulative = requestParameters.isCumulative;
        const pageIndex = requestParameters.pageIndex;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timePeriod, 'TimePeriod');
        }
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (periodOffset !== undefined && periodOffset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodOffset, 'PeriodOffset');
        }
        if (isCumulative !== undefined && isCumulative !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isCumulative, 'IsCumulative');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/company-admin-dashboard/satisfaction-rate`;
        return this.httpClient.request<DecimalValueChart>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
