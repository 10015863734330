import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import {
    NewAssetDialogComponent,
    NewAssetDialogInput,
} from './new-asset-dialog.component';

@Injectable({ providedIn: 'root' })
export class NewAssetDialogService implements IDialogService {
    private dialogRef: MatDialogRef<NewAssetDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open(input?: NewAssetDialogInput) {
        this.dialogRef = this.dialog.open(NewAssetDialogComponent, {
            data: input,
        });
    }

    close() {
        this.dialogRef.close();
    }
}
