import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { RedirectGuard } from 'app/core/auth/guards/redirect.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AssoProfileViewComponent } from '../association/profile/view/asso-profile-view.component';
import { RegularSignUpComponent } from '../auth/sign-up/regular/regular-sign-up.component';
import { CourseChapterComponent } from './awareness/courses/chapter/chapter.component';
import { CourseReviewComponent } from './awareness/courses/course-review/course-review.component';
import { CourseDashboardComponent } from './awareness/courses/dashboard/course-dashboard.component';
import { RegularDashboardComponent } from './dashboard/regular-dashboard.component';
import { MissionListComponent } from './mission-list/mission-list.component';
import { MissionManageRegularComponent } from './mission/manage/mission-manage-regular.component';
import { MissionViewComponent } from './mission/view/mission-view.component';
import { ProjectViewComponent } from './project/view/project-view.component';

export const regularRoutes = [
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [{ path: 'sign-up', component: RegularSignUpComponent }],
    },
    {
        path: '',
        canActivate: [AuthGuard, RedirectGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'dashboard',
                children: [
                    {
                        path: '',
                        component: RegularDashboardComponent,
                    },
                ],
            },
            {
                path: 'awareness',
                children: [
                    {
                        path: 'courses',
                        component: CourseDashboardComponent,
                    },
                    {
                        path: 'courses/chapter/:chapterId',
                        component: CourseChapterComponent,
                    },
                    {
                        path: 'courses/review/:courseId',
                        component: CourseReviewComponent,
                    },
                ],
            },
        ],
    },
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'missions',
                children: [
                    {
                        path: '',
                        component: MissionListComponent,
                    },
                    {
                        path: ':id',
                        component: MissionViewComponent,
                    },
                    {
                        path: ':id/manage',
                        component: MissionManageRegularComponent,
                    },
                ],
            },
            {
                path: 'projects',
                children: [
                    {
                        path: ':id',
                        component: ProjectViewComponent,
                    },
                ],
            },
            {
                path: 'organizations',
                children: [
                    {
                        path: ':id',
                        component: AssoProfileViewComponent,
                    },
                ],
            },
        ],
    },
];
