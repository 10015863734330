import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'next-chapter-section-button',
    templateUrl: './next-button.component.html',
    styleUrls: ['./next-button.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class NextChapterSectionButtonComponent {
    @Input() text: string;
    @Input() disabled = false;
}
