<div class="mb-8">
    <div class="grid grid-cols-12">
        <div class="hidden md:block col-span-4 text-center">
            <span class="text-5xl font-extrabold"
                >{{ progressPercentage }} %</span
            >
            <span class="text-lg"
                >&nbsp; {{ "coursesection.completed" | transloco }}</span
            >
        </div>
        <div class="col-span-12 md:col-span-8">
            <div class="whitespace-nowrap font-bold text-lg mb-2">
                <div
                    class="transition-all duration-1000"
                    [style]="'margin-left: ' + (progressPercentage - 3) + '%'"
                >
                    <span *ngIf="progressPercentage < 100">
                        {{ "coursesection.step" | transloco }}&nbsp;
                        {{ activeSectionIndex + 1 }}</span
                    >
                    <span *ngIf="progressPercentage >= 100">
                        {{ "coursesection.finished" | transloco }}
                    </span>
                </div>
            </div>
            <div class="w-full relative">
                <div class="w-full flex h-6">
                    <div
                        [style]="'width: ' + progressPercentage + '%'"
                        class="bg-gradient-to-r from-primary to-warn rounded-tl-full rounded-bl-full transition-all duration-1000"
                        [class.rounded-full]="progressPercentage >= 100"
                    ></div>
                    <div class="bg-white grow rounded-full"></div>
                </div>

                <div class="absolute top-0 w-full flex justify-between">
                    <div
                        *ngFor="let sectionIndex of indices"
                        class="rounded-full w-8 -mt-1 h-8 transition-all duration-1000"
                        (click)="goToSection(sectionIndex)"
                        [matTooltip]="
                            ('coursesection.go-back' | transloco) +
                            ' ' +
                            (sectionIndex + 1)
                        "
                        [matTooltipDisabled]="
                            sectionIndex >= activeSectionIndex
                        "
                        [ngClass]="
                            sectionIndex <= activeSectionIndex
                                ? 'bg-gradient-to-br from-primary to-warn border-warn cursor-pointer'
                                : 'bg-white border cursor-not-allowed'
                        "
                    ></div>
                </div>
            </div>
        </div>
    </div>
</div>
