import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DualQuizChallengeSelectors } from 'app/store/dual-quiz/challenge/challenge.selectors';
import { DualQuizChallengeNextButtonComponent } from '../next-question-button/next-button.component';

@Component({
    selector: 'dual-quiz-challenge-question-details',
    templateUrl: './question-details.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkInfoLabelComponent,
        LetDirective,
        TranslocoModule,
        NgIf,
        NgClass,
        MatIconModule,
        DualQuizChallengeNextButtonComponent,
    ],
})
export class DualQuizChallengeQuestionDetailsComponent {
    Math = Math;

    answerResponse$ = this.store.select(
        DualQuizChallengeSelectors.selectAnswerResponse
    );

    noQuestionLeft$ = this.store.select(
        DualQuizChallengeSelectors.selectNoQuestionLeft
    );

    challengeId$ = this.store.select(
        DualQuizChallengeSelectors.selectChallengeId
    );

    isSourceALink$ = this.store.select(
        DualQuizChallengeSelectors.selectIsSourceALink
    );

    isExplanationAnImage$ = this.store.select(
        DualQuizChallengeSelectors.selectIsExplanationAnImage
    );

    constructor(private store: Store) {}
}
