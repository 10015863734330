<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    (openedChanged)="openedChanged($event)"
    [name]="'editValueDrawer'"
    [position]="'right'"
    #editMetricDrawer
>
    <div class="flex flex-col w-full max-h-screen bg-card">
        <div class="flex gap-2 flex-row items-center p-6 text-white bg-warn">
            <div class="ml-3 text-2xl font-semibold tracking-tight">
                {{ "csrapproach.configure-a-metric" | transloco }}
            </div>
            <button
                class="ml-auto"
                mat-icon-button
                (click)="editMetricDrawer.close()"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"
                ></mat-icon>
            </button>
        </div>

        <div
            class="p-4 flex flex-col gap-5 justify-between h-full overflow-auto"
        >
            <form
                *ngIf="formGroup"
                class="p-3 flex flex-col gap-3"
                [formGroup]="formGroup"
            >
                <div class="w-full flex flex-col">
                    <mat-form-field [subscriptSizing]="'dynamic'">
                        <mat-label>{{
                            "csrapproach.number" | transloco
                        }}</mat-label>
                        <input
                            matInput
                            type="number"
                            [formControlName]="'number'"
                            required
                        />
                        <mat-error
                            *ngIf="
                                formGroup.controls.number.hasError('required')
                            "
                        >
                            {{ "bslk.form.required" | transloco }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <bslk-advanced-select-sync
                    *ngIf="unitsTranslationsLoaded"
                    [items]="unitsAdvancedSelectItems"
                    [group]="formGroup"
                    [controlName]="'unit'"
                    [label]="'csrapproach.unit' | transloco"
                    [searchPlaceholder]="'csrapproach.search-unit' | transloco"
                ></bslk-advanced-select-sync>

                <div class="w-full flex flex-col">
                    <mat-form-field [subscriptSizing]="'dynamic'">
                        <mat-label>{{
                            "csrapproach.description" | transloco
                        }}</mat-label>
                        <textarea
                            matInput
                            [formControlName]="'name'"
                        ></textarea>
                        <mat-error
                            *ngIf="formGroup.controls.name.hasError('required')"
                        >
                            {{ "bslk.form.required" | transloco }}
                        </mat-error>
                    </mat-form-field>
                    <bslk-length-info
                        [text]="formGroup.controls.name.value"
                        [maxLength]="150"
                    ></bslk-length-info>
                </div>

                <!-- Actions -->
                <div class="flex items-center justify-end">
                    <bslk-classic-button
                        [isDisabled]="!formGroup.valid"
                        (onClick)="update()"
                        [label]="'csrapproach.validate' | transloco"
                    ></bslk-classic-button>
                </div>
            </form>
        </div>
    </div>
</fuse-drawer>
