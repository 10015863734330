<ng-container *ngrxLet="allMissions$ as allMissions">
    <base-kpi-panel
        *ngrxLet="isLoading$ as isLoading"
        [title]="'companyadmindashboard.kpis.all-company-missions' | transloco"
        [kpiName]="kpiName"
        [isExpandable]="allMissions?.totalCount > 0"
        [isExpanded]="isExpanded$ | async"
        [isLoading]="isLoading"
    >
        <ng-container *ngIf="!isLoading">
            <div
                class="flex flex-col justify-center items-center gap-4 h-full"
                *ngIf="allMissions.totalCount > 0"
            >
                <span class="font-extrabold text-5xl w-1/3 text-center">{{
                    allMissions.totalCount
                }}</span>
                <span class="text-xl text-hint w-2/3 text-center">{{
                    "companyadmindashboard.missions-done" | transloco
                }}</span>
            </div>

            <div
                *ngIf="allMissions.totalCount === 0"
                class="flex flex-col justify-center h-full"
            >
                <mat-icon
                    [svgIcon]="'heroicons_outline:face-frown'"
                    class="icon-size-16 mx-auto"
                ></mat-icon>
                <span class="text-xl font-weight mt-2 text-center">{{
                    "companyadmindashboard.no-mission-done" | transloco
                }}</span>
            </div>
        </ng-container>

        <div *ngIf="!isLoading" expandedContent>
            <div class="relative overflow-x-scroll">
                <table class="w-full text-left mt-1">
                    <thead
                        class="bg-warn shadow-outside text-lg shadow-warn-50 text-white"
                    >
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                {{ "companyadmindashboard.goals" | transloco }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{
                                    "companyadmindashboard.mission" | transloco
                                }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{
                                    "companyadmindashboard.employee" | transloco
                                }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{
                                    "companyadmindashboard.completion"
                                        | transloco
                                }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{ "companyadmindashboard.status" | transloco }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let mission of allMissions.data"
                            class="bg-white border-b"
                        >
                            <th
                                scope="row"
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <div class="flex flex-wrap gap-1">
                                    <div
                                        *ngFor="
                                            let goal of mission.sustainableDevelopmentGoals
                                        "
                                        [style]="
                                            'background-color:' +
                                            sustainableDevelopmentGoals[goal]
                                                .color +
                                            ';'
                                        "
                                        [matTooltip]="
                                            'enums.ESustainableDevelopmentGoal.' +
                                                goal | transloco
                                        "
                                        class="flex h-10 w-10 rounded justify-center items-center"
                                    >
                                        <mat-icon
                                            class="text-white"
                                            [svgIcon]="
                                                sustainableDevelopmentGoals[
                                                    goal
                                                ].icon
                                            "
                                        ></mat-icon>
                                    </div>
                                </div>
                            </th>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <div class="flex">
                                    <img
                                        class="w-10 h-10 my-auto rounded-lg mr-2"
                                        [src]="mission.coverUrl"
                                    />
                                    <div
                                        *ngIf="!mission.coverUrl"
                                        class="w-10 h-10 my-auto rounded-lg mr-2 bg-gray-200"
                                    ></div>
                                    <div class="flex flex-col truncate">
                                        <span class="font-bold truncate">{{
                                            mission.name
                                        }}</span>
                                        <span class="text-hint truncate">{{
                                            mission.association.name
                                        }}</span>
                                    </div>
                                </div>
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                {{ mission.user.firstName }}
                                {{ mission.user.familyName }}
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <bslk-classic-progress-bar
                                    [progress]="
                                        calculateDateRangeCompletion(mission)
                                    "
                                ></bslk-classic-progress-bar>
                            </td>

                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <mission-state
                                    [state]="mission.missionState"
                                    [showDescriptionAsTooltip]="false"
                                >
                                </mission-state>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <mat-paginator
                class="bg-warn-100"
                [ngClass]="{
                    'pointer-events-none': isLoading
                }"
                [length]="allMissions.totalCount"
                [pageIndex]="allMissions.pageIndex"
                [pageSize]="allMissions.pageSize"
                [hidePageSize]="true"
                (page)="pageChangeEvent($event)"
                [showFirstLastButtons]="false"
            ></mat-paginator>
        </div>
    </base-kpi-panel>
</ng-container>
