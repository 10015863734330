<div class="flex justify-center bg-white w-full py-1">
    <div class="flex items-center gap-3">
        <span class="text-sm">{{ "shared.common.by" | transloco }}</span>
        <a
            target="_blank"
            href="https://basilik.io"
            class="flex items-center gap-1 cursor-pointer transition-all duration-300 hover:translate-x-2"
        >
            <img class="h-12" src="assets/images/logo/logo.svg" />
            <span class="text-3xl font-bold">basilik</span>
        </a>
    </div>
</div>
