import {
    AsyncPipe,
    JsonPipe,
    KeyValuePipe,
    NgFor,
    NgIf,
} from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MatCheckboxChange,
    MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { CompanyAdminDashboardActions } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.actions';
import { CompanyAdminDashboardSelectors } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.selectors';
import {
    CompanyAdminKpi,
    CompanyAdminTab,
} from 'app/store/company/company-admin-dashboard/company-admin-dashboard.state';
import { map } from 'rxjs';

@Component({
    selector: 'company-admin-dashboard-settings-dialog',
    templateUrl: './settings-dialog.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BaseDialogComponent,
        JsonPipe,
        KeyValuePipe,
        LetDirective,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatTabsModule,
        NgFor,
        NgIf,
        TranslocoModule,
    ],
})
export class CompanyAdminDashboardSettingsDialogComponent {
    configuration$ = this.store.select(
        CompanyAdminDashboardSelectors.selectConfiguration
    );

    activeTabIndex$ = this.store
        .select(CompanyAdminDashboardSelectors.selectActiveTab)
        .pipe(
            map((activeTab) => Object.keys(this.enabledTabs).indexOf(activeTab))
        );

    enabledTabs: Record<CompanyAdminTab, boolean> = {
        overview: true,
        employee: true,
        missions: true,
        accounting: false,
    };

    constructor(
        private store: Store,
        private dialogRef: MatDialogRef<CompanyAdminDashboardSettingsDialogComponent>
    ) {}

    close() {
        this.dialogRef.close();
    }

    onConfigChange(
        kpi: CompanyAdminKpi,
        tab: CompanyAdminTab,
        event: MatCheckboxChange
    ) {
        this.store.dispatch(
            CompanyAdminDashboardActions.configureKpiDisplay({
                kpi,
                tab,
                isDisplayed: event.checked,
            })
        );
    }

    keepOriginalOrder = () => 0;
}
