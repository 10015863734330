import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyEmployeeService } from 'app/api';
import { ImportEmployeesDialogService } from 'app/modules/company-admin/setup/company-employees/import-employees-dialog/import-employees-dialog.service';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import {
    createLoadListRequestEffect,
    createReloadEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { CompanyEmployeeActions } from './company-employee.actions';
import { CompanyEmployeeSelectors } from './company-employee.selectors';

@Injectable()
export class CompanyEmployeeEffects {
    loadEmployees$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.companyEmployeeService,
        CompanyEmployeeActions,
        CompanyEmployeeSelectors,
        this.companyEmployeeService.companyEmployeeGet
    );

    reload$ = createReloadEffect(this.actions$, CompanyEmployeeActions, [
        CompanyEmployeeActions.UpdateAllRequestSuccess,
    ]);

    updateEmployees$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyEmployeeActions.UpdateAllRequest),
            exhaustMap(({ employees }) => {
                return this.companyEmployeeService
                    .companyEmployeePut({ companyEmployeeViewModel: employees })
                    .pipe(
                        map(() =>
                            CompanyEmployeeActions.UpdateAllRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                CompanyEmployeeActions.UpdateAllRequestFail({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    closeOnSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CompanyEmployeeActions.UpdateAllRequestSuccess),
                tap(() => this.importEmployeesDialogService.close())
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private companyEmployeeService: CompanyEmployeeService,
        private importEmployeesDialogService: ImportEmployeesDialogService
    ) {}
}
