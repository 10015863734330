import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { BaseBlockComponent } from '../base/base-block/base-block.component';

@Component({
    selector: 'bslk-image',
    templateUrl: './image.component.html',
    standalone: true,
    imports: [NgIf],
})
export class BslkImageComponent extends BaseBlockComponent {
    get imageUrl() {
        return this.block.imageUrl;
    }
}
