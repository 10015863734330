import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { BslkBadgeComponent } from '@bslk-business/components/badge/badge.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { BadgeViewModel } from 'app/api';
import { BasileAvatarComponent } from 'app/shared/components/avatar/basile/basile-avatar.component';
import { ERegularUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { CourseActions } from 'app/store/course/course/course.action';
import { CourseSelectors } from 'app/store/course/course/course.selectors';
import { MyBasileSelectors } from 'app/store/my-basile/my-basilie.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil, tap } from 'rxjs';
import { NextChapterSectionButtonComponent } from '../chapter/card/next-button/next-button.component';

@Component({
    selector: 'course-review',
    templateUrl: './course-review.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        BasileAvatarComponent,
        MatIconModule,
        LetDirective,
        NgIf,
        NgClass,
        AsyncPipe,
        NgxSkeletonLoaderModule,
        NgFor,
        BslkBadgeComponent,
        NextChapterSectionButtonComponent,
    ],
})
export class CourseReviewComponent implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();
    obtainedBadges: BadgeViewModel[];
    currentBadgeIndex = 0;

    basileConfig$ = this.store.select(
        MyBasileSelectors.selectBasileConfiguration
    );

    isLoading$ = this.store.select(CourseSelectors.selectIsLoadingReview);
    review$ = this.store.select(CourseSelectors.selectReview).pipe(
        tap((review) => {
            this.obtainedBadges = review?.obtainedBadges;
        })
    );

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    get currentBadge() {
        return this.obtainedBadges[this.currentBadgeIndex];
    }

    get hasPrevious() {
        return this.currentBadgeIndex > 0;
    }

    get hasNext() {
        return this.currentBadgeIndex + 1 < this.obtainedBadges.length;
    }

    ngOnInit(): void {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                const courseId = params['courseId'];
                if (courseId) {
                    this.store.dispatch(
                        CourseActions.loadCourseReviewRequest({
                            courseId,
                        })
                    );
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }

    next() {
        if (this.hasNext) {
            this.currentBadgeIndex++;
        }
    }

    previous() {
        if (this.hasPrevious) {
            this.currentBadgeIndex--;
        }
    }

    goToCoursesPage() {
        this.router.navigateByUrl(
            UrlHelpers.getRegularUrl(ERegularUrl.RegularCoursesPage)
        );
    }

    getIconFromPercentage(percentage: number) {
        if (percentage > 90) {
            return 'heroicons_solid:trophy';
        } else if (percentage > 70) {
            return 'heroicons_solid:hand-thumb-up';
        } else if (percentage > 50) {
            return 'feather:smile';
        } else if (percentage > 35) {
            return 'feather:meh';
        } else {
            return 'feather:frown';
        }
    }
}
