<div class="flex justify-center w-full my-2">
    <div
        class="px-4 py-2 bg-tertiary border border-transparent rounded-lg flex items-center gap-2 transition-all duration-300 text-white cursor-pointer hover:text-tertiary hover:bg-white hover:border-tertiary"
    >
        <mat-icon
            [svgIcon]="'heroicons_outline:cursor-arrow-rays'"
            class="text-current"
        >
        </mat-icon>
        <a target="_blank" [href]="block.url">{{
            block.urlTextPreview ?? ("bslk.page.learn-more" | transloco)
        }}</a>
    </div>
</div>
