import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicProgressBar } from '@bslk/components/progress-bar/classic-progress-bar/classic-progress-bar.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { MissionRegisteredViewModel } from 'app/api';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { EnumTagListComponent } from 'app/shared/components/enum-tag-list/enum-tag-list.component';
import { MissionStateComponent } from 'app/shared/components/mission/state/mission-state.component';
import {
    calculateDateRangeCompletion,
    formatDateRange,
} from 'app/shared/utils/date-helpers.utils';
import { SuperAdminDashboardActions } from 'app/store/super-admin/super-admin-dashboard/super-admin-dashboard.actions';
import { SuperAdminDashboardSelectors } from 'app/store/super-admin/super-admin-dashboard/super-admin-dashboard.selectors';
import {
    SuperAdminKpi,
    SuperAdminKpiExtendedEvent,
} from 'app/store/super-admin/super-admin-dashboard/super-admin-dashboard.state';
import { tap } from 'rxjs';
import { BaseKpiPanelComponent } from '../../../../company-admin/dashboard/blocks/panels/base-kpi-panel/base-kpi-panel.component';

@Component({
    selector: 'pending-missions',
    templateUrl: './pending-missions.component.html',
    standalone: true,
    imports: [
        BaseKpiPanelComponent,
        BslkClassicProgressBar,
        TranslocoModule,
        LetDirective,
        AsyncPipe,
        NgClass,
        NgFor,
        NgIf,
        ClassicAvatarComponent,
        MatTooltipModule,
        EnumTagListComponent,
        MissionStateComponent,
        MatIconModule,
        MatPaginatorModule,
    ],
})
export class PendingMissionsComponent implements OnInit {
    @Output() expands: EventEmitter<SuperAdminKpiExtendedEvent> =
        new EventEmitter<SuperAdminKpiExtendedEvent>();

    kpiName: SuperAdminKpi = 'pending-missions';

    constructor(
        private store: Store,
        private cdRef: ChangeDetectorRef,
        private datePipe: DatePipe
    ) {}

    isLoading$ = this.store
        .select(SuperAdminDashboardSelectors.selectIsLoading(this.kpiName))
        .pipe(
            tap(() => {
                this.cdRef.detectChanges();
            })
        );

    pendingMissions$ = this.store.select(
        SuperAdminDashboardSelectors.selectKpiResult(this.kpiName)
    );

    ngOnInit() {
        this.store.dispatch(
            SuperAdminDashboardActions.checkIfKpiIsLoaded({
                kpi: this.kpiName,
            })
        );
    }

    formatDateRange(startDate: string, endDate: string): string {
        return formatDateRange(this.datePipe, startDate, endDate);
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            SuperAdminDashboardActions.updateKpiFilters({
                kpi: this.kpiName,
                filters: {
                    pageIndex: event.pageIndex,
                },
            })
        );
    }

    calculateDateRangeCompletion(mission: MissionRegisteredViewModel) {
        return calculateDateRangeCompletion(mission.startDate, mission.endDate);
    }
}
