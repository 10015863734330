<div class="w-full" *ngrxLet="association$ as association">
    <cover-template
        *ngrxLet="isLoading$ as isLoading"
        [coverUrl]="association?.coverUrl"
        [logoUrl]="association?.logoUrl"
        [logoFirstLetter]="association?.name[0]"
        [title]="association?.name"
        [isLoading]="isLoading"
    >
        <div avatarActions>
            <button
                *ngIf="canEdit$ | async"
                #logoButton
                mat-mini-fab
                class="relative"
                (click)="openUploadLogoDialog()"
                color="primary"
            >
                <mat-icon
                    color="accent"
                    class="icon-size-5 svg-white"
                    matPrefix
                    [svgIcon]="'heroicons_solid:camera'"
                ></mat-icon>
                <element-highlighter
                    [target]="logoButtonRef.nativeElement"
                    *ngIf="viewInitialized && isAssoLogoHighlighted$ | async"
                ></element-highlighter>
            </button>
        </div>

        <div
            *ngrxLet="activityFields$ as activityFields"
            class="flex justify-end grow"
            headerActions
        >
            <enum-tag-list
                *ngIf="!isLoading"
                [tags]="activityFields"
                [enum]="EActivityField"
                [maxTagsVisible]="3"
            ></enum-tag-list>
        </div>

        <div *ngrxLet="canEdit$ as canEdit" coverActions>
            <button
                #coverButton
                (click)="openUploadCoverDialog()"
                *ngIf="canEdit"
                mat-flat-button
                class="border-2 border-solid border-primary bg-white mr-3"
            >
                <mat-icon
                    class="icon-size-5 text-current"
                    [svgIcon]="'heroicons_outline:camera'"
                ></mat-icon>
                <span class="ml-2">{{
                    "associationview.edit-cover" | transloco
                }}</span>
                <element-highlighter
                    [position]="'left'"
                    [target]="coverButtonRef.nativeElement"
                    *ngIf="viewInitialized && isAssoCoverHighlighted$ | async"
                >
                </element-highlighter>
            </button>
            <a
                *ngIf="canEdit"
                [routerLink]="'getAssociationEditPage' | urlHelper"
            >
                <button
                    mat-flat-button
                    class="relative border-2 mt-2 lg:mt-0 border-solid border-primary bg-white"
                >
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_outline:pencil'"
                    ></mat-icon>
                    <span class="ml-2">{{
                        "associationview.edit" | transloco
                    }}</span>
                </button>
            </a>
        </div>

        <div class="w-full" leftColumn>
            <bslk-classic-card
                *ngIf="!isLoading"
                [title]="'associationview.location' | transloco"
                class="w-full"
            >
                <div class="mb-3 font-bold text-sm">
                    {{ "associationview.visit-us" | transloco }}
                </div>
                <div class="flex flex-col">
                    <div class="flex items-center mb-3">
                        <mat-icon
                            class="icon-size-5 mr-3"
                            [svgIcon]="'heroicons_solid:map-pin'"
                        ></mat-icon>
                        <span
                            *ngIf="hasLocation$ | async"
                            class="leading-none"
                            >{{ locationShort$ | async }}</span
                        >
                        <span
                            *ngIf="!(hasLocation$ | async)"
                            class="leading-none"
                            >{{
                                "associationview.no-location" | transloco
                            }}</span
                        >
                    </div>
                    <div
                        *ngrxLet="hasAddress$ as hasAddress"
                        class="flex items-center"
                    >
                        <mat-icon
                            class="icon-size-5 mr-3"
                            [svgIcon]="'heroicons_solid:home'"
                        ></mat-icon>
                        <span *ngIf="hasAddress" class="leading-none">{{
                            address$ | async
                        }}</span>
                        <span *ngIf="!hasAddress" class="leading-none">{{
                            "associationview.no-address" | transloco
                        }}</span>
                    </div>
                    <div
                        *ngIf="showGoogleMaps"
                        #googleMaps
                        class="mt-4 w-full h-60"
                    ></div>
                </div>
            </bslk-classic-card>
        </div>

        <bslk-classic-card
            *ngIf="!isLoading && (canEdit$ | async)"
            class="w-full mb-8"
        >
            <onboarding-progress
                class="w-full"
                [tasks]="onboardingTasks$ | async"
            ></onboarding-progress>
        </bslk-classic-card>

        <!-- Description -->
        <bslk-classic-card
            *ngIf="!isLoading"
            [title]="
                association?.name +
                ' ' +
                ('associationview.few-words' | transloco)
            "
            class="w-full"
        >
            <div
                *ngrxLet="hasDescription$ as hasDescription"
                class="flex flex-col sm:flex-row items-start mt-4"
            >
                <span *ngIf="!hasDescription">{{
                    "associationview.no-description" | transloco
                }}</span>
                <div
                    [innerHTML]="description$ | async"
                    *ngIf="hasDescription"
                ></div>
            </div>
        </bslk-classic-card>

        <fuse-card
            *ngIf="!isLoading && (hasMedia$ | async)"
            class="media-wrapper w-full mt-8"
        >
            <iframe
                [src]="media$ | async | safe"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            >
            </iframe>
        </fuse-card>

        <association-rating
            class="mt-8"
            *ngIf="!isLoading && association.rating"
            [association]="association"
        ></association-rating>
    </cover-template>
</div>
