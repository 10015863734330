import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import { DonationCampaignShareSettingsDialogComponent } from './share-settings-dialog.component';

@Injectable({ providedIn: 'root' })
export class DonationCampaignShareSettingsDialogService
    implements IDialogService
{
    private dialogRef: MatDialogRef<DonationCampaignShareSettingsDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open() {
        this.dialogRef = this.dialog.open(
            DonationCampaignShareSettingsDialogComponent
        );
    }

    close() {
        this.dialogRef?.close();
    }
}
