import { AsyncPipe, JsonPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { AwarenessCampaignFormService } from 'app/shared/services/form/awareness-campaign-form.service';
import { AwarenessCampaignSelectors } from 'app/store/awareness/awareness-campaign/awareness-campaign.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AwarenessCampaignThematicConfigurationComponent } from './thematic-configuration/thematic-configuration.component';

@Component({
    selector: 'awareness-campaign-configuration',
    templateUrl: './configuration.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        AwarenessCampaignThematicConfigurationComponent,
        BslkClassicButtonComponent,
        FuseAlertComponent,
        MatFormFieldModule,
        MatInputModule,
        NgFor,
        NgIf,
        JsonPipe,
        NgxSkeletonLoaderModule,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class AwarenessCampaignConfigurationComponent {
    @Input() showErrors: boolean;

    isLoading$ = this.store.select(
        AwarenessCampaignSelectors.selectIsLoadingActiveCampaign
    );

    constructor(
        private store: Store,
        private formService: AwarenessCampaignFormService
    ) {}

    get formGroup() {
        return this.formService.getFormGroup;
    }

    get thematicsFormArray() {
        return this.formGroup.get('thematics') as FormArray;
    }

    addThematic() {
        this.thematicsFormArray.push(this.formService.createThematic());
    }

    getInvalidThematicsControlIndices(control: string) {
        return this.thematicsFormArray.controls
            .map((formGroup: FormGroup, index: number) => {
                if (formGroup.get(control).invalid) {
                    return index;
                }
            })
            .filter((index: number | undefined) => index !== undefined);
    }
}
