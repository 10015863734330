import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BslkColor } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';
import { DateTime } from 'luxon';

@Component({
    selector: 'bslk-countdown',
    templateUrl: './countdown.component.html',
    standalone: true,
    imports: [TranslocoModule, NgIf, NgClass],
})
export class BslKCountdownComponent implements OnInit, OnDestroy {
    @Input() targetDate!: DateTime;
    @Input() showSeconds = false;
    @Input() color: BslkColor = 'tertiary';
    days: string = '00';
    hours: string = '00';
    minutes: string = '00';
    seconds: string = '00';

    private intervalId!: number;

    ngOnInit(): void {
        this.startCountdown();
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    startCountdown(): void {
        this.updateTime();

        this.intervalId = window.setInterval(
            () => {
                this.updateTime();
            },
            this.showSeconds ? 1000 : 1000 * 60
        );
    }

    updateTime(): void {
        const now = DateTime.now();
        const difference = this.targetDate
            .diff(now, ['days', 'hours', 'minutes', 'seconds'])
            .toObject();

        if (this.targetDate > now) {
            this.days = this.formatNumber(Math.floor(difference.days ?? 0));
            this.hours = this.formatNumber(Math.floor(difference.hours ?? 0));
            this.minutes = this.formatNumber(
                Math.floor(difference.minutes ?? 0)
            );
            this.seconds = this.formatNumber(
                Math.floor(difference.seconds ?? 0)
            );
        } else {
            this.clearCountdown();
        }
    }

    clearCountdown(): void {
        clearInterval(this.intervalId);
        this.days = this.hours = this.minutes = this.seconds = '00';
    }

    formatNumber(value: number): string {
        return value.toString().padStart(2, '0');
    }
}
