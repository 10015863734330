import { NgIf } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { Store } from '@ngrx/store';
import { ProjectViewComponent } from 'app/modules/regular/project/view/project-view.component';
import { ProjectActions } from 'app/store/project/project/project.action';

@Component({
    selector: 'project-drawer-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
    standalone: true,
    imports: [ProjectViewComponent, FuseDrawerComponent, NgIf, MatIconModule],
})
export class ProjectDrawerDetails implements OnChanges {
    @ViewChild('detailsDrawer') drawer: FuseDrawerComponent;

    @Input() projectId: number;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();
    @Output() projectChosen: EventEmitter<void> = new EventEmitter<void>();

    constructor(private store: Store) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.projectId && changes.projectId.currentValue) {
            this.store.dispatch(
                ProjectActions.loadProjectRequest({
                    id: this.projectId,
                })
            );
            this.openDrawer();
        }
    }

    openDrawer() {
        if (!this.drawer) {
            return;
        }
        this.drawer.open();
    }

    onClose() {
        this.drawer.close();
    }

    onChoose() {
        this.onClose();
        this.projectChosen.emit();
    }

    openedChanged(isOpened: boolean) {
        if (!isOpened) {
            this.projectId = null;
            this.close.emit();
        }
    }
}
