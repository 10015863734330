import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PageViewModel } from 'app/api';

export const DynamicContentActions = createActionGroup({
    source: 'DynamicContent',
    events: {
        'Load Good News Of The Week If Needed': emptyProps(),
        'Load Good News Of The Week Request': emptyProps(),
        'Load Good News Of The Week Request Success': props<{
            response: PageViewModel;
        }>(),
        'Load Good News Of The Week Request Fail': props<{ error: string }>(),
    },
});
