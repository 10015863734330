import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AwarenessCampaignActions } from 'app/store/awareness/awareness-campaign/awareness-campaign.action';
import { AwarenessCampaignSelectors } from 'app/store/awareness/awareness-campaign/awareness-campaign.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CourseDashboardThematicHeader } from './header/header.component';
import { CourseDashboardThematicTokensComponent } from './tokens/tokens.component';

@Component({
    selector: 'course-dashboard-thematic-timeline',
    templateUrl: './thematic-timeline.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        CourseDashboardThematicHeader,
        CourseDashboardThematicTokensComponent,
        LetDirective,
        MatIconModule,
        NgFor,
        NgIf,
        NgxSkeletonLoaderModule,
        TranslocoModule,
    ],
})
export class CourseDashboardThematicTimeline implements OnInit {
    thematicsProgress$ = this.store.select(
        AwarenessCampaignSelectors.selectThemathicsProgress
    );
    isLoading$ = this.store.select(AwarenessCampaignSelectors.selectIsLoading);
    hasOngoingCampaign$ = this.store.select(
        AwarenessCampaignSelectors.selectHasOngoingCampaign
    );

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(
            AwarenessCampaignActions.loadCurrentCampaignProgressRequest()
        );
    }
}
