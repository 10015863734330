import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { UsersActions } from './users.actions';
import { UsersState } from './users.state';

export const initialState: UsersState = ressourceListInitialState;

export const usersReducer = createRessourceListReducer(
    ressourceListInitialState,
    UsersActions
);
