import {
    DonationCampaignViewModel,
    DonationCampaignViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const DonationCampaignListActions = {
    ...createRessourceListActionGroup<
        DonationCampaignViewModel,
        DonationCampaignViewModelPaginatedListDto
    >('DonationCampaignList'),
};
