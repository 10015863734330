import { createSelector } from '@ngrx/store';
import { BadgeViewModel, EBadge } from 'app/api';
import { orderBy } from 'lodash-es';
import { AppState } from '..';

const selectState = (state: AppState) => state.badge;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);
const selectUserBadges = createSelector(selectState, (state) => state.badges);

const selectAllBadgesWithStatus = createSelector(
    selectUserBadges,
    (userBadges: BadgeViewModel[]) => {
        const allBadges: BadgeViewModel[] = Object.values(EBadge).map(
            (badgeId) => ({
                badgeId: badgeId,
            })
        );

        const combinedBadges = allBadges.map((badge) => {
            const foundBadge = userBadges.find(
                (userBadge) => userBadge.badgeId === badge.badgeId
            );
            return foundBadge || badge;
        });

        const sortedByDate = orderBy(
            combinedBadges,
            [
                (badge) =>
                    badge.dateCreated
                        ? new Date(badge.dateCreated).getTime()
                        : -1,
            ],
            ['desc']
        );

        const sortedBadges = sortedByDate.sort((a, b) => {
            const aObtained = a.dateCreated !== null;
            const bObtained = b.dateCreated !== null;
            if (aObtained && !bObtained) return -1;
            if (!aObtained && bObtained) return 1;
            return 0;
        });

        return sortedBadges;
    }
);

export const BadgeSelectors = {
    selectIsLoading,
    selectAllBadgesWithStatus,
};
