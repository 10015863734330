import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslKCountdownComponent } from '@bslk/components/countdown/countdown.component';
import { TranslocoModule } from '@ngneat/transloco';
import { DateTime } from 'luxon';

@Component({
    selector: 'course-dashboard-thematic-lock',
    templateUrl: './lock.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        MatIconModule,
        NgIf,
        NgClass,
        NgFor,
        BslKCountdownComponent,
    ],
})
export class CourseDashboardThematicLock {
    @Input() startDate: string;

    get startDateDateTime() {
        return DateTime.fromISO(this.startDate);
    }

    get isStarted() {
        return this.startDateDateTime < DateTime.now();
    }
}
