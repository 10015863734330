import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CourseSectionCommentViewModel } from 'app/api';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';
import { IFormService } from './form-service';

@Injectable({
    providedIn: 'root',
})
export class CourseSectionCommentFormService
    implements IFormService<CourseSectionCommentViewModel>
{
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder) {
        this.initForm();
    }

    get getFormGroup() {
        return this.formGroup;
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            id: [''],
            comment: [''],
            chapterId: [''],
            sectionId: [''],
        });
        return this.formGroup;
    }

    updateForm(entity: CourseSectionCommentViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
    }

    getEntity(): { [key: string]: CourseSectionCommentViewModel } {
        const courseSectionCommentViewModel =
            returnOnlyNonNullFields<CourseSectionCommentViewModel>(
                this.formGroup.value
            ) as CourseSectionCommentViewModel;

        return { courseSectionCommentViewModel };
    }
}
