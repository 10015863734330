import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AvatarAssetService } from 'app/api/api/avatarAsset.service';
import { NewAssetDialogService } from 'app/shared/components/dialog/new-asset/new-asset-dialog.service';
import { catchError, exhaustMap, filter, map, of, tap } from 'rxjs';
import { UserActions } from '../user/user/user.actions';
import { UserSelectors } from '../user/user/user.selectors';
import { MyBasileActions } from './my-basile.action';

@Injectable()
export class MyBasilikEffects {
    initBasileConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                UserActions.signInSuccess,
                UserActions.alreadySignedIn,
                MyBasileActions.loadAssetsRequest
            ),
            concatLatestFrom(() =>
                this.store.select(UserSelectors.selectIsAssoAdmin)
            ),
            filter(([, isAssoAdmin]) => {
                return !isAssoAdmin;
            }),
            map(() => MyBasileActions.loadConfigurationRequest())
        )
    );

    reloadAssetsAndConfigurationOnAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                MyBasileActions.buyAssetRequestSuccess,
                MyBasileActions.equipAssetRequestSuccess,
                MyBasileActions.unequipAssetRequestSuccess
            ),
            map(() => MyBasileActions.loadAssetsRequest())
        )
    );

    loadConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MyBasileActions.loadConfigurationRequest),
            exhaustMap(() => {
                return this.avatarAssetService.avatarAssetBasileGet().pipe(
                    map((configuration) =>
                        MyBasileActions.loadConfigurationRequestSuccess({
                            configuration,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            MyBasileActions.loadConfigurationRequestFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    loadAssets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MyBasileActions.loadAssetsRequest),
            exhaustMap(() => {
                return this.avatarAssetService.avatarAssetGet().pipe(
                    map((assets) =>
                        MyBasileActions.loadAssetsRequestSuccess({
                            assets,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            MyBasileActions.loadAssetsRequestFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    buyAsset$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MyBasileActions.buyAssetRequest),
            exhaustMap(({ asset }) => {
                return this.avatarAssetService
                    .avatarAssetAssetIdBuyPatch({ assetId: asset.id })
                    .pipe(
                        map(() =>
                            MyBasileActions.buyAssetRequestSuccess({ asset })
                        ),
                        catchError((httpResponse) =>
                            of(
                                MyBasileActions.buyAssetRequestFail({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    equipAsset$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MyBasileActions.equipAssetRequest),
            exhaustMap(({ asset }) => {
                return this.avatarAssetService
                    .avatarAssetAssetIdEquipPatch({ assetId: asset.id })
                    .pipe(
                        map(() => MyBasileActions.equipAssetRequestSuccess()),
                        catchError((httpResponse) =>
                            of(
                                MyBasileActions.equipAssetRequestFail({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    unequipAsset$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MyBasileActions.unequipAssetRequest),
            exhaustMap(({ assetType }) => {
                return this.avatarAssetService
                    .avatarAssetAvatarAssetTypeUnequipPatch({
                        avatarAssetType: assetType,
                    })
                    .pipe(
                        map(() => MyBasileActions.unequipAssetRequestSuccess()),
                        catchError((httpResponse) =>
                            of(
                                MyBasileActions.unequipAssetRequestFail({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    openNewAssetDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MyBasileActions.buyAssetRequestSuccess),
                tap(({ asset }) => {
                    this.newDialogDialogService.open({ asset });
                })
            ),
        { dispatch: false }
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private router: Router,
        private avatarAssetService: AvatarAssetService,
        private newDialogDialogService: NewAssetDialogService
    ) {}
}
