import { AsyncPipe, JsonPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EDonationCampaignState, ETrackedActivityType } from 'app/api';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { FooterComponent } from 'app/shared/components/footer/footer.component';
import { ProjectListComponent } from 'app/shared/components/project/project-list/project-list.component';
import { SanitizePipe } from 'app/shared/pipes/sanitize.pipe';
import { DonationCampaignActions } from 'app/store/donation/donation-campaign/donation-campaign.action';
import { DonationCampaignSelectors } from 'app/store/donation/donation-campaign/donation-campaign.selectors';
import { TrackedActivityActions } from 'app/store/tracked-activity/tracked-activity.actions';
import { Subject, takeUntil } from 'rxjs';
import { ChooseProjectDialogService } from './choose-project-dialog/choose-project-dialog.service';
import { GiftCardComponent } from './gift-card/gift-card.component';
import { OpenGiftComponent } from './open-gift/open-gift.component';

@Component({
    selector: 'donation-gift',
    templateUrl: './donation-gift.component.html',
    styleUrls: ['./donation-gift.component.scss'],
    standalone: true,
    animations: fuseAnimations,
    imports: [
        AsyncPipe,
        BslkTagComponent,
        BslkTagButtonComponent,
        ClassicAvatarComponent,
        FooterComponent,
        FuseAlertComponent,
        GiftCardComponent,
        JsonPipe,
        LetDirective,
        MatIconModule,
        NgClass,
        NgIf,
        MatProgressSpinnerModule,
        OpenGiftComponent,
        ProjectListComponent,
        SanitizePipe,
        TranslocoModule,
    ],
})
export class DonationGiftComponent implements OnInit, OnDestroy {
    EDonationCampaignState = EDonationCampaignState;

    unsubscribeAll: Subject<any> = new Subject<any>();
    isPreview = false;
    giftOpened = false;
    giftOpenedEndAnimation = false;

    campaign$ = this.store.select(
        DonationCampaignSelectors.selectDonationCampaign
    );
    isLoading$ = this.store.select(DonationCampaignSelectors.selectIsLoading);

    hasChosenProject$ = this.store.select(
        DonationCampaignSelectors.selectHasChosenProject
    );

    previewUrl$ = this.store.select(
        DonationCampaignSelectors.selectDonationGiftPreviewUrl
    );

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private chooseProjectDialogService: ChooseProjectDialogService
    ) {}

    ngOnInit() {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                const token = params['token'];
                if (token) {
                    this.store.dispatch(
                        DonationCampaignActions.loadDonationCampaignRequestByToken(
                            {
                                token,
                            }
                        )
                    );
                    this.store.dispatch(
                        TrackedActivityActions.logActivityRequest({
                            activity: {
                                trackedActivityType:
                                    ETrackedActivityType.DonationGiftLink,
                                details: token,
                            },
                        })
                    );
                }
            });

        this.route.data
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((data) => {
                this.isPreview = data.isPreview;
            });
    }

    onProjectChosen(id: number, isOneEnvelope: boolean) {
        this.store.dispatch(DonationCampaignActions.chooseProject({ id }));
        this.chooseProjectDialogService.open({
            isPreview: this.isPreview,
            isOneEnvelope,
        });
    }

    onGiftOpened() {
        this.giftOpened = true;
        setTimeout(() => {
            this.giftOpenedEndAnimation = true;
        }, 4000);
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
