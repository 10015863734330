import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BlockViewModel } from 'app/api';
import { BaseRichTextBlockComponent } from '../base/rich-text-block/base-rich-text-block.component';
import { RichTextTemplateComponent } from '../base/rich-text-block/rich-text-template/rich-text-template.component';

@Component({
    selector: 'bslk-bullet-list-item',
    templateUrl: './bullet-list-item.component.html',
    standalone: true,
    imports: [NgIf, RichTextTemplateComponent],
})
export class BslkBulletListItemComponent extends BaseRichTextBlockComponent {
    @Input() block: BlockViewModel;
}
