<bslk-classic-card
    *ngIf="mission"
    [title]="'missionmanage.responsible-user' | transloco"
>
    <div class="flex justify-between items-center">
        <div class="flex overflow-hidden">
            <classic-avatar
                class="mr-4"
                [avatarUrl]="registeredResponsibleUser?.avatarUrl"
                [fallbackLetter]="responsibleUserFirstLetter"
                [textSize]="4"
                [size]="14"
            >
            </classic-avatar>
            <div class="truncate m-auto mr-2">
                <span class="text-lg font-semibold">
                    <span *ngIf="registeredResponsibleUser">
                        {{ registeredResponsibleUser.firstName }}
                        {{ registeredResponsibleUser.familyName }}
                    </span>
                    <span *ngIf="notRegisteredResponsibleUserEmail">
                        {{ notRegisteredResponsibleUserEmail }}
                    </span>
                </span>
                <span class="block truncate">
                    {{ mission.associationName }}
                </span>
            </div>
        </div>
        <button
            *ngIf="registeredResponsibleUser"
            mat-flat-button
            (click)="buildChat()"
            color="warn"
            class="text-white flex-none my-auto ml-auto"
        >
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:chat-bubble-oval-left-ellipsis'"
            ></mat-icon>
            <span class="ml-2">{{ "missionmanage.contact" | transloco }}</span>
        </button>
        <a
            *ngIf="!registeredResponsibleUser"
            [href]="'mailto:' + notRegisteredResponsibleUserEmail"
        >
            <button
                mat-flat-button
                color="warn"
                class="text-white flex-none my-auto ml-auto"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:envelope'"
                ></mat-icon>
                <span class="ml-2">{{
                    "missionmanage.contact" | transloco
                }}</span>
            </button>
        </a>
    </div>
</bslk-classic-card>
