import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BadgeService } from 'app/api';
import { NewBadgeDialogService } from 'app/shared/components/dialog/new-badge/new-badge-dialog.service';
import { isEmpty } from 'lodash-es';
import { catchError, exhaustMap, filter, map, of, tap } from 'rxjs';
import { CourseActions } from '../course/course/course.action';
import { BadgeActions } from './badge.action';

@Injectable()
export class BadgeEffects {
    openDialogIfNewBadge$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    CourseActions.updateChapterCompletionRequestSuccess,
                    CourseActions.sendAnswerRequestSuccess,
                    CourseActions.sendSurveyAnswerRequestSuccess
                ),
                filter(({ badges }) => !isEmpty(badges)),
                tap(({ badges }) => {
                    this.newBadgeDialogService.open({ badges });
                })
            ),
        { dispatch: false }
    );

    loadBadges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BadgeActions.loadBadgesRequest),
            exhaustMap(() => {
                return this.badgeService.badgeGet().pipe(
                    map((badges) =>
                        BadgeActions.loadBadgesRequestSuccess({
                            badges: badges.data,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            BadgeActions.loadBadgesRequestFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private newBadgeDialogService: NewBadgeDialogService,
        private badgeService: BadgeService
    ) {}
}
