import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { SuperAdminDashboardSelectors } from 'app/store/super-admin/super-admin-dashboard/super-admin-dashboard.selectors';
import { ActiveUsersComponent } from './charts/active-users/active-users.component';
import { PendingMissionsComponent } from './panels/pending-missions/pending-missions.component';

@Component({
    selector: 'super-admin-dashboard',
    templateUrl: './super-admin-dashboard.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        LetDirective,
        NgClass,
        NgIf,
        PendingMissionsComponent,
        ActiveUsersComponent,
    ],
})
export class SuperAdminDashboardComponent {
    isPendingMissionsExtended$ = this.store.select(
        SuperAdminDashboardSelectors.selectIsKpiExpanded('pending-missions')
    );

    constructor(private store: Store) {}
}
