import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EFeature, EUserRole } from 'app/api';

export const NavigationActions = createActionGroup({
    source: 'Navigation',
    events: {
        'Init Navigation': props<{
            userRole: EUserRole;
            allowedFeatures: EFeature[];
        }>(),
        'Not Found': emptyProps(),
    },
});
