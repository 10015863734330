<div class="flex flex-col gap-y-4">
    <fuse-alert
        class="max-w-lg"
        [appearance]="'soft'"
        [showIcon]="true"
        [type]="'success'"
    >
        <span fuseAlertTitle>
            {{
                "missionview.registration.registration-success" | transloco
            }}</span
        >
        {{
            "shared.mission-state.waiting-for-association-acceptance.description"
                | transloco
        }}
    </fuse-alert>

    <fuse-alert
        *ngIf="isTeamBuilding$ | async"
        class="max-w-lg"
        [appearance]="'soft'"
        [showIcon]="true"
        [type]="'warn'"
    >
        <span fuseAlertTitle>
            {{
                "missionview.registration.add-employees-title" | transloco
            }}</span
        >
        {{ "missionview.registration.add-employees-description" | transloco }}
    </fuse-alert>

    <div *ngIf="hasResponsibleUser$ | async">
        <div
            *ngrxLet="responsibleUser$ as responsibleUser"
            class="flex flex-col items-center gap-y-3"
        >
            <span class="text-warn font-bold text-lg">
                {{
                    "missionview.registration.want-to-contact" | transloco
                }}</span
            >
            <bslk-classic-button
                (onClick)="buildChat(responsibleUser)"
                [icon]="'heroicons_outline:chat-bubble-oval-left-ellipsis'"
                [label]="
                    'missionview.registration.contact-association' | transloco
                "
            ></bslk-classic-button>
        </div>
    </div>
</div>
