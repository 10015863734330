import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ProjectListFiltersActions } from 'app/store/project/project-list-filters/project-list-filters.action';
import { ProjectListFiltersSelectors } from 'app/store/project/project-list-filters/project-list-filters.selectors';

@Component({
    selector: 'locations-filters',
    templateUrl: './locations-filters.component.html',
    standalone: true,
    imports: [
        FuseDrawerComponent,
        MatIconModule,
        TranslocoModule,
        BslkTagComponent,
        NgFor,
        AsyncPipe,
        NgIf,
        LetDirective,
        BslkTagButtonComponent,
    ],
})
export class ProjectLocationsFiltersComponent implements OnChanges {
    @ViewChild('drawer') drawer: FuseDrawerComponent;

    @Input() openEvent: string;

    filteredLocations$ = this.store.select(
        ProjectListFiltersSelectors.selectLocations
    );

    availableLocations$ = this.store.select(
        ProjectListFiltersSelectors.selectAvailableLocations
    );

    constructor(private store: Store) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.openEvent) {
            this.openDrawer();
        }
    }

    addLocation(location: string) {
        this.store.dispatch(
            ProjectListFiltersActions.addLocation({
                location,
            })
        );
    }

    removeLocation(location: string) {
        this.store.dispatch(
            ProjectListFiltersActions.removeLocation({
                location,
            })
        );
    }

    openDrawer() {
        if (!this.drawer) {
            return;
        }
        this.drawer.open();
    }

    onClose() {
        this.drawer.close();
    }
}
