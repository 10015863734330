<div
    class="w-full bg-white shadow-lg rounded-2xl flex flex-col gap-4 overflow-hidden"
>
    <div class="flex gap-2 items-center px-6 pt-6">
        <mat-icon [svgIcon]="'heroicons_outline:hand-raised'"></mat-icon>
        <div>
            <span class="text-2xl font-bold">{{
                "donationcampaign.participations" | transloco
            }}</span>
            <span class="text-hint text-lg font-semibold ml-2"
                >&nbsp;{{ selectRequestsCount$ | async }}
                {{ "donationcampaign.in-total" | transloco }}</span
            >
        </div>
    </div>
    <div *ngrxLet="requests$ as requests" class="relative overflow-x-scroll">
        <table class="w-full text-left mt-1">
            <thead
                class="bg-warn shadow-outside text-lg shadow-warn-50 text-white"
            >
                <tr>
                    <th scope="col" class="px-6 py-3">
                        {{ "donationcampaign.email" | transloco }}
                    </th>
                    <th scope="col" class="px-6 py-3 max-w-32 truncate">
                        {{ "donationcampaign.project" | transloco }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ "donationcampaign.comment" | transloco }}
                    </th>
                    <th scope="col" class="px-6 py-3">
                        {{ "donationcampaign.date" | transloco }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    [class.border-b]="!last"
                    *ngFor="let request of requests; let last = last"
                    class="bg-white"
                >
                    <th
                        scope="row"
                        class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                    >
                        <div class="flex flex-wrap gap-1">
                            {{ request.email }}
                        </div>
                    </th>
                    <td class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4">
                        <div class="col-span-5 flex items-center gap-2">
                            <classic-avatar
                                [size]="8"
                                [borderSize]="2"
                                [avatarUrl]="
                                    request.project.association.logoUrl
                                "
                                [fallbackLetter]="
                                    request.project.association.name.at(0)
                                "
                            >
                            </classic-avatar>
                            <div class="flex flex-col justify-center gap-2">
                                <span class="font-bold text-lg">{{
                                    request.project.name
                                }}</span>
                                <span>{{
                                    request.project.association.name
                                }}</span>
                            </div>
                        </div>
                    </td>
                    <td class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4">
                        <span *ngIf="request.comment"
                            >{{ request.comment }}
                        </span>
                        <span
                            class="text-hint italic"
                            *ngIf="!request.comment"
                            >{{
                                "donationcampaign.no-comment" | transloco
                            }}</span
                        >
                    </td>
                    <td class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4">
                        <span>{{
                            request.dateCreated | date : "dd/MM/yyyy HH:mm"
                        }}</span>
                    </td>
                </tr>
            </tbody>
        </table>

        <div
            *ngIf="requests?.length === 0"
            class="w-full justify-center p-6 text-center"
        >
            <span class="font-bold text-xl">{{
                "donationcampaign.no-participation-yet" | transloco
            }}</span>
        </div>
    </div>

    <div
        *ngIf="(canShowMore$ | async) || (canShowLess$ | async)"
        class="w-full pb-6 flex justify-center gap-3"
    >
        <bslk-tag-button
            (click)="showMore()"
            *ngIf="canShowMore$ | async"
            [icon]="'feather:plus'"
            >{{ "donationcampaign.show-more" | transloco }}</bslk-tag-button
        >
        <bslk-tag-button
            (click)="showLess()"
            *ngIf="canShowLess$ | async"
            [icon]="'feather:minus'"
            >{{ "donationcampaign.show-less" | transloco }}</bslk-tag-button
        >
    </div>
</div>
