<div>
    <img
        *ngIf="page.coverUrl"
        class="h-20 lg:h-30 xxl:h-40 object-cover"
        [src]="page.coverUrl"
        alt="Cover image"
    />

    <div class="relative w-fit mx-auto">
        <p class="text-3xl font-extrabold tracking-tight text-primary">
            {{ page.title }}
        </p>
    </div>

    <div>
        <bslk-page-content
            class="text-gray-600"
            [blocks]="page.blocks"
        ></bslk-page-content>

        <div class="max-w-full flex mt-6 pt-2 border-t-2 border-primary">
            <div
                class="w-1/2 flex flex-col justify-center pr-3 border-r-2"
                *ngIf="page.sources"
            >
                <div class="flex items-center font-bold text-lg mb-2">
                    <mat-icon
                        [svgIcon]="'heroicons_outline:magnifying-glass'"
                        class="mr-1 text-current icon-size-5"
                    >
                    </mat-icon>
                    <span>{{ "bslk.page.sources" | transloco }}</span>
                </div>
                <div class="flex flex-col gap-y-1">
                    <div *ngFor="let source of page.sources">
                        <a
                            class="flex items-center italic"
                            [href]="source"
                            target="_blank"
                        >
                            <mat-icon
                                [svgIcon]="'heroicons_outline:link'"
                                class="mr-1 icon-size-4 text-current"
                            >
                            </mat-icon>
                            <span class="truncate">
                                {{ source }}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="w-1/2">
                <ng-content select="[signature]"></ng-content>
            </div>
        </div>
    </div>
</div>
