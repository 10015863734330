/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EAwarenessThematic = 'CorporateSocialResponsability' | 'CrisisManagementAndResilience' | 'SustainableAndEthicalFinance' | 'TechnologicalInnovationAndEthics' | 'HealthWellbeingAndEducation' | 'CommunityEngagementAndLocalDevelopment' | 'OperationalPracticesAndResponsibleConsumption' | 'EnvironmentalImpact' | 'HumanRightsAndLaborConditions' | 'CorporateEthics' | 'Governance';

export const EAwarenessThematic = {
    CorporateSocialResponsability: 'CorporateSocialResponsability' as EAwarenessThematic,
    CrisisManagementAndResilience: 'CrisisManagementAndResilience' as EAwarenessThematic,
    SustainableAndEthicalFinance: 'SustainableAndEthicalFinance' as EAwarenessThematic,
    TechnologicalInnovationAndEthics: 'TechnologicalInnovationAndEthics' as EAwarenessThematic,
    HealthWellbeingAndEducation: 'HealthWellbeingAndEducation' as EAwarenessThematic,
    CommunityEngagementAndLocalDevelopment: 'CommunityEngagementAndLocalDevelopment' as EAwarenessThematic,
    OperationalPracticesAndResponsibleConsumption: 'OperationalPracticesAndResponsibleConsumption' as EAwarenessThematic,
    EnvironmentalImpact: 'EnvironmentalImpact' as EAwarenessThematic,
    HumanRightsAndLaborConditions: 'HumanRightsAndLaborConditions' as EAwarenessThematic,
    CorporateEthics: 'CorporateEthics' as EAwarenessThematic,
    Governance: 'Governance' as EAwarenessThematic
};

