import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TrackedActivityService } from 'app/api';
import { getUserSessionFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { isNil } from 'lodash-es';
import { Observable, catchError, exhaustMap, map, of } from 'rxjs';
import { TrackedActivityActions } from './tracked-activity.actions';

@Injectable()
export class TrackedActivityEffects {
    logActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrackedActivityActions.logActivityRequest),
            exhaustMap(({ activity }) => {
                const session = getUserSessionFromLocalStorage();
                const isLoggedIn = !isNil(session);
                const apiCall = isLoggedIn
                    ? this.trackedActivityService.activityPost
                    : this.trackedActivityService.activityAnonymousPost;
                const boundApiCall = apiCall.bind(this.trackedActivityService);
                const apiObservable = boundApiCall({
                    trackedActivityViewModel: activity,
                }) as Observable<void>;

                return apiObservable.pipe(
                    map(() =>
                        TrackedActivityActions.logActivityRequestSuccess()
                    ),
                    catchError((httpResponse) =>
                        of(
                            TrackedActivityActions.logActivityRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );
    constructor(
        private actions$: Actions,
        private trackedActivityService: TrackedActivityService
    ) {}
}
