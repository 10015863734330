<div class="flex flex-col w-full p-2 px-4 md:px-6 xl:p-6 xl:px-10">
    <dual-quiz-header></dual-quiz-header>

    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 md:col-span-7 lg:col-span-8 flex flex-col">
            <dual-quiz-challenges></dual-quiz-challenges>
        </div>
        <div
            class="col-span-12 md:col-span-5 lg:col-span-4 flex flex-col gap-3"
        >
            <dual-quiz-challenge-match-ups></dual-quiz-challenge-match-ups>
        </div>
    </div>
</div>
