<base-dialog
    [title]="'shared.mission-or-project.title' | transloco"
    [showActions]="false"
    [closeAction]="closeAction"
    [color]="'warn'"
>
    <div class="flex" body>
        <bslk-choice-card
            (click)="openMissionDialog()"
            [title]="'shared.mission-or-project.mission-title' | transloco"
            [backgroundIcon]="'heroicons_outline:briefcase'"
            [description]="
                'shared.mission-or-project.mission-description' | transloco
            "
            class="w-1/2 pr-3"
            [backgroundColor]="'primary'"
        >
        </bslk-choice-card>
        <bslk-choice-card
            (click)="openProjectDialog()"
            [title]="'shared.mission-or-project.project-title' | transloco"
            [backgroundIcon]="'heroicons_outline:banknotes'"
            [description]="
                'shared.mission-or-project.project-description' | transloco
            "
            class="w-1/2 pl-3"
            [backgroundColor]="'tertiary'"
        >
        </bslk-choice-card>
    </div>
</base-dialog>
