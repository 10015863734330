<div class="flex flex-col gap-4 p-6">
    <div class="text-4xl font-extrabold tracking-tight">Demonstration</div>

    <div
        *ngrxLet="lastExecutedStep$ as lastExecutedStep"
        class="flex w-full gap-8"
    >
        <div
            *ngFor="let step of steps$ | async"
            (click)="executeStep(step.order)"
            [ngClass]="{
                'hover:bg-primary hover:text-white cursor-pointer hover:scale-105':
                    lastExecutedStep + 1 === step.order,
                grayscale: lastExecutedStep + 1 != step.order
            }"
            class="bg-primary-300 flex flex-col items-center gap-4 flex-1 shadow-lg rounded-2xl p-4 transition-all duration-200"
        >
            <div class="text-2xl font-bold text-center">
                Step #{{ step.order }}
            </div>
            <mat-icon
                [svgIcon]="'heroicons_outline:play'"
                class="icon-size-10 text-current"
            ></mat-icon>
            <div class="text-lg grow flex flex-col justify-center items-center">
                <span>{{ step.description }}</span>
            </div>
        </div>
    </div>

    <div class="w-full flex justify-center">
        <fuse-alert class="flex" type="error" *ngIf="error$ | async as error">
            {{ error }}
        </fuse-alert>
    </div>
</div>
