<div
    (click)="openDrawer()"
    [matTooltip]="'coursesection.see-all-comments' | transloco"
    class="flex items-center text-white hover:text-primary justify-center right-0 w-10 h-10 shadow-lg rounded-l-lg z-90 cursor-pointer bg-primary bg-opacity-60 hover:bg-opacity-10 transition-all"
>
    <mat-icon
        class="icon-size-5 text-current"
        [svgIcon]="'heroicons_solid:chevron-double-left'"
    ></mat-icon>
</div>

<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [transparentOverlay]="true"
    [name]="'commentsDrawer'"
    [position]="'right'"
    #commentsDrawer
>
    <div class="flex flex-col w-full max-h-screen bg-card">
        <div
            class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary"
        >
            <mat-icon
                class="icon-size-7 text-current"
                [svgIcon]="'heroicons_solid:chat-bubble-left-right'"
            ></mat-icon>
            <div class="ml-3 text-2xl font-semibold tracking-tight">
                {{ "coursesection.comments" | transloco }}
            </div>
            <button
                class="ml-auto"
                mat-icon-button
                (click)="commentsDrawer.close()"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"
                ></mat-icon>
            </button>
        </div>

        <div
            *ngrxLet="comments$ as comments"
            class="flex flex-col flex-1 overflow-auto pt-6"
        >
            <div
                *ngIf="!(isLoading$ | async); else loading"
                class="gap-3 flex flex-col flex-1 overflow-auto grow"
            >
                <div
                    *ngIf="comments.length === 0"
                    class="px-6 flex flex-col justify-center text-black h-full"
                >
                    <div
                        class="grow flex flex-col justify-center gap-5 text-lg items-center"
                    >
                        {{ "coursesection.be-first" | transloco }}
                        <mat-icon
                            [svgIcon]="'heroicons_outline:chat-bubble-left'"
                            class="text-current icon-size-12"
                        ></mat-icon>
                    </div>
                </div>
                <div
                    *ngFor="
                        let comment of comments;
                        let first = first;
                        let last = last
                    "
                    class="pb-4 px-6"
                    [class.border-b]="!last"
                >
                    <course-section-comment
                        [comment]="comment"
                    ></course-section-comment>
                </div>
            </div>
            <mat-paginator
                footer
                *ngIf="(totalPages$ | async) > 1"
                class="bg-gray-50 border-top"
                [ngClass]="{
                    'pointer-events-none': isLoading
                }"
                [length]="totalCount$ | async"
                [pageIndex]="pageIndex$ | async"
                [pageSize]="pageSize$ | async"
                [hidePageSize]="true"
                (page)="pageChangeEvent($event)"
                [showFirstLastButtons]="false"
            ></mat-paginator>
        </div>

        <form
            [formGroup]="formGroup"
            class="px-6 flex flex-col gap-3 p-6 bg-gray-50 border-t"
        >
            <mat-form-field
                floatLabel="always"
                subscriptSizing="dynamic"
                class="w-full mb-3"
            >
                <mat-label>{{
                    "coursesection.your-comment" | transloco
                }}</mat-label>
                <textarea
                    class="h-28 bg-white"
                    [formControlName]="'comment'"
                    matInput
                    [placeholder]="
                        'coursesection.what-do-you-think' | transloco
                    "
                >
                </textarea>
            </mat-form-field>

            <button
                (click)="comment()"
                class="flex items-center justify-center w-full py-2 bg-white font-bold text-lg rounded-full border border-black hover:border-warn hover:bg-warn transition-all hover:text-white duration-400"
            >
                <mat-icon
                    [svgIcon]="'heroicons_solid:chat-bubble-oval-left-ellipsis'"
                    class="mr-2 text-current"
                ></mat-icon>
                <span>{{ "coursesection.comment" | transloco }}</span>
            </button>
        </form>
    </div>
</fuse-drawer>

<ng-template #loading>
    <div class="flex flex-col flex-1 overflow-auto px-6">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-28 mb-6"
        ></ngx-skeleton-loader>

        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-28 mb-6"
        ></ngx-skeleton-loader>

        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-28 mb-6"
        ></ngx-skeleton-loader>

        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-28 mb-6"
        ></ngx-skeleton-loader>

        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-28"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
