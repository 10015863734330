import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { AssociationRatingListActions } from './association-rating.actions';
import { AssociationRatingListState } from './association-rating.state';

export const initialState: AssociationRatingListState =
    ressourceListInitialState;

export const associationRatingListReducer = createRessourceListReducer(
    ressourceListInitialState,
    AssociationRatingListActions
);
