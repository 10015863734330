import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { BslkAnswerRequestComponent } from '@bslk/components/answer-request/answer-request.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { RegularDashboardActions } from 'app/store/regular-dashboard/regular-dashboard.actions';
import { RegularDashboardSelectors } from 'app/store/regular-dashboard/regular-dashboard.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'regulard-dashboard-manager-approvals',
    standalone: true,
    templateUrl: './regulard-dashboard-manager-approvals.component.html',
    imports: [
        AsyncPipe,
        BslkAnswerRequestComponent,
        LetDirective,
        NgFor,
        NgIf,
        NgxSkeletonLoaderModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        TranslocoModule,
        RouterLink,
    ],
})
export class RegulardDashboardManagerApprovalsComponent implements OnInit {
    pendingApprovals$ = this.store.select(
        RegularDashboardSelectors.selectManagerPendingApprovals
    );

    noPendingApprovals$ = this.store.select(
        RegularDashboardSelectors.selectNoManagerPendingApprovals
    );

    isLoading$ = this.store.select(
        RegularDashboardSelectors.selectIsLoadingManagerPendingApprovals
    );

    constructor(private store: Store, private datePipe: DatePipe) {}

    ngOnInit(): void {
        this.store.dispatch(
            RegularDashboardActions.loadPendingManagerApprovalsRequest()
        );
    }

    formatDateRange(startDate: string, endDate: string) {
        return formatDateRange(this.datePipe, startDate, endDate);
    }

    requestAnswered(
        missionSlotId: number,
        userId: number,
        isApproved: boolean
    ) {
        console.log(isApproved);
        this.store.dispatch(
            RegularDashboardActions.checkIfCanAnswerApproveRequest({
                isApproved,
                missionSlotId,
                userId,
            })
        );
    }
}
