import { Clipboard } from '@angular/cdk/clipboard';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DonationCampaignViewModel } from 'app/api';
import { DonationCampaignSelectors } from 'app/store/donation/donation-campaign/donation-campaign.selectors';
import { DonationCampaignShareSettingsDialogService } from './share-settings-dialog/share-settings-dialog.service';

@Component({
    selector: 'donation-campaign-share-link',
    templateUrl: './share-link.component.html',
    standalone: true,
    imports: [AsyncPipe, MatIconModule, NgIf, TranslocoModule, LetDirective],
})
export class DonationCampaignShareLinkComponent {
    @Input() campaign: DonationCampaignViewModel;

    notPaid$ = this.store.select(
        DonationCampaignSelectors.selectIsCampaignIsNotPaid
    );
    finished$ = this.store.select(
        DonationCampaignSelectors.selectIsCampaignInFinished
    );
    donationGiftUrl$ = this.store.select(
        DonationCampaignSelectors.selectDonationGiftUrl
    );

    isCopied = false;

    constructor(
        private store: Store,
        private clipboard: Clipboard,
        private donationCampaignShareSettingsDialogService: DonationCampaignShareSettingsDialogService
    ) {}

    copyToClipboard(url: string) {
        this.isCopied = true;
        setTimeout(() => {
            this.isCopied = false;
        }, 3000);
        this.clipboard.copy(url);
    }

    openSettingsDialog() {
        this.donationCampaignShareSettingsDialogService.open();
    }
}
