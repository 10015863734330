<div
    (click)="open()"
    class="min-w-screen h-full grow flex justify-center items-center cursor-pointer relative"
>
    <svg
        #gift
        xmlns="http://www.w3.org/2000/svg"
        class="gift w-[300px] h-[300px] md:w-[400px] md:h-[400px] lg:w-[500px] lg:h-[500px] xl:w-[600px] xl:h-[600px] xxl:w-[800px] xxl:h-[800px] z-20"
        viewBox="-180 -200 800 800"
        preserveAspectRatio="xMidYMid meet"
    >
        <path
            d="M425.435 273.698v193.301c0 24.899-20.099 45-45 45h-150v-271h131.4c1.8 0 3.6.3 5.099.899l48.6 17.701c6 2.1 9.901 7.8 9.901 14.099z"
            fill="#c60034"
        />
        <path
            d="M415.534 259.599l-48.6-17.701c-1.5-.599-3.3-.899-5.099-.899h-311.4c-8.401 0-15 6.599-15 15v211c0 24.899 20.099 45 45 45h300c24.901 0 45-20.101 45-45V273.698c0-6.299-3.901-11.999-9.901-14.099z"
            fill="#fc1a40"
        />
        <path d="M185.435 240.999v271h90v-271h-90z" fill="#fcbf29" />
        <g class="gift-top">
            <path
                d="M420.035 97.788c-5.099-20.7-20.4-36.899-41.1-43.5-20.4-6.301-42.301-1.8-58.2 12.599l-34.801 30.601-6.899-45.899c-3.3-21-16.8-38.701-36.899-47.1-3.9-1.501-7.8-2.701-11.7-3.301-16.5-3.3-33.6.3-47.401 10.501-18.3 13.2-27.599 35.4-24.6 57.599 3.301 22.2 18.301 41.1 39.6 48.6l32.401 11.7 39.9 14.7h.3l69.3 25.499c6.899 2.401 14.099 3.602 20.999 3.602 14.702 0 29.101-5.402 40.501-15.601 16.799-15 23.999-38.101 18.599-60z"
                fill="#fe9923"
            />
            <path
                d="M401.435 157.788c-11.4 10.199-25.8 15.601-40.501 15.601-6.899 0-14.099-1.201-20.999-3.602l-69.3-25.499h-.3l-39.9-14.7V1.187c3.9.601 7.8 1.8 11.7 3.301 20.099 8.399 33.6 26.1 36.899 47.1l6.899 45.899 34.801-30.601c15.899-14.399 37.8-18.9 58.2-12.599 20.7 6.601 36 22.8 41.1 43.5 5.401 21.9-1.799 45.001-18.599 60.001z"
                fill="#fe8821"
            />
            <path
                d="M476.735 234.098l-20.4 56.4c-2.401 6-8.101 9.6-14.101 9.6-1.8 0-3.6-.3-5.099-.899l-155.101-56.4-14.099-46.5-37.5 8.399-32.999 7.5-155.101-56.4c-7.8-2.999-11.699-11.7-9-19.2l20.7-56.398c3.9-11.4 12.301-20.402 23.101-25.501s23.099-5.7 34.499-1.5l118.801 43.2 8.101 2.999s32.399 58.801 33.3 58.801c.601 0 13.5-7.2 26.1-14.101 12.597-6.9 25.198-14.099 25.198-14.099l126.599 46.199c11.4 4.2 20.4 12.601 25.499 23.401 5.102 10.799 5.701 23.1 1.502 34.499z"
                fill="#ff3e75"
            />
            <path
                d="M476.735 234.098l-20.4 56.4c-2.401 6-8.101 9.6-14.101 9.6-1.8 0-3.6-.3-5.099-.899l-155.101-56.4-14.099-46.5-37.5 8.399V96.399l8.101 2.999s32.399 58.801 33.3 58.801c.601 0 13.5-7.2 26.1-14.101 12.598-6.9 25.199-14.099 25.199-14.099l126.599 46.199c11.4 4.2 20.4 12.601 25.499 23.401 5.102 10.799 5.701 23.1 1.502 34.499z"
                fill="#fc1a40"
            />
            <path
                d="M238.535 99.398l-8.101 22.2-32.999 90.601 32.999 12.001 37.5 13.5 14.099 5.099 41.102-112.8-84.6-30.601z"
                fill="#fcbf29"
            />
            <path
                fill="#fe9923"
                d="M323.135 129.999l-41.101 112.8-14.099-5.1-37.5-13.5V121.598l8.1-22.2z"
            />
        </g>
        <path fill="#fe9923" d="M230.439 240.999h45v271h-45z" />
    </svg>

    <div
        *ngIf="!isOpened"
        [ngClass]="{
            'opacity-0': !campaign,
            'opacity-100': campaign,
        }"
        class="absolute flex flex-col gap-10 mt-[-400px] mr-0 md:mr-[-500px] lg:mr-[-600px] xl:mr-[-700px] xxl:mr-[-800px] transition-all duration-300"
    >
        <span
            [ngClass]="{
            'opacity-0': !campaign,
            'opacity-100': campaign,
        }"
            [style]="
                'backgroundColor:' +
                campaign?.customization.headerBackgroundColor +
                ';color:' +
                campaign?.customization.textColor
            "
            class="mb-5 md:-ml-20 px-4 py-2 rounded-xl font-bold text-3xl md:text-5xl animate-wizz uppercase -rotate-6 truncate"
            >{{ "donationgift.open-your-gift" | transloco }}</span
        >
        <svg
            class="hidden md:block rotate-[150deg] w-32"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 142 106"
            fill="none"
        >
            <g filter="url(#filter0_d_3325_30175)">
                <path
                    d="M120.3402 16.0796C75.2572 7.94762 19.77972 21.6908 0.271068 93.232C0.0228744 94.1422 0.991434 94.915 1.80023 94.4336L10.63158 89.1768C11.63436 88.5798 12.9274 88.911 13.51968 89.9166L18.73594 98.772C19.21366 99.583 20.4462 99.3694 20.6948 98.4594C39.4714 29.7238 91.4572 19.07078 120.1316 18.14566C121.3938 18.10494 121.5826 16.30372 120.3402 16.0796Z"
                    fill="#fe9923"
                />
                <path
                    d="M134.4722 17.25332L122.3302 2.1987C120.8508 0.364402 117.9392 1.97857 118.6924 4.21544L122.8834 16.66104C123.0806 17.2466 123.0126 17.89004 122.6976 18.42222L115.9994 29.7332C114.7956 31.7662 117.3068 33.9454 119.137 32.456L134.1578 20.2316C135.0636 19.49448 135.2044 18.16106 134.4722 17.25332Z"
                    fill="#fe9923"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_3325_30175"
                    x="0.232422"
                    y="1.4025"
                    width="140.709"
                    height="103.873"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="3" dy="3" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.9 0 0 0 0 0.1 0 0 0 0 0.25 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3325_30175"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_3325_30175"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    </div>
</div>
