/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EMissionRecurrenceType } from './eMissionRecurrenceType';
import { MissionBaseViewModel } from './missionBaseViewModel';
import { MissionSlotUserViewModel } from './missionSlotUserViewModel';
import { ETimePeriod } from './eTimePeriod';


export interface MissionSlotViewModel { 
    id?: number;
    dateCreated?: string;
    dateUpdated?: string | null;
    dateArchived?: string | null;
    startDate?: string;
    endDate?: string;
    availableSpotsLeft?: number;
    estimatedTimeInHours?: number | null;
    recurrenceType?: EMissionRecurrenceType;
    customRecurrenceUnit?: number | null;
    customRecurrenceType?: ETimePeriod;
    users?: Array<MissionSlotUserViewModel> | null;
    guid?: string | null;
    parentGuid?: string | null;
    mission?: MissionBaseViewModel;
}
export namespace MissionSlotViewModel {
}


