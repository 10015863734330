import { Injectable } from '@angular/core';
import { FuseAlertService } from '@fuse/components/alert';
import { BehaviorSubject, Observable } from 'rxjs';
import { SnackAlert } from './snack-alert.model';

@Injectable({
    providedIn: 'root',
})
export class SnackAlertService {
    private snackAlerts: SnackAlert[] = [];
    private snackAlertsSubject = new BehaviorSubject<SnackAlert[]>(
        this.snackAlerts
    );

    constructor(private _fuseAlertService: FuseAlertService) {}

    get snackAlerts$(): Observable<SnackAlert[]> {
        return this.snackAlertsSubject.asObservable();
    }

    addSnackAlert(alert: SnackAlert) {
        alert.id = this.generateGuid();
        this.snackAlerts.push(alert);
        this.snackAlertsSubject.next([...this.snackAlerts]);

        const dismissalTime = alert.duration || 5000;
        setTimeout(() => {
            this._fuseAlertService.dismiss(alert.id);
            this.removeSnackAlert(alert);
        }, dismissalTime);
    }

    removeSnackAlert(alert: SnackAlert) {
        const index = this.snackAlerts.indexOf(alert);
        if (index >= 0) {
            this.snackAlerts.splice(index, 1);
            this.snackAlertsSubject.next([...this.snackAlerts]);
        }
    }

    generateGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                const r = (Math.random() * 16) | 0;
                const v = c === 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }
}
