import { Clipboard } from '@angular/cdk/clipboard';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DonationCodeViewModel } from 'app/api';
import { DonationCampaignSelectors } from 'app/store/donation/donation-campaign/donation-campaign.selectors';

@Component({
    selector: 'donation-campaign-whitelisted-codes',
    templateUrl: './whitelisted-codes.component.html',
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        AsyncPipe,
        LetDirective,
        TranslocoModule,
        BslkTagComponent,
        MatTooltipModule,
        MatIconModule,
    ],
})
export class DonationCampaignWhitelistedCodesComponent {
    copiedCodeId: number;
    timeoutId: any;

    isLoading$ = this.store.select(DonationCampaignSelectors.selectIsLoading);
    whitelistedCodes$ = this.store.select(
        DonationCampaignSelectors.selectWhitelistedCodes
    );

    constructor(private store: Store, private clipboard: Clipboard) {}

    copyToClipboard(code: DonationCodeViewModel) {
        if (!code.isUsed) {
            this.clipboard.copy(code.code);
            this.copiedCodeId = code.id;
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(() => {
                this.copiedCodeId = null;
                this.timeoutId = null;
            }, 4000);
        }
    }
}
