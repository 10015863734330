import { AsyncPipe, JsonPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkBusinessSdgPickerComponent } from '@bslk-business/components/form/sdg-picker/sdg-picker.component';
import { BslkAdvancedSelectSyncComponent } from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { LocationSelectionComponent } from 'app/shared/components/location-selection/location-selection.component';
import { MissionFormService } from 'app/shared/services/form/mission-form.service';
import { QUILL_MODULES } from 'app/shared/utils/constants.utils';
import { AssociationSelectors } from 'app/store/association/association/association.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { isEmpty } from 'lodash-es';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { QuillEditorComponent } from 'ngx-quill';
@Component({
    selector: 'create-mission-step-2',
    standalone: true,
    templateUrl: './create-mission-step-2.component.html',
    imports: [
        AsyncPipe,
        BslkAdvancedSelectSyncComponent,
        BslkBusinessSdgPickerComponent,
        BslkInfoLabelComponent,
        BslkClassicToggleComponent,
        FuseAlertComponent,
        FuseScrollbarDirective,
        JsonPipe,
        LetDirective,
        LocationSelectionComponent,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatStepperModule,
        MatTooltipModule,
        NgFor,
        NgIf,
        NgxMaterialTimepickerModule,
        QuillEditorComponent,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class CreateMissionStep2Component {
    quillModules = QUILL_MODULES;

    usersForAdvancedSelect$ = this.store.select(
        AssociationSelectors.selectUsersForAdvancedSelect
    );

    isAssoAdmin$ = this.store.select(UserSelectors.selectIsAssoAdmin);

    constructor(
        private store: Store,
        private missionFormService: MissionFormService
    ) {}

    get formGroup() {
        return this.missionFormService.getFormGroup;
    }

    get fileName() {
        return this.formGroup.controls.coverFile.value?.name ?? '';
    }

    get fileIsSet() {
        return !isEmpty(this.fileName);
    }

    get isDescriptionErrorDisplayed() {
        const descriptionCtrl = this.formGroup.get('description');
        return descriptionCtrl.errors && descriptionCtrl.touched;
    }
}
