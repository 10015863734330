<div class="p-4 md:p-6 xxl:p-8 w-full">
    <div class="flex justify-between mb-6">
        <div class="flex flex-col gap-1">
            <span class="text-3xl font-bold">{{
                "setup.title" | transloco
            }}</span>
            <span class="text-lg">{{ "setup.subtitle" | transloco }}</span>
        </div>
    </div>

    <div class="grid gap-4">
        <company-employees></company-employees>
    </div>
</div>

<ng-template #loading>
    <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col gap-4">
            <div class="h-64 overflow-hidden rounded-2xl shadow-lg">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>
            <div class="h-64 overflow-hidden rounded-2xl shadow-lg">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>
        </div>
        <div class="h-200 overflow-hidden rounded-2xl shadow-lg">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full h-full"
            ></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
