import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ImportSheetService } from 'app/api';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { ImportSheetActions } from './import.action';

@Injectable()
export class ImportSheetEffects {
    importEmails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImportSheetActions.importEmailsRequest),
            exhaustMap(({ file }) =>
                this.sheetImportService.importSheetEmailsPost({ file }).pipe(
                    map((emails) =>
                        ImportSheetActions.importEmailsRequestSuccess({
                            emails,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            ImportSheetActions.importEmailsRequestFailure({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                )
            )
        )
    );

    importEmployees$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ImportSheetActions.importCompanyEmployeesRequest),
            exhaustMap(({ file }) =>
                this.sheetImportService
                    .importSheetCompanyEmployeesPost({ file })
                    .pipe(
                        map((employees) =>
                            ImportSheetActions.importCompanyEmployeesRequestSuccess(
                                {
                                    employees,
                                }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                ImportSheetActions.importCompanyEmployeesRequestFailure(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    closeOnSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ImportSheetActions.importEmailsRequestSuccess,
                    ImportSheetActions.importCompanyEmployeesRequestSuccess
                ),
                tap(() => this.fileUploadDialogService.close())
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private sheetImportService: ImportSheetService,
        private fileUploadDialogService: FileUploadDialogService
    ) {}
}
