import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { fuseAnimations } from '@fuse/animations';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DualQuizChallengeActions } from 'app/store/dual-quiz/challenge/challenge.action';
import { DualQuizChallengeSelectors } from 'app/store/dual-quiz/challenge/challenge.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';
import { DualQuizChallengePreQuestionComponent } from './pre-question/pre-question.component';
import { DualQuizChallengeQuestionDetailsComponent } from './question-details/question-details.component';
import { DualQuizChallengeQuestionComponent } from './question/question.component';
import { DualQuizChallengeBarTimerComponent } from './timers/bar-timer/bar-timer.component';

@Component({
    selector: 'dual-quiz-challenge',
    templateUrl: './challenge.component.html',
    standalone: true,
    animations: fuseAnimations,
    imports: [
        BslkTagComponent,
        AsyncPipe,
        LetDirective,
        TranslocoModule,
        NgxSkeletonLoaderModule,
        DualQuizChallengePreQuestionComponent,
        DualQuizChallengeQuestionComponent,
        DualQuizChallengeBarTimerComponent,
        DualQuizChallengeQuestionDetailsComponent,
        NgIf,
        NgClass,
    ],
})
export class DualQuizChallengeComponent implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    isLoading$ = this.store.select(DualQuizChallengeSelectors.selectIsLoading);
    challenge$ = this.store.select(DualQuizChallengeSelectors.selectChallenge);
    currentQuestionIndex$ = this.store.select(
        DualQuizChallengeSelectors.selectCurrentQuestionIndex
    );
    isAnswering$ = this.store.select(
        DualQuizChallengeSelectors.selectIsAnswering
    );
    isTimerRunning$ = this.store.select(
        DualQuizChallengeSelectors.selectIsTimerRunning
    );
    hasAnsweredOnce$ = this.store.select(
        DualQuizChallengeSelectors.selectHasAnsweredOnce
    );
    showQuestionDetails$ = this.store.select(
        DualQuizChallengeSelectors.selectShowQuestionDetails
    );

    constructor(private store: Store, private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                if (params['id']) {
                    this.store.dispatch(
                        DualQuizChallengeActions.loadChallengeRequest({
                            id: params['id'],
                        })
                    );
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
