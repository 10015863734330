import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { RatingReportComponent } from 'app/shared/components/rating/rating-report/rating-report/rating-report.component';
import { createOverlayRef } from 'app/shared/utils/overlay.utils';
import { MissionRatingListActions } from 'app/store/mission/mission-ratings/mission-ratings.actions';
import { MissionRatingListSelectors } from 'app/store/mission/mission-ratings/mission-ratings.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'my-ratings',
    templateUrl: './my-ratings.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        RatingReportComponent,
        LetDirective,
        MatIconModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        NgxSkeletonLoaderModule,
        TranslocoModule,
    ],
})
export class UserRatingsComponent implements OnDestroy {
    @ViewChild('userRatingsOrigin') userRatingsOrigin: ElementRef;
    @ViewChild('userRatingsPanel') userRatingsPanel: TemplateRef<any>;

    @Input() ratingsCount: number;

    overlayRef: OverlayRef;
    unsubscribeAll: Subject<any> = new Subject<any>();

    ratings$ = this.store.select(MissionRatingListSelectors.selectData);
    totalCount$ = this.store.select(
        MissionRatingListSelectors.selectTotalCount
    );
    pageIndex$ = this.store.select(MissionRatingListSelectors.selectPageIndex);
    pageSize$ = this.store.select(MissionRatingListSelectors.selectPageSize);
    totalPages$ = this.store.select(
        MissionRatingListSelectors.selectTotalPages
    );
    isLoading$ = this.store.select(MissionRatingListSelectors.selectIsLoading);

    constructor(
        private store: Store,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef
    ) {}

    openPanel(): void {
        if (!this.userRatingsPanel || !this.userRatingsOrigin) {
            return;
        }

        this.store.dispatch(MissionRatingListActions.Initialize());

        if (!this.overlayRef) {
            this.overlayRef = createOverlayRef(
                this.overlay,
                this.userRatingsOrigin.nativeElement
            );
            this.overlayRef
                .backdropClick()
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe(() => {
                    this.closePanel();
                });
        }

        this.overlayRef.attach(
            new TemplatePortal(this.userRatingsPanel, this.viewContainerRef)
        );
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            MissionRatingListActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
    }

    closePanel(): void {
        this.overlayRef.detach();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }
}
