import { AsyncPipe, DatePipe, NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkBadgeComponent as BslkBusinessBadgeComponent } from '@bslk-business/components/badge/badge.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { ExperienceBarComponent } from 'app/shared/components/experience-bar/experience-bar.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'course-dashboard-experience-bar',
    templateUrl: './experience-bar.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkBusinessBadgeComponent,
        BslkTagComponent,
        DatePipe,
        LetDirective,
        MatIconModule,
        NgClass,
        NgIf,
        NgxSkeletonLoaderModule,
        TranslocoModule,
        ExperienceBarComponent,
    ],
})
export class CourseDashboardExperienceBarComponent {}
