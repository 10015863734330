import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    DualQuizAnswerResponseViewModel,
    DualQuizChallengeViewModel,
} from 'app/api';

export const DualQuizChallengeActions = createActionGroup({
    source: 'DualQuizChallenge',
    events: {
        'Load Challenge Request': props<{ id: number }>(),
        'Load Challenge Request Success': props<{
            challenge: DualQuizChallengeViewModel;
        }>(),
        'Load Challenge Request Fail': props<{ error: string }>(),

        'Answer Question': props<{ choice: number }>(),
        'Answer Question Request': props<{ choice: number }>(),
        'Answer Question Request Success': props<{
            response: DualQuizAnswerResponseViewModel;
        }>(),
        'Answer Question Request Fail': props<{ error: string }>(),

        'Next Question': props<{ isStarting: boolean }>(),
        'Emitted Current Question Success': emptyProps(),
        'Emitted Current Question Fail': props<{ error: string }>(),

        'Start Timer': props<{ duration: number }>(),
        'Stop Timer': emptyProps(),
        'Update Timer': props<{ timer: number }>(),
    },
});
