import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    AssociationJoinRequestService,
    AssociationService,
    AssociationViewModel,
    EAssociationState,
    EUserRole,
} from 'app/api';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { AssociationFormService } from 'app/shared/services/form/association-form.service';
import { updateLocalStorageUser } from 'app/shared/utils/local-storage.utils';
import {
    EAssociationUrl,
    UrlHelpers,
} from 'app/shared/utils/url-helpers.utils';
import { isNil } from 'lodash-es';
import { catchError, exhaustMap, filter, map, mergeMap, of, tap } from 'rxjs';
import { AlertActions } from '../../alert/alert.actions';
import { UserActions } from '../../user/user/user.actions';
import { UserSelectors } from '../../user/user/user.selectors';
import { AssociationActions } from './association.action';
import { AssociationSelectors } from './association.selectors';

@Injectable()
export class AssociationEffects {
    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationActions.createRequest),
            concatLatestFrom(() => this.store.select(UserSelectors.selectUser)),
            mergeMap(([associationCreation, user]) =>
                this.associationService
                    .associationPost({
                        associationViewModel: {
                            name: associationCreation.data.name,
                            tags: associationCreation.data.tags,
                            state: EAssociationState.Acquired,
                            users: [user],
                            location: { id: 0 },
                        } as AssociationViewModel,
                    })
                    .pipe(
                        map((associationId) => {
                            const clonedUser = structuredClone(user);
                            clonedUser.association = {
                                id: associationId,
                                name: associationCreation.data.name,
                            };
                            updateLocalStorageUser(clonedUser);
                            return AssociationActions.createRequestSuccesful({
                                association: clonedUser.association,
                            });
                        }),
                        catchError((httpResponse) =>
                            of(
                                AssociationActions.createRequestFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    )
            )
        )
    );

    redirectAfterCreation$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AssociationActions.createRequestSuccesful),
                tap(() => {
                    this.router.navigateByUrl(
                        UrlHelpers.getAssociationUrl(
                            EAssociationUrl.AssociationEditPage
                        )
                    );
                })
            ),
        { dispatch: false }
    );

    loadAssociation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationActions.loadRequest),
            exhaustMap(({ id }) =>
                this.associationService.associationIdGet({ id }).pipe(
                    map((response) =>
                        AssociationActions.loadRequestSuccess({ response })
                    ),
                    catchError((httpResponse) =>
                        of(
                            AssociationActions.loadRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                )
            )
        )
    );

    initUserAssociation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.signInSuccess, UserActions.alreadySignedIn),
            concatLatestFrom(() =>
                this.store.select(UserSelectors.selectHasAssociation)
            ),
            filter(([, hasAssociation]) => hasAssociation),
            map(() =>
                AssociationActions.loadMineRequest({ displayIsLoading: true })
            )
        )
    );

    loadMyAssociation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationActions.loadMineRequest),
            concatLatestFrom(() => [
                this.store.select(UserSelectors.selectHasAssociation),
                this.store.select(UserSelectors.selectAssociationId),
            ]),
            filter(([, hasAssociation]) => hasAssociation),
            exhaustMap(([, , associationId]) =>
                this.associationService
                    .associationIdGet({ id: associationId })
                    .pipe(
                        map((response) => {
                            this.associationFormService.updateForm(response);
                            return AssociationActions.loadRequestSuccess({
                                response,
                            });
                        }),
                        catchError((httpResponse) =>
                            of(
                                AssociationActions.loadRequestFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    )
            )
        )
    );

    updateAssociation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationActions.updateRequest),
            exhaustMap(() => {
                const association = this.associationFormService.getEntity();
                return this.associationService.associationPut(association).pipe(
                    map(() =>
                        AssociationActions.updateRequestSuccess({
                            association: association.associationViewModel,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            AssociationActions.updateRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            }),
            tap(() => {
                this.router.navigateByUrl(
                    UrlHelpers.getAssociationUrl(
                        EAssociationUrl.AssociationViewPage
                    )
                );
            })
        )
    );

    cancelUpdate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AssociationActions.cancelUpdate),
                concatLatestFrom(() =>
                    this.store.select(AssociationSelectors.selectAssociation)
                ),
                tap(([, association]) => {
                    this.associationFormService.updateForm(association);
                })
            ),
        { dispatch: false }
    );

    updateSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationActions.updateRequestSuccess),
            map(() => {
                return AlertActions.displaySuccess({
                    key: 'association-update',
                });
            })
        )
    );

    updateFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationActions.updateRequestFail),
            map(() => {
                return AlertActions.displayError({ key: 'association-update' });
            })
        )
    );

    updateAssociationLogo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationActions.updateLogoRequest),
            exhaustMap(({ file }) => {
                return this.associationService
                    .associationLogoPut({ file })
                    .pipe(
                        map((url) =>
                            AssociationActions.updateLogoSuccess({ url })
                        ),
                        catchError((httpResponse) =>
                            of(
                                AssociationActions.updateLogoFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    );
            })
        )
    );

    updateImage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    AssociationActions.updateCoverSuccess,
                    AssociationActions.updateLogoSuccess
                ),
                tap(() => {
                    this.fileUploadDialogService.close();
                })
            ),
        { dispatch: false }
    );

    updateAssociationCover$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationActions.updateCoverRequest),
            exhaustMap(({ file }) => {
                return this.associationService
                    .associationCoverPut({ file })
                    .pipe(
                        map((url) =>
                            AssociationActions.updateCoverSuccess({ url })
                        ),
                        catchError((httpResponse) =>
                            of(
                                AssociationActions.updateCoverFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    );
            })
        )
    );

    updateImageFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AssociationActions.updateLogoFail,
                AssociationActions.updateCoverFail
            ),
            map(() => {
                return AlertActions.displayGenericError();
            })
        )
    );

    joinAssociation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationActions.joinRequest),
            exhaustMap(({ associationId }) =>
                this.associationJoinRequestService
                    .associationJoinRequestAssociationIdPost({ associationId })
                    .pipe(
                        map(() => AssociationActions.joinRequestSuccess()),
                        catchError((httpResponse) =>
                            of(
                                AssociationActions.joinRequestFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    )
            )
        )
    );

    checkIfPendingRequestCheckNeeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.alreadySignedIn, UserActions.signInSuccess),
            concatLatestFrom(() => [
                this.store.select(UserSelectors.selectAssociationId),
                this.store.select(UserSelectors.selectUserRole),
            ]),
            filter(
                ([, associationId, userRole]) =>
                    userRole === EUserRole.AssoAdmin && isNil(associationId)
            ),
            map(() => AssociationActions.getHasPendingRequest())
        )
    );

    pendingRequestCheck$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationActions.getHasPendingRequest),
            exhaustMap(() =>
                this.associationJoinRequestService
                    .associationJoinRequestIsPendingGet()
                    .pipe(
                        map((hasPendingRequest) =>
                            AssociationActions.setHasPendingRequest({
                                hasPendingRequest,
                            })
                        ),
                        catchError(() => of(AlertActions.displayGenericError()))
                    )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private associationService: AssociationService,
        private associationJoinRequestService: AssociationJoinRequestService,
        private associationFormService: AssociationFormService,
        private fileUploadDialogService: FileUploadDialogService
    ) {}
}
