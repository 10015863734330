<base-dialog
    (onClose)="close()"
    [title]="'missionview.registration.title' | transloco"
>
    <div body class="w-full flex flex-col items-center justify-center">
        <ng-container
            *ngIf="!showRegistrationSuccess; else registrationSuccess"
        >
            <div class="w-full mb-2">
                <div class="text-lg">
                    {{
                        "missionview.registration.select-slot-description"
                            | transloco
                    }}
                </div>
            </div>
            <mat-form-field class="w-full">
                <mat-label>{{
                    "missionview.registration.slots" | transloco
                }}</mat-label>
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_outline:calendar'"
                    matPrefix
                ></mat-icon>
                <mat-select
                    [placeholder]="
                        'missionview.registration.select-slot-placeholder'
                            | transloco
                    "
                    [(ngModel)]="selectedSlotId"
                >
                    <mat-option
                        *ngFor="let missionSlot of slotsWithAvailableSpots"
                        [disabled]="
                            (alreadyRegisteredIds$ | async).includes(
                                missionSlot.id
                            )
                        "
                        [value]="missionSlot.id"
                    >
                        {{ formatDateRange(missionSlot) }}
                        <span
                            *ngIf="
                                (alreadyRegisteredIds$ | async).includes(
                                    missionSlot.id
                                )
                            "
                            >({{
                                "missionview.registration.already-registered"
                                    | transloco
                            }})</span
                        >
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <ng-template #registrationSuccess>
            <registration-success (onChat)="close()"></registration-success>
        </ng-template>
    </div>

    <!-- Actions -->
    <div
        actions
        *ngrxLet="isLoading$ as isLoading"
        class="flex justify-end w-full"
    >
        <!-- Discard -->
        <button mat-stroked-button (click)="close()">
            <span *ngIf="!showRegistrationSuccess">{{
                "missionview.registration.cancel" | transloco
            }}</span>
            <span *ngIf="showRegistrationSuccess">{{
                "missionview.registration.close" | transloco
            }}</span>
        </button>

        <!-- Register -->
        <button
            *ngIf="!showRegistrationSuccess"
            [disabled]="isSubmitDisabled || isLoading"
            (click)="register()"
            class="text-accent ml-3"
            mat-flat-button
            [color]="'primary'"
            type="submit"
        >
            <div class="flex items-center">
                <mat-progress-spinner
                    class="mr-3"
                    *ngIf="isLoading"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                ></mat-progress-spinner>
                <span>
                    {{ "missionview.registration.continue" | transloco }}
                </span>
            </div>
        </button>

        <a
            (click)="close()"
            class="ml-3"
            *ngIf="showRegistrationSuccess"
            [routerLink]="'/missions/' + missionId + '/manage'"
        >
            <bslk-classic-button
                [background]="'tertiary'"
                [label]="'missionview.manage' | transloco"
                [icon]="'heroicons_outline:play'"
            >
            </bslk-classic-button>
        </a>
    </div>
</base-dialog>
