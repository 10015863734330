<div class="relative w-full pb-[56.25%] pt-[25px] mb-5">
    <iframe
        class="absolute w-full h-full top-0 left-0"
        [src]="videoUrl | safe"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
    >
    </iframe>
</div>
<ng-content></ng-content>
