import { AsyncPipe, NgIf } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { Store } from '@ngrx/store';
import { EFileType } from 'app/api';
import { ProjectViewComponent } from 'app/modules/regular/project/view/project-view.component';
import { FileManagerDetailsComponent } from 'app/modules/shared/my-files/details/my-files-details.component';
import { FilesSelectors } from 'app/store/files/files.selectors';
import { tap } from 'rxjs';

@Component({
    selector: 'donation-campaign-legal-documents',
    templateUrl: './legal-documents.component.html',
    styleUrls: ['./legal-documents.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        ProjectViewComponent,
        FuseDrawerComponent,
        FileManagerDetailsComponent,
        NgIf,
        MatIconModule,
    ],
})
export class DonationCampaignLegalDocumentsComponent {
    @ViewChild('legalDocumentsDrawer') drawer: FuseDrawerComponent;

    hasSelectedItem$ = this.store.select(FilesSelectors.selectHasSelectedFile);
    selectedItem$ = this.store.select(FilesSelectors.selectSelectedFile).pipe(
        tap((file) => {
            if (
                file?.fileType ===
                    EFileType.DonationCampaignFundraisingAppeal ||
                file?.fileType === EFileType.DonationCampaignTaxReceipt
            ) {
                this.drawer?.open();
            }

            if (!file) {
                this.drawer?.close();
            }
        })
    );

    constructor(private store: Store) {}

    openDrawer() {
        if (!this.drawer) {
            return;
        }
        this.drawer.open();
    }
}
