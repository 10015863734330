import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DualQuizQuestionViewModel } from 'app/api';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';
import { IFormService } from './form-service';

@Injectable({
    providedIn: 'root',
})
export class DualQuizQuestionFormService
    implements IFormService<DualQuizQuestionViewModel>
{
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder) {
        this.initForm();
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            id: [''],
            correctAnswer: [null, Validators.required],
            question: ['', Validators.required],
            answer1: ['', Validators.required],
            answer2: ['', Validators.required],
            answer3: [''],
            answer4: [''],
            explanation: [''],
            source: [''],
            thematic: ['', Validators.required],
        });
        return this.formGroup;
    }

    updateForm(entity: DualQuizQuestionViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
    }

    getEntity(): { [key: string]: DualQuizQuestionViewModel } {
        this.formGroup.updateValueAndValidity();

        const dualQuizQuestionViewModel =
            returnOnlyNonNullFields<DualQuizQuestionViewModel>(
                this.formGroup.value
            ) as DualQuizQuestionViewModel;

        return { dualQuizQuestionViewModel };
    }

    get getFormGroup() {
        return this.formGroup;
    }
}
