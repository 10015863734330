import {
    DualQuizQuestionViewModel,
    DualQuizQuestionViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const DualQuizQuestionActions = createRessourceListActionGroup<
    DualQuizQuestionViewModel,
    DualQuizQuestionViewModelPaginatedListDto
>('DualQuizQuestion');
