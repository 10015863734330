import { isNil, pick, pickBy } from 'lodash-es';

export function returnOnlyNonNullFields<T>(obj: T) {
    const fields = Object.keys(obj);
    const filteredValues = pickBy(obj as object, (value) => {
        return !isNil(value) && value !== '';
    });

    const object = pick(filteredValues, fields);
    return object;
}

export function mergeObjects<T>(obj1: T, obj2: T) {
    const result = { ...obj1 };
    Object.keys(obj2).forEach((key) => {
        const value = obj2[key];
        if (value !== null && value !== '') {
            result[key] = value;
        }
    });
    return result;
}
