import { AsyncPipe, DatePipe, NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkBadgeComponent as BslkBusinessBadgeComponent } from '@bslk-business/components/badge/badge.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { BadgeViewModel } from 'app/api';
import { BadgeActions } from 'app/store/badge/badge.action';
import { BadgeSelectors } from 'app/store/badge/badge.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'course-dashboard-my-badges',
    templateUrl: './my-badges.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkBusinessBadgeComponent,
        BslkTagComponent,
        DatePipe,
        LetDirective,
        MatIconModule,
        NgClass,
        NgIf,
        NgxSkeletonLoaderModule,
        TranslocoModule,
    ],
})
export class CourseDashboardMyBadgesComponent implements OnInit, OnDestroy {
    isLoading$ = this.store.select(BadgeSelectors.selectIsLoading);

    unsubscribeAll: Subject<any> = new Subject<any>();
    allBadges: BadgeViewModel[];
    currentBadgeIndex = 0;

    constructor(private store: Store) {}

    get currentBadge() {
        return this.allBadges[this.currentBadgeIndex];
    }

    get hasPrevious() {
        return this.currentBadgeIndex > 0;
    }

    get hasNext() {
        return this.currentBadgeIndex + 1 < this.allBadges.length;
    }

    ngOnInit(): void {
        this.store.dispatch(BadgeActions.loadBadgesRequest());

        this.store
            .select(BadgeSelectors.selectAllBadgesWithStatus)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((allBadges) => {
                this.allBadges = allBadges;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }

    next() {
        if (this.hasNext) {
            this.currentBadgeIndex++;
        }
    }

    previous() {
        if (this.hasPrevious) {
            this.currentBadgeIndex--;
        }
    }
}
