import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BslkBusinessSdgPickerComponent } from '@bslk-business/components/form/sdg-picker/sdg-picker.component';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import {
    BslkAdvancedSelectSyncComponent,
    BslkAdvancedSelectSyncInput,
} from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { BslkInfoComponent } from '@bslk/components/info/info.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EActivityField } from 'app/api';
import { CompanyFiltersFormService } from 'app/shared/services/form/company-filters-form.service';
import { TranslationService } from 'app/shared/services/transloco/translation.service';
import { CompanyFiltersActions } from 'app/store/company/company-filters/company-filters.action';
import { CompanyFiltersSelectors } from 'app/store/company/company-filters/company-filters.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';
import { BlacklistedAssociationsComponent } from './blacklisted-associations/blacklisted-associations.component';

@Component({
    selector: 'company-filters',
    templateUrl: './company-filters.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BlacklistedAssociationsComponent,
        BslkAdvancedSelectSyncComponent,
        BslkBusinessSdgPickerComponent,
        BslkClassicButtonComponent,
        BslkClassicCardComponent,
        BslkInfoComponent,
        LetDirective,
        NgIf,
        TranslocoModule,
        NgxSkeletonLoaderModule,
    ],
})
export class CompanyFiltersComponent implements OnInit {
    unsubscribeAll: Subject<any> = new Subject<any>();

    activityFieldsTranslationsLoaded: boolean = false;
    activityFieldsAdvancedSelectItems: BslkAdvancedSelectSyncInput[];

    isLoading$ = this.store.select(CompanyFiltersSelectors.selectIsLoading);

    constructor(
        private store: Store,
        private companyFiltersFormService: CompanyFiltersFormService,
        private translationService: TranslationService
    ) {}

    get formGroup() {
        return this.companyFiltersFormService.getFormGroup;
    }

    ngOnInit(): void {
        this.store.dispatch(CompanyFiltersActions.loadFiltersRequest());

        this.translationService
            .getTranslatedEnum(EActivityField, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.activityFieldsAdvancedSelectItems = translatedItems;
                this.activityFieldsTranslationsLoaded = true;
            });
    }

    save() {
        this.store.dispatch(CompanyFiltersActions.updateFiltersRequest());
    }

    ngOnDestroy() {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
