import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { AssociationUsersActions } from './association-users.actions';
import { AssociationUsersState } from './association-users.state';

export const initialState: AssociationUsersState = ressourceListInitialState;

export const associationUsersReducer = createRessourceListReducer(
    ressourceListInitialState,
    AssociationUsersActions
);
