import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { BslkColor } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'company-admin-dashboard-header-tab',
    templateUrl: './tab.component.html',
    standalone: true,
    imports: [MatIconModule, BslkTagComponent, NgIf, TranslocoModule],
})
export class CompanyAdminDashboardHeaderTabComponent {
    @Input() color: BslkColor = 'warn';
    @Input() icon: string;
    @Input() bgIcon: string;
    @Input() isActive: boolean;
    @Input() isDisabled = false;
}
