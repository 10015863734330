import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { EUserRole } from 'app/api';
import { redirectIfNotAllowed } from 'app/shared/utils/auth.utils';

export const SuperAdminGuard: CanActivateFn | CanActivateChildFn = () =>
    redirectIfNotAllowed([EUserRole.SuperAdmin]);

export const AssoAdminGuard: CanActivateFn | CanActivateChildFn = () =>
    redirectIfNotAllowed([EUserRole.AssoAdmin]);

export const RegularGuard: CanActivateFn | CanActivateChildFn = () =>
    redirectIfNotAllowed([EUserRole.Regular]);

export const CompanyAdminGuard: CanActivateFn | CanActivateChildFn = () =>
    redirectIfNotAllowed([EUserRole.CompanyAdmin]);

export const ExternalGuard: CanActivateFn | CanActivateChildFn = () =>
    redirectIfNotAllowed([EUserRole.External]);
