import { createSelector } from '@ngrx/store';
import { AppState } from '../..';
import { ProjectListSelectors } from '../project-list/project-list.selectors';

const selectState = (state: AppState) => state.project;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectProject = createSelector(selectState, (state) => state.project);

const selectProjectImagesUrl = createSelector(selectProject, (project) =>
    project?.images.map((image) => image.url)
);

const selectIsUploadFileLoading = createSelector(
    selectState,
    (state) => state.isUploadFileLoading
);

const selectIsCreatingLoading = createSelector(
    selectState,
    ProjectListSelectors.selectIsLoading,
    (state, isUpdating) => state.isCreatingLoading || isUpdating
);

const selectError = createSelector(selectState, (state) => state.error);

const selectCoverFile = createSelector(selectState, (state) => state.coverFile);

const selectLocations = createSelector(
    selectProject,
    (project) => project?.locations || []
);

const selectVideoUrl = createSelector(
    selectProject,
    (project) => `https://www.youtube.com/embed/${project?.videoUrl}`
);

const selectCanCreateProject = createSelector(
    selectState,
    (state) => state.canCreateProject?.canCreateProject
);
const selectMissingLegalDocuments = createSelector(
    selectState,
    (state) => state.canCreateProject?.missingDocuments
);
const selectIsLoadingCanCreateProject = createSelector(
    selectState,
    (state) => state.isLoadingCanCreateProject
);

export const ProjectSelectors = {
    selectCanCreateProject,
    selectCoverFile,
    selectError,
    selectIsCreatingLoading,
    selectIsLoading,
    selectIsLoadingCanCreateProject,
    selectIsUploadFileLoading,
    selectLocations,
    selectMissingLegalDocuments,
    selectProject,
    selectProjectImagesUrl,
    selectVideoUrl,
};
