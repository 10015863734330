/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserViewModel } from './userViewModel';
import { DonationCampaignViewModel } from './donationCampaignViewModel';
import { ProjectViewModel } from './projectViewModel';


export interface DonationRequestViewModel { 
    id?: number;
    dateCreated?: string;
    dateUpdated?: string | null;
    dateArchived?: string | null;
    isPaidToAssociation?: boolean;
    email?: string | null;
    familyName?: string | null;
    firstName?: string | null;
    code?: string | null;
    comment?: string | null;
    project?: ProjectViewModel;
    projectId?: number;
    user?: UserViewModel;
    donationCampaign?: DonationCampaignViewModel;
    userId?: number | null;
    donationCampaignId?: number;
}

