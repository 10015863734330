import { createSelector } from '@ngrx/store';
import { isEmpty, isNil } from 'lodash-es';
import { DateTime } from 'luxon';
import { AppState } from '../..';
import { UserSelectors } from '../../user/user/user.selectors';
import { MissionListSelectors } from '../mission-list/mission-list.selectors';
const selectState = (state: AppState) => state.mission;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectIsUploadFileLoading = createSelector(
    selectState,
    (state) => state.isUploadFileLoading
);

const selectIsCreatingLoading = createSelector(
    selectState,
    MissionListSelectors.selectIsLoading,
    (state, isUpdating) => state.isCreatingLoading || isUpdating
);

const selectError = createSelector(selectState, (state) => state.error);

const selectCoverFile = createSelector(selectState, (state) => state.coverFile);

const selectMission = createSelector(selectState, (state) => state.mission);

const selectAvailableMissionSlots = createSelector(selectMission, (mission) =>
    mission.missionSlots.filter(
        (s) =>
            s.availableSpotsLeft > 0 &&
            DateTime.fromISO(s.endDate) > DateTime.now()
    )
);

const selectAvailableMissionSlotsPreview = createSelector(
    selectAvailableMissionSlots,
    (missionSlots) => missionSlots.slice(0, 6)
);

const selectIsSeeAllAvailableMissionSlotsButtonNeeded = createSelector(
    selectAvailableMissionSlotsPreview,
    selectAvailableMissionSlots,
    (previewSlots, allSlots) => previewSlots.length !== allSlots.length
);

const selectHasCover = createSelector(
    selectMission,
    (mission) => !isEmpty(mission?.coverUrl)
);

const selectHasLogo = createSelector(
    selectMission,
    (mission) => !isEmpty(mission?.association?.logoUrl)
);

const selectLocation = createSelector(
    selectMission,
    (mission) => mission?.location
);

const selectLocationWithoutAddress = createSelector(
    selectLocation,
    (location) => {
        let locationString: string = location.country;
        if (!isEmpty(location.zipCode)) {
            locationString += ', ' + location.zipCode;
        }
        if (!isEmpty(location.city)) {
            locationString += ' ' + location.city;
        }
        return locationString;
    }
);

const selectAssociationNameFirstLetter = createSelector(
    selectMission,
    (mission) => mission?.association?.name[0]
);

const selectIsRegistered = createSelector(
    selectMission,
    UserSelectors.selectUserId,
    (mission, userId) =>
        mission?.missionSlots.some((s) =>
            s.users.some((u) => u.user.id === userId)
        )
);

const selectIsRegisteredToAllSlots = createSelector(
    selectMission,
    UserSelectors.selectUserId,
    (mission, userId) =>
        mission?.missionSlots.every((s) =>
            s.users.some((u) => u.user.id === userId)
        )
);

const selectIsDateSelectionVolunteerBased = createSelector(
    selectMission,
    (mission) => mission.isDateSelectionVolunteerBased
);

const selectCanProposeSlot = createSelector(
    selectMission,
    (mission) =>
        mission.isDateSelectionVolunteerBased &&
        mission?.missionSlots?.length < mission?.maxUniqueSlotProposals
);

const selectHasAnySlotWithAvailableSpot = createSelector(
    selectMission,
    (mission) => mission?.missionSlots.some((s) => s.availableSpotsLeft > 0)
);

const selectCanRegister = createSelector(
    selectIsRegisteredToAllSlots,
    selectHasAnySlotWithAvailableSpot,
    selectCanProposeSlot,
    (isRegisteretedToAllSlots, hasAnySlotWithAvailableSpot, canProposeSlot) =>
        canProposeSlot ||
        (!isRegisteretedToAllSlots && hasAnySlotWithAvailableSpot)
);

const selectIsTeamBuilding = createSelector(
    selectMission,
    (mission) => mission?.isTeamBuilding
);

const selectRegisterIsDisabled = createSelector(
    UserSelectors.selectIsManager,
    selectIsTeamBuilding,
    (isManager, isTeamBuilding) => {
        return !isManager && isTeamBuilding;
    }
);

const selectUserRegisteredTimeslotIds = createSelector(
    selectMission,
    UserSelectors.selectUserId,
    (mission, userId) =>
        mission?.missionSlots
            .filter((s) => s.users.some((u) => u.user.id === userId))
            .map((s) => s.id)
);

const selectHasResponsibleUser = createSelector(
    selectMission,
    (mission) => !isNil(mission?.responsibleUser)
);

const selectResponsibleUser = createSelector(
    selectMission,
    (mission) => mission?.responsibleUser
);

const selectResponseUserLastConnection = createSelector(
    selectMission,
    (mission) => DateTime.fromISO(mission?.responsibleUser?.lastConnection)
);

const selectResponsibleUserLastConnectionDiff = createSelector(
    selectResponseUserLastConnection,
    (lastConnection) =>
        DateTime.now().diff(lastConnection, ['days', 'hours', 'minutes'])
);

const selectResponsibleUserIsOnline = createSelector(
    selectResponsibleUserLastConnectionDiff,
    (lastConnectionDiff) => lastConnectionDiff.hours < 1
);

const selectResponsibleUserLastOnlineTimeUnit = createSelector(
    selectResponsibleUserLastConnectionDiff,
    (lastConnectionDiff) => {
        if (lastConnectionDiff.days > 0) {
            return 'days';
        }
        if (lastConnectionDiff.hours > 0) {
            return 'hours';
        }
        return 'minutes';
    }
);

const selectResponsibleUserLastOnlineTimeValue = createSelector(
    selectResponsibleUserLastConnectionDiff,
    (lastConnectionDiff) => {
        if (lastConnectionDiff.days > 0) {
            return lastConnectionDiff.days;
        }
        if (lastConnectionDiff.hours > 0) {
            return lastConnectionDiff.hours;
        }
        return lastConnectionDiff.minutes;
    }
);

export const MissionSelectors = {
    selectAssociationNameFirstLetter,
    selectAvailableMissionSlots,
    selectAvailableMissionSlotsPreview,
    selectCanRegister,
    selectCoverFile,
    selectError,
    selectHasCover,
    selectHasLogo,
    selectHasResponsibleUser,
    selectIsCreatingLoading,
    selectIsDateSelectionVolunteerBased,
    selectIsLoading,
    selectIsRegistered,
    selectIsRegisteredToAllSlots,
    selectIsSeeAllAvailableMissionSlotsButtonNeeded,
    selectIsTeamBuilding,
    selectIsUploadFileLoading,
    selectLocation,
    selectLocationWithoutAddress,
    selectMission,
    selectRegisterIsDisabled,
    selectResponsibleUser,
    selectResponsibleUserIsOnline,
    selectResponsibleUserLastOnlineTimeUnit,
    selectResponsibleUserLastOnlineTimeValue,
    selectUserRegisteredTimeslotIds,
};
