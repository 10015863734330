import {
    GoogleSigninButtonModule,
    SocialLoginModule,
} from '@abacritt/angularx-Social-login';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import {
    Component,
    HostListener,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import {
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthTemplateComponent } from 'app/shared/components/templates/auth-template/auth-template.component';
import { ErrorPipe } from 'app/shared/pipes/error.pipe';
import { UserActions } from 'app/store/user/user/user.actions';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { environment } from 'environments/environment';
import { RegularOrAssoDialogService } from '../sign-up/regular-or-asso/regular-or-asso-dialog.service';

@Component({
    selector: 'auth-sign-in',
    templateUrl: './sign-in.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        AsyncPipe,
        AuthTemplateComponent,
        BslkClassicButtonComponent,
        ErrorPipe,
        FormsModule,
        FuseAlertComponent,
        GoogleSigninButtonModule,
        JsonPipe,
        LetDirective,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        NgIf,
        ReactiveFormsModule,
        RouterLink,
        SocialLoginModule,
        TranslocoModule,
    ],
})
export class AuthSignInComponent implements OnInit {
    // Temporary solution to only display google sso when "secret" code is typed
    showSecretGoogleSso = false;
    private secretCode = 'googlesso';
    private enteredCode = '';

    loginFormGroup = this.formBuilder.group({
        email: ['', Validators.required],
        password: ['', Validators.required],
    });

    loginFailed$ = this.store.select(UserSelectors.selectLoginFailed);

    isLoading$ = this.store.select(UserSelectors.selectIsLoading);

    loginError$ = this.store.select(UserSelectors.selectLoginError);

    enableGoogleSso = environment.enableGoogleSso;

    constructor(
        private store: Store,
        private regularOrAssoDialogService: RegularOrAssoDialogService,
        private formBuilder: FormBuilder,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.authService.setIsRegistering(false);
        this.store.dispatch(UserActions.resetErrors());
    }

    microsoftSignIn() {
        this.authService.tryMicrosoftLogin('sign-in');
    }

    linkedInSignIn(): void {
        this.authService.trylinkedInLogin({ operationType: 'sign-in' });
    }

    connect() {
        if (this.loginFormGroup.valid) {
            const email = this.loginFormGroup.controls.email.value;
            const password = this.loginFormGroup.controls.password.value;
            this.authService.trySignInWithEmail(email, password);
        }
    }

    signup() {
        this.regularOrAssoDialogService.open();
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.enteredCode += event.key;
        if (this.enteredCode.endsWith(this.secretCode)) {
            this.showSecretGoogleSso = true;
        }

        // Optional: Clear enteredCode if it gets too long or if a certain key is pressed (e.g., Escape)
        if (
            this.enteredCode.length > this.secretCode.length ||
            event.key === 'Escape'
        ) {
            this.enteredCode = '';
        }
    }
}
