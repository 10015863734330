<div class="w-full" *ngrxLet="mission$ as mission">
    <cover-template
        *ngrxLet="isLoading$ as isLoading"
        [coverUrl]="mission?.coverUrl"
        [logoUrl]="mission?.association.logoUrl"
        [logoFirstLetter]="mission?.association?.name[0]"
        [title]="mission?.name"
        [isLoading]="isLoading"
    >
        <!-- Cover actions -->
        <div coverActions>
            <!-- Activity fields -->
            <enum-tag-list
                class="hidden md:flex"
                *ngIf="!isLoading"
                [tags]="mission.association.tags"
                [enum]="EActivityField"
                [maxTagsVisible]="3"
            ></enum-tag-list>
        </div>

        <!-- Regular Header actions -->
        <div
            *ngIf="isRegular$ | async"
            class="ml-auto flex gap-x-4"
            headerActions
        >
            <!-- Register -->
            <div *ngrxLet="isRegisterDisabled$ as isRegisterDisabled">
                <button
                    *ngIf="!isLoading && (canRegister$ | async)"
                    (click)="register()"
                    mat-flat-button
                    color="accent"
                    class="text-white"
                >
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_outline:bolt'"
                    ></mat-icon>
                    <span class="ml-2">{{
                        "missionview.register" | transloco
                    }}</span>
                </button>
            </div>
            <!-- Manage -->
            <a
                *ngIf="!isLoading && (registered$ | async)"
                [routerLink]="'/missions/' + mission.id + '/manage'"
            >
                <button mat-flat-button class="bg-tertiary text-white">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_outline:play'"
                    ></mat-icon>
                    <span class="ml-2">{{
                        "missionview.manage" | transloco
                    }}</span>
                </button>
            </a>
            <!-- Share -->
            <!-- <bslk-text-slide-button
                *ngIf="!(isAssoAdmin$ | async)"
                class="flex-none"
                [label]="'Partager' | transloco"
                [icon]="'heroicons_outline:share'"
            ></bslk-text-slide-button> -->
        </div>

        <!-- Regular Header actions -->
        <div
            *ngIf="(isAssoAdminOrSuperAdmin$ | async) && !isLoading"
            class="ml-auto flex gap-2"
            headerActions
        >
            <!-- Modify -->
            <bslk-classic-button
                *ngIf="isAssoAdminOrSuperAdmin$ | async"
                [label]="'shared.common.edit' | transloco"
                [icon]="'heroicons_outline:pencil'"
                (onClick)="editMission()"
            ></bslk-classic-button>

            <bslk-classic-button
                *ngIf="isAssoAdmin$ | async"
                [background]="'tertiary'"
                [label]="'shared.common.follow' | transloco"
                [icon]="'heroicons_outline:play'"
                [href]="'/a/missions/' + mission.id + '/manage'"
            >
            </bslk-classic-button>
        </div>

        <!-- Left Column -->
        <div class="w-full" leftColumn>
            <bslk-classic-card
                *ngIf="!isLoading; else skeleton50"
                [title]="'missionview.location' | transloco"
            >
                <div class="flex flex-col gap-y-3">
                    <div *ngIf="mission.isRemote">
                        <div class="flex items-center">
                            <mat-icon
                                class="icon-size-5 mr-3"
                                [svgIcon]="'heroicons_solid:computer-desktop'"
                            ></mat-icon>
                            <span class="leading-none">{{
                                "missionview.remote" | transloco
                            }}</span>
                        </div>
                    </div>
                    <div *ngIf="mission.location" class="flex flex-col">
                        <div class="flex items-center mb-3">
                            <mat-icon
                                class="icon-size-5 mr-3"
                                [svgIcon]="'heroicons_solid:map-pin'"
                            ></mat-icon>
                            <span class="leading-none">{{
                                locationWithoutAddress$ | async
                            }}</span>
                        </div>
                        <div class="flex items-center">
                            <mat-icon
                                class="icon-size-5 mr-3"
                                [svgIcon]="'heroicons_solid:home'"
                            ></mat-icon>
                            <span class="leading-none">{{
                                mission.location.address
                            }}</span>
                        </div>
                        <div
                            *ngIf="showGoogleMaps"
                            #googleMaps
                            class="mt-4 w-full h-60"
                        ></div>
                    </div>
                </div>
            </bslk-classic-card>

            <!-- Slots -->
            <bslk-classic-card
                *ngIf="!isLoading; else skeleton80"
                [title]="'missionview.slots' | transloco"
                class="flex w-full mt-5"
            >
                <div
                    *ngIf="!mission.isDateSelectionVolunteerBased"
                    class="space-y-3"
                >
                    <div
                        class="flex flex-col"
                        *ngFor="
                            let missionSlot of (showAllSlots
                                ? availableMissionSlots$
                                : availableMissionSlotsPreview$
                            ) | async
                        "
                    >
                        <div class="flex items-center">
                            <mat-icon
                                class="icon-size-4 mr-1"
                                [svgIcon]="'heroicons_solid:calendar'"
                            ></mat-icon>
                            <span class="font-bold">{{
                                formatDateRange(missionSlot)
                            }}</span>
                        </div>
                        <div class="flex items-center">
                            <span class="font-semibold"
                                >{{
                                    missionSlot.availableSpotsLeft
                                }}&nbsp;</span
                            >
                            <span>{{
                                "missionview.available-spots" | transloco
                            }}</span>
                        </div>
                    </div>

                    <div class="mt-2 flex justify-center">
                        <button
                            *ngIf="
                                isSeeAllAvailableMissionSlotsButtonNeeded$
                                    | async
                            "
                            mat-stroked-button
                            (click)="showAllSlots = !showAllSlots"
                        >
                            <mat-icon
                                class="text-current"
                                [svgIcon]="
                                    'heroicons_outline:chevron-' +
                                    (showAllSlots ? 'up' : 'down')
                                "
                            ></mat-icon>
                            <div class="ml-2">
                                {{
                                    "missionview.see-" +
                                        (showAllSlots ? "less" : "all")
                                        | transloco
                                }}
                            </div>
                        </button>
                    </div>
                </div>
                <div
                    class="flex flex-col gap-3"
                    *ngIf="mission.isDateSelectionVolunteerBased"
                >
                    <fuse-alert type="info" appearance="soft">
                        {{ "missionview.propose-slot" | transloco }}
                        {{ mission.expirationDate | date : "fullDate" }}.
                    </fuse-alert>

                    <div class="flex flex-col gap-1">
                        <div class="flex items-center gap-1">
                            <mat-icon
                                [svgIcon]="'heroicons_outline:clock'"
                            ></mat-icon>
                            {{ "missionview.duration" | transloco }}
                            <mat-icon
                                class="icon-size-3 text-current"
                                [svgIcon]="'heroicons_outline:arrow-right'"
                            ></mat-icon>
                            <span class="font-bold">
                                {{
                                    "enums.EDuration." + mission.duration
                                        | transloco
                                }}</span
                            >
                        </div>
                        <div
                            *ngIf="mission.isPaidTeamBuilding"
                            class="flex items-center gap-1"
                        >
                            <mat-icon
                                [svgIcon]="'heroicons_outline:banknotes'"
                            ></mat-icon>
                            {{ "missionview.price-per-person" | transloco }}
                            <mat-icon
                                class="icon-size-3 text-current"
                                [svgIcon]="'heroicons_outline:arrow-right'"
                            ></mat-icon>
                            <span class="font-bold">
                                {{ mission.paidTeamBuildingPricePerPerson
                                }}{{
                                    "enums.ECurrency." +
                                        mission.paidTeamBuildingCurrency
                                        | transloco
                                }}/{{ "missionview.pers" | transloco }}
                            </span>
                        </div>
                        <div
                            *ngIf="mission.isPaidTeamBuilding"
                            class="flex items-center gap-1"
                        >
                            <mat-icon
                                [svgIcon]="'heroicons_outline:users'"
                            ></mat-icon>
                            {{ "missionview.min-person" | transloco }}
                            <mat-icon
                                class="icon-size-3 text-current"
                                [svgIcon]="'heroicons_outline:arrow-right'"
                            ></mat-icon>
                            <span class="font-bold">
                                {{ mission.paidTeamBuildingMinPerson }}
                            </span>
                        </div>
                    </div>
                </div>
            </bslk-classic-card>

            <!-- Responsible User -->
            <ng-container *ngIf="!isLoading; else skeleton30">
                <bslk-classic-card
                    *ngIf="mission?.responsibleUser"
                    [title]="'missionview.responsible-user' | transloco"
                    class="flex w-full mt-5"
                >
                    <div class="flex">
                        <classic-avatar
                            class="mr-4"
                            [avatarUrl]="mission?.responsibleUser?.avatarUrl"
                            [fallbackLetter]="
                                mission?.responsibleUser?.firstName[0]
                            "
                            [textSize]="7"
                            [size]="20"
                        >
                        </classic-avatar>
                        <div class="flex flex-col justify-center">
                            <span class="text-lg font-semibold mb-2"
                                >{{ mission.responsibleUser.firstName }}
                                {{ mission.responsibleUser.familyName }}</span
                            >
                        </div>
                    </div>
                    <fuse-alert
                        class="mt-4"
                        [appearance]="'soft'"
                        [type]="'info'"
                    >
                        {{ "missionview.responsible-user-info" | transloco }}
                    </fuse-alert>
                </bslk-classic-card>
            </ng-container>

            <!-- Rewards -->
            <ng-container *ngIf="!isLoading; else skeleton30">
                <bslk-classic-card
                    [title]="'missionview.rewards-title' | transloco"
                    class="flex w-full mt-5"
                >
                    <div class="flex flex-col">
                        <span class="mb-2">{{
                            "missionview.rewards-description" | transloco
                        }}</span>
                        <bslk-reward
                            [size]="'large'"
                            [purposeCoins]="500"
                        ></bslk-reward>
                    </div>
                </bslk-classic-card>
            </ng-container>
        </div>

        <!-- Main -->

        <div class="flex w-full flex-col gap-y-5">
            <!-- Mission Description -->
            <bslk-classic-card
                class="w-full"
                *ngIf="!isLoading; else skeleton50"
                [title]="'missionview.description' | transloco"
            >
                <fuse-alert
                    class="flex mb-2"
                    *ngIf="mission.isTeamBuilding"
                    [type]="'info'"
                >
                    {{ "missionview.team-building-info" | transloco }}
                </fuse-alert>

                <div
                    class="flex flex-col gap-y-3 items-start px-6 overflow-x-scroll"
                >
                    <div [innerHTML]="sanitize(mission.description)"></div>
                    <div
                        class="w-full border-t"
                        *ngIf="mission.skills?.length > 0"
                    >
                        <div
                            class="text-lg flex items-center font-semibold my-3"
                        >
                            <mat-icon
                                class="text-current mr-2"
                                [svgIcon]="'heroicons_outline:wrench'"
                            ></mat-icon>
                            {{ "missionview.required-skills" | transloco }}
                        </div>
                        <enum-tag-list
                            *ngIf="!isLoading"
                            [tags]="mission.skills"
                            [enum]="ESkill"
                            [background]="'tertiary'"
                        ></enum-tag-list>
                    </div>
                    <div class="w-full border-t">
                        <div
                            class="w-full flex items-center my-3 text-lg font-semibold"
                        >
                            <mat-icon
                                class="text-current mr-2"
                                [svgIcon]="'heroicons_outline:bolt'"
                            ></mat-icon>
                            {{ "missionview.your-impact" | transloco }}
                        </div>
                        <span class="text-xl">
                            {{ mission.expectedImpact }}
                        </span>
                        <div class="flex flex-wrap gap-2 mt-2">
                            <span class="text-sm"
                                >{{ "missionview.sdg-impact" | transloco }}
                                <span class="font-semibold">{{
                                    "missionview.sdgs" | transloco
                                }}</span
                                ><bslk-info
                                    [tooltip]="
                                        'missionview.sdg-info' | transloco
                                    "
                                ></bslk-info>
                            </span>
                            <bslk-tag
                                *ngFor="
                                    let sdg of mission.sustainableDevelopmentGoals
                                "
                                [size]="'small'"
                                [icon]="sustainableDevlopmentGoals[sdg].icon"
                                [background]="null"
                                [backgroundHexaColor]="
                                    sustainableDevlopmentGoals[sdg].color
                                "
                            >
                                {{
                                    "enums.ESustainableDevelopmentGoal." + sdg
                                        | transloco
                                }}
                            </bslk-tag>
                        </div>
                    </div>
                </div>
            </bslk-classic-card>

            <!-- Association description -->
            <ng-container *ngIf="!isLoading; else skeleton30">
                <bslk-classic-card
                    *ngIf="mission.association.description"
                    [title]="
                        mission.association.name +
                        ' ' +
                        ('missionview.few-words' | transloco)
                    "
                    class="flex w-full"
                >
                    <div headerActions>
                        <a
                            target="_blank"
                            [matTooltip]="
                                'missionview.see-asso-profile' | transloco
                            "
                            [routerLink]="
                                '/organizations/' + mission.association.id
                            "
                        >
                            <mat-icon
                                class="text-current"
                                [svgIcon]="
                                    'heroicons_outline:information-circle'
                                "
                            ></mat-icon>
                        </a>
                    </div>
                    <div
                        class="flex flex-col sm:flex-row items-start mt-4 px-6 overflow-x-scroll"
                    >
                        <div
                            class="pb-1"
                            [innerHTML]="
                                sanitize(mission.association.description)
                            "
                        ></div>
                    </div>
                </bslk-classic-card>
            </ng-container>

            <!-- Association rating-->
            <ng-container
                *ngIf="
                    !isLoading && mission.association.rating;
                    else skeleton30
                "
            >
                <association-rating
                    [association]="mission.association"
                ></association-rating>
            </ng-container>

            <ng-container *ngIf="!isLoading; else skeleton30">
                <bslk-classic-card
                    *ngIf="
                        (isRegular$ | async) &&
                        mission.similarMissions &&
                        mission.similarMissions.length > 0
                    "
                    [title]="'missionview.similar-missions' | transloco"
                    class="flex w-full"
                >
                    <div class="grid sm:grid-cols-2 gap-4 mt-4">
                        <mission-grid-card
                            *ngFor="let mission of mission.similarMissions"
                            [mission]="mission"
                        >
                        </mission-grid-card>
                    </div>
                </bslk-classic-card>
            </ng-container>
        </div>
    </cover-template>
</div>
