<div class="w-full bg-white">
    <div
        class="grid grid-cols-12 p-0 md:pt-4 md:px-2 lg:px-4 xl:px-8 gap-x-4 relative"
    >
        <course-dashboard-thematic-timeline
            class="col-span-12 lg:col-span-8 min-h-screen"
        ></course-dashboard-thematic-timeline>
        <div class="col-span-12 lg:col-span-4 lg:sticky bottom-0 self-end">
            <div
                class="flex flex-col p-2 md:p-0 gap-4 sticky top-0 pb-4 mt-4 lg:mt-0"
            >
                <course-dashboard-experience-bar></course-dashboard-experience-bar>
                <div class="flex flex-col md:flex-row lg:flex-col gap-4">
                    <course-dashboard-my-badges
                        class="flex-1"
                    ></course-dashboard-my-badges>
                    <course-dashboard-ranking
                        class="flex-1"
                    ></course-dashboard-ranking>
                </div>
            </div>
        </div>
    </div>
</div>
