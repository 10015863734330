import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AuthTemplateComponent } from 'app/shared/components/templates/auth-template/auth-template.component';
import { ValidationActions } from 'app/store/validation/validation.actions';
import { ValidationSelectors } from 'app/store/validation/validation.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'email-validation',
    standalone: true,
    animations: fuseAnimations,
    templateUrl: './email-validation.component.html',
    imports: [
        AsyncPipe,
        AuthTemplateComponent,
        BslkClassicButtonComponent,
        FuseAlertComponent,
        LetDirective,
        MatProgressSpinnerModule,
        NgIf,
        TranslocoModule,
    ],
})
export class EmailValidationComponent implements OnInit, OnDestroy {
    private unsubscribeAll: Subject<any> = new Subject<any>();

    isLoading$ = this.store.select(ValidationSelectors.selectIsLoading);
    codeError$ = this.store.select(ValidationSelectors.selectCodeError);
    isEmailAlreadyValidated$ = this.store.select(
        ValidationSelectors.selectIsEmailAlreadyValidated
    );

    constructor(private store: Store, private route: ActivatedRoute) {}

    code: string;
    userId: number;
    newEmailSent = false;

    ngOnInit(): void {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                this.code = params['code'];
                this.userId = params['userId'];
                if (this.code && this.userId) {
                    this.validateEmail();
                }
            });
    }

    validateEmail() {
        this.store.dispatch(
            ValidationActions.validateEmailRequest({
                userId: this.userId,
                code: this.code,
            })
        );
    }

    sendNewMail() {
        this.newEmailSent = true;
        this.store.dispatch(
            ValidationActions.newValidationEmailRequest({ userId: this.userId })
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
