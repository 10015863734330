import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store';
import { isNil } from 'lodash-es';

const selectState = (state: AppState) => state.dualQuizChallenge;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectChallenge = createSelector(selectState, (state) => state.challenge);

const selectChallengeId = createSelector(
    selectChallenge,
    (challenge) => challenge?.id
);

const selectSecondsPerQuestion = createSelector(
    selectChallenge,
    (challenge) => challenge?.secondsPerQuestion
);

const selectCurrentQuestionIndex = createSelector(
    selectState,
    (state) => state.currentQuestionIndex
);

const selectCurrentQuestion = createSelector(
    selectCurrentQuestionIndex,
    selectChallenge,
    (index, challenge) => challenge?.questions[index]
);

const selectQuestionsLeft = createSelector(
    selectChallenge,
    (challenge) => challenge?.questions.filter((q) => !q.hasAnswered).length
);

const selectNoQuestionLeft = createSelector(
    selectQuestionsLeft,
    (questionsLeft) => questionsLeft === 0
);

const selectIsAnsweringLastQuestion = createSelector(
    selectQuestionsLeft,
    (questionsLeft) => questionsLeft === 1
);

const selectIsAnswering = createSelector(
    selectState,
    (state) => state.isAnswering
);

const selectAnswerResponse = createSelector(
    selectState,
    (state) => state.answerResponse
);

const selectIsExplanationAnImage = createSelector(
    selectAnswerResponse,
    (response) => response?.explanation?.startsWith('https')
);

const selectIsSourceALink = createSelector(selectAnswerResponse, (response) =>
    response?.source?.startsWith('https')
);

const selectShowQuestionDetails = createSelector(
    selectAnswerResponse,
    (answerResponse) => !isNil(answerResponse)
);

const selectIsTimerRunning = createSelector(
    selectState,
    (state) => state.isTimerRunning
);

const selectTimer = createSelector(selectState, (state) => state.timer);

const selectTimerPercentage = createSelector(
    selectState,
    (state) => (state.timer / (state.timerDuration * 1000)) * 100
);

const selectTimeLeftInSeconds = createSelector(selectState, (state) =>
    Math.round(state.timer / 1000)
);

const selectHasAnsweredOnce = createSelector(
    selectAnswerResponse,
    (response) => !isNil(response)
);

const selectCorrectAnswer = createSelector(
    selectAnswerResponse,
    (response) => response?.correctAnswer
);

const selectIsCorrect = createSelector(
    selectAnswerResponse,
    (response) => response?.isCorrect
);

const selectCorrectPercentage = createSelector(
    selectAnswerResponse,
    (response) => response?.correctPercentage
);

const selectExplanation = createSelector(
    selectAnswerResponse,
    (response) => response?.explanation
);

const selectLastChoice = createSelector(
    selectState,
    (state) => state.lastChoice
);

const selectQuestionEmittedResponseReceived = createSelector(
    selectState,
    (state) => state.questionEmittedResponseReceived
);

export const DualQuizChallengeSelectors = {
    selectIsLoading,
    selectChallenge,
    selectChallengeId,
    selectCurrentQuestionIndex,
    selectCurrentQuestion,
    selectCorrectPercentage,
    selectIsAnswering,
    selectAnswerResponse,
    selectIsTimerRunning,
    selectTimer,
    selectTimerPercentage,
    selectHasAnsweredOnce,
    selectCorrectAnswer,
    selectIsCorrect,
    selectTimeLeftInSeconds,
    selectExplanation,
    selectLastChoice,
    selectShowQuestionDetails,
    selectQuestionsLeft,
    selectSecondsPerQuestion,
    selectQuestionEmittedResponseReceived,
    selectNoQuestionLeft,
    selectIsAnsweringLastQuestion,
    selectIsExplanationAnImage,
    selectIsSourceALink,
};
