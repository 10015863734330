import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterLink } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { MissionFormService } from 'app/shared/services/form/mission-form.service';
import { MissionManageActions } from 'app/store/mission/mission-manage/mission-manage.action';
import { MissionManageSelectors } from 'app/store/mission/mission-manage/mission-manage.selectors';
import { isNil } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { RegistrationSuccessComponent } from '../shared/registration-success/registration-success/registration-success.component';
import { ProposeSlotCalendarComponent } from './calendar/propose-slot-calendar.component';

export type MissionProposeSlotDialogInput = {
    missionId: number;
    expirationDate: string;
};

@Component({
    selector: 'mission-propose-dialog',
    templateUrl: './mission-propose-slot-dialog.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkClassicButtonComponent,
        BaseDialogComponent,
        FormsModule,
        FuseAlertComponent,
        LetDirective,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        RegistrationSuccessComponent,
        RouterLink,
        TranslocoModule,
        ProposeSlotCalendarComponent,
    ],
})
export class MissionProposeSlotDialogComponent implements OnInit, OnDestroy {
    isLoading$ = this.store.select(
        MissionManageSelectors.selectIsLoadingUserValidation
    );

    unsubscribeAll: Subject<any> = new Subject<any>();
    selectedSlotId: number;
    registeredSlotId: number;

    constructor(
        private store: Store,
        private dialogRef: MatDialogRef<MissionProposeSlotDialogComponent>,
        private missionFormService: MissionFormService,
        @Inject(MAT_DIALOG_DATA) public data: MissionProposeSlotDialogInput
    ) {}

    get missionId() {
        return this.data.missionId.toString();
    }

    get showRegistrationSuccess() {
        return !isNil(this.registeredSlotId);
    }

    get isSubmitDisabled() {
        return (
            this.missionSlotsArray.invalid ||
            this.missionSlotsArray.length !== 1
        );
    }

    get missionSlotsArray() {
        return this.missionFormService.getFormGroup.controls
            .missionSlots as FormArray;
    }

    ngOnInit(): void {
        this.store
            .select(MissionManageSelectors.selectMissionSlotRegisteredId)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((id) => {
                this.registeredSlotId = id;
            });
    }

    register() {
        const missionSlot = this.missionSlotsArray.at(0).value;
        this.store.dispatch(
            MissionManageActions.proposeMissionSlotRequest({ missionSlot })
        );
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
