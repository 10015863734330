import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    FormArray,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ETrackedActivityType, MissionTaskViewModel } from 'app/api';
import { ActivityService } from 'app/shared/services/activity/activity.service';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { MissionEditTasksService } from './mission-edit-tasks.service';

@Component({
    selector: 'mission-edit-tasks',
    standalone: true,
    templateUrl: './mission-edit-tasks.component.html',
    imports: [
        AsyncPipe,
        BslkClassicCardComponent,
        FormsModule,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgFor,
        NgIf,
        LetDirective,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class MissionEditTasksComponent implements OnInit {
    @Input() tasks: MissionTaskViewModel[];

    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<MissionTaskViewModel[]> = new EventEmitter<
        MissionTaskViewModel[]
    >();

    isRegular$ = this.store.select(UserSelectors.selectIsRegular);

    constructor(
        private store: Store,
        private missionEditTasksService: MissionEditTasksService,
        private activityService: ActivityService
    ) {}

    get taskFormGroups() {
        return this.missionEditTasksService.tasksFormGroups;
    }

    get canSave() {
        return (
            this.taskFormGroups.length > 0 &&
            this.taskFormGroups.every((fg) => fg.valid)
        );
    }

    ngOnInit(): void {
        this.missionEditTasksService.updateTasks(this.tasks);
        this.activityService.sendActivityRequest({
            trackedActivityType: ETrackedActivityType.MissionManageEditTasks,
        });
    }

    addTask() {
        this.missionEditTasksService.addTaskFormGroup();
    }

    removeTask(formGroup: FormGroup) {
        this.missionEditTasksService.removeTaskFormGroup(formGroup);
    }

    addSubtask(formGroup: FormGroup) {
        this.missionEditTasksService.addSubtaskFormGroup(formGroup);
    }

    removeSubtask(formGroup: FormGroup, index: number) {
        this.missionEditTasksService.removeSubtaskFormGroup(formGroup, index);
    }

    getSubtaskTitleFormControl(formGroup: FormGroup) {
        return formGroup.controls.title;
    }

    hasMoreThanOneSubtask(formGroup: FormGroup) {
        let subtasksFormArray = formGroup.controls.subtasks as FormArray;
        return subtasksFormArray.controls.length > 1;
    }

    save() {
        if (this.canSave) {
            this.onSave.emit(this.missionEditTasksService.getTasks());
        }
    }

    close() {
        this.onClose.emit();
    }
}
