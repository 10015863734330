import {
    DualQuizAnswerResponseViewModel,
    DualQuizChallengeViewModel,
} from 'app/api';

export type DualQuizChallengeState = {
    isLoading: boolean;
    challenge?: DualQuizChallengeViewModel;
    currentQuestionIndex?: number;
    isAnswering: boolean;
    answerResponse?: DualQuizAnswerResponseViewModel;
    hasAnsweredOnce: boolean;
    questionEmittedResponseReceived: boolean; // only accept answer when server registered time at which question was emitted
    isTimerRunning: boolean;
    timer?: number;
    timerDuration?: number;
    lastChoice?: number;
};

export enum EDualQuizChallengePreQuestionState {
    Starting = 'Starting',
    Resuming = 'Resuming',
}
