/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EAuthenticationError = 'NotAllowedToSwitchRole' | 'EmailNotValidated' | 'InvalidVerificationCode' | 'InvalidState' | 'NoAccount' | 'NoAssociation' | 'NotAllowed' | 'WrongMethod' | 'WrongPassword' | 'NoAnonymousGuid';

export const EAuthenticationError = {
    NotAllowedToSwitchRole: 'NotAllowedToSwitchRole' as EAuthenticationError,
    EmailNotValidated: 'EmailNotValidated' as EAuthenticationError,
    InvalidVerificationCode: 'InvalidVerificationCode' as EAuthenticationError,
    InvalidState: 'InvalidState' as EAuthenticationError,
    NoAccount: 'NoAccount' as EAuthenticationError,
    NoAssociation: 'NoAssociation' as EAuthenticationError,
    NotAllowed: 'NotAllowed' as EAuthenticationError,
    WrongMethod: 'WrongMethod' as EAuthenticationError,
    WrongPassword: 'WrongPassword' as EAuthenticationError,
    NoAnonymousGuid: 'NoAnonymousGuid' as EAuthenticationError
};

