import {
    AssociationViewModel,
    AssociationViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const AssociationListActions = createRessourceListActionGroup<
    AssociationViewModel,
    AssociationViewModelPaginatedListDto
>('Association');
