import { createSelector } from '@ngrx/store';
import { isEmpty, isNil, orderBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { AppState } from '..';

const selectState = (state: AppState) => state.chat;

const selectChats = createSelector(selectState, (state) => {
    const chats = isEmpty(state.searchChat) ? state.chats : state.filteredChats;
    return orderBy(chats, ['dateUpdated'], ['desc']);
});

const selectAllChats = createSelector(selectState, (state) =>
    orderBy(state.chats)
);

const selectUnreadChatsCount = createSelector(
    selectAllChats,
    (chats) => chats?.filter((c) => !c.isReaden).length
);

const selectSearchChat = createSelector(
    selectState,
    (state) => state.searchChat
);

const selectMessagesPageIndex = createSelector(
    selectState,
    (state) => state.selectedChatMessagesPageIndex
);

const selectHasChats = createSelector(
    selectAllChats,
    (chats) => !isEmpty(chats)
);

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectSelectedChatId = createSelector(
    selectState,
    (state) => state.selectedChatId
);

const selectSelectedChat = createSelector(selectState, (state) =>
    state.chats.find((c) => c.id === state.selectedChatId)
);

const selectHasSelectedChat = createSelector(
    selectSelectedChatId,
    (selectedChatId) => !isNil(selectedChatId)
);

const selectChatHasOlderMessages = createSelector(
    selectState,
    (state) =>
        state.selectedChatMessagesPageIndex + 1 <
        state.selectedChatMessagesToTalPages
);

const selectMessages = createSelector(selectState, (state) => {
    const messages = structuredClone(state.selectedChatMessages);
    return messages?.sort((a, b) => {
        const dateA = DateTime.fromISO(a.dateCreated);
        const dateB = DateTime.fromISO(b.dateCreated);
        return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
    });
});

export const ChatSelectors = {
    selectSelectedChatId,
    selectSelectedChat,
    selectSearchChat,
    selectHasSelectedChat,
    selectMessagesPageIndex,
    selectChatHasOlderMessages,
    selectIsLoading,
    selectUnreadChatsCount,
    selectAllChats,
    selectChats,
    selectHasChats,
    selectMessages,
};
