import { AsyncPipe, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AssociationDashboardSelectors } from 'app/store/association/association-dashboard/association-dashboard.selectors';
import { AssociationSelectors } from 'app/store/association/association/association.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { sample } from 'lodash-es';
import { CreateMissionDialogService } from '../create-mission/create-mission-dialog.service';
import { ProjectDonationsComponent } from './charts/project-donations/project-donations.component';
import { MissionsKpisComponent } from './missions-kpis/missions-kpis.component';
import { PendingAssociationApprovalsComponent } from './pending-association-approvals/pending-association-approvals.component';
@Component({
    selector: 'asso-dashboard',
    standalone: true,
    templateUrl: './asso-dashboard.component.html',
    imports: [
        MatButtonModule,
        ProjectDonationsComponent,
        MatTooltipModule,
        MissionsKpisComponent,
        PendingAssociationApprovalsComponent,
        AsyncPipe,
        NgClass,
        MatIconModule,
        LetDirective,
        TranslocoModule,
    ],
})
export class AssoDashboardComponent {
    basilePosture = sample([
        'enthusiast',
        'flexing',
        'happy',
        'hello',
        'jumping',
        'reading',
        'peeking-left',
        'peeking-right',
        'speaking',
        'writing',
    ]);

    isProjectDonations$ = this.store.select(
        AssociationDashboardSelectors.selectIsKpiExpanded('project-donations')
    );

    displayedName$ = this.store.select(UserSelectors.selectFirstName);

    canCreateMission$ = this.store.select(
        AssociationSelectors.selectCanCreateMission
    );

    constructor(
        private store: Store,
        private createMissionDialogService: CreateMissionDialogService
    ) {}

    newMission(canCreateMission: boolean) {
        if (canCreateMission) {
            this.createMissionDialogService.open({
                isEdit: false,
                isFromEntityPage: false,
            });
        }
    }
}
