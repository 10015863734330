import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    AuthenticationService,
    EUserRole,
    RegistrationService,
    ReminderConfigurationService,
    UserService,
} from 'app/api';
import { AuthService } from 'app/core/auth/auth.service';
import { RewardDialogService } from 'app/shared/components/dialog/reward/reward-dialog.service';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { UserFormService } from 'app/shared/services/form/user-form.service';
import { isEmpty, isNil } from 'lodash-es';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';
import { AlertActions } from '../../alert/alert.actions';
import { UserActions } from './user.actions';
import { UserSelectors } from './user.selectors';
@Injectable()
export class UserEffects {
    signedInWithGoogle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.signedInWithGoogle),
            filter(({ user }) => !isNil(user)),
            exhaustMap(({ user }) =>
                this.apiAuthService
                    .authGooglePost({ body: `"${user.idToken}"` })
                    .pipe(
                        map((response) =>
                            UserActions.signInSuccess({ session: response })
                        ),
                        catchError((httpResponse) =>
                            of(
                                UserActions.signInFailure({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    )
            )
        )
    );

    signInWithMicrosoft$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.signedInWithMicrosoft),
            exhaustMap(({ result }) =>
                this.apiAuthService
                    .authMicrosoftPost({
                        authenticationSecuredSsoPayload: {
                            token: result.idToken,
                            state: result.state,
                        },
                    })
                    .pipe(
                        map((response) =>
                            UserActions.signInSuccess({ session: response })
                        ),
                        catchError((httpResponse) =>
                            of(
                                UserActions.signInFailure({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    )
            )
        )
    );

    signInWithLinkedIn$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.signedInWithLinkedIn),
            exhaustMap(({ code, state }) =>
                this.apiAuthService
                    .authLinkedinPost({
                        authenticationSecuredSsoPayload: { token: code, state },
                    })
                    .pipe(
                        map((response) =>
                            UserActions.signInSuccess({ session: response })
                        ),
                        catchError((httpResponse) =>
                            of(
                                UserActions.signInFailure({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    )
            )
        )
    );

    signInWithEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.signedInWithEmail),
            exhaustMap(({ email, password }) =>
                this.apiAuthService
                    .authClassicPost({
                        authenticationInfo: {
                            email,
                            password,
                        },
                    })
                    .pipe(
                        map((response) =>
                            UserActions.signInSuccess({ session: response })
                        ),
                        catchError((httpResponse) =>
                            of(
                                UserActions.signInFailure({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    )
            )
        )
    );

    signIn$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.signInSuccess, UserActions.signUpSuccess),
                tap(({ session }) => this.authService.signIn(session))
            ),
        { dispatch: false }
    );

    signUp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                UserActions.signUpUserWithGoogle,
                UserActions.signUpUserWithMicrosoft,
                UserActions.signUpUserWithLinkedIn,
                UserActions.signUpUserWithLinkedInWithProfessionalEmailLinked,
                UserActions.signUpUserWithEmail
            ),
            concatLatestFrom(() =>
                this.store.select(UserSelectors.selectBuiltUser)
            ),
            exhaustMap(([{}, userViewModel]) =>
                this.registrationService
                    .registrationPost({ userViewModel })
                    .pipe(
                        map((session) =>
                            UserActions.signUpSuccess({ session })
                        ),
                        catchError((httpResponse) =>
                            of(
                                UserActions.signUpFailure({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    )
            )
        )
    );

    signedOut$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.signOut),
            exhaustMap(() =>
                this.apiAuthService.authSignoutPost().pipe(
                    tap(() => {
                        this.authService.signOut();
                    }),
                    map(() => AlertActions.doNotDisplayError()),
                    catchError(() => {
                        this.authService.signOut();
                        return of(AlertActions.displayGenericError());
                    })
                )
            )
        )
    );

    redirectAfterLogin$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.signInSuccess),
                tap(() => {
                    const redirectUrlQueryParam =
                        this.activatedRoute.snapshot.queryParams.redirectURL;
                    const redirectUrl = !isNil(redirectUrlQueryParam)
                        ? redirectUrlQueryParam
                        : '/';
                    this.router.navigateByUrl(redirectUrl);
                })
            ),
        { dispatch: false }
    );

    signInAfterSignUpSuccess = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.signUpSuccess),
            filter(
                ({ session }) =>
                    session.success && !session.needsToValidateEmail
            ),
            map(({ session }) => UserActions.signInSuccess({ session }))
        )
    );

    loadUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.loadUserInForm),
                concatLatestFrom(() =>
                    this.store.select(UserSelectors.selectUser)
                ),
                tap(([, user]) => {
                    this.userFormService.updateForm(user);
                })
            ),
        { dispatch: false }
    );

    updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateRequest),
            exhaustMap(() => {
                const user = this.userFormService.getEntity();
                return this.userService.userPut(user).pipe(
                    map((rewards) => {
                        const hasGainedRewards = !isEmpty(rewards);
                        if (hasGainedRewards) {
                            this.rewardDialogService.open({
                                rewards,
                            });
                        }
                        return UserActions.updateRequestSuccess({
                            user: user.userViewModel,
                            hasGainedRewards,
                        });
                    }),
                    catchError((httpResponse) =>
                        of(
                            UserActions.updateRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    updateSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateRequestSuccess),
            map(() => {
                return AlertActions.displaySuccess({ key: 'user-update' });
            })
        )
    );

    updateFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateRequestFail),
            map(() => {
                return AlertActions.displayError({ key: 'user-update' });
            })
        )
    );

    cancelUpdate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.cancelUpdate),
                concatLatestFrom(() =>
                    this.store.select(UserSelectors.selectUser)
                ),
                tap(([, user]) => {
                    this.userFormService.updateForm(user);
                })
            ),
        { dispatch: false }
    );

    updateAvatar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateAvatarRequest),
            exhaustMap(({ file }) => {
                return this.userService.userAvatarPut({ file }).pipe(
                    map((url) =>
                        UserActions.updateAvatarRequestSuccess({ url })
                    ),
                    catchError((httpResponse) =>
                        of(
                            UserActions.updateAvatarRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    updateAvatarSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.updateAvatarRequestSuccess),
                tap(() => {
                    this.fileUploadDialogService.close();
                })
            ),
        { dispatch: false }
    );

    updateAvatarFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateAvatarRequestFail),
            map(() => {
                return AlertActions.displayGenericError();
            })
        )
    );

    updatePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updatePasswordRequest),
            exhaustMap(({ oldPassword, newPassword }) => {
                return this.userService
                    .userChangePasswordPut({
                        userPasswordChangeViewModel: {
                            oldPassword,
                            newPassword,
                        },
                    })
                    .pipe(
                        map(() => UserActions.updatePasswordRequestSuccess()),
                        catchError((httpResponse) =>
                            of(
                                UserActions.updatePasswordRequestFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    );
            })
        )
    );

    updatePasswordSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updatePasswordRequestSuccess),
            map(() => {
                return AlertActions.displaySuccess({ key: 'password-update' });
            })
        )
    );

    switchRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.switchRoleRequest),
            exhaustMap(({ redirect }) => {
                return this.apiAuthService.authSwitchRolePost().pipe(
                    map((newRole) => {
                        return UserActions.switchRoleRequestSuccess({
                            newRole,
                            redirect,
                        });
                    }),
                    catchError((httpResponse) =>
                        of(
                            UserActions.switchRoleRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    redirectAfterSwitchRole$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(UserActions.switchRoleRequestSuccess),
                tap(({ newRole, redirect }) => {
                    if (redirect) {
                        // User has signed in as company admin with a redirect url that needs to be accessed as regular
                        this.router.navigateByUrl(redirect);
                        window.location.reload(); // Safe reload in case API 401 triggered js errors
                    } else {
                        const redirectUrl =
                            newRole === EUserRole.CompanyAdmin ? '/ca' : '/';
                        this.router.navigateByUrl(redirectUrl);
                    }
                })
            ),
        { dispatch: false }
    );

    loadSignatureIfNecessary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                UserActions.loadUserInForm,
                UserActions.updateRequestSuccess
            ),
            concatLatestFrom(() =>
                this.store.select(UserSelectors.selectHasSignature)
            ),
            filter(([, hasSignature]) => hasSignature),
            map(() => {
                return UserActions.loadSignatureRequest();
            })
        )
    );

    loadSignature$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.loadSignatureRequest),
            exhaustMap(() => {
                return this.userService.userSignatureGet().pipe(
                    map((url) =>
                        UserActions.loadSignatureRequestSuccess({ url })
                    ),
                    catchError((httpResponse) =>
                        of(
                            UserActions.loadSignatureRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    loadReminderConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.loadReminderConfigurationRequest),
            exhaustMap(() => {
                return this.reminderConfigurationService
                    .reminderConfigurationGet()
                    .pipe(
                        map((configuration) =>
                            UserActions.loadReminderConfigurationRequestSuccess(
                                {
                                    configuration,
                                }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                UserActions.loadReminderConfigurationRequestFail(
                                    {
                                        error: httpResponse.error,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    updateConfigurationRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateReminderConfigurationRequest),
            concatLatestFrom(() =>
                this.store.select(UserSelectors.selectReminderConfiguration)
            ),
            exhaustMap(([, configuration]) => {
                return this.reminderConfigurationService
                    .reminderConfigurationPut({
                        reminderConfigurationViewModel: configuration,
                    })
                    .pipe(
                        map(() =>
                            UserActions.updateReminderConfigurationRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                UserActions.updateReminderConfigurationRequestFail(
                                    {
                                        error: httpResponse.error,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    updateUserRewardsIfNecessary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.checkIfRewardsAreUpdated),
            concatLatestFrom(() =>
                this.store.select(UserSelectors.selectHasGainedRewards)
            ),
            filter(([, hasGainedRewards]) => hasGainedRewards),
            exhaustMap(() => {
                return this.userService.userMeGet().pipe(
                    map((user) =>
                        UserActions.updateUserRewards({
                            experiencePoints: user.experiencePoints,
                            purposeCoins: user.purposeCoins,
                        })
                    )
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private store: Store,
        private authService: AuthService,
        private apiAuthService: AuthenticationService,
        private reminderConfigurationService: ReminderConfigurationService,
        private userService: UserService,
        private registrationService: RegistrationService,
        private userFormService: UserFormService,
        private rewardDialogService: RewardDialogService,
        private fileUploadDialogService: FileUploadDialogService
    ) {}
}
