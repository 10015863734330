import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { TranslocoModule, isNil } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ProjectViewModel } from 'app/api';
import { ProjectListActions } from 'app/store/project/project-list/project-list.actions';
import { ProjectListSelectors } from 'app/store/project/project-list/project-list.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { of } from 'rxjs';
import { ProjectCardComponent } from './card/card.component';
import { ProjectListFiltersComponent } from './filters/filters.component';

@Component({
    selector: 'project-list',
    templateUrl: './project-list.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        NgClass,
        NgFor,
        TranslocoModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        ProjectCardComponent,
        ProjectListFiltersComponent,
        NgxSkeletonLoaderModule,
    ],
})
export class ProjectListComponent implements OnInit {
    @Input() projects: ProjectViewModel[];
    @Input() canSelect = false;
    @Input() canChoose = false;
    @Input() selectedProjectIds: number[] = [];

    @Output() selectedProjectsChange = new EventEmitter<number[]>();
    @Output() projectChosen = new EventEmitter<number>();

    projects$ = this.store.select(ProjectListSelectors.selectData);
    isLoading$ = this.store.select(ProjectListSelectors.selectIsLoading);
    pageIndex$ = this.store.select(ProjectListSelectors.selectPageIndex);
    totalPages$ = this.store.select(ProjectListSelectors.selectTotalPages);
    totalCount$ = this.store.select(ProjectListSelectors.selectTotalCount);
    pageSize$ = this.store.select(ProjectListSelectors.selectPageSize);

    constructor(private store: Store) {}

    get isSourceAsync() {
        return isNil(this.projects);
    }

    get projectsAsync() {
        return this.isSourceAsync ? this.projects$ : of(this.projects);
    }

    get projectsCountAsync() {
        return this.isSourceAsync ? this.totalCount$ : of(this.projects.length);
    }

    get showFilters() {
        return this.isSourceAsync || this.projects.length >= 10;
    }

    ngOnInit() {
        if (this.isSourceAsync) {
            this.store.dispatch(
                ProjectListActions.InitializeWithFilters({
                    filters: [{ key: 'isActive', value: true }],
                })
            );
        }
    }

    onProjectSelected(projectId: number) {
        this.selectedProjectIds = [...this.selectedProjectIds, projectId];
        this.selectedProjectsChange.emit(this.selectedProjectIds);
    }

    onProjectUnselected(projectId: number) {
        this.selectedProjectIds = this.selectedProjectIds.filter(
            (id) => id !== projectId
        );
        this.selectedProjectsChange.emit(this.selectedProjectIds);
    }

    onProjectChosen(projectId: number) {
        this.projectChosen.emit(projectId);
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            ProjectListActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
    }
}
