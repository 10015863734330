import { createSelector } from '@ngrx/store';
import { AppState } from '../..';

const selectState = (state: AppState) => state.companyCsrApproach;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);
const selectIsLoadingImageUpload = createSelector(
    selectState,
    (state) => state.isLoadingImageUpload
);

const selectCsrApproach = createSelector(
    selectState,
    (state) => state.csrApproach
);

export const CompanyCsrApproachSelectors = {
    selectIsLoading,
    selectCsrApproach,
    selectIsLoadingImageUpload,
};
