import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { RankingAnswersActions } from './ranking-answers.actions';
import { RankingAnswersState } from './ranking-answers.state';

export const initialState: RankingAnswersState = ressourceListInitialState;

export const rankingAnswersReducer = createRessourceListReducer(
    ressourceListInitialState,
    RankingAnswersActions
);
