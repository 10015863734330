<mat-form-field
    floatLabel="always"
    class="w-full"
    [subscriptSizing]="'dynamic'"
>
    <mat-label *ngIf="!hideLabel">{{
        "shared.location.address" | transloco
    }}</mat-label>
    <mat-icon
        class="icon-size-5"
        [svgIcon]="'heroicons_solid:map-pin'"
        matPrefix
    ></mat-icon>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <input
        class="input"
        autocomplete="off"
        [placeholder]="
            'shared.location.' + locationType + '-placeholder' | transloco
        "
        aria-autocomplete="none"
        list="autocompleteOff"
        matInput
        (change)="inputChange()"
        [formControl]="inputFormControl"
        #addresstext
    />
</mat-form-field>
