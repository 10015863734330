import { on } from '@ngrx/store';
import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { CompanyEmployeeActions } from './company-employee.actions';
import { CompanyEmployeeState } from './company-employee.state';

export const initialState: CompanyEmployeeState = ressourceListInitialState;

const additionalActions = [
    on(
        CompanyEmployeeActions.UpdateAllRequest,
        (state: CompanyEmployeeState) => {
            return {
                ...state,
                isLoading: true,
            };
        }
    ),
    on(
        CompanyEmployeeActions.UpdateAllRequestSuccess,
        (state: CompanyEmployeeState) => {
            return {
                ...state,
                isLoading: false,
            };
        }
    ),
    on(
        CompanyEmployeeActions.UpdateAllRequestFail,
        (state: CompanyEmployeeState) => {
            return {
                ...state,
                isLoading: false,
            };
        }
    ),
];

export const companyEmployeeReducer = createRessourceListReducer(
    ressourceListInitialState,
    CompanyEmployeeActions,
    additionalActions
);
