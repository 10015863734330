<div
    class="shadow border rounded-xl py-4 px-6 flex items-center gap-6 text-tertiary"
>
    <div
        class="ring-4 ring-tertiary rounded-full p-2 w-10 h-10 xxl:w-14 xxl:h-14"
    >
        <mat-icon
            [svgIcon]="'basile-flat'"
            class="icon-size-6 xxl:icon-size-10 text-current"
        ></mat-icon>
    </div>
    <experience-bar class="grow"></experience-bar>
</div>
