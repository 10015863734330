import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ClientSideErrorViewModel, ErrorService } from 'app/api';
import { first } from 'rxjs';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private recentErrors: { [key: string]: number } = {};
    private errorTimeout = 3000; // Time in milliseconds to ignore duplicate errors

    constructor(private errorService: ErrorService) {}

    handleError(error: any) {
        console.error(error);
        // Only log client side error, http errors will already be logged back-end side
        if (!(error instanceof HttpErrorResponse)) {
            const errorString = JSON.stringify({
                message: error.message,
                stack: error.stack,
            });
            const currentTime = new Date().getTime();
            // Check if the same error has occurred recently
            if (
                !this.recentErrors[errorString] ||
                currentTime - this.recentErrors[errorString] > this.errorTimeout
            ) {
                // Update the timestamp for this error
                this.recentErrors[errorString] = currentTime;

                // Send the error payload to your logging service
                const message = error.message || error.toString();
                const stackTrace = error.stack
                    ? error.stack
                    : 'No stack trace available';

                const url = window.location.href;
                const navigator = window.navigator.appVersion;
                const clientSideErrorViewModel: ClientSideErrorViewModel = {
                    message,
                    stackTrace,
                    navigator,
                    url,
                };
                this.errorService
                    .errorPost({ clientSideErrorViewModel })
                    .pipe(first())
                    .subscribe({
                        next: () => {
                            console.debug('Succesfully sent error to API');
                        },
                        error: (err) => {
                            console.error(
                                'Could not send error log to server',
                                err
                            );
                        },
                    });
            } else {
                console.debug('Not sending identical error to API');
            }
        }
    }
}
