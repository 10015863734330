import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DonationCampaignCustomizationViewModel } from 'app/api';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';
import { IFormService } from './form-service';

@Injectable({
    providedIn: 'root',
})
export class DonationCampaignCustomizationFormService
    implements IFormService<DonationCampaignCustomizationViewModel>
{
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder) {
        this.initForm();
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            id: [''],
            headerBackgroundColor: ['#ffffff', { nonNullable: true }],
            textColor: ['#f4af4b', { nonNullable: true }],
            bodyBackgroundColor: ['#f8eee1', { nonNullable: true }],
            imageAsBase64: [''],
            imageUrl: [''],
            dateCreated: [''],
            dateUpdated: [''],
        });
        return this.formGroup;
    }

    updateForm(entity: DonationCampaignCustomizationViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
    }

    getEntity(): { [key: string]: DonationCampaignCustomizationViewModel } {
        this.formGroup.updateValueAndValidity();

        const donationCampaignCustomizationViewModel =
            returnOnlyNonNullFields<DonationCampaignCustomizationViewModel>(
                this.formGroup.value
            ) as DonationCampaignCustomizationViewModel;

        return { donationCampaignCustomizationViewModel };
    }

    get getFormGroup() {
        return this.formGroup;
    }
}
