import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkCardToggleComponent } from '@bslk/components/form/toggle/card/card-toggle.component';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { EAwarenessThematic } from 'app/api';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { SingleSelectAsyncComponent } from 'app/shared/components/form/select/async/single-select-async.component';
import { DualQuizChallengesActions } from 'app/store/dual-quiz/challenges/challenges.action';
import { DualQuizChallengesSelectors } from 'app/store/dual-quiz/challenges/challenges.selectors';
import { CompanyUsersActions } from 'app/store/user/company-users/company-users.actions';
import { CompanyUsersSelectors } from 'app/store/user/company-users/company-users.selectors';
import { InteractedWithUsersActions } from 'app/store/user/interacted-with-users/interacted-with-users.actions';
import { InteractedWithUsersSelectors } from 'app/store/user/interacted-with-users/interacted-with-users.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { sample } from 'lodash-es';

export type NewChallengeDialogInput = {
    userId: number;
};

type ChallengeeType = 'registered' | 'unregistered';

@Component({
    selector: 'new-challenge-dialog',
    standalone: true,
    templateUrl: './new-challenge-dialog.component.html',
    imports: [
        AsyncPipe,
        BaseDialogComponent,
        BslkClassicButtonComponent,
        BslkCardToggleComponent,
        BslkInfoLabelComponent,
        FuseAlertComponent,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        NgClass,
        NgFor,
        NgIf,
        ReactiveFormsModule,
        SingleSelectAsyncComponent,
        TranslocoModule,
    ],
})
export class NewChallengeDialogComponent implements OnInit {
    EAwarenessThematic = EAwarenessThematic;
    CompanyUsersActions = CompanyUsersActions;
    CompanyUsersSelectors = CompanyUsersSelectors;
    InteractedWithUsersActions = InteractedWithUsersActions;
    InteractedWithUsersSelectors = InteractedWithUsersSelectors;

    firstRandomThematic: EAwarenessThematic;
    secondRandomThematic: EAwarenessThematic;
    thirdRandomThematic: EAwarenessThematic;

    isSpinAnimationOver = false;
    hasTriedStartingChallenge = false;

    formGroup = new FormGroup(
        {
            challengeeEmail: new FormControl(null, Validators.email),
            challengeeId: new FormControl(null),
            thematic: new FormControl(null),
            secondsPerQuestion: new FormControl(15, Validators.required),
            questionsCount: new FormControl(5, Validators.required),
            challengeeType: new FormControl('registered'),
        },
        { validators: this.dynamicValidator() }
    );

    hoverThematic: EAwarenessThematic;

    isLoading$ = this.store.select(DualQuizChallengesSelectors.selectIsLoading);
    userId$ = this.store.select(UserSelectors.selectUserId);
    isRegular$ = this.store.select(UserSelectors.selectIsRegular);
    isExternal$ = this.store.select(UserSelectors.selectIsExternal);

    constructor(
        private store: Store,
        private dialogRef: MatDialogRef<NewChallengeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: NewChallengeDialogInput
    ) {}

    get formGroupErrors(): string[] {
        return Object.keys(this.formGroup.errors || {}).filter(
            (key) => this.formGroup.errors[key]
        );
    }

    ngOnInit(): void {
        this.beginThematicRoulette();
        if (this.data?.userId) {
            this.formGroup.controls.challengeeId.setValue(this.data.userId);
        }
    }

    close() {
        this.dialogRef.close();
    }

    getRandomThematic(): EAwarenessThematic {
        const keys = Object.keys(EAwarenessThematic).filter(
            (key) =>
                key != this.firstRandomThematic &&
                key != this.secondRandomThematic &&
                key != this.thirdRandomThematic
        );

        const randomKey = sample(keys);

        return EAwarenessThematic[randomKey];
    }

    beginThematicRoulette() {
        const interval = setInterval(() => {
            this.firstRandomThematic = this.getRandomThematic();
            this.secondRandomThematic = this.getRandomThematic();
            this.thirdRandomThematic = this.getRandomThematic();
        }, 200);

        setTimeout(() => {
            clearInterval(interval);
            this.isSpinAnimationOver = true;
        }, 3000);
    }

    setThematic(thematic: EAwarenessThematic) {
        this.formGroup.controls.thematic.setValue(thematic);
    }

    setChallengeeType(challengeeType: ChallengeeType) {
        this.formGroup.controls.challengeeType.setValue(challengeeType);
    }

    startChallenge() {
        this.hasTriedStartingChallenge = true;

        if (this.formGroupErrors.length > 0) {
            return;
        }
        this.store.dispatch(
            DualQuizChallengesActions.NewChallengeRequest({
                questionsCount: this.formGroup.controls.questionsCount.value,
                challengeeId: this.formGroup.controls.challengeeId.value,
                challengeeEmail: this.formGroup.controls.challengeeEmail.value,
                thematic: this.formGroup.controls.thematic.value,
                secondsPerQuestion:
                    this.formGroup.controls.secondsPerQuestion.value,
            })
        );
    }

    dynamicValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const formGroup = control as FormGroup;

            const isMissingChallengeeId =
                formGroup.controls.challengeeType.value === 'registered' &&
                !formGroup.controls.challengeeId.value;

            const isMissingChallengeeEmail =
                formGroup.controls.challengeeType.value === 'unregistered' &&
                !formGroup.controls.challengeeEmail.value;
            const isInvalidChallengeeEmail =
                formGroup.controls.challengeeType.value === 'unregistered' &&
                formGroup.controls.challengeeEmail.invalid;

            return {
                missingChallengeeId: isMissingChallengeeId,
                missingChallengeeEmail: isMissingChallengeeEmail,
                //missingThematic: !formGroup.controls.thematic.value,
                invalidChallengeeEmail: isInvalidChallengeeEmail,
            };
        };
    }
}
