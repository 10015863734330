import { DatePipe } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { AdvancedChartFilters } from '@bslk/components/charts/bslk-advanced-chart/bslk-advanced-chart.component';
import { Store } from '@ngrx/store';
import { SuperAdminDashboardActions } from 'app/store/super-admin/super-admin-dashboard/super-admin-dashboard.actions';
import { SuperAdminDashboardSelectors } from 'app/store/super-admin/super-admin-dashboard/super-admin-dashboard.selectors';
import { SuperAdminKpi } from 'app/store/super-admin/super-admin-dashboard/super-admin-dashboard.state';
import { ApexAxisChartSeries, ApexOptions } from 'ng-apexcharts';
import { Subject, tap } from 'rxjs';

export abstract class SuperAdminDashboardChartComponent {
    chartOptions: Partial<ApexOptions>;
    labels: string[];
    series: ApexAxisChartSeries | number[];
    unsubscribeAll: Subject<any> = new Subject<any>();

    isLoading$ = this.store
        .select(SuperAdminDashboardSelectors.selectIsLoading(this.kpiName))
        .pipe(
            tap(() => {
                this.cdRef.detectChanges();
            })
        );

    isExpanded$ = this.store.select(
        SuperAdminDashboardSelectors.selectIsKpiExpanded(this.kpiName)
    );

    dateRange$ = this.store.select(
        SuperAdminDashboardSelectors.selectKpiResultDates(this.kpiName)
    );

    constructor(
        protected store: Store,
        protected datePipe: DatePipe,
        protected cdRef: ChangeDetectorRef,
        protected kpiName: SuperAdminKpi
    ) {}

    onExpand(isExpanded: boolean) {
        if (isExpanded) {
            this.store.dispatch(
                SuperAdminDashboardActions.expandKpi({ kpi: this.kpiName })
            );
        } else {
            this.store.dispatch(SuperAdminDashboardActions.minimizeKpi());
        }
    }

    onFilterChange(filters: AdvancedChartFilters) {
        this.store.dispatch(
            SuperAdminDashboardActions.updateKpiFilters({
                kpi: this.kpiName,
                filters: {
                    timePeriod: filters.timePeriod,
                    periodOffset: filters.periodOffset,
                },
            })
        );
    }
}
