/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MissionRegisteredViewModel } from './missionRegisteredViewModel';
import { MissionUserStatsViewModel } from './missionUserStatsViewModel';
import { DonationCampaignViewModel } from './donationCampaignViewModel';
import { MissionUnregisteredViewModel } from './missionUnregisteredViewModel';


export interface RegularRecapViewModel { 
    sensCoins?: number;
    commentsCount?: number;
    incomingMissions?: number;
    hasOngoingAwarenessCampaign?: boolean;
    chaptersCompletedCount?: number;
    donationsTotalContribution?: number;
    totalDonations?: number;
    missionStats?: MissionUserStatsViewModel;
    missions?: Array<MissionRegisteredViewModel> | null;
    recommendedMissions?: Array<MissionUnregisteredViewModel> | null;
    pendingDonationGifts?: Array<DonationCampaignViewModel> | null;
}

