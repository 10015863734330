<div
    [matTooltip]="disabledTooltip"
    [matTooltipDisabled]="!disabledTooltip || !isDisabled"
>
    <button
        [class]="'text-' + textColor"
        [class.fuse-mat-button-large]="size === 'large'"
        [class.w-full]="size === 'large'"
        [ngClass]="isDisabledOrIsLoading ? 'bg-gray-200' : 'bg-' + background"
        [disabled]="isDisabledOrIsLoading"
        (click)="onButtonClick()"
        mat-flat-button
        [type]="type"
    >
        <div class="flex items-center gap-2">
            <mat-progress-spinner
                *ngIf="isLoading"
                [diameter]="24"
                [mode]="'indeterminate'"
            ></mat-progress-spinner>
            <mat-icon
                *ngIf="icon && !isLoading"
                class="text-white"
                [svgIcon]="icon"
            >
            </mat-icon>
            <div>
                <span *ngIf="label">{{ label }}</span>
                <ng-content></ng-content>
            </div>
        </div>
    </button>
</div>
