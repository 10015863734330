<ol class="list-none relative border-s border-gray-200">
    <li *ngFor="let item of items" class="mb-6 ms-6">
        <bslk-round-icon
            [color]="getColorFromState(item.timeState)"
            [icon]="getIconFromState(item.timeState)"
            [size]="'small'"
            class="absolute -start-3"
        ></bslk-round-icon>
        <h3
            class="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white"
        >
            {{ item.titleKey | transloco }}
        </h3>
        <time
            *ngIf="item.date"
            class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
            >{{ "bslk.timeline." + item.dateLabel | transloco }}
            {{ item.date | date }}</time
        >
        <p class="text-base font-normal text-gray-500 dark:text-gray-400">
            {{ item.descriptionKey | transloco }}
        </p>
        <bslk-tag
            class="flex mt-4"
            *ngIf="showIsHereTag(item.timeState)"
            [background]="'primary-light'"
            [textColor]="'primary'"
            [size]="'very-small'"
            [fontWeight]="'normal'"
            >{{ "bslk.timeline.you-are-here" | transloco }}
        </bslk-tag>
        <div class="mt-4">
            <bslk-classic-button
                (onClick)="onActionClick(item)"
                *ngIf="item.actionKey"
                [isDisabled]="item.isActionDisabled"
                [label]="item.actionKey | transloco"
            ></bslk-classic-button>
        </div>
    </li>
</ol>
