<div class="flex flex-col w-full">
    <div class="p-4 lg:p-8 xl:p-12 w-full grow">
        <div class="text-4xl font-extrabold tracking-tight">
            {{ "associationmissions.title" | transloco }}
        </div>
        <div class="flex justify-between mt-4">
            <span class="hidden lg:inline">{{
                "associationmissions.subtitle" | transloco
            }}</span>
            <div
                class="auto-width flex items-center ml-auto"
                *ngIf="translationsLoaded"
            >
                <span class="whitespace-nowrap mr-2">{{
                    "associationmissions.sort-by" | transloco
                }}</span>
                <mat-select
                    (ngModelChange)="sortColumnChanged($event)"
                    class="text-center min-w-50"
                    [(ngModel)]="sortBy"
                >
                    <mat-option
                        *ngFor="let missionSortOption of missionSortOptions"
                        [value]="missionSortOption"
                    >
                        <span class="text-center whitespace-nowrap">
                            {{ translations[missionSortOption.translationKey] }}
                        </span>
                    </mat-option>
                </mat-select>
                <mat-select
                    (ngModelChange)="sortDirectionChanged($event)"
                    class="min-w-30 ml-5"
                    [(ngModel)]="sortDirection"
                >
                    <mat-option [value]="'Asc'">
                        <span class="whitespace-nowrap">
                            {{ translations["asc"] }}
                        </span>
                    </mat-option>
                    <mat-option [value]="'Desc'">
                        <span class="whitespace-nowrap">
                            {{ translations["desc"] }}
                        </span>
                    </mat-option>
                </mat-select>
            </div>
        </div>

        <div class="mt-5" *ngrxLet="missions$ as missions">
            <div
                class="text-4xl font-bold text-center mt-10"
                *ngIf="!missions || missions.length === 0; else missionsList"
            >
                <span>{{ "associationmissions.no-mission" | transloco }}</span>
            </div>

            <ng-template #missionsList>
                <mission-association-card
                    [mission]="mission"
                    *ngFor="let mission of missions"
                >
                </mission-association-card>
            </ng-template>
        </div>
    </div>
    <mat-paginator
        class="bg-white border-b border-t"
        [ngClass]="{ 'pointer-events-none': isLoading$ | async }"
        [length]="totalCount$ | async"
        [pageIndex]="pageIndex$ | async"
        [pageSize]="pageSize$ | async"
        [hidePageSize]="true"
        (page)="pageChangeEvent($event)"
        [showFirstLastButtons]="true"
    >
    </mat-paginator>
</div>
