import { Component, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CompanyEmployeesComponent } from './company-employees/company-employees.component';

@Component({
    templateUrl: './setup.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        NgxSkeletonLoaderModule,
        CompanyEmployeesComponent,
    ],
})
export class CompanyAdminSetupComponent implements OnInit {
    constructor(private store: Store) {}

    ngOnInit() {}
}
