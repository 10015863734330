import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkTimelineComponent } from '@bslk/components/timeline/timeline.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { MissionStateComponent } from 'app/shared/components/mission/state/mission-state.component';
import { MissionManageActions } from 'app/store/mission/mission-manage/mission-manage.action';
import { MissionManageSelectors } from 'app/store/mission/mission-manage/mission-manage.selectors';

@Component({
    selector: 'mission-manage-state',
    standalone: true,
    templateUrl: './mission-manage-state.component.html',
    imports: [
        AsyncPipe,
        BslkClassicCardComponent,
        BslkTimelineComponent,
        MissionStateComponent,
        TranslocoModule,
    ],
})
export class MissionManageStateComponent {
    timeline$ = this.store.select(MissionManageSelectors.selectTimelineItems);

    constructor(private store: Store) {}

    onActionClick(actionId: string) {
        if (actionId === 'validate') {
            this.store.dispatch(MissionManageActions.validateSlot());
            return;
        }
    }
}
