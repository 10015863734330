import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProjectCanCreateViewModel, ProjectViewModel } from 'app/api';
import { CreateProjectDialogInput } from 'app/modules/association/create-project/create-project.component';

export const ProjectActions = createActionGroup({
    source: 'Project',
    events: {
        'Open Dialog': props<{ input: CreateProjectDialogInput }>(),
        'Close Dialog': emptyProps(),

        'Create Project Request': emptyProps(),
        'Create Project Request Success': props<{ id: number }>(),
        'Create Project Request Fail': props<{ error: string }>(),

        'Edit Project': emptyProps(),
        'Update Project Request': emptyProps(),
        'Update Project Request Success': emptyProps(),
        'Update Project Request Fail': props<{ error: string }>(),

        'Load Project Request': props<{ id: number }>(),
        'Load Project Request Success': props<{
            project: ProjectViewModel;
        }>(),
        'Load Project Request Fail': props<{ error: string }>(),

        'Load Can Create Project Request': emptyProps(),
        'Load Can Create Project Request Success': props<{
            response: ProjectCanCreateViewModel;
        }>(),
        'Load Can Create Project Request Fail': props<{ error: string }>(),
    },
});
