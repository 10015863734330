import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkColor } from '@bslk/types/bslk-types';
import { FuseCardComponent } from '@fuse/components/card';
import { BslkTagComponent } from '../../tag/tag.component';

@Component({
    selector: 'bslk-choice-card',
    standalone: true,
    templateUrl: './choice-card.component.html',
    imports: [
        FuseCardComponent,
        MatIconModule,
        BslkTagComponent,
        NgIf,
        NgClass,
    ],
})
export class BslkChoiceCardComponent {
    @Input() backgroundColor: BslkColor = 'tertiary';
    @Input() backgroundIcon: string;
    @Input() description: string;
    @Input() disabled = false;
    @Input() hideTitleIcon = false;
    @Input() tag: string;
    @Input() tagIcon: string;
    @Input() title: string;
    @Input() titleIcon = 'mat_solid:add';
}
