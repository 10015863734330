import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

export const translocoLanguageInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const translocoService = inject(TranslocoService);

    const languageCode = translocoService.getActiveLang().split('-')[0];

    const modifiedReq = req.clone({
        headers: req.headers.set('Accept-Language', languageCode),
    });

    return next(modifiedReq);
};
