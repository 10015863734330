<div
    *ngrxLet="hasDownloadedFileBlobUrl$ as hasDownloadedFileBlobUrl"
    class="flex flex-col flex-auto p-6 md:p-8 h-full"
>
    <!-- Close button -->
    <div class="flex items-center justify-end">
        <div class="cursor-pointer" (click)="unselectItem()" mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </div>
    </div>

    <!-- Name & Type -->
    <div class="flex flex-col items-start mt-8">
        <div class="text-xl font-medium">
            <span *ngIf="item.folderType">
                {{
                    item.name ??
                        ("files.folder-types." + item.folderType | transloco)
                }}</span
            >
            <span
                *ngIf="
                    item.fileType &&
                    !item.folderType &&
                    item.fileType !== EFileType.AssociationLegalDocument
                "
            >
                {{
                    item.name ??
                        ("files.file-types." + item.fileType | transloco)
                }}</span
            >
            <span *ngIf="item.fileType === EFileType.AssociationLegalDocument">
                {{
                    "files.association-legal-document." +
                        item.legalDocumentType +
                        ".title" | transloco
                }}</span
            >
        </div>
        <div class="text-secondary truncate" *ngIf="item.shortDescription">
            {{ item.shortDescription }}
        </div>
        <div
            class="mt-1 px-1.5 rounded text-sm font-semibold leading-5 text-white"
            [class.bg-indigo-600]="item.fileExtension === EFileExtension.Folder"
            [class.bg-red-600]="item.fileExtension === EFileExtension.Pdf"
            [class.bg-blue-600]="item.fileExtension === EFileExtension.Txt"
            [class.bg-amber-600]="item.fileExtension === EFileExtension.Jpeg"
            [class.bg-green-600]="item.fileExtension === EFileExtension.Png"
        >
            {{ item.fileExtension.toUpperCase() }}
        </div>
    </div>

    <!-- Preview -->
    <div
        *ngrxLet="isLoadingDownload$ as isLoadingDownload"
        [class.grow]="hasDownloadedFileBlobUrl"
        class="mt-8 aspect-[9/6]"
    >
        <div
            class="flex items-center justify-center h-full border rounded-lg bg-gray-50 dark:bg-card"
        >
            <ng-container *ngIf="item.fileExtension === EFileExtension.Folder">
                <mat-icon
                    class="icon-size-24 text-hint"
                    [svgIcon]="'heroicons_outline:folder'"
                ></mat-icon>
            </ng-container>
            <ng-container
                *ngIf="
                    item.fileExtension !== EFileExtension.Folder &&
                    !hasDownloadedFileBlobUrl &&
                    !isLoadingDownload
                "
            >
                <mat-icon
                    class="icon-size-24 text-hint"
                    [svgIcon]="'heroicons_outline:document'"
                ></mat-icon>
            </ng-container>

            <ng-container
                *ngrxLet="downloadedFileBlobUrl$ as downloadedFileBlobUrl"
            >
                <div
                    class="h-full flex flex-col pt-3 justify-between"
                    *ngIf="hasDownloadedFileBlobUrl"
                >
                    <div class="h-5/6">
                        <iframe
                            [src]="downloadedFileBlobUrl | safe"
                            width="100%"
                            height="100%"
                        ></iframe>
                    </div>

                    <div class="h-1/6 flex flex-col justify-center m-auto">
                        <bslk-classic-button
                            (onClick)="open(downloadedFileBlobUrl)"
                            [label]="'files.open-new-window' | transloco"
                            [icon]="'feather:download'"
                        ></bslk-classic-button>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="isLoadingDownload">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>

    <ng-container *ngrxLet="downloadedFileError$ as downloadedFileError">
        <fuse-alert
            class="flex mt-8"
            [type]="'error'"
            *ngIf="downloadedFileError"
        >
            {{ "files.error-missing-info" | transloco }}
            {{ "files.errors." + downloadedFileError | transloco }}
        </fuse-alert>
    </ng-container>
    <ng-container
        *ngrxLet="
            associationLegalDocumentWarning$ as associationLegalDocumentWarning
        "
    >
        <fuse-alert
            class="flex mt-8"
            [type]="'warn'"
            *ngIf="associationLegalDocumentWarning"
        >
            {{
                "files.warnings." + associationLegalDocumentWarning | transloco
            }}
        </fuse-alert>
    </ng-container>

    <!-- Information -->
    <ng-container *ngIf="item.displayedProperties?.length > 0">
        <div class="text-lg font-medium mt-8">
            {{ "files.information" | transloco }}
        </div>
        <div class="flex flex-col mt-4 border-t border-b divide-y font-medium">
            <div
                *ngFor="let property of item.displayedProperties | keyvalue"
                class="flex items-center justify-between py-3"
            >
                <div class="text-secondary">{{ property.key }}</div>
                <div>{{ property.value }}</div>
            </div>
        </div>
    </ng-container>

    <!-- Description -->
    <ng-container *ngIf="showDescription">
        <div class="flex items-center justify-between mt-8">
            <div class="text-lg font-medium">
                {{ "files.description" | transloco }}
            </div>
        </div>
        <div class="flex mt-2 border-t">
            <div class="py-3">
                <ng-container *ngIf="item.longDescription">
                    <div>{{ item.longDescription }}</div>
                </ng-container>
                <ng-container
                    *ngIf="item.fileExtension === EFileExtension.Folder"
                >
                    <div>
                        {{
                            "files.descriptions." +
                                item.folderType +
                                "." +
                                (userRole$ | async) | transloco
                        }}
                    </div>
                </ng-container>
                <ng-container
                    *ngIf="item.fileType === EFileType.AssociationLegalDocument"
                >
                    <div>
                        {{
                            "files.association-legal-document." +
                                item.legalDocumentType +
                                ".description" | transloco
                        }}
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <!-- Add File -->
    <div *ngIf="item.canUpload" class="flex flex-col gap-4 mt-8">
        <div class="text-lg pb-2 border-b">
            {{ "files.add" | transloco }}
        </div>
        <bslk-classic-button
            (click)="upload()"
            class="self-center mt-2"
            [icon]="'heroicons_outline:arrow-down-tray'"
            >{{ "files.upload" | transloco }}</bslk-classic-button
        >
    </div>
</div>
