import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkBusinessSdgPickerComponent } from '@bslk-business/components/form/sdg-picker/sdg-picker.component';
import { BslkAdvancedChartComponent } from '@bslk/components/charts/bslk-advanced-chart/bslk-advanced-chart.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import {
    ESustainableDevelopmentGoal,
    ESustainableDevelopmentGoalInt32EnumChart,
} from 'app/api';
import { dictionaryToSortedTuple } from 'app/shared/utils/array.utils';
import { sustainableDevelopmentGoals } from 'app/shared/utils/constants.utils';
import {
    getEnumIntValue,
    getEnumStringValue,
} from 'app/shared/utils/enum-helpers.utils';
import { CompanyAdminDashboardActions } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.actions';
import { CompanyAdminDashboardSelectors } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.selectors';
import { ApexOptions } from 'ng-apexcharts';
import { takeUntil } from 'rxjs';
import { CompanyAdminDashboardChartComponent } from '../chart.component';

@Component({
    selector: 'dual-quiz-answers-by-sdg',
    templateUrl: './dual-quiz-answers-by-sdg.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        LetDirective,
        BslkAdvancedChartComponent,
        MatIconModule,
        BslkBusinessSdgPickerComponent,
        TranslocoModule,
    ],
})
export class DualQuizAnswersBySdgComponent
    extends CompanyAdminDashboardChartComponent
    implements OnInit, OnDestroy
{
    expandOptions: Partial<ApexOptions> = {
        legend: {
            show: true,
        },
        yaxis: {
            show: true,
        },
        grid: {
            show: true,
        },
    };
    response: ESustainableDevelopmentGoalInt32EnumChart;
    sortedData: [string, number][] = [];

    constructor(
        protected store: Store,
        protected datePipe: DatePipe,
        protected cdRef: ChangeDetectorRef,
        protected translocoService: TranslocoService
    ) {
        super(store, datePipe, cdRef, 'dual-quiz-answers-by-sdg');
    }

    ngOnInit(): void {
        this.store.dispatch(
            CompanyAdminDashboardActions.checkIfKpiIsLoaded({
                kpi: this.kpiName,
            })
        );

        this.store
            .select(
                CompanyAdminDashboardSelectors.selectKpiResult(this.kpiName)
            )
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(
                (response: ESustainableDevelopmentGoalInt32EnumChart) => {
                    if (response) {
                        this.response = response;
                        this.sortedData = dictionaryToSortedTuple(
                            response.data
                        );

                        this.series = [
                            {
                                name: this.translocoService.translate(
                                    'companyadmindashboard.questions-answered'
                                ),
                                data: this.sortedData.map((v) => v[1]),
                            },
                        ];
                        this.buildOptions();
                    }
                }
            );
    }

    buildOptions() {
        const that = this;
        this.chartOptions = {
            chart: {
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    distributed: true,
                },
            },
            colors: this.sortedData.map(
                (v) => sustainableDevelopmentGoals[v[0]].color
            ),
            xaxis: {
                categories: this.sortedData.map(
                    (v) =>
                        getEnumIntValue(ESustainableDevelopmentGoal, v[0]) + 1
                ),
                labels: {
                    show: true,
                    style: {
                        fontSize: '12px',
                        fontWeight: '700',
                    },
                },
            },
            yaxis: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                position: 'left',
                formatter(legend) {
                    const sdgId = legend as unknown as number;
                    const sdgVal = getEnumStringValue(
                        ESustainableDevelopmentGoal,
                        sdgId - 1
                    );
                    return that.translocoService.translate(
                        'enums.ESustainableDevelopmentGoal.' + sdgVal
                    );
                },
            },
            grid: {
                show: false,
                padding: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
            tooltip: {
                x: {
                    formatter: function (value) {
                        const sdgId = value as unknown as number;
                        const sdgVal = getEnumStringValue(
                            ESustainableDevelopmentGoal,
                            sdgId - 1
                        );
                        return that.translocoService.translate(
                            'enums.ESustainableDevelopmentGoal.' + sdgVal
                        );
                    },
                },
            },
        };
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
