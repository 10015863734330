/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EDonationCampaignWhitelistType = 'None' | 'Email' | 'Code';

export const EDonationCampaignWhitelistType = {
    None: 'None' as EDonationCampaignWhitelistType,
    Email: 'Email' as EDonationCampaignWhitelistType,
    Code: 'Code' as EDonationCampaignWhitelistType
};

