<bslk-advanced-chart
    (expands)="onExpand($event)"
    (filterChanges)="onFilterChange($event)"
    class="w-full"
    [title]="'companyadmindashboard.kpis.satisfaction-rate' | transloco"
    [isLoading]="isLoading$ | async"
    [series]="series"
    [options]="chartOptions"
    [gradientColorFrom]="gradientColor"
    [gradientColorTo]="'white'"
    [infoTooltip]="
        'companyadmindashboard.kpi-infos.satisfaction-rate' | transloco
    "
    [hasData]="hasData"
    [isExpandable]="true"
    [isExpanded]="isExpanded$ | async"
    [dateRange]="dateRange$ | async"
    [isTimePeriodSelectionEnabled]="true"
>
    <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <mat-icon [svgIcon]="icon" class="icon-size-20 text-white"></mat-icon>
    </div>
</bslk-advanced-chart>
