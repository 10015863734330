import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { getUserFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { ProjectListActions } from 'app/store/project/project-list/project-list.actions';
import { ProjectListSelectors } from 'app/store/project/project-list/project-list.selectors';
import { Subject } from 'rxjs';
import { ProjectAssociationCardComponent } from './project-card/project-association-card.component';

@Component({
    selector: 'project-list',
    standalone: true,
    templateUrl: './project-list.component.html',
    imports: [
        AsyncPipe,
        FormsModule,
        LetDirective,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatPaginatorModule,
        MatSelectModule,
        ProjectAssociationCardComponent,
        NgClass,
        NgFor,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class AssociationProjectListComponent implements OnInit, OnDestroy {
    private unsubscribeAll: Subject<any> = new Subject<any>();

    projects$ = this.store.select(ProjectListSelectors.selectData);
    isLoading$ = this.store.select(ProjectListSelectors.selectIsLoading);
    pageIndex$ = this.store.select(ProjectListSelectors.selectPageIndex);
    totalPages$ = this.store.select(ProjectListSelectors.selectTotalPages);
    totalCount$ = this.store.select(ProjectListSelectors.selectTotalCount);
    pageSize$ = this.store.select(ProjectListSelectors.selectPageSize);

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(
            ProjectListActions.FilterListRequest({
                filters: [
                    {
                        key: 'associationId',
                        value: getUserFromLocalStorage().association.id,
                    },
                ],
            })
        );
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            ProjectListActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
