<bslk-info-label *ngIf="label" class="font-bold" [tooltip]="labelTooltip">{{
    label
}}</bslk-info-label>
<div
    [class.grayscale]="isReadonly || control?.disabled"
    class="flex flex-col md:flex-row justify-between mt-1"
>
    <div
        *ngFor="let choice of choices; let isFirst = first; let isLast = last"
        [class.truncate]="truncateDescription"
        class="flex flex-1 p-3 shadow-md border-r cursor-pointer"
        [ngClass]="{
            'rounded-tl-xl rounded-bl-xl border-l': isFirst,
            'rounded-tr-xl rounded-br-xl': isLast,
            'border-r border-r-gray-300': !isLast,
            'text-white': choice.value === value,
            'bg-primary': color === 'primary' && choice.value === value,
            'bg-primary-100': color === 'primary' && choice.value !== value,
            'bg-warn': color === 'warn' && choice.value === value,
            'bg-warn-100': color === 'warn' && choice.value !== value
        }"
        (click)="setValue(choice.value)"
    >
        <div class="my-auto flex w-full">
            <mat-icon
                class="my-auto mx-3 text-current"
                [svgIcon]="choice.icon"
            ></mat-icon>
            <div
                [class.truncate]="truncateDescription"
                class="flex flex-col h-full w-full"
            >
                <div class="flex justify-between items-center">
                    <span class="font-semibold" [matTooltip]="choice.text">{{
                        choice.text
                    }}</span>
                    <bslk-tag
                        *ngIf="choice.value === value && !hideSelectedTag"
                        [background]="'primary-light'"
                        [textColor]="'primary'"
                        [size]="'very-small'"
                        >{{ "bslk.form.selected" | transloco }}</bslk-tag
                    >
                </div>
                <div
                    [class.truncate]="truncateDescription"
                    class="text-sm"
                    [matTooltip]="choice.description"
                >
                    <span>{{ choice.description }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
