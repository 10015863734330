<bslk-info-label
    [class.font-bold]="isLabelBold"
    *ngIf="label"
    [tooltip]="infoTooltip"
    >{{ label }}</bslk-info-label
>
<div class="flex flex-wrap justify-between">
    <button
        *ngFor="let choice of choices; let isFirst = first; let isLast = last"
        [class.text-sm]="size === 'small' || size === 'very-small'"
        [class.p-2]="size === 'small' || size === 'very-small'"
        [class.p-3]="size === 'medium'"
        [class.p-4]="size === 'large'"
        [class.min-w-30]="size !== 'very-small'"
        class="truncate flex-1 font-semibold shadow-md border-r transition-all duration-300"
        [ngClass]="{
            'rounded-tl rounded-bl border-l':
                isFirst && (size === 'small' || size === 'very-small'),
            'rounded-tl-lg rounded-bl-lg border-l':
                isFirst && size === 'medium',
            'rounded-tr rounded-br':
                isLast && (size === 'small' || size === 'very-small'),
            'rounded-tr-lg rounded-br-lg': isLast && size === 'medium',
            'border-r border-r-gray-300': !isLast,
            'text-white': choice.value === value,
            'bg-gray-100': choice.value !== value
        }"
        [class.bg-primary]="color === 'primary' && choice.value === value"
        [class.bg-warn]="color === 'warn' && choice.value === value"
        (click)="setValue(choice.value)"
    >
        <span [matTooltip]="choice.text">{{ choice.text }}</span>
    </button>
</div>
