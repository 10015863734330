import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ElementRef } from '@angular/core';

export function createOverlayRef(
    overlay: Overlay,
    connectedTo: ElementRef
): OverlayRef {
    return overlay.create({
        hasBackdrop: true,
        backdropClass: 'fuse-backdrop-on-mobile',
        scrollStrategy: overlay.scrollStrategies.block(),
        positionStrategy: overlay
            .position()
            .flexibleConnectedTo(connectedTo)
            .withLockedPosition(true)
            .withPush(true)
            .withPositions([
                {
                    originX: 'end', // Positioning from the right side of the connected element
                    originY: 'top',
                    overlayX: 'start', // Aligning the overlay to the left side of the connected element
                    overlayY: 'top',
                },
                {
                    originX: 'end', // Positioning from the right side of the connected element
                    originY: 'bottom',
                    overlayX: 'start', // Aligning the overlay to the left side of the connected element
                    overlayY: 'bottom',
                },
                {
                    originX: 'start', // Positioning from the left side of the connected element
                    originY: 'top',
                    overlayX: 'end', // Aligning the overlay to the right side of the connected element
                    overlayY: 'top',
                },
                {
                    originX: 'start', // Positioning from the left side of the connected element
                    originY: 'bottom',
                    overlayX: 'end', // Aligning the overlay to the right side of the connected element
                    overlayY: 'bottom',
                },
            ]),
    });
}
