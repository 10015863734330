import { createAction, props } from '@ngrx/store';
import {
    CompanyEmployeeViewModel,
    CompanyEmployeeViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const CompanyEmployeeActions = {
    ...createRessourceListActionGroup<
        CompanyEmployeeViewModel,
        CompanyEmployeeViewModelPaginatedListDto
    >('CompanyEmployee'),

    UpdateAllRequest: createAction(
        '[CompanyEmployee] Update All Request',
        props<{ employees: CompanyEmployeeViewModel[] }>()
    ),
    UpdateAllRequestSuccess: createAction(
        '[CompanyEmployee] Update All Request Success'
    ),
    UpdateAllRequestFail: createAction(
        '[CompanyEmployee] Update All Request Fail',
        props<{ error: string }>()
    ),
};
