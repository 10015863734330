import { createSelector } from '@ngrx/store';
import { EFileExtension } from 'app/api';
import { isNil } from 'lodash-es';
import { AppState } from '..';

const selectState = (state: AppState) => state.files;

const selectSelectedFile = createSelector(
    selectState,
    (state) => state.selectedFile
);

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectIsLoadingUpload = createSelector(
    selectState,
    (state) => state.isLoadingUpload
);

const selectDownloadedFileBlobUrl = createSelector(
    selectState,
    (state) => state.downloadedFileBlobUrl
);

const selectHasDownloadedFileBlobUrl = createSelector(
    selectDownloadedFileBlobUrl,
    (fileBlobUrl) => !isNil(fileBlobUrl)
);

const selectIsLoadingDownload = createSelector(
    selectState,
    (state) => state.isLoadingDownload
);

const selectDownloadedFileError = createSelector(
    selectState,
    (state) => state.downloadedFileError
);

const selectAssociationLegalDocumentWarning = createSelector(
    selectState,
    (state) => state.associationLegalDocumentWarning
);

const selectData = createSelector(selectState, (state) => state.data);

const selectHasData = createSelector(selectData, (data) => !isNil(data));

const selectPageIndex = createSelector(
    selectState,
    (state) => state.currentFolderPageIndex
);
const selectTotalPages = createSelector(
    selectState,
    (state) => state.currentFolderPageSize
);
const selectTotalCount = createSelector(
    selectState,
    (state) => state.currentFolderTotalCount
);
const selectPageSize = createSelector(
    selectState,
    (state) => state.currentFolderPageSize
);

const selectPaths = createSelector(selectState, (state) => state.path);

const selectHasSelectedFile = createSelector(
    selectSelectedFile,
    (selectedFile) => !isNil(selectedFile)
);

const selectSelectedFolder = createSelector(
    selectState,
    (state) => state.selectedFolder
);

const selectHasSelectedFolder = createSelector(
    selectSelectedFolder,
    (selectedFolder) => !isNil(selectedFolder)
);

const selectDisplayedFiles = createSelector(
    selectSelectedFolder,
    selectHasSelectedFolder,
    (selectedFolder, hasSelectedFolder) => {
        if (!hasSelectedFolder) {
            return [];
        }
        return (
            selectedFolder.children?.filter(
                (f) => f.fileExtension !== EFileExtension.Folder
            ) ?? []
        );
    }
);

const selectDisplayedFolders = createSelector(
    selectData,
    selectSelectedFolder,
    selectHasSelectedFolder,
    (data, selectedFolder, hasSelectedFolder) => {
        if (!hasSelectedFolder) {
            return (
                data?.filter(
                    (f) => f.fileExtension === EFileExtension.Folder
                ) ?? []
            );
        }
        return (
            selectedFolder.children?.filter(
                (f) => f.fileExtension === EFileExtension.Folder
            ) ?? []
        );
    }
);

const selectItems = createSelector(
    selectDisplayedFiles,
    selectDisplayedFolders,
    selectPaths,
    (displayedFiles, displayedFolders, path) => {
        return {
            files: displayedFiles,
            folders: displayedFolders,
            path,
        };
    }
);

export const FilesSelectors = {
    selectAssociationLegalDocumentWarning,
    selectDownloadedFileBlobUrl,
    selectDownloadedFileError,
    selectHasDownloadedFileBlobUrl,
    selectHasSelectedFile,
    selectHasSelectedFolder,
    selectHasData,
    selectIsLoading,
    selectIsLoadingDownload,
    selectIsLoadingUpload,
    selectItems,
    selectPageIndex,
    selectPageSize,
    selectSelectedFile,
    selectSelectedFolder,
    selectTotalCount,
    selectTotalPages,
};
