import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkRadioGroupBinaryComponent } from '@bslk/components/form/radio-group-binary/radio-group-binary.component';
import { BslkStarRatingComponent } from '@bslk/components/form/rating/star-rating.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MissionRatingViewModel } from 'app/api';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';

@Component({
    selector: 'rating-report',
    templateUrl: './rating-report.component.html',
    standalone: true,
    imports: [
        BslkRadioGroupBinaryComponent,
        BslkTagComponent,
        MatTooltipModule,
        BslkStarRatingComponent,
        ClassicAvatarComponent,
        NgFor,
        NgIf,
        TranslocoModule,
    ],
})
export class RatingReportComponent {
    @Input() rating: MissionRatingViewModel;

    constructor() {}
}
