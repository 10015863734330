<div class="flex items-center justify-between pb-4">
    <div class="grow max-w-200 pr-10 flex justify-center">
        <ng-container *ngrxLet="percentages$ as percentages">
            <div *ngrxLet="results$ as results" class="flex w-full">
                <div
                    [style]="
                        'width: ' +
                        (percentages?.victoryPercentage ?? 33.33) +
                        '%'
                    "
                    class="min-w-fit flex flex-col uppercase transition-width duration-500"
                >
                    <span class="text-sm text-center font-semibold px-1">{{
                        "dualquiz.victories" | transloco
                    }}</span>
                    <div
                        class="bg-tertiary bg-opacity-50 rounded-l-2xl flex items-center justify-center py-2"
                    >
                        <span
                            [ngClass]="{
                                'text-transparent': !results,
                                'text-tertiary': results
                            }"
                            class="text-xl font-bold"
                            >{{ results?.victoryCount ?? 0 }}</span
                        >
                    </div>
                </div>

                <div
                    [style]="
                        'width: ' + (percentages?.drawPercentage ?? 33.33) + '%'
                    "
                    class="min-w-fit flex flex-col uppercase transition-width duration-500"
                >
                    <span class="text-sm text-center font-semibold px-1">{{
                        "dualquiz.draws" | transloco
                    }}</span>
                    <div
                        class="bg-warn bg-opacity-50 flex items-center justify-center py-2"
                    >
                        <span
                            [ngClass]="{
                                'text-transparent': !results,
                                'text-warn': results
                            }"
                            class="text-xl font-bold"
                            >{{ results?.drawCount ?? 0 }}</span
                        >
                    </div>
                </div>

                <div
                    [style]="
                        'width: ' +
                        (percentages?.defeatPercentage ?? 33.33) +
                        '%'
                    "
                    class="min-w-fit flex flex-col uppercase transition-width duration-500"
                >
                    <span class="text-sm text-center font-semibold px-1">{{
                        "dualquiz.defeats" | transloco
                    }}</span>
                    <div
                        class="bg-primary bg-opacity-50 rounded-r-2xl flex items-center justify-center py-2"
                    >
                        <span
                            [ngClass]="{
                                'text-transparent': !results,
                                'text-primary': results
                            }"
                            class="text-xl font-bold"
                            >{{ results?.defeatCount ?? 0 }}</span
                        >
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <button
        (click)="openNewChallengeDialog()"
        class="flex self-end font-semibold p-6 border border-solid border-primary"
        mat-flat-button
    >
        {{ "dualquiz.new-challenge" | transloco }}
    </button>
</div>

<ng-template #loading> </ng-template>
