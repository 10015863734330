import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { BslkColor, BslkSizeExtended } from '@bslk/types/bslk-types';
import { isNil } from 'lodash-es';
import { FormControlComponent } from '../../form-control-component';
export type BslkClassicToggleComponentInput = {
    text: string;
    value: any;
};

@Component({
    selector: 'bslk-classic-toggle',
    standalone: true,
    templateUrl: './classic-toggle.component.html',
    imports: [
        NgIf,
        MatButtonModule,
        NgFor,
        NgClass,
        MatTooltipModule,
        BslkInfoLabelComponent,
    ],
})
export class BslkClassicToggleComponent extends FormControlComponent {
    @Input() choices: BslkClassicToggleComponentInput[];
    @Input() color: BslkColor = 'primary';
    @Input() size: BslkSizeExtended = 'medium';
    @Input() infoTooltip: string;
    @Input() disableDefaultValueSet = false;

    extraOnInit = () => {
        if (
            !this.disableDefaultValueSet &&
            this.choices.length > 0 &&
            isNil(this.value)
        ) {
            this.setValue(this.choices.at(0).value);
        }
    };
}
