<div class="p-4 md:p-6 xxl:p-8 w-full">
    <div class="flex justify-between mb-6">
        <div class="flex flex-col gap-1">
            <span class="text-3xl font-bold">{{
                "donationcampaigns.gift-donation-campaigns" | transloco
            }}</span>
            <span class="text-lg">{{
                "donationcampaigns.create-update-track-campaigns" | transloco
            }}</span>
        </div>

        <div class="flex items-center gap-4">
            <bslk-tag-button
                (click)="openCustomizationDialog()"
                [icon]="'heroicons_outline:photo'"
                >{{
                    "donationcampaigns.customize" | transloco
                }}</bslk-tag-button
            >
            <bslk-classic-button
                (click)="openCreateCampaignDialog()"
                [icon]="'heroicons_outline:plus'"
            >
                {{
                    "donationcampaigns.new-campaign" | transloco
                }}</bslk-classic-button
            >
            <!-- <bslk-menu
                (onAction)="onMenuAction($event)"
                [icon]="'feather:more-vertical'"
                [iconSize]="'large'"
                [items]="[
                            {
                                icon: 'heroicons_solid:pencil',
                                action: 'customize',
                                label: 'Personnaliser' | transloco,
                            },
                    ]"
            ></bslk-menu> -->
        </div>
    </div>

    <div
        *ngIf="!(isLoading$ | async); else loading"
        class="grid grid-cols-1 gap-6"
    >
        <donation-campaign-card
            *ngFor="let campaign of campaigns$ | async"
            [campaign]="campaign"
        ></donation-campaign-card>
    </div>
</div>

<ng-template #loading>
    <div class="grid grid-cols-1 gap-6">
        <div
            class="rounded-xl shadow-lg h-36 overflow-hidden"
            *ngFor="let _ of [1, 2, 3, 4, 5, 6]"
        >
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full h-full"
            ></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
