import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DonationCampaignCustomizationViewModel } from 'app/api';
import { DonationCampaignCustomizeDialogInput } from 'app/modules/company-admin/donation-campaigns/customize-dialog/customize-dialog.component';

export const DonationCampaignCustomizationActions = createActionGroup({
    source: 'DonationCampaignCustomization',
    events: {
        'Open Dialog': props<{ input: DonationCampaignCustomizeDialogInput }>(),
        'Close Dialog': emptyProps(),
        'Load Donation Campaign Customization Request': props<{
            donationCampaignId: number;
        }>(),
        'Load Donation Campaign Customization Request Success': props<{
            donationCampaignCustomization: DonationCampaignCustomizationViewModel;
        }>(),
        'Load Donation Campaign Customization Request Failure': props<{
            error: string;
        }>(),
        'Update Donation Campaign Customization Request': emptyProps(),
        'Update Donation Campaign Customization Request Success': emptyProps(),
        'Update Donation Campaign Customization Request Failure': props<{
            error: string;
        }>(),
        'Create Or Update Specific Donation Campaign Customization Request':
            props<{ donationCampaignId: number }>(),
        'Create Or Update Specific Donation Campaign Customization Request Success':
            emptyProps(),
        'Create Or Update Specific Donation Campaign Customization Request Failure':
            props<{
                error: string;
            }>(),
    },
});
