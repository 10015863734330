<div class="flex flex-col">
    <ng-container *ngIf="!isLoading else skeletons">
        <mission-unregistered-row-card 
            class="mb-6"
            *ngFor="let mission of missions" 
            [mission]="mission">
        </mission-unregistered-row-card>
    </ng-container>
</div>

<ng-template #skeletons>
    <ngx-skeleton-loader [theme]="{height: '100%'}" class="w-full mt-6 mb-6 h-64"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{height: '100%'}" class="w-full mb-6 h-64"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{height: '100%'}" class="w-full mb-6 h-64"></ngx-skeleton-loader>
</ng-template>