export function colorWithOpacity(hex, opacity) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    const clampOpacity = Math.max(0, Math.min(1, opacity));

    return `rgba(${r}, ${g}, ${b}, ${clampOpacity})`;
}
export function lightenColor(hex, percent) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    const white = 255;
    const mixPercent = Math.min(100, Math.max(0, percent)) / 100;

    const newR = Math.floor(r + (white - r) * mixPercent);
    const newG = Math.floor(g + (white - g) * mixPercent);
    const newB = Math.floor(b + (white - b) * mixPercent);

    const newHex =
        '#' +
        newR.toString(16).padStart(2, '0') +
        newG.toString(16).padStart(2, '0') +
        newB.toString(16).padStart(2, '0');

    return newHex;
}
