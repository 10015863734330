import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { AvatarAssetViewModel } from 'app/api/model/avatarAssetViewModel';
import { getAvatarAssetPath } from 'app/shared/utils/file-helpers.utils';
import { MyBasileActions } from 'app/store/my-basile/my-basile.action';
import { BaseDialogComponent } from '../base/base-dialog.component';

export type NewAssetDialogInput = {
    asset: AvatarAssetViewModel;
};

@Component({
    selector: 'new-asset-dialog',
    standalone: true,
    templateUrl: './new-asset-dialog.component.html',
    imports: [
        BaseDialogComponent,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        TranslocoModule,
    ],
})
export class NewAssetDialogComponent {
    constructor(
        private store: Store,
        private dialogRef: MatDialogRef<NewAssetDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: NewAssetDialogInput
    ) {}

    get asset(): AvatarAssetViewModel {
        return this.data.asset;
    }

    get assetPath(): string {
        return getAvatarAssetPath(this.asset);
    }

    equip() {
        this.store.dispatch(
            MyBasileActions.equipAssetRequest({ asset: this.data.asset })
        );
        this.close();
    }

    close() {
        this.dialogRef.close();
    }
}
