import { createAction, props } from '@ngrx/store';
import {
    DualQuizChallengeMatchUpResultViewModel,
    DualQuizChallengeMatchUpResultViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from 'app/store/shared/ressource-list/ressource-list-action-group-creator';

export const DualQuizMatchUpsActions = {
    ...createRessourceListActionGroup<
        DualQuizChallengeMatchUpResultViewModel,
        DualQuizChallengeMatchUpResultViewModelPaginatedListDto
    >('DualQuizMatchUps'),

    LoadUserResultsRequest: createAction(
        `[DualQuizMatchUps] Load User Results Request`
    ),
    LoadUserResultsRequestSuccess: createAction(
        `[DualQuizMatchUps] Load User Results Request Success`,
        props<{ results: DualQuizChallengeMatchUpResultViewModel }>()
    ),
    LoadUserResultsRequestFail: createAction(
        `[DualQuizMatchUps] Load User Results Request Fail`,
        props<{ error: string }>()
    ),
};
