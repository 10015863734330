import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicOverlayComponent } from '@bslk/components/overlay/classic-overlay/bslk-classic-overlay/bslk-classic-overlay.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { BasileConfigurationViewModel } from 'app/api';
import { ExperienceBarComponent } from 'app/shared/components/experience-bar/experience-bar.component';
import { MyBasileComponent } from 'app/shared/components/my-basile/my-basile.component';
import { getUserLevelInformation } from 'app/shared/utils/experience-points.utils';
import { MyBasileSelectors } from 'app/store/my-basile/my-basilie.selectors';
import { tap } from 'rxjs';

@Component({
    selector: 'level-information',
    templateUrl: './level-information.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        LetDirective,
        ExperienceBarComponent,
        BslkClassicOverlayComponent,
        MatIconModule,
        MatTooltipModule,
        NgIf,
        NgClass,
        TranslocoModule,
        MyBasileComponent,
    ],
})
export class LevelInformationComponent implements AfterViewInit {
    @ViewChild('levelInformationOrigin') levelInformationOriginRef: ElementRef;

    isPanelOpen = false;
    isViewInitialized = false;
    shownLevel: number;

    basileConfiguration$ = this.store
        .select(MyBasileSelectors.selectBasileConfiguration)
        .pipe(
            tap((configuration: BasileConfigurationViewModel) => {
                if (configuration) {
                    this.shownLevel = getUserLevelInformation(
                        configuration.experiencePoints
                    ).currentLevel;
                    this.cdRef.detectChanges();
                }
            })
        );

    constructor(private cdRef: ChangeDetectorRef, private store: Store) {}

    get hasPrevious() {
        return this.shownLevel > 1;
    }

    get hasNext() {
        return this.shownLevel < 100;
    }

    openPanel(): void {
        this.isPanelOpen = true;
    }

    ngAfterViewInit(): void {
        this.isViewInitialized = true;
        this.cdRef.detectChanges();
    }

    previous() {
        if (this.hasPrevious) {
            this.shownLevel -= 1;
        }
    }

    next() {
        if (this.hasNext) {
            this.shownLevel += 1;
        }
    }

    onClose() {
        this.isPanelOpen = false;
    }
}
