import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ETrackedActivityType } from 'app/api';
import { ActivityService } from 'app/shared/services/activity/activity.service';
import { MissionListActions } from 'app/store/mission/mission-list/mission-list.actions';
import { MissionListSelectors } from 'app/store/mission/mission-list/mission-list.selectors';
import { ResourceListFilter } from 'app/store/shared/ressource-list/ressource-list-state';
import { isEmpty } from 'lodash-es';
import {
    MissionFiltersComponent,
    MissionFiltersInput,
} from './filters/mission-filters.component';
import { MissionFiltersService } from './filters/mission-filters.service';
import { MissionGridViewComponent } from './grid-view/mission-grid-view.component';
import { MissionRowViewComponent } from './row-view/mission-row-view.component';

@Component({
    selector: 'mission-list',
    standalone: true,
    templateUrl: './mission-list.component.html',
    imports: [
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        AsyncPipe,
        NgIf,
        NgClass,
        LetDirective,
        NgFor,
        TranslocoModule,
        FormsModule,
        MissionRowViewComponent,
        MissionGridViewComponent,
        MissionFiltersComponent,
        MatPaginatorModule,
    ],
})
export class MissionListComponent implements OnInit {
    filtersInput: MissionFiltersInput = {
        view: 'grid',
    };

    missions$ = this.store.select(MissionListSelectors.selectData);
    isLoading$ = this.store.select(MissionListSelectors.selectIsLoading);
    pageIndex$ = this.store.select(MissionListSelectors.selectPageIndex);
    totalPages$ = this.store.select(MissionListSelectors.selectTotalPages);
    totalCount$ = this.store.select(MissionListSelectors.selectTotalCount);
    pageSize$ = this.store.select(MissionListSelectors.selectPageSize);

    search: string;

    constructor(
        private store: Store,
        private missionFiltersService: MissionFiltersService,
        private activityService: ActivityService
    ) {}

    ngOnInit(): void {
        this.store.dispatch(
            MissionListActions.InitializeWithFilters({
                filters: [
                    {
                        key: 'minimalIncludes',
                        value: true,
                    },
                    {
                        key: 'isActive',
                        value: true,
                    },
                    {
                        key: 'hasAnyAvailableSpotLeft',
                        value: true,
                    },
                ],
            })
        );
        this.activityService.sendActivityRequest({
            trackedActivityType: ETrackedActivityType.ViewMissionsPage,
        });
    }

    clearSearch() {
        this.search = '';
        this.missionFiltersService.removeFilter({ type: 'nameContains' });
        this.store.dispatch(
            MissionListActions.FilterListRequest({
                filters: this.missionFiltersService.filters,
            })
        );
    }

    searchMission() {
        if (isEmpty(this.search)) {
            this.clearSearch();
            return;
        }
        this.missionFiltersService.setNameContains(this.search);
        this.store.dispatch(
            MissionListActions.FilterListRequest({
                filters: this.missionFiltersService.filters,
            })
        );
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            MissionListActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
    }

    onFilterClear(event: ResourceListFilter[]) {
        if (event.includes('nameContains')) {
            this.search = '';
        }
    }
}
