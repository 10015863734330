import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkPageContentComponent } from '@bslk/components/dynamic-content/page/content/content.component';
import { BslkRoundIconComponent } from '@bslk/components/round-icon/round-icon.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'chapter-card-anecdote-section',
    templateUrl: './anecdote.component.html',
    standalone: true,
    imports: [
        BslkPageContentComponent,
        MatIconModule,
        BslkRoundIconComponent,
        TranslocoModule,
    ],
})
export class ChapterCardAnecdoteSectionComponent {}
