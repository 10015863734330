import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import {
    CreateProjectDialogComponent,
    CreateProjectDialogInput,
} from './create-project.component';

@Injectable({ providedIn: 'root' })
export class CreateProjectDialogService implements IDialogService {
    private dialogRef: MatDialogRef<CreateProjectDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open(input: CreateProjectDialogInput) {
        this.dialogRef = this.dialog.open(CreateProjectDialogComponent, {
            data: input,
            maxWidth: '100vw',
            maxHeight: '100vh',
            disableClose: true,
        });
    }

    close() {
        this.dialogRef.close();
    }
}
