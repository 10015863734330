<div *ngrxLet="isLoading$ as isLoading" class="p-4 md:p-6 xxl:p-8 w-full">
    <div class="flex justify-between mb-6">
        <div class="flex flex-col gap-1">
            <span class="text-3xl font-bold">{{
                "companyfilters.skills-sponsorship-filters" | transloco
            }}</span>
            <span class="text-lg">{{
                "companyfilters.manage-missions-based-on-filters" | transloco
            }}</span>
        </div>

        <bslk-classic-button
            [isDisabled]="!this.formGroup.dirty"
            [disabledTooltip]="
                'companyfilters.no-modifications-made' | transloco
            "
            (onClick)="save()"
        >
            {{ "companyfilters.save" | transloco }}</bslk-classic-button
        >
    </div>

    <div *ngIf="!isLoading; else loading" class="grid grid-cols-12 gap-4">
        <div class="flex flex-col col-span-12 lg:col-span-6 gap-4">
            <bslk-classic-card
                [title]="'companyfilters.activity-fields' | transloco"
            >
                <bslk-info
                    headerActions
                    [size]="'large'"
                    [tooltip]="
                        'companyfilters.activity-fields-tooltip' | transloco
                    "
                ></bslk-info>

                <bslk-advanced-select-sync
                    class="flex-1"
                    *ngIf="activityFieldsTranslationsLoaded"
                    [items]="activityFieldsAdvancedSelectItems"
                    [multiple]="'multiple'"
                    [group]="formGroup"
                    [icon]="'mat_solid:category'"
                    [controlName]="'activityFields'"
                    [selectPlaceholder]="
                        'companyfilters.select-activity-fields' | transloco
                    "
                    [searchPlaceholder]="
                        'companyfilters.search-activity-field' | transloco
                    "
                ></bslk-advanced-select-sync>
            </bslk-classic-card>

            <bslk-classic-card
                [title]="
                    'companyfilters.sustainable-development-goals' | transloco
                "
            >
                <bslk-info
                    headerActions
                    [size]="'large'"
                    [tooltip]="'companyfilters.sdgs-tooltip' | transloco"
                ></bslk-info>

                <bslk-business-sdg-picker
                    [control]="formGroup.controls.sdgs"
                    [justify]="'start'"
                    [displayValueInField]="true"
                    [displayNonSelectedLabel]="true"
                ></bslk-business-sdg-picker>
            </bslk-classic-card>
        </div>

        <div class="flex col-span-12 lg:col-span-6">
            <blacklisted-associations class="w-full"></blacklisted-associations>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col gap-4">
            <div class="h-64 overflow-hidden rounded-2xl shadow-lg">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>
            <div class="h-64 overflow-hidden rounded-2xl shadow-lg">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>
        </div>
        <div class="h-200 overflow-hidden rounded-2xl shadow-lg">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full h-full"
            ></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
