import { Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { randomNumberFrom1To } from 'app/shared/utils/number.utils';

@Component({
    selector: 'course-loading',
    templateUrl: './loading.component.html',
    standalone: true,
    imports: [TranslocoModule],
})
export class CourseLoadingComponent {
    randomNumber = randomNumberFrom1To(10);
}
