<div class="bg-gray-50 border p-3 flex flex-col">
    <div class="border">
        <img
            *ngIf="rating.mission.coverUrl"
            class="h-20 lg:h-30 xxl:h-40 object-cover"
            [src]="rating.mission.coverUrl"
            alt="Cover image"
        />

        <div
            *ngIf="!rating.mission.coverUrl"
            class="h-20 lg:h-30 xxl:h-40 object-cover flex items-center border-b-2"
        >
            <span class="text-4xl text-gray-400 mx-auto">{{
                "missionview.no-cover" | transloco
            }}</span>
        </div>

        <!-- Mission info -->
        <div
            class="flex flex-col flex-0 lg:flex-row items-center max-w-7xl w-full mx-auto px-8 py-2 mb-1 bg-card"
        >
            <!-- Association Logo -->
            <div class="-mt-22 lg:-mt-18 relative">
                <classic-avatar
                    [size]="24"
                    [borderSize]="4"
                    [avatarUrl]="rating.mission.association.logoUrl"
                    [fallbackLetter]="rating.mission.association.name.at(0)"
                >
                </classic-avatar>
            </div>

            <!-- Details -->
            <div class="flex flex-col items-center lg:items-start lg:ml-8">
                <div
                    [matTooltip]="rating.mission.name"
                    class="text-2xl font-bold truncate max-w-120 p-4 md:p-0"
                >
                    {{ rating.mission.name }}
                </div>
                <div class="font-bold text-primary">
                    {{ rating.mission.association.name }}
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-col gap-y-4 px-3 mt-4">
        <bslk-star-rating
            [value]="rating.experienceRating"
            [isReadonly]="true"
            [label]="'missionmanage.rating.experience-rating-label' | transloco"
            [minLabel]="
                'missionmanage.rating.experience-rating-min-label' | transloco
            "
            [maxLabel]="
                'missionmanage.rating.experience-rating-max-label' | transloco
            "
        ></bslk-star-rating>

        <bslk-star-rating
            [value]="rating.purposeRating"
            [isReadonly]="true"
            [label]="'missionmanage.rating.purpose-rating-label' | transloco"
            [minLabel]="
                'missionmanage.rating.purpose-rating-min-label' | transloco
            "
            [maxLabel]="
                'missionmanage.rating.purpose-rating-max-label' | transloco
            "
        ></bslk-star-rating>

        <div>
            <div class="font-bold mb-1">
                {{
                    "missionmanage.rating.feelings-perceived-label" | transloco
                }}
            </div>
            <span>{{ rating.feelingsFelt }}</span>
        </div>

        <div>
            <div class="font-bold mb-1">
                {{
                    "missionmanage.rating.learning-new-about-self-label"
                        | transloco
                }}
            </div>
            <span>{{ rating.newSelfAndOthersLearning }}</span>
        </div>

        <bslk-radio-group-binary
            [isReadonly]="true"
            [label]="
                'missionmanage.rating.company-initiatives-important-label'
                    | transloco
            "
            [value]="rating.isCompanyInitiativesImportant"
            [isReadonly]="true"
        ></bslk-radio-group-binary>

        <div>
            <div class="font-bold mb-1">
                {{ "missionmanage.rating.why-label" | transloco }}
            </div>
            <span>{{ rating.isCompanyInitiativesImportantComment }}</span>
        </div>

        <bslk-radio-group-binary
            [isReadonly]="true"
            [label]="
                'missionmanage.rating.improving-company-image-label' | transloco
            "
            [value]="rating.isActionImprovingCompanyImage"
        ></bslk-radio-group-binary>

        <bslk-radio-group-binary
            [isReadonly]="true"
            [label]="
                'missionmanage.rating.contributing-to-personal-growth-label'
                    | transloco
            "
            [value]="rating.isActionContributingToPersonalGrowthAtCompany"
        ></bslk-radio-group-binary>

        <div>
            <div class="font-bold mb-1">
                {{
                    "missionmanage.rating.preferred-activity-fields-label"
                        | transloco
                }}
            </div>
            <div class="flex gap-1">
                <bslk-tag
                    *ngFor="let activityField of rating.preferredActivityFields"
                    [size]="'small'"
                >
                    {{
                        "enums.EActivityField." + activityField | transloco
                    }}</bslk-tag
                >
            </div>
        </div>

        <div *ngIf="rating.otherPreferredActivityFields">
            <div class="font-bold mb-1">
                {{
                    "missionmanage.rating.other-causes-interest-label"
                        | transloco
                }}
            </div>
            <span>{{ rating.otherPreferredActivityFields }}</span>
        </div>

        <div>
            <div class="font-bold mb-1">
                {{
                    "missionmanage.rating.desired-days-per-year-label"
                        | transloco
                }}
            </div>
            <span> {{ rating.desiredDaysPerYear }}</span>
        </div>

        <bslk-radio-group-binary
            [isReadonly]="true"
            [label]="'missionmanage.rating.recommend-mission-label' | transloco"
            [value]="rating.isMissionRecommendable"
        ></bslk-radio-group-binary>

        <div>
            <div class="font-bold mb-1">
                {{ "missionmanage.rating.other-comments-label" | transloco }}
            </div>
            <span>{{ rating.otherComments }}</span>
        </div>

        <bslk-star-rating
            [value]="rating.associationRating"
            [isReadonly]="true"
            [label]="
                'missionmanage.rating.association-rating-label' | transloco
            "
            [minLabel]="
                'missionmanage.rating.association-rating-label-min-label'
                    | transloco
            "
            [maxLabel]="
                'missionmanage.rating.association-rating-label-max-label'
                    | transloco
            "
        ></bslk-star-rating>

        <div>
            <div class="font-bold mb-1">
                {{
                    "missionmanage.rating.association-comment-label" | transloco
                }}
            </div>
            <span>{{ rating.associationComment }}</span>
        </div>
    </div>
</div>
