import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkMenuComponent } from '@bslk/components/menu/menu.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { DonationCampaignCustomizationActions } from 'app/store/donation/donation-campaign-customization/donation-campaign-customization.action';
import { DonationCampaignListActions } from 'app/store/donation/donation-campaign-list/donation-campaign-list.actions';
import { DonationCampaignListSelectors } from 'app/store/donation/donation-campaign-list/donation-campaign-list.selectors';
import { DonationCampaignActions } from 'app/store/donation/donation-campaign/donation-campaign.action';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DonationCampaignCardComponent } from './card/card.component';

@Component({
    selector: 'donation-campaigns',
    templateUrl: './donation-campaigns.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkClassicButtonComponent,
        BslkTagButtonComponent,
        BslkMenuComponent,
        NgIf,
        NgFor,
        NgxSkeletonLoaderModule,
        TranslocoModule,
        DonationCampaignCardComponent,
    ],
})
export class DonationCampaignsComponent implements OnInit {
    campaigns$ = this.store.select(DonationCampaignListSelectors.selectData);
    isLoading$ = this.store.select(
        DonationCampaignListSelectors.selectIsLoading
    );
    pageIndex$ = this.store.select(
        DonationCampaignListSelectors.selectPageIndex
    );
    totalPages$ = this.store.select(
        DonationCampaignListSelectors.selectTotalPages
    );
    totalCount$ = this.store.select(
        DonationCampaignListSelectors.selectTotalCount
    );
    pageSize$ = this.store.select(DonationCampaignListSelectors.selectPageSize);

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(DonationCampaignListActions.Initialize());
    }

    openCreateCampaignDialog() {
        this.store.dispatch(
            DonationCampaignActions.openDialog({
                input: { isEdit: false, isFromEntityPage: false },
            })
        );
    }

    openCustomizationDialog() {
        this.store.dispatch(
            DonationCampaignCustomizationActions.openDialog({
                input: { isDefaultCustomization: true },
            })
        );
    }

    onMenuAction(event: string) {
        if (event === 'customize') {
            return this.openCustomizationDialog();
        }
    }
}
