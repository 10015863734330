import { AsyncPipe, JsonPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { FooterComponent } from 'app/shared/components/footer/footer.component';
import { ProjectListComponent } from 'app/shared/components/project/project-list/project-list.component';
import { SanitizePipe } from 'app/shared/pipes/sanitize.pipe';
import { DonationCampaignActions } from 'app/store/donation/donation-campaign/donation-campaign.action';
import { DonationCampaignSelectors } from 'app/store/donation/donation-campaign/donation-campaign.selectors';
import { Subject, takeUntil } from 'rxjs';
import { DonationCertificateComponent } from '../donation-certificate/donation-certificate.component';
import { GiftCardComponent } from '../gift-card/gift-card.component';
import { OpenGiftComponent } from '../open-gift/open-gift.component';

@Component({
    selector: 'app-thank-you',
    templateUrl: './thank-you.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkTagComponent,
        BslkTagButtonComponent,
        ClassicAvatarComponent,
        FooterComponent,
        FuseAlertComponent,
        GiftCardComponent,
        JsonPipe,
        LetDirective,
        MatIconModule,
        NgClass,
        NgIf,
        MatProgressSpinnerModule,
        OpenGiftComponent,
        ProjectListComponent,
        SanitizePipe,
        TranslocoModule,
        DonationCertificateComponent,
    ],
})
export class ThankYouComponent implements OnInit {
    unsubscribeAll: Subject<any> = new Subject<any>();

    campaign$ = this.store.select(
        DonationCampaignSelectors.selectDonationCampaign
    );
    isLoading$ = this.store.select(DonationCampaignSelectors.selectIsLoading);

    isLoadingContactMe$ = this.store.select(
        DonationCampaignSelectors.selectIsLoadingContactMe
    );
    hasChosenProject$ = this.store.select(
        DonationCampaignSelectors.selectHasChosenProject
    );
    hasAskedToBeContacted$ = this.store.select(
        DonationCampaignSelectors.selecHasAskedToBeContacted
    );
    chosenProject$ = this.store.select(
        DonationCampaignSelectors.selectChosenProject
    );

    contribution$ = this.store.select(
        DonationCampaignSelectors.selectChosenProjectContribution
    );

    previewUrl$ = this.store.select(
        DonationCampaignSelectors.selectDonationGiftPreviewUrl
    );

    constructor(private store: Store, private activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.activatedRoute.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                const id = params['id'];
                if (id) {
                    this.store.dispatch(
                        DonationCampaignActions.loadDonationRequestRequest({
                            id,
                        })
                    );
                }
            });
    }

    onContactMe() {
        this.store.dispatch(DonationCampaignActions.contactMeRequest());
    }
}
