import { Pipe, PipeTransform } from '@angular/core';
import { getUserLevelInformation } from '../utils/experience-points.utils';

@Pipe({
    name: 'level',
    standalone: true,
})
export class LevelPipe implements PipeTransform {
    transform(experiencePoints: number): number {
        return getUserLevelInformation(experiencePoints).currentLevel;
    }
}
