<base-dialog
    [title]="'signup.user-type-question' | transloco"
    [showActions]="false"
    (onClose)="close()"
>
    <div class="flex" body>
        <bslk-choice-card
            (click)="onClick('regular')"
            [title]="'signup.working-in-company' | transloco"
            [hideTitleIcon]="true"
            [backgroundColor]="'warn'"
            [backgroundIcon]="'heroicons_outline:briefcase'"
            [description]="'signup.company-registered-description' | transloco"
            class="w-1/2 pr-3"
        >
        </bslk-choice-card>
        <bslk-choice-card
            (click)="onClick('asso')"
            [title]="'signup.in-association' | transloco"
            [hideTitleIcon]="true"
            [backgroundIcon]="'heroicons_outline:heart'"
            [backgroundColor]="'warn'"
            [description]="'signup.association-join-description' | transloco"
            class="w-1/2 pl-3"
        >
        </bslk-choice-card>
    </div>
</base-dialog>
