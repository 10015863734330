import { createSelector } from '@ngrx/store';
import { DualQuizChallengeMatchUpResultViewModel } from 'app/api';
import { AppState } from 'app/store';
import {
    RessourceListSelectors,
    createRessourceListSelectors,
} from 'app/store/shared/ressource-list/ressource-list-selectors.creator';
import { isNil } from 'lodash-es';

const selectState = (state: AppState) => state.dualQuizMatchUpsList;

const selectIsLoadingResults = createSelector(selectState, (state) =>
    isNil(state.results)
);

const selectResults = createSelector(selectState, (state) => state.results);

const selectTotalChallenges = createSelector(selectResults, (results) =>
    !results
        ? 0
        : results.victoryCount + results.drawCount + results.defeatCount
);

const selectVictoryPercentage = createSelector(
    selectResults,
    selectTotalChallenges,
    (results, totalChallenges) => {
        if (totalChallenges === 0) {
            return 33.333;
        }
        return (results.victoryCount / totalChallenges) * 100;
    }
);

const selectDrawPercentage = createSelector(
    selectResults,
    selectTotalChallenges,
    (results, totalChallenges) => {
        if (totalChallenges === 0) {
            return 33.333;
        }
        return (results.drawCount / totalChallenges) * 100;
    }
);

const selectDefeatPercentage = createSelector(
    selectResults,
    selectTotalChallenges,
    (results, totalChallenges) => {
        if (totalChallenges === 0) {
            return 33.333;
        }
        return (results.defeatCount / totalChallenges) * 100;
    }
);

const selectResultsPercentages = createSelector(
    selectResults,
    selectVictoryPercentage,
    selectDrawPercentage,
    selectDefeatPercentage,
    (results, victoryPercentage, drawPercentage, defeatPercentage) => {
        if (!results) {
            return null;
        }
        return {
            victoryPercentage,
            drawPercentage,
            defeatPercentage,
        };
    }
);

interface AdditionalSelectors {
    selectIsLoadingResults: (state: any) => boolean;
    selectResults: (state: any) => DualQuizChallengeMatchUpResultViewModel;
    selectResultsPercentages: (state: any) => any;
}

type DualQuizMatchupSelectors =
    RessourceListSelectors<DualQuizChallengeMatchUpResultViewModel> &
        AdditionalSelectors;

export const DualQuizMatchUpsSelectors: DualQuizMatchupSelectors = {
    ...createRessourceListSelectors('dualQuizMatchUpsList'),
    selectIsLoadingResults,
    selectResults,
    selectResultsPercentages,
};
