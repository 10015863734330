import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { TranslocoModule } from '@ngneat/transloco';
import { FormControlComponent } from '../form-control-component';

@Component({
    selector: 'bslk-radio-group-binary',
    standalone: true,
    templateUrl: './radio-group-binary.component.html',
    imports: [TranslocoModule, ReactiveFormsModule, MatRadioModule, NgIf],
})
export class BslkRadioGroupBinaryComponent extends FormControlComponent {
    change(event: MatRadioChange) {
        this.setValue(event.value);
    }
}
