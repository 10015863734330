import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmailValidationComponent } from '../auth/sign-up/email-validation/email-validation.component';
import { CompanyCsrApproachComponent } from './csr-approach/csr-approach.component';
import { DualQuizChallengeResultsComponent } from './dual-quiz/challenge-results/challenge-results.component';
import { DualQuizChallengeComponent } from './dual-quiz/challenge/challenge.component';
import { DualQuizComponent } from './dual-quiz/dual-quiz.component';
import { EditMyBasileComponent } from './my-basile/edit-my-basile.component';
import { FileManagerListComponent } from './my-files/my-files.component';

export const sharedRoutes = [
    {
        path: 's',
        canActivate: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'validate-email/:userId/:code',
                component: EmailValidationComponent,
            },
        ],
    },
    {
        path: 's',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'settings',
                loadChildren: () =>
                    import(
                        'app/modules/shared/user-settings/user-settings.routes'
                    ),
            },
            {
                path: 'chat',
                loadChildren: () =>
                    import('app/modules/shared/chat/chat.routes'),
            },
            {
                path: 'csr-approach',
                component: CompanyCsrApproachComponent,
            },
            {
                path: 'dual-quiz',
                children: [
                    {
                        path: '',
                        component: DualQuizComponent,
                    },
                    {
                        path: ':id',
                        component: DualQuizChallengeComponent,
                    },
                    {
                        path: ':id/results',
                        component: DualQuizChallengeResultsComponent,
                    },
                ],
            },
            {
                path: 'my-basile',
                component: EditMyBasileComponent,
            },
            {
                path: 'my-files',
                children: [
                    {
                        path: '',
                        component: FileManagerListComponent,
                    },
                    {
                        path: ':folderType',
                        component: FileManagerListComponent,
                    },
                ],
            },
        ],
    },
];
