import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationViewModel } from 'app/api';

export abstract class LocationFormService {
    protected locationFormGroup: FormGroup;

    constructor(protected fb: FormBuilder) {}

    initLocationForm(isRequired?: boolean) {
        this.locationFormGroup = this.createLocationFormGroup(null, isRequired);
        return this.locationFormGroup;
    }

    patchLocation(location: LocationViewModel) {
        if (location) {
            this.locationFormGroup.reset();
            this.locationFormGroup.patchValue(location);
        }
    }

    createLocationFormGroup(
        location?: LocationViewModel,
        isRequired?: boolean
    ) {
        return this.fb.group({
            id: [location?.id ?? 0],
            longitude: [location?.longitude],
            latitude: [location?.latitude],
            radiusKm: [location?.radiusKm],
            country: isRequired
                ? [location?.country, Validators.required]
                : [location?.country],
            city: isRequired
                ? [location?.city, Validators.required]
                : [location?.city],
            zipCode: [location?.zipCode],
            address: isRequired
                ? [location?.address, Validators.required]
                : [location?.address],
        });
    }
}
