import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MatCheckboxChange,
    MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
    BslkAdvancedSelectSyncComponent,
    BslkAdvancedSelectSyncInput,
} from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { BslkCardToggleComponent } from '@bslk/components/form/toggle/card/card-toggle.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import {
    EActivityField,
    ECurrency,
    EDuration,
    EMissionType,
    ESkill,
} from 'app/api';
import { LocationSelectionComponent } from 'app/shared/components/location-selection/location-selection.component';
import { MissionFormService } from 'app/shared/services/form/mission-form.service';
import { TranslationService } from 'app/shared/services/transloco/translation.service';
import {
    addRequiredValidatorToAll,
    removeAllValidators,
} from 'app/shared/utils/form-helpers.utils';
import { AssociationSelectors } from 'app/store/association/association/association.selectors';
import { MissionActions } from 'app/store/mission/mission/mission.action';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Subject, takeUntil } from 'rxjs';
import { BslkSliderComponent } from '../../../../../../@bslk/components/form/slider/slider.component';

@Component({
    selector: 'create-mission-step-0',
    standalone: true,
    templateUrl: './create-mission-step-0.component.html',
    imports: [
        AsyncPipe,
        BslkAdvancedSelectSyncComponent,
        BslkCardToggleComponent,
        BslkClassicToggleComponent,
        BslkSliderComponent,
        FuseAlertComponent,
        LetDirective,
        LocationSelectionComponent,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatStepperModule,
        MatTooltipModule,
        MatTooltipModule,
        NgFor,
        NgIf,
        NgxMaterialTimepickerModule,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class CreateMissionStep0Component implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    EActivityField = EActivityField;
    ESkill = ESkill;
    ECurrency = ECurrency;
    EMissionType = EMissionType;
    EDuration = EDuration;

    skillsTranslationsLoaded: boolean = false;
    skillsAdvancedSelectItems: BslkAdvancedSelectSyncInput[];

    hasSomeLocationSet$ = this.store.select(
        AssociationSelectors.selectHasSomeLocationSet
    );

    constructor(
        private store: Store,
        private translationService: TranslationService,
        private missionFormService: MissionFormService
    ) {}

    get formGroup() {
        return (
            this.missionFormService.getFormGroup ??
            this.missionFormService.initForm()
        );
    }

    get availabilityFormControl() {
        return this.formGroup.controls.availability;
    }

    get showAvailabilityDaysAWeek() {
        return false;
    }

    get availabilityDaysAWeekValue() {
        return this.formGroup.controls.availabilityDaysAWeek.value;
    }

    get isOnSite() {
        return this.formGroup.controls.isOnSite.value;
    }

    get locationFormGroup() {
        return this.formGroup.controls.location as FormGroup;
    }

    ngOnInit(): void {
        this.translationService
            .getTranslatedEnum(ESkill, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.skillsAdvancedSelectItems = translatedItems;
                this.skillsTranslationsLoaded = true;
            });
    }

    copyLocation(event: MatCheckboxChange) {
        this.store.dispatch(
            MissionActions.copyLocation({ checked: event.checked })
        );
    }

    isRemoteChanged(isRemote: boolean) {
        if (!this.isOnSite && isRemote) {
            removeAllValidators(this.locationFormGroup);
        } else {
            this.formGroup.controls.isOnSite.setValue(true);
            addRequiredValidatorToAll(this.locationFormGroup, [
                'country',
                'city',
                'address',
            ]);
        }
    }

    isOnSiteChanged(isOnSite: boolean) {
        if (!isOnSite) {
            this.formGroup.controls.isRemote.setValue(true);
            this.locationFormGroup.reset();
            removeAllValidators(this.locationFormGroup);
        } else {
            addRequiredValidatorToAll(this.locationFormGroup, [
                'country',
                'city',
                'address',
            ]);
        }
    }

    missionTypeChanged() {
        // Reset team building setup if mission type changed
        this.formGroup.controls.isTeamBuilding.setValue(false);
        this.isTeamBuildingChanged(false);
        this.isPaidTeamBuildingChanged(false);
    }

    isTeamBuildingChanged(isTeamBuilding: boolean) {
        if (isTeamBuilding) {
            this.formGroup.controls.maxVolunteersBySlot.setValue(10);
            this.formGroup.controls.isPaidTeamBuilding.setValue(false);
        } else {
            this.formGroup.controls.maxVolunteersBySlot.setValue(1);
            this.formGroup.controls.isPaidTeamBuilding.setValue(null);
            this.formGroup.controls.paidTeamBuildingPricePerPerson.setValue(
                null
            );
            this.formGroup.controls.paidTeamBuildingMinPerson.setValue(null);
            this.formGroup.controls.paidTeamBuildingCurrency.setValue(null);
        }
    }

    isPaidTeamBuildingChanged(isPaidTeamBuilding: boolean) {
        if (!isPaidTeamBuilding) {
            this.formGroup.controls.paidTeamBuildingPricePerPerson.setValue(
                null
            );
            this.formGroup.controls.paidTeamBuildingMinPerson.setValue(null);
            this.formGroup.controls.paidTeamBuildingCurrency.setValue(null);
        } else {
            this.formGroup.controls.paidTeamBuildingCurrency.setValue(
                ECurrency.Euro
            );
        }
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }

    ngOnDestroy() {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
