import { baseEnvironment } from './environment.base';
import { Environment } from './environment.interface';

export const environment: Environment = {
    ...baseEnvironment,
    apiBaseUrl: 'https://dev.api.basilik.io',
    enableGoogleSso: true,
    offlineMode: false,
    production: false,
    redirectIfUnauthorized: true,
    redirectUri: 'https://dev.app.basilik.io',
};
