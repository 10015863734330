import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    AsyncPipe,
    DatePipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnDestroy,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkStarRatingComponent } from '@bslk/components/form/rating/star-rating.component';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EFeedbackType } from 'app/api';
import { ImageUploadDialogInput } from 'app/shared/components/file-upload/file-upload-dialog.component';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { FeedbackActions } from 'app/store/feedback/feedback.action';
import { FeedbackSelectors } from 'app/store/feedback/feedback.selectors';
import { isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { FeedbackFormService } from './feedback-form.service';

@Component({
    selector: 'feedback',
    templateUrl: './feedback.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        BslkClassicButtonComponent,
        BslkStarRatingComponent,
        DatePipe,
        FuseAlertComponent,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        NgClass,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        ReactiveFormsModule,
        LetDirective,
        RouterLink,
        TranslocoModule,
    ],
})
export class FeedbackComponent implements OnDestroy {
    @ViewChild('feedbackOrigin') private _feedbackOrigin: ElementRef;
    @ViewChild('feedbackPanel') private feedbackPanel: TemplateRef<any>;

    EFeedbackType = EFeedbackType;

    private overlayRef: OverlayRef;
    private unsubscribeAll: Subject<any> = new Subject<any>();

    isLoading$ = this.store.select(FeedbackSelectors.selectIsLoading);

    isFeedbackSent$ = this.store.select(FeedbackSelectors.selectIsFeedbackSent);

    feedbackType?: EFeedbackType = null;
    submitted = false;

    constructor(
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private feedbackFormService: FeedbackFormService,
        private store: Store,
        private fileUploadDialogService: FileUploadDialogService
    ) {}

    get showPickFeedbackType() {
        return isNil(this.feedbackType);
    }

    get formGroup() {
        return this.feedbackFormService.getFormGroup;
    }

    get isScreenshotAdded() {
        return !isNil(this.formGroup.controls.fileAsBase64.value);
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();

        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }

    openPanel(): void {
        this.store.dispatch(FeedbackActions.resetFeedbackState());
        if (!this.feedbackPanel || !this._feedbackOrigin) {
            return;
        }
        if (!this.overlayRef) {
            this._createOverlay();
        }

        this.overlayRef.attach(
            new TemplatePortal(this.feedbackPanel, this.viewContainerRef)
        );
    }

    openFileUploadDialog() {
        this.fileUploadDialogService.open({
            formControl: this.formGroup.controls.fileAsBase64,
            maxFileSize: 2000 * 1024,
        } as ImageUploadDialogInput);
    }

    closePanel(): void {
        this.feedbackType = null;
        this.overlayRef.detach();
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    private _createOverlay(): void {
        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(this._feedbackOrigin.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        this.overlayRef.backdropClick().subscribe(() => {
            this.closePanel();
        });
    }

    chooseFeedbackType(feedbackType: EFeedbackType) {
        this.feedbackType = feedbackType;
        this.formGroup.controls.type.setValue(feedbackType);
    }

    send() {
        this.submitted = true;
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            this.store.dispatch(FeedbackActions.sendFeedbackRequest());
        }
    }
}
