import { createReducer, on } from '@ngrx/store';
import { NavigationActions } from './navigation.actions';
import { NavigationState } from './navigation.state';

export const initialState: NavigationState = {
    userRole: null,
    allowedFeatures: []
};

export const navigationReducer = createReducer(
  initialState,
  on(NavigationActions.initNavigation, (state, { userRole, allowedFeatures }) => {
    return {
      ...state,
      userRole,
      removeNavigation: false,
      allowedFeatures: allowedFeatures || []
    }
  })
);