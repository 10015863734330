import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkSheetDataEditorComponent } from '@bslk/components/form/sheet-data-editor/sheet-data-editor.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { CompanyEmployeeViewModel } from 'app/api';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { CompanyEmployeeActions } from 'app/store/company/company-employee/company-employee.actions';
import { CompanyEmployeeSelectors } from 'app/store/company/company-employee/company-employee.selectors';
import { ImportSheetActions } from 'app/store/sheet/import/import.action';
import { ImportSheetSelectors } from 'app/store/sheet/import/import.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'import-employees-dialog',
    templateUrl: './import-employees-dialog.component.html',
    standalone: true,
    imports: [
        BslkSheetDataEditorComponent,
        TranslocoModule,
        BslkClassicButtonComponent,
        BaseDialogComponent,
        AsyncPipe,
        LetDirective,
    ],
})
export class ImportEmployeesDialogComponent implements OnInit {
    unsubscribeAll: Subject<any> = new Subject<any>();
    employees: CompanyEmployeeViewModel[] = [];

    isLoading$ = this.store.select(CompanyEmployeeSelectors.selectIsLoading);

    importedEmails$ = this.store
        .select(ImportSheetSelectors.selectCompanyEmployees)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((employees) => {
            if (employees) {
                this.employees = employees;
                this.store.dispatch(ImportSheetActions.resetCompanyEmployees());
            }
        });

    constructor(
        private dialogRef: MatDialogRef<ImportEmployeesDialogComponent>,
        private store: Store,
        private fileUploadDialogService: FileUploadDialogService
    ) {}

    get hasAnyEmployeeImported() {
        return this.employees.length > 0;
    }

    ngOnInit(): void {}

    onDataChanged(employees: CompanyEmployeeViewModel[]) {
        this.employees = employees;
    }

    onImportClicked() {
        this.fileUploadDialogService.open({
            action: ImportSheetActions.importCompanyEmployeesRequest,
            isLoading$: this.store.select(ImportSheetSelectors.selectIsLoading),
            error$: this.store.select(ImportSheetSelectors.selectError),
            maxFileSize: 2000 * 1024,
            infoTextKey: 'setup.import-info',
            extraAllowedFormats: ['csv', 'xlsx'],
            disableImages: true,
        });
    }

    submit() {
        if (this.hasAnyEmployeeImported) {
            this.store.dispatch(
                CompanyEmployeeActions.UpdateAllRequest({
                    employees: this.employees,
                })
            );
        }
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
