/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Since notification translation is handled in the front-end, we  don\'t store the message itself but only variables which will be contained  in the template notification message
 */
export interface NotificationVariable { 
    id?: number;
    key?: string | null;
    value?: string | null;
}

