import {
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Output,
} from '@angular/core';

@Directive({
    standalone: true,
    selector: '[bslkFileDnd]',
})
export class BslkFileDndDirective {
    @HostBinding('class.bg-primary-100') fileOver: boolean;
    @Output() fileDropped = new EventEmitter<any>();

    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        if (evt.currentTarget.contains(evt.relatedTarget)) {
            return;
        }
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    }
    @HostListener('dragover', ['$event']) onDragOver(evt) {
        if (evt.currentTarget.contains(evt.relatedTarget)) {
            return;
        }
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    }

    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
