<div class="bg-white rounded-xl shadow-lg w-full h-full flex flex-col">
    <div
        class="flex justify-between items-center pt-3 xl:pt-4 xxl:pt-6 px-3 xl:px-5 xxl:px-6"
    >
        <div class="text-bold text-xl truncate font-bold mb-2 mr-2 min-h-[1em]">
            {{ title }}
        </div>
        <div class="flex gap-x-4">
            <ng-content select="[headerActions]"></ng-content>
            <mat-icon
                *ngIf="isExpandable"
                (click)="toggleExpand()"
                [svgIcon]="
                    'heroicons_outline:' +
                    (isExpanded
                        ? 'magnifying-glass-minus'
                        : 'magnifying-glass-plus')
                "
                class="cursor-pointer hover:text-warn duration-200 hover:scale-110"
            ></mat-icon>
        </div>
    </div>
    <ng-container *ngIf="!isLoading; else loading">
        <div
            class="h-full w-full px-3 xl:px-4 xxl:px-6 pb-3 xl:pb-4 xxl:pb-6"
            *ngIf="!isExpanded"
        >
            <ng-content></ng-content>
        </div>
        <div class="h-full w-full" *ngIf="isExpanded">
            <ng-content select="[expandedContent]"></ng-content>
        </div>
    </ng-container>
</div>

<ng-template #loading>
    <ngx-skeleton-loader
        [ngClass]="isExpanded ? 'h-80' : 'h-full'"
        [theme]="{ height: '100%' }"
        class="w-full px-3 xl:px-4 xxl:px-6 pb-3 xl:pb-4 xxl:pb-6"
    ></ngx-skeleton-loader>
</ng-template>
