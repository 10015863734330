import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { BadgeViewModel, EBadge } from 'app/api';

@Component({
    standalone: true,
    selector: 'bslk-business-badge',
    templateUrl: './badge.component.html',
    imports: [TranslocoModule, MatIconModule],
})
export class BslkBadgeComponent {
    @Input() badge: BadgeViewModel;
    @Input() isDisabled = false;

    icons = {
        [EBadge.FirstGoodAnswer]: 'heroicons_outline:question-mark-circle',
        [EBadge.ThreeGoodAnswersInARow]: 'heroicons_outline:bolt',
        [EBadge.FirstChapterCompleted]: 'heroicons_outline:play',
        [EBadge.FirstCourseCompleted]: 'heroicons_outline:book-open',
        [EBadge.SurveyFoundAllAnswers]: 'heroicons_outline:chart-bar',
    };

    constructor() {}
}
