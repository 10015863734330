import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EUserRole } from 'app/api';
import { AuthTemplateComponent } from 'app/shared/components/templates/auth-template/auth-template.component';
import { AssociationSelectors } from 'app/store/association/association/association.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { SignupAuthComponent } from '../auth/sign-up-auth.component';

@Component({
    selector: 'regular-sign-up',
    templateUrl: './regular-sign-up.component.html',
    animations: fuseAnimations,
    standalone: true,
    imports: [
        AuthTemplateComponent,
        BslkClassicButtonComponent,
        FuseAlertComponent,
        LetDirective,
        MatButtonModule,
        NgIf,
        RouterLink,
        SignupAuthComponent,
        TranslocoModule,
    ],
})
export class RegularSignUpComponent {
    EUserRole = EUserRole;

    isLoading$ = this.store.select(AssociationSelectors.selectIsLoading);
    isSignedIn$ = this.store.select(UserSelectors.selectIsSignedIn);
    needsToValidateEmail$ = this.store.select(
        UserSelectors.selectNeedsToValidateEmail
    );

    constructor(private store: Store) {}
}
