import { createSelector } from '@ngrx/store';
import { AppState } from '..';

const selectState = (state: AppState) => state.myBasilik;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectAssets = createSelector(selectState, (state) => state.assets);

const selectBasileConfiguration = createSelector(
    selectState,
    (state) => state.configuration
);

const selectPurposeCoinsBalance = createSelector(
    selectBasileConfiguration,
    (configuration) => configuration.purposeCoins
);

export const MyBasileSelectors = {
    selectIsLoading,
    selectAssets,
    selectPurposeCoinsBalance,
    selectBasileConfiguration,
};
