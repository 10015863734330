import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { SafePipe } from 'app/shared/pipes/safe.pipe';

@Component({
    selector: 'chapter-card-video-section',
    templateUrl: './video.component.html',
    standalone: true,
    imports: [TranslocoModule, SafePipe],
})
export class ChapterCardVideoSectionComponent {
    @Input() videoUrl: string;
}
