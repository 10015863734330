<div class="flex flex-col md:flex-row gap-x-3 full-calendar">
    <div class="w-full md:w-2/3">
        <full-calendar
            [options]="calendarOptions"
            [events]="calendarEvents | async"
        >
        </full-calendar>
    </div>
    <div class="w-full md:w-1/3">
        <div class="p-3 flex w-full h-full rounded-xl bg-gray-50">
            <fuse-alert
                class="flex m-auto"
                *ngIf="!builtSlotFormGroup && (isDrawing || !hasStartedDrawing)"
                [type]="'info'"
                [appearance]="'outline'"
            >
                <span *ngIf="!hasStartedDrawing && !isDrawing">
                    {{ "createmission.click-to-define-start" | transloco }}
                    <span class="font-bold">{{
                        "createmission.the-beginning" | transloco
                    }}</span>
                    {{ "createmission.of-your-slot" | transloco }}
                </span>
                <span *ngIf="isDrawing">
                    {{ "createmission.now-click-to-define-end" | transloco }}
                    <span class="font-bold">{{
                        "createmission.the-end" | transloco
                    }}</span>
                    {{ "createmission.of-your-slot" | transloco }}
                </span>
            </fuse-alert>
            <div
                *ngIf="builtSlotFormGroup"
                class="flex w-full flex-col gap-y-3 justify-between h-full"
            >
                <!-- Start and end date -->
                <div
                    class="w-full grow items-center flex gap-3 justify-between"
                >
                    <mat-form-field
                        [subscriptSizing]="'dynamic'"
                        floatLabel="always"
                        (click)="pickerStart.open()"
                        class="w-full"
                    >
                        <mat-label>{{
                            "createmission.start-date" | transloco
                        }}</mat-label>
                        <input
                            class="cursor-pointer"
                            matInput
                            readonly
                            [matDatepicker]="pickerStart"
                            [matDatepickerFilter]="getWeekendsDatesFilter"
                            [formControl]="builtSlotFormGroup.controls.startDay"
                            [placeholder]="
                                'createmission.start-date-placeholder'
                                    | transloco
                            "
                        />
                        <mat-datepicker-toggle
                            matPrefix
                            [for]="pickerStart"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #pickerStart></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field
                        [subscriptSizing]="'dynamic'"
                        floatLabel="always"
                        (click)="pickerEnd.open()"
                        class="w-full"
                    >
                        <mat-label>{{
                            "createmission.end-date" | transloco
                        }}</mat-label>
                        <input
                            class="cursor-pointer"
                            [placeholder]="
                                'createmission.end-date-placeholder' | transloco
                            "
                            matInput
                            readonly
                            [matDatepicker]="pickerEnd"
                            [matDatepickerFilter]="getWeekendsDatesFilter"
                            [formControl]="builtSlotFormGroup.controls.endDay"
                        />
                        <mat-datepicker-toggle
                            matPrefix
                            [for]="pickerEnd"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd></mat-datepicker>
                    </mat-form-field>
                </div>

                <span
                    *ngIf="builtSlotFormGroup.invalid"
                    class="font-semibold text-primary"
                >
                    {{ "createmission.date-mismatch-error" | transloco }}
                </span>

                <!-- Time picker -->
                <div
                    *ngIf="displayTimePicker"
                    class="w-full flex justify-between items-center"
                >
                    <mat-form-field
                        [subscriptSizing]="'dynamic'"
                        class="w-1/2 pr-3"
                    >
                        <mat-label>{{
                            "createmission.start-time" | transloco
                        }}</mat-label>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_outline:clock'"
                            matPrefix
                        ></mat-icon>
                        <input
                            [formControl]="
                                builtSlotFormGroup.controls.startTime
                            "
                            type="time"
                            matInput
                        />
                        <mat-error>
                            {{ "createmission.start-time-error" | transloco }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field
                        [subscriptSizing]="'dynamic'"
                        class="w-1/2 pl-3"
                    >
                        <mat-label>{{
                            "createmission.end-time" | transloco
                        }}</mat-label>
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_outline:clock'"
                            matPrefix
                        ></mat-icon>
                        <input
                            [formControl]="builtSlotFormGroup.controls.endTime"
                            type="time"
                            matInput
                        />
                        <mat-error>
                            {{ "createmission.end-time-error" | transloco }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex justify-end">
                    <bslk-classic-button
                        [label]="'Supprimer le créneau'"
                        (onClick)="removeSlot()"
                    ></bslk-classic-button>
                </div>
            </div>
        </div>
    </div>
</div>
