import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DonationCampaignService } from 'app/api';
import {
    createCreateItemEffect,
    createDeleteItemEffect,
    createLoadItemRequestEffect,
    createLoadListRequestEffect,
    createReloadEffect,
    createUpdateSelectedItemEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';

import { CreateDonationCampaignDialogService } from 'app/modules/company-admin/donation-campaigns/create/create.service';
import { DonationCampaignFormService } from 'app/shared/services/form/donation-campaign-form.service';
import { map, tap } from 'rxjs';
import { DonationCampaignActions } from '../donation-campaign/donation-campaign.action';
import { DonationCampaignListActions } from './donation-campaign-list.actions';
import { DonationCampaignListSelectors } from './donation-campaign-list.selectors';
@Injectable()
export class DonationCampaignListEffects {
    loadCampaigns$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.donationCampaignService,
        DonationCampaignListActions,
        DonationCampaignListSelectors,
        this.donationCampaignService.donationCampaignGet
    );

    loadCampaign$ = createLoadItemRequestEffect(
        this.store,
        this.actions$,
        this.donationCampaignService,
        DonationCampaignListActions,
        DonationCampaignListSelectors,
        this.donationCampaignService.donationCampaignIdGet
    );

    reload$ = createReloadEffect(this.actions$, DonationCampaignListActions);

    deleteCampaign$ = createDeleteItemEffect(
        this.donationCampaignService,
        this.actions$,
        DonationCampaignListActions,
        'donation-campaign-delete',
        this.donationCampaignService.donationCampaignDelete
    );

    createCampaign$ = createCreateItemEffect(
        this.donationCampaignService,
        this.actions$,
        DonationCampaignListActions,
        this.donationCampaignFormService,
        'donation-campaign-create',
        this.donationCampaignService.donationCampaignPost
    );

    updateCampaign$ = createUpdateSelectedItemEffect(
        this.donationCampaignService,
        this.actions$,
        DonationCampaignListActions,
        this.donationCampaignFormService,
        'donation-campaign-update',
        this.donationCampaignService.donationCampaignPut
    );

    closeDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    DonationCampaignListActions.CreateItemSuccess,
                    DonationCampaignListActions.UpdateSelectedItemSuccess,
                    DonationCampaignActions.updateDonationCampaignRequestSuccess
                ),
                tap(() => {
                    this.createDonationCampaignDialogService.close();
                })
            ),
        { dispatch: false }
    );

    openUpdateCampaignDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DonationCampaignListActions.SelectItem),
            concatLatestFrom(() =>
                this.store.select(
                    DonationCampaignListSelectors.selectSelectedItem
                )
            ),
            tap(([, project]) => {
                this.donationCampaignFormService.updateForm(project);
            }),
            map(() =>
                DonationCampaignActions.openDialog({
                    input: { isEdit: true, isFromEntityPage: false },
                })
            )
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private donationCampaignService: DonationCampaignService,
        private donationCampaignFormService: DonationCampaignFormService,
        private createDonationCampaignDialogService: CreateDonationCampaignDialogService
    ) {}
}
