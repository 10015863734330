import { createActionGroup, props } from '@ngrx/store';
import { ReportConfigViewModel } from 'app/api';

export const ReportActions = createActionGroup({
    source: 'Report',
    events: {
        'Download Report Request': props<{ config: ReportConfigViewModel }>(),
        'Download Report Request Success': props<{
            blob: Blob;
            filename: string;
        }>(),
        'Download Report Request Failure': props<{ error: string }>(),
    },
});
