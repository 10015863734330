import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyFiltersViewModel } from 'app/api';
import { IFormService } from './form-service';

@Injectable({
    providedIn: 'root',
})
export class CompanyFiltersFormService
    implements IFormService<CompanyFiltersViewModel>
{
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder) {
        this.initForm();
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            activityFields: [],
            sdgs: [],
            blacklistedAssociations: [],
        });
        return this.formGroup;
    }

    updateForm(entity: CompanyFiltersViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
    }

    getEntity(): { [key: string]: CompanyFiltersViewModel } {
        return {
            companyFiltersViewModel: this.formGroup
                .value as CompanyFiltersViewModel,
        };
    }

    get getFormGroup() {
        return this.formGroup;
    }
}
