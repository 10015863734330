export type UserLevelInformation = {
    currentLevel: number;
    pointsToNextLevel: number;
    pointsInCurrentLevel: number;
    percentageProgressInCurrentLevel: number;
};

export function getUserLevelInformation(
    experiencePoints: number
): UserLevelInformation {
    function xpRequired(level: number): number {
        if (level === 0) {
            return 0;
        }
        return 1471 * Math.pow(1.1, level - 1);
    }

    let currentLevel = 1;
    let cumulativeXp = 0;

    while (cumulativeXp <= experiencePoints) {
        currentLevel++;
        cumulativeXp += xpRequired(currentLevel);
    }

    currentLevel--;
    cumulativeXp -= xpRequired(currentLevel + 1);

    const previousLevelXp = cumulativeXp;
    const pointsInCurrentLevel = experiencePoints - previousLevelXp;
    const pointsToNextLevel =
        cumulativeXp + xpRequired(currentLevel + 1) - experiencePoints;

    const percentageProgressInCurrentLevel =
        (pointsInCurrentLevel / xpRequired(currentLevel + 1)) * 100;

    return {
        currentLevel: Math.max(1, currentLevel),
        pointsToNextLevel: Math.round(pointsToNextLevel),
        pointsInCurrentLevel: Math.round(pointsInCurrentLevel),
        percentageProgressInCurrentLevel: Math.round(
            percentageProgressInCurrentLevel
        ),
    };
}
