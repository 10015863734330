/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EMissionRecurrenceType = 'None' | 'Daily' | 'Weekly' | 'BiMonthly' | 'Monthly' | 'Quaterly' | 'BiAnnual' | 'Custom';

export const EMissionRecurrenceType = {
    None: 'None' as EMissionRecurrenceType,
    Daily: 'Daily' as EMissionRecurrenceType,
    Weekly: 'Weekly' as EMissionRecurrenceType,
    BiMonthly: 'BiMonthly' as EMissionRecurrenceType,
    Monthly: 'Monthly' as EMissionRecurrenceType,
    Quaterly: 'Quaterly' as EMissionRecurrenceType,
    BiAnnual: 'BiAnnual' as EMissionRecurrenceType,
    Custom: 'Custom' as EMissionRecurrenceType
};

