import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    AssociationLegalDocumentService,
    EFolderType,
    ProjectService,
} from 'app/api';
import { CreateProjectDialogService } from 'app/modules/association/create-project/create-project.service';
import { ProjectFormService } from 'app/shared/services/form/project-form.service';
import { ESharedUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { catchError, exhaustMap, filter, map, mergeMap, of, tap } from 'rxjs';
import { AlertActions } from '../../alert/alert.actions';
import { ProjectActions } from './project.action';
import { ProjectSelectors } from './project.selectors';

@Injectable()
export class ProjectEffects {
    openDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.openDialog),
                tap(({ input }) => {
                    this.createProjectDialogService.open(input);
                })
            ),
        { dispatch: false }
    );

    closeDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectActions.closeDialog),
                tap(() => {
                    this.createProjectDialogService.close();
                })
            ),
        { dispatch: false }
    );

    createProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.createProjectRequest),
            exhaustMap(() => {
                const project = this.projectFormService.getEntity();
                return this.projectService.projectPost(project).pipe(
                    map((id) =>
                        ProjectActions.createProjectRequestSuccess({ id })
                    ),
                    catchError((httpResponse) =>
                        of(
                            ProjectActions.createProjectRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    openUpdateProjectDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.editProject),
            concatLatestFrom(() => [
                this.store.select(ProjectSelectors.selectProject),
            ]),
            tap(([, project]) => {
                this.projectFormService.updateForm(project);
            }),
            map(() =>
                ProjectActions.openDialog({
                    input: { isEdit: true, isFromEntityPage: true },
                })
            )
        )
    );

    updateProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.updateProjectRequest),
            exhaustMap(() => {
                const projectViewModel = this.projectFormService.getEntity();
                return this.projectService.projectPut(projectViewModel).pipe(
                    map(() => ProjectActions.updateProjectRequestSuccess()),
                    catchError((httpResponse) =>
                        of(
                            ProjectActions.updateProjectRequestFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    reloadOnUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.updateProjectRequestSuccess),
            concatLatestFrom(() => [
                this.store.select(ProjectSelectors.selectProject),
            ]),
            tap(() => {
                this.createProjectDialogService.close();
            }),
            map(([, project]) =>
                ProjectActions.loadProjectRequest({ id: project.id })
            )
        )
    );

    createProjectSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.createProjectRequestSuccess),
            tap(() => {
                this.createProjectDialogService.close();
            }),
            map(() => {
                return AlertActions.displaySuccess({ key: 'project-create' });
            })
        )
    );

    createProjectFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.createProjectRequestFail),
            map(() => {
                return AlertActions.displayGenericError();
            })
        )
    );

    loadProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.loadProjectRequest),
            exhaustMap(({ id }) => {
                return this.projectService.projectIdGet({ id }).pipe(
                    map((response) =>
                        ProjectActions.loadProjectRequestSuccess({
                            project: response,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            ProjectActions.loadProjectRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    canCreateProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.loadCanCreateProjectRequest),
            exhaustMap(() => {
                return this.associationLegalDocumentsService
                    .associationLegalDocumentsCanCreateGet()
                    .pipe(
                        map((response) =>
                            ProjectActions.loadCanCreateProjectRequestSuccess({
                                response,
                            })
                        ),
                        catchError((httpResponse) =>
                            of(
                                ProjectActions.loadCanCreateProjectRequestFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    );
            })
        )
    );

    openCreateProjectDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.loadCanCreateProjectRequestSuccess),
            filter(({ response }) => response.canCreateProject),
            map(() => {
                return ProjectActions.openDialog({
                    input: { isEdit: false, isFromEntityPage: false },
                });
            })
        )
    );

    redirectToMissingLegalDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.loadCanCreateProjectRequestSuccess),
            filter(({ response }) => !response.canCreateProject),
            mergeMap(() => {
                this.router.navigateByUrl(
                    UrlHelpers.getSharedUrl(ESharedUrl.SharedMyFiles, {
                        id: EFolderType.AssociationLegalDocuments,
                    })
                );
                const actions = [
                    AlertActions.displayInfo({
                        key: 'missing-legal-documents',
                    }),
                ];
                return actions;
            })
        )
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private projectService: ProjectService,
        private projectFormService: ProjectFormService,
        private associationLegalDocumentsService: AssociationLegalDocumentService,
        private createProjectDialogService: CreateProjectDialogService,
        private router: Router
    ) {}
}
