<div
    *ngIf="tags && tags.length > 0 && tagTranslationsLoaded"
    class="flex items-center"
>
    <bslk-tag
        [background]="background"
        class="mr-2"
        *ngFor="let tag of visibleTagsTranslations"
    >
        {{ tag }}
    </bslk-tag>
    <div
        class="flex items-center ml-2"
        [matTooltip]="tooltipText"
        *ngIf="tags.length > maxTagsVisible"
    >
        +{{ tags.length - maxTagsVisible }}&nbsp;
        <span *ngIf="tags.length - maxTagsVisible > 1">{{
            "bslk.shared.others" | transloco
        }}</span>
        <span *ngIf="tags.length - maxTagsVisible === 1">{{
            "bslk.shared.other" | transloco
        }}</span>
    </div>
</div>
