import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkInfoComponent } from '@bslk/components/info/info.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { CompanyEmployeeActions } from 'app/store/company/company-employee/company-employee.actions';
import { CompanyEmployeeSelectors } from 'app/store/company/company-employee/company-employee.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ImportEmployeesDialogService } from './import-employees-dialog/import-employees-dialog.service';

@Component({
    selector: 'company-employees',
    templateUrl: './company-employees.component.html',
    standalone: true,
    imports: [
        MatPaginatorModule,
        TranslocoModule,
        LetDirective,
        NgClass,
        FormsModule,
        ReactiveFormsModule,
        AsyncPipe,
        BslkTagButtonComponent,
        BslkClassicCardComponent,
        BslkInfoComponent,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        NgxSkeletonLoaderModule,
        NgIf,
        NgFor,
    ],
})
export class CompanyEmployeesComponent implements OnInit {
    search: string;

    employees$ = this.store.select(CompanyEmployeeSelectors.selectData);
    isLoading$ = this.store.select(CompanyEmployeeSelectors.selectIsLoading);
    pageIndex$ = this.store.select(CompanyEmployeeSelectors.selectPageIndex);
    totalPages$ = this.store.select(CompanyEmployeeSelectors.selectTotalPages);
    totalCount$ = this.store.select(CompanyEmployeeSelectors.selectTotalCount);
    pageSize$ = this.store.select(CompanyEmployeeSelectors.selectPageSize);
    properties$ = this.store.select(CompanyEmployeeSelectors.selectProperties);

    constructor(
        private store: Store,
        private importEmployeesDialogService: ImportEmployeesDialogService
    ) {}

    ngOnInit() {
        this.store.dispatch(CompanyEmployeeActions.LoadListRequest());
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            CompanyEmployeeActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
    }

    onSearch() {
        this.store.dispatch(
            CompanyEmployeeActions.FilterListRequest({
                filters: [{ key: 'nameContains', value: this.search }],
            })
        );
    }

    openImportDialog() {
        this.importEmployeesDialogService.open();
    }

    clearSearch() {
        this.search = '';
        this.onSearch();
    }
}
