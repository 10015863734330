<fuse-card
    (click)="viewProject(project.id)"
    [ngClass]="{
        ' border-primary': isSelected,
        ' border-transparent': !isSelected
    }"
    class="flex flex-col w-full h-full group hover:cursor-pointer relative border-4 transition-all duration-400"
>
    <div class="h-64 overflow-hidden">
        <div *ngIf="canSelect" class="z-10 absolute top-0 right-0 p-2">
            <bslk-icon-button
                (click)="selectProject($event)"
                *ngIf="!isSelected"
                [color]="'warn'"
                [tooltip]="'projects.select' | transloco"
                [icon]="'heroicons_outline:plus'"
            ></bslk-icon-button>
            <bslk-icon-button
                (click)="unselectProject($event)"
                *ngIf="isSelected"
                [color]="'warn'"
                [tooltip]="'projects.unselect' | transloco"
                [icon]="'heroicons_outline:minus'"
            ></bslk-icon-button>
        </div>

        <div *ngIf="canChoose" class="z-10 absolute top-3 right-3 flex gap-2">
            <bslk-tag-button
                (click)="chooseProject($event)"
                [icon]="'heroicons_outline:heart'"
                ><span class="font-bold">{{
                    "projects.choose" | transloco
                }}</span></bslk-tag-button
            >

            <bslk-tag-button [icon]="'feather:search'"
                ><span class="font-bold">{{
                    "projects.discover" | transloco
                }}</span></bslk-tag-button
            >
        </div>

        <div
            class="h-full w-full relative translate group-hover:scale-105 transition-all duration-300"
        >
            <!-- Color mask effect on hover -->
            <div
                class="absolute bg-warn inset-0 opacity-0 group-hover:opacity-10 transition-opacity duration-300"
            ></div>
            <img
                *ngIf="project.images.length > 0"
                class="w-full h-full object-cover"
                [src]="project.images[0].url"
                alt="Cover image"
            />
            <div
                *ngIf="project.images.length === 0"
                class="w-full h-full flex border-b"
            >
                <mat-icon
                    class="icon-size-16 m-auto"
                    [svgIcon]="'heroicons_solid:photo'"
                >
                </mat-icon>
            </div>
        </div>
    </div>

    <div class="flex flex-col w-full px-4 py-1">
        <div class="flex flex-col pt-2">
            <span
                class="font-bold text-lg group-hover:text-warn duration-300 transition-all"
                >{{ project.name }}
            </span>
            <div class="truncate">
                <span>{{ "projects.by" | transloco }}&nbsp;</span>
                <span class="font-bold text-primary">{{
                    project.association.name
                }}</span>
            </div>
        </div>
        <div class="py-4 flex flex-col">
            <div
                class="my-auto overflow-y-scroll flex flex-wrap gap-x-2 gap-y-1"
            >
                <bslk-tag
                    [background]="'warn'"
                    [size]="'small'"
                    *ngFor="let activityField of project.activityFields"
                    >{{
                        "enums.EActivityField." + activityField | transloco
                    }}</bslk-tag
                >
            </div>
        </div>
    </div>
</fuse-card>

<project-drawer-details
    (close)="viewedProjectId = null"
    (projectChosen)="onProjectChosen()"
    [projectId]="viewedProjectId"
></project-drawer-details>
