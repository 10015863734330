import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getUserSessionFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { UserActions } from 'app/store/user/user/user.actions';
import { isNil } from 'lodash-es';
export const AssoSignupGuard: CanActivateFn | CanActivateChildFn = (
    _,
    state
) => {
    const router: Router = inject(Router);
    const store: Store = inject(Store);

    const userSession = getUserSessionFromLocalStorage();

    // only allow user on sign up page is not logged in OR logged in but no association created yet
    if (userSession && new Date(userSession.expirationDate) > new Date()) {
        store.dispatch(UserActions.alreadySignedIn({ session: userSession }));
        if (isNil(userSession.user.association)) {
            return true;
        }
        return router.parseUrl(`sign-in`);
    }
    return true;
};
