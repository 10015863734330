import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    AwarenessCampaignThemathicCompletionViewModel,
    AwarenessCampaignThematicDetailsViewModel,
    AwarenessCampaignViewModel,
    EAwarenessThematic,
} from 'app/api';

export const AwarenessCampaignActions = createActionGroup({
    source: 'AwarenessCampaign',
    events: {
        'Load Current Campaign Progress Request': emptyProps(),
        'Load Current Campaign Progress Request Success': props<{
            thematicsProgress: AwarenessCampaignThemathicCompletionViewModel[];
        }>(),
        'Load Current Campaign Progress Request Fail': props<{
            error: string;
        }>(),

        'Load Active Campaign Request': emptyProps(),
        'Load Active Campaign Request Success': props<{
            awarenessCampaign: AwarenessCampaignViewModel;
        }>(),
        'Load Active Campaign Request Fail': props<{
            error: string;
        }>(),

        'Load Thematic Details Request': props<{
            thematic: EAwarenessThematic;
        }>(),
        'Load Thematic Details Request Success': props<{
            thematicDetails: AwarenessCampaignThematicDetailsViewModel;
        }>(),
        'Load Thematic Details Request Fail': props<{
            error: string;
        }>(),

        'Create Campaign Request': emptyProps(),
        'Create Campaign Request Success': emptyProps(),
        'Create Campaign Request Fail': props<{
            error: string;
        }>(),

        'Update Campaign Request': emptyProps(),
        'Update Campaign Request Success': emptyProps(),
        'Update Campaign Request Fail': props<{
            error: string;
        }>(),

        'Claim Reward Request': props<{ chapterId: string }>(),
        'Claim Reward Request Success': emptyProps(),
        'Claim Reward Request Fail': props<{ error: string }>(),
    },
});
