import { AsyncPipe, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';

import { Store } from '@ngrx/store';
import { EFileExtension, EFileType, FileViewModel } from 'app/api';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { SafePipe } from 'app/shared/pipes/safe.pipe';
import { FilesActions } from 'app/store/files/files.action';
import { FilesSelectors } from 'app/store/files/files.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';

@Component({
    selector: 'file-manager-details',
    templateUrl: './my-files-details.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkClassicButtonComponent,
        FuseAlertComponent,
        KeyValuePipe,
        LetDirective,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        NgFor,
        NgIf,
        RouterLink,
        SafePipe,
        TranslocoModule,
    ],
})
export class FileManagerDetailsComponent {
    @Input() item: FileViewModel;

    EFileExtension = EFileExtension;
    EFileType = EFileType;

    hasDownloadedFileBlobUrl$ = this.store.select(
        FilesSelectors.selectHasDownloadedFileBlobUrl
    );

    downloadedFileBlobUrl$ = this.store.select(
        FilesSelectors.selectDownloadedFileBlobUrl
    );

    isLoadingDownload$ = this.store.select(
        FilesSelectors.selectIsLoadingDownload
    );

    downloadedFileError$ = this.store.select(
        FilesSelectors.selectDownloadedFileError
    );

    associationLegalDocumentWarning$ = this.store.select(
        FilesSelectors.selectAssociationLegalDocumentWarning
    );

    userRole$ = this.store.select(UserSelectors.selectUserRole);

    constructor(
        private store: Store,
        private fileUploadDialogService: FileUploadDialogService
    ) {}

    get showDescription() {
        return (
            this.item.longDescription ||
            this.item.fileExtension === EFileExtension.Folder ||
            this.item.fileType === EFileType.AssociationLegalDocument
        );
    }

    unselectItem() {
        this.store.dispatch(FilesActions.unselectFile());
    }

    open(url: string) {
        window.open(url);
    }

    upload() {
        if (this.item.fileType === EFileType.AssociationLegalDocument) {
            this.fileUploadDialogService.open({
                id: this.item.legalDocumentType,
                action: FilesActions.uploadAssociationLegalDocumentRequest,
                isLoading$: this.store.select(
                    FilesSelectors.selectIsLoadingUpload
                ),
                maxFileSize: 10000 * 1024,
                infoTextKey: 'files.legal-document-upload-info',
                extraAllowedFormats: ['pdf'],
            });
        }
    }
}
