import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BslkColor } from '@bslk/types/bslk-types';

@Component({
    selector: 'bslk-icon-button',
    standalone: true,
    templateUrl: './icon-button.component.html',
    imports: [
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatTooltipModule,
        NgIf,
        NgClass,
        RouterModule,
    ],
})
export class BslkIconButtonComponent {
    @Input() color: BslkColor = 'primary';
    @Input() icon: string;
    @Input() tooltip: string;

    @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
}
