/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EMissionType = 'FlashCampaign' | 'SkillsBasedSponsorship' | 'Mentoring';

export const EMissionType = {
    FlashCampaign: 'FlashCampaign' as EMissionType,
    SkillsBasedSponsorship: 'SkillsBasedSponsorship' as EMissionType,
    Mentoring: 'Mentoring' as EMissionType
};

