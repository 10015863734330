import { NgIf } from '@angular/common';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { FormatNumberPipe } from '../../../app/shared/pipes/format-number.pipe';

@Component({
    selector: 'bslk-animated-number',
    standalone: true,
    templateUrl: './animated-number.component.html',
    imports: [
        MatSliderModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatRadioModule,
        NgIf,
        FormatNumberPipe,
    ],
})
export class BslkAnimatedNumberComponent implements OnInit, OnChanges {
    @Input() endValue: number;
    currentNumber = 0;
    increment: number;
    seconds: number = 4;

    ngOnInit() {
        this.startAnimation();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.endValue && !changes.endValue.firstChange) {
            this.currentNumber = 0;
            this.startAnimation();
        }
    }

    startAnimation() {
        this.increment = this.endValue / (this.seconds * 20);
        const interval = setInterval(() => {
            if (this.currentNumber < this.endValue) {
                this.currentNumber += this.increment;
                this.currentNumber = Math.min(
                    this.endValue,
                    Math.ceil(this.currentNumber)
                );
            } else {
                this.currentNumber = this.endValue;
                clearInterval(interval);
            }
        }, 50);
    }
}
