import { NgClass, NgFor, NgIf } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { BslKCountdownComponent } from '@bslk/components/countdown/countdown.component';
import { BslkColor } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';
import { CourseUserCompletionViewModel, EAwarenessThematic } from 'app/api';
import { DateTime } from 'luxon';
import { BslkClassicOverlayComponent } from '../../../../../../../../@bslk/components/overlay/classic-overlay/bslk-classic-overlay/bslk-classic-overlay.component';

@Component({
    selector: 'course-dashboard-thematic-header',
    templateUrl: './header.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        MatIconModule,
        NgIf,
        NgClass,
        NgFor,
        BslkClassicOverlayComponent,
        BslKCountdownComponent,
        MatExpansionModule,
    ],
})
export class CourseDashboardThematicHeader implements OnInit, AfterViewInit {
    @ViewChild('summaryButton') summaryButtonRef: ElementRef;

    @Input() thematic: EAwarenessThematic;
    @Input() color: BslkColor = 'tertiary';
    @Input() startDate: string;
    @Input() endDate: string;
    @Input() courses: CourseUserCompletionViewModel[];
    @Input() index: number;

    isPanelOpen = false;
    isViewInitialized = false;
    showCountdown = false;
    countdownTargetDate: DateTime;

    constructor(private cdRef: ChangeDetectorRef) {}

    get isStarted() {
        return DateTime.fromISO(this.startDate) < DateTime.now();
    }

    ngOnInit(): void {
        const startDateDT = DateTime.fromISO(this.startDate);
        const endDateDT = DateTime.fromISO(this.endDate);
        if (startDateDT < DateTime.now()) {
            this.countdownTargetDate = endDateDT;
            this.showCountdown = true;
            this.cdRef.detectChanges();
        }
    }

    openPanel(): void {
        this.isPanelOpen = true;
    }

    onClose() {
        this.isPanelOpen = false;
    }

    ngAfterViewInit(): void {
        this.isViewInitialized = true;
        this.cdRef.detectChanges();
    }
}
