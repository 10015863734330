<div
    *ngrxLet="activeSection$ as activeSection"
    class="shadow-solid-primary bg-white w-full rounded-xl flex flex-col relative"
>
    <div
        *ngrxLet="commentsCount$ as commentsCount"
        class="p-4 text-2xl font-bold border-b border-gray-200 text-center truncate"
    >
        {{ commentsCount }}
        {{
            "coursesection.comment-" +
                (commentsCount > 1 ? "plural" : "singular") | transloco
        }}
    </div>

    <ng-container *ngrxLet="comments$ as comments">
        <div
            class="flex flex-col px-6"
            *ngIf="comments?.length > 0; else noComments"
        >
            <div
                *ngFor="let comment of comments; let last = last"
                class="py-4"
                [class.border-b]="!last"
            >
                <course-section-comment
                    [comment]="comment"
                ></course-section-comment>
            </div>
        </div>
    </ng-container>

    <ng-template #noComments>
        <div class="p-6 flex flex-col justify-center text-black h-full">
            <div
                class="grow flex flex-col justify-center gap-5 text-lg items-center"
            >
                {{ "coursesection.be-first" | transloco }}
                <mat-icon
                    [svgIcon]="'heroicons_outline:chat-bubble-left'"
                    class="text-current icon-size-12"
                ></mat-icon>
            </div>
        </div>
    </ng-template>

    <div class="flex px-8 py-3 border-t">
        <button
            (click)="comment()"
            class="flex items-center justify-center w-full py-2 bg-white font-bold text-lg rounded-full border border-black hover:border-warn hover:bg-warn transition-all hover:text-white duration-400"
        >
            <mat-icon
                [svgIcon]="'heroicons_solid:chat-bubble-oval-left-ellipsis'"
                class="mr-2 text-current"
            ></mat-icon>
            <span>{{ "coursesection.comment" | transloco }}</span>
        </button>
    </div>

    <div class="absolute right-0 h-full flex flex-col justify-center">
        <chapter-section-drawer-comments
            [sectionId]="activeSection.id"
            [chapterId]="activeChapterId$ | async"
            [openEvent]="openDrawerEvent"
        ></chapter-section-drawer-comments>
    </div>
</div>
