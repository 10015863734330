import { BslkTimelineItem } from '@bslk/components/timeline/timeline.component';
import { createSelector } from '@ngrx/store';
import {
    EMissionState,
    EMissionTimelineEvent,
    ETimeState,
    EUserRole,
} from 'app/api';
import { isEmpty, isNil } from 'lodash-es';
import { AppState } from '../..';
import { UserSelectors } from '../../user/user/user.selectors';
const selectState = (state: AppState) => state.missionManage;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectIsLoadingUserValidation = createSelector(
    selectState,
    (state) => state.isLoadingUserValidation
);

const selectIsLoadingTasks = createSelector(
    selectState,
    (state) => state.isLoadingTasks
);

const selectMission = createSelector(selectState, (state) => state.mission);

const selectIsTeamBuilding = createSelector(
    selectMission,
    (mission) => mission?.isTeamBuilding
);

const selectIsPaidTeamBuilding = createSelector(
    selectMission,
    (mission) => mission?.isPaidTeamBuilding
);

const selectMissionSlots = createSelector(
    selectMission,
    (mission) => mission?.missionSlots
);

const selectHasAnySlot = createSelector(
    selectMissionSlots,
    (slots) => !isEmpty(slots)
);

const selectShowMissionSlotsDropdown = createSelector(
    selectMissionSlots,
    (slots) => !isNil(slots) && slots.length > 1
);

const selectSelectedSlot = createSelector(selectState, (state) =>
    state.mission?.missionSlots.find((ms) => ms.id === state.selectedSlotId)
);

const selectSelectedSlotSpotsLeft = createSelector(
    selectSelectedSlot,
    (selectedSlot) => selectedSlot?.availableSpotsLeft
);

const selectPaidTeamBuildingMinSpotsToFill = createSelector(
    selectMission,
    selectSelectedSlot,
    (mission, selectedSlot) =>
        mission?.paidTeamBuildingMinPerson - selectedSlot?.users.length
);

const selectPaidTeamBuildingPrice = createSelector(
    selectMission,
    selectSelectedSlot,
    (mission, selectedSlot) =>
        Math.max(
            selectedSlot?.users.length *
                mission?.paidTeamBuildingPricePerPerson,
            mission?.paidTeamBuildingPricePerPerson *
                mission?.paidTeamBuildingMinPerson
        )
);

const selectPaidTeamBuildingCurrency = createSelector(
    selectMission,
    (mission) => mission?.paidTeamBuildingCurrency
);

const selectSelectedSlotIsFull = createSelector(
    selectSelectedSlotSpotsLeft,
    (spotsLeft) => spotsLeft <= 0
);

const selectSelectedSlotEstimatedHours = createSelector(
    selectSelectedSlot,
    (selectedSlot) => selectedSlot?.estimatedTimeInHours
);

const selectSelectedSlotId = createSelector(
    selectSelectedSlot,
    (selectedSlot) => selectedSlot?.id
);

const selectMissionTasks = createSelector(
    selectMission,
    (mission) => mission?.tasks
);

const selectSelectedSlotUsers = createSelector(
    selectSelectedSlot,
    (slot) => slot?.users
);

const selectSelectedSlotUserIds = createSelector(
    selectSelectedSlotUsers,
    (users) => users?.map((u) => u.user?.id)
);

const selectSelectedSlotIsGroupSlot = createSelector(
    selectSelectedSlotUsers,
    (users) => users?.length > 1
);

const selectHasSelectedSlot = createSelector(
    selectSelectedSlot,
    (slot) => !isNil(slot)
);

const selectMissionSlotRegisteredId = createSelector(
    selectState,
    (state) => state.missionSlotRegisteredId
);

const selectSelectedSlotUser = createSelector(
    selectSelectedSlot,
    UserSelectors.selectUserId,
    (slot, userId) => slot?.users?.find((u) => u.user.id === userId)
);

const selectSelectedSlotUserMissionState = createSelector(
    selectSelectedSlotUser,
    (selectedSlotUser) => selectedSlotUser?.missionState
);

const selectCanPostRating = createSelector(
    UserSelectors.selectUserRole,
    selectSelectedSlotUser,
    (role, selectedSlotUser) =>
        !isNil(selectedSlotUser) &&
        role === EUserRole.Regular &&
        !selectedSlotUser.hasUserLeftRating &&
        (selectedSlotUser.missionState === EMissionState.InProgress ||
            selectedSlotUser.missionState ===
                EMissionState.WaitingForUserRating)
);

const selectHasPostedRating = createSelector(
    selectSelectedSlotUser,
    (selectedSlotUser) => selectedSlotUser?.hasUserLeftRating
);

const selectCanManageTasks = createSelector(
    UserSelectors.selectUserRole,
    selectSelectedSlotUserMissionState,
    (role, state) =>
        // Association admins and users who are accepted can manage mission
        role === EUserRole.AssoAdmin ||
        state !== EMissionState.WaitingForAssociationAcceptance
);

const selectCanDeleteSlot = createSelector(
    selectSelectedSlot,
    (selectedSlot) => {
        return isEmpty(selectedSlot?.users);
    }
);

const selectCanCancelParticipation = createSelector(
    selectSelectedSlotUser,
    (selectedSlotUser) =>
        selectedSlotUser &&
        !selectedSlotUser.isValidatedByUser &&
        !selectedSlotUser.hasUserLeftRating &&
        !selectedSlotUser.isValidatedByAssociation
);

const selectTimelineForRegular = createSelector(
    selectSelectedSlotUser,
    selectSelectedSlot,
    (selectedSlotUser, selectedSlot) => {
        const userValidationTimeState = selectedSlotUser.timelineEvents.find(
            (te) => te.timelineEvent === EMissionTimelineEvent.UserValidation
        ).timeState;
        return [
            // Registration
            {
                titleKey: 'shared.mission-timeline.registration.title',
                descriptionKey:
                    'shared.mission-timeline.registration.description',
                dateLabel: 'done-on',
                timeState: selectedSlotUser.timelineEvents.find(
                    (te) =>
                        te.timelineEvent === EMissionTimelineEvent.Registration
                ).timeState,
            },
            // Manager acceptation
            {
                titleKey: 'shared.mission-timeline.manager-acceptation.title',
                descriptionKey:
                    'shared.mission-timeline.manager-acceptation.description',
                dateLabel: 'done-on',
                timeState: selectedSlotUser.timelineEvents.find(
                    (te) =>
                        te.timelineEvent ===
                        EMissionTimelineEvent.ManagerAcceptation
                ).timeState,
            },
            // Association acceptation
            {
                titleKey:
                    'shared.mission-timeline.association-acceptation.title',
                descriptionKey:
                    'shared.mission-timeline.association-acceptation.description',
                dateLabel: 'done-on',
                timeState: selectedSlotUser.timelineEvents.find(
                    (te) =>
                        te.timelineEvent ===
                        EMissionTimelineEvent.AssociationAcceptation
                ).timeState,
            },
            // Mission start
            {
                titleKey: 'shared.mission-timeline.start.title',
                descriptionKey: 'shared.mission-timeline.start.description',
                dateLabel: 'from',
                date: selectedSlot.startDate,
                timeState: selectedSlotUser.timelineEvents.find(
                    (te) => te.timelineEvent === EMissionTimelineEvent.Start
                ).timeState,
            },
            // Rating
            {
                titleKey: 'shared.mission-timeline.rating.title',
                descriptionKey: 'shared.mission-timeline.rating.description',
                date: selectedSlot.endDate,
                dateLabel: 'from',
                timeState: selectedSlotUser.timelineEvents.find(
                    (te) => te.timelineEvent === EMissionTimelineEvent.Rating
                ).timeState,
            },
            // User validation
            {
                titleKey: 'shared.mission-timeline.user-validation.title',
                descriptionKey:
                    'shared.mission-timeline.user-validation.description',
                date: selectedSlot.endDate,
                dateLabel: selectedSlotUser.isValidatedByUser
                    ? 'done-on'
                    : 'from',
                timeState: userValidationTimeState,
                actionId: 'validate',
                actionKey: 'shared.mission-timeline.user-validation.action',
                isActionDisabled:
                    userValidationTimeState !== ETimeState.Present,
            },
            // Association validation
            {
                titleKey:
                    'shared.mission-timeline.association-validation.title',
                descriptionKey:
                    'shared.mission-timeline.association-validation.description',
                dateLabel: 'from',
                timeState: selectedSlotUser.timelineEvents.find(
                    (te) =>
                        te.timelineEvent ===
                        EMissionTimelineEvent.AssociationValidation
                ).timeState,
            },
        ] as BslkTimelineItem[];
    }
);

const selectTimelineItems = createSelector(
    selectTimelineForRegular,
    (timelineForRegular) => {
        return timelineForRegular;
    }
);

export const MissionManageSelectors = {
    selectCanCancelParticipation,
    selectCanDeleteSlot,
    selectCanManageTasks,
    selectCanPostRating,
    selectHasAnySlot,
    selectHasPostedRating,
    selectHasSelectedSlot,
    selectIsLoading,
    selectIsLoadingTasks,
    selectIsLoadingUserValidation,
    selectIsPaidTeamBuilding,
    selectIsTeamBuilding,
    selectMission,
    selectMissionSlotRegisteredId,
    selectMissionSlots,
    selectMissionTasks,
    selectPaidTeamBuildingMinSpotsToFill,
    selectPaidTeamBuildingPrice,
    selectSelectedSlot,
    selectSelectedSlotEstimatedHours,
    selectSelectedSlotId,
    selectSelectedSlotIsFull,
    selectSelectedSlotIsGroupSlot,
    selectSelectedSlotSpotsLeft,
    selectSelectedSlotUserIds,
    selectSelectedSlotUserMissionState,
    selectSelectedSlotUsers,
    selectShowMissionSlotsDropdown,
    selectTimelineItems,
    selectPaidTeamBuildingCurrency,
};
