<div *ngIf="label" class="font-bold mb-2">{{ label }}</div>
<div *ngIf="view === 'slider'" class="flex items-center pl-3">
    <mat-slider class="grow" [min]="min" [max]="max" [step]="step">
        <input
            *ngIf="control"
            (valueChange)="onValueChange($event)"
            matInput
            [formControl]="control"
            matSliderThumb
        />
        <input
            *ngIf="!control"
            (valueChange)="onValueChange($event)"
            matInput
            [(ngModel)]="ngModelValue"
            matSliderThumb
        />
    </mat-slider>
    <div class="ml-5">
        <span class="font-bold text-lg">{{ value }}</span>
        {{ unitLabel }}
    </div>
</div>
<mat-form-field class="flex" *ngIf="view === 'input'">
    <input
        *ngIf="control"
        (valueChange)="onValueChange($event)"
        matInput
        [formControl]="control"
        type="number"
    />
    <input
        *ngIf="!control"
        (valueChange)="onValueChange($event)"
        matInput
        [(ngModel)]="ngModelValue"
    />
</mat-form-field>
