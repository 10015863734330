import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EDualQuizChallengeResult } from 'app/api/model/models';
import { ESharedUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { DualQuizChallengeActions } from 'app/store/dual-quiz/challenge/challenge.action';
import { DualQuizChallengeSelectors } from 'app/store/dual-quiz/challenge/challenge.selectors';
import { MyBasileSelectors } from 'app/store/my-basile/my-basilie.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';
import { DualQuizChallengeColumnComponent } from './column/column.component';

@Component({
    selector: 'challenge-results',
    templateUrl: './challenge-results.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        BslkClassicButtonComponent,
        AsyncPipe,
        LetDirective,
        MatIconModule,
        NgIf,
        NgClass,
        DualQuizChallengeColumnComponent,
        NgxSkeletonLoaderModule,
    ],
})
export class DualQuizChallengeResultsComponent implements OnInit, OnDestroy {
    EDualQuizChallengeResult = EDualQuizChallengeResult;

    unsubscribeAll: Subject<any> = new Subject<any>();

    challenge$ = this.store.select(DualQuizChallengeSelectors.selectChallenge);
    userBasileConfiguration$ = this.store.select(
        MyBasileSelectors.selectBasileConfiguration
    );
    isLoading$ = this.store.select(DualQuizChallengeSelectors.selectIsLoading);

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                if (params['id']) {
                    this.store.dispatch(
                        DualQuizChallengeActions.loadChallengeRequest({
                            id: params['id'],
                        })
                    );
                }
            });
    }

    goToChallenges() {
        this.router.navigateByUrl(
            UrlHelpers.getSharedUrl(ESharedUrl.SharedDualQuizChallengesPage)
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
