import { DatePipe, NgIf } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { BslkMenuComponent } from '@bslk/components/menu/menu.component';
import { BslkColor } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';
import { EChartTimePeriod } from 'app/api';
import { DateRange } from 'app/shared/types/common.types';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { merge } from 'lodash-es';
import { ApexAxisChartSeries, ApexOptions } from 'ng-apexcharts';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BslkChartComponent } from '../bslk-chart/bslk-chart.component';

export type AdvancedChartFilters = {
    timePeriod: EChartTimePeriod;
    periodOffset: number;
};

@Component({
    selector: 'bslk-advanced-chart',
    templateUrl: './bslk-advanced-chart.component.html',
    standalone: true,
    imports: [
        NgIf,
        BslkChartComponent,
        MatIconModule,
        BslkMenuComponent,
        MatTooltipModule,
        NgxSkeletonLoaderModule,
        TranslocoModule,
        MatSelectModule,
        BslkClassicToggleComponent,
    ],
})
export class BslkAdvancedChartComponent implements OnChanges {
    @ViewChild('bslkChart') bslkChart: BslkChartComponent;

    @Output() expands: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() filterChanges: EventEmitter<AdvancedChartFilters> =
        new EventEmitter<AdvancedChartFilters>();

    @Input() options: Partial<ApexOptions>;
    @Input() expandOptions: Partial<ApexOptions>;
    @Input() isLoading: boolean;
    @Input() title: string;
    @Input() series: ApexAxisChartSeries;
    @Input() labels: string[];
    @Input() isExpandable = false;
    @Input() isExpanded = false;
    @Input() isDownloadEnabled = false;
    @Input() isTimePeriodSelectionEnabled = false;
    @Input() infoTooltip: string;
    @Input() gradientColorTo: BslkColor;
    @Input() gradientColorFrom: BslkColor;
    @Input() hasData = true;
    @Input() dateRange: DateRange;

    chartOptions: Partial<ApexOptions>;

    EChartTimePeriod = EChartTimePeriod;
    filters: AdvancedChartFilters = {
        timePeriod: EChartTimePeriod.Year,
        periodOffset: 0,
    };

    constructor(private datePipe: DatePipe) {}

    get formattedDateRange() {
        return formatDateRange(
            this.datePipe,
            this.dateRange.startDate,
            this.dateRange.endDate
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isExpanded || changes.options || changes.expandOptions) {
            this.buildOptions();
        }
    }

    buildOptions() {
        if (!this.expandOptions) {
            this.chartOptions = this.options;
        }
        const overridenOptions = merge({}, this.options, this.expandOptions);
        this.chartOptions = this.isExpanded ? overridenOptions : this.options;
    }

    download(type: 'png' | 'svg' | 'csv') {
        this.bslkChart.download(type);
    }

    toggleExpand() {
        this.expands.emit(!this.isExpanded);
    }

    onTimePeriodChange(timePeriod: EChartTimePeriod) {
        this.filters = {
            timePeriod,
            periodOffset: 0,
        };
        this.filterChanges.emit(this.filters);
    }

    onPeriodOffsetChange(change: number) {
        this.filters.periodOffset += change;
        this.filterChanges.emit(this.filters);
    }
}
