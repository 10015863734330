import { TextFieldModule } from '@angular/cdk/text-field';
import {
    AsyncPipe,
    DatePipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkTextSlideButtonComponent } from '@bslk/components/button/text-slide/text-slide-button.component';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkInfoComponent } from '@bslk/components/info/info.component';
import { BslkRewardComponent } from '@bslk/components/reward/reward.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import {
    EActivityField,
    ESkill,
    ETrackedActivityType,
    MissionSlotViewModel,
} from 'app/api';
import { AssociationRatingComponent } from 'app/shared/components/association/association-rating/association-rating.component';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { EnumTagListComponent } from 'app/shared/components/enum-tag-list/enum-tag-list.component';
import { MissionGridCardComponent } from 'app/shared/components/mission/grid/mission-grid-card.component';
import { CoverTemplateComponent } from 'app/shared/components/templates/cover-template/cover-template.component';
import { SafePipe } from 'app/shared/pipes/safe.pipe';
import { ActivityService } from 'app/shared/services/activity/activity.service';
import { GoogleMapsApiService } from 'app/shared/services/api/google-maps-api.service';
import { sustainableDevelopmentGoals } from 'app/shared/utils/constants.utils';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { initializeMapWithLocation } from 'app/shared/utils/google-maps.utils';
import { MissionActions } from 'app/store/mission/mission/mission.action';
import { MissionSelectors } from 'app/store/mission/mission/mission.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
    selector: 'mission-view',
    standalone: true,
    templateUrl: './mission-view.component.html',
    imports: [
        AssociationRatingComponent,
        AsyncPipe,
        BslkClassicButtonComponent,
        BslkClassicCardComponent,
        BslkInfoComponent,
        BslkRewardComponent,
        BslkTagComponent,
        BslkTextSlideButtonComponent,
        ClassicAvatarComponent,
        CoverTemplateComponent,
        DatePipe,
        EnumTagListComponent,
        FuseAlertComponent,
        LetDirective,
        MatButtonModule,
        MatChipsModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTooltipModule,
        MissionGridCardComponent,
        NgClass,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        NgxSkeletonLoaderModule,
        RouterLink,
        SafePipe,
        TextFieldModule,
        TranslocoModule,
    ],
})
export class MissionViewComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('googleMaps') mapRef: ElementRef;

    ESkill = ESkill;
    EActivityField = EActivityField;

    unsubscribeAll: Subject<any> = new Subject<any>();
    locationSubscription: Subscription;
    showGoogleMaps = false;
    showAllSlots = false;
    sustainableDevlopmentGoals = sustainableDevelopmentGoals;

    canRegister$ = this.store.select(MissionSelectors.selectCanRegister);
    isRegisterDisabled$ = this.store.select(
        MissionSelectors.selectRegisterIsDisabled
    );
    hasCover$ = this.store.select(MissionSelectors.selectHasCover);
    hasLogo$ = this.store.select(MissionSelectors.selectHasLogo);
    isLoading$ = this.store.select(MissionSelectors.selectIsLoading);
    isRegular$ = this.store.select(UserSelectors.selectIsRegular);
    isAssoAdmin$ = this.store.select(UserSelectors.selectIsAssoAdmin);
    isAssoAdminOrSuperAdmin$ = this.store.select(
        UserSelectors.selectIsAssoAdminOrSuperAdmin
    );
    mission$ = this.store.select(MissionSelectors.selectMission);
    availableMissionSlots$ = this.store.select(
        MissionSelectors.selectAvailableMissionSlots
    );
    availableMissionSlotsPreview$ = this.store.select(
        MissionSelectors.selectAvailableMissionSlotsPreview
    );
    isSeeAllAvailableMissionSlotsButtonNeeded$ = this.store.select(
        MissionSelectors.selectIsSeeAllAvailableMissionSlotsButtonNeeded
    );
    registered$ = this.store.select(MissionSelectors.selectIsRegistered);
    locationWithoutAddress$ = this.store.select(
        MissionSelectors.selectLocationWithoutAddress
    );
    responsibleUserIsOnline$ = this.store.select(
        MissionSelectors.selectResponsibleUserIsOnline
    );
    responsibleUserLastOnlineTimeUnit$ = this.store.select(
        MissionSelectors.selectResponsibleUserLastOnlineTimeUnit
    );
    responsibleUserLastOnlineTimeValue$ = this.store.select(
        MissionSelectors.selectResponsibleUserLastOnlineTimeValue
    );
    associationNameFirstLetter$ = this.store.select(
        MissionSelectors.selectAssociationNameFirstLetter
    );

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private datePipe: DatePipe,
        private googleMapsApiService: GoogleMapsApiService,
        private activityService: ActivityService
    ) {}

    ngOnInit(): void {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                if (params['id']) {
                    this.store.dispatch(
                        MissionActions.loadMissionRequest({ id: params['id'] })
                    );
                    this.activityService.sendActivityRequest({
                        trackedActivityType:
                            ETrackedActivityType.ViewSingleMissionPage,
                        details: params['id'],
                    });
                    this.ngAfterViewInit();
                }
            });
    }

    ngAfterViewInit(): void {
        this.locationSubscription = this.store
            .select(MissionSelectors.selectLocation)
            .subscribe((location) => {
                if (location?.latitude && location?.longitude) {
                    this.showGoogleMaps = true;
                    this.googleMapsApiService
                        .loadLibrary({
                            core: true,
                            maps: true,
                            marker: true,
                        })
                        .then((libraries) => {
                            initializeMapWithLocation(
                                libraries,
                                this.mapRef,
                                location
                            );
                            this.locationSubscription.unsubscribe();
                        });
                }
            });
    }

    sanitize(text: string) {
        return this.sanitizer.bypassSecurityTrustHtml(text);
    }

    formatDateRange(missionSlot: MissionSlotViewModel) {
        return formatDateRange(
            this.datePipe,
            missionSlot.startDate,
            missionSlot.endDate
        );
    }

    register() {
        this.store.dispatch(MissionActions.registerMission());
    }

    editMission() {
        this.store.dispatch(MissionActions.editMission());
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }

    goToPreviousPage() {
        window.history.back();
    }

    ngOnDestroy(): void {
        if (this.locationSubscription) {
            this.locationSubscription.unsubscribe();
        }
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
