import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EActivityField } from 'app/api';
import { ProjectListFiltersActions } from 'app/store/project/project-list-filters/project-list-filters.action';
import { ProjectListFiltersSelectors } from 'app/store/project/project-list-filters/project-list-filters.selectors';

@Component({
    selector: 'activity-fields-filters',
    templateUrl: './activity-fields-filters.component.html',
    standalone: true,
    imports: [
        FuseDrawerComponent,
        MatIconModule,
        TranslocoModule,
        BslkTagComponent,
        NgFor,
        AsyncPipe,
        NgIf,
        LetDirective,
        BslkTagButtonComponent,
    ],
})
export class ProjectsActivityFieldsFiltersComponent implements OnChanges {
    @ViewChild('drawer') drawer: FuseDrawerComponent;

    @Input() openEvent: string;

    filteredActivityFields$ = this.store.select(
        ProjectListFiltersSelectors.selectActivityFields
    );

    availableActivityFields$ = this.store.select(
        ProjectListFiltersSelectors.selectAvailableActivityFields
    );

    constructor(private store: Store) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.openEvent) {
            this.openDrawer();
        }
    }

    addActivityField(activityField: EActivityField) {
        this.store.dispatch(
            ProjectListFiltersActions.addActivityField({
                activityField,
            })
        );
    }

    removeActivityField(activityField: EActivityField) {
        this.store.dispatch(
            ProjectListFiltersActions.removeActivityField({
                activityField,
            })
        );
    }

    openDrawer() {
        if (!this.drawer) {
            return;
        }
        this.drawer.open();
    }

    onClose() {
        this.drawer.close();
    }
}
