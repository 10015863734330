import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NgIf } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BslkColor, BslkSize } from '@bslk/types/bslk-types';
import { createOverlayRef } from 'app/shared/utils/overlay.utils';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'bslk-classic-overlay',
    templateUrl: './bslk-classic-overlay.component.html',
    standalone: true,
    imports: [MatIconModule, MatButtonModule, NgIf],
})
export class BslkClassicOverlayComponent implements OnChanges, OnDestroy {
    @ViewChild('panel') panel: TemplateRef<any>;

    @Input() isOpen = false;
    @Input() hideHeader = false;
    @Input() opaqueBackground = false;
    @Input() origin: ElementRef;
    @Input() color: BslkColor = 'primary';
    @Input() size: BslkSize = 'medium';
    @Input() overflowAuto = true;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    overlayRef: OverlayRef;
    unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.isOpen || changes.isOpen.firstChange) {
            return;
        }
        if (changes.isOpen.currentValue) {
            this.openPanel();
        } else if (changes.isOpen && !changes.isOpen.currentValue) {
            this.closePanel();
        }
    }

    openPanel(): void {
        if (!this.panel || !this.origin) {
            return;
        }

        if (!this.overlayRef) {
            this.overlayRef = createOverlayRef(
                this.overlay,
                this.origin.nativeElement
            );
            this.overlayRef
                .backdropClick()
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe(() => {
                    this.closePanel();
                });
        }

        this.overlayRef.attach(
            new TemplatePortal(this.panel, this.viewContainerRef)
        );
    }

    closePanel(): void {
        this.overlayRef?.detach();
        this.close.emit();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }
}
