import { AsyncPipe, JsonPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { SnackAlert } from './snack-alert.model';
import { SnackAlertService } from './snack-alert.service';

@Component({
    standalone: true,
    selector: 'app-snack-alert',
    templateUrl: './snack-alert.component.html',
    imports: [
        AsyncPipe,
        FuseAlertComponent,
        JsonPipe,
        LetDirective,
        NgFor,
        NgIf,
        RouterLink,
        TranslocoModule,
    ],
})
export class SnackAlertComponent {
    constructor(
        private snackAlertService: SnackAlertService,
        private store: Store
    ) {}

    closeSnackAlert(alert: SnackAlert) {
        this.snackAlertService.removeSnackAlert(alert);
    }

    dispatchAction(alert: SnackAlert) {
        if (alert.action) {
            this.store.dispatch(alert.action);
        }
    }

    get snackAlerts$() {
        return this.snackAlertService.snackAlerts$;
    }
}
