import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { ManagedUsersActions } from './managed-users.actions';
import { ManagedUsersState } from './managed-users.state';

export const initialState: ManagedUsersState = ressourceListInitialState;

export const managedUsersReducer = createRessourceListReducer(
    ressourceListInitialState,
    ManagedUsersActions
);
