import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AuthenticationResponseViewModel } from 'app/api';

export const ValidationActions = createActionGroup({
    source: 'Validation',
    events: {
        'Send Email Request': props<{ email: string }>(),
        'Send Email Request Success': emptyProps(),
        'Send Email Request Fail': props<{ error: string }>(),

        'Send Code Request': props<{ code: string }>(),
        'Send Code Request Success': emptyProps(),
        'Send Code Request Fail': props<{ error: string }>(),

        'Send Set Password Request': props<{ password: string }>(),
        'Send Set Password Request Success': emptyProps(),
        'Send Set Password Request Fail': props<{ error: string }>(),

        'Validate Email Request': props<{ userId: number; code: string }>(),
        'Validate Email Request Success': props<{
            session: AuthenticationResponseViewModel;
        }>(),
        'Validate Email Request Fail': props<{ error: string }>(),

        'New Validation Email Request': props<{ userId: number }>(),
        'New Validation Email Request Success': emptyProps(),
        'New Validation Email Request Fail': props<{ error: string }>(),
    },
});
