<button
    [ngClass]="
        disabled
            ? 'grayscale cursor-default'
            : 'hover:translate-x-1 hover:translate-y-1 hover:shadow-none'
    "
    class="next-button mt-3 transition-all px-16 py-4 rounded-full shadow-light shadow-primary flex text-center mx-auto transform"
>
    <span class="w-full text-xl font-bold text-white">{{ text }}</span>
</button>
