import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { BasileAvatarComponent } from 'app/shared/components/avatar/basile/basile-avatar.component';
import { LevelPipe } from 'app/shared/pipes/level.pipe';
import { RankingAnswersActions } from 'app/store/ranking/ranking-answers/ranking-answers.actions';
import { RankingAnswersSelectors } from 'app/store/ranking/ranking-answers/ranking-answers.selectors';
import { RankingExperienceActions } from 'app/store/ranking/ranking-experience/ranking-experience.actions';
import { RankingExperienceSelectors } from 'app/store/ranking/ranking-experience/ranking-experience.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable } from 'rxjs';

type RankingBy = 'level' | 'answers';

@Component({
    selector: 'course-dashboard-ranking',
    templateUrl: './ranking.component.html',
    standalone: true,
    imports: [
        MatIconModule,
        AsyncPipe,
        NgIf,
        LetDirective,
        TranslocoModule,
        LevelPipe,
        BasileAvatarComponent,
        NgxSkeletonLoaderModule,
        BslkClassicToggleComponent,
    ],
})
export class CourseDashboardRankingComponent implements OnInit {
    first$: Observable<any>;
    second$: Observable<any>;
    third$: Observable<any>;
    userRank$: Observable<number>;
    isLoading$: Observable<boolean>;

    rankingBy: RankingBy = 'level';

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.showSelectedRanking();
    }

    rankingTypeChange(type: RankingBy) {
        this.rankingBy = type;
        this.showSelectedRanking();
    }

    showSelectedRanking() {
        if (this.rankingBy === 'level') {
            this.showRankingByLevel();
            return;
        }
        this.showRankingByAnswers();
    }

    showRankingByLevel() {
        this.store.dispatch(
            RankingExperienceActions.InitializeWithFilters({
                filters: [
                    {
                        key: 'pageSize',
                        value: 3,
                    },
                ],
            })
        );

        this.first$ = this.store.select(
            RankingExperienceSelectors.selectByRank(1)
        );
        this.second$ = this.store.select(
            RankingExperienceSelectors.selectByRank(2)
        );
        this.third$ = this.store.select(
            RankingExperienceSelectors.selectByRank(3)
        );

        this.userRank$ = this.store.select(
            RankingExperienceSelectors.selectUserRank
        );

        this.isLoading$ = this.store.select(
            RankingExperienceSelectors.selectIsLoading
        );
    }

    showRankingByAnswers() {
        this.store.dispatch(
            RankingAnswersActions.InitializeWithFilters({
                filters: [
                    {
                        key: 'pageSize',
                        value: 3,
                    },
                ],
            })
        );

        this.first$ = this.store.select(
            RankingAnswersSelectors.selectByRank(1)
        );
        this.second$ = this.store.select(
            RankingAnswersSelectors.selectByRank(2)
        );
        this.third$ = this.store.select(
            RankingAnswersSelectors.selectByRank(3)
        );

        this.userRank$ = this.store.select(
            RankingAnswersSelectors.selectUserRank
        );

        this.isLoading$ = this.store.select(
            RankingAnswersSelectors.selectIsLoading
        );
    }
}
