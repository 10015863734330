import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

export interface IFormComponent {
  get getIsSubmitted(): boolean;
}

export class ClassicErrorStateMatcher implements ErrorStateMatcher {

    constructor(private formComponent: IFormComponent) {

    }

    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control && control.invalid && (control.dirty || control.touched || this.formComponent.getIsSubmitted);
    }
  }