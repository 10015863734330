import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'sanitize',
    standalone: true,
})
export class SanitizePipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}
    transform(html) {
        return this.domSanitizer.sanitize(SecurityContext.HTML, html);
    }
}
