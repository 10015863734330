import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { ProjectViewModel } from 'app/api';

@Component({
    selector: 'donation-impact',
    templateUrl: './donation-impact.component.html',
    standalone: true,
    imports: [TranslocoModule, NgIf, MatIconModule],
})
export class ProjectDonationImpactComponent {
    @Input() project: ProjectViewModel;

    constructor() {}
}
