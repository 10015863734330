import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UserService } from 'app/api';
import {
    createLoadListRequestEffect,
    createReloadEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { InteractedWithUsersActions } from './interacted-with-users.actions';
import { InteractedWithUsersSelectors } from './interacted-with-users.selectors';

@Injectable()
export class InteractedWithUsersEffects {
    loadUsers$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.userService,
        InteractedWithUsersActions,
        InteractedWithUsersSelectors,
        this.userService.userInteractedWithGet
    );

    reload$ = createReloadEffect(this.actions$, InteractedWithUsersActions);

    constructor(
        private actions$: Actions,
        private store: Store,
        private userService: UserService
    ) {}
}
