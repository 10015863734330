import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EUserRole } from 'app/api';
import { getUserFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { isNil } from 'lodash-es';
import { map, take } from 'rxjs';

export const AssoCreatedGuard = () => {
    const router: Router = inject(Router);
    const store: Store = inject(Store);

    return store.select(UserSelectors.selectUserRole).pipe(
        take(1),
        map((userRoleFromStore) => {
            let userRole: EUserRole = userRoleFromStore;
            const user = getUserFromLocalStorage();
            if (!userRole) {
                userRole = user?.userRole;
            }
            if (
                !userRole ||
                userRole !== EUserRole.AssoAdmin ||
                isNil(user.association)
            ) {
                const urlTree = router.parseUrl(`a/sign-up`);
                return urlTree;
            } else {
                return true;
            }
        })
    );
};
