import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { CourseActions } from 'app/store/course/course/course.action';
import { CourseSelectors } from 'app/store/course/course/course.selectors';
import { Subject, takeUntil } from 'rxjs';
import { CourseLoadingComponent } from '../loading/loading.component';
import { ChapterBasileComponent } from './basile/basile.component';
import { ChapterCardComponent } from './card/card.component';
import { ChapterSectionPreviewCommentsComponent } from './comments/preview/preview.component';
import { ChapterGainComponent } from './gain/gain.component';
import { ChapterProgressBarComponent } from './progress-bar/progress-bar.component';

@Component({
    selector: 'chapter',
    templateUrl: './chapter.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        ChapterBasileComponent,
        ChapterCardComponent,
        ChapterGainComponent,
        ChapterProgressBarComponent,
        ChapterSectionPreviewCommentsComponent,
        CourseLoadingComponent,
        TranslocoModule,
        NgIf,
    ],
})
export class CourseChapterComponent implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    isLoading$ = this.store.select(CourseSelectors.selectIsLoadingChapter);
    activeSectionIndex$ = this.store.select(
        CourseSelectors.selectActiveChapterSectionIndex
    );

    sectionsCount$ = this.store.select(
        CourseSelectors.selectActiveChapterSectionsCount
    );

    hasActiveChapter$ = this.store.select(
        CourseSelectors.selectHasActiveChapter
    );

    experienceGained$ = this.store.select(
        CourseSelectors.selectExperienceGained
    );
    purposeCoinsGained$ = this.store.select(
        CourseSelectors.selectPurposeCoinsGained
    );

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private fuseNavigationService: FuseNavigationService
    ) {}

    ngOnInit() {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                const chapterId = params['chapterId'];

                this.store.dispatch(
                    CourseActions.loadChapterRequest({
                        chapterId,
                    })
                );
            });

        const mainNavigation = this.getMainNavigation();
        if (mainNavigation) {
            mainNavigation.close();
        } else {
            this.fuseNavigationService
                .onComponentRegistered()
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe(() => {
                    this.getMainNavigation()?.close();
                });
        }
    }

    getMainNavigation() {
        return this.fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
            'mainNavigation'
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
