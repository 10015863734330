import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store';

const selectState = (state: AppState) => state.companyFilters;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

export const CompanyFiltersSelectors = {
    selectIsLoading,
};
