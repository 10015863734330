import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { TranslocoModule } from '@ngneat/transloco';
import {
    BasileConfigurationViewModel,
    DualQuizChallengeViewModel,
    EDualQuizChallengeResult,
    EReward,
} from 'app/api';
import { BasileAvatarComponent } from 'app/shared/components/avatar/basile/basile-avatar.component';
import { getRewardValues } from 'app/shared/utils/rewards.utils';
import { isNil } from 'lodash-es';

@Component({
    selector: 'dual-quiz-challenge-result-column',
    templateUrl: './column.component.html',
    styleUrls: ['./column.component.scss'],
    standalone: true,
    imports: [
        BasileAvatarComponent,
        BslkInfoLabelComponent,
        TranslocoModule,
        MatTooltipModule,
        NgIf,
        NgFor,
        MatIconModule,
    ],
})
export class DualQuizChallengeColumnComponent {
    Math = Math;

    @Input() basileConfiguration: BasileConfigurationViewModel;
    @Input() answers: boolean[];
    @Input() correctAnswersCount: number;
    @Input() name: string;
    @Input() challenge: DualQuizChallengeViewModel;
    @Input() isChallenger: boolean;

    get isDone() {
        return this.answers.every((answer) => !isNil(answer));
    }

    get correctAnswersPercentage() {
        return (
            (this.correctAnswersCount / this.challenge.questions.length) * 100
        );
    }

    get hasWon() {
        return (
            (this.isChallenger &&
                this.challenge.result ===
                    EDualQuizChallengeResult.ChallengerWon) ||
            (!this.isChallenger &&
                this.challenge.result ===
                    EDualQuizChallengeResult.ChallengeeWon)
        );
    }

    get rewards() {
        const hasLost =
            (this.isChallenger &&
                this.challenge.result ===
                    EDualQuizChallengeResult.ChallengeeWon) ||
            (!this.isChallenger &&
                this.challenge.result ===
                    EDualQuizChallengeResult.ChallengerWon);

        const reward = this.hasWon
            ? EReward.DualQuizChallengeWon
            : hasLost
            ? EReward.DualQuizChallengeLost
            : EReward.DualQuizChallengeTied;
        return getRewardValues(reward);
    }
}
