import { createAction, props } from '@ngrx/store';
import {
    DualQuizChallengeViewModel,
    DualQuizChallengeViewModelPaginatedListDto,
    EAwarenessThematic,
} from 'app/api';
import { createRessourceListActionGroup } from 'app/store/shared/ressource-list/ressource-list-action-group-creator';

export const DualQuizChallengesActions = {
    ...createRessourceListActionGroup<
        DualQuizChallengeViewModel,
        DualQuizChallengeViewModelPaginatedListDto
    >('DualQuizChallenges'),

    NewChallengeRequest: createAction(
        `[DualQuizChallenges] New Request Request`,
        props<{
            challengeeId?: number;
            challengeeEmail?: string;
            thematic: EAwarenessThematic;
            secondsPerQuestion: number;
            questionsCount: number;
        }>()
    ),
    NewChallengeRequestSuccess: createAction(
        `[DualQuizChallenges] New Request Request Success`,
        props<{ id: number }>()
    ),
    NewChallengeRequestFail: createAction(
        `[DualQuizChallenges] New Request Request Fail`,
        props<{ error: string }>()
    ),
};
