import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CompanyFiltersViewModel } from 'app/api';

export const CompanyFiltersActions = createActionGroup({
    source: 'CompanyFilters',
    events: {
        'Load Filters Request': emptyProps(),
        'Load Filters Request Success': props<{
            filters: CompanyFiltersViewModel;
        }>(),
        'Load Filters Request Fail': props<{ error: string }>(),

        'Update Filters Request': emptyProps(),
        'Update Filters Request Success': emptyProps(),
        'Update Filters Request Fail': props<{ error: string }>(),
    },
});
