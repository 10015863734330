import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RankingService } from 'app/api';
import {
    createLoadListRequestEffect,
    createReloadEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { RankingExperienceActions } from './ranking-experience.actions';
import { RankingExperienceSelectors } from './ranking-experience.selectors';

@Injectable()
export class RankingExperienceEffects {
    loadRanking$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.rankingService,
        RankingExperienceActions,
        RankingExperienceSelectors,
        this.rankingService.rankingExperienceGet
    );

    reload$ = createReloadEffect(this.actions$, RankingExperienceActions);

    constructor(
        private actions$: Actions,
        private store: Store,
        private rankingService: RankingService
    ) {}
}
