<base-dialog
    [size]="'small'"
    [showHeader]="false"
    (onClose)="close()"
    [showActions]="true"
>
    <fuse-alert type="warning" [appearance]="'outline'" body>
        <span class="text-lg font-semibold">{{
            data.textKey | transloco
        }}</span>
    </fuse-alert>

    <div class="flex justify-end gap-2" actions>
        <button mat-stroked-button (click)="close()">
            {{ "shared.common.cancel" | transloco }}
        </button>
        <button (click)="confirm()" mat-flat-button class="bg-warn text-white">
            <span class="ml-1">{{ "shared.common.confirm" | transloco }}</span>
        </button>
    </div>
</base-dialog>
