/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EChartTimePeriod = 'Week' | 'Month' | 'Year';

export const EChartTimePeriod = {
    Week: 'Week' as EChartTimePeriod,
    Month: 'Month' as EChartTimePeriod,
    Year: 'Year' as EChartTimePeriod
};

