import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ExportCompanyGetRequestParams } from 'app/api';
import {
    CompanyAdminDashboardConfiguration,
    CompanyAdminKpi,
    CompanyAdminKpiFilters,
    CompanyAdminKpiResponse,
    CompanyAdminTab,
} from './company-admin-dashboard.state';

export const CompanyAdminDashboardActions = createActionGroup({
    source: 'CompanyAdminDashboard',
    events: {
        'Init Dashboard': emptyProps(),
        'Check If Kpi Is Loaded': props<{ kpi: CompanyAdminKpi }>(),
        'Load Kpi Request': props<{
            kpi: CompanyAdminKpi;
        }>(),
        'Load Kpi Request Success': props<{
            kpi: CompanyAdminKpi;
            response: CompanyAdminKpiResponse;
        }>(),
        'Load Kpi Request Fail': props<{
            kpi: CompanyAdminKpi;
            error: string;
        }>(),
        'Update Kpi Filters': props<{
            kpi: CompanyAdminKpi;
            filters: CompanyAdminKpiFilters;
        }>(),
        'Switch Tab': props<{ tab: CompanyAdminTab }>(),
        'Expand Kpi': props<{ kpi: CompanyAdminKpi }>(),
        'Configure Kpi Display': props<{
            kpi: CompanyAdminKpi;
            tab: CompanyAdminTab;
            isDisplayed: boolean;
        }>(),
        'Update Configuration': props<{
            config: CompanyAdminDashboardConfiguration;
        }>(),
        'Minimize Kpi': emptyProps(),

        'Export Data Request': props<{
            config: ExportCompanyGetRequestParams;
        }>(),
        'Export Data Request Success': props<{
            blob: Blob;
            filename: string;
        }>(),
        'Export Data Request Fail': props<{ error: string }>(),
    },
});
