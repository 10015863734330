<table class="w-full text-left mt-1">
    <thead class="bg-warn shadow-outside text-lg shadow-warn-50 text-white">
        <tr>
            <th *ngFor="let key of getKeys()" scope="col" class="px-6 py-3">
                {{ "bslk.form.sheet-data-editor.fields." + key | transloco }}
            </th>
            <th></th>
        </tr>
    </thead>
    <tbody class="border">
        <tr
            *ngFor="let entity of entities; let i = index"
            class="bg-white border-t"
        >
            <td
                *ngFor="let key of getKeys()"
                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4 border-r"
            >
                <input
                    autofocus
                    [(ngModel)]="entity[key]"
                    [placeholder]="placeholders ? placeholders[key] ?? '' : ''"
                    (ngModelChange)="editEntity(i, key, $event)"
                    class="w-full"
                />
            </td>
            <td
                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4 flex justify-center"
            >
                <bslk-icon-button
                    (click)="deleteEntity(i)"
                    [icon]="'heroicons_outline:x-mark'"
                ></bslk-icon-button>
            </td>
        </tr>
        <tr *ngIf="entities?.length === 0">
            <td
                [attr.colspan]="fields.length"
                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4 font-bold text-2xl text-center"
            >
                {{ "bslk.form.sheet-data-editor.no-data" | transloco }}
            </td>
        </tr>
    </tbody>
</table>

<div class="mt-4 flex gap-3 items-center justify-end w-full">
    <bslk-tag-button (click)="onImportClicked()" [icon]="'feather:upload'">{{
        "bslk.form.sheet-data-editor.import" | transloco
    }}</bslk-tag-button>
    <bslk-tag-button (click)="addEntity()" [icon]="'heroicons_outline:plus'">{{
        "bslk.form.sheet-data-editor.add" | transloco
    }}</bslk-tag-button>
</div>
