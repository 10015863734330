<div class="flex flex-col gap-5" [formGroup]="formGroup">
    <div class="flex flex-col gap-1 flex-1">
        <bslk-info-label
            class="font-bold"
            [tooltip]="
                'donationcampaigns.visible-only-to-organize-campaigns'
                    | transloco
            "
            >{{
                "donationcampaigns.campaign-name" | transloco
            }}</bslk-info-label
        >
        <mat-form-field subscriptSizing="dynamic" floatLabel="always">
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:pencil'"
                matPrefix
            ></mat-icon>
            <input
                [placeholder]="'donationcampaigns.name-placeholder' | transloco"
                [formControlName]="'name'"
                matInput
            />
        </mat-form-field>
        <bslk-length-info
            [text]="formGroup.controls.name.value"
            [maxLength]="200"
        ></bslk-length-info>
    </div>

    <bslk-card-toggle
        [label]="'donationcampaigns.campaign-type' | transloco"
        [control]="formGroup.controls.isOneEnvelope"
        [choices]="[
            {
                text: 'donationcampaigns.individual-gift-donations' | transloco,
                description:
                    'donationcampaigns.each-recipient-chooses-project'
                    | transloco,
                icon: 'heroicons_outline:gift',
                value: false
            },
            {
                text: 'donationcampaigns.global-envelope' | transloco,
                description:
                    'donationcampaigns.recipients-vote-for-preferred-project'
                    | transloco,
                icon: 'heroicons_outline:envelope',
                value: true
            }
        ]"
        [value]="formGroup.controls.isOneEnvelope.value"
    ></bslk-card-toggle>

    <div
        *ngIf="!formGroup.controls.isOneEnvelope.value"
        class="flex items-center gap-2"
    >
        <div class="flex-1 flex items-center gap-1">
            <div class="flex flex-col grow gap-1">
                <bslk-info-label class="font-bold">{{
                    "donationcampaigns.number-of-gift-donations" | transloco
                }}</bslk-info-label>
                <mat-form-field
                    class="grow"
                    [subscriptSizing]="'dynamic'"
                    [floatLabel]="'always'"
                >
                    <input
                        matInput
                        type="number"
                        [formControlName]="'numberOfDonations'"
                        [placeholder]="'donationcampaigns.ten' | transloco"
                        required
                    />
                    <mat-icon
                        matSuffix
                        class="text-current"
                        [svgIcon]="'heroicons_outline:gift'"
                    ></mat-icon>
                </mat-form-field>
            </div>
        </div>
        <div class="flex-1 flex flex-col gap-1">
            <bslk-info-label class="font-bold">{{
                "donationcampaigns.unit-price-of-gift" | transloco
            }}</bslk-info-label>
            <mat-form-field
                class="w-full"
                [subscriptSizing]="'dynamic'"
                [floatLabel]="'always'"
            >
                <input
                    matInput
                    type="number"
                    [formControlName]="'donationAmount'"
                    [placeholder]="'donationcampaigns.twenty' | transloco"
                    required
                />
                <mat-icon
                    matSuffix
                    class="text-current"
                    [svgIcon]="'mat_outline:euro'"
                ></mat-icon>
            </mat-form-field>
        </div>
    </div>

    <div
        *ngIf="formGroup.controls.isOneEnvelope.value"
        class="flex flex-col grow gap-1"
    >
        <bslk-info-label class="font-bold">{{
            "donationcampaigns.envelope-amount" | transloco
        }}</bslk-info-label>
        <mat-form-field
            class="grow"
            [subscriptSizing]="'dynamic'"
            [floatLabel]="'always'"
        >
            <input
                matInput
                type="number"
                [formControlName]="'donationAmount'"
                [placeholder]="'donationcampaigns.two-thousand' | transloco"
                required
            />
            <mat-icon
                matSuffix
                class="text-current"
                [svgIcon]="'mat_outline:euro'"
            ></mat-icon>
        </mat-form-field>
    </div>

    <div
        [class.justify-end]="!formGroup.controls.isPaid.value"
        [class.justify-between]="formGroup.controls.isPaid.value"
        class="flex items-center"
    >
        <div
            *ngIf="formGroup.controls.isPaid.value"
            class="flex grow justify-center"
        >
            <fuse-alert [type]="'info'">
                {{ "donationcampaigns.cant-edit" | transloco }}
            </fuse-alert>
        </div>

        <div
            class="flex flex-col gap-1 border-dashed p-2 border-primary bg-primary-50 rounded-xl border-2 min-w-100"
        >
            <span class="font-bold">{{
                "donationcampaigns.campaign-cost" | transloco
            }}</span>
            <div class="flex items-center gap-10 justify-between">
                <div class="flex flex-col">
                    <span>{{
                        "donationcampaigns.total-cost" | transloco
                    }}</span>
                    <span>{{
                        "donationcampaigns.net-cost-after-tax-deduction"
                            | transloco
                    }}</span>
                </div>
                <div class="flex flex-col">
                    <span
                        ><span class="font-bold text-lg">
                            {{ price.total }}</span
                        >
                        €</span
                    >
                    <span
                        ><span class="font-bold text-lg">
                            {{ price.afterTaxDeductible }}</span
                        >
                        €</span
                    >
                </div>
            </div>
        </div>
    </div>
</div>
