import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { BslkSize } from '@bslk/types/bslk-types';

export type BslkMenuItem = {
    icon: string;
    label: string;
    hide: boolean;
    routerLink?: string;
    action?: string;
};

@Component({
    selector: 'bslk-menu',
    standalone: true,
    templateUrl: './menu.component.html',
    imports: [
        MatButtonModule,
        MatProgressSpinnerModule,
        NgIf,
        NgFor,
        MatMenuModule,
        RouterLink,
        MatIconModule,
    ],
})
export class BslkMenuComponent {
    @Input() label: string;
    @Input() icon: string;
    @Input() iconSize: BslkSize = 'medium';
    @Input() items: BslkMenuItem[];

    @Output() onAction: EventEmitter<string> = new EventEmitter<string>();

    onClick(item: BslkMenuItem) {
        if (item.action) {
            this.onAction.emit(item.action);
        }
    }
}
