import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { DonationCampaignListActions } from './donation-campaign-list.actions';
import { DonationCampaignListState } from './donation-campaign-list.state';

export const initialState: DonationCampaignListState = {
    ...ressourceListInitialState,
};

const additionalActions = [];

export const donationCampaignListReducer = createRessourceListReducer(
    ressourceListInitialState,
    DonationCampaignListActions,
    additionalActions
);
