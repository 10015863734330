import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import {
    BslkAdvancedSelectSyncComponent,
    BslkAdvancedSelectSyncInput,
} from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { EActivityField } from 'app/api';
import { ProjectFormService } from 'app/shared/services/form/project-form.service';
import { TranslationService } from 'app/shared/services/transloco/translation.service';
import { QUILL_MODULES } from 'app/shared/utils/constants.utils';
import { QuillEditorComponent } from 'ngx-quill';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'create-project-step-0',
    standalone: true,
    templateUrl: './create-project-step-0.component.html',
    imports: [
        AsyncPipe,
        BslkAdvancedSelectSyncComponent,
        BslkInfoLabelComponent,
        LetDirective,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
        BslkLengthInfoComponent,
        QuillEditorComponent,
        BslkClassicToggleComponent,
    ],
})
export class CreateProjectStep0Component implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    EActivityField = EActivityField;
    quillModules = QUILL_MODULES;

    activityFieldsTranslationsLoaded: boolean = false;
    activityFieldsAdvancedSelectItems: BslkAdvancedSelectSyncInput[];

    constructor(
        private translationService: TranslationService,
        private projectFormService: ProjectFormService
    ) {}

    get formGroup() {
        return (
            this.projectFormService.getFormGroup ??
            this.projectFormService.initForm()
        );
    }

    ngOnInit(): void {
        this.translationService
            .getTranslatedEnum(EActivityField, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.activityFieldsAdvancedSelectItems = translatedItems;
                this.activityFieldsTranslationsLoaded = true;
            });
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }

    ngOnDestroy() {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
