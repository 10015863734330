import { on } from '@ngrx/store';
import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { MissionListActions } from './mission-list.actions';
import { MissionListState } from './mission-list.state';

export const initialState: MissionListState = {
    ...ressourceListInitialState,
    cities: [],
    isLoadingCities: false,
};

const additionalActions = [
    on(MissionListActions.PublishRequest, (state: MissionListState, { id }) => {
        return {
            ...state,
            selectedItemId: id,
        };
    }),
    on(MissionListActions.LoadCities, (state: MissionListState) => {
        return {
            ...state,
            cities: [],
            isLoadingCities: true,
        };
    }),
    on(
        MissionListActions.LoadCitiesSuccess,
        (state: MissionListState, { cities }) => {
            return {
                ...state,
                cities,
                isLoadingCities: false,
            };
        }
    ),
    on(MissionListActions.LoadCitiesFail, (state: MissionListState) => {
        return {
            ...state,
            isLoadingCities: false,
        };
    }),
];

export const missionListReducer = createRessourceListReducer(
    ressourceListInitialState,
    MissionListActions,
    additionalActions
);
