import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MissionSubtaskViewModel, MissionTaskViewModel } from 'app/api';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';

@Injectable({
    providedIn: 'root',
})
export class MissionEditTasksService {
    private formGroups: FormGroup[];

    constructor(protected fb: FormBuilder) {
        this.formGroups = [this.createTaskFormGroup()];
    }

    get tasksFormGroups() {
        return this.formGroups;
    }

    private createTaskFormGroup(task?: MissionTaskViewModel) {
        return this.fb.group({
            title: [task?.title ?? '', [Validators.required]],
            subtasks: this.fb.array(
                task?.subtasks.map((st) => this.createSubtaskFormGroup(st)) ?? [
                    this.createSubtaskFormGroup(),
                ],
                Validators.minLength(1)
            ),
        });
    }

    private createSubtaskFormGroup(subtask?: MissionSubtaskViewModel) {
        return this.fb.group({
            id: [subtask?.id ?? 0],
            title: [subtask?.title ?? '', Validators.required],
            isCompleted: [subtask?.isCompleted ?? false],
        });
    }

    addTaskFormGroup() {
        this.formGroups.push(this.createTaskFormGroup());
    }

    addSubtaskFormGroup(formGroup: FormGroup) {
        let subtasksFormArray = formGroup.controls.subtasks as FormArray;
        subtasksFormArray.push(this.createSubtaskFormGroup());
    }

    updateTasks(tasks: MissionTaskViewModel[]) {
        this.formGroups = tasks.map((t) => this.createTaskFormGroup(t));
    }

    removeTaskFormGroup(formGroup: FormGroup) {
        this.formGroups = this.formGroups.filter((f) => f !== formGroup);
    }

    removeSubtaskFormGroup(formGroup: FormGroup, index: number) {
        let subtasksFormArray = formGroup.controls.subtasks as FormArray;
        subtasksFormArray.removeAt(index);
    }

    getTasks(): MissionSubtaskViewModel[] {
        return this.formGroups.map(
            (f) =>
                returnOnlyNonNullFields<MissionTaskViewModel>(
                    f.value
                ) as MissionTaskViewModel
        );
    }
}
