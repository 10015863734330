<fuse-card class="py-6 px-3 flex flex-col">
    <!-- View type -->
    <div class="flex justify-between items-center">
        <span class="font-bold">{{
            "missions.filters.view-by" | transloco
        }}</span>
        <div class="items-center flex">
            <mat-icon
                (click)="changeView('grid')"
                [class.text-warn]="isGridView"
                class="icon-size-8 cursor-pointer mr-1"
                [svgIcon]="'mat_solid:apps'"
            >
            </mat-icon>
            <span class="mr-2" *ngIf="isGridView">{{
                "missions.filters.grid" | transloco
            }}</span>

            <mat-icon
                (click)="changeView('row')"
                [class.text-warn]="isRowView"
                class="icon-size-8 cursor-pointer mr-1"
                [svgIcon]="'mat_solid:format_list_bulleted'"
            >
            </mat-icon>
            <span *ngIf="isRowView">{{
                "missions.filters.list" | transloco
            }}</span>
        </div>
    </div>

    <div class="border-b w-full my-4"></div>

    <div class="flex flex-col justify-between items-center">
        <div class="flex w-full justify-between">
            <span class="font-bold">{{
                "missions.filters.filter-by" | transloco
            }}</span>
            <span
                *ngIf="hasActiveFilters"
                (click)="removeAllFilters()"
                class="cursor-pointer"
                >{{ "missions.filters.clear-filters" | transloco }}</span
            >
        </div>
        <div
            class="flex flex-wrap w-full mt-2 -mb-2"
            *ngIf="hasActiveFilters; else noFilters"
        >
            <bslk-tag
                class="mb-2 mr-2 cursor-pointer overflow-hidden"
                *ngFor="let activeFilter of activeFilters"
                (click)="removeFilter(activeFilter)"
            >
                <div class="truncate">
                    <span *ngIf="activeFilter.translocoKey">
                        {{
                            "missions.filters." + activeFilter.translocoKey
                                | transloco
                        }}
                    </span>
                    <span *ngIf="activeFilter.name">
                        {{ activeFilter.name }}
                    </span>
                </div>
                <mat-icon
                    class="icon-size-5 text-white ml-1"
                    [svgIcon]="'heroicons_outline:x-mark'"
                >
                </mat-icon>
            </bslk-tag>
        </div>
        <ng-template #noFilters>
            <span class="flex items-center">
                <mat-icon
                    class="icon-size-5 mr-1"
                    [svgIcon]="'heroicons_outline:funnel'"
                >
                </mat-icon>
                {{ "missions.filters.no-active-filters" | transloco }}
            </span>
        </ng-template>
    </div>

    <div class="border-b w-full my-4"></div>

    <checkbox-filter
        *ngIf="skillsTranslationsLoaded"
        [items]="skillsTranslations"
        [selectedValues]="filteredSkills"
        [title]="'missions.filters.skills' | transloco"
        [filterPlaceholder]="'missions.filters.filter-skills' | transloco"
        (filterChanged)="skillsChanged()"
    >
    </checkbox-filter>

    <div class="border-b w-full my-4"></div>

    <checkbox-filter
        *ngIf="missionTypesTranslationsLoaded"
        [items]="missionTypesTranslations"
        [selectedValues]="filteredMissionTypes"
        [title]="'missions.filters.mission-types' | transloco"
        [enableSearch]="false"
        (filterChanged)="missionTypesChanged()"
    >
        <div class="border-b w-full my-2"></div>
        <team-building-filter
            [clearEvent]="clearFiltersSubject"
            (teamBuildingChanged)="teamBuildingChanged()"
        ></team-building-filter>
    </checkbox-filter>

    <div class="border-b w-full my-4"></div>

    <checkbox-filter
        *ngIf="activityFieldsTranslationsLoaded"
        [items]="activityFieldsTranslations"
        [selectedValues]="filteredActivityFields"
        [title]="'missions.filters.activity-fields' | transloco"
        [filterPlaceholder]="
            'missions.filters.filter-activity-fields' | transloco
        "
        (filterChanged)="activityFieldsChanged()"
    >
    </checkbox-filter>

    <div class="border-b w-full my-4"></div>

    <location-filter
        [clearEvent]="clearFiltersSubject"
        (locationChange)="locationsChanged()"
    ></location-filter>

    <div class="border-b w-full my-4"></div>

    <dates-filter
        [clearEvent]="clearFiltersSubject"
        (datesChanged)="datesChanged($event)"
    ></dates-filter>

    <!-- <div class="border-b w-full my-4"></div> -->

    <!-- <availability-filter
        [clearEvent]="clearFiltersSubject"
        (availabilityChanged)="dispatchFiltersAction()"
    ></availability-filter> -->
</fuse-card>
