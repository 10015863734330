/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EFileExtension = 'Folder' | 'Pdf' | 'Jpeg' | 'Png' | 'Txt' | 'Missing';

export const EFileExtension = {
    Folder: 'Folder' as EFileExtension,
    Pdf: 'Pdf' as EFileExtension,
    Jpeg: 'Jpeg' as EFileExtension,
    Png: 'Png' as EFileExtension,
    Txt: 'Txt' as EFileExtension,
    Missing: 'Missing' as EFileExtension
};

