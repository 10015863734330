import { createReducer, on } from "@ngrx/store";
import { HighlightActions } from "./highlight.action";
import { HighlightState } from "./highlight.state";


export const highlightInitialState: HighlightState = {
}

export const highlightReducer = createReducer(
  highlightInitialState,

  on(HighlightActions.createHighlightEvent, (state, { event }) => {
    return {
      ...state,
      highlightedElement: event.highlightedElement
    }
  }),

  on(HighlightActions.stopHighlightEvent, (state) => {
    return {
      ...state,
      highlightedElement: undefined
    }
  }),
);