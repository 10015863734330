import { DatePipe, NgIf } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkPageComponent } from '@bslk/components/dynamic-content/page/page.component';
import { BslkClassicOverlayComponent } from '@bslk/components/overlay/classic-overlay/bslk-classic-overlay/bslk-classic-overlay.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DynamicContentActions } from 'app/store/dynamic-content/dynamic-content.action';
import { DynamicContentSelectors } from 'app/store/dynamic-content/dynamic-content.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'good-news',
    templateUrl: './good-news.component.html',
    standalone: true,
    imports: [
        BslkClassicOverlayComponent,
        NgxSkeletonLoaderModule,
        BslkPageComponent,
        DatePipe,
        LetDirective,
        MatIconModule,
        MatTooltipModule,
        NgIf,
        TranslocoModule,
    ],
})
export class GoodNewsComponent implements AfterViewInit {
    @ViewChild('goodNewsOrigin') goodNewsOriginRef: ElementRef;

    isPanelOpen = false;
    isViewInitialized = false;

    page$ = this.store.select(DynamicContentSelectors.selectGoodNewsOfTheWeek);

    constructor(private cdRef: ChangeDetectorRef, private store: Store) {}

    openPanel(): void {
        this.store.dispatch(
            DynamicContentActions.loadGoodNewsOfTheWeekIfNeeded()
        );
        this.isPanelOpen = true;
    }

    ngAfterViewInit(): void {
        this.isViewInitialized = true;
        this.cdRef.detectChanges();
    }

    onClose() {
        this.isPanelOpen = false;
    }
}
