<div class="grid justify-center grid-col-1 gap-2 md:flex md:justify-between">
    <div class="col-span-1 flex gap-4 items-center">
        <bslk-tag-button
            (click)="openActivityFieldsFilters()"
            [icon]="'feather:grid'"
            [number]="activityFieldsCount$ | async"
            >{{ "projects.activity-fields" | transloco }}</bslk-tag-button
        >
        <bslk-tag-button
            (click)="openLocationsFilters()"
            [icon]="'feather:map-pin'"
            [number]="locationsCount$ | async"
            >{{ "projects.zones-actions" | transloco }}</bslk-tag-button
        >
        <span
            *ngIf="hasAnyActiveFilter$ | async"
            (click)="reset()"
            class="font-bold text-lg cursor-pointer"
            >{{ "projects.reset-filters" | transloco }}</span
        >
    </div>
    <div class="col-span-1 max-w-100 grow">
        <mat-form-field
            class="fuse-mat-dense fuse-mat-rounded w-full"
            [subscriptSizing]="'dynamic'"
        >
            <mat-icon
                class="icon-size-5"
                matPrefix
                [svgIcon]="'heroicons_solid:magnifying-glass'"
            ></mat-icon>
            <input
                matInput
                (input)="searchProject()"
                [(ngModel)]="nameContains"
                [autocomplete]="'off'"
                [placeholder]="'projects.search-project' | transloco"
            />
            <button
                mat-icon-button
                matSuffix
                *ngIf="nameContains"
                (click)="clearSearch()"
            >
                <mat-icon class="mr-1" [svgIcon]="'heroicons_solid:x-mark'">
                </mat-icon>
            </button>
        </mat-form-field>
    </div>
</div>

<activity-fields-filters
    [openEvent]="activityFieldsOpenEvent"
></activity-fields-filters>
<locations-filters [openEvent]="locationsOpenEvent"></locations-filters>
