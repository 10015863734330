/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EDuration = 'OneHour' | 'FewHours' | 'OneDay' | 'TwoToFiveDays' | 'OneToThreeWeeks' | 'Between1MonthAnd6Months' | 'MoreThan6Months';

export const EDuration = {
    OneHour: 'OneHour' as EDuration,
    FewHours: 'FewHours' as EDuration,
    OneDay: 'OneDay' as EDuration,
    TwoToFiveDays: 'TwoToFiveDays' as EDuration,
    OneToThreeWeeks: 'OneToThreeWeeks' as EDuration,
    Between1MonthAnd6Months: 'Between1MonthAnd6Months' as EDuration,
    MoreThan6Months: 'MoreThan6Months' as EDuration
};

