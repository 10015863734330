import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkRewardComponent } from '@bslk/components/reward/reward.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { Action, Store } from '@ngrx/store';
import { RewardValues } from 'app/shared/utils/rewards.utils';

export type OnboardingTask = {
    translocoKey: string;
    completed: boolean;
    disabled?: boolean;
    disabledTooltipTranslocoKey?: string;
    action?: Action;
    rewards?: RewardValues;
};

@Component({
    standalone: true,
    selector: 'onboarding-progress',
    templateUrl: './onboarding-progress.component.html',
    imports: [
        BslkRewardComponent,
        NgIf,
        NgFor,
        MatIconModule,
        TranslocoModule,
        FuseAlertComponent,
        MatTooltipModule,
    ],
})
export class OnboardingProgressComponent {
    constructor(private store: Store) {}

    @Input() tasks: OnboardingTask[];
    @Input() lightMode = false;
    @Input() showTasks = true;
    @Input() actionsBackground = 'bg-warn-100';

    get tasksDone() {
        return this.tasks.filter((t) => t.completed);
    }

    get tasksToDo() {
        return this.tasks.filter((t) => !t.completed);
    }

    get completedPercentage(): number {
        return Math.round((this.tasksDone.length / this.tasks.length) * 100);
    }

    get barColor(): string {
        if (this.completedPercentage >= 75) {
            return 'quaternary';
        }
        if (this.completedPercentage >= 50) {
            return 'warn';
        }
        return 'primary';
    }

    onActionClick(task: OnboardingTask) {
        if (task.action) {
            this.store.dispatch(task.action);
            return;
        }
    }
}
