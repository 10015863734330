import { createReducer, on } from '@ngrx/store';
import { DynamicContentActions } from './dynamic-content.action';
import { DynamicContentState } from './dynamic-content.state';

export const dynamicContentInitialState: DynamicContentState = {
    isLoadingGoodNews: false,
};

export const dynamicContentReducer = createReducer(
    dynamicContentInitialState,

    on(DynamicContentActions.loadGoodNewsOfTheWeekRequest, (state) => {
        return {
            ...state,
            isLoadingGoodNews: true,
        };
    }),
    on(
        DynamicContentActions.loadGoodNewsOfTheWeekRequestSuccess,
        (state, { response }) => {
            return {
                ...state,
                goodNewsOfTheWeek: response,
                isLoadingGoodNews: false,
            };
        }
    ),
    on(DynamicContentActions.loadGoodNewsOfTheWeekRequestFail, (state) => {
        return {
            ...state,
            isLoadingGoodNews: false,
        };
    })
);
