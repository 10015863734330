<div class="bg-tertiary-200 rounded-xl mb-4">
    <div
        class="flex items-center p-3 bg-tertiary text-white rounded-tl-xl rounded-tr-xl"
    >
        <mat-icon
            [svgIcon]="'heroicons_outline:users'"
            class="text-current mr-1"
        ></mat-icon>

        {{ "coursesection.survey-title" | transloco }}
    </div>
    <div class="p-3 relative">
        {{ "coursesection.survey-description" | transloco }}
    </div>
</div>

<span class="text-xl font-bold">{{ section.survey.question }}</span>

<div class="flex flex-col gap-2 mt-4">
    <div
        class="flex justify-between bg-tertiary text-white px-4 py-2 rounded-2xl min-h-[2.5em]"
        *ngFor="let answer of section.survey.answers"
    >
        <span
            *ngIf="
                correctAnswersGuessedIds.includes(answer.id) ||
                triesLeft === 0 ||
                (hasAlreadyCompletedSurvey$ | async)
            "
        >
            {{ answer.synonyms[0] }}
        </span>
        <span
            *ngIf="
                correctAnswersGuessedIds.includes(answer.id) ||
                triesLeft === 0 ||
                (hasAlreadyCompletedSurvey$ | async)
            "
            class="font-bold"
        >
            {{ answer.score }}
        </span>
    </div>
</div>

<div
    *ngIf="!isDone && !(hasAlreadyCompletedSurvey$ | async)"
    class="flex flex-col mt-4"
>
    <div class="flex w-full gap-2">
        <mat-form-field [floatLabel]="'always'" class="grow">
            <mat-label>{{
                "coursesection.survey-proposition" | transloco
            }}</mat-label>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:users'"
                matPrefix
            ></mat-icon>
            <input
                [placeholder]="
                    'coursesection.survey-proposition-placeholder' | transloco
                "
                matInput
                (keydown.enter)="validate()"
                [(ngModel)]="userGuess"
            />
        </mat-form-field>

        <div class="flex items-center text-white gap-2">
            <div
                [class.bg-primary]="triesLeft < 3"
                class="rounded-full border border-primary h-12 w-12 flex items-center justify-center"
            >
                <mat-icon
                    *ngIf="triesLeft < 3"
                    class="text-current"
                    [svgIcon]="'heroicons_solid:x-mark'"
                ></mat-icon>
                <mat-icon
                    *ngIf="triesLeft === 3"
                    class="text-primary"
                    [svgIcon]="'heroicons_solid:heart'"
                ></mat-icon>
            </div>
            <div
                [class.bg-primary]="triesLeft < 2"
                class="rounded-full border border-primary h-12 w-12 flex items-center justify-center"
            >
                <mat-icon
                    *ngIf="triesLeft < 2"
                    class="text-current"
                    [svgIcon]="'heroicons_solid:x-mark'"
                ></mat-icon>
                <mat-icon
                    *ngIf="triesLeft >= 2"
                    class="text-primary"
                    [svgIcon]="'heroicons_solid:heart'"
                ></mat-icon>
            </div>
            <div
                [class.bg-primary]="triesLeft < 1"
                class="rounded-full border border-primary h-12 w-12 flex items-center justify-center"
            >
                <mat-icon
                    *ngIf="triesLeft < 1"
                    class="text-current"
                    [svgIcon]="'heroicons_solid:x-mark'"
                ></mat-icon>
                <mat-icon
                    *ngIf="triesLeft >= 1"
                    class="text-primary"
                    [svgIcon]="'heroicons_solid:heart'"
                ></mat-icon>
            </div>
        </div>
    </div>

    <div class="flex justify-center">
        <button
            [class.grayscale]="userGuess.length === 0"
            (click)="validate()"
            class="w-full shadow-light bg-warn shadow-warn-300 transition-all px-4 md:px-16 py-4 rounded-full flex text-center mx-auto hover:shadow-none transform hover:translate-x-1 hover:translate-y-1"
        >
            <span class="w-full text-xl font-bold text-white">{{
                "coursesection.survey-validate" | transloco
            }}</span>
        </button>
    </div>
</div>
<div
    *ngIf="isDone || (hasAlreadyCompletedSurvey$ | async)"
    class="flex flex-col gap-2 mt-4"
>
    {{ section.survey.explications }}

    <div class="flex flex-col gap-2 mt-4">
        <div
            class="flex justify-between bg-quaternary text-white px-4 py-2 rounded-2xl min-h-[2.5em]"
            *ngFor="let answer of section.survey.trueAnswers | orderByValueDesc"
        >
            <span>
                {{ answer.key }}
            </span>
            <span class="font-bold">
                {{ answer.value }}
            </span>
        </div>
    </div>

    <div class="flex justify-center">
        <next-chapter-section-button
            *ngrxLet="isLastSection$ as isLastSection"
            (click)="nextSection(isLastSection)"
            [text]="'coursesection.continue' | transloco"
        ></next-chapter-section-button>
    </div>
</div>
