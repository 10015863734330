import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { RouterLink } from '@angular/router';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkInfoComponent } from '@bslk/components/info/info.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AssociationViewModel } from 'app/api';
import { CompanyFiltersFormService } from 'app/shared/services/form/company-filters-form.service';
import { AssociationListActions } from 'app/store/association/associations/associations.actions';
import { AssociationListSelectors } from 'app/store/association/associations/associations.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'blacklisted-associations',
    templateUrl: './blacklisted-associations.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkClassicCardComponent,
        BslkInfoComponent,
        BslkTagButtonComponent,
        BslkTagComponent,
        FormsModule,
        LetDirective,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        NgClass,
        NgFor,
        NgIf,
        NgxSkeletonLoaderModule,
        RouterLink,
        TranslocoModule,
    ],
})
export class BlacklistedAssociationsComponent implements OnInit {
    search: string;

    associations$ = this.store.select(AssociationListSelectors.selectData);
    isLoading$ = this.store.select(AssociationListSelectors.selectIsLoading);
    pageIndex$ = this.store.select(AssociationListSelectors.selectPageIndex);
    totalPages$ = this.store.select(AssociationListSelectors.selectTotalPages);
    totalCount$ = this.store.select(AssociationListSelectors.selectTotalCount);
    pageSize$ = this.store.select(AssociationListSelectors.selectPageSize);

    constructor(
        private store: Store,
        private companyFiltersFormService: CompanyFiltersFormService
    ) {}

    get formControl() {
        return this.companyFiltersFormService.getFormGroup.controls
            .blacklistedAssociations;
    }

    ngOnInit() {
        this.loadAssociations();
    }

    addAssociation(association: AssociationViewModel) {
        const newAssociations = [...this.formControl.value, association];
        this.formControl.setValue(newAssociations);
        this.formControl.markAsDirty();
        this.loadAssociations();
    }

    removeAssociation(association: AssociationViewModel) {
        this.formControl.setValue(
            this.formControl.value.filter(
                (a: AssociationViewModel) => a.id !== association.id
            )
        );
        this.formControl.markAsDirty();
        this.loadAssociations();
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            AssociationListActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
    }

    onSearch() {
        this.store.dispatch(
            AssociationListActions.FilterListRequest({
                filters: [{ key: 'nameContains', value: this.search }],
            })
        );
    }

    clearSearch() {
        this.search = '';
        this.onSearch();
    }

    loadAssociations() {
        this.store.dispatch(
            AssociationListActions.FilterListRequest({
                filters: [
                    { key: 'pageSize', value: 8 },
                    {
                        key: 'excludeIds',
                        value: this.formControl.value.map(
                            (a: AssociationViewModel) => a.id
                        ),
                    },
                ],
            })
        );
    }
}
