import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { InteractedWithUsersActions } from './interacted-with-users.actions';
import { InteractedWithUsersState } from './interacted-with-users.state';

export const initialState: InteractedWithUsersState = ressourceListInitialState;

export const interactedWithUsersReducer = createRessourceListReducer(
    ressourceListInitialState,
    InteractedWithUsersActions
);
