import { AsyncPipe, DatePipe, NgClass, NgIf } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ETrackedActivityType } from 'app/api';
import { ActivityService } from 'app/shared/services/activity/activity.service';
import { ChatActions } from 'app/store/chat/chat.action';
import { ChatSelectors } from 'app/store/chat/chat.selectors';

@Component({
    selector: 'messages',
    templateUrl: './messages.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'messages',
    standalone: true,
    imports: [
        MatButtonModule,
        NgIf,
        MatIconModule,
        NgClass,
        RouterLink,
        DatePipe,
        AsyncPipe,
        LetDirective,
    ],
})
export class MessagesComponent implements OnInit {
    unreadCount$ = this.store.select(ChatSelectors.selectUnreadChatsCount);

    constructor(
        private store: Store,
        private activityService: ActivityService
    ) {}
    ngOnInit(): void {
        this.store.dispatch(ChatActions.triggerLoadChatsRequest());
    }

    chatLinkClicked() {
        this.activityService.sendActivityRequest({
            trackedActivityType: ETrackedActivityType.OpenMessages,
        });
    }
}
