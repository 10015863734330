/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EUserRole = 'Regular' | 'CompanyAdmin' | 'AssoAdmin' | 'SuperAdmin' | 'External';

export const EUserRole = {
    Regular: 'Regular' as EUserRole,
    CompanyAdmin: 'CompanyAdmin' as EUserRole,
    AssoAdmin: 'AssoAdmin' as EUserRole,
    SuperAdmin: 'SuperAdmin' as EUserRole,
    External: 'External' as EUserRole
};

