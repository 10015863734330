import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import {
    BslkDragDropEvent,
    BslkDragHoverEvent,
    BslkDropZoneDirective,
} from '@bslk/directives/drop-zone.directive';
import { TranslocoModule } from '@ngneat/transloco';
import { EAwarenessThematic } from 'app/api';
import { AwarenessCampaignFormService } from 'app/shared/services/form/awareness-campaign-form.service';
import { isNil } from 'lodash-es';
import { DateTime } from 'luxon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'awareness-campaign-thematic-configuration',
    templateUrl: './thematic-configuration.component.html',
    standalone: true,
    imports: [
        BslkClassicButtonComponent,
        TranslocoModule,
        NgIf,
        NgxSkeletonLoaderModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatDatepickerModule,
        BslkDropZoneDirective,
        MatInputModule,
        ReactiveFormsModule,
        DragDropModule,
        MatIconModule,
    ],
})
export class AwarenessCampaignThematicConfigurationComponent implements OnInit {
    @Input() index: number;

    unsubscribeAll: Subject<void> = new Subject();
    previousThematic: EAwarenessThematic;
    thematic: EAwarenessThematic;

    constructor(private formService: AwarenessCampaignFormService) {
        this.startDateFilter = this.startDateFilter.bind(this);
        this.endDateFilter = this.endDateFilter.bind(this);
    }
    get formArray() {
        return this.formService.getFormGroup.controls.thematics as FormArray;
    }

    get formGroup() {
        return this.formArray.at(this.index) as FormGroup;
    }

    get thematicFormValue() {
        return this.formGroup.controls.thematic.value;
    }

    get isOnlyThematic() {
        return this.formArray.length === 1;
    }

    // Check if there is a previous thematic and if it has a value so it can swap
    get hasPrevious() {
        return (
            this.index > 0 &&
            !isNil(this.formArray.at(this.index - 1)) &&
            this.thematic
        );
    }

    // Check if there is a next thematic and if it has a value so it can swap
    get hasNext() {
        return (
            this.index + 1 < this.formArray.length &&
            !isNil(this.formArray.at(this.index + 1)) &&
            this.thematic
        );
    }

    // Take minimum start date from previous thematic end date
    get minStartDate() {
        if (this.index === 0) {
            return DateTime.now();
        }
        return (this.formArray.at(this.index - 1) as FormGroup).controls.endDate
            .value;
    }

    // Can only setup dates if previous thematics has dates
    get canSetupDates() {
        if (this.index === 0) {
            return true;
        }
        return this.formArray.controls
            .slice(0, this.index)
            .every(
                (formGroup: FormGroup) =>
                    formGroup.controls.startDate.value?.isValid &&
                    formGroup.controls.endDate.value?.isValid
            );
    }

    ngOnInit(): void {
        this.thematic = this.formGroup.controls.thematic.value;
        this.formGroup.controls.thematic.valueChanges
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((value) => {
                this.thematic = value;
            });

        this.formArray.valueChanges
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.formService.getFormGroup.updateValueAndValidity();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    removeThematic() {
        if (this.isOnlyThematic) {
            this.formGroup.reset();
            return;
        }
        this.formArray.removeAt(this.index);
    }

    onHovered(event: BslkDragHoverEvent): void {
        if (event.hovered) {
            this.previousThematic = this.thematic;
            this.thematic = event.data;
        } else {
            if (this.thematic !== this.thematicFormValue) {
                this.thematic = this.previousThematic;
            }
        }
    }

    onDropped(event: BslkDragDropEvent): void {
        this.setThematic(event.data);
    }

    setThematic(thematic: EAwarenessThematic) {
        this.formGroup.controls.thematic.setValue(thematic);
    }

    startDateFilter(date: DateTime) {
        // Thematics setup in the past can be started at a past date
        const isOldThematic = !isNil(this.formGroup.controls.dateCreated.value);

        const today = DateTime.now();
        const previousEndDates = this.formArray.controls
            .slice(0, this.index)
            .map((control) => control.value.endDate);

        return (
            (date > today || isOldThematic) &&
            previousEndDates.every((endDate) => date > endDate)
        );
    }

    endDateFilter(date: DateTime) {
        // Thematics setup in the past can be ended at a past date
        const isOldThematic = !isNil(this.formGroup.controls.dateCreated.value);

        const startDate = this.formGroup.controls.startDate.value;
        const today = DateTime.now();
        const nextStartDates = this.formArray.controls
            .slice(this.index + 1)
            .map((control) => control.value.startDate)
            .filter(
                (startDate: DateTime) => startDate?.isValid && !isNil(startDate)
            );

        return (
            startDate !== null &&
            date > startDate &&
            (date > today || isOldThematic) &&
            nextStartDates.every((startDate) => date < startDate)
        );
    }

    swapWithNextThematic() {
        const nextThematicFormGroup = this.formArray.at(
            this.index + 1
        ) as FormGroup;
        const nextThematicValue = nextThematicFormGroup.controls.thematic.value;
        nextThematicFormGroup.controls.thematic.setValue(this.thematic);
        this.formGroup.controls.thematic.setValue(nextThematicValue);
    }

    swapWithPreviousThematic() {
        const previousThematicFormGroup = this.formArray.at(
            this.index - 1
        ) as FormGroup;
        const previousThematicValue =
            previousThematicFormGroup.controls.thematic.value;
        previousThematicFormGroup.controls.thematic.setValue(this.thematic);
        this.formGroup.controls.thematic.setValue(previousThematicValue);
    }

    dateChanged() {
        this.formService.getFormGroup.updateValueAndValidity();
    }
}
