import {
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { ResourceListFilter } from 'app/store/shared/ressource-list/ressource-list-state';
import { DateTime } from 'luxon';
import { Observable, Subscription } from 'rxjs';
import { MissionFiltersService } from '../mission-filters.service';

@Component({
    selector: 'dates-filter',
    standalone: true,
    templateUrl: './dates-filter.component.html',
    imports: [
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        FormsModule,
        MatInputModule,
        MatCheckboxModule,
        MatTooltipModule,
        NgClass,
        AsyncPipe,
        NgIf,
        LetDirective,
        NgFor,
        TranslocoModule,
        NgTemplateOutlet,
    ],
})
export class DatesFilterComponent implements OnInit, OnDestroy {
    @Input() clearEvent: Observable<ResourceListFilter[]>;
    @Output() datesChanged: EventEmitter<string> = new EventEmitter<string>();

    private clearEventSubscription: Subscription;

    isExpanded = false;
    startDate: DateTime | null = this.missionFiltersService.getStartDate;
    endDate: DateTime | null = this.missionFiltersService.getEndDate;

    datesValid = true;

    constructor(private missionFiltersService: MissionFiltersService) {}

    ngOnInit() {
        if (this.clearEvent) {
            this.clearEventSubscription = this.clearEvent.subscribe((types) => {
                if (types.includes('startDate')) {
                    this.startDate = null;
                }
                if (types.includes('endDate')) {
                    this.endDate = null;
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.clearEventSubscription) {
            this.clearEventSubscription.unsubscribe();
        }
    }

    toggleIsExpanded() {
        this.isExpanded = !this.isExpanded;
    }

    onDatesChanged(type: 'start' | 'end') {
        if (this.checkIfDatesAreValid()) {
            this.missionFiltersService.setStartDate(this.startDate);
            if (this.endDate) {
                this.endDate = this.endDate.set({ hour: 23, minute: 59 });
            }
            this.missionFiltersService.setEndDate(this.endDate);
            this.datesChanged.emit(type);
        }
    }

    checkIfDatesAreValid() {
        this.datesValid = true;

        if (!this.startDate || !this.endDate) {
            return this.datesValid;
        }
        if (this.startDate > this.endDate) {
            this.datesValid = false;
        }
        return this.datesValid;
    }
}
