import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    ChatViewModel,
    ChatViewModelPaginatedListDto,
    MessageViewModel,
    MessageViewModelPaginatedListDto,
    UserViewModel,
} from 'app/api';

export const ChatActions = createActionGroup({
    source: 'Chat',
    events: {
        'Trigger Load Chats Request': emptyProps(),
        'Load Chats Request': emptyProps(),
        'Load Chats Request Success': props<{
            response: ChatViewModelPaginatedListDto;
        }>(),
        'Load Chats Request Fail': props<{ error: string }>(),

        'Search Chat Request': props<{ search: string }>(),
        'Search Chat Request Success': props<{
            response: ChatViewModelPaginatedListDto;
        }>(),
        'Search Chat Request Fail': props<{ error: string }>(),

        'Select Chat': props<{ chatId: number }>(),
        'Select First Chat': emptyProps(),
        'Acknowledge Selected Chat': emptyProps(),
        'Unselect Chat': emptyProps(),

        'Load Chat Messages Request': emptyProps(),
        'Load Chat Messages Request Success': props<{
            response: MessageViewModelPaginatedListDto;
        }>(),
        'Load Chat Messages Request Fail': props<{ error: string }>(),

        'Load More Chat Messages': emptyProps(),
        'Load Last Chat Messages Request': emptyProps(),

        'Build Message': props<{ text: string }>(),

        'Send Message Request': props<{ message: MessageViewModel }>(),
        'Send Message Request Success': props<{ id: number }>(),
        'Send Message Request Fail': props<{ error: string }>(),

        'Create Chat Request': props<{ chat: ChatViewModel }>(),
        'Create Chat Request Success': props<{
            chatViewModel: ChatViewModel;
        }>(),
        'Create Chat Request Fail': props<{ error: string }>(),

        'Build Chat': props<{ users: UserViewModel[]; currentRoute: string }>(),
        'Build Chat Success': props<{ chat: ChatViewModel }>(),
        'Redirect To Chat': props<{ id: number }>(),
    },
});
