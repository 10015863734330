<div class="flex flex-col">
    <span class="font-bold">
        {{ label }}
    </span>
    <mat-radio-group
        (change)="change($event)"
        [value]="value"
        class="flex justify-center gap-8"
    >
        <mat-radio-button
            [disabled]="isReadonly"
            [value]="true"
            color="primary"
            >{{
                "bslk.form.radio-group-boolean.yes" | transloco
            }}</mat-radio-button
        >
        <mat-radio-button
            [disabled]="isReadonly"
            [value]="false"
            color="primary"
            >{{
                "bslk.form.radio-group-boolean.no" | transloco
            }}</mat-radio-button
        >
    </mat-radio-group>
    <span *ngIf="isInvalidAndTouched" class="text-primary text-sm">{{
        "bslk.form.required" | transloco
    }}</span>
</div>
