<div [formGroup]="formGroup" class="flex flex-col gap-6">
    <bslk-business-metric-configurator [group]="metricsFormArray.controls[0]">
        <bslk-info-label [tooltip]="'createproject.metric-tooltip' | transloco">
            <span class="text-2xl font-bold">{{
                "createproject.metric-1" | transloco
            }}</span></bslk-info-label
        >
    </bslk-business-metric-configurator>
    <bslk-business-metric-configurator [group]="metricsFormArray.controls[1]">
        <bslk-info-label [tooltip]="'createproject.metric-tooltip' | transloco">
            <span class="text-2xl font-bold">{{
                "createproject.metric-2" | transloco
            }}</span></bslk-info-label
        >
    </bslk-business-metric-configurator>
    <bslk-business-metric-configurator [group]="metricsFormArray.controls[2]">
        <bslk-info-label [tooltip]="'createproject.metric-tooltip' | transloco">
            <span class="text-2xl font-bold">{{
                "createproject.metric-3" | transloco
            }}</span></bslk-info-label
        >
    </bslk-business-metric-configurator>
</div>
