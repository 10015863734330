import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AssociationViewModel } from 'app/api';
import { AssociationCreation } from './association.state';

export const AssociationActions = createActionGroup({
    source: 'Association',
    events: {
        'Load Request': props<{ id: number }>(),
        'Load Mine Request': props<{ displayIsLoading: boolean }>(),
        'Load Request Success': props<{ response: AssociationViewModel }>(),
        'Load Request Fail': props<{ error: string }>(),

        'Create Request': props<{ data: AssociationCreation }>(),
        'Create Request Succesful': props<{
            association: AssociationViewModel;
        }>(),
        'Create Request Fail': props<{ error: string }>(),

        'Join Request': props<{ associationId: number }>(),
        'Join Request Success': emptyProps(),
        'Join Request Fail': props<{ error: string }>(),
        'Get Has Pending Request': emptyProps(),
        'Set Has Pending Request': props<{ hasPendingRequest: boolean }>(),

        'Cancel Update': emptyProps(),

        'Update Request': emptyProps(),
        'Update Request Success': props<{
            association: AssociationViewModel;
        }>(),
        'Update Request Fail': props<{ error: string }>(),

        'Update Logo Request': props<{ file: File }>(),
        'Update Logo Success': props<{ url: string }>(),
        'Update Logo Fail': props<{ error: string }>(),

        'Update Cover Request': props<{ file: File }>(),
        'Update Cover Success': props<{ url: string }>(),
        'Update Cover Fail': props<{ error: string }>(),

        'Reset Errors': emptyProps(),
    },
});
