<div class="mt-2 flex items-center justify-between">
    <div>
        <span>{{ user.firstName + " " + user.familyName }}</span>
        <div class="text-sm">
            <span *ngIf="user.jobTitle">
                {{ user.jobTitle }}
                {{ "missionmanage.at" | transloco }}
            </span>
            <span>{{ user.company.name }}</span>
        </div>
    </div>
    <div *ngIf="showActions" class="flex-none flex items-center my-auto">
        <button
            (click)="onButtonClick(true)"
            class="bg-quaternary shadow-xl rounded-xl p-2 items-center flex mr-3"
        >
            <mat-icon
                class="icon-size-5 text-white"
                [svgIcon]="'heroicons_solid:check'"
            ></mat-icon>
        </button>
        <button
            (click)="onButtonClick(false)"
            class="bg-primary shadow-xl rounded-xl p-2 items-center flex"
        >
            <mat-icon
                class="icon-size-5 text-white"
                [svgIcon]="'heroicons_solid:x-mark'"
            ></mat-icon>
        </button>
    </div>
</div>
