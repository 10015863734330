/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ETimePeriod = 'Day' | 'Week' | 'Month';

export const ETimePeriod = {
    Day: 'Day' as ETimePeriod,
    Week: 'Week' as ETimePeriod,
    Month: 'Month' as ETimePeriod
};

