import {
    KeyValuePipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FormControlComponent } from '@bslk/components/form/form-control-component';
import { BslkClassicOverlayComponent } from '@bslk/components/overlay/classic-overlay/bslk-classic-overlay/bslk-classic-overlay.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { BslkJustify } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';
import { ESustainableDevelopmentGoal } from 'app/api';
import { TranslationService } from 'app/shared/services/transloco/translation.service';
import { sustainableDevelopmentGoals } from 'app/shared/utils/constants.utils';
import { remove } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'bslk-business-sdg-picker',
    templateUrl: './sdg-picker.component.html',
    standalone: true,
    imports: [
        BslkTagComponent,
        NgFor,
        NgIf,
        NgClass,
        KeyValuePipe,
        NgTemplateOutlet,
        TranslocoModule,
        MatIconModule,
        BslkClassicOverlayComponent,
        BslkClassicButtonComponent,
    ],
})
export class BslkBusinessSdgPickerComponent
    extends FormControlComponent
    implements OnInit, OnDestroy
{
    @Input() isSingularValue = false;
    @Input() columnDisplay = false;
    @Input() asOverlay = false;
    @Input() reduceNonSelectedOpacity = false;
    @Input() justify: BslkJustify = 'center';
    @Input() displayValueInField = false;
    @Input() displayNonSelectedLabel = false;

    @ViewChild('pickerOrigin') pickerOrigin: ElementRef;

    unsubscribeAll: Subject<any> = new Subject<any>();
    isPanelOpen = false;
    sustainableDevelopmentGoals = sustainableDevelopmentGoals;

    constructor(
        private translationService: TranslationService,
        private cdRef: ChangeDetectorRef
    ) {
        super();
    }

    get pickedSustainableDevelopmentGoals() {
        return Object.entries(this.sustainableDevelopmentGoals).reduce(
            (acc, [key, value]) => {
                if (this.isSingularValue && this.value === key) {
                    acc[key] = value;
                } else if (!this.isSingularValue && this.value?.includes(key)) {
                    acc[key] = value;
                }
                return acc;
            },
            {} as Record<string, any>
        );
    }

    get notPickedSustainableDevelopmentGoals() {
        return Object.entries(this.sustainableDevelopmentGoals).reduce(
            (acc, [key, value]) => {
                if (this.isSingularValue && this.value !== key) {
                    acc[key] = value;
                } else if (
                    !this.isSingularValue &&
                    !this.value?.includes(key)
                ) {
                    acc[key] = value;
                }
                return acc;
            },
            {} as Record<string, any>
        );
    }

    get isEverySdgSelected() {
        return (
            Object.keys(this.notPickedSustainableDevelopmentGoals).length === 0
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.translationService
            .getTranslatedEnum(ESustainableDevelopmentGoal, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                translatedItems.forEach((i) => {
                    sustainableDevelopmentGoals[i.value].translation = i.name;
                });
            });
        this.cdRef.detectChanges();
    }

    sdgClicked(sdg: ESustainableDevelopmentGoal) {
        const v = structuredClone(this.value) as ESustainableDevelopmentGoal[];
        if (this.isSingularValue) {
            this.setValue(sdg);
        } else {
            if (v.includes(sdg)) {
                remove(v, (item) => item === sdg);
                this.setValue(v.filter((i) => sdg !== i));
            } else {
                v.push(sdg);
                this.setValue(v);
            }
        }
        if (this.asOverlay) {
            this.isPanelOpen = false;
        }
    }

    openPanel(): void {
        this.isPanelOpen = true;
    }

    onClose() {
        this.isPanelOpen = false;
    }

    ngOnDestroy() {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
