import { DatePipe } from '@angular/common';
import {
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
} from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';
import { provideFuse } from '@fuse';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { environment } from 'environments/environment';
import { ApiModule, Configuration, ConfigurationParameters } from './api';
import { GlobalErrorHandler } from './shared/services/errors/global-error-handler.service';
import { provideNgrxStore } from './store/store.provider';

export function apiConfigFactory(): Configuration {
    const params: ConfigurationParameters = {
        basePath: environment.apiBaseUrl,
        withCredentials: true,
    };
    return new Configuration(params);
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),
        provideAuth(),
        provideNgrxStore(),
        importProvidersFrom(ApiModule.forRoot(apiConfigFactory)),
        // Material Date Adapter
        DatePipe,
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'dd/MM/yy',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco(),

        // Custom global error handler
        { provide: ErrorHandler, useClass: GlobalErrorHandler },

        // Fuse
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
            },
            fuse: {
                layout: 'classy',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'basilik',
                themes: [
                    {
                        id: 'basilik',
                        name: 'basilik',
                    },
                ],
            },
        }),
    ],
};
