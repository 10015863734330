import { createReducer, on } from '@ngrx/store';
import { SetupActions } from './setup.action';
import { SetupState } from './setup.state';

export const setupInitialState: SetupState = {
    isLoadingAdministrativeContactUpdate: false,
    isLoadingCompany: false,
};

export const setupReducer = createReducer(
    setupInitialState,

    on(SetupActions.loadCompanyRequest, (state) => {
        return {
            ...state,
            isLoadingCompany: true,
        };
    }),
    on(SetupActions.loadCompanyRequestSuccess, (state, { response }) => {
        return {
            ...state,
            company: response,
            isLoadingCompany: false,
        };
    }),
    on(SetupActions.loadCompanyRequestFail, (state) => {
        return {
            ...state,
            isLoadingCompany: false,
        };
    }),

    on(SetupActions.updateAdministrativeContactRequest, (state) => {
        return {
            ...state,
            isLoadingAdministrativeContactUpdate: true,
        };
    }),
    on(SetupActions.updateAdministrativeContactRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingAdministrativeContactUpdate: false,
        };
    }),
    on(SetupActions.updateAdministrativeContactRequestFail, (state) => {
        return {
            ...state,
            isLoadingAdministrativeContactUpdate: false,
        };
    })
);
