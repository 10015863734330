import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkSize } from '@bslk/types/bslk-types';

@Component({
    selector: 'bslk-round-icon',
    standalone: true,
    templateUrl: './round-icon.component.html',
    imports: [MatIconModule, NgIf, MatTooltipModule, NgClass],
})
export class BslkRoundIconComponent {
    @Input() icon: string;
    @Input() tooltip: string;
    @Input() color: string = 'green';
    @Input() size: BslkSize = 'medium';
    @Input() isButton = false;
}
