import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    MissionService,
    MissionSlotProposalDto,
    MissionSlotService,
    MissionTaskService,
} from 'app/api';
import { ConfirmEstimationDialogService } from 'app/modules/regular/mission/manage/state/confirm-estimation-dialog/confirm-estimation-dialog.service';
import {
    EAssociationUrl,
    ERegularUrl,
    UrlHelpers,
} from 'app/shared/utils/url-helpers.utils';
import { isNil } from 'lodash-es';
import { catchError, exhaustMap, filter, map, of, tap } from 'rxjs';
import { UserSelectors } from '../../user/user/user.selectors';
import { MissionRatingActions } from '../mission-rating/mission-rating.action';
import { MissionSelectors } from '../mission/mission.selectors';
import { MissionManageActions } from './mission-manage.action';
import { MissionManageSelectors } from './mission-manage.selectors';
@Injectable()
export class MissionManageEffects {
    loadMissionManage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.loadMissionManageRequest),
            exhaustMap(({ id, missionSlotId }) => {
                return this.missionService.missionIdManageGet({ id }).pipe(
                    map((response) =>
                        MissionManageActions.loadMissionManageRequestSuccess({
                            mission: response,
                            missionSlotId,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            MissionManageActions.loadMissionManageRequestFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    reloadOnMissionStateChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                MissionManageActions.acceptUserInSlotRequestSuccess,
                MissionManageActions.denyUserInSlotRequestSuccess,
                MissionManageActions.acceptValidationSlotRequestSuccess,
                MissionManageActions.denyValidationSlotRequestSuccess,
                MissionManageActions.validateSlotRequestSuccess,
                MissionManageActions.deleteMissionSlotRequestSuccess,
                MissionManageActions.addTeamBuildingUserRequestSuccess,
                MissionManageActions.removeTeamBuildingUserRequestSuccess,
                MissionRatingActions.postRatingRequestSuccess
            ),
            concatLatestFrom(() =>
                this.store.select(MissionManageSelectors.selectMission)
            ),
            filter(([, mission]) => !isNil(mission)),
            map(([, mission]) =>
                MissionManageActions.loadMissionManageRequest({
                    id: mission.id,
                })
            )
        )
    );

    registerToMissionSlot$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.registerMissionSlotRequest),
            concatLatestFrom(() =>
                this.store.select(UserSelectors.selectUserId)
            ),
            exhaustMap(([{ id }, userId]) => {
                return this.missionSlotService
                    .missionSlotIdRegisterPatch({ id })
                    .pipe(
                        map(() =>
                            MissionManageActions.registerMissionSlotRequestSuccess(
                                {
                                    slotId: id,
                                    userId: userId,
                                }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.registerMissionSlotRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    proposeMissionSlot$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.proposeMissionSlotRequest),
            concatLatestFrom(() => [
                this.store.select(UserSelectors.selectUserId),
                this.store.select(MissionSelectors.selectMission),
            ]),
            exhaustMap(([{ missionSlot }, userId, mission]) => {
                const missionSlotProposalDto: MissionSlotProposalDto = {
                    missionId: mission.id,
                    userId: userId,
                    missionSlotProposal: missionSlot,
                };
                return this.missionSlotService
                    .missionSlotProposePatch({ missionSlotProposalDto })
                    .pipe(
                        map((missionSlotId) =>
                            MissionManageActions.proposeMissionSlotRequestSuccess(
                                {
                                    slotId: missionSlotId,
                                }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.proposeMissionSlotRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    unregisterToMissionSlot$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.unregisterMissionSlotRequest),
            concatLatestFrom(() =>
                this.store.select(MissionManageSelectors.selectSelectedSlotId)
            ),
            filter(([, selectedSlotId]) => !isNil(selectedSlotId)),
            exhaustMap(([, selectedSlotId]) => {
                return this.missionSlotService
                    .missionSlotIdUnregisterPatch({ id: selectedSlotId })
                    .pipe(
                        map(() =>
                            MissionManageActions.unregisterMissionSlotRequestSuccess()
                        ),
                        tap(() => {
                            this.router.navigate([
                                UrlHelpers.getRegularUrl(
                                    ERegularUrl.RegularDashboardPage
                                ),
                            ]);
                        }),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.unregisterMissionSlotRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    acceptUserInMissionSlot$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.acceptUserInSlotRequest),
            concatLatestFrom(() =>
                this.store.select(MissionManageSelectors.selectSelectedSlotId)
            ),
            exhaustMap(([{ userId }, selectedSlotId]) => {
                return this.missionSlotService
                    .missionSlotIdAssociationAcceptUserIdPatch({
                        id: selectedSlotId,
                        userId,
                    })
                    .pipe(
                        map(() =>
                            MissionManageActions.acceptUserInSlotRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.acceptUserInSlotRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    denyUserInMissionSlot$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.denyUserInSlotRequest),
            concatLatestFrom(() =>
                this.store.select(MissionManageSelectors.selectSelectedSlotId)
            ),
            exhaustMap(([{ userId }, selectedSlotId]) => {
                return this.missionSlotService
                    .missionSlotIdAssociationDenyUserIdPatch({
                        id: selectedSlotId,
                        userId,
                    })
                    .pipe(
                        map(() =>
                            MissionManageActions.denyUserInSlotRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.denyUserInSlotRequestFail({
                                    error: httpResponse?.error ?? httpResponse,
                                })
                            )
                        )
                    );
            })
        )
    );

    addUserToTeamBuilding$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.addTeamBuildingUserRequest),
            concatLatestFrom(() =>
                this.store.select(MissionManageSelectors.selectSelectedSlotId)
            ),
            exhaustMap(([{ userId }, selectedSlotId]) => {
                return this.missionSlotService
                    .missionSlotIdManagerAddUserIdPatch({
                        id: selectedSlotId,
                        userId,
                    })
                    .pipe(
                        map(() =>
                            MissionManageActions.addTeamBuildingUserRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.addTeamBuildingUserRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    removeUserFromTeamBuilding$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.removeTeamBuildingUserRequest),
            concatLatestFrom(() =>
                this.store.select(MissionManageSelectors.selectSelectedSlotId)
            ),
            exhaustMap(([{ userId }, selectedSlotId]) => {
                return this.missionSlotService
                    .missionSlotIdManagerRemoveUserIdPatch({
                        id: selectedSlotId,
                        userId,
                    })
                    .pipe(
                        map(() =>
                            MissionManageActions.removeTeamBuildingUserRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.removeTeamBuildingUserRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    estimateHoursIfNeededForValidation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.validateSlot),
            concatLatestFrom(() => [
                this.store.select(
                    MissionManageSelectors.selectSelectedSlotIsGroupSlot
                ),
                this.store.select(
                    MissionManageSelectors.selectSelectedSlotEstimatedHours
                ),
            ]),
            map(([, isGroupSlot, estimatedTimeInHours]) => {
                if (!isGroupSlot) {
                    this.confirmEstimationDialogService.open({
                        isAdmin: false,
                        estimatedTimeInHours,
                    });
                    return;
                }
                return MissionManageActions.validateSlotRequest({
                    estimatedTimeInHours: null,
                });
            }),
            filter((action) => !!action)
        )
    );

    userValidateMissionSlot$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.validateSlotRequest),
            concatLatestFrom(() =>
                this.store.select(MissionManageSelectors.selectSelectedSlotId)
            ),
            exhaustMap(([{ estimatedTimeInHours }, selectedSlotId]) => {
                return this.missionSlotService
                    .missionSlotIdValidationPatch({
                        id: selectedSlotId,
                        missionSlotValidationDto: {
                            estimatedTimeInHours,
                        },
                    })
                    .pipe(
                        map(() =>
                            MissionManageActions.validateSlotRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.validateSlotRequestFail({
                                    error: httpResponse?.error ?? httpResponse,
                                })
                            )
                        )
                    );
            })
        )
    );

    acceptUserValidationInMissionSlot$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.acceptValidationSlotRequest),
            concatLatestFrom(() =>
                this.store.select(MissionManageSelectors.selectSelectedSlotId)
            ),
            exhaustMap(([{ userId, estimatedTimeInHours }, selectedSlotId]) => {
                return this.missionSlotService
                    .missionSlotIdValidationAcceptPatch({
                        id: selectedSlotId,
                        missionSlotValidationDto: {
                            userId,
                            estimatedTimeInHours,
                        },
                    })
                    .pipe(
                        map(() =>
                            MissionManageActions.acceptValidationSlotRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.acceptValidationSlotRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    denyUserValidationInMissionSlot$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.denyValidationSlotRequest),
            concatLatestFrom(() =>
                this.store.select(MissionManageSelectors.selectSelectedSlotId)
            ),
            exhaustMap(([{ userId }, selectedSlotId]) => {
                return this.missionSlotService
                    .missionSlotIdValidationDenyPatch({
                        id: selectedSlotId,
                        missionSlotValidationDto: {
                            userId,
                        },
                    })
                    .pipe(
                        map(() =>
                            MissionManageActions.denyValidationSlotRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.denyValidationSlotRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    updateMissionTasks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.updateMissionTasksRequest),
            concatLatestFrom(() =>
                this.store.select(MissionManageSelectors.selectMission)
            ),
            filter(([, mission]) => !isNil(mission)),
            exhaustMap(([{ tasks }, mission]) => {
                // Transform tasks to flat subtasks
                const subtasks = structuredClone(tasks).flatMap((t) =>
                    t.subtasks.map((st) => {
                        st.parent = t;
                        st.parent.subtasks = null;
                        return st;
                    })
                );
                return this.missionTaskService
                    .missionTaskMissionIdPatch({
                        missionId: mission.id,
                        missionSubtaskViewModel: subtasks,
                    })
                    .pipe(
                        map((response) =>
                            MissionManageActions.updateMissionTasksRequestSuccess(
                                { tasks: response }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.updateMissionTasksRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    updateMissionTaskCompletionStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                MissionManageActions.updateMissionTaskCompletionStatusRequest
            ),
            exhaustMap(({ id, isCompleted }) => {
                return this.missionTaskService
                    .missionTaskIdCompletionStatusPatch({
                        id,
                        body: isCompleted,
                    })
                    .pipe(
                        map(() =>
                            MissionManageActions.updateMissionTaskCompletionStatusRequestSuccess(
                                { id, isCompleted }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.updateMissionTaskCompletionStatusRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    deleteSelectedMissionSlot$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionManageActions.deleteMissionSlotRequest),
            concatLatestFrom(() => [
                this.store.select(MissionManageSelectors.selectSelectedSlotId),
                this.store.select(MissionManageSelectors.selectMissionSlots),
            ]),
            filter(([, selectedSlotId]) => !isNil(selectedSlotId)),
            exhaustMap(([, selectedSlotId, missionSlots]) => {
                return this.missionSlotService
                    .missionSlotIdDelete({ id: selectedSlotId })
                    .pipe(
                        map(() =>
                            MissionManageActions.deleteMissionSlotRequestSuccess(
                                { wasOnlySlot: missionSlots.length === 1 }
                            )
                        ),
                        tap(({ wasOnlySlot }) => {
                            if (wasOnlySlot) {
                                this.router.navigate([
                                    UrlHelpers.getAssociationUrl(
                                        EAssociationUrl.AssociationMissionsPage
                                    ),
                                ]);
                            }
                        }),
                        catchError((httpResponse) =>
                            of(
                                MissionManageActions.deleteMissionSlotRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private confirmEstimationDialogService: ConfirmEstimationDialogService,
        private missionService: MissionService,
        private missionSlotService: MissionSlotService,
        private missionTaskService: MissionTaskService,
        private router: Router,
        private store: Store
    ) {}
}
