<div class="w-full flex items-center justify-center">
    <div class="flex items-center justify-center gap-4 relative">
        <ng-container *ngFor="let animation of animations">
            <div
                [@flyInOut]="{
                    value: '',
                    params: { xFinal: animation.x, yFinal: animation.y }
                }"
                [ngClass]="{
                        'text-tertiary': color === 'tertiary',
                        'text-warn': color === 'warn',
                    }"
                class="absolute z-90 truncate font-bold flex gap-1 items-center"
                [style.left]="'50%'"
                [style.top]="'50%'"
                [class.text-xl]="animation.value <= 100"
                [class.text-3xl]="animation.value > 100"
            >
                <span>+{{ animation.value }}</span>
                <mat-icon [svgIcon]="icon" class="text-current"></mat-icon>
            </div>
        </ng-container>
        <div class="flex gap-3 items-center">
            <div
                [ngClass]="{
                        'shadow-tertiary': color === 'tertiary',
                        'shadow-warn': color === 'warn',
                    }"
                class="bg-white rounded-2xl py-2 px-4 shadow-light flex items-center"
            >
                <mat-icon
                    [svgIcon]="icon"
                    [ngClass]="{
                        'text-tertiary': color === 'tertiary',
                        'text-warn': color === 'warn',
                    }"
                    class="icon-size-10 mr-1"
                ></mat-icon>
                <span class="font-bold text-xl"
                    >{{ Math.round(displayedValue) }}
                </span>
            </div>
        </div>
    </div>
</div>
