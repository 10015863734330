<ng-container *ngrxLet="allUserProgressions$ as allUserProgressions">
    <base-kpi-panel
        *ngrxLet="isLoading$ as isLoading"
        [title]="'companyadmindashboard.kpis.awareness-progression' | transloco"
        [kpiName]="kpiName"
        [isExpandable]="false"
        [isExpanded]="true"
        [isLoading]="isLoading"
    >
        <div class="flex items-center mb-2" headerActions>
            <mat-form-field
                class="fuse-mat-dense fuse-mat-rounded w-full min-w-64 xl:min-w-80"
                [subscriptSizing]="'dynamic'"
            >
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:magnifying-glass'"
                ></mat-icon>
                <input
                    matInput
                    (input)="searchEmployee()"
                    [(ngModel)]="search"
                    [autocomplete]="'off'"
                    [placeholder]="
                        'companyadmindashboard.search-employee' | transloco
                    "
                />
                <button
                    mat-icon-button
                    matSuffix
                    *ngIf="search"
                    (click)="clearSearch()"
                >
                    <mat-icon class="mr-1" [svgIcon]="'heroicons_solid:x-mark'">
                    </mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div
            *ngIf="
                !isLoading &&
                allUserProgressions &&
                allUserProgressions.totalCount > 0
            "
            expandedContent
        >
            <div class="relative overflow-x-scroll">
                <table class="w-full text-left mt-1">
                    <thead
                        class="bg-warn shadow-outside text-lg shadow-warn-50 text-white"
                    >
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                {{
                                    "companyadmindashboard.employee" | transloco
                                }}
                            </th>
                            <th scope="col" class="px-6 py-3 max-w-32 truncate">
                                {{ "companyadmindashboard.level" | transloco }}
                            </th>
                            <th
                                *ngFor="
                                    let thematic of getThematics(
                                        allUserProgressions.data
                                    )
                                "
                                scope="col"
                                class="px-6 py-3"
                            >
                                {{
                                    "enums.EAwarenessThematic." + thematic
                                        | transloco
                                }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let progression of allUserProgressions.data"
                            class="bg-white border-b"
                        >
                            <th
                                scope="row"
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <div class="flex flex-wrap gap-1">
                                    {{ progression.user.name }}
                                </div>
                            </th>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                <div
                                    class="px-4 py-2 font-bold bg-primary-100 text-primary rounded-xl w-min"
                                >
                                    {{
                                        getLevel(
                                            progression.user.experiencePoints
                                        )
                                    }}
                                </div>
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                                *ngFor="
                                    let percentage of progression.thematicPercentages
                                        | keyvalue
                                "
                            >
                                <bslk-classic-progress-bar
                                    [progress]="percentage.value"
                                ></bslk-classic-progress-bar>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <mat-paginator
                class="bg-warn-100"
                [ngClass]="{
                    'pointer-events-none': isLoading
                }"
                [length]="allUserProgressions.totalCount"
                [pageIndex]="allUserProgressions.pageIndex"
                [pageSize]="allUserProgressions.pageSize"
                [hidePageSize]="true"
                (page)="pageChangeEvent($event)"
                [showFirstLastButtons]="false"
            ></mat-paginator>
        </div>

        <div
            expandedContent
            *ngIf="!isLoading && !allUserProgressions"
            class="flex flex-col justify-center gap-3 h-full py-8"
        >
            <mat-icon
                [svgIcon]="'heroicons_outline:academic-cap'"
                class="icon-size-16 mx-auto"
            ></mat-icon>
            <span class="text-xl font-weight mt-2 text-center">{{
                "companyadmindashboard.no-campaign-configured" | transloco
            }}</span>
        </div>
    </base-kpi-panel>
</ng-container>
