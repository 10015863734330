<div class="flex flex-col gap-1">
    <span class="font-bold">{{ progress }}%</span>
    <div class="bg-gray-200 w-full rounded h-2">
        <div
            [style]="'width:' + progress + '%'"
            [class.bg-primary]="color === 'primary'"
            [class.bg-warn]="color === 'warn'"
            [class.bg-quaternary]="color === 'quaternary'"
            [class]="'h-full rounded'"
        ></div>
    </div>
</div>
