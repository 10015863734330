import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MissionUnregisteredViewModel } from 'app/api';
import { CreateMissionDialogInput } from 'app/modules/association/create-mission/create-mission.component';

export const MissionActions = createActionGroup({
    source: 'Mission',
    events: {
        'Open Dialog': props<{ input: CreateMissionDialogInput }>(),
        'Close Dialog': emptyProps(),

        'Copy Location': props<{ checked: boolean }>(),

        'Set Cover Request': props<{ file: File }>(),
        'Set Cover Success': emptyProps(),
        'Update Cover Request Success': emptyProps(),
        'Update Cover Request Fail': props<{ error: string }>(),

        'Edit Mission': emptyProps(),
        'Update Mission Request': emptyProps(),
        'Update Mission Request Success': emptyProps(),
        'Update Mission Request Fail': props<{ error: string }>(),

        'Register Mission': emptyProps(),

        'Create Mission Request': emptyProps(),
        'Create Mission Request Success': props<{ id: number }>(),
        'Create Mission Request Fail': props<{ error: string }>(),

        'Copy Mission Request': props<{ id: number }>(),
        'Copy Mission Request Success': emptyProps(),
        'Copy Mission Request Fail': props<{ error: string }>(),

        'Load Mission Request': props<{ id: number }>(),
        'Load Mission Request Success': props<{
            mission: MissionUnregisteredViewModel;
        }>(),
        'Load Mission Request Fail': props<{ error: string }>(),
    },
});
