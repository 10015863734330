<auth-template
    class="w-full"
    [title]="'signup.sign-up' | transloco"
    *ngrxLet="isLoading$ as isLoading"
>
    <ng-container *ngIf="!isLoading; else loading">
        <div *ngIf="needsToLinkProfessionalEmail$ | async" class="mt-4">
            <fuse-alert *transloco="let t" [type]="'info'" class="mb-4">
                <div class="flex flex-col gap-1.5">
                    <p>
                        {{ t("signup.needs-to-link-professional-email-1", { 
                    name: firstName$ | async,                  
                    email: email$ | async,
                })}}
                    </p>

                    <p>
                        {{
                            "signup.needs-to-link-professional-email-2"
                                | transloco
                        }}
                    </p>
                </div>
            </fuse-alert>
            <fuse-alert [type]="'warn'" class="my-4">
                {{ "signup.needs-to-link-professional-email-3" | transloco }}
            </fuse-alert>

            <mat-form-field [floatLabel]="'always'" class="w-full">
                <mat-label>{{
                    "signup.professional-email" | transloco
                }}</mat-label>
                <input
                    [placeholder]="
                        (emailPlaceholderPrefix$ | async) +
                        ('signup.professional-email-placeholder' | transloco)
                    "
                    autocomplete="given-name"
                    matInput
                    [formControl]="professionalEmailFormControl"
                />
                <mat-error>
                    {{ "signup.email-required" | transloco }}
                </mat-error>
            </mat-form-field>

            <div class="flex mt-2">
                <bslk-classic-button
                    (onClick)="validateProfessionalEmail()"
                    class="w-full"
                    [size]="'large'"
                    [label]="'signup.continue' | transloco"
                    [isLoading]="isLoading"
                    [isDisabled]="
                        isSubmitted && professionalEmailFormControl.invalid
                    "
                ></bslk-classic-button>
            </div>
        </div>

        <div *ngIf="needsToValidateEmail$ | async" class="mt-4">
            <fuse-alert
                class="flex justify-center"
                [appearance]="'outline'"
                [type]="'info'"
            >
                {{ "signup.validate-email" | transloco }}
            </fuse-alert>
        </div>

        <div class="mt-4" *ngrxLet="signUpError$ as signUpError">
            <fuse-alert
                class="mb-4 flex justify-center"
                *ngIf="signUpError"
                [appearance]="'outline'"
                [showIcon]="true"
                [type]="'error'"
                [@shake]="true"
            >
                {{ "signup.errors." + signUpError | transloco }}
            </fuse-alert>
        </div>
    </ng-container>

    <div *ngrxLet="isSignedIn$ as isSignedIn" footer>
        <ng-container *ngIf="!isSignedIn">
            <div class="flex justify-center items-baseline font-medium">
                {{ "signup.already-registered" | transloco }} &nbsp;
                <a class="font-bold" [routerLink]="'/sign-in'">
                    <button mat-flat-button>
                        {{ "signup.sign-in" | transloco }}
                    </button>
                </a>
            </div>
        </ng-container>
        <ng-container *ngIf="isSignedIn">
            <div class="flex justify-center items-baseline font-medium">
                <a class="font-bold" [routerLink]="'/sign-out'">{{
                    "signup.sign-out" | transloco
                }}</a>
            </div>
        </ng-container>

        <div
            class="flex justify-center items-baseline mt-0.5 text-sm text-gray-500"
        >
            *{{ "signup.required-information" | transloco }}
        </div>
    </div>
</auth-template>

<ng-template #loading>
    <div class="flex flex-col justify-center items-center pt-12 pb-8">
        <mat-progress-spinner
            [diameter]="72"
            [mode]="'indeterminate'"
        ></mat-progress-spinner>
    </div>
</ng-template>
