import { createSelector } from '@ngrx/store';
import { AppState } from '..';

const selectState = (state: AppState) => state.dynamicContent;

const selectGoodNewsOfTheWeek = createSelector(
    selectState,
    (state) => state.goodNewsOfTheWeek
);

const selectIsGoodNewsOfTheWeekLoaded = createSelector(
    selectState,
    (state) => state.goodNewsOfTheWeek
);

const selectIsLoadingGoodNews = createSelector(
    selectState,
    (state) => state.isLoadingGoodNews
);

export const DynamicContentSelectors = {
    selectGoodNewsOfTheWeek,
    selectIsGoodNewsOfTheWeekLoaded,
    selectIsLoadingGoodNews,
};
