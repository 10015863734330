import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AvailableFilters, EActivityField } from 'app/api';

export const ProjectListFiltersActions = createActionGroup({
    source: 'ProjectListFilters',
    events: {
        'Load Available Filters Request': emptyProps(),
        'Load Available Filters Request Success': props<{
            availableFilters: AvailableFilters;
        }>(),
        'Load Available Filters Request Failure': props<{ error: string }>(),

        'Set Name Contains': props<{ nameContains: string }>(),
        'Reset Name Contains': emptyProps(),

        'Add Activity Field': props<{ activityField: EActivityField }>(),
        'Remove Activity Field': props<{ activityField: EActivityField }>(),

        'Add Location': props<{ location: string }>(),
        'Remove Location': props<{ location: string }>(),

        'Reset all': emptyProps(),
    },
});
