import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';

export type RemoveSlotDialogEvent = 'all' | 'single';

@Component({
    selector: 'remove-slot-dialog',
    standalone: true,
    templateUrl: './remove-slot-dialog.component.html',
    imports: [
        BslkClassicButtonComponent,
        BaseDialogComponent,
        MatDialogModule,
        FormsModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatIconModule,
        FuseAlertComponent,
        MatRadioModule,
        TranslocoModule,
        TranslocoModule,
    ],
})
export class RemoveSlotDialogComponent {
    @Output() onConfirm: EventEmitter<RemoveSlotDialogEvent> =
        new EventEmitter<RemoveSlotDialogEvent>();

    selectedOption: RemoveSlotDialogEvent = 'single';

    constructor(private dialogRef: MatDialogRef<RemoveSlotDialogComponent>) {}

    confirm() {
        this.onConfirm.emit(this.selectedOption);
        this.close();
    }

    close() {
        this.dialogRef.close();
    }
}
