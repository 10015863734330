import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions, EventMountArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoService } from '@ngneat/transloco';
import { MissionRegisteredViewModel } from 'app/api';
import { DateTime } from 'luxon';

@Component({
    selector: 'regular-dashboard-calendar',
    standalone: true,
    templateUrl: './regular-dashboard-calendar.component.html',
    styleUrls: ['regular-dashboard-calendar.component.scss'],
    imports: [FullCalendarModule, FuseCardComponent, MatTooltipModule],
    encapsulation: ViewEncapsulation.None,
})
export class RegularDashboardCalendarComponent implements OnInit {
    @Input() missions: MissionRegisteredViewModel[];

    calendarOptions: CalendarOptions;

    constructor(private translocoService: TranslocoService) {}

    get currentDate() {
        return DateTime.now().toFormat('d MMMM y');
    }

    ngOnInit(): void {
        this.calendarOptions = {
            plugins: [dayGridPlugin, interactionPlugin],
            locale: this.translocoService.getActiveLang(),
            headerToolbar: {
                left: 'prev,next',
                center: 'title',
                right: '',
            },
            height: 'auto',
            contentHeight: 'auto',
            dayHeaderFormat: { weekday: 'narrow' },
            initialView: 'dayGridMonth',
            fixedWeekCount: false,
            showNonCurrentDates: false,
            events: this.buildCalendarEvents(),
            eventDidMount: (info: EventMountArg) => this.attachTooltip(info),
        };
    }

    // Builds only one event per day maximum, only way I found to limit event rows to one and also to prevent ugly event spanning over multiple days
    buildCalendarEvents() {
        const events = {};
        this.missions.forEach((mission) => {
            if (mission.startDate && mission.endDate) {
                let currentDate = new Date(mission.startDate);
                const endDate = new Date(mission.endDate);

                while (currentDate <= endDate) {
                    const dateStr = currentDate.toISOString().split('T')[0];

                    if (!events[dateStr]) {
                        events[dateStr] = {
                            description: mission.name,
                            start: dateStr,
                            allDay: true,
                            url: '#/missions/' + mission.id,
                        };
                    }
                    currentDate = new Date(
                        currentDate.setDate(currentDate.getDate() + 1)
                    );
                }
            }
        });

        return Object.values(events);
    }

    attachTooltip(info: EventMountArg) {
        if (info.event.extendedProps.description) {
            const tooltipText = info.event.extendedProps.description;
            info.el.setAttribute('title', tooltipText);
        }
    }
}
