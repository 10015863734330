import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MissionRatingActions = createActionGroup({
    source: 'MissionRating',
    events: {
        'Post Rating Request': emptyProps(),
        'Post Rating Request Success': emptyProps(),
        'Post Rating Request Fail': props<{ error: string }>(),
    },
});
