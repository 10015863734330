import { createReducer, on } from '@ngrx/store';
import { ELoginType } from 'app/api';
import { generateGUID } from 'app/shared/utils/guid.utils';
import { updateLocalStorageUser } from 'app/shared/utils/local-storage.utils';
import { CourseActions } from 'app/store/course/course/course.action';
import { produce } from 'immer';
import { isEmpty } from 'lodash-es';
import { AssociationActions } from '../../association/association/association.action';
import { UserActions } from './user.actions';
import { UserState } from './user.state';

export const initialState: UserState = {
    isLoading: false,
    isLoadingSignature: false,
    isLoadingReminderConfiguration: false,
    isLoadingUnsubscribe: false,
    loginFailed: false,
    hasGainedRewards: false,
    hasUnsubscribed: false,
};

export const userReducer = createReducer(
    initialState,
    on(UserActions.signedInWithGoogle, (state, { user }) => {
        return {
            ...state,
            tokenId: user?.idToken,
            loginError: null,
            isLoading: true,
        };
    }),
    on(UserActions.signedInWithMicrosoft, (state) => {
        return {
            ...state,
            loginError: null,
            isLoading: true,
        };
    }),
    on(UserActions.signedInWithLinkedIn, (state) => {
        return {
            ...state,
            loginError: null,
            isLoading: true,
        };
    }),
    on(UserActions.signedInWithEmail, (state) => {
        return {
            ...state,
            loginError: null,
            isLoading: true,
        };
    }),
    on(UserActions.signInSuccess, (state, { session }) => {
        return {
            ...state,
            sessionInfo: session,
            loginFailed: false,
            isLoading: false,
        };
    }),

    on(UserActions.signInFailure, (state, { error }) => {
        return {
            ...state,
            loginFailed: true,
            isLoading: false,
            loginError: error,
        };
    }),
    on(UserActions.alreadySignedIn, (state, { session }) => {
        return {
            ...state,
            sessionInfo: session,
        };
    }),
    on(UserActions.signOut, (state) => {
        return {
            ...state,
            sessionInfo: null,
        };
    }),
    on(UserActions.startBuildingUser, (state, { user }) => {
        return {
            ...state,
            builtUser: user,
        };
    }),
    on(UserActions.signUpUserWithGoogle, (state, { user }) => {
        return {
            ...state,
            signUpError: null,
            isLoading: true,
            builtUser: {
                ...state.builtUser,
                firstName: user.firstName,
                familyName: user.lastName,
                email: user.email,
                loginType: ELoginType.Google,
                token: user.idToken,
                location: { id: 0 },
            },
        };
    }),
    on(UserActions.signUpUserWithMicrosoft, (state, { user }) => {
        return {
            ...state,
            signUpError: null,
            isLoading: true,
            builtUser: {
                ...state.builtUser,
                firstName: user.idTokenClaims['given_name'],
                familyName: user.idTokenClaims['family_name'],
                email: user.idTokenClaims['email'],
                loginType: ELoginType.Microsoft,
                token: user.idToken,
                location: { id: 0 },
            },
        };
    }),
    on(UserActions.signUpUserWithLinkedIn, (state, { code, stateGuid }) => {
        return {
            ...state,
            signUpError: null,
            isLoading: true,
            builtUser: {
                ...state.builtUser,
                // LinkedIn code doesn't provide email, only the token fetched from the server does
                email: generateGUID(),
                loginType: ELoginType.LinkedIn,
                token: code,
                location: { id: 0 },
                state: stateGuid,
            },
        };
    }),
    on(
        UserActions.signUpUserWithLinkedInWithProfessionalEmailLinked,
        (state, { professionalEmail, stateGuid }) => {
            return {
                ...state,
                signUpError: null,
                isLoading: true,
                builtUser: {
                    ...state.builtUser,
                    email: professionalEmail,
                    loginType: ELoginType.LinkedIn,
                    // Validated access token has been sent from the API
                    token: state.sessionInfo.user.token,
                    personalEmailForSso: state.sessionInfo.user.email,
                    location: { id: 0 },
                    state: stateGuid,
                },
            };
        }
    ),
    on(UserActions.signUpUserWithEmail, (state, { user }) => {
        return {
            ...state,
            signUpError: null,
            builtUser: user,
            isLoading: true,
            location: { id: 0 },
        };
    }),
    on(UserActions.signUpSuccess, (state, { session }) => {
        return {
            ...state,
            isLoading: false,
            sessionInfo: session,
        };
    }),
    on(UserActions.signUpFailure, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            signUpError: error,
        };
    }),
    on(UserActions.resetErrors, (state) => {
        return {
            ...state,
            loginError: null,
            signUpError: null,
        };
    }),

    on(UserActions.updateRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(
        UserActions.updateRequestSuccess,
        (state, { user, hasGainedRewards }) => {
            let sessionInfo = structuredClone(state.sessionInfo);
            let updatedUser = structuredClone(user);
            // Don't override avatar url
            if (sessionInfo.user.avatarUrl) {
                updatedUser.avatarUrl = state.sessionInfo.user.avatarUrl;
            }
            if (
                !isEmpty(user.signatureBase64) ||
                sessionInfo.user.hasSignature
            ) {
                updatedUser.hasSignature = true;
                updatedUser.signatureBase64 = null;
            }
            sessionInfo.user = updatedUser;

            updateLocalStorageUser(updatedUser);
            return {
                ...state,
                isLoading: false,
                sessionInfo,
                hasGainedRewards,
            };
        }
    ),

    on(UserActions.updateRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(UserActions.updateAvatarRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(UserActions.updateAvatarRequestSuccess, (state, { url }) => {
        const sessionInfo = structuredClone(state.sessionInfo);
        sessionInfo.user.avatarUrl = url;
        updateLocalStorageUser(sessionInfo.user);
        return {
            ...state,
            isLoading: false,
            sessionInfo,
        };
    }),

    on(UserActions.updateAvatarRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(UserActions.updatePasswordRequest, (state) => {
        return {
            ...state,
            isLoading: true,
            updatePasswordError: null,
        };
    }),

    on(UserActions.updatePasswordRequestSuccess, (state) => {
        return {
            ...state,
            isLoading: false,
            updatePasswordError: null,
        };
    }),

    on(UserActions.updatePasswordRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            updatePasswordError: error,
        };
    }),

    on(AssociationActions.createRequestSuccesful, (state, { association }) => {
        const sessionInfo = structuredClone(state.sessionInfo);
        sessionInfo.user.association = association;
        return {
            ...state,
            sessionInfo,
        };
    }),

    on(UserActions.switchRoleRequestSuccess, (state, { newRole }) => {
        const sessionInfo = structuredClone(state.sessionInfo);
        sessionInfo.user.userRole = newRole;
        updateLocalStorageUser(sessionInfo.user);
        return {
            ...state,
            sessionInfo,
        };
    }),

    on(UserActions.loadSignatureRequest, (state) => {
        return {
            ...state,
            isLoadingSignature: true,
        };
    }),

    on(UserActions.loadSignatureRequestSuccess, (state, { url }) => {
        return {
            ...state,
            isLoadingSignature: false,
            signatureUrl: url,
        };
    }),

    on(UserActions.loadSignatureRequestFail, (state) => {
        return {
            ...state,
            isLoadingSignature: false,
        };
    }),

    on(UserActions.deleteSignature, (state) => {
        const sessionInfo = structuredClone(state.sessionInfo);
        sessionInfo.user.hasSignature = false;
        updateLocalStorageUser(sessionInfo.user);
        return {
            ...state,
            sessionInfo,
        };
    }),

    on(UserActions.loadReminderConfigurationRequest, (state) => {
        return {
            ...state,
            isLoadingReminderConfiguration: true,
        };
    }),

    on(
        UserActions.loadReminderConfigurationRequestSuccess,
        (state, { configuration }) => {
            return {
                ...state,
                isLoadingReminderConfiguration: false,
                reminderConfiguration: configuration,
            };
        }
    ),

    on(UserActions.loadReminderConfigurationRequestFail, (state) => {
        return {
            ...state,
            isLoadingReminderConfiguration: false,
        };
    }),

    on(UserActions.updateReminterConfigurationValue, (state, { key, value }) =>
        produce(state, (draftState) => {
            draftState.reminderConfiguration.isSubscribed[key] = value;
        })
    ),
    on(UserActions.updateReminderConfigurationRequest, (state) => {
        return {
            ...state,
            isLoadingReminderConfiguration: true,
        };
    }),

    on(UserActions.updateReminderConfigurationRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingReminderConfiguration: false,
        };
    }),

    on(UserActions.updateReminderConfigurationRequestFail, (state) => {
        return {
            ...state,
            isLoadingReminderConfiguration: false,
        };
    }),

    // Rewards update
    on(UserActions.setHasGainedRewards, (state) => {
        return {
            ...state,
            hasGainedRewards: true,
        };
    }),
    on(CourseActions.updateChapterCompletionRequestSuccess, (state) => {
        return {
            ...state,
            hasGainedRewards: true,
        };
    }),
    on(
        UserActions.updateUserRewards,
        (state, { purposeCoins, experiencePoints }) => {
            const sessionInfo = structuredClone(state.sessionInfo);
            sessionInfo.user.experiencePoints = experiencePoints;
            sessionInfo.user.purposeCoins = purposeCoins;
            updateLocalStorageUser(sessionInfo.user);
            return {
                ...state,
                sessionInfo,
            };
        }
    ),

    on(UserActions.unsubscribeRequest, (state) => {
        return {
            ...state,
            isLoadingUnsubscribe: true,
        };
    }),

    on(UserActions.unsubscribeRequestFail, (state) => {
        return {
            ...state,
            isLoadingUnsubscribe: false,
        };
    }),

    on(UserActions.unsubscribeRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingUnsubscribe: false,
            hasUnsubscribed: true,
        };
    })
);
