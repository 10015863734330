import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DualQuizChallengeService, DualQuizChallengeViewModel } from 'app/api';
import { NewChallengeDialogService } from 'app/modules/shared/dual-quiz/header/new-challenge-dialog/new-challenge-dialog.service';
import { ESharedUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import {
    createLoadListRequestEffect,
    createReloadEffect,
} from 'app/store/shared/ressource-list/ressource-list-effects-creator';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { DualQuizChallengesActions } from './challenges.action';
import { DualQuizChallengesSelectors } from './challenges.selectors';

@Injectable()
export class DualQuizChallengesEffects {
    loadChallenges$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.dualQuizChallengeService,
        DualQuizChallengesActions,
        DualQuizChallengesSelectors,
        this.dualQuizChallengeService.dualQuizChallengeMeGet
    );

    reload$ = createReloadEffect(this.actions$, DualQuizChallengesActions);

    newChallenge$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DualQuizChallengesActions.NewChallengeRequest),
            exhaustMap(
                ({
                    challengeeEmail,
                    challengeeId,
                    thematic,
                    secondsPerQuestion,
                    questionsCount,
                }) => {
                    const dualQuizChallengeViewModel: DualQuizChallengeViewModel =
                        {
                            challengeeEmail,
                            challengeeId,
                            thematic,
                            secondsPerQuestion,
                            questionsCount,
                        };
                    return this.dualQuizChallengeService
                        .dualQuizChallengePost({
                            dualQuizChallengeViewModel,
                        })
                        .pipe(
                            map((id) =>
                                DualQuizChallengesActions.NewChallengeRequestSuccess(
                                    { id }
                                )
                            ),
                            catchError((httpResponse) =>
                                of(
                                    DualQuizChallengesActions.NewChallengeRequestFail(
                                        {
                                            error:
                                                httpResponse?.error ??
                                                httpResponse.toString(),
                                        }
                                    )
                                )
                            )
                        );
                }
            )
        )
    );

    redirectOnNewChallengeSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DualQuizChallengesActions.NewChallengeRequestSuccess),
                tap(({ id }) => {
                    const challengePageUrl = UrlHelpers.getSharedUrl(
                        ESharedUrl.SharedDualQuizChallengePage,
                        { id }
                    );
                    this.router.navigateByUrl(challengePageUrl);
                    this.newChallengeDialogService.close();
                })
            ),
        { dispatch: false }
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private router: Router,
        private dualQuizChallengeService: DualQuizChallengeService,
        private newChallengeDialogService: NewChallengeDialogService
    ) {}
}
