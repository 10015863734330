import { TextFieldModule } from '@angular/cdk/text-field';
import {
    AsyncPipe,
    DatePipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import {
    MissionRegisteredViewModel,
    MissionUnregisteredViewModel,
} from 'app/api';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { ERegularUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MissionAvailabilityComponent } from '../availability/mission-availability.component';

@Component({
    selector: 'mission-row-card',
    standalone: true,
    templateUrl: './mission-row-card.component.html',
    imports: [
        AsyncPipe,
        DatePipe,
        FuseCardComponent,
        LetDirective,
        MatButtonModule,
        MatChipsModule,
        MatDividerModule,
        MatExpansionModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        MissionAvailabilityComponent,
        NgClass,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        NgxSkeletonLoaderModule,
        RouterLink,
        TextFieldModule,
        TranslocoModule,
    ],
})
export class MissionRowCardComponent {
    @Input() mission: MissionRegisteredViewModel | MissionUnregisteredViewModel;

    constructor(private datePipe: DatePipe, private router: Router) {}

    formatDateRange(startDate: string, endDate: string): string {
        return formatDateRange(this.datePipe, startDate, endDate);
    }

    view() {
        this.router.navigateByUrl(
            UrlHelpers.getRegularUrl(ERegularUrl.RegularMissionPage, {
                id: this.mission.id,
            })
        );
    }
}
