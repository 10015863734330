import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkRoundIconComponent } from '@bslk/components/round-icon/round-icon.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { MissionSlotUserViewModel, UserViewModel } from 'app/api';
import { UserSlotViewModel } from 'app/api/model/userSlotViewModel';
import {
    ConfirmEstimationDialogComponent,
    ConfirmEstimationDialogInput,
} from 'app/modules/regular/mission/manage/state/confirm-estimation-dialog/confirm-estimation-dialog.component';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { SingleSelectAsyncComponent } from 'app/shared/components/form/select/async/single-select-async.component';
import { ChatActions } from 'app/store/chat/chat.action';
import { MissionManageActions } from 'app/store/mission/mission-manage/mission-manage.action';
import { MissionManageSelectors } from 'app/store/mission/mission-manage/mission-manage.selectors';
import { CompanyUsersActions } from 'app/store/user/company-users/company-users.actions';
import { CompanyUsersSelectors } from 'app/store/user/company-users/company-users.selectors';
import { ManagedUsersActions } from 'app/store/user/managed-users/managed-users.actions';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { isNil } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import {
    MissionMemberRequestComponent,
    MissionMemberRequestEvent,
} from './request/mission-member-request.component';

@Component({
    selector: 'mission-members',
    standalone: true,
    templateUrl: './mission-members.component.html',
    imports: [
        AsyncPipe,
        BslkClassicCardComponent,
        BslkRoundIconComponent,
        BslkTagComponent,
        SingleSelectAsyncComponent,
        ClassicAvatarComponent,
        LetDirective,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MissionMemberRequestComponent,
        NgFor,
        NgIf,
        FuseAlertComponent,
        TranslocoModule,
    ],
})
export class MissionMembersComponent {
    @Input() members: UserSlotViewModel[];
    @Input() isAdmin: boolean = false;

    isLoading$ = this.store.select(
        MissionManageSelectors.selectIsLoadingUserValidation
    );

    isGroupSlot$ = this.store.select(
        MissionManageSelectors.selectSelectedSlotIsGroupSlot
    );

    estimatedHours$ = this.store.select(
        MissionManageSelectors.selectSelectedSlotEstimatedHours
    );

    alreadyRegisteredUserIds$ = this.store.select(
        MissionManageSelectors.selectSelectedSlotUserIds
    );

    slotIsFull$ = this.store.select(
        MissionManageSelectors.selectSelectedSlotIsFull
    );

    spotsLeft$ = this.store.select(
        MissionManageSelectors.selectSelectedSlotSpotsLeft
    );

    isManager$ = this.store.select(UserSelectors.selectIsManager);

    isTeamBuilding$ = this.store.select(
        MissionManageSelectors.selectIsTeamBuilding
    );

    isPaidTeamBuilding$ = this.store.select(
        MissionManageSelectors.selectIsPaidTeamBuilding
    );

    minSpotsToFill$ = this.store.select(
        MissionManageSelectors.selectPaidTeamBuildingMinSpotsToFill
    );

    paidTeamBuildingPrice$ = this.store.select(
        MissionManageSelectors.selectPaidTeamBuildingPrice
    );

    paidTeamBuildingCurrency$ = this.store.select(
        MissionManageSelectors.selectPaidTeamBuildingCurrency
    );

    user$ = this.store.select(UserSelectors.selectUser);

    unsubscribeAll: Subject<any> = new Subject<any>();
    isAddingUsers = false;
    CompanyUsersActions = CompanyUsersActions;
    CompanyUsersSelectors = CompanyUsersSelectors;
    addedUserFormControl = new FormControl<number | null>(null);

    constructor(
        private store: Store,
        private dialog: MatDialog,
        private route: ActivatedRoute
    ) {}

    get pendingAcceptations() {
        return this.members.filter((m) => !m.isAcceptedByAssociation);
    }

    get hasPendingAcceptation() {
        return this.pendingAcceptations.length > 0;
    }

    get pendingValidations() {
        return this.members.filter(
            (m) => !m.isValidatedByAssociation && m.isValidatedByUser
        );
    }

    get hasPendingValidation() {
        return this.pendingValidations.length > 0;
    }

    get hasMembers() {
        return this.members.length > 0;
    }

    buildChat(user: UserViewModel) {
        this.store.dispatch(
            ChatActions.buildChat({
                users: [user],
                currentRoute: this.route.snapshot.toString(),
            })
        );
    }

    onAcceptationAnswer(event: MissionMemberRequestEvent) {
        if (event.isAccepted) {
            this.store.dispatch(
                MissionManageActions.acceptUserInSlotRequest({
                    userId: event.userId,
                })
            );
            return;
        }
        this.store.dispatch(
            MissionManageActions.denyUserInSlotRequest({
                userId: event.userId,
            })
        );
    }

    onValidationAnswer(
        event: MissionMemberRequestEvent,
        isGroupSlot: boolean,
        estimatedTimeInHours: number
    ) {
        if (event.isAccepted) {
            // If it's a solo slot, admin is asked if hours estimation is correct and can change it
            if (!isGroupSlot) {
                this.dialog.open(ConfirmEstimationDialogComponent, {
                    data: {
                        isAdmin: true,
                        userId: event.userId,
                        estimatedTimeInHours,
                    } as ConfirmEstimationDialogInput,
                });
            } else {
                this.store.dispatch(
                    MissionManageActions.acceptValidationSlotRequest({
                        userId: event.userId,
                    })
                );
            }
        } else {
            this.store.dispatch(
                MissionManageActions.denyValidationSlotRequest({
                    userId: event.userId,
                })
            );
        }
    }

    startAddingUsers() {
        this.isAddingUsers = true;
        this.store.dispatch(ManagedUsersActions.Initialize());
        this.addedUserFormControl.valueChanges
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((v) => {
                this.addUser(v);
                this.addedUserFormControl.setValue(null, {
                    emitEvent: false,
                });
            });
    }

    addUser(userId: number) {
        this.store.dispatch(
            MissionManageActions.addTeamBuildingUserRequest({ userId })
        );
    }

    removeUser(userId: number) {
        this.store.dispatch(
            MissionManageActions.removeTeamBuildingUserRequest({ userId })
        );
    }

    canRemoveUser(
        missionSlotUser: MissionSlotUserViewModel,
        user: UserViewModel
    ) {
        return (
            missionSlotUser.addedByUserId === user.id ||
            (missionSlotUser.user.managerEmail === user.email &&
                !isNil(missionSlotUser.addedByUserId))
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
