import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkSize } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'bslk-info',
    standalone: true,
    templateUrl: './info.component.html',
    imports: [MatIconModule, TranslocoModule, MatTooltipModule, NgClass],
})
export class BslkInfoComponent {
    @Input() tooltip: string;
    @Input() size: BslkSize = 'small';
}
