import { AsyncPipe } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions, EventClickArg, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoService } from '@ngneat/transloco';
import { MissionSlotViewModel } from 'app/api';
import { MissionFormService } from 'app/shared/services/form/mission-form.service';
import {
    getAdditionalValueLodash as getAdditionalValue,
    hasExactlyOneAdditionalValue,
} from 'app/shared/utils/array.utils';
import { areStringDatesOnSameDay } from 'app/shared/utils/date-helpers.utils';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MissionSlotBuilderService } from '../mission-slot-builder.service';
import CreateMissionEditSlotComponent from './edit-current-slot/create-mission-edit-slot.component';

@Component({
    selector: 'create-mission-calendar',
    standalone: true,
    templateUrl: './create-mission-calendar.component.html',
    styleUrls: ['../../../../../../../styles/full-calendar.scss'],
    imports: [
        FullCalendarModule,
        FuseCardComponent,
        MatTooltipModule,
        CreateMissionEditSlotComponent,
        AsyncPipe,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class CreateMissionCalendarComponent implements OnInit, OnDestroy {
    calendarOptions: CalendarOptions;
    private eventSubject: BehaviorSubject<EventInput[]> = new BehaviorSubject<
        EventInput[]
    >([]);
    private missionSlotsSubscription: Subscription;

    calendarEvents: Observable<EventInput[]> = this.eventSubject.asObservable();

    selectedMissionSlotGuid: string;
    // Used to detect new slots to auto-select them
    knownMissionSlotGuids: string[] = [];

    // Used to draw hover events (so we remove real events while draving hover event)
    knownEvents: EventInput[] = [];

    constructor(
        private translocoService: TranslocoService,
        private missionFormService: MissionFormService,
        private missionSlotBuilderService: MissionSlotBuilderService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    get missionSlotsFormArray() {
        return this.missionFormService.getFormGroup.controls
            .missionSlots as FormArray;
    }

    ngOnInit(): void {
        this.missionSlotBuilderService.softReset();
        this.setCalendarOptions();
        this.subscribeToMissionSlots();
    }

    setCalendarOptions(): void {
        this.calendarOptions = {
            plugins: [dayGridPlugin, interactionPlugin],
            locale: this.translocoService.getActiveLang(),
            buttonText: {
                today: this.translocoService.translate(
                    'createmission.this-month'
                ),
            },
            headerToolbar: {
                left: 'prev,next',
                center: 'title',
                right: 'today',
            },
            weekends: false,
            dayHeaderFormat: { weekday: 'narrow' },
            initialView: 'dayGridMonth',
            contentHeight: 'auto',
            displayEventEnd: true,
            fixedWeekCount: false,
            viewDidMount: () => {
                this.renderRealEvents(this.missionSlotsFormArray.value);
            },
            dayCellClassNames: function (arg) {
                var today = new Date();
                today.setHours(0, 0, 0, 0);

                var cellDate = new Date(arg.date);
                cellDate.setHours(0, 0, 0, 0);

                if (cellDate < today) {
                    return ['fc-non-selectable']; // Return the class name for dates before today
                } else {
                    return []; // Return an empty array for dates today or in the future
                }
            },
            dateClick: (info: DateClickArg) => this.dateClick(info),
            eventClick: (info: EventClickArg) => this.eventClick(info),
            datesSet: () => this.dateSets(),
        };
    }

    dateSets(): void {
        var calendarEl = document.querySelector('.full-calendar');
        var dateElements = calendarEl.querySelectorAll('.fc-daygrid-day');
        // After each month change, we must register date mouse hover to draw event that is being created
        dateElements.forEach((dateElement) => {
            dateElement.addEventListener('mouseenter', (event: MouseEvent) => {
                if (this.missionSlotBuilderService.isDrawing) {
                    const date = (event.target as HTMLElement).getAttribute(
                        'data-date'
                    );
                    const endDate = DateTime.fromISO(date).set({ hour: 17 });
                    if (
                        endDate >
                        DateTime.fromISO(
                            this.missionSlotBuilderService.currentSlotBuilder
                                .startDate
                        )
                    ) {
                        this.drawHoverEvent(endDate);
                    }
                }
            });
        });
    }

    dateClick(event: DateClickArg) {
        this.selectedMissionSlotGuid = undefined;
        this.handleEventDateSet(event.dateStr);
    }

    eventClick(args: EventClickArg) {
        if (this.missionSlotBuilderService.isDrawing) {
            return;
        }

        const eventGuid = args.event.extendedProps.guid;
        // Event has a guid, we select the corresponding slot
        if (eventGuid) {
            this.selectedMissionSlotGuid = args.event.extendedProps.guid;
            this.changeDetectorRef.detectChanges();
        }
        // Event is a placeholder, we consider the click as a date click
        else {
            this.handleEventDateSet(args.event.endStr);
        }
    }

    handleEventDateSet(dateStr: string) {
        if (DateTime.fromISO(dateStr) < DateTime.utc()) {
            return;
        }
        this.missionSlotBuilderService.handleDate(dateStr);
        // Draw instantly placeholder even that is being created as one day event (until user hovers another date)
        if (this.missionSlotBuilderService.isDrawing) {
            const drawnEvent: EventInput = {
                start: new Date(
                    this.missionSlotBuilderService.currentSlotBuilder.startDate
                ),
                end: DateTime.fromISO(
                    this.missionSlotBuilderService.currentSlotBuilder.startDate
                )
                    .set({ hour: 17 })
                    .toString(),
                backgroundColor: 'var(--fuse-primary)',
            };
            this.eventSubject.next([...this.knownEvents, drawnEvent]);
        }
    }

    // Draw event that is being created
    drawHoverEvent(endDate: DateTime) {
        const startDate =
            this.missionSlotBuilderService.currentSlotBuilder.startDate;
        const allDay = !areStringDatesOnSameDay(startDate, endDate.toString());
        const drawnEvent: EventInput = {
            start: new Date(startDate),
            // Must add 1 day to end date because of how fullcalendar allday exclusive end date works
            end: endDate.plus({ days: allDay ? 1 : 0 }).toJSDate(),
            allDay,
            backgroundColor: 'var(--fuse-primary)',
            borderColor: 'var(--fuse-primary)',
        };
        this.eventSubject.next([...this.knownEvents, drawnEvent]);
    }

    // Real events subscription to draw them
    private subscribeToMissionSlots(): void {
        this.missionSlotsSubscription =
            this.missionSlotsFormArray.valueChanges.subscribe(
                (slots: MissionSlotViewModel[]) => {
                    this.renderRealEvents(slots);
                }
            );
    }

    renderRealEvents(slots: MissionSlotViewModel[]) {
        const slotTranslation =
            this.translocoService.translate('createmission.slot');
        const events = slots.map((slot) => {
            const allDay = !areStringDatesOnSameDay(
                slot.startDate,
                slot.endDate
            );
            const isValid =
                DateTime.fromISO(slot.startDate) <
                DateTime.fromISO(slot.endDate);
            return {
                guid: slot.guid,
                title: allDay ? slotTranslation : '',
                start: new Date(slot.startDate),
                // Must add 1 day to end date because of how fullcalendar allday exclusive end date works
                end: DateTime.fromISO(slot.endDate)
                    .plus({ days: allDay ? 1 : 0 })
                    .toJSDate(),
                allDay,
                backgroundColor: isValid ? 'var(--fuse-warn)' : 'red',
                borderColor: isValid ? 'var(--fuse-warn)' : 'red',
            };
        });
        this.knownEvents = events;
        this.eventSubject.next(events);
        const newGuids = slots.map((s) => s.guid);
        // Select fresh mission slot if created "by hand" and not with recurrency
        if (
            hasExactlyOneAdditionalValue(this.knownMissionSlotGuids, newGuids)
        ) {
            this.selectedMissionSlotGuid = getAdditionalValue(
                this.knownMissionSlotGuids,
                newGuids
            );
        }
        this.knownMissionSlotGuids = slots.map((s) => s.guid);
    }

    ngOnDestroy(): void {
        if (this.missionSlotsSubscription) {
            this.missionSlotsSubscription.unsubscribe();
        }
    }
}
