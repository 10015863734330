import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { EMissionState } from 'app/api';
import { BslkTagComponent } from '../../../../../@bslk/components/tag/tag.component';

@Component({
    selector: 'mission-state',
    standalone: true,
    templateUrl: './mission-state.component.html',
    imports: [
        FuseAlertComponent,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatTooltipModule,
        BslkTagComponent,
        TranslocoModule,
        NgIf,
    ],
})
export class MissionStateComponent {
    @Input() state: EMissionState;
    @Input() showDescriptionAsTooltip = true;

    EMissionState = EMissionState;

    stateValues = {
        [EMissionState.Upcoming]: {
            key: 'upcoming',
            backgroundColor: 'primary-light',
            textColor: 'primary',
            icon: 'calendar',
        },
        [EMissionState.Done]: {
            key: 'done',
            backgroundColor: 'quaternary-light',
            textColor: 'quaternary',
            icon: 'check-badge',
        },
        [EMissionState.Archived]: {
            key: 'archived',
            backgroundColor: 'quaternary-light',
            textColor: 'quaternary',
            icon: 'archive-box',
        },
        [EMissionState.InProgress]: {
            key: 'in-progress',
            backgroundColor: 'tertiary-light',
            textColor: 'tertiary',
            icon: 'play-circle',
        },
        [EMissionState.WaitingForManagerAcceptance]: {
            key: 'waiting-for-manager-acceptance',
            backgroundColor: 'warn-light',
            textColor: 'warn',
            icon: 'exclamation-triangle',
        },
        [EMissionState.WaitingForAssociationAcceptance]: {
            key: 'waiting-for-association-acceptance',
            backgroundColor: 'warn-light',
            textColor: 'warn',
            icon: 'exclamation-triangle',
        },
        [EMissionState.WaitingForAssociationValidation]: {
            key: 'waiting-for-association-validation',
            backgroundColor: 'warn-light',
            textColor: 'warn',
            icon: 'exclamation-triangle',
        },
        [EMissionState.WaitingForUserRating]: {
            key: 'waiting-for-user-rating',
            backgroundColor: 'warn-light',
            textColor: 'warn',
            icon: 'exclamation-triangle',
        },
        [EMissionState.WaitingForUserValidation]: {
            key: 'waiting-for-user-validation',
            backgroundColor: 'warn-light',
            textColor: 'warn',
            icon: 'exclamation-triangle',
        },
    };

    get stateValue() {
        return this.stateValues[this.state];
    }

    get icon() {
        return this.stateValue.icon;
    }

    get backgroundColor() {
        return this.stateValue.backgroundColor;
    }

    get textColor() {
        return this.stateValue.textColor;
    }

    get key() {
        return this.stateValue.key;
    }
}
