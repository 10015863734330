<ng-container *ngIf="(questionsLeft$ | async) > 0; else noQuestionsLeft">
    <div class="flex flex-col gap-6" *ngrxLet="questionIndex$ as questionIndex">
        <div class="flex flex-col gap-2 w-full p-4 bg-primary-100 rounded-xl">
            <div class="flex w-full justify-between">
                <div class="flex items-center gap-1">
                    <mat-icon
                        class="text-current icon-size-5"
                        [svgIcon]="'heroicons_outline:question-mark-circle'"
                    ></mat-icon>
                    <div>{{ "dualquiz.questions-left" | transloco }}</div>
                </div>
                <span class="font-bold">{{ questionsLeft$ | async }}</span>
            </div>
            <div class="flex w-full justify-between">
                <div class="flex items-center gap-1">
                    <mat-icon
                        class="text-current icon-size-5"
                        [svgIcon]="'heroicons_outline:clock'"
                    ></mat-icon>
                    <div>{{ "dualquiz.time-per-question" | transloco }}</div>
                </div>
                <span class="font-bold"
                    >{{ secondsPerQuestion$ | async }}
                    {{ "dualquiz.seconds" | transloco }}</span
                >
            </div>
        </div>

        <div>
            <ng-container *ngIf="questionIndex === 0">
                <span class="text-xl text-center font-bold mb-8">
                    {{ "dualquiz.ready-to-start" | transloco }}</span
                >
            </ng-container>
            <ng-container *ngIf="questionIndex > 0">
                <span class="text-xl text-center font-bold mb-8">
                    {{ "dualquiz.ready-to-resume" | transloco }}</span
                >
            </ng-container>
        </div>

        <!-- TODO: Afficher Cadence, nombre de questions restantes -->
        <dual-quiz-challenge-next-button
            [isStarting]="true"
        ></dual-quiz-challenge-next-button>
    </div>
</ng-container>

<ng-template #noQuestionsLeft>
    <div class="flex text-lg flex-col text-center items-center justify-center">
        {{ "dualquiz.all-questions-answered" | transloco }}
    </div>
</ng-template>
