<base-dialog
    (onClose)="close()"
    [title]="'setup.import-employees-title' | transloco"
    [showActions]="true"
>
    <div class="w-full h-full flex flex-col gap-4" body>
        <bslk-sheet-data-editor
            (dataChanged)="onDataChanged($event)"
            (importClicked)="onImportClicked()"
            [entities]="employees"
            [fields]="['firstName', 'familyName', 'email']"
            [placeholders]="{
                firstName: 'John',
                familyName: 'Doe',
                email: 'john.doe@domain.com'
            }"
        ></bslk-sheet-data-editor>
    </div>

    <div class="flex justify-end" actions>
        <bslk-classic-button
            (click)="submit()"
            [isDisabled]="!hasAnyEmployeeImported"
            [isLoading]="isLoading$ | async"
            >{{ "shared.common.save" | transloco }}</bslk-classic-button
        >
    </div>
</base-dialog>
