// dialog.service.ts

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import {
    ImageUploadDialogComponent,
    ImageUploadDialogInput,
} from './file-upload-dialog.component';

@Injectable({ providedIn: 'root' })
export class FileUploadDialogService implements IDialogService {
    private dialogRef: MatDialogRef<ImageUploadDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open(input?: ImageUploadDialogInput) {
        this.dialogRef = this.dialog.open(ImageUploadDialogComponent, {
            data: input,
        });
    }

    close() {
        this.dialogRef.close();
    }
}
