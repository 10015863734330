import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SafeUrl } from '@angular/platform-browser';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkSheetDataEditorComponent } from '@bslk/components/form/sheet-data-editor/sheet-data-editor.component';
import { BslkCardToggleComponent } from '@bslk/components/form/toggle/card/card-toggle.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { QRCodeModule } from 'angularx-qrcode';
import { EDonationCampaignWhitelistType } from 'app/api';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { DonationCampaignFormService } from 'app/shared/services/form/donation-campaign-form.service';
import { EExternalUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { DonationCampaignActions } from 'app/store/donation/donation-campaign/donation-campaign.action';
import { DonationCampaignSelectors } from 'app/store/donation/donation-campaign/donation-campaign.selectors';
import { ImportSheetActions } from 'app/store/sheet/import/import.action';
import { ImportSheetSelectors } from 'app/store/sheet/import/import.selectors';
import { EmailEntity } from 'app/store/sheet/import/import.state';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'donation-campaign-share-settings-dialog',
    templateUrl: './share-settings-dialog.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BaseDialogComponent,
        BslkCardToggleComponent,
        BslkTagButtonComponent,
        BslkClassicButtonComponent,
        BslkClassicToggleComponent,
        BslkInfoLabelComponent,
        BslkSheetDataEditorComponent,
        FuseAlertComponent,
        LetDirective,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
        QRCodeModule,
    ],
})
export class DonationCampaignShareSettingsDialogComponent
    implements OnInit, OnDestroy
{
    EDonationCampaignWhitelistType = EDonationCampaignWhitelistType;

    unsubscribeAll: Subject<any> = new Subject<any>();
    emails: EmailEntity[] = [];
    hasSwitchedToCodeWhitelist = false;
    hasChangedCustomUrl = false;
    qrCodeDownloadLink: SafeUrl;
    hasSubmitted = false;
    initialWhitelistTypeIsCode =
        this.formGroup.controls.whitelistType.value ===
        EDonationCampaignWhitelistType.Code;

    isLoading$ = this.store.select(
        DonationCampaignSelectors.selectIsLoadingUpdate
    );
    error$ = this.store.select(DonationCampaignSelectors.selectUpdateError);
    donationGiftUrl$ = this.store.select(
        DonationCampaignSelectors.selectDonationGiftUrl
    );
    importedEmails$ = this.store
        .select(ImportSheetSelectors.selectEmails)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((emails) => {
            if (emails) {
                this.emails = emails.map((e) => ({ email: e }));
                this.formGroup.controls.whitelistedEmails.setValue(emails);
                this.store.dispatch(ImportSheetActions.resetEmails());
            }
        });

    constructor(
        private store: Store,
        private donationCampaignFormService: DonationCampaignFormService,
        private dialogRef: MatDialogRef<DonationCampaignShareSettingsDialogComponent>,
        private fileUploadDialogService: FileUploadDialogService
    ) {}

    ngOnInit(): void {
        this.emails =
            this.formGroup.controls.whitelistedEmails.value?.map(
                (e: string) => ({
                    email: e,
                })
            ) ?? [];
    }

    get urlPrefix() {
        return (
            environment.redirectUri +
            UrlHelpers.getExternalUrl(EExternalUrl.DonationGift, { id: '' })
        );
    }

    get formGroup() {
        return this.donationCampaignFormService.getFormGroup;
    }

    close() {
        this.dialogRef.close();
    }

    submit() {
        this.hasSubmitted = true;
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            this.store.dispatch(
                DonationCampaignActions.updateDonationCampaignRequest()
            );
        }
    }

    onWhitelistTypeChange(type: EDonationCampaignWhitelistType) {
        if (type === EDonationCampaignWhitelistType.Code) {
            this.hasSwitchedToCodeWhitelist = true;
        }
    }

    onDataChanged(emails: EmailEntity[]) {
        this.emails = emails;
        this.formGroup.controls.whitelistedEmails.setValue(
            emails.map((e) => e.email)
        );
    }

    onCustomUrlChange() {
        this.hasChangedCustomUrl = true;
    }

    onChangeURL(url: SafeUrl) {
        this.qrCodeDownloadLink = url;
    }

    onImportClicked() {
        this.fileUploadDialogService.open({
            action: ImportSheetActions.importEmailsRequest,
            isLoading$: this.store.select(ImportSheetSelectors.selectIsLoading),
            error$: this.store.select(ImportSheetSelectors.selectError),
            maxFileSize: 2000 * 1024,
            infoTextKey: 'donationcampaign.import-info',
            extraAllowedFormats: ['csv', 'xlsx'],
            disableImages: true,
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
