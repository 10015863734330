import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProjectService } from 'app/api';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import {
    createDeleteItemEffect,
    createLoadItemRequestEffect,
    createLoadListRequestEffect,
    createReloadEffect,
    createUpdateSelectedItemEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';

import { ProjectFormService } from 'app/shared/services/form/project-form.service';
import { AlertActions } from 'app/store/alert/alert.actions';
import { ProjectActions } from '../project/project.action';
import { ProjectListActions } from './project-list.actions';
import { ProjectListSelectors } from './project-list.selectors';
@Injectable()
export class ProjectListEffects {
    loadProjects$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.projectService,
        ProjectListActions,
        ProjectListSelectors,
        this.projectService.projectGet
    );

    loadProject$ = createLoadItemRequestEffect(
        this.store,
        this.actions$,
        this.projectService,
        ProjectListActions,
        ProjectListSelectors,
        this.projectService.projectIdGet
    );

    reload$ = createReloadEffect(this.actions$, ProjectListActions, [
        ProjectActions.createProjectRequestSuccess,
    ]);

    deleteProject$ = createDeleteItemEffect(
        this.projectService,
        this.actions$,
        ProjectListActions,
        'project-delete',
        this.projectService.projectDelete
    );

    openUpdateProjectDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectListActions.SelectItem),
            concatLatestFrom(() =>
                this.store.select(ProjectListSelectors.selectSelectedItem)
            ),
            tap(([, project]) => {
                this.projectFormService.updateForm(project);
            }),
            map(() =>
                ProjectActions.openDialog({
                    input: { isEdit: true, isFromEntityPage: false },
                })
            )
        )
    );

    publishRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectListActions.PublishRequest),
            concatLatestFrom(() =>
                this.store.select(ProjectListSelectors.selectSelectedItem)
            ),
            tap(([, selectedItem]) => {
                const projectCopy = structuredClone(selectedItem);
                projectCopy.isActive = true;
                this.projectFormService.updateForm(projectCopy);
            }),
            map(() => ProjectListActions.UpdateSelectedItem())
        )
    );

    updateProject$ = createUpdateSelectedItemEffect(
        this.projectService,
        this.actions$,
        ProjectListActions,
        this.projectFormService,
        'project-update',
        this.projectService.projectPut
    );

    addImageFromList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectListActions.AddImage),
            exhaustMap(({ file, id }) => {
                return this.projectService
                    .projectIdAddImagePost({ id, file })
                    .pipe(
                        map((image) => {
                            this.fileUploadDialogService.close();
                            return ProjectListActions.AddImageSuccess({
                                projectId: id,
                                image,
                            });
                        }),
                        catchError(() =>
                            of(ProjectListActions.UpdateImageFail())
                        )
                    );
            })
        )
    );

    removeImageFromList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectListActions.RemoveImage),
            exhaustMap(({ id }) => {
                return this.projectService
                    .projectIdRemoveImageDelete({ id })
                    .pipe(
                        map(() => ProjectListActions.RemoveImageSuccess()),
                        catchError(() =>
                            of(ProjectListActions.UpdateImageFail())
                        )
                    );
            })
        )
    );

    closeAfterUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectListActions.UpdateSelectedItemSuccess),
            map(() => ProjectActions.closeDialog())
        )
    );

    displayErrorOnUpdateFail = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectListActions.UpdateImageFail),
            map(() => AlertActions.displayGenericError())
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private projectService: ProjectService,
        private fileUploadDialogService: FileUploadDialogService,
        private projectFormService: ProjectFormService
    ) {}
}
