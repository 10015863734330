import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { CompanyActions } from './company.actions';
import { CompanyState } from './company.state';

export const initialState: CompanyState = ressourceListInitialState;

export const companyReducer = createRessourceListReducer(
    ressourceListInitialState,
    CompanyActions
);
