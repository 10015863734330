import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CompanyEmployeeViewModel } from 'app/api';

export const ImportSheetActions = createActionGroup({
    source: 'ImportSheet',
    events: {
        'Import Emails Request': props<{ file: File }>(),
        'Import Emails Request Success': props<{ emails: string[] }>(),
        'Import Emails Request Failure': props<{ error: string }>(),
        'Reset Emails': emptyProps(),

        'Import Company Employees Request': props<{ file: File }>(),
        'Import Company Employees Request Success': props<{
            employees: CompanyEmployeeViewModel[];
        }>(),
        'Import Company Employees Request Failure': props<{ error: string }>(),
        'Reset Company Employees': emptyProps(),
    },
});
