import { createReducer, on } from '@ngrx/store';
import { mergeObjects } from 'app/shared/utils/generic-helpers.utils';
import { AssociationActions } from './association.action';
import { AssociationState } from './association.state';

export const associationinitialState: AssociationState = {
    isLoading: false,
    hasPendingJoinRequest: false,
    isLoadingFileUpload: false,
};

export const associationReducer = createReducer(
    associationinitialState,

    on(AssociationActions.createRequest, (state) => {
        return {
            ...state,
            isLoading: true,
            error: null,
        };
    }),

    on(AssociationActions.createRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            error,
        };
    }),

    on(AssociationActions.createRequestSuccesful, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(AssociationActions.updateRequestSuccess, (state, { association }) => {
        return {
            ...state,
            // Merge objects to not lose logo url, cover url, etc., since those aren't in the form
            association: mergeObjects(state.association, association),
        };
    }),

    on(AssociationActions.joinRequestSuccess, (state) => {
        return {
            ...state,
            hasPendingJoinRequest: true,
        };
    }),

    on(
        AssociationActions.setHasPendingRequest,
        (state, { hasPendingRequest }) => {
            return {
                ...state,
                hasPendingJoinRequest: hasPendingRequest,
            };
        }
    ),

    on(AssociationActions.resetErrors, (state) => {
        return {
            ...state,
            error: null,
        };
    }),

    on(AssociationActions.loadRequest, (state, { id }) => {
        return {
            ...state,
            isLoading: true,
            error: null,
        };
    }),

    on(AssociationActions.loadMineRequest, (state, { displayIsLoading }) => {
        return {
            ...state,
            isLoading: displayIsLoading,
            error: null,
        };
    }),

    on(AssociationActions.loadRequestSuccess, (state, { response }) => {
        return {
            ...state,
            isLoading: false,
            error: null,
            association: response,
        };
    }),

    on(AssociationActions.loadRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            error: error,
        };
    }),

    on(AssociationActions.updateCoverRequest, (state) => {
        return {
            ...state,
            isLoadingFileUpload: true,
        };
    }),

    on(AssociationActions.updateCoverFail, (state) => {
        return {
            ...state,
            isLoadingFileUpload: false,
        };
    }),

    on(AssociationActions.updateCoverSuccess, (state, { url }) => {
        return {
            ...state,
            isLoadingFileUpload: false,
            association: {
                ...state.association,
                coverUrl: url,
            },
        };
    }),

    on(AssociationActions.updateLogoRequest, (state) => {
        return {
            ...state,
            isLoadingFileUpload: true,
        };
    }),

    on(AssociationActions.updateLogoFail, (state) => {
        return {
            ...state,
            isLoadingFileUpload: false,
        };
    }),

    on(AssociationActions.updateLogoSuccess, (state, { url }) => {
        const association = {
            ...state.association,
            logoUrl: url,
        };
        return {
            ...state,
            association,
            isLoadingFileUpload: false,
        };
    })
);
