import { Component, Input } from '@angular/core';

@Component({
    selector: 'auth-template',
    standalone: true,
    templateUrl: './auth-template.component.html',
})
export class AuthTemplateComponent {
    @Input() title: string;

    constructor() {}
}
