import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyService } from 'app/api';
import { isNil } from 'lodash-es';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { AlertActions } from '../alert/alert.actions';
import { SetupActions } from './setup.action';

@Injectable()
export class SetupEffects {
    loadCompany$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetupActions.loadCompanyRequest),
            exhaustMap(() => {
                return this.companyService.companyUserGet().pipe(
                    map((response) =>
                        SetupActions.loadCompanyRequestSuccess({ response })
                    ),
                    catchError((httpResponse) =>
                        of(
                            SetupActions.loadCompanyRequestFail({
                                error: httpResponse?.error ?? httpResponse,
                            })
                        )
                    )
                );
            })
        )
    );

    updateCompanyAdministrativeContact$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetupActions.updateAdministrativeContactRequest),
            exhaustMap(({ userId }) => {
                return (
                    isNil(userId)
                        ? this.companyService.companyRemoveAdministrativeContactPatch()
                        : this.companyService.companyAdministrativeContactIdPatch(
                              { id: userId }
                          )
                ).pipe(
                    map(() =>
                        SetupActions.updateAdministrativeContactRequestSuccess()
                    ),
                    catchError((httpResponse) =>
                        of(
                            SetupActions.updateAdministrativeContactRequestFail(
                                {
                                    error: httpResponse?.error ?? httpResponse,
                                }
                            )
                        )
                    )
                );
            })
        )
    );

    updateSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetupActions.updateAdministrativeContactRequestSuccess),
            map(() => {
                return AlertActions.displaySuccess({
                    key: 'setup-update',
                });
            })
        )
    );

    constructor(
        private actions$: Actions,
        private companyService: CompanyService,
        private store: Store
    ) {}
}
