/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ESkill = 'SoftwareDevelopment' | 'DataAnalysis' | 'GraphicDesign' | 'SocialMediaManagement' | 'Writing' | 'Editing' | 'Translation' | 'Mentoring' | 'Coaching' | 'PublicSpeaking' | 'EventPlanning' | 'Fundraising' | 'Accounting' | 'Budgeting' | 'LegalAdvice' | 'MedicalAssistance' | 'FirstAid' | 'Counseling' | 'Cooking' | 'FoodDistribution' | 'Photography' | 'Videography' | 'Music' | 'ArtAndCraft' | 'Construction' | 'Plumbing' | 'ElectricalWork' | 'Gardening' | 'Cleaning' | 'Driving' | 'AnimalCare' | 'Childcare' | 'ElderCare' | 'FitnessTraining' | 'Hairdressing' | 'BeautyServices' | 'Sewing' | 'ITSupport' | 'WebDevelopment' | 'Marketing' | 'Sales' | 'CustomerService' | 'ProjectManagement' | 'Research' | 'EnvironmentalConservation' | 'Other' | 'Audit' | 'ChangeManagement' | 'ProjectMonitoring' | 'Purchasing' | 'Quality' | 'Taxation' | 'BankingFinance' | 'HumanResources' | 'ManualLabor' | 'Pedagogy' | 'Reporting' | 'Communication' | 'EventManagement' | 'Administration';

export const ESkill = {
    SoftwareDevelopment: 'SoftwareDevelopment' as ESkill,
    DataAnalysis: 'DataAnalysis' as ESkill,
    GraphicDesign: 'GraphicDesign' as ESkill,
    SocialMediaManagement: 'SocialMediaManagement' as ESkill,
    Writing: 'Writing' as ESkill,
    Editing: 'Editing' as ESkill,
    Translation: 'Translation' as ESkill,
    Mentoring: 'Mentoring' as ESkill,
    Coaching: 'Coaching' as ESkill,
    PublicSpeaking: 'PublicSpeaking' as ESkill,
    EventPlanning: 'EventPlanning' as ESkill,
    Fundraising: 'Fundraising' as ESkill,
    Accounting: 'Accounting' as ESkill,
    Budgeting: 'Budgeting' as ESkill,
    LegalAdvice: 'LegalAdvice' as ESkill,
    MedicalAssistance: 'MedicalAssistance' as ESkill,
    FirstAid: 'FirstAid' as ESkill,
    Counseling: 'Counseling' as ESkill,
    Cooking: 'Cooking' as ESkill,
    FoodDistribution: 'FoodDistribution' as ESkill,
    Photography: 'Photography' as ESkill,
    Videography: 'Videography' as ESkill,
    Music: 'Music' as ESkill,
    ArtAndCraft: 'ArtAndCraft' as ESkill,
    Construction: 'Construction' as ESkill,
    Plumbing: 'Plumbing' as ESkill,
    ElectricalWork: 'ElectricalWork' as ESkill,
    Gardening: 'Gardening' as ESkill,
    Cleaning: 'Cleaning' as ESkill,
    Driving: 'Driving' as ESkill,
    AnimalCare: 'AnimalCare' as ESkill,
    Childcare: 'Childcare' as ESkill,
    ElderCare: 'ElderCare' as ESkill,
    FitnessTraining: 'FitnessTraining' as ESkill,
    Hairdressing: 'Hairdressing' as ESkill,
    BeautyServices: 'BeautyServices' as ESkill,
    Sewing: 'Sewing' as ESkill,
    ItSupport: 'ITSupport' as ESkill,
    WebDevelopment: 'WebDevelopment' as ESkill,
    Marketing: 'Marketing' as ESkill,
    Sales: 'Sales' as ESkill,
    CustomerService: 'CustomerService' as ESkill,
    ProjectManagement: 'ProjectManagement' as ESkill,
    Research: 'Research' as ESkill,
    EnvironmentalConservation: 'EnvironmentalConservation' as ESkill,
    Other: 'Other' as ESkill,
    Audit: 'Audit' as ESkill,
    ChangeManagement: 'ChangeManagement' as ESkill,
    ProjectMonitoring: 'ProjectMonitoring' as ESkill,
    Purchasing: 'Purchasing' as ESkill,
    Quality: 'Quality' as ESkill,
    Taxation: 'Taxation' as ESkill,
    BankingFinance: 'BankingFinance' as ESkill,
    HumanResources: 'HumanResources' as ESkill,
    ManualLabor: 'ManualLabor' as ESkill,
    Pedagogy: 'Pedagogy' as ESkill,
    Reporting: 'Reporting' as ESkill,
    Communication: 'Communication' as ESkill,
    EventManagement: 'EventManagement' as ESkill,
    Administration: 'Administration' as ESkill
};

