import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { SuperAdminGuard } from 'app/core/auth/guards/roles.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { ProjectListComponent } from 'app/shared/components/project/project-list/project-list.component';
import { SuperAdminDashboardComponent } from './dashboard/super-admin-dashboard.component';
import { DemonstrationComponent } from './demonstration/demonstration.component';
import { QuestionsComponent } from './questions/questions.component';

export const superAdminRoutes = [
    {
        path: 'sa',
        canActivate: [AuthGuard, SuperAdminGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'companies',
                loadChildren: () =>
                    import('app/modules/super-admin/company/companies.routes'),
            },
            {
                path: 'associations',
                loadChildren: () =>
                    import(
                        'app/modules/super-admin/associations/associations.routes'
                    ),
            },
            {
                path: 'users',
                loadChildren: () =>
                    import('app/modules/super-admin/users/users.routes'),
            },
            {
                path: 'questions',
                component: QuestionsComponent,
            },
            {
                path: 'projects',
                component: ProjectListComponent,
            },
            {
                path: 'demonstration',
                component: DemonstrationComponent,
            },
            {
                path: 'dashboard',
                component: SuperAdminDashboardComponent,
            },
        ],
    },
];
