import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ChatService, ChatViewModel, MessageService } from 'app/api';
import { isNil } from 'lodash-es';
import { DateTime } from 'luxon';
import {
    EMPTY,
    catchError,
    debounceTime,
    exhaustMap,
    filter,
    map,
    of,
    switchMap,
    tap,
} from 'rxjs';
import { UserSelectors } from '../user/user/user.selectors';
import { ChatActions } from './chat.action';
import { ChatSelectors } from './chat.selectors';
@Injectable()
export class ChatEffects {
    triggerLoadChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.triggerLoadChatsRequest),
            concatLatestFrom(() =>
                this.store.select(ChatSelectors.selectHasChats)
            ),
            filter(([, hasChats]) => !hasChats),
            map(() => ChatActions.loadChatsRequest())
        )
    );

    loadChats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.loadChatsRequest),
            concatLatestFrom(() =>
                this.store.select(ChatSelectors.selectHasChats)
            ),
            exhaustMap(() => {
                return this.chatService.chatGet({}).pipe(
                    map((response) =>
                        ChatActions.loadChatsRequestSuccess({ response })
                    ),
                    catchError((httpResponse) =>
                        of(
                            ChatActions.loadChatsRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    searchChats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.searchChatRequest),
            debounceTime(300),
            concatLatestFrom(() =>
                this.store.select(ChatSelectors.selectSearchChat)
            ),
            exhaustMap(([, searchChat]) => {
                return this.chatService
                    .chatGet({ nameContains: searchChat })
                    .pipe(
                        map((response) =>
                            ChatActions.searchChatRequestSuccess({ response })
                        ),
                        catchError((httpResponse) =>
                            of(
                                ChatActions.searchChatRequestFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    );
            })
        )
    );

    selectChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.selectChat),
            filter(({ chatId }) => chatId !== 0),
            map(() => ChatActions.loadChatMessagesRequest())
        )
    );

    selectFirstChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.selectFirstChat),
            concatLatestFrom(() =>
                this.store.select(ChatSelectors.selectChats)
            ),
            filter(([, chats]) => chats.length > 0),
            map(([, chats]) =>
                ChatActions.selectChat({ chatId: chats.at(0).id })
            )
        )
    );

    acknowledgeChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.selectChat),
            concatLatestFrom(() =>
                this.store.select(ChatSelectors.selectSelectedChat)
            ),
            filter(([, chat]) => chat.id !== 0 && !chat.isReaden),
            map(() => ChatActions.acknowledgeSelectedChat())
        )
    );

    acknowledgeChatRequest$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChatActions.acknowledgeSelectedChat),
                concatLatestFrom(() =>
                    this.store.select(ChatSelectors.selectSelectedChat)
                ),
                switchMap(([, chat]) => {
                    return this.chatService
                        .chatIdAcknowledgePatch({ id: chat.id })
                        .pipe(switchMap(() => EMPTY));
                })
            ),
        { dispatch: false }
    );

    loadMoreMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.loadMoreChatMessages),
            map(() => ChatActions.loadChatMessagesRequest())
        )
    );

    loadChatMessagesRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.loadChatMessagesRequest),
            concatLatestFrom(() => [
                this.store.select(ChatSelectors.selectSelectedChatId),
                this.store.select(ChatSelectors.selectMessagesPageIndex),
            ]),
            exhaustMap(([, chatId, pageIndex]) => {
                return this.messageService
                    .messageGet({ chatId, pageIndex })
                    .pipe(
                        map((response) =>
                            ChatActions.loadChatMessagesRequestSuccess({
                                response,
                            })
                        ),
                        catchError((httpResponse) =>
                            of(
                                ChatActions.loadChatMessagesRequestFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    );
            })
        )
    );

    loadLastChatMessagesRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.loadLastChatMessagesRequest),
            concatLatestFrom(() => [
                this.store.select(ChatSelectors.selectSelectedChatId),
            ]),
            filter(([, chatId]) => chatId !== 0),
            exhaustMap(([, chatId]) => {
                return this.messageService.messageGet({ chatId }).pipe(
                    map((response) =>
                        ChatActions.loadChatMessagesRequestSuccess({ response })
                    ),
                    catchError((httpResponse) =>
                        of(
                            ChatActions.loadChatMessagesRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    buildMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.buildMessage),
            concatLatestFrom(() => [
                this.store.select(UserSelectors.selectUserId),
                this.store.select(ChatSelectors.selectSelectedChatId),
            ]),
            map(([{ text }, userId, selectedChatId]) =>
                ChatActions.sendMessageRequest({
                    message: {
                        id: 0,
                        text,
                        senderId: userId,
                        chatId: selectedChatId,
                        isMine: true,
                        dateCreated: new Date()
                            .toISOString()
                            .replace('Z', '+00:00'),
                    },
                })
            )
        )
    );

    sendMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.sendMessageRequest),
            concatLatestFrom(() =>
                this.store.select(ChatSelectors.selectSelectedChat)
            ),
            exhaustMap(([{ message }, selectedChat]) => {
                // New chat not yet created in db
                if (isNil(selectedChat.id) || selectedChat.id === 0) {
                    const chat = structuredClone(selectedChat);
                    chat.messages = [message];
                    return of(ChatActions.createChatRequest({ chat }));
                }

                // Chat already exists, we simply post the new message
                return this.messageService
                    .messagePost({ messageViewModel: message })
                    .pipe(
                        map((id) =>
                            ChatActions.sendMessageRequestSuccess({ id })
                        ),
                        catchError((httpResponse) =>
                            of(
                                ChatActions.sendMessageRequestFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    );
            })
        )
    );

    createChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.createChatRequest),
            exhaustMap(({ chat }) => {
                return this.chatService.chatPost({ chatViewModel: chat }).pipe(
                    map((chatViewModel) =>
                        ChatActions.createChatRequestSuccess({ chatViewModel })
                    ),
                    catchError((httpResponse) =>
                        of(
                            ChatActions.sendMessageRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    buildChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.buildChat),
            concatLatestFrom(() => [
                this.store.select(UserSelectors.selectUser),
            ]),
            exhaustMap(([{ users, currentRoute }, user]) => {
                const contactIds = users.map((u) => u.id);
                // Check first if chat exists between the contacts
                return this.chatService
                    .chatGet({ userIds: [user.id, ...contactIds] })
                    .pipe(
                        map((response) => {
                            if (response.totalCount > 0) {
                                this.router.navigateByUrl(`/s/chat`);
                                return ChatActions.selectChat({
                                    chatId: response.data[0].id,
                                });
                            }
                            // If not, we create it
                            const chat: ChatViewModel = {
                                id: 0,
                                userIds: [user.id, ...contactIds],
                                isGroup: users.length > 2,
                                contacts: users,
                                isReaden: true,
                                dateUpdated: DateTime.utc().toString(),
                            };
                            return ChatActions.buildChatSuccess({ chat });
                        })
                    );
            })
        )
    );

    redirectToChat$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ChatActions.buildChatSuccess,
                    ChatActions.redirectToChat
                ),
                tap(() => {
                    this.router.navigateByUrl(`/s/chat`);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private router: Router,
        private messageService: MessageService,
        private chatService: ChatService
    ) {}
}
