<div [formGroup]="group" class="single-select">
    <mat-form-field
        [subscriptSizing]="'dynamic'"
        floatLabel="always"
        class="w-full"
    >
        <mat-label *ngIf="label">
            <bslk-info-label class="inline-block" [tooltip]="tooltip">
                {{ label }}
            </bslk-info-label>
        </mat-label>
        <span
            class="whitespace-nowrap w-full"
            *ngIf="showInitialValue && initialValue"
            >{{ initialValue }}</span
        >
        <mat-icon
            *ngIf="icon && !showInitialValue"
            class="icon-size-5"
            [svgIcon]="icon"
            matPrefix
        >
        </mat-icon>
        <mat-select
            [multiple]="multiple"
            [placeholder]="selectPlaceholder"
            (openedChange)="onMatSelectOpenedChange($event)"
            (selectionChange)="onChange()"
            [formControlName]="controlName"
        >
            <div class="relative">
                <div
                    *ngIf="!disableSearch"
                    class="search sticky top-0 z-99 border-b-2"
                >
                    <mat-form-field
                        [subscriptSizing]="'dynamic'"
                        class="search-input py-2 w-full px-1 bg-warn-100"
                    >
                        <mat-icon
                            [svgIcon]="'heroicons_solid:magnifying-glass'"
                            matPrefix
                        >
                        </mat-icon>
                        <input
                            class="bg-warn-100"
                            #searchInput
                            [placeholder]="searchPlaceholder"
                            [value]="search"
                            matInput
                            (keyup)="onSearch($event.target.value)"
                        />
                        <button
                            mat-icon-button
                            matSuffix
                            *ngIf="search"
                            (click)="clearSearch()"
                        >
                            <mat-icon
                                class="text-black"
                                [svgIcon]="'heroicons_solid:x-mark'"
                            >
                            </mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <mat-option
                    *ngFor="let item of displayedItems"
                    [class.hidden]="!shouldDisplayItem(item)"
                    [value]="item.value"
                >
                    {{ item.name }}
                </mat-option>
            </div>
        </mat-select>
        <mat-error>
            {{ errorMessage }}
        </mat-error>
    </mat-form-field>
</div>
