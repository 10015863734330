import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkDraggableDirective } from '@bslk/directives/draggable.directive';
import { TranslocoModule } from '@ngneat/transloco';
import {
    AwarenessCampaignThematicViewModel,
    EAwarenessThematic,
} from 'app/api';
import { AwarenessCampaignFormService } from 'app/shared/services/form/awareness-campaign-form.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AwarenessCampaignThematicDetailsComponent } from '../thematic-details/thematic-details.component';

@Component({
    selector: 'awareness-campaign-thematics-list',
    templateUrl: './thematics-list.component.html',
    standalone: true,
    imports: [
        BslkClassicButtonComponent,
        BslkDraggableDirective,
        TranslocoModule,
        NgIf,
        NgxSkeletonLoaderModule,
        NgFor,
        MatButtonModule,
        MatIconModule,
        AwarenessCampaignThematicDetailsComponent,
    ],
})
export class AwarenessCampaignThematicsListComponent {
    EAwarenessThematic = EAwarenessThematic;
    selectedThematicDetails: EAwarenessThematic;

    constructor(private formService: AwarenessCampaignFormService) {}

    get formArray() {
        return this.formService.getFormGroup.controls.thematics as FormArray;
    }

    get alreadySelectedThematics(): EAwarenessThematic[] {
        return this.formArray.value.map(
            (t: AwarenessCampaignThematicViewModel) => t.thematic
        );
    }

    get thematics(): EAwarenessThematic[] {
        return Object.values(EAwarenessThematic).filter(
            (v) =>
                !this.alreadySelectedThematics.includes(v as EAwarenessThematic)
        ) as EAwarenessThematic[];
    }

    addThematic(thematic: EAwarenessThematic) {
        const firstEmptyThematic = this.formArray.controls.find(
            (c) => !(c as FormGroup).controls.thematic.value
        ) as FormGroup;
        if (firstEmptyThematic) {
            firstEmptyThematic.controls.thematic.setValue(thematic);
        } else {
            this.formArray.push(this.formService.createThematic({ thematic }));
        }
    }
}
