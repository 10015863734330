import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FeedbackService } from 'app/api';
import { FeedbackFormService } from 'app/layout/common/feedback/feedback-form.service';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { FeedbackActions } from './feedback.action';

@Injectable()
export class FeedbackEffects {
    sendFeedback$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FeedbackActions.sendFeedbackRequest),
            exhaustMap(() => {
                const feedbackViewModel = this.feedbackFormService.getEntity();
                return this.feedbackService
                    .feedbackPost(feedbackViewModel)
                    .pipe(
                        map(() => FeedbackActions.sendFeedbackRequestSuccess()),
                        catchError((httpResponse) =>
                            of(
                                FeedbackActions.sendFeedbackRequestFail({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    resetFormService$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(FeedbackActions.resetFeedbackState),
                tap(() => {
                    this.feedbackFormService.initForm();
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private feedbackFormService: FeedbackFormService,
        private feedbackService: FeedbackService
    ) {}
}
