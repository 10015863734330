import { createSelector } from '@ngrx/store';
import { OnboardingTask } from 'app/shared/components/onboarding-progress/onboarding-progress.component';
import {
    EAccountUrl,
    EAssociationUrl,
    UrlHelpers,
} from 'app/shared/utils/url-helpers.utils';
import { isEmpty } from 'lodash-es';
import { AppState } from '../..';
import { HighlightActions } from '../../highlight/highlight.action';
import { UserSelectors } from '../../user/user/user.selectors';
const selectState = (state: AppState) => state.association;

const selectIsLoading = createSelector(
    selectState,
    UserSelectors.selectIsLoading,
    (state, userIsLoading) => state.isLoading || userIsLoading
);

const selectIsLoadingFileUpload = createSelector(
    selectState,
    (state) => state.isLoadingFileUpload
);

const selectError = createSelector(selectState, (state) => state.error);

const selectAssociation = createSelector(
    selectState,
    (state) => state.association
);

const selectHasPendingJoinRequest = createSelector(
    selectState,
    (state) => state.hasPendingJoinRequest
);

const selectAssociationIsLoaded = createSelector(
    selectAssociation,
    (association) => association !== null && association !== undefined
);

const selectName = createSelector(
    selectAssociation,
    (association) => association?.name
);

const selectNameFirstLetter = createSelector(selectAssociation, (association) =>
    association?.name.charAt(0)
);

const selectActivityFields = createSelector(
    selectAssociation,
    (association) => association?.tags
);

const selectHasLogo = createSelector(
    selectAssociation,
    (association) => !isEmpty(association?.logoUrl)
);

const selectHasCover = createSelector(
    selectAssociation,
    (association) => !isEmpty(association?.coverUrl)
);

const selectHasMedia = createSelector(
    selectAssociation,
    (association) => !isEmpty(association?.mediaUrl)
);

const selectHasDescription = createSelector(
    selectAssociation,
    (association) => !isEmpty(association?.description)
);

const selectHasCountry = createSelector(
    selectAssociation,
    (association) => !isEmpty(association?.location?.country)
);

const selectHasCity = createSelector(
    selectAssociation,
    (association) => !isEmpty(association?.location?.city)
);

const selectHasAddress = createSelector(
    selectAssociation,
    (association) => !isEmpty(association?.location?.address)
);

const selectHasZipCode = createSelector(
    selectAssociation,
    (association) => !isEmpty(association?.location?.zipCode)
);

const selectHasCreatedMission = createSelector(
    selectAssociation,
    (association) => association?.hasCreatedMission
);

const selectHasCode = createSelector(
    selectAssociation,
    (association) => !isEmpty(association?.code)
);

const selectHasSomeLocationSet = createSelector(
    selectHasCountry,
    selectHasCity,
    selectHasAddress,
    selectHasZipCode,
    (hasCountry, hasCity, hasAddress, hasZipCode) =>
        hasCountry || hasCity || hasAddress || hasZipCode
);

const selectHasLocation = createSelector(
    selectHasAddress,
    (selectHasAddress) => selectHasAddress
);

const selectLogo = createSelector(
    selectAssociation,
    (association) => association?.logoUrl
);

const selectCover = createSelector(
    selectAssociation,
    (association) => association?.coverUrl
);

const selectMedia = createSelector(
    selectAssociation,
    (association) => `https://www.youtube.com/embed/${association?.mediaUrl}`
);

const selectDescription = createSelector(
    selectAssociation,
    (association) => association?.description
);

const selectCountry = createSelector(
    selectAssociation,
    (association) => association?.location?.country
);

const selectCity = createSelector(
    selectAssociation,
    (association) => association?.location?.city
);

const selectAddress = createSelector(
    selectAssociation,
    (association) => association?.location?.address
);

const selectZipCode = createSelector(
    selectAssociation,
    (association) => association?.location?.zipCode
);

const selectLocation = createSelector(
    selectAssociation,
    (association) => association?.location
);

const selectLocationShort = createSelector(
    selectHasCity,
    selectHasZipCode,
    selectCountry,
    selectCity,
    selectZipCode,
    (hasCity, hasZipCode, country, city, zipCode) => {
        let location = country;
        if (hasCity) {
            location += ', ' + city;
        }
        if (hasZipCode) {
            location += ' ' + zipCode;
        }
        return location;
    }
);

const selectUsers = createSelector(
    selectAssociation,
    (association) => association?.users
);

const selectUsersForAdvancedSelect = createSelector(selectUsers, (users) => {
    return users?.map((user) => ({
        name: user.firstName + ' ' + user.familyName,
        value: user.id,
    }));
});

const selectIsMyAssociation = createSelector(
    UserSelectors.selectAssociationId,
    selectAssociation,
    (userAssociationId, selectedAssociation) =>
        userAssociationId === selectedAssociation?.id
);

const selectCanCreateMission = createSelector(
    selectHasCode,
    selectHasDescription,
    selectHasLocation,
    UserSelectors.selectHasSignature,
    (hasCode, hasDescription, hasLocation, hasSignature) =>
        hasCode && hasDescription && hasLocation && hasSignature
);

const selectOnboardingTasks = createSelector(
    selectHasCode,
    selectHasDescription,
    selectHasLocation,
    selectHasLogo,
    selectHasCover,
    selectHasMedia,
    selectHasCreatedMission,
    UserSelectors.selectHasSignature,
    selectCanCreateMission,
    (
        hasCode,
        hasDescription,
        hasLocation,
        hasLogo,
        hasCover,
        hasMedia,
        hasCreatedMission,
        hasSignature,
        canCreateMission
    ) => {
        const tasks: OnboardingTask[] = [
            {
                translocoKey: 'code',
                completed: hasCode,
                action: HighlightActions.createHighlightEvent({
                    event: {
                        highlightedElement: 'association-code',
                        targetUrl: UrlHelpers.getAssociationUrl(
                            EAssociationUrl.AssociationEditPage
                        ),
                    },
                }),
            },
            {
                translocoKey: 'description',
                completed: hasDescription,
                action: HighlightActions.createHighlightEvent({
                    event: {
                        highlightedElement: 'association-description',
                        targetUrl: UrlHelpers.getAssociationUrl(
                            EAssociationUrl.AssociationEditPage
                        ),
                    },
                }),
            },
            {
                translocoKey: 'location',
                completed: hasLocation,
                action: HighlightActions.createHighlightEvent({
                    event: {
                        highlightedElement: 'association-location',
                        targetUrl: UrlHelpers.getAssociationUrl(
                            EAssociationUrl.AssociationEditPage
                        ),
                    },
                }),
            },
            {
                translocoKey: 'logo',
                completed: hasLogo,
                action: HighlightActions.createHighlightEvent({
                    event: {
                        highlightedElement: 'association-logo',
                        targetUrl: UrlHelpers.getAssociationUrl(
                            EAssociationUrl.AssociationViewPage
                        ),
                    },
                }),
            },
            {
                translocoKey: 'cover',
                completed: hasCover,
                action: HighlightActions.createHighlightEvent({
                    event: {
                        highlightedElement: 'association-cover',
                        targetUrl: UrlHelpers.getAssociationUrl(
                            EAssociationUrl.AssociationViewPage
                        ),
                    },
                }),
            },
            {
                translocoKey: 'media',
                completed: hasMedia,
                action: HighlightActions.createHighlightEvent({
                    event: {
                        highlightedElement: 'association-media',
                        targetUrl: UrlHelpers.getAssociationUrl(
                            EAssociationUrl.AssociationEditPage
                        ),
                    },
                }),
            },
            {
                translocoKey: 'signature',
                completed: hasSignature,
                action: HighlightActions.createHighlightEvent({
                    event: {
                        highlightedElement: 'account-signature',
                        targetUrl: UrlHelpers.getAccountUrl(
                            EAccountUrl.AccountSettings
                        ),
                    },
                }),
            },
            {
                translocoKey: 'mission',
                completed: hasCreatedMission,
                disabled: !canCreateMission,
                disabledTooltipTranslocoKey: 'mission-disabled',
                action: HighlightActions.createHighlightEvent({
                    event: {
                        highlightedElement: 'association-create-mission',
                    },
                }),
            },
        ];
        return tasks;
    }
);

export const AssociationSelectors = {
    selectAssociation,
    selectAssociationIsLoaded,
    selectHasPendingJoinRequest,
    selectIsLoading,
    selectIsLoadingFileUpload,
    selectLocation,
    selectHasSomeLocationSet,
    selectError,
    selectName,
    selectNameFirstLetter,
    selectActivityFields,
    selectHasLogo,
    selectHasCover,
    selectHasMedia,
    selectHasDescription,
    selectHasAddress,
    selectCanCreateMission,
    selectLogo,
    selectCover,
    selectMedia,
    selectDescription,
    selectAddress,
    selectHasLocation,
    selectLocationShort,
    selectIsMyAssociation,
    selectUsersForAdvancedSelect,
    selectOnboardingTasks,
};
