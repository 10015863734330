<!-- <apx-chart
    #chart
    *ngIf="isReady"
    class="w-full h-full flex flex-col"
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis"
    [stroke]="chartOptions.stroke"
    [dataLabels]="chartOptions.dataLabels"
    [labels]="chartOptions.labels"
    [legend]="chartOptions.legend"
    [grid]="chartOptions.grid"
    [plotOptions]="chartOptions.plotOptions"
    [fill]="chartOptions.fill"
    [responsive]="chartOptions.responsive"
    [colors]="chartOptions.colors"
></apx-chart> -->
<apx-chart
    #chart
    *ngIf="isReady"
    class="w-full h-full flex flex-col"
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis"
    [labels]="chartOptions.labels"
    [legend]="chartOptions.legend"
    [grid]="chartOptions.grid"
    [plotOptions]="chartOptions.plotOptions"
    [fill]="chartOptions.fill"
    [responsive]="chartOptions.responsive"
    [colors]="chartOptions.colors"
    [tooltip]="chartOptions.tooltip"
></apx-chart>
