import { AvatarAssetViewModel } from 'app/api/model/avatarAssetViewModel';

export function getAvatarAssetPath(asset: AvatarAssetViewModel) {
    return (
        'assets/basile/' +
        asset.avatarAssetType.toString().toLowerCase() +
        '/' +
        asset.name +
        '.svg'
    );
}
