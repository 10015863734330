import { Component, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'bslk-answer-request',
    standalone: true,
    templateUrl: './answer-request.component.html',
    imports: [MatIconModule],
})
export class BslkAnswerRequestComponent {
    @Output() onAnswer: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    answer(answer: boolean) {
        this.onAnswer.emit(answer);
    }
}
