<div
    class="fuse-alert-container"
    *ngIf="!dismissible || (dismissible && !dismissed)"
    [@fadeIn]="!dismissed"
    [@fadeOut]="!dismissed"
>
    <!-- Border -->
    <div class="fuse-alert-border" *ngIf="appearance === 'border'"></div>

    <!-- Icon -->
    <div class="fuse-alert-icon my-auto" *ngIf="showIcon">
        <!-- Custom icon -->
        <div class="fuse-alert-custom-icon">
            <ng-content select="[fuseAlertIcon]"></ng-content>
        </div>

        <!-- Default icons -->
        <div class="fuse-alert-default-icon">
            <mat-icon
                *ngIf="type === 'primary'"
                [svgIcon]="'heroicons_solid:check-circle'"
            ></mat-icon>

            <mat-icon
                *ngIf="type === 'accent'"
                [svgIcon]="'heroicons_solid:check-circle'"
            ></mat-icon>

            <mat-icon
                *ngIf="type === 'warn'"
                [svgIcon]="'heroicons_solid:exclamation-triangle'"
            ></mat-icon>

            <mat-icon
                *ngIf="type === 'basic'"
                [svgIcon]="'heroicons_solid:check-circle'"
            ></mat-icon>

            <mat-icon
                *ngIf="type === 'info'"
                [svgIcon]="'heroicons_solid:information-circle'"
            ></mat-icon>

            <mat-icon
                *ngIf="type === 'success'"
                [svgIcon]="'heroicons_solid:check-circle'"
            ></mat-icon>

            <mat-icon
                *ngIf="type === 'warning'"
                [svgIcon]="'heroicons_solid:exclamation-triangle'"
            ></mat-icon>

            <mat-icon
                *ngIf="type === 'error'"
                [svgIcon]="'heroicons_solid:x-circle'"
            ></mat-icon>
        </div>
    </div>

    <!-- Content -->
    <div class="fuse-alert-content">
        <div class="fuse-alert-title">
            <ng-content select="[fuseAlertTitle]"></ng-content>
        </div>

        <div class="fuse-alert-message">
            <ng-content></ng-content>
            <div class="inline" *ngIf="message">
                {{ shownMessage }}<span *ngIf="!showAll">...</span>
                <div class="inline">
                    <span
                        (click)="showAll = !showAll"
                        class="font-bold cursor-pointer"
                        >&nbsp;Voir {{ showAll ? "moins" : "plus" }}</span
                    >
                </div>
            </div>
        </div>
    </div>

    <!-- Dismiss button -->
    <button
        class="fuse-alert-dismiss-button"
        mat-icon-button
        (click)="dismiss()"
    >
        <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
    </button>
</div>
