import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyService } from 'app/api';
import { CompanyFiltersFormService } from 'app/shared/services/form/company-filters-form.service';
import { AlertActions } from 'app/store/alert/alert.actions';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { CompanyFiltersActions } from './company-filters.action';

@Injectable()
export class CompanyFiltersEffects {
    loadFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyFiltersActions.loadFiltersRequest),
            exhaustMap(() => {
                return this.companyService.companyFiltersGet().pipe(
                    map((filters) => {
                        this.companyFiltersFormService.updateForm(filters);
                        return CompanyFiltersActions.loadFiltersRequestSuccess({
                            filters,
                        });
                    }),
                    catchError((httpResponse) =>
                        of(
                            CompanyFiltersActions.loadFiltersRequestFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    updateFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyFiltersActions.updateFiltersRequest),
            exhaustMap(() => {
                const filters = this.companyFiltersFormService.getEntity();
                return this.companyService.companyFiltersPatch(filters).pipe(
                    map(() =>
                        CompanyFiltersActions.updateFiltersRequestSuccess()
                    ),
                    catchError((httpResponse) =>
                        of(
                            CompanyFiltersActions.updateFiltersRequestFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    dispatchAlertOnSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyFiltersActions.updateFiltersRequestSuccess),
            map(() =>
                AlertActions.displaySuccess({
                    key: 'company-filters',
                })
            )
        )
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private companyService: CompanyService,
        private companyFiltersFormService: CompanyFiltersFormService
    ) {}
}
