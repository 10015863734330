<ng-container *ngrxLet="mission$ as mission">
    <cover-template
        *ngrxLet="isLoading$ as isLoading"
        [coverUrl]="mission?.coverUrl"
        [logoUrl]="mission?.associationLogoUrl"
        [logoFirstLetter]="mission?.associationName[0]"
        [title]="mission?.name"
        [isLoading]="isLoading"
    >
        <div class="w-full flex justify-end" coverActions>
            <a
                [routerLink]="
                    ((isAdmin$ | async) ? '/a' : '') +
                    '/missions/' +
                    mission?.id
                "
            >
                <button
                    mat-flat-button
                    class="border-2 border-solid border-primary bg-white"
                >
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_outline:eye'"
                    ></mat-icon>
                    <span class="ml-2">{{
                        "missionmanage.view-mission" | transloco
                    }}</span>
                </button>
            </a>
            <ng-content select="[coverActions]"></ng-content>
        </div>

        <div class="w-full flex justify-end" headerActions>
            <ng-content select="[headerActions]"></ng-content>
        </div>

        <div class="w-full flex flex-col gap-6" leftColumn>
            <mission-slot-picker *ngIf="!isLoading"></mission-slot-picker>
            <ng-content select="[leftColumn]"></ng-content>
            <mission-members
                [class.order-first]="mission?.isTeamBuilding"
                *ngIf="!isLoading && (hasSelectedSlot$ | async)"
                [members]="selectedSlotUsers$ | async"
                [isAdmin]="isAdmin$ | async"
            ></mission-members>
        </div>

        <ng-content select="[rightColumn]"></ng-content>

        <mission-tasks *ngIf="!isLoading" class="w-full"> </mission-tasks>

        <ng-content></ng-content>
    </cover-template>
</ng-container>
