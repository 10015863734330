import {
    Directive,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { isNil } from 'lodash-es';
@Directive()
export abstract class FormControlComponent implements OnInit, OnChanges {
    @Input() label: string;
    @Input() labelTooltip: string;
    @Input() isLabelBold = false;
    @Input() value: any;
    @Input() control: FormControl;
    @Input() isReadonly: boolean = false;

    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

    get isNil() {
        return isNil(this.value);
    }

    get isInvalidAndTouched() {
        if (isNil(this.control)) {
            return false;
        }
        return !this.control.valid && this.control.touched;
    }

    get hasRequiredValidator(): boolean {
        if (!this.control.validator) return false;

        const originalValue = this.control.value;

        // Temporarily set the control's value to null to test for the 'required' validator
        this.control.setValue(null);
        const isInvalidWhenEmpty = this.control.invalid;

        // Reset the control's value to its original value
        this.control.setValue(originalValue);

        return isInvalidWhenEmpty;
    }

    extraOnInit?: () => void;
    extraOnChanges?: (changes: SimpleChanges) => void;

    ngOnInit(): void {
        if (!isNil(this.control?.value) && isNil(this.value)) {
            this.value = this.control.value;
        }
        if (this.extraOnInit) {
            this.extraOnInit();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.extraOnChanges) {
            this.extraOnChanges(changes);
        }
    }

    setValue(value: any) {
        if (this.isReadonly || this.control?.disabled) {
            return;
        }

        this.value = value;
        this.valueChange.emit(value);
        if (this.control) {
            this.control.setValue(this.value);
            this.control.markAsDirty();
        }
    }
}
