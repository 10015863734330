import {
    Int32UserRankingViewModel,
    Int32UserRankingViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const RankingAnswersActions = createRessourceListActionGroup<
    Int32UserRankingViewModel,
    Int32UserRankingViewModelPaginatedListDto
>('RankingAnswers');
