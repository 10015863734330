import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DemonstrationService } from 'app/api';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { DemonstrationActions } from './demonstration.action';

@Injectable()
export class DemonstrationEffects {
    loadSteps$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemonstrationActions.loadStepsRequest),
            exhaustMap(() => {
                return this.demonstrationService.demonstrationStepsGet().pipe(
                    map((steps) =>
                        DemonstrationActions.loadStepsRequestSuccess({
                            steps,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            DemonstrationActions.loadStepsRequestFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    executeStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DemonstrationActions.executeStepRequest),
            exhaustMap(({ step }) => {
                return this.demonstrationService
                    .demonstrationExecuteStepPost({ step })
                    .pipe(
                        map(() =>
                            DemonstrationActions.executeStepRequestSuccess({
                                step,
                            })
                        ),
                        catchError((httpResponse) =>
                            of(
                                DemonstrationActions.executeStepRequestFail({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );
    constructor(
        private actions$: Actions,
        private demonstrationService: DemonstrationService
    ) {}
}
