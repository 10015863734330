import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EUserRole } from 'app/api';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { map, take } from 'rxjs';
import { getUserFromLocalStorage } from './local-storage.utils';

export const redirectIfNotAllowed = (allowedRoles: EUserRole[]) => {
    const router: Router = inject(Router);
    const store: Store = inject(Store);

    return store.select(UserSelectors.selectUserRole).pipe(
        take(1),
        map((userRoleFromStore) => {
            let userRole: EUserRole = userRoleFromStore;
            if (!userRole) {
                const user = getUserFromLocalStorage();
                userRole = user?.userRole;
            }
            if (!userRole || !allowedRoles.includes(userRole)) {
                const urlTree = router.parseUrl(``);
                return urlTree;
            } else {
                return true;
            }
        })
    );
};
