import { RichTextViewModel } from 'app/api';
import { getUserFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { BaseBlockComponent } from '../base-block/base-block.component';

export abstract class BaseRichTextBlockComponent extends BaseBlockComponent {
    private replacements = {
        Name: getUserFromLocalStorage().firstName,
    };

    private callToActions = ['CallToComment'];
    protected activatedCallToActions: string[] = [];

    get richText(): RichTextViewModel[] {
        return this.block.richText.map((rt) => this.processRichText(rt));
    }

    private processRichText(richText: RichTextViewModel): RichTextViewModel {
        let modifiedRichText: RichTextViewModel = { ...richText };

        if (modifiedRichText.plainText) {
            // Handle placeholders
            Object.keys(this.replacements).forEach((placeholder) => {
                const placeHolderRegex = new RegExp(`{{${placeholder}}}`, 'g');
                modifiedRichText.plainText =
                    modifiedRichText.plainText!.replace(
                        placeHolderRegex,
                        this.replacements[placeholder]
                    );
            });

            // Handle call to actions
            this.callToActions.forEach((callToAction) => {
                const callToActionRegex = new RegExp(
                    `\\[\\[${callToAction}\\]\\]`,
                    'g'
                );
                modifiedRichText.plainText =
                    modifiedRichText.plainText!.replace(callToActionRegex, '');
                this.activatedCallToActions.push(callToAction);
            });
        }

        return modifiedRichText;
    }
}
