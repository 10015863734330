import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    CompanyAgencyViewModel,
    CompanyViewModel,
    ECompanyState,
    EFeature,
    ELoginType,
    LocationViewModel,
} from 'app/api';
import { getEnumValues } from 'app/shared/utils/enum-helpers.utils';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';
import { IFormService } from './form-service';
import { LocationFormService } from './location-form.service';

@Injectable({
    providedIn: 'root',
})
export class CompanyFormService
    extends LocationFormService
    implements IFormService<CompanyViewModel>
{
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder) {
        super(fb);
        this.initForm();
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            id: [''],
            location: this.initLocationForm(),
            dateCreated: [''],
            dateUpdated: [''],
            dateArchived: [''],
            name: ['', Validators.required],
            loginType: [ELoginType.Google],
            state: [ECompanyState.Prospect],
            domain: ['', Validators.required],
            rcsNumber: ['', Validators.required],
            rcsLocation: ['', Validators.required],
            capital: ['', Validators.required],
            legalForm: [''],
            companyLegalId: [''],
            taxDeductibleSubscriptionAmount: [''],
            contractStartDate: [''],
            yearlyVolunteeringDays: [null],
            maximumUsers: [2],
            allowedFeatures: [getEnumValues(EFeature), Validators.required],
            agencies: this.fb.array([]),
        });
        return this.formGroup;
    }

    createAgency(agency?: CompanyAgencyViewModel): FormGroup {
        const formGroup = this.fb.group({
            id: [agency?.id ?? 0],
            location: this.createLocationFormGroup(agency?.location),
        });
        return formGroup;
    }

    updateForm(entity: CompanyViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
        this.patchLocation(entity.location);
        this.formGroup.controls.agencies = this.fb.array([]);
        let agenciesControl = this.formGroup.controls.agencies as FormArray;
        if (entity.agencies) {
            entity.agencies.forEach((agency) => {
                const agencyControl = this.createAgency(agency);
                agenciesControl.push(agencyControl);
            });
        }
    }

    getEntity(): { [key: string]: CompanyViewModel } {
        this.formGroup.controls.agencies.updateValueAndValidity();
        this.formGroup.updateValueAndValidity();

        const companyViewModel = returnOnlyNonNullFields<CompanyViewModel>(
            this.formGroup.value
        ) as CompanyViewModel;

        companyViewModel.location = returnOnlyNonNullFields<LocationViewModel>(
            companyViewModel.location
        );

        return { companyViewModel };
    }

    get getFormGroup() {
        return this.formGroup;
    }
}
