import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DonationCampaignCustomizationService } from 'app/api';
import { DonationCampaignCustomizeDialogService } from 'app/modules/company-admin/donation-campaigns/customize-dialog/customize-dialog.service';
import { DonationCampaignCustomizationFormService } from 'app/shared/services/form/donation-campaign-customization-form.service';
import { AlertActions } from 'app/store/alert/alert.actions';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { DonationCampaignCustomizationActions } from './donation-campaign-customization.action';

@Injectable()
export class DonationCampaignCustomizationEffects {
    openDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DonationCampaignCustomizationActions.openDialog),
                tap(({ input }) => {
                    this.dialogService.open(input);
                })
            ),
        { dispatch: false }
    );

    closeDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DonationCampaignCustomizationActions.closeDialog),
                tap(() => {
                    this.dialogService.close();
                })
            ),
        { dispatch: false }
    );

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                DonationCampaignCustomizationActions.loadDonationCampaignCustomizationRequest
            ),
            exhaustMap(({ donationCampaignId }) => {
                return this.apiService
                    .donationCampaignCustomizationGet({
                        donationCampaignId,
                    })
                    .pipe(
                        map((donationCampaignCustomization) => {
                            this.formService.updateForm(
                                donationCampaignCustomization
                            );
                            return DonationCampaignCustomizationActions.loadDonationCampaignCustomizationRequestSuccess(
                                { donationCampaignCustomization }
                            );
                        }),
                        catchError((httpResponse) =>
                            of(
                                DonationCampaignCustomizationActions.loadDonationCampaignCustomizationRequestFailure(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                DonationCampaignCustomizationActions.updateDonationCampaignCustomizationRequest
            ),
            exhaustMap(() => {
                const formValue = this.formService.getEntity();
                return this.apiService
                    .donationCampaignCustomizationPut(formValue)
                    .pipe(
                        map(() =>
                            DonationCampaignCustomizationActions.updateDonationCampaignCustomizationRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                DonationCampaignCustomizationActions.updateDonationCampaignCustomizationRequestFailure(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    createOrUpdateSpecificCampaign$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                DonationCampaignCustomizationActions.createOrUpdateSpecificDonationCampaignCustomizationRequest
            ),
            exhaustMap(({ donationCampaignId }) => {
                const formValue = this.formService.getEntity();
                const params = {
                    id: donationCampaignId,
                    donationCampaignCustomizationViewModel:
                        formValue.donationCampaignCustomizationViewModel,
                };
                return this.apiService
                    .donationCampaignCustomizationIdPut(params)
                    .pipe(
                        map(() =>
                            DonationCampaignCustomizationActions.createOrUpdateSpecificDonationCampaignCustomizationRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                DonationCampaignCustomizationActions.createOrUpdateSpecificDonationCampaignCustomizationRequestFailure(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    closeDialogOnSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                DonationCampaignCustomizationActions.updateDonationCampaignCustomizationRequestSuccess,
                DonationCampaignCustomizationActions.createOrUpdateSpecificDonationCampaignCustomizationRequestSuccess
            ),
            map(() => {
                this.dialogService.close();
                return AlertActions.displaySuccess({
                    key: 'donation-campaign-customization',
                });
            })
        )
    );

    constructor(
        private actions$: Actions,
        private apiService: DonationCampaignCustomizationService,
        private dialogService: DonationCampaignCustomizeDialogService,
        private formService: DonationCampaignCustomizationFormService
    ) {}
}
