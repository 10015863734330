<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    (openedChanged)="openedChanged($event)"
    [name]="'editDateDrawer'"
    [position]="'right'"
    #editDateDrawer
>
    <div class="flex flex-col w-full max-h-screen bg-card">
        <div class="flex gap-2 flex-row items-center p-6 text-white bg-warn">
            <div class="ml-3 text-2xl font-semibold tracking-tight">
                {{ "csrapproach.configure-a-date" | transloco }}
            </div>
            <button
                class="ml-auto"
                mat-icon-button
                (click)="editDateDrawer.close()"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"
                ></mat-icon>
            </button>
        </div>

        <div
            class="p-4 flex flex-col gap-5 justify-between h-full overflow-auto"
        >
            <form
                *ngIf="formGroup"
                class="p-3 flex flex-col gap-3"
                [formGroup]="formGroup"
            >
                <mat-form-field
                    [subscriptSizing]="'dynamic'"
                    floatLabel="always"
                    (click)="picker.open()"
                >
                    <mat-label>{{ "csrapproach.date" | transloco }}</mat-label>

                    <input
                        class="cursor-pointer text-center"
                        matInput
                        [formControlName]="'date'"
                        readonly
                        [matDatepicker]="picker"
                    />

                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <div class="w-full flex flex-col">
                    <mat-form-field
                        [subscriptSizing]="'dynamic'"
                        class="w-full"
                    >
                        <mat-label>{{
                            "csrapproach.name" | transloco
                        }}</mat-label>
                        <input matInput [formControlName]="'name'" required />
                        <mat-error
                            *ngIf="formGroup.controls.name.hasError('required')"
                        >
                            {{ "bslk.form.required" | transloco }}
                        </mat-error>
                    </mat-form-field>
                    <bslk-length-info
                        [text]="formGroup.controls.name.value"
                        [maxLength]="100"
                    ></bslk-length-info>
                </div>

                <div class="w-full flex flex-col">
                    <mat-form-field
                        [subscriptSizing]="'dynamic'"
                        class="w-full"
                    >
                        <mat-label>{{
                            "csrapproach.description" | transloco
                        }}</mat-label>
                        <textarea
                            matInput
                            [formControlName]="'description'"
                        ></textarea>
                    </mat-form-field>
                    <bslk-length-info
                        [text]="formGroup.controls.description.value"
                        [maxLength]="250"
                    ></bslk-length-info>
                </div>

                <!-- Actions -->
                <div class="flex items-center justify-end">
                    <bslk-classic-button
                        [isDisabled]="!formGroup.valid"
                        (onClick)="update()"
                        [label]="'csrapproach.validate' | transloco"
                    ></bslk-classic-button>
                </div>
            </form>
        </div>
    </div>
</fuse-drawer>
