import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ValidationService } from 'app/api';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { UserActions } from '../user/user/user.actions';
import { ValidationActions } from './validation.actions';
import { ValidationSelectors } from './validation.selectors';

@Injectable()
export class ValidationEffects {
    sendMail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ValidationActions.sendEmailRequest),
            mergeMap(({ email }) =>
                this.validationService
                    .validationResetPasswordEmailPost({ email })
                    .pipe(
                        map(() => ValidationActions.sendEmailRequestSuccess()),
                        catchError((httpResponse) =>
                            of(
                                ValidationActions.sendEmailRequestFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    )
            )
        )
    );

    sendCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ValidationActions.sendCodeRequest),
            concatLatestFrom(() =>
                this.store.select(ValidationSelectors.selectEmail)
            ),
            mergeMap(([{ code }, email]) =>
                this.validationService
                    .validationResetPasswordEmailCodePost({
                        email,
                        body: `"${code}"`,
                    })
                    .pipe(
                        map(() => ValidationActions.sendCodeRequestSuccess()),
                        catchError((httpResponse) =>
                            of(
                                ValidationActions.sendCodeRequestFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    )
            )
        )
    );

    sendPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ValidationActions.sendSetPasswordRequest),
            concatLatestFrom(() =>
                this.store.select(ValidationSelectors.selectEmail)
            ),
            mergeMap(([{ password }, email]) =>
                this.validationService
                    .validationResetPasswordEmailSetPost({
                        email,
                        body: `"${password}"`,
                    })
                    .pipe(
                        map(() =>
                            ValidationActions.sendSetPasswordRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                ValidationActions.sendSetPasswordRequestFail({
                                    error: httpResponse.error,
                                })
                            )
                        )
                    )
            )
        )
    );

    validateEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ValidationActions.validateEmailRequest),
            mergeMap(({ userId, code }) =>
                this.validationService
                    .validationValidateEmailUserIdCodePost({ userId, code })
                    .pipe(
                        map((session) =>
                            ValidationActions.validateEmailRequestSuccess({
                                session,
                            })
                        ),
                        catchError((httpResponse) =>
                            of(
                                ValidationActions.validateEmailRequestFail({
                                    error:
                                        httpResponse.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    )
            )
        )
    );

    signUpSuccessAfterEmailValidation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ValidationActions.validateEmailRequestSuccess),
            map(({ session }) => UserActions.signUpSuccess({ session }))
        )
    );

    redirectAfterPasswordSet$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ValidationActions.sendSetPasswordRequestSuccess),
                tap(() => {
                    this.router.navigateByUrl('/sign-in');
                })
            ),
        { dispatch: false }
    );

    sendNewValidationMail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ValidationActions.newValidationEmailRequest),
            mergeMap(({ userId }) =>
                this.validationService
                    .validationAskEmailValidationUserIdPost({ userId })
                    .pipe(
                        map(() =>
                            ValidationActions.newValidationEmailRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                ValidationActions.newValidationEmailRequestFail(
                                    {
                                        error:
                                            httpResponse.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private router: Router,
        private validationService: ValidationService
    ) {}
}
