import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import { ImportEmployeesDialogComponent } from './import-employees-dialog.component';

@Injectable({ providedIn: 'root' })
export class ImportEmployeesDialogService implements IDialogService {
    private dialogRef: MatDialogRef<ImportEmployeesDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open() {
        this.dialogRef = this.dialog.open(ImportEmployeesDialogComponent);
    }

    close() {
        this.dialogRef.close();
    }
}
