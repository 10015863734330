<!-- Level information -->
<div
    #levelInformationOrigin
    (click)="openPanel()"
    class="cursor-pointer h-full col-span-4 md:col-span-2 lg:col-span-1 shadow-lg relative rounded-2xl p-4 flex items-center gap-6 text-white bg-gradient-to-br from-tertiary-600 to-tertiary-400"
>
    <experience-bar
        class="z-10"
        [secondaryColor]="'white'"
        [barColor]="'warn-gradient'"
    ></experience-bar>
    <mat-icon
        class="absolute text-white h-48 w-48 opacity-20 -rotate-15 top-1/2 -translate-y-1/2 -left-2"
        [svgIcon]="'basile-flat'"
    ></mat-icon>
</div>

<bslk-classic-overlay
    *ngIf="isViewInitialized"
    [isOpen]="isPanelOpen"
    [origin]="levelInformationOriginRef"
    [overflowAuto]="false"
    [color]="'tertiary'"
    (close)="onClose()"
>
    <span title>{{ "regulardashboard.levels" | transloco }}</span>
    <div
        class="flex text-tertiary flex-col w-full items-center gap-4 py-4 justify-center"
    >
        <div class="font-extrabold text-3xl text-center uppercase">
            {{ "regulardashboard.level" | transloco }} {{ shownLevel
            }}<span *ngIf="shownLevel === 100">+</span>
        </div>

        <div
            *ngrxLet="basileConfiguration$ as basileConfiguration"
            class="flex w-full justify-around items-center"
        >
            <mat-icon
                (click)="previous()"
                [svgIcon]="'heroicons_outline:chevron-left'"
                class="icon-size-24"
                [ngClass]="
                    hasPrevious
                        ? 'cursor-pointer text-current'
                        : 'text-transparent'
                "
            ></mat-icon>
            <my-basile
                *ngIf="basileConfiguration"
                [configuration]="basileConfiguration"
                [overrideLevel]="shownLevel"
                class="w-48"
            ></my-basile>
            <mat-icon
                class="icon-size-24"
                (click)="next()"
                [svgIcon]="'heroicons_outline:chevron-right'"
                [ngClass]="
                    hasNext ? 'cursor-pointer text-current' : 'text-transparent'
                "
            ></mat-icon>
        </div>
    </div>
</bslk-classic-overlay>
