import { createReducer, on } from '@ngrx/store';
import { ProjectListActions } from '../project-list/project-list.actions';
import { ProjectActions } from './project.action';
import { ProjectState } from './project.state';

export const projectInitialState: ProjectState = {
    isLoading: false,
    isCreatingLoading: false,
    isUploadFileLoading: false,
    isLoadingCanCreateProject: false,
};

export const projectReducer = createReducer(
    projectInitialState,

    on(ProjectActions.openDialog, (state) => {
        return {
            ...state,
            coverFile: null,
        };
    }),

    on(ProjectActions.createProjectRequest, (state) => {
        return {
            ...state,
            isCreatingLoading: true,
        };
    }),

    on(ProjectActions.createProjectRequestSuccess, (state, { id }) => {
        return {
            ...state,
            isCreatingLoading: false,
            ProjectCreatedId: id,
        };
    }),

    on(ProjectActions.createProjectRequestFail, (state, { error }) => {
        return {
            ...state,
            isCreatingLoading: false,
            error,
        };
    }),

    on(ProjectListActions.UpdateCover, (state) => {
        return {
            ...state,
            isUploadFileLoading: true,
        };
    }),

    on(ProjectActions.loadProjectRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(ProjectActions.loadProjectRequestSuccess, (state, { project }) => {
        return {
            ...state,
            project,
            isLoading: false,
        };
    }),

    on(ProjectActions.loadProjectRequestFail, (state, { error }) => {
        return {
            ...state,
            error,
            isLoading: false,
        };
    }),

    on(ProjectActions.updateProjectRequest, (state) => {
        return {
            ...state,
            isCreatingLoading: true,
        };
    }),

    on(ProjectActions.updateProjectRequestSuccess, (state) => {
        return {
            ...state,
            isCreatingLoading: false,
        };
    }),

    on(ProjectActions.updateProjectRequestFail, (state, { error }) => {
        return {
            ...state,
            isCreatingLoading: false,
            error,
        };
    }),

    on(ProjectActions.loadCanCreateProjectRequest, (state) => {
        return {
            ...state,
            isLoadingCanCreateProject: true,
        };
    }),

    on(
        ProjectActions.loadCanCreateProjectRequestSuccess,
        (state, { response }) => {
            return {
                ...state,
                isLoadingCanCreateProject: false,
                canCreateProject: response,
            };
        }
    ),

    on(ProjectActions.loadCanCreateProjectRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoadingCanCreateProject: false,
            error,
        };
    })
);
