<div *ngIf="!isLoading; else loading" class="description flex flex-col gap-4">
    <div class="flex justify-between">
        <span
            *ngIf="!isEditing"
            class="font-extrabold text-4xl truncate text-transparent bg-clip-text bg-gradient-to-r from-primary to-warn"
            >{{
                csrApproach.title ??
                    ("csrapproach.our-csr-commitment" | transloco)
            }}</span
        >

        <div *ngIf="isEditing" class="flex flex-col min-w-120">
            <mat-form-field [subscriptSizing]="'dynamic'">
                <mat-label>{{ "csrapproach.title" | transloco }}</mat-label>
                <input matInput [formControl]="formGroup.controls.title" />
            </mat-form-field>
            <bslk-length-info
                [text]="formGroup.controls.title.value"
                [maxLength]="80"
            ></bslk-length-info>
        </div>

        <ng-container *ngIf="isCompanyAdmin">
            <bslk-classic-button
                *ngIf="!isEditing"
                [icon]="'heroicons_outline:cog-6-tooth'"
                [label]="'csrapproach.update' | transloco"
                (onClick)="isEditing = true"
            ></bslk-classic-button>
            <bslk-classic-button
                *ngIf="isEditing"
                [isDisabled]="formGroup.invalid"
                [icon]="'heroicons_outline:check'"
                [label]="'csrapproach.validate' | transloco"
                (onClick)="update()"
            ></bslk-classic-button>
        </ng-container>
    </div>
    <div class="flex gap-4 items-stretch min-h-64">
        <div class="flex flex-1">
            <div
                class="text-lg max-h-full w-full bg-gradient-to-br from-primary-300 to-warn-300 p-4 rounded-2xl overflow-y-scroll"
                *ngIf="!isEditing"
            >
                <div
                    class="w-full"
                    [innerHTML]="csrApproach.description | sanitize"
                    *ngIf="csrApproach.description; else noDescription"
                ></div>
                <ng-template #noDescription>
                    {{
                        csrApproach.description ?? "csrapproach.no-description"
                            | transloco
                    }}
                </ng-template>
            </div>

            <div class="flex flex-col h-full w-full" *ngIf="isEditing">
                <quill-editor
                    class="w-full grow overflow-hidden"
                    [formControl]="formGroup.controls.description"
                    [placeholder]="
                        'csrapproach.description-placeholder' | transloco
                    "
                    [modules]="quillModules"
                ></quill-editor>
                <bslk-length-info
                    [text]="formGroup.controls.description.value"
                    [isHtml]="true"
                    [maxLength]="1000"
                ></bslk-length-info>
            </div>
        </div>
        <div class="hidden lg:flex max-h-full flex-1 items-center relative">
            <img
                [src]="
                    csrApproach.imageUrl ??
                    '/assets/images/placeholders/csr-approach.png'
                "
                alt="Illustration"
                class="absolute w-full h-full top-0 left-0 object-cover rounded-xl"
            />
            <div
                *ngIf="isCompanyAdmin"
                class="absolute top-5 left-5 flex gap-4"
            >
                <button
                    (click)="removeImage()"
                    *ngIf="csrApproach.imageUrl && isEditing"
                    [matTooltip]="
                        'csrapproach.restore-default-image' | transloco
                    "
                    mat-mini-fab
                    color="primary"
                >
                    <mat-icon
                        color="accent"
                        class="icon-size-5 svg-white"
                        matPrefix
                        [svgIcon]="'heroicons_solid:x-mark'"
                    ></mat-icon>
                </button>
                <button
                    (click)="openUploadimageDialog()"
                    *ngIf="isEditing"
                    [matTooltip]="'csrapproach.change-image' | transloco"
                    mat-mini-fab
                    color="primary"
                >
                    <mat-icon
                        color="accent"
                        class="icon-size-5 svg-white"
                        matPrefix
                        [svgIcon]="'heroicons_solid:camera'"
                    ></mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="flex gap-4 items-stretch h-64">
        <div class="flex-1"></div>
        <div class="flex-1">
            <div class="rounded-3xl flex-1 h-full overflow-hidden">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full"
                ></ngx-skeleton-loader>
            </div>
        </div>
    </div>
</ng-template>
