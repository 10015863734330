<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    (openedChanged)="openedChanged($event)"
    [mode]="'over'"
    [transparentOverlay]="true"
    [name]="'commentsDrawer'"
    [position]="'right'"
    #detailsDrawer
>
    <div
        *ngrxLet="isLoading$ as isLoading"
        class="flex flex-col w-full max-h-screen bg-card"
    >
        <div
            class="flex gap-2 flex-row items-center px-6 py-3 text-white bg-warn"
            *ngIf="thematic"
        >
            <!-- <mat-icon
                class="icon-size-12 text-current"
                [svgIcon]="awarenessThematicIcons[thematic]"
            ></mat-icon> -->
            <div class="ml-3 text-2xl font-semibold tracking-tight">
                {{ "enums.EAwarenessThematic." + thematic | transloco }}
            </div>
            <button
                class="ml-auto"
                mat-icon-button
                (click)="detailsDrawer.close()"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"
                ></mat-icon>
            </button>
        </div>

        <ng-container *ngrxLet="details$ as details">
            <div
                class="p-4 flex flex-col gap-5 justify-between h-full overflow-auto"
            >
                <div class="flex flex-col gap-2">
                    <span class="font-bold text-lg">{{
                        "courses.campaign-creation.sdg-list" | transloco
                    }}</span>
                    <div
                        *ngIf="details && !isLoading; else loading"
                        class="flex flex-wrap gap-1"
                    >
                        <bslk-tag
                            *ngFor="
                                let sdg of details.sustainableDevelopmentGoals
                            "
                            [size]="'small'"
                            [icon]="sustainableDevlopmentGoals[sdg].icon"
                            [background]="null"
                            [backgroundHexaColor]="
                                sustainableDevlopmentGoals[sdg].color
                            "
                        >
                            {{
                                "enums.ESustainableDevelopmentGoal." + sdg
                                    | transloco
                            }}
                        </bslk-tag>
                    </div>
                </div>

                <div class="flex flex-col gap-2">
                    <span class="font-bold text-lg">{{
                        "courses.campaign-creation.courses" | transloco
                    }}</span>
                    <div
                        *ngIf="details && !isLoading; else loading"
                        class="flex flex-col gap-1"
                    >
                        <span
                            class="font-semibold"
                            *ngFor="
                                let courseTitle of details.courseTitles;
                                let index = index
                            "
                        >
                            {{ index + 1 }}. {{ courseTitle }}
                        </span>
                    </div>
                </div>

                <fuse-alert [type]="'info'">
                    {{ "courses.campaign-creation.thematic-info" | transloco }}
                </fuse-alert>
            </div>
        </ng-container>
    </div>
</fuse-drawer>

<ng-template #loading>
    <div class="flex flex-col gap-1">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-10"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-10"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-10"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-10"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-10"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
