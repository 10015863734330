import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { DonationCampaignViewModel } from 'app/api';

@Component({
    selector: 'gift-card',
    templateUrl: './gift-card.component.html',
    standalone: true,
    imports: [TranslocoModule, NgClass, NgIf, NgTemplateOutlet],
})
export class GiftCardComponent {
    @Input() campaign: DonationCampaignViewModel;
}
