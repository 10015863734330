<div class="bg-accent w-full">
    <div class="flex w-full flex-col p-4 xl:p-6 rounded-xl shadow-xl bg-white">
        <div class="flex flex-col gap-4 md:flex-row justify-between">
            <div class="flex flex-col">
                <span class="font-bold text-2xl"
                    >{{ "companyadmindashboard.welcome-back" | transloco }}
                    {{ firstName$ | async }}
                </span>
                <span>{{
                    "companyadmindashboard.welcome-back-subtitle" | transloco
                }}</span>
            </div>
            <div class="flex justify-around gap-6">
                <div
                    class="flex gap-2 hover:text-warn transition-all duration-500 cursor-pointer relative"
                    (click)="openReportDialog()"
                >
                    <mat-icon
                        [svgIcon]="'heroicons_outline:computer-desktop'"
                        class="text-current"
                    ></mat-icon>
                    <span class="font-bold">{{
                        "companyadmindashboard.report.title" | transloco
                    }}</span>
                    <bslk-tag [size]="'very-small'"
                        ><span class="uppercase">{{
                            "shared.common.new" | transloco
                        }}</span></bslk-tag
                    >
                </div>
                <div
                    class="flex gap-2 hover:text-warn transition-all duration-500 cursor-pointer relative"
                    (click)="openExportDialog()"
                >
                    <mat-icon
                        [svgIcon]="'heroicons_outline:arrow-up-on-square'"
                        class="text-current"
                    ></mat-icon>
                    <span class="font-bold">{{
                        "companyadmindashboard.export.export" | transloco
                    }}</span>
                    <bslk-tag [size]="'very-small'"
                        ><span class="uppercase">{{
                            "shared.common.new" | transloco
                        }}</span></bslk-tag
                    >
                </div>
                <div
                    class="flex gap-2 hover:text-warn transition-all duration-500 cursor-pointer"
                    (click)="openSettingsDialog()"
                >
                    <mat-icon
                        [svgIcon]="'heroicons_outline:cog-6-tooth'"
                        class="text-current"
                    ></mat-icon>
                    <span class="font-bold">{{
                        "companyadmindashboard.configure" | transloco
                    }}</span>
                </div>
            </div>
        </div>

        <div
            *ngrxLet="activeTab$ as activeTab"
            class="grid gap-4 grid-cols-4 w-full mt-5"
        >
            <company-admin-dashboard-header-tab
                (click)="switchTab('overview')"
                class="col-span-4 md:col-span-1"
                [isActive]="activeTab === 'overview'"
                [color]="'tertiary'"
                [icon]="'heroicons_outline:home'"
                [bgIcon]="'heroicons_outline:chart-bar'"
                >{{
                    "companyadmindashboard.tabs.overview" | transloco
                }}</company-admin-dashboard-header-tab
            >
            <company-admin-dashboard-header-tab
                (click)="switchTab('employee')"
                class="col-span-4 md:col-span-1"
                [isActive]="activeTab === 'employee'"
                [color]="'error'"
                [icon]="'heroicons_outline:sun'"
                [bgIcon]="'heroicons_outline:face-smile'"
                >{{
                    "companyadmindashboard.tabs.employee" | transloco
                }}</company-admin-dashboard-header-tab
            >
            <company-admin-dashboard-header-tab
                (click)="switchTab('missions')"
                class="col-span-4 md:col-span-1"
                [isActive]="activeTab === 'missions'"
                [icon]="'heroicons_outline:light-bulb'"
                [color]="'warn'"
                [bgIcon]="'heroicons_outline:calendar-days'"
                >{{
                    "companyadmindashboard.tabs.missions" | transloco
                }}</company-admin-dashboard-header-tab
            >
            <company-admin-dashboard-header-tab
                class="col-span-4 md:col-span-1"
                [isActive]="activeTab === 'accounting'"
                [isDisabled]="true"
                [color]="'primary'"
                [icon]="'heroicons_outline:currency-euro'"
                [bgIcon]="'heroicons_outline:calculator'"
                ><span class="truncate">{{
                    "companyadmindashboard.tabs.accounting" | transloco
                }}</span></company-admin-dashboard-header-tab
            >
        </div>
    </div>
</div>
