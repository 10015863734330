import { AsyncPipe, NgIf } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DonationCampaignActions } from 'app/store/donation/donation-campaign/donation-campaign.action';
import { DonationCampaignSelectors } from 'app/store/donation/donation-campaign/donation-campaign.selectors';
import { environment } from 'environments/environment';
import { NgxCaptureService } from 'ngx-capture';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'donation-certificate',
    templateUrl: './donation-certificate.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        AsyncPipe,
        LetDirective,
        BslkTagButtonComponent,
        NgIf,
    ],
})
export class DonationCertificateComponent implements OnChanges {
    @ViewChild('screen') screen: any;

    @Input() hideActions = false;
    @Input() triggerCapture: string;

    @Output() imageCapture: EventEmitter<string> = new EventEmitter<string>();

    unsubscribeAll: Subject<any> = new Subject<any>();
    isProduction = environment.production;

    chosenProject$ = this.store.select(
        DonationCampaignSelectors.selectChosenProject
    );

    contribution$ = this.store.select(
        DonationCampaignSelectors.selectChosenProjectContribution
    );

    request$ = this.store.select(
        DonationCampaignSelectors.selectDonationRequest
    );

    hasDonationRequestId$ = this.store.select(
        DonationCampaignSelectors.selectHasDonationRequestId
    );

    constructor(
        private store: Store,
        private captureService: NgxCaptureService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.triggerCapture && changes.triggerCapture.currentValue) {
            this.emitCapture();
        }
    }

    download() {
        this.captureService
            .getImage(this.screen.nativeElement, true)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((img) => {
                this.captureService.downloadImage(img);
            });
    }

    emitCapture() {
        this.captureService
            .getImage(this.screen.nativeElement, true)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((img) => {
                this.imageCapture.emit(img);
            });
    }

    shareOnLinkedIn() {
        this.store.dispatch(
            DonationCampaignActions.shareCertificateOnLinkedIn()
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
