import { ESustainableDevelopmentGoal } from 'app/api';

export const QUILL_MODULES: any = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'],
    ],
};

export const SLOT_DEFAULT_HOURS = {
    START: 9,
    END: 17,
    START_AS_STRING: '09:00',
    END_AS_STRING: '17:00',
};

export interface SdgValue {
    color: string;
    icon: string;
    translation?: string;
}

export const sustainableDevelopmentGoals: Record<string, SdgValue> = {
    [ESustainableDevelopmentGoal.NoPoverty]: {
        color: '#E5243B',
        icon: 'mat_outline:reduce_capacity',
    },
    [ESustainableDevelopmentGoal.ZeroHunger]: {
        color: '#DDA63A',
        icon: 'mat_outline:no_food',
    },
    [ESustainableDevelopmentGoal.GoodHealthAndWellBeing]: {
        color: '#4C9F38',
        icon: 'heroicons_outline:heart',
    },
    [ESustainableDevelopmentGoal.QualityEducation]: {
        color: '#C5192D',
        icon: 'heroicons_outline:academic-cap',
    },
    [ESustainableDevelopmentGoal.GenderEquality]: {
        color: '#FF3A21',
        icon: 'heroicons_outline:user-group',
    },
    [ESustainableDevelopmentGoal.CleanWaterAndSanitation]: {
        color: '#26BDE2',
        icon: 'mat_outline:water_drop',
    },
    [ESustainableDevelopmentGoal.AffordableAndCleanEnergy]: {
        color: '#FCC30B',
        icon: 'heroicons_outline:light-bulb',
    },
    [ESustainableDevelopmentGoal.DecentWorkAndEconomicGrowth]: {
        color: '#A21942',
        icon: 'heroicons_outline:briefcase',
    },
    [ESustainableDevelopmentGoal.IndustryInnovationAndInfrastructure]: {
        color: '#FD6925',
        icon: 'heroicons_outline:cog',
    },
    [ESustainableDevelopmentGoal.ReducedInequalities]: {
        color: '#DD1367',
        icon: 'heroicons_outline:scale',
    },
    [ESustainableDevelopmentGoal.SustainableCitiesAndCommunities]: {
        color: '#FD9D24',
        icon: 'heroicons_outline:home',
    },
    [ESustainableDevelopmentGoal.ResponsibleConsumptionAndProduction]: {
        color: '#BF8B2E',
        icon: 'mat_outline:recycling',
    },
    [ESustainableDevelopmentGoal.ClimateAction]: {
        color: '#3F7E44',
        icon: 'heroicons_outline:cloud',
    },
    [ESustainableDevelopmentGoal.LifeBelowWater]: {
        color: '#0A97D9',
        icon: 'mat_outline:waves',
    },
    [ESustainableDevelopmentGoal.LifeOnLand]: {
        color: '#56C02B',
        icon: 'mat_outline:grass',
    },
    [ESustainableDevelopmentGoal.PeaceJusticeAndStrongInstitutions]: {
        color: '#00689D',
        icon: 'heroicons_outline:shield-check',
    },
    [ESustainableDevelopmentGoal.PartnershipsForTheGoals]: {
        color: '#19486A',
        icon: 'feather:target',
    },
};
