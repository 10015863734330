import {
    AsyncPipe,
    JsonPipe,
    NgFor,
    NgIf,
    NgSwitch,
    NgSwitchCase,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormArray, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { LocationSelectionComponent } from 'app/shared/components/location-selection/location-selection.component';
import { MissionFormService } from 'app/shared/services/form/mission-form.service';
import { AssociationActions } from 'app/store/association/association/association.action';
import { MissionListActions } from 'app/store/mission/mission-list/mission-list.actions';
import { MissionActions } from 'app/store/mission/mission/mission.action';
import { MissionSelectors } from 'app/store/mission/mission/mission.selectors';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { QuillEditorComponent } from 'ngx-quill';
import { CreateMissionValidationService } from './create-mission-validation.service';
import { CreateMissionStep0Component } from './steps/step0/create-mission-step-0.component';
import { CreateMissionStep1Component } from './steps/step1/create-mission-step-1.component';
import { CreateMissionStep2Component } from './steps/step2/create-mission-step-2.component';

export type CreateMissionDialogInput = {
    isEdit: boolean;
    isFromEntityPage: boolean;
};

@Component({
    selector: 'create-mission',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './create-mission.component.html',
    styleUrls: ['./create-mission.component.scss'],
    animations: fuseAnimations,
    imports: [
        AsyncPipe,
        CreateMissionStep0Component,
        CreateMissionStep1Component,
        CreateMissionStep2Component,
        FuseAlertComponent,
        FuseScrollbarDirective,
        JsonPipe,
        LocationSelectionComponent,
        LetDirective,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatStepperModule,
        MatTooltipModule,
        NgFor,
        NgIf,
        NgSwitch,
        NgSwitchCase,
        NgxMaterialTimepickerModule,
        QuillEditorComponent,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class CreateMissionDialogComponent implements OnInit {
    @ViewChild('stepper') private stepper: MatStepper;
    @ViewChild('formDiv') formDiv: ElementRef;

    hasTriedToValidateStep = false;

    isCreatingLoading$ = this.store.select(
        MissionSelectors.selectIsCreatingLoading
    );

    constructor(
        private store: Store,
        private missionFormService: MissionFormService,
        private missionValidationService: CreateMissionValidationService,
        @Inject(MAT_DIALOG_DATA) public data: CreateMissionDialogInput
    ) {}

    get formGroup() {
        return this.missionFormService.getFormGroup;
    }

    get hasPreviousStep() {
        return this.stepper?.selectedIndex > 0;
    }

    get isCurrentStepValid() {
        return this.missionValidationService.isStepValid(
            this.stepper?.selectedIndex
        );
    }

    get missionSlotsArray() {
        return this.formGroup.controls.missionSlots as FormArray;
    }

    get getCurrentStepErrors() {
        return this.missionValidationService.getCurrentStepErrors(
            this.stepper?.selectedIndex
        );
    }

    get displayErrorsSummary() {
        return (
            this.hasTriedToValidateStep && this.getCurrentStepErrors.length > 0
        );
    }

    ngOnInit(): void {
        if (!this.data.isEdit) {
            this.missionFormService.initForm();
        }
        this.store.dispatch(
            AssociationActions.loadMineRequest({ displayIsLoading: false })
        );
    }

    nextStep() {
        if (
            this.missionValidationService.isStepValid(
                this.stepper.selectedIndex
            )
        ) {
            this.stepper.next();
        }
    }

    previousStep() {
        this.stepper.previous();
    }

    continue() {
        this.formGroup.updateValueAndValidity();
        if (
            !this.missionValidationService.isStepValid(
                this.stepper.selectedIndex
            )
        ) {
            this.hasTriedToValidateStep = true;
            return;
        }

        this.hasTriedToValidateStep = false;
        if (
            this.stepper.selectedIndex === 2 &&
            this.missionValidationService.isStep2Valid
        ) {
            const actionToDispatch = this.data.isEdit
                ? this.data.isFromEntityPage
                    ? MissionActions.updateMissionRequest()
                    : MissionListActions.UpdateSelectedItem()
                : MissionActions.createMissionRequest();
            this.store.dispatch(actionToDispatch);
            return;
        }
        this.formDiv.nativeElement.scrollTop = 0;
        this.nextStep();
    }

    isStepValid(step: number) {
        return this.missionValidationService.isStepValid(step);
    }

    close() {
        this.store.dispatch(MissionActions.closeDialog());
    }
}
