<div class="w-fit" [class.grayscale]="isDisabled">
    <div class="w-fit m-auto">
        <div
            class="h-24 min-w-24 xl:h-32 xl:min-w-32 bg-tertiary rounded-full border-b-[16px] border-tertiary-800 shadow-xl flex items-center justify-center"
        >
            <mat-icon
                [svgIcon]="icons[badge.badgeId]"
                class="icon-size-12 xl:icon-size-16 text-white"
            ></mat-icon>
        </div>
    </div>
    <div class="w-fit flex flex-col justify-center items-center mt-4">
        <div class="px-4 py-2 bg-tertiary bg-opacity-20 w-fit rounded-xl flex">
            <span
                class="text-2xl xl:text-3xl font-extrabold uppercase text-center text-transparent bg-clip-text bg-gradient-to-r to-primary-400 from-tertiary"
                >{{
                    "bslkbusiness.badge." + badge.badgeId + ".title" | transloco
                }}</span
            >
        </div>

        <span class="font-bold text-md xl:text-lg text-center mt-2">{{
            "bslkbusiness.badge." + badge.badgeId + ".description" | transloco
        }}</span>
    </div>
</div>
