<div [class.grayscale]="!isStarted" class="w-full">
    <div
        [class.text-tertiary]="color === 'tertiary'"
        [class.text-primary]="color === 'primary'"
        [class.text-warn]="color === 'warn'"
        [class.shadow-tertiary]="color === 'tertiary'"
        [class.shadow-primary]="color === 'primary'"
        [class.shadow-warn]="color === 'warn'"
        class="bg-white shadow-light rounded-2xl py-4 px-6 font-bold flex flex-col md:flex-row gap-3 justify-between"
    >
        <div class="flex items-center gap-5">
            <div #summaryButton class="flex">
                <div
                    [ngClass]="{
                    'bg-tertiary shadow-primary hover:bg-primary hover:shadow-tertiary': color === 'tertiary',
                    'bg-primary shadow-tertiary hover:bg-tertiary hover:shadow-primary': color === 'primary',
                    'bg-warn shadow-tertiary hover:bg-tertiary hover:shadow-warn': color === 'warn',
                }"
                    (click)="openPanel()"
                    class="rounded-xl flex flex-col items-center justify-center p-2 shadow-light my-auto cursor-pointer transition-all duration-300"
                >
                    <mat-icon
                        class="text-white icon-size-7"
                        [svgIcon]="'heroicons_outline:list-bullet'"
                    ></mat-icon>
                    <bslk-classic-overlay
                        [isOpen]="isPanelOpen"
                        [origin]="summaryButtonRef"
                        [color]="color"
                        (close)="onClose()"
                    >
                        <span title class="text-2xl font-bold">{{
                            "courses.timeline.summary" | transloco
                        }}</span>
                        <mat-accordion>
                            <mat-expansion-panel
                                class="mb-2"
                                *ngFor="
                                    let course of courses;
                                    let courseIndex = index
                                "
                            >
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="text-tertiary"
                                            >{{ courseIndex + 1 }}.
                                            {{ course.name }}</span
                                        >
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="flex flex-col gap-1">
                                    <div
                                        *ngFor="
                                            let chapter of course.chapters;
                                            let chapterIndex = index
                                        "
                                    >
                                        {{ courseIndex + 1 }}.{{
                                            chapterIndex + 1
                                        }}
                                        {{ chapter.name }}
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </bslk-classic-overlay>
                </div>
            </div>
            <div class="flex flex-col justify-between">
                <div class="flex items-center gap-2 text-md xxl:text-lg">
                    <span>{{
                        "courses.thematic." + (index + 1) | transloco
                    }}</span>
                </div>
                <span class="text-xl xxl:text-2xl">{{
                    "enums.EAwarenessThematic." + thematic | transloco
                }}</span>
            </div>
        </div>

        <div class="flex justify-center">
            <bslk-countdown
                *ngIf="showCountdown"
                [targetDate]="countdownTargetDate"
            ></bslk-countdown>
        </div>
    </div>
</div>
