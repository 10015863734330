// dialog.service.ts

import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DonationCampaignFormService } from 'app/shared/services/form/donation-campaign-form.service';
import { isEmpty, isNil } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class CreateDonationCampaignValidationService {
    constructor(
        private donationCampaignFormService: DonationCampaignFormService,
        private translocoService: TranslocoService
    ) {}

    get formGroup() {
        return this.donationCampaignFormService.getFormGroup;
    }

    get isStep0Valid() {
        return this.getStep0Errors.length === 0;
    }

    get isStep1Valid() {
        return this.getStep1Errors.length === 0;
    }

    get isStep2Valid() {
        return this.getStep2Errors.length === 0;
    }

    isStepValid(stepIndex: number): boolean {
        switch (stepIndex) {
            case 0:
                return this.isStep0Valid;
            case 1:
                return this.isStep1Valid;
            case 2:
                return this.isStep2Valid;
            default:
                return false;
        }
    }

    getCurrentStepErrors(step: number): string[] {
        switch (step) {
            case 0:
                return this.getStep0Errors;
            case 1:
                return this.getStep1Errors;
            case 2:
                return this.getStep2Errors;
        }
    }

    get getStep0Errors(): string[] {
        const errors = [];

        if (this.formGroup.controls.name.invalid) {
            errors.push(
                this.translocoService.translate('donationcampaigns.name-error')
            );
        }

        const isOneEnvelope = this.formGroup.controls.isOneEnvelope.value;
        if (!isOneEnvelope) {
            if (
                isNil(this.formGroup.controls.numberOfDonations.value) ||
                this.formGroup.controls.numberOfDonations.value < 1
            ) {
                errors.push(
                    this.translocoService.translate(
                        'donationcampaigns.number-of-donations-error'
                    )
                );
            }
        }

        if (
            isNil(this.formGroup.controls.donationAmount.value) ||
            this.formGroup.controls.donationAmount.value < 1
        ) {
            if (!isOneEnvelope) {
                errors.push(
                    this.translocoService.translate(
                        'donationcampaigns.gift-amount-error'
                    )
                );
            } else {
                errors.push(
                    this.translocoService.translate(
                        'donationcampaigns.envelope-amount-error'
                    )
                );
            }
        }

        return errors;
    }

    get getStep1Errors(): string[] {
        const errors = [];

        if (this.formGroup.controls.projectIds.invalid) {
            errors.push(
                this.translocoService.translate(
                    'donationcampaigns.projects-error'
                )
            );
        }

        return errors;
    }

    get getStep2Errors(): string[] {
        const errors = [];

        if (
            this.formGroup.controls.isClientCampaign.value &&
            isEmpty(this.formGroup.controls.clientName.value)
        ) {
            errors.push(
                this.translocoService.translate(
                    'donationcampaigns.empty-client-name-error'
                )
            );
        }

        if (this.formGroup.controls.message.invalid) {
            errors.push(
                this.translocoService.translate(
                    'donationcampaigns.message-error'
                )
            );
        }

        if (
            this.formGroup.controls.isOneEnvelope.value &&
            isNil(this.formGroup.controls.expirationDate.value)
        ) {
            errors.push(
                this.translocoService.translate(
                    'donationcampaigns.expiration-date-error'
                )
            );
        }

        return errors;
    }
}
