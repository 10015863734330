<div
    [style]="
        backgroundHexaColor
            ? 'background-color: ' + backgroundHexaColor + ';'
            : ''
    "
    class="max-w-full w-fit flex items-center whitespace-nowrap transition-all duration-500 align-middle"
    [class.rounded]="appearance === 'rounded-sm'"
    [class.rounded-xl]="appearance === 'rounded'"
    [class.rounded-2xl]="appearance === 'rounded-xl'"
    [class.font-semibold]="fontWeight === 'semibold'"
    [class.font-bold]="fontWeight === 'bold'"
    [class.text-sm]="size === 'very-small' || size === 'small'"
    [class.text-lg]="size === 'big'"
    [class.text-xl]="size === 'very-big'"
    [ngClass]="{ 'py-0.5': size === 'very-small' }"
    [class.py-1]="size === 'small'"
    [class.py-2]="size === 'normal'"
    [class.py-3]="size === 'big'"
    [class.py-3]="size === 'very-big'"
    [ngClass]="{ 'px-2.5': size === 'very-small' || size === 'small' }"
    [class.px-4]="size === 'normal'"
    [class.px-5]="size === 'big'"
    [class.px-6]="size === 'very-big'"
    [class.bg-primary-100]="background === 'primary-light'"
    [class.bg-primary]="background === 'primary'"
    [class.bg-warn]="background === 'warn'"
    [class.bg-warn-100]="background === 'warn-light'"
    [class.bg-tertiary]="background === 'tertiary'"
    [class.bg-tertiary-100]="background === 'tertiary-light'"
    [class.bg-quaternary]="background === 'quaternary'"
    [class.bg-quaternary-100]="background === 'quaternary-light'"
    [class.bg-gray-500]="background === 'gray'"
    [class.bg-gray-100]="background === 'gray-light'"
    [class.bg-blue-500]="background === 'blue'"
    [class.bg-blue-100]="background === 'blue-light'"
    [class.bg-white]="background === 'white'"
    [class.bg-black]="background === 'black'"
    [class.text-white]="textColor === 'white'"
    [class.text-warn-800]="textColor === 'warn'"
    [class.text-tertiary-800]="textColor === 'tertiary'"
    [class.text-primary-800]="textColor === 'primary'"
    [class.text-quaternary-800]="textColor === 'quaternary'"
    [class.text-blue-800]="textColor === 'blue'"
    [class.text-gray-800]="textColor === 'gray'"
    [class.bg-opacity-70]="isDisabled"
    [class.cursor-pointer]="isRemovable"
>
    <mat-icon
        *ngIf="icon"
        [class.icon-size-4]="size === 'small' || size === 'very-small'"
        [class.icon-size-5]="
            size === 'normal' || size === 'big' || size === 'very-big'
        "
        class="mr-1 text-current"
        [svgIcon]="icon"
    ></mat-icon>
    <ng-content></ng-content>
    <mat-icon
        *ngIf="isRemovable"
        [class.icon-size-3]="size === 'very-small'"
        [class.icon-size-4]="size === 'small'"
        class="icon-size-5 ml-1 text-current"
        [svgIcon]="'heroicons_outline:x-mark'"
    ></mat-icon>
</div>
