import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    SuperAdminKpi,
    SuperAdminKpiFilters,
    SuperAdminKpiResponse,
} from './super-admin-dashboard.state';

export const SuperAdminDashboardActions = createActionGroup({
    source: 'SuperAdminDashboard',
    events: {
        'Init Dashboard': emptyProps(),
        'Check If Kpi Is Loaded': props<{ kpi: SuperAdminKpi }>(),
        'Load Kpi Request': props<{
            kpi: SuperAdminKpi;
        }>(),
        'Load Kpi Request Success': props<{
            kpi: SuperAdminKpi;
            response: SuperAdminKpiResponse;
        }>(),
        'Load Kpi Request Fail': props<{
            kpi: SuperAdminKpi;
            error: string;
        }>(),
        'Update Kpi Filters': props<{
            kpi: SuperAdminKpi;
            filters: SuperAdminKpiFilters;
        }>(),
        'Expand Kpi': props<{ kpi: SuperAdminKpi }>(),
        'Minimize Kpi': emptyProps(),
    },
});
