import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MissionSlotService, RegularDashboardService } from 'app/api';
import { EAccountUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { Observable, catchError, exhaustMap, filter, map, of } from 'rxjs';
import { AlertActions } from '../alert/alert.actions';
import { HighlightActions } from '../highlight/highlight.action';
import { UserSelectors } from '../user/user/user.selectors';
import { RegularDashboardActions } from './regular-dashboard.actions';

@Injectable()
export class RegularDashboardEffects {
    loadRegularUserRecap$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegularDashboardActions.loadRecapRequest),
            exhaustMap(() => {
                return this.dashboardService.regularDashboardRecapGet().pipe(
                    map((recap) =>
                        RegularDashboardActions.loadRecapRequestSuccess({
                            recap,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            RegularDashboardActions.loadRecapRequestFail({
                                error: httpResponse?.error ?? httpResponse,
                            })
                        )
                    )
                );
            })
        )
    );

    loadPendingManagerApprovals$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegularDashboardActions.loadPendingManagerApprovalsRequest),
            concatLatestFrom(() =>
                this.store.select(UserSelectors.selectIsManager)
            ),
            filter(([, isManager]) => isManager),
            exhaustMap(() => {
                return this.dashboardService
                    .regularDashboardPendingManagerApprovalsGet()
                    .pipe(
                        map((approvalsInfo) =>
                            RegularDashboardActions.loadPendingManagerApprovalsRequestSuccess(
                                { approvalsInfo }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                RegularDashboardActions.loadPendingManagerApprovalsRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ?? httpResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    checkIfCanAnswerApproveRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegularDashboardActions.checkIfCanAnswerApproveRequest),
            concatLatestFrom(() => [
                this.store.select(UserSelectors.selectHasSignature),
            ]),
            map(([{ isApproved, missionSlotId, userId }, hasSignature]) => {
                if (hasSignature) {
                    return RegularDashboardActions.answerApproveRequest({
                        isApproved,
                        missionSlotId,
                        userId,
                    });
                }
                return AlertActions.displayInfo({
                    key: 'signature-needed-for-approval',
                    action: HighlightActions.createHighlightEvent({
                        event: {
                            highlightedElement: 'account-signature',
                            targetUrl: UrlHelpers.getAccountUrl(
                                EAccountUrl.AccountSettings
                            ),
                        },
                    }),
                });
            })
        )
    );

    managerAnswerRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegularDashboardActions.answerApproveRequest),
            exhaustMap(({ isApproved, missionSlotId, userId }) => {
                const apiCall = isApproved
                    ? this.missionSlotService
                          .missionSlotIdManagerAcceptUserIdPatch
                    : this.missionSlotService
                          .missionSlotIdManagerDenyUserIdPatch;
                const boundApiCall = apiCall.bind(this.missionSlotService);
                const apiObservable = boundApiCall({
                    id: missionSlotId,
                    userId,
                }) as Observable<void>;
                return apiObservable.pipe(
                    map(() =>
                        RegularDashboardActions.answerApproveRequestSuccess()
                    ),
                    catchError((httpResponse) =>
                        of(
                            RegularDashboardActions.answerApproveRequestFail({
                                error: httpResponse?.error ?? httpResponse,
                            })
                        )
                    )
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private dashboardService: RegularDashboardService,
        private missionSlotService: MissionSlotService
    ) {}
}
