<fuse-card class="h-64 mb-5">
    <div class="flex w-full">
        <div class="w-1/4 relative">
            <ng-template #nonActive>
                <div
                    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col text-center"
                    *ngIf="!mission.isActive"
                >
                    <mat-icon
                        class="h-15 w-15 m-auto"
                        [svgIcon]="'heroicons_solid:eye-slash'"
                        matPrefix
                    ></mat-icon>
                    <span class="font-bold text-2xl mt-3">{{
                        "associationmissions.not-published" | transloco
                    }}</span>
                </div>
            </ng-template>
            <div *ngIf="mission.coverUrl" class="w-full h-full">
                <img
                    [class.opacity-50]="!mission.isActive"
                    class="h-full object-cover"
                    [src]="mission.coverUrl"
                    alt="Cover image"
                />
                <ng-container *ngIf="!mission.isActive">
                    <ng-container *ngTemplateOutlet="nonActive"></ng-container>
                </ng-container>
            </div>
            <div *ngIf="!mission.coverUrl" class="w-full h-full flex border-r">
                <mat-icon
                    *ngIf="mission.isActive; else nonActive"
                    class="icon-size-16 m-auto"
                    [svgIcon]="'heroicons_solid:photo'"
                >
                </mat-icon>
            </div>
            <div class="absolute top-5 right-5">
                <button
                    mat-mini-fab
                    (click)="openUploadCoverDialog(mission.id)"
                    color="primary"
                >
                    <mat-icon
                        color="accent"
                        class="icon-size-5 svg-white"
                        matPrefix
                        [svgIcon]="'heroicons_solid:camera'"
                    ></mat-icon>
                </button>
            </div>
        </div>

        <div class="w-2/4 border-r-2 flex flex-col">
            <div class="flex flex-col border-b-2 p-6 pb-3">
                <span class="text-2xl truncate font-bold"
                    >{{ mission.name }}
                </span>
                <div class="truncate">
                    <span>{{
                        "associationmissions.managed-by" | transloco
                    }}</span>
                    <span
                        class="truncate font-semibold"
                        [matTooltip]="
                            mission.responsibleUser.firstName +
                            ' ' +
                            mission.responsibleUser.familyName
                        "
                        *ngIf="mission.responsibleUser"
                    >
                        {{ mission.responsibleUser.firstName }}
                        {{ mission.responsibleUser.familyName }}
                    </span>
                    <span
                        [matTooltip]="mission.notRegisteredResponsibleUserEmail"
                        class="truncate font-semibold"
                        *ngIf="!mission.responsibleUser"
                    >
                        {{ mission.notRegisteredResponsibleUserEmail }}
                    </span>
                    <span>
                        {{ "associationmissions.the" | transloco }}
                    </span>
                    <span
                        class="truncate font-semibold"
                        [matTooltip]="lastUpdate | date"
                        >{{ lastUpdate | date }}</span
                    >
                </div>
            </div>
            <div class="flex border-b-2 px-6">
                <div class="hidden lg:flex flex-col p-3 border-r-2 truncate">
                    <span class="font-bold text-2xl text-warn">
                        {{ mission.totalRegisteredVolunteers }}
                    </span>
                    <span class="truncate">{{
                        "associationmissions.registered-volunteers" | transloco
                    }}</span>
                </div>
                <div class="flex-grow text-center flex flex-col p-3 truncate">
                    <span
                        [matTooltip]="
                            formatDateRange(mission.startDate, mission.endDate)
                        "
                        class="font-bold text-2xl text-warn truncate"
                    >
                        {{
                            formatDateRange(mission.startDate, mission.endDate)
                        }}
                    </span>
                    <span class="truncate">{{
                        "associationmissions.mission-dates" | transloco
                    }}</span>
                </div>
                <div class="hidden xl:flex flex-col border-l-2 truncate p-3">
                    <span class="font-bold text-2xl text-warn">
                        {{ mission.missionSlots.length }}
                    </span>
                    <span
                        class="truncate"
                        [matTooltip]="'associationmissions.slots' | transloco"
                        *ngIf="mission.missionSlots.length > 1"
                        >{{ "associationmissions.slots" | transloco }}</span
                    >
                    <span
                        class="truncate"
                        [matTooltip]="'associationmissions.slot' | transloco"
                        *ngIf="mission.missionSlots.length <= 1"
                        >{{ "associationmissions.slot" | transloco }}</span
                    >
                </div>
            </div>
            <div class="flex flex-col overflow-x-scroll px-6 p-2">
                <span class="font-bold">{{
                    "associationmissions.required-skills" | transloco
                }}</span>

                <enum-tag-list
                    class="mt-2"
                    *ngIf="mission.skills"
                    [tags]="mission.skills"
                    [enum]="ESkill"
                    [maxTagsVisible]="3"
                ></enum-tag-list>
                <ng-template #noSkills>
                    <span>{{
                        "associationmissions.no-skill-required" | transloco
                    }}</span>
                </ng-template>
            </div>
        </div>
        <div class="w-1/4 p-6 flex flex-col justify-between">
            <div class="text-right flex flex-col">
                <span class="font-bold text-warn mb-2">{{
                    "associationmissions.mission-location" | transloco
                }}</span>
                <span *ngIf="mission.isRemote">{{
                    "associationmissions.remote" | transloco
                }}</span>
                <div *ngIf="mission.location" class="flex flex-col">
                    <span class="mb-1">{{ mission.location.address }}</span>
                    <span
                        >{{ mission.location.zipCode }}
                        {{ mission.location.city }}</span
                    >
                </div>
            </div>
            <div>
                <bslk-menu
                    (onAction)="onMenuAction($event)"
                    [icon]="'heroicons_solid:cog-6-tooth'"
                    [label]="'associationmissions.actions' | transloco"
                    [items]="[
                            {
                                icon: 'heroicons_solid:eye',
                                routerLink: '/a/missions/' + this.mission.id,
                                label: 'associationmissions.view' | transloco,
                            },
                            {
                                icon: 'heroicons_solid:play',
                                routerLink: '/a/missions/' + this.mission.id + '/manage',
                                label: 'associationmissions.manage' | transloco,
                            },
                            {
                                icon: 'heroicons_solid:document-arrow-up',
                                action: 'publish',
                                label: 'associationmissions.publish' | transloco,
                                hide: this.mission.isActive,
                            },
                            {
                                icon: 'heroicons_solid:pencil',
                                action: 'modify',
                                label: 'associationmissions.modify' | transloco,
                            },
                            {
                                icon: 'heroicons_solid:document-duplicate',
                                action: 'copy',
                                label: 'associationmissions.copy' | transloco,
                            },
                            {
                                icon: 'heroicons_solid:archive-box',
                                action: 'archive',
                                label: 'associationmissions.archive' | transloco,
                            },
                    ]"
                ></bslk-menu>
            </div>
        </div>
    </div>
</fuse-card>
