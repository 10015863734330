import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { BslkAdvancedSelectSyncComponent } from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { BslkCardToggleComponent } from '@bslk/components/form/toggle/card/card-toggle.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { DonationCampaignFormService } from 'app/shared/services/form/donation-campaign-form.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'create-donation-campaign-step-0',
    standalone: true,
    templateUrl: './step-0.component.html',
    imports: [
        AsyncPipe,
        BslkAdvancedSelectSyncComponent,
        BslkInfoLabelComponent,
        BslkCardToggleComponent,
        BslkClassicToggleComponent,
        LetDirective,
        FuseAlertComponent,
        MatIconModule,
        MatInputModule,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
        BslkLengthInfoComponent,
    ],
})
export class CreateDonationCampaignStep0Component {
    unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private donationCampaignFormService: DonationCampaignFormService
    ) {}

    get formGroup() {
        return (
            this.donationCampaignFormService.getFormGroup ??
            this.donationCampaignFormService.initForm()
        );
    }

    get price() {
        return {
            total: this.donationCampaignFormService.getTotalPrice(),
            afterTaxDeductible:
                this.donationCampaignFormService.getPriceAfterTaxDeductible(),
        };
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }
}
