import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { MissionOrProjectDialogService } from "app/modules/association/mission-or-project/mission-or-project.service";
import { tap } from "rxjs";
import { DialogActions } from "./dialog.action";


@Injectable()
export class DialogEffects {
 
  openMissionOrProjectDialog$ = createEffect(() => this.actions$.pipe(
    ofType(DialogActions.openMissionOrProjectDialog),
    tap(() => {
      this.missionOrProjectDialogService.open();
    })
  ), { dispatch: false });

  closeMissionOrProjectDialog$ = createEffect(() => this.actions$.pipe(
    ofType(DialogActions.closeMissionOrProjectDialog),
    tap(() => {
      this.missionOrProjectDialogService.close();
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private missionOrProjectDialogService: MissionOrProjectDialogService
  ) {}
}