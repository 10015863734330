<div class="mt-5 min-h-40 full-calendar flex gap-x-3">
    <div class="w-2/3">
        <full-calendar
            [options]="calendarOptions"
            [events]="calendarEvents | async"
        >
        </full-calendar>
    </div>
    <create-mission-edit-slot
        [missionSlotGuid]="selectedMissionSlotGuid"
        class="w-1/3"
    ></create-mission-edit-slot>
</div>
