import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import {
    CreateDonationCampaignDialogComponent,
    CreateDonationCampaignDialogInput,
} from './create.component';

@Injectable({ providedIn: 'root' })
export class CreateDonationCampaignDialogService implements IDialogService {
    private dialogRef: MatDialogRef<CreateDonationCampaignDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open(input: CreateDonationCampaignDialogInput) {
        this.dialogRef = this.dialog.open(
            CreateDonationCampaignDialogComponent,
            {
                data: input,
                maxWidth: '100vw',
                maxHeight: '100vh',
                disableClose: true,
            }
        );
    }

    close() {
        this.dialogRef?.close();
    }
}
