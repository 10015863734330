<div class="flex flex-col w-full">
    <div class="p-4 md:p-6 xxl:p-8 w-full grow">
        <div class="flex flex-col-reverse md:flex-row mb-4">
            <div class="w-full md:w-3/4 md:pr-3 mt-4 md:mt-0 flex flex-col">
                <div class="flex justify-between">
                    <div class="text-2xl font-bold tracking-tight">
                        {{ "missions.title" | transloco }}
                    </div>

                    <div class="flex w-1/2 xl:w-1/3 items-center">
                        <mat-form-field
                            class="fuse-mat-dense fuse-mat-rounded w-full"
                            [subscriptSizing]="'dynamic'"
                        >
                            <mat-icon
                                class="icon-size-5"
                                matPrefix
                                [svgIcon]="'heroicons_solid:magnifying-glass'"
                            ></mat-icon>
                            <input
                                matInput
                                (input)="searchMission()"
                                [(ngModel)]="search"
                                [autocomplete]="'off'"
                                [placeholder]="'missions.search' | transloco"
                            />
                            <button
                                mat-icon-button
                                matSuffix
                                *ngIf="search"
                                (click)="clearSearch()"
                            >
                                <mat-icon
                                    class="mr-1"
                                    [svgIcon]="'heroicons_solid:x-mark'"
                                >
                                </mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="mt-6">
                    <mission-grid-view
                        *ngIf="filtersInput.view === 'grid'"
                        [missions]="missions$ | async"
                        [isLoading]="isLoading$ | async"
                    >
                    </mission-grid-view>
                    <mission-row-view
                        *ngIf="filtersInput.view === 'row'"
                        [missions]="missions$ | async"
                        [isLoading]="isLoading$ | async"
                        >>
                    </mission-row-view>

                    <!-- If no mission -->
                    <div
                        class="flex flex-col w-full text-center"
                        *ngIf="(totalCount$ | async) === 0"
                    >
                        <span class="font-bold text-2xl">{{
                            "missions.no-missions-title" | transloco
                        }}</span>
                        <span class="mt-1">{{
                            "missions.no-missions-description" | transloco
                        }}</span>
                        <mat-icon
                            class="icon-size-8 mt-3 m-auto"
                            [svgIcon]="'heroicons_outline:face-frown'"
                        ></mat-icon>
                    </div>
                </div>
            </div>
            <mission-filters
                [input]="filtersInput"
                (filterClear)="onFilterClear($event)"
                class="w-full mb-3 md:w-1/4 md:pl-3"
            ></mission-filters>
        </div>
    </div>

    <mat-paginator
        class="bg-white w-full"
        [ngClass]="{ 'pointer-events-none': isLoading$ | async }"
        [length]="totalCount$ | async"
        [pageIndex]="pageIndex$ | async"
        [pageSize]="pageSize$ | async"
        [hidePageSize]="true"
        (page)="pageChangeEvent($event)"
        [showFirstLastButtons]="true"
    >
    </mat-paginator>
</div>
