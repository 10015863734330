<base-dialog
    (onClose)="close()"
    [showFireworks]="true"
    [title]="'coursesection.trophy-chapter.title' | transloco"
    [showActions]="true"
    [color]="'primary'"
>
    <div
        class="w-full h-full flex flex-col gap-3 justify-center items-center relative"
        body
    >
        <div class="flex">
            <div
                class="p-4 bg-primary bg-opacity-20 rounded-full border border-black"
            >
                <mat-icon
                    class="w-48 h-48 animate-reward"
                    [svgIcon]="'bslk_solid:trophy'"
                ></mat-icon>
            </div>
        </div>

        <fuse-alert class="max-w-120" [type]="'success'">
            {{ "coursesection.trophy-chapter.description" | transloco }}
        </fuse-alert>

        <bslk-reward
            class="mt-2 flex justify-center"
            [purposeCoins]="reward.purposeCoins"
            [experiencePoints]="reward.experiencePoints"
            [size]="'large'"
        ></bslk-reward>
    </div>

    <div class="flex justify-end" actions>
        <button
            (click)="close()"
            class="z-10 text-white m-auto bg-primary"
            mat-flat-button
        >
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            <span class="ml-1">{{ "shared.common.close" | transloco }}</span>
        </button>
    </div>
</base-dialog>
