import {
    AssociationRatingViewModel,
    AssociationRatingViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const AssociationRatingListActions = createRessourceListActionGroup<
    AssociationRatingViewModel,
    AssociationRatingViewModelPaginatedListDto
>('AssociationRatingList');
