import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UserService } from 'app/api';
import { UserFormService } from 'app/shared/services/form/user-form.service';
import {
    createCreateItemEffect,
    createDeleteItemEffect,
    createLoadListRequestEffect,
    createReloadEffect,
    createSelectedItemEffect,
    createStartCreationEffect,
    createUpdateSelectedItemEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { UsersActions } from './users.actions';
import { UsersSelectors } from './users.selectors';

@Injectable()
export class UsersEffects {
    loadUsers$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.userService,
        UsersActions,
        UsersSelectors,
        this.userService.userGet
    );

    reload$ = createReloadEffect(this.actions$, UsersActions);

    itemSelected$ = createSelectedItemEffect(
        this.store,
        this.actions$,
        UsersActions,
        UsersSelectors,
        this.userFormService
    );

    updateUser$ = createUpdateSelectedItemEffect(
        this.userService,
        this.actions$,
        UsersActions,
        this.userFormService,
        'user-update',
        this.userService.userPut
    );

    createUser$ = createCreateItemEffect(
        this.userService,
        this.actions$,
        UsersActions,
        this.userFormService,
        'user-create',
        this.userService.userPost
    );

    deleteUser$ = createDeleteItemEffect(
        this.userService,
        this.actions$,
        UsersActions,
        'user-delete',
        this.userService.userDelete
    );

    startCreation$ = createStartCreationEffect(
        this.actions$,
        UsersActions,
        this.userFormService
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private userService: UserService,
        private userFormService: UserFormService
    ) {}
}
