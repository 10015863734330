import { NgFor, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BslkRewardComponent } from '@bslk/components/reward/reward.component';
import { TranslocoModule } from '@ngneat/transloco';
import { RewardViewModel } from 'app/api';
import { BaseDialogComponent } from '../base/base-dialog.component';

export type RewardDialogInput = {
    rewards: RewardViewModel[];
};

@Component({
    selector: 'reward-dialog',
    standalone: true,
    templateUrl: './reward-dialog.component.html',
    imports: [
        BaseDialogComponent,
        BslkRewardComponent,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        NgFor,
        NgIf,
        TranslocoModule,
    ],
})
export class RewardDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<RewardDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RewardDialogInput
    ) {}

    get rewards(): RewardViewModel[] {
        return this.data.rewards;
    }

    close() {
        this.dialogRef.close();
    }
}
