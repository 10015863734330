import { createSelector } from '@ngrx/store';
import { AppState } from '..';

const selectState = (state: AppState) => state.demonstration;

const selectLastExecutedStep = createSelector(
    selectState,
    (state) => state.lastExecutedStep
);

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectError = createSelector(selectState, (state) => state.error);
const selectSteps = createSelector(selectState, (state) => state.steps);

export const DemonstrationSelectors = {
    selectLastExecutedStep,
    selectSteps,
    selectIsLoading,
    selectError,
};
