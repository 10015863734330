<div class="w-full flex h-full">
    <div class="relative w-full m-auto">
        <div style="z-index: 1" class="relative">
            <svg
                id="Basile-tete"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 350 550"
                class="absolute"
            >
                <defs>
                    <style>
                        .cls-1 {
                            stroke: #17191c;
                            stroke-miterlimit: 10;
                        }
                    </style>
                </defs>
                <path
                    class="cls-1"
                    [style]="'fill:' + levelColor"
                    d="M175,51.13c21.35,24.04,40.31,49.89,56.55,77.13,20.65,34.63,37.26,73.88,30.59,112.95-6.99,40.97-32.31,72.29-73.17,81.35-4.34.96-9.2,1.55-13.98,1.5-4.77.06-9.63-.53-13.98-1.5-40.86-9.06-66.18-40.37-73.17-81.35-6.66-39.08,9.94-78.32,30.59-112.95,16.24-27.23,35.2-53.09,56.55-77.13"
                />
            </svg>
            <svg
                class="w-full h-full"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 350 550"
            >
                <defs>
                    <style>
                        .cls-1 {
                            stroke-miterlimit: 10;
                        }
                        .cls-1,
                        .cls-2,
                        .cls-3 {
                            stroke: #17191c;
                        }
                        .cls-2 {
                            fill: none;
                        }
                        .cls-2,
                        .cls-3 {
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 0.94px;
                        }
                        .cls-3 {
                            fill: #f8eee1;
                        }
                    </style>
                </defs>
                <path
                    class="cls-1"
                    [style]="'fill:' + levelColor"
                    d="M132.87,420.47c-.99-2.19-5.61-4.55-6.78-2.5-1.35,2.34,1.04,4.68,3.3,6.51,3.32,2.69,4.94,7.14,4.02,11-.92,3.87-3.34,6.96-7.46,7.66-6.17,1.04-12.7-3.27-15.49-8.82-2.8-5.55-3.24-12.19-1-17.7,2.51-6.15,5.42-6.99,7.22-12.65,2.89-9.07,6.65-34.8,7.49-45.04,1.36-10.58.16-34.88,17.97-42.09h65.63c17.81,7.22,16.6,31.51,17.97,42.09.85,10.24,4.66,35.97,7.56,45.03,1.81,5.66,4.72,6.49,7.24,12.64,2.25,5.5,1.82,12.14-.97,17.7-2.79,5.56-9.31,9.88-15.48,8.85-4.12-.69-6.55-3.78-7.47-7.65-.93-3.87.69-8.32,4-11.01,2.26-1.84,4.64-4.18,3.29-6.52-1.18-2.04-5.89.3-6.87,2.5"
                />
                <path
                    class="cls-1"
                    [style]="'fill:' + levelColor"
                    d="M216.98,420.62c-.87,23.18-14.3,83.95-18.71,103.33-1.23,5.4-.56,10.19,4.2,13.03.03.02-.03.07,0,.09,3.17,1.87,5.85,3.02,11.15,2.74,1.74-.09,4.39-.09,6.26-.04,1.74.04,3.52-.23,5.17.3,1.66.53,3.18,1.71,3.78,3.34s.1,3.71-1.38,4.62c-.97.59-2.17.64-3.3.66-10.48.22-21.8.24-32.29.06-2.89-.05-8.25-.34-7.88-7.02l-.02.09v-88.81c-1.89.74-5.07,1.71-9.09,1.69-3.9-.02-6.98-.96-8.85-1.69v88.81l-.02-.09c.37,6.67-5,6.97-7.88,7.02-10.48.18-21.81.15-32.29-.06-1.14-.02-2.34-.07-3.3-.66-1.48-.91-1.99-2.98-1.38-4.62.61-1.63,2.13-2.8,3.78-3.34,1.66-.53,3.43-.26,5.17-.3,1.87-.05,4.52-.05,6.26.04,5.3.27,7.99-.88,11.15-2.74.03-.02-.03-.07,0-.09,4.75-2.84,5.42-7.63,4.2-13.03-4.4-19.38-17.84-80.15-18.71-103.33h83.97Z"
                />
                <path
                    class="cls-2"
                    d="M214.4,358.94c3.58,35.02,2.67,61.68,2.67,61.68h-84.15s-.91-26.66,2.67-61.68"
                />
                <path class="cls-3" d="M135.6,358.94s2.44-19.7,3.63-26.9" />
                <path class="cls-3" d="M214.4,358.94s-2.26-19.7-3.45-26.9" />
            </svg>
        </div>

        <!-- <svg
            class="w-full h-full"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 350 550"
        >
            <defs>
                <style>
                    .cls-1,
                    .cls-2 {
                        fill: none;
                        stroke: #17191c;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                    }
                    .cls-3,
                    .cls-4 {
                        mix-blend-mode: multiply;
                    }
                    .cls-3,
                    .cls-4,
                    .cls-5 {
                        stroke-width: 0px;
                    }
                    .cls-3,
                    .cls-4 {
                        fill: #8490c8;
                        opacity: 0.35;
                    }
                    .cls-2 {
                        stroke-width: 0.25px;
                    }
                    .cls-6 {
                        isolation: isolate;
                    }
                </style>
            </defs>
            <g class="cls-6">
                <g id="Calque_1">
                    <path
                        class="cls-2"
                        d="m194.35,482.96c.99,1.08,2.81,1.26,3.99.39"
                    />
                    <path
                        [style]="'fill:' + levelColor"
                        class="cls-5"
                        d="m251.19,318.7s25.12.99,33.66,1.15c8.54.16,20.53,9.36,30.38,12.97,9.85,3.61,17.41-2.3,21.51-4.93,4.11-2.63,3.61-12.15,1.64-15.93-1.97-3.78-10.84-1.64-14.12-.49-3.28,1.15-6.73,4.11-9.69,3.45-2.96-.66,1.97-3.28,1.97-3.28,0,0,3.78-.16,7.39-3.94,3.61-3.78-1.81-5.42-3.61-5.58-1.81-.16-10.35,2.63-13.96,3.78-3.61,1.15-10.51,2.79-11,2.79s-44.01-.82-44.01-.82c0,0-65.6,2.78-74.78,2.43s-77.69-2.5-77.69-2.5c0,0-43.88.65-45.67.33-1.79-.33-12.03-3.25-14.3-4.06-2.28-.81-7.15-2.11-9.26-2.11s-3.9.31-4.51,3.15c-.73,3.37,11.01,7.09,11.18,7.74s.16,3.41-3.74,1.46-10.4-5.69-13-5.53c-2.6.16-8.78.98-9.26,5.53-.49,4.55-.65,10.56,4.88,14.3,5.53,3.74,15.76,5.85,21.94,2.93s15.6-9.26,19.83-10.24c4.23-.98,18.85-2.93,23.4-2.76s18.69.16,18.69.16l47.21.61s-8.33,25.97-11.22,36.22-5.45,35.58-2.24,60.91c3.21,25.32,13.78,73.09,14.75,78.86s5.45,20.2,1.6,23.72c-3.85,3.53-9.88,5.25-17.25,5.25s-11.28-.12-11.92,4.69c-.64,4.81,6.73,4.49,17.31,4.49s14.43.32,18.59-.32c4.17-.64,9.62-.32,8.33-15.71-1.28-15.39,0-77.58,0-77.58,0,0,6.41.96,11.22,1.28,4.81.32,10.9-1.6,10.9-1.6,0,0,.32,82.06,0,84.31s-2.56,9.3,9.3,9.3,25.32-.64,28.85-.32c0,0,6.12-.53,5.78-3.35s-2.28-5.04-5.98-5.64c-3.69-.6-9.94.47-12.69.2-2.75-.27-4.37.47-8.06-2.69s-4.63-5.78-4.7-8.8l15.03-74.4,4.16-25.74.76-28.58-1.14-16.28-3.6-15.14-5.87-22.15-5.11-11.36,48.12-.16Z"
                    />
                    <path
                        class="cls-3"
                        d="m124.3,524.35c1.15-.23,2.34-.22,3.51-.2,2.55.04,5.1.08,7.65.12,1.08.02,2.31-.28,3.29.17,1.55.7,2.43,2.79,2.27,4.48s-1.41,3.23-3.02,3.79c-1.09.38-2.28.35-3.44.31-3.47-.12-6.94-.23-10.41-.35-1.38-.05-2.96-.19-3.79-1.3-.58-.78-.62-1.85-.34-2.78.67-2.19,1.98-3.78,4.27-4.24Z"
                    />
                    <path
                        [style]="'fill:' + levelColor"
                        class="cls-5"
                        d="m132.02,405.19l86.07.28s-4.87,39.49-6.26,44.5l-25.03,1.11v-11.4c-2.89.69-6.62,1.32-10.98,1.39-4.54.07-8.42-.47-11.4-1.11.05,3.62.09,7.23.14,10.85l-26.42-1.67s-5.7-37.13-6.12-43.94Z"
                    />
                    <path
                        class="cls-4"
                        d="m27.73,301.82s-1.52,2.6,1.95,4.44c3.47,1.84,7.04,2.38,6.72,6.5-2.6-1.3-6.72-2.38-7.91-3.47s-4.01-3.25-3.47-4.77,1.19-2.28,2.71-2.71Z"
                    />
                    <path
                        class="cls-3"
                        d="m146,319.28s-63.46-.67-67.36-.45-16.93,1.09-21.02,2.6c0,0-9.1,4.01-13.87,7.04-4.77,3.03-13.33,5.96-19.83,3.9-6.5-2.06-13.65-5.53-13.87-11.92-.22-6.39.54-8.88,2.28-9.97s5.09-1.52,5.09-1.52c-3.03,4.98-3.03,7.48-1.41,10.29s8.8,9.47,17.01,7.15c8.17-2.3,20.37-7.58,24.59-8.45,4.23-.87,13.11-3.14,27.19-2.38,14.08.76,62.1.93,62.1.93"
                    />
                    <path
                        class="cls-3"
                        d="m203.05,316.21l48.55-.93s25.83-2.31,39.36,2.77c13.53,5.07,19.99,10.3,27.52,9.99s16.76-1.85,20.91-5.54c-1.08,3.08-2.92,8-9.53,9.53-6.61,1.54-15.53,1.23-19.99-1.08-4.46-2.31-14.3-9.69-22.29-10.46-8-.77-36.44-1.54-36.44-1.54l-46.97-.05-1.12-2.72Z"
                    />
                    <path
                        class="cls-4"
                        d="m313.02,314.46s-1.71-3.99,3.13-5.7c4.84-1.71,8.83-2.99,8.83-2.99,0,0-.71,2.71-1.99,3.42-1.28.71-4.56,1.42-6.41,2.42s-3.56,2.85-3.56,2.85Z"
                    />
                    <path
                        class="cls-2"
                        d="m17.22,309.32c-6.66,9.1,3.14,16.75,10.84,17.62,6.11.69,17.4-5,23.2-7.07,15.37-5.48,30.64-4.23,47.45-3.99l48.4.44"
                    />
                    <path
                        class="cls-2"
                        d="m338.52,323.02c-7.08,4.7-16.16,6.26-24.4,4.2-5.98-1.49-11.35-4.73-17.07-7.02-14.37-5.75-30.36-5.32-45.83-4.8l-48.64.65"
                    />
                    <path
                        class="cls-2"
                        d="m27.66,301.99c-.58,1.19-.05,2.69.9,3.61s2.23,1.42,3.46,1.91,2.51,1.02,3.4,2,1.29,2.54.57,3.66"
                    />
                    <path
                        class="cls-2"
                        d="m312.87,313.63c-.18-.89,0-1.85.5-2.62.82-1.27,2.35-1.87,3.77-2.4,2.47-.91,4.95-1.82,7.42-2.72"
                    />
                    <path
                        class="cls-1"
                        d="m159.24,438.32c10.02,3.69,21.47,3.72,31.52.09"
                    />
                    <path
                        class="cls-1"
                        d="m251.53,308.03c14.96.17,30.78.28,45.74.44,6.48-2.12,13.2-3.45,19.68-5.57,2.72-.89,6.47-1.39,7.73,1.17,1.24,2.52-1.42,5.26-4.05,6.25-2.63.99-5.76,1.45-7.35,3.77,2.02,2.79,4.55,0,7.78-1.2,3.23-1.2,6.32-3.05,9.76-3.23,6.33-.32,9.4,2.62,9.44,8.96s-3.67,10.42-9.48,12.94c-5.82,2.52-12.57,2.16-18.61.24-6.04-1.92-9.66-5.57-15.39-8.28-13.43-6.35-31.02-3.66-45.87-4.62"
                    />
                    <path
                        class="cls-1"
                        d="m98.55,308.04c-14.96.04-30.78.03-45.74.07-6.46-2.18-13.17-3.55-19.63-5.73-2.71-.91-6.45-1.44-7.74,1.11-1.26,2.51,1.38,5.27,4,6.29s5.75,1.5,7.32,3.83c-2.04,2.77-4.55-.04-7.77-1.27s-6.3-3.1-9.74-3.31c-6.33-.37-9.43,2.55-9.51,8.88-.09,6.34,3.58,10.45,9.38,13.02,5.79,2.57,12.55,2.27,18.61.39,6.06-1.87,9.71-5.49,15.46-8.15,13.48-6.24,31.37-3.97,45.71-4.54"
                    />
                    <path
                        class="cls-4"
                        d="m313.02,314.46s-1.71-3.99,3.13-5.7c4.84-1.71,8.83-2.99,8.83-2.99,0,0-.71,2.71-1.99,3.42-1.28.71-4.56,1.42-6.41,2.42s-3.56,2.85-3.56,2.85Z"
                    />
                    <path
                        class="cls-2"
                        d="m312.87,313.63c-.18-.89,0-1.85.5-2.62.82-1.27,2.35-1.87,3.77-2.4,2.47-.91,4.95-1.82,7.42-2.72"
                    />
                    <path
                        class="cls-1"
                        d="m159.24,438.32c10.02,3.69,21.47,3.72,31.52.09"
                    />
                    <path
                        class="cls-1"
                        d="m145.34,319.19l-46.44-.56c-14.34.57-32.23-1.7-45.71,4.54-5.75,2.66-9.4,6.28-15.46,8.15-6.06,1.87-12.81,2.18-18.61-.39-5.79-2.57-9.46-6.68-9.38-13.02.09-6.34,3.18-9.26,9.51-8.88,3.44.2,6.52,2.08,9.74,3.31s5.72,4.04,7.77,1.27c-1.57-2.32-4.7-2.81-7.32-3.83s-5.26-3.78-4-6.29c1.29-2.55,5.03-2.02,7.74-1.11,6.46,2.18,13.17,3.55,19.63,5.73,14.96-.04,30.78-.03,45.74-.07l81.22,1.77,71.76-1.78c14.96.17,30.78.28,45.74.44,6.48-2.12,13.2-3.45,19.68-5.57,2.72-.89,6.47-1.39,7.73,1.17,1.24,2.52-1.42,5.26-4.05,6.25-2.63.99-5.76,1.45-7.35,3.77,2.02,2.79,4.55,0,7.78-1.2,3.23-1.2,6.32-3.05,9.76-3.23,6.33-.32,9.4,2.62,9.44,8.96s-3.67,10.42-9.48,12.94c-5.82,2.52-12.57,2.16-18.61.24-6.04-1.92-9.66-5.57-15.39-8.28-13.43-6.35-32.06-3.81-45.87-4.62l-47.13-.36"
                    />
                    <path
                        class="cls-1"
                        d="m147.13,316.53c-18.59,52.17-16.41,60.27-15.37,88.19.87,23.33,14.3,84.1,18.71,103.48,1.23,5.4.56,10.19-4.2,13.03-.03.02.03.07,0,.09-3.17,1.87-5.85,3.02-11.15,2.74-1.74-.09-4.39-.09-6.26-.04-1.74.04-3.52-.23-5.17.3-1.66.53-3.18,1.71-3.78,3.34s-.1,3.71,1.38,4.62c.97.59,2.17.64,3.3.66,10.48.22,20.96.24,31.44.06,2.89-.05,8.25-.34,7.88-7.02v.09l.86-88.81"
                    />
                    <path
                        class="cls-1"
                        d="m202.86,316.53c18.59,52.17,16.41,60.27,15.37,88.19-.87,23.33-14.3,84.1-18.71,103.48-1.23,5.4-.56,10.19,4.2,13.03.03.02-.03.07,0,.09,3.17,1.87,5.85,3.02,11.15,2.74,1.74-.09,4.39-.09,6.26-.04,1.74.04,3.52-.23,5.17.3,1.66.53,3.18,1.71,3.78,3.34s.1,3.71-1.38,4.62c-.97.59-2.17.64-3.3.66-10.48.22-20.96.24-31.44.06-2.89-.05-8.25-.34-7.88-7.02l-.07.09.86-88.81"
                    />
                    <path
                        class="cls-2"
                        d="m132.23,411.35c28.69.28,56.62-.31,85.31-.03"
                    />
                    <path class="cls-1" d="m138.41,449.11l25.27,1.6" />
                    <path
                        class="cls-1"
                        d="m186.63,450.96c8.36-.38,16.72-.76,25.08-1.14"
                    />
                    <path
                        class="cls-1"
                        d="m132.47,405.4c1.8-.1,84.1.17,85.9.07"
                    />
                    <path
                        class="cls-3"
                        d="m145.02,522.2c5.03-2.78,9.54-8.75,8.46-14.39-1.62-8.42-9.09-48.44-10.88-56.83-9.32-43.73-9.3-90.6,5.5-133.29.22-.63.02-1.01-.49-1.43,0,0-6.69,18.93-8.8,25.13-2.12,6.2-6.67,23.94-7.22,35.24s.26,23.98.45,28.55.57,14.85,2.54,25.69c1.96,10.83,6.44,34.07,8.48,43.56,2.04,9.5,6.67,26.37,7.3,32.42s.94,8.08.24,9.58-5.56,5.77-5.56,5.77Z"
                    />
                    <path
                        class="cls-2"
                        d="m138.37,524.25c1.9.8,3.11,2.97,2.81,5.01s-2.1,3.76-4.15,3.97"
                    />
                    <path
                        class="cls-3"
                        d="m204.62,532.84c-9.98-.6-13.94-3.94-14.83-7.62s-.59-8.69-.45-12.48c.94-24.72.76-49.05,1.7-73.77,0-.25-.04-.5-.29-.56s-.5,0-.75.07c-1.01.29-2.03.58-3.04.88.04,0-.02.26.01.26l-.53,34.69-.21,30.35v22.31s-.42,4.65,3.91,5.6c4.34.95,6.98.63,8.46.63s6-.36,6-.36Z"
                    />
                    <path
                        class="cls-2"
                        d="m151.95,483.42c1.5.85,3.59.48,4.7-.84"
                    />
                    <path
                        class="cls-2"
                        d="m145.02,522.2c5.03-2.78,9.54-8.75,8.46-14.39-1.62-8.42-9.09-48.44-10.88-56.83-9.32-43.73-9.3-90.6,5.5-133.29.22-.63.02-1.01-.49-1.43"
                    />
                    <path
                        class="cls-2"
                        d="m191.04,438.97c-.94,24.72-.76,49.05-1.7,73.77-.14,3.78-.45,8.8.45,12.48s4.85,7.02,14.83,7.62"
                    />
                    <path
                        class="cls-1"
                        d="m211.64,533.05c-1.76.01-3.28-1.81-2.96-3.54"
                    />
                    <path
                        [style]="'fill:' + levelColor"
                        class="cls-5"
                        d="m174.04,39.2s-27.34,32.73-47.23,62.97c-19.89,30.24-43.5,77.88-41.84,112.68,1.66,34.8,17.89,76.33,58.43,92.79,33.25,13.5,51.84,7.38,85.09-18.01,28.19-21.52,42.08-58.21,34.62-96.33-7.46-38.11-26.93-69.6-42.67-94.04-15.74-24.44-46.4-60.07-46.4-60.07Z"
                    />
                    <path
                        class="cls-3"
                        d="m175.38,41.56s-23.3,26.34-42.21,63.48c-18.91,37.14-32.42,75.64-31.74,107.38.68,31.74,8.67,60.18,31.07,79.69,25.77,22.46,42.55,22.29,42.55,22.29,0,0-17.9,3.38-46.6-14.52-28.7-17.9-36.47-43.22-39.51-56.73-3.04-13.51-8.1-46.6,3.71-74.29,11.82-27.69,31.4-63.82,42.55-79.69l38.85-49.47,1.34,1.86Z"
                    />
                    <path
                        class="cls-2"
                        d="m175.34,41.45c-26.25,31.23-46.88,67.16-60.61,105.58-7.55,21.14-13.06,43.24-13.32,65.68s4.97,45.33,17.46,63.98c12.49,18.65,34.95,35.51,57.26,37.92"
                    />
                    <path
                        class="cls-1"
                        d="m174.23,39.78c-21.51,24.21-40.6,50.25-56.96,77.69-20.8,34.88-37.53,74.41-30.82,113.77,7.04,41.27,32.54,72.8,73.7,81.93,7.84,1.74,17.35,2.28,25.03.01,37.11-10.98,71.04-40.8,77.9-82.08,6.55-39.37-10.34-78.87-31.29-113.72-16.47-27.4-35.68-53.41-57.28-77.59"
                    />
                    <path
                        class="cls-1"
                        d="m174.23,39.78c-21.51,24.21-40.6,50.25-56.96,77.69-20.8,34.88-37.53,74.41-30.82,113.77,7.04,41.27,32.54,72.8,73.7,81.93,7.84,1.74,17.35,2.28,25.03.01,37.11-10.98,71.04-40.8,77.9-82.08,6.55-39.37-10.34-78.87-31.29-113.72-16.47-27.4-35.68-53.41-57.28-77.59"
                    />
                </g>
            </g>
        </svg> -->
        <img
            [style]="getAssetPosition('face')"
            class="absolute"
            [src]="getAssetPath(configuration.face)"
        />
        <img
            *ngIf="configuration.beard"
            [style]="getAssetPosition('beard')"
            class="absolute"
            [src]="getAssetPath(configuration.beard)"
        />
        <img
            *ngIf="configuration.mustache"
            [style]="getAssetPosition('mustache')"
            class="absolute"
            [src]="getAssetPath(configuration.mustache)"
        />
        <img
            *ngIf="configuration.hat"
            [style]="getAssetPosition('hat')"
            class="absolute"
            [src]="getAssetPath(configuration.hat)"
        />
        <img
            *ngIf="configuration.glasses"
            [style]="getAssetPosition('glasses')"
            class="absolute"
            [src]="getAssetPath(configuration.glasses)"
        />
        <img
            *ngIf="configuration.top"
            [style]="getAssetPosition('top')"
            class="absolute"
            [src]="getAssetPath(configuration.top)"
        />
        <img
            *ngIf="configuration.cape"
            [style]="getAssetPosition('cape')"
            class="absolute"
            [src]="getAssetPath(configuration.cape)"
        />

        <img
            *ngIf="configuration.bottom"
            [style]="getAssetPosition('bottom')"
            class="absolute"
            [src]="getAssetPath(configuration.bottom)"
        />
        <img
            *ngIf="configuration.shoes"
            [style]="getAssetPosition('shoes')"
            class="absolute"
            [src]="getAssetPath(configuration.shoes)"
        />

        <img
            *ngIf="configuration.neck"
            [style]="getAssetPosition('neck')"
            class="absolute"
            [src]="getAssetPath(configuration.neck)"
        />
    </div>
</div>
