import {
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MatCheckboxChange,
    MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { ResourceListFilter } from 'app/store/shared/ressource-list/ressource-list-state';
import { Observable, Subscription } from 'rxjs';
import { MissionFiltersService } from '../mission-filters.service';

@Component({
    selector: 'team-building-filter',
    standalone: true,
    templateUrl: './team-building-filter.component.html',
    imports: [
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        FormsModule,
        MatInputModule,
        MatCheckboxModule,
        MatTooltipModule,
        NgClass,
        AsyncPipe,
        NgIf,
        LetDirective,
        NgFor,
        TranslocoModule,
        NgTemplateOutlet,
    ],
})
export class TeamBuildingFilter implements OnInit, OnDestroy {
    @Input() clearEvent: Observable<ResourceListFilter[]>;
    @Output() teamBuildingChanged: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    private clearEventSubscription: Subscription;

    isExpanded = false;
    isTeamBuilding: boolean | null =
        this.missionFiltersService.getIsTeamBuilding;

    constructor(private missionFiltersService: MissionFiltersService) {}

    ngOnInit() {
        if (this.clearEvent) {
            this.clearEventSubscription = this.clearEvent.subscribe((types) => {
                if (types.includes('isTeamBuilding')) {
                    this.isTeamBuilding = false;
                    this.missionFiltersService.setIsTeamBuilding(
                        this.isTeamBuilding
                    );
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.clearEventSubscription) {
            this.clearEventSubscription.unsubscribe();
        }
    }

    toggleIsExpanded() {
        this.isExpanded = !this.isExpanded;
    }

    onTeamBuildingChanged(change: MatCheckboxChange) {
        this.isTeamBuilding = change.checked;
        this.missionFiltersService.setIsTeamBuilding(this.isTeamBuilding);
        this.teamBuildingChanged.emit(this.isTeamBuilding);
    }

    // Needed otherwise checkbox doesn't lose focus on click and extra click on page is needed to do any action
    checkboxClicked(event: MouseEvent): void {
        (event.target as HTMLElement).blur();
    }
}
