import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DualQuizChallengeService } from 'app/api';
import {
    createLoadListRequestEffect,
    createReloadEffect,
} from 'app/store/shared/ressource-list/ressource-list-effects-creator';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { DualQuizMatchUpsActions } from './matchups.action';
import { DualQuizMatchUpsSelectors } from './matchups.selectors';

@Injectable()
export class DualQuizMatchUpsEffects {
    loadMatchups$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.dualQuizChallengeService,
        DualQuizMatchUpsActions,
        DualQuizMatchUpsSelectors,
        this.dualQuizChallengeService.dualQuizChallengeMatchUpResultsGet
    );

    reload$ = createReloadEffect(this.actions$, DualQuizMatchUpsActions);

    loadUserResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DualQuizMatchUpsActions.LoadUserResultsRequest),
            exhaustMap(() => {
                return this.dualQuizChallengeService
                    .dualQuizChallengeResultsGet()
                    .pipe(
                        map((results) =>
                            DualQuizMatchUpsActions.LoadUserResultsRequestSuccess(
                                { results }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                DualQuizMatchUpsActions.LoadUserResultsRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private dualQuizChallengeService: DualQuizChallengeService
    ) {}
}
