import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    AssociationLegalDocumentService,
    DonationCampaignLegalDocumentService,
    EFileExtension,
    EFileType,
    EFolderType,
    FilesService,
} from 'app/api';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { isNil } from 'lodash-es';
import { Observable, catchError, exhaustMap, filter, map, of } from 'rxjs';
import { FilesActions } from './files.action';
import { FilesSelectors } from './files.selectors';

@Injectable()
export class FilesEffects {
    initialLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.reset),
            map(() => FilesActions.loadFilesRequest({}))
        )
    );

    loadFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.loadFilesRequest),
            concatLatestFrom(() =>
                this.store.select(FilesSelectors.selectPageIndex)
            ),
            exhaustMap(([{ folderType, missionId }, pageIndex]) => {
                return this.fileService
                    .filesGet({
                        folderType,
                        missionId,
                        pageIndex,
                    })
                    .pipe(
                        map((response) =>
                            FilesActions.loadFilesRequestSuccess({ response })
                        ),
                        catchError((httpResponse) =>
                            of(
                                FilesActions.loadFilesRequestFail({
                                    error: httpResponse?.error ?? httpResponse,
                                })
                            )
                        )
                    );
            })
        )
    );

    loadFilesOnPageChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.changeFolderPage),
            concatLatestFrom(() =>
                this.store.select(FilesSelectors.selectSelectedFolder)
            ),
            filter(([, selectedFolder]) => !isNil(selectedFolder)),
            map(([, selectedFolder]) => {
                const missionId =
                    selectedFolder.folderType ===
                    EFolderType.MissionSkillsSponsorshipDocuments
                        ? selectedFolder.id
                        : null;
                return FilesActions.loadFilesRequest({
                    folderType: selectedFolder.folderType,
                    missionId,
                });
            })
        )
    );

    loadFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.selectFolder),
            map(({ folder }) => {
                const missionId =
                    folder.folderType ===
                    EFolderType.MissionSkillsSponsorshipDocuments
                        ? folder.id
                        : null;
                return FilesActions.loadFilesRequest({
                    folderType: folder.folderType,
                    missionId,
                });
            })
        )
    );

    startSponsorshipAgreementDownload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.selectFile),
            concatLatestFrom(() => [
                this.store.select(FilesSelectors.selectSelectedFolder),
            ]),
            filter(([{ file }, selectedFolder]) => {
                if (!file || !selectedFolder) {
                    return false;
                }
                return (
                    selectedFolder.folderType ===
                        EFolderType.MissionSkillsSponsorshipDocuments &&
                    file.fileType === EFileType.SponsorshipAgreement &&
                    !isNil(file.hiddenProperties.missionSlotId) &&
                    !isNil(file.hiddenProperties.userId)
                );
            }),
            map(([{ file }]) =>
                FilesActions.downloadSponsorshipAgreementRequest({ file })
            )
        )
    );

    startSponsorshipAmendmentDownload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.selectFile),
            concatLatestFrom(() => [
                this.store.select(FilesSelectors.selectSelectedFolder),
            ]),
            filter(([{ file }, selectedFolder]) => {
                if (!file || !selectedFolder) {
                    return false;
                }
                return (
                    selectedFolder.folderType ===
                        EFolderType.MissionSkillsSponsorshipDocuments &&
                    file.fileType === EFileType.SponsorshipAmendment &&
                    !isNil(file.hiddenProperties.missionSlotId) &&
                    !isNil(file.hiddenProperties.userId)
                );
            }),
            map(([{ file }]) =>
                FilesActions.downloadSponsorshipAmendmentRequest({ file })
            )
        )
    );

    startSponsorshipAttendanceDownload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.selectFile),
            concatLatestFrom(() => [
                this.store.select(FilesSelectors.selectSelectedFolder),
            ]),
            filter(([{ file }, selectedFolder]) => {
                if (!file || !selectedFolder) {
                    return false;
                }
                return (
                    selectedFolder.folderType ===
                        EFolderType.MissionSkillsSponsorshipDocuments &&
                    file.fileType === EFileType.SponsorshipAttendance &&
                    !isNil(file.hiddenProperties.missionSlotId)
                );
            }),
            map(([{ file }]) =>
                FilesActions.downloadSponsorshipAttendanceRequest({ file })
            )
        )
    );

    downloadSponsorshipAgreement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.downloadSponsorshipAgreementRequest),
            exhaustMap(({ file }) => {
                const missionSlotId = file.hiddenProperties.missionSlotId;
                const userId = file.hiddenProperties.userId;
                return this.fileService
                    .filesSponsorshipAgreementMissionSlotIdUserIdGet({
                        missionSlotId,
                        userId,
                    })
                    .pipe(
                        map((blob: Blob) =>
                            FilesActions.downloadSponsorshipAgreementRequestSuccess(
                                {
                                    blob,
                                }
                            )
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            this.handleBlobError(errorResponse.error).pipe(
                                map((errorMessage) =>
                                    FilesActions.downloadSponsorshipAgreementRequestFail(
                                        {
                                            error: errorMessage,
                                        }
                                    )
                                )
                            )
                        )
                    );
            })
        )
    );

    downloadSponsorshipAmendment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.downloadSponsorshipAmendmentRequest),
            exhaustMap(({ file }) => {
                const missionSlotId = file.hiddenProperties.missionSlotId;
                const userId = file.hiddenProperties.userId;
                return this.fileService
                    .filesSponsorshipAmendmentMissionSlotIdUserIdGet({
                        missionSlotId,
                        userId,
                    })
                    .pipe(
                        map((blob: Blob) =>
                            FilesActions.downloadSponsorshipAmendmentRequestSuccess(
                                {
                                    blob,
                                }
                            )
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            this.handleBlobError(errorResponse.error).pipe(
                                map((errorMessage) =>
                                    FilesActions.downloadSponsorshipAmendmentRequestFail(
                                        {
                                            error: errorMessage,
                                        }
                                    )
                                )
                            )
                        )
                    );
            })
        )
    );

    downloadSponsorshipAttendance$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.downloadSponsorshipAttendanceRequest),
            exhaustMap(({ file }) => {
                const missionSlotId = file.hiddenProperties.missionSlotId;
                return this.fileService
                    .filesSponsorshipAttendanceMissionSlotIdGet({
                        missionSlotId,
                    })
                    .pipe(
                        map((blob: Blob) =>
                            FilesActions.downloadSponsorshipAttendanceRequestSuccess(
                                {
                                    blob,
                                }
                            )
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            this.handleBlobError(errorResponse.error).pipe(
                                map((errorMessage) =>
                                    FilesActions.downloadSponsorshipAttendanceRequestFail(
                                        {
                                            error: errorMessage,
                                        }
                                    )
                                )
                            )
                        )
                    );
            })
        )
    );

    uploadAssociationLegalDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.uploadAssociationLegalDocumentRequest),
            exhaustMap(({ file, id }) => {
                return this.associationLegalDocumentService
                    .associationLegalDocumentsDocumentTypePost({
                        documentType: id,
                        formFile: file,
                    })
                    .pipe(
                        map((url) =>
                            FilesActions.uploadAssociationLegalDocumentRequestSuccess(
                                { url }
                            )
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(
                                FilesActions.uploadAssociationLegalDocumentRequestFail(
                                    {
                                        error:
                                            errorResponse.error ??
                                            errorResponse,
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    startAssociationLegalDocumentDownload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.selectFile),
            concatLatestFrom(() => [
                this.store.select(FilesSelectors.selectSelectedFolder),
            ]),
            filter(([{ file }, selectedFolder]) => {
                if (!file || !selectedFolder) {
                    return false;
                }
                return (
                    selectedFolder.folderType ===
                        EFolderType.AssociationLegalDocuments &&
                    file.fileExtension !== EFileExtension.Missing
                );
            }),
            map(([{ file }]) =>
                FilesActions.downloadAssociationLegalDocumentRequest({
                    documentType: file.legalDocumentType,
                })
            )
        )
    );

    downloadAssociationLegalDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.downloadAssociationLegalDocumentRequest),
            exhaustMap(({ documentType }) => {
                return this.fileService
                    .filesAssociationLegalDocumentDocumentTypeGet({
                        documentType,
                    })
                    .pipe(
                        map((blob: Blob) =>
                            FilesActions.downloadAssociationLegalDocumentRequestSuccess(
                                {
                                    blob,
                                }
                            )
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            this.handleBlobError(errorResponse.error).pipe(
                                map((errorMessage) =>
                                    FilesActions.downloadAssociationLegalDocumentRequestFail(
                                        {
                                            error: errorMessage,
                                        }
                                    )
                                )
                            )
                        )
                    );
            })
        )
    );

    startDonationCampaignFundraisingAppealDownload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.selectFile),
            filter(
                ({ file }) =>
                    file.fileType ===
                        EFileType.DonationCampaignFundraisingAppeal &&
                    !isNil(file.hiddenProperties?.donationCampaignId)
            ),
            map(({ file }) =>
                FilesActions.downloadFundraisingAppealRequest({
                    id: file.hiddenProperties?.donationCampaignId,
                })
            )
        )
    );

    startDonationCampaignTaxReceiptDownload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.selectFile),
            filter(
                ({ file }) =>
                    file.fileType === EFileType.DonationCampaignTaxReceipt &&
                    !isNil(file.hiddenProperties?.donationCampaignId)
            ),
            map(({ file }) =>
                FilesActions.downloadCampaignTaxReceiptRequest({
                    id: file.hiddenProperties?.donationCampaignId,
                })
            )
        )
    );

    startSubscriptionTaxReceiptDownload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.selectFile),
            filter(
                ({ file }) =>
                    file.fileType === EFileType.DeductibleSubscriptionTaxReceipt
            ),
            map(() => FilesActions.downloadSubscriptionTaxReceiptRequest())
        )
    );

    downloadDonationCampaignFundraisingAppeal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.downloadFundraisingAppealRequest),
            exhaustMap(({ id }) => {
                return this.donationCampaignLegalDocumentService
                    .donationCampaignLegalDocumentFundraisingAppealIdGet({
                        id,
                    })
                    .pipe(
                        map((blob: Blob) =>
                            FilesActions.downloadFundraisingAppealRequestSuccess(
                                {
                                    blob,
                                }
                            )
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            this.handleBlobError(errorResponse.error).pipe(
                                map((errorMessage) =>
                                    FilesActions.downloadFundraisingAppealRequestFail(
                                        {
                                            error: errorMessage,
                                        }
                                    )
                                )
                            )
                        )
                    );
            })
        )
    );

    downloadDonationCampaignTaxReceipt$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.downloadCampaignTaxReceiptRequest),
            exhaustMap(({ id }) => {
                return this.donationCampaignLegalDocumentService
                    .donationCampaignLegalDocumentTaxReceiptIdGet({
                        id,
                    })
                    .pipe(
                        map((blob: Blob) =>
                            FilesActions.downloadCampaignTaxReceiptRequestSuccess(
                                {
                                    blob,
                                }
                            )
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            this.handleBlobError(errorResponse.error).pipe(
                                map((errorMessage) =>
                                    FilesActions.downloadCampaignTaxReceiptRequestFail(
                                        {
                                            error: errorMessage,
                                        }
                                    )
                                )
                            )
                        )
                    );
            })
        )
    );

    downloadSubscriptionTaxReceipt$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.downloadSubscriptionTaxReceiptRequest),
            exhaustMap(() => {
                return this.fileService.filesSubscriptionTaxReceiptGet().pipe(
                    map((blob: Blob) =>
                        FilesActions.downloadSubscriptionTaxReceiptRequestSuccess(
                            {
                                blob,
                            }
                        )
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        this.handleBlobError(errorResponse.error).pipe(
                            map((errorMessage) =>
                                FilesActions.downloadSubscriptionTaxReceiptRequestFail(
                                    {
                                        error: errorMessage,
                                    }
                                )
                            )
                        )
                    )
                );
            })
        )
    );

    reloadOnAssociationLegalDocumentUploaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FilesActions.uploadAssociationLegalDocumentRequestSuccess),
            map(() => {
                this.fileUploadService.close();
                return FilesActions.loadFilesRequest({
                    folderType: EFolderType.AssociationLegalDocuments,
                });
            })
        )
    );

    private handleBlobError(blob: Blob) {
        return new Observable<string>((observer) => {
            const reader = new FileReader();
            reader.onerror = () => {
                observer.error('Failed to read error message');
            };
            reader.onload = () => {
                observer.next(reader.result as string);
                observer.complete();
            };
            reader.readAsText(blob);
        });
    }

    constructor(
        private actions$: Actions,
        private fileService: FilesService,
        private associationLegalDocumentService: AssociationLegalDocumentService,
        private donationCampaignLegalDocumentService: DonationCampaignLegalDocumentService,
        private fileUploadService: FileUploadDialogService,
        private store: Store
    ) {}
}
