<mat-icon
    [matTooltip]="tooltip"
    [ngClass]="{
        'icon-size-4': size === 'small',
        'icon-size-6': size === 'medium',
        'icon-size-7': size === 'large',
    }"
    class="text-tertiary"
    [svgIcon]="'heroicons_solid:information-circle'"
></mat-icon>
