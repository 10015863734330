import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { ETimeState } from 'app/api';
import { DateTime } from 'luxon';
import { BslkClassicButtonComponent } from '../button/classic/classic-button.component';
import { BslkRoundIconComponent } from '../round-icon/round-icon.component';
import { BslkTagComponent } from '../tag/tag.component';

export type BslkTimelineItem = {
    titleKey: string;
    date: DateTime | string;
    dateLabel: 'done-on' | 'from';
    descriptionKey: string;
    timeState: ETimeState;
    actionId?: string;
    actionKey?: string;
    isActionDisabled?: boolean;
};

@Component({
    selector: 'bslk-timeline',
    standalone: true,
    templateUrl: './timeline.component.html',
    imports: [
        BslkClassicButtonComponent,
        BslkTagComponent,
        BslkRoundIconComponent,
        NgIf,
        NgFor,
        DatePipe,
        MatIconModule,
        TranslocoModule,
        MatTooltipModule,
        TranslocoModule,
    ],
})
export class BslkTimelineComponent {
    @Input() items: BslkTimelineItem[] = [];
    @Output() actionClick: EventEmitter<string> = new EventEmitter<string>();

    onActionClick(event: BslkTimelineItem) {
        if (!event.isActionDisabled) {
            this.actionClick.emit(event.actionId);
        }
    }

    getIconFromState(state: ETimeState) {
        switch (state) {
            case ETimeState.Future:
                return 'heroicons_outline:calendar';
            case ETimeState.Present:
                return 'heroicons_outline:play';
            case ETimeState.Past:
                return 'heroicons_outline:check';
        }
    }

    getColorFromState(state: ETimeState) {
        switch (state) {
            case ETimeState.Future:
                return 'warn';
            case ETimeState.Present:
                return 'blue';
            case ETimeState.Past:
                return 'green';
        }
    }

    showIsHereTag(state: ETimeState) {
        return state === ETimeState.Present;
    }
}
