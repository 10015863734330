/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EDepartment = 'CustomerService' | 'Engineering' | 'Executive' | 'Finance' | 'HumanResources' | 'IT' | 'Legal' | 'Logistics' | 'Marketing' | 'Operations' | 'Procurement' | 'ProductManagement' | 'QualityAssurance' | 'ResearchAndDevelopment' | 'Sales';

export const EDepartment = {
    CustomerService: 'CustomerService' as EDepartment,
    Engineering: 'Engineering' as EDepartment,
    Executive: 'Executive' as EDepartment,
    Finance: 'Finance' as EDepartment,
    HumanResources: 'HumanResources' as EDepartment,
    It: 'IT' as EDepartment,
    Legal: 'Legal' as EDepartment,
    Logistics: 'Logistics' as EDepartment,
    Marketing: 'Marketing' as EDepartment,
    Operations: 'Operations' as EDepartment,
    Procurement: 'Procurement' as EDepartment,
    ProductManagement: 'ProductManagement' as EDepartment,
    QualityAssurance: 'QualityAssurance' as EDepartment,
    ResearchAndDevelopment: 'ResearchAndDevelopment' as EDepartment,
    Sales: 'Sales' as EDepartment
};

