<div class="flex w-full justify-center relative">
    <div
        (click)="isReward ? claimReward() : goToChapter()"
        [class.ml-[35%]]="position === 1"
        [class.ml-[20%]]="position === 2"
        [class.mr-[20%]]="position === 4"
        [class.mr-[35%]]="position === 5"
        class="h-36 w-36 relative"
    >
        <div
            [ngClass]="state === 'current' || displayTitle ? 'flex' : 'hidden'"
            [class.top-48]="state === 'current'"
            [class.top-40]="state !== 'current'"
            class="absolute inset-0 z-20 justify-center items-center"
        >
            <div
                [class.grayscale]="state === 'coming'"
                [class.animate-bounce]="state === 'current'"
                class="relative"
            >
                <div
                    *ngIf="!isReward || state === 'current'"
                    class="whitespace-nowrap px-4 py-2 bg-warn-gradient text-white shadow-xl rounded-xl text-2xl font-bold"
                >
                    <span *ngIf="title">{{ title }}</span>
                    <span *ngIf="state === 'current' && isReward">{{
                        "courses.timeline.claim-trophy" | transloco
                    }}</span>
                    <div
                        class="absolute -top-2 left-1/2 transform -translate-x-1/2 w-4 h-4 bg-warn-gradient rotate-45 -z-1"
                    ></div>
                </div>
            </div>
        </div>

        <svg
            *ngIf="state === 'current' && !isReward"
            class="w-full h-full"
            viewBox="0 0 100 100"
            style="transform: rotate(-90deg)"
        >
            <circle
                class="progress-left"
                r="40"
                cx="50"
                cy="50"
                fill="transparent"
                stroke-width="4px"
            ></circle>
            <circle
                class="progress-done"
                r="40"
                cx="50"
                cy="50"
                fill="transparent"
                stroke-width="4px"
                stroke-width="4px"
                stroke-linecap="round"
                [attr.stroke-dasharray]="circumference"
                [attr.stroke-dashoffset]="dashoffset"
            ></circle>
        </svg>
        <div
            class="absolute top-0 left-0 w-full h-full flex justify-center items-center"
        >
            <button
                (mouseover)="onMouseOver()"
                (mouseout)="onMouseOut()"
                [class.grayscale]="state === 'coming'"
                class="h-24 w-24 bg-warn-gradient rounded-full border-b-[12px] border-warn-800 shadow-xl flex items-center justify-center active:border-none active:translate-y-1 transition-all duration-100"
            >
                <mat-icon
                    [svgIcon]="isReward ? 'bslk_solid:trophy' : 'basile-flat'"
                    class="text-white icon-size-12"
                ></mat-icon>
            </button>
        </div>
    </div>

    <div
        *ngIf="position === 1 && !isFirstChapter"
        class="absolute w-full hidden md:flex justify-start"
    >
        <img
            class="absolute top-1/2 mt-12 w-48 ml-[10%] transform -translate-y-1/2"
            [src]="'assets/basile-postures/' + basilePosture + '.png'"
        />
    </div>
    <div
        *ngIf="position === 5 && !isFirstChapter"
        class="absolute w-full hidden md:flex justify-end"
    >
        <img
            class="absolute top-1/2 w-48 mr-[10%] transform -translate-y-1/2"
            [src]="'assets/basile-postures/' + basilePosture + '.png'"
        />
    </div>
</div>
