<bslk-classic-card
    *ngrxLet="tasks$ as tasks"
    [title]="'missionmanage.mission-tasks' | transloco"
    [isLoading]="isLoadingTasks$ | async"
>
    <div class="cursor-pointer" *ngIf="canManageTasks$ | async" headerActions>
        <mat-icon
            (click)="onEditTask(true)"
            *ngIf="!isEditingTasks"
            class="text-current"
            [svgIcon]="'heroicons_outline:pencil-square'"
        ></mat-icon>
        <mat-icon
            (click)="onEditTask(false)"
            *ngIf="isEditingTasks"
            class="text-current"
            [svgIcon]="'heroicons_outline:arrow-uturn-left'"
        ></mat-icon>
    </div>
    <!-- View tasks -->
    <ng-container *ngIf="!isEditingTasks">
        <ng-container *ngIf="tasks.length > 0">
            <div *ngFor="let task of tasks">
                <div class="font-bold text-lg flex items-center mb-1">
                    <div
                        class="w-3 h-3 flex items-center mr-2 border rounded-full border-black"
                    >
                        <div
                            [class.bg-black]="atLeastOneSubtaskCompleted(task)"
                            class="w-1/2 h-full"
                        ></div>
                        <div
                            [class.bg-black]="allSubtasksCompleted(task)"
                            class="w-1/2 h-full"
                        ></div>
                    </div>
                    <span>{{ task.title }}</span>
                </div>
                <div
                    class="ml-6 square-checkbox-container"
                    *ngFor="let subtask of task.subtasks"
                >
                    <mat-checkbox
                        [disabled]="!(canManageTasks$ | async)"
                        [color]="'accent'"
                        (change)="onTaskStateChanged(subtask.id, $event)"
                        [checked]="subtask.isCompleted"
                    >
                        <span class="font-semibold">{{ subtask.title }}</span>
                    </mat-checkbox>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="tasks.length === 0">
            <fuse-alert
                *ngrxLet="isAssoAdmin$ as isAssoAdmin"
                [appearance]="'soft'"
                [dismissible]="true"
                [type]="'info'"
                class="mb-3"
            >
                <span *ngIf="isAssoAdmin">{{
                    "missionmanage.no-tasks-yet-info-asso" | transloco
                }}</span>
                <span *ngIf="!isAssoAdmin">{{
                    "missionmanage.no-tasks-yet-info-regular" | transloco
                }}</span>
            </fuse-alert>
            <div class="mb-3 text-center">
                {{ "missionmanage.no-tasks-yet" | transloco }}
            </div>

            <div class="flex justify-center">
                <button
                    *ngIf="canManageTasks$ | async"
                    (click)="onEditTask(true)"
                    mat-flat-button
                    class="bg-warn text-white"
                >
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_outline:plus'"
                    ></mat-icon>
                    <span class="ml-1">{{
                        "missionmanage.add-tasks" | transloco
                    }}</span>
                </button>
            </div>
        </ng-container>
    </ng-container>

    <!-- Edit tasks -->
    <mission-edit-tasks
        *ngIf="isEditingTasks"
        (onSave)="onTasksChanged($event)"
        (onClose)="onEditTask(false)"
        [tasks]="tasks"
    ></mission-edit-tasks>
</bslk-classic-card>
