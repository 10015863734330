<div class="flex justify-center">
    <bslk-tag
        class="font-bold max-w-full inline-block"
        [background]="backgroundColor"
        [textColor]="textColor"
        [icon]="'heroicons_outline:' + icon"
        [matTooltip]="
            'shared.mission-state.' + key + '.description' | transloco
        "
        [matTooltipDisabled]="!showDescriptionAsTooltip"
    >
        <div class="truncate">
            {{ "shared.mission-state." + key + ".title" | transloco }}
        </div>
    </bslk-tag>
</div>
