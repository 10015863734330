import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BslkIconButtonComponent } from '@bslk/components/button/icon/icon-button.component';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'bslk-sheet-data-editor',
    templateUrl: './sheet-data-editor.component.html',
    imports: [
        ReactiveFormsModule,
        BslkIconButtonComponent,
        TranslocoModule,
        NgFor,
        JsonPipe,
        NgIf,
        TranslocoModule,
        FormsModule,
        BslkTagButtonComponent,
    ],
    standalone: true,
})
export class BslkSheetDataEditorComponent<T> {
    @Input() entities: T[];
    @Input() fields: string[];
    @Input() placeholders: [key: string];
    @Output() dataChanged = new EventEmitter<T[]>();
    @Output() importClicked = new EventEmitter<void>();

    editEntity(index: number, field: string, value: any) {
        this.entities[index][field] = value;
        this.dataChanged.emit(this.entities);
    }

    deleteEntity(index: number) {
        this.entities = [...this.entities];
        this.entities.splice(index, 1);
        this.dataChanged.emit(this.entities);
    }

    addEntity() {
        const entity = {} as T;
        this.fields.forEach((field) => {
            entity[field] = '';
        });
        this.entities.push(entity);
        this.dataChanged.emit(this.entities);
    }

    getKeys(): string[] {
        return this.fields;
    }

    onImportClicked() {
        this.importClicked.emit();
    }
}
