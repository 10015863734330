<base-dialog
    (onClose)="close()"
    [showFireworks]="true"
    [title]="'basile.new-asset-dialog.title' | transloco"
    [showActions]="true"
>
    <div class="w-full h-full flex flex-col justify-center" body>
        <img class="w-32 mx-auto mb-3" [src]="assetPath" />
        <div class="text-center p-2">
            <div class="text-2xl font-bold">
                {{ "basile.new-asset-dialog.congrats" | transloco }}
            </div>
            <div class="text-xl">
                {{ "basile.new-asset-dialog.you-unlocked" | transloco }}
                <span class="font-bold"
                    >{{ "basile.assets." + asset.name | transloco }}.</span
                >
            </div>
        </div>
    </div>

    <div class="flex justify-end" actions>
        <button
            (click)="equip()"
            [color]="'accent'"
            class="z-10 text-white m-auto"
            mat-flat-button
        >
            <mat-icon [svgIcon]="'heroicons_outline:heart'"></mat-icon>
            <span class="ml-1">{{
                "basile.new-asset-dialog.equip" | transloco
            }}</span>
        </button>
    </div>
</base-dialog>
