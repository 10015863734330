import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { EUserRole } from 'app/api';
import {
    getUserSessionFromLocalStorage,
    updateLocalStorageSession,
} from 'app/shared/utils/local-storage.utils';
import { of } from 'rxjs';

export const RedirectGuard: CanActivateFn | CanActivateChildFn = () => {
    const router: Router = inject(Router);

    const session = getUserSessionFromLocalStorage();
    const user = session?.user;
    const userRole = user?.userRole;
    if (userRole === undefined) {
        return router.parseUrl(`sign-in`);
    }
    let pathPrefix: string;
    if (userRole === EUserRole.Regular) {
        if (session.isFirstConnection) {
            session.isFirstConnection = false;
            updateLocalStorageSession(session);
            pathPrefix = 's/csr-approach';
        } else {
            return of(true);
        }
    } else if (userRole === EUserRole.AssoAdmin) {
        pathPrefix = 'a';
    } else if (userRole === EUserRole.CompanyAdmin) {
        pathPrefix = 'ca';
    } else if (userRole === EUserRole.SuperAdmin) {
        pathPrefix = 'sa';
    } else if (userRole === EUserRole.External) {
        pathPrefix = 'ext';
    } else {
        return router.parseUrl(`sign-in`);
    }
    return router.parseUrl(pathPrefix);
};
