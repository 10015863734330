<base-dialog
    (onClose)="close()"
    [title]="'coursesection.rating.title' | transloco"
    [color]="'primary'"
    [showActions]="true"
>
    <div class="w-full h-full" body>
        <div class="flex flex-col gap-5">
            <bslk-star-rating
                [label]="'coursesection.rating.rating' | transloco"
                [control]="formGroup.controls.rating"
            ></bslk-star-rating>

            <div class="flex flex-col w-full gap-1">
                <mat-form-field
                    floatLabel="always"
                    subscriptSizing="dynamic"
                    class="w-full"
                >
                    <mat-label>{{
                        "coursesection.rating.comment" | transloco
                    }}</mat-label>
                    <textarea
                        matInput
                        [formControl]="formGroup.controls.comment"
                        [placeholder]="
                            'coursesection.rating.comment-placeholder'
                                | transloco
                        "
                    ></textarea>
                </mat-form-field>
                <bslk-length-info
                    [maxLength]="300"
                    [text]="formGroup.controls.comment.value"
                ></bslk-length-info>
            </div>
        </div>
    </div>

    <div class="flex justify-end" actions>
        <bslk-classic-button
            (onClick)="submit()"
            [label]="'shared.common.send' | transloco"
            [isLoading]="isLoading$ | async"
            [isDisabled]="formGroup.invalid"
            [icon]="'heroicons_outline:paper-airplane'"
        >
        </bslk-classic-button>
    </div>
</base-dialog>
