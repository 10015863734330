<div
    *ngrxLet="timerPercentage$ as timerPercentage"
    class="h-full w-full border-4 border-primary rounded-3xl p-1"
>
    <div
        [style]="'width:' + timerPercentage + '%;'"
        [ngClass]="{
            'rounded-full': timerPercentage > 2,
            'rounded-l-full': timerPercentage <= 2,
        }"
        class="bg-primary h-full"
        [class.animate-pulse]="timerPercentage < 33"
    ></div>
</div>
