<div 
  #elementHighlighter
  (click)="onClick($event)" 
  [@fadeIn]="true" 
  [ngClass]="position === 'right' ? 'right-0 translate-x-full' : 'flex-row-reverse	left-0 -translate-x-full'"
  class="cursor-default	absolute z-10 flex top-0 -translate-y-full">
  <div class="arrow self-end" [ngClass]="position === 'right' ? 'mr-8' : 'ml-8'">
    <!-- Arrow content and animations here -->
    <div class="arrow-animation text-warn" [ngClass]="position === 'right' ? 'animation-right' : 'animation-left'">
      <mat-icon
        class="w-16 h-16 text-warn"
        [svgIcon]="'heroicons_solid:arrow-down-' + (position === 'right' ? 'left' : 'right')">
      </mat-icon>
    </div>
  </div>

  <basile 
    (buttonClicked)="removeHighlighting()"
    [text]="'basile.here' | transloco" 
    [textPostion]="position"
    [buttonText]="'basile.got-it' | transloco">
  </basile>
</div>
