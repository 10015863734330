import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { UserViewModel } from 'app/api';

export type MissionMemberRequestEvent = {
    userId: number;
    isAccepted: boolean;
};

@Component({
    selector: 'mission-member-request',
    standalone: true,
    templateUrl: './mission-member-request.component.html',
    imports: [NgFor, NgIf, MatIconModule, TranslocoModule],
})
export class MissionMemberRequestComponent {
    @Input() user: UserViewModel;
    @Input() showActions = true;

    @Output() onRequestAnswer: EventEmitter<MissionMemberRequestEvent> =
        new EventEmitter<MissionMemberRequestEvent>();

    onButtonClick(isAccepted: boolean) {
        this.onRequestAnswer.emit({
            userId: this.user.id,
            isAccepted: isAccepted,
        });
    }
}
