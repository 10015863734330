import { NgClass, NgIf } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { DonationCampaignViewModel, ETrackedActivityType } from 'app/api';
import { TrackedActivityActions } from 'app/store/tracked-activity/tracked-activity.actions';

@Component({
    selector: 'open-gift',
    templateUrl: './open-gift.component.html',
    standalone: true,
    styleUrls: ['./open-gift.component.scss'],
    animations: fuseAnimations,
    imports: [TranslocoModule, NgClass, NgIf],
})
export class OpenGiftComponent implements OnChanges {
    @ViewChild('gift') gift: ElementRef;

    @Input() campaign: DonationCampaignViewModel;
    @Output() giftOpened: EventEmitter<void> = new EventEmitter<void>();

    isOpened = false;
    fadeOutCard = false;
    autoOpenAfterSeconds = 7;

    constructor(private store: Store) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.campaign && changes.campaign.currentValue) {
            setTimeout(() => {
                this.open();
            }, this.autoOpenAfterSeconds * 1000);
        }
    }

    open() {
        if (this.campaign && !this.isOpened) {
            this.gift.nativeElement.classList.add('is-open');
            this.isOpened = true;
            this.giftOpened.emit();
            this.store.dispatch(
                TrackedActivityActions.logActivityRequest({
                    activity: {
                        trackedActivityType:
                            ETrackedActivityType.DonationGiftOpen,
                        details: this.campaign.accessToken,
                    },
                })
            );
        }
    }
}
