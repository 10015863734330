<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    (openedChanged)="openedChanged($event)"
    [name]="'editPillarDrawer'"
    [position]="'right'"
    #viewPillarDrawer
>
    <div class="flex flex-col w-full max-h-screen bg-card">
        <div class="flex gap-2 pt-2 px-2 flex-row items-center">
            <button
                class="ml-auto"
                mat-icon-button
                (click)="viewPillarDrawer.close()"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"
                ></mat-icon>
            </button>
        </div>

        <div
            *ngIf="pillar"
            class="p-4 flex flex-col gap-5 h-full overflow-auto"
        >
            <div
                [class.border]="!pillar.imageUrl"
                class="h-48 rounded-3xl overflow-hidden relative"
            >
                <img
                    *ngIf="pillar.imageUrl"
                    [src]="pillar.imageUrl"
                    class="object-cover h-full w-full z-0"
                />
                <div
                    [ngClass]="{
                        'bg-primary-100': color == 'primary',
                        'bg-warn-100': color == 'warn',
                        'bg-tertiary-100': color == 'tertiary'
                    }"
                    class="absolute bottom-3 left-3 rounded-xl p-2 w-min"
                >
                    <span
                        [ngClass]="{
                            'text-primary': color == 'primary',
                            'text-warn': color == 'warn',
                            'text-tertiary': color == 'tertiary'
                        }"
                        class="text-xl font-bold truncate"
                        >{{ pillar.name }}</span
                    >
                </div>
            </div>

            <span class="text-lg">{{ pillar.description }}</span>

            <div *ngIf="pillar.actions.length > 0" clas="flex flex-col gap-2">
                <span class="font-bold text-xl">{{
                    "csrapproach.actions-implemented" | transloco
                }}</span>

                <div
                    *ngFor="let action of pillar.actions"
                    class="border-b pb-2"
                >
                    <mat-accordion>
                        <mat-expansion-panel
                            class="mat-elevation-z0 remove-mat-expansion-panel-padding"
                        >
                            <mat-expansion-panel-header class="pl-0">
                                <mat-panel-description
                                    [ngClass]="{
                                        'text-primary': color == 'primary',
                                        'text-warn': color == 'warn',
                                        'text-tertiary': color == 'tertiary'
                                    }"
                                    class="font-bold"
                                >
                                    {{ action.name }}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <span class="text-black">
                                {{ action.description }}
                            </span>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
    </div>
</fuse-drawer>
