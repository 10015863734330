<auth-template
    class="w-full"
    [title]="'validation.email-validation' | transloco"
    *ngrxLet="codeError$ as codeError"
>
    <div class="mt-5" *ngrxLet="isLoading$ as isLoading">
        <mat-progress-spinner
            class="m-auto"
            *ngIf="isLoading"
            [diameter]="50"
            [mode]="'indeterminate'"
        >
        </mat-progress-spinner>

        <fuse-alert
            class="mb-4 flex justify-center"
            *ngIf="!isLoading && codeError"
            [appearance]="'outline'"
            [showIcon]="true"
            [type]="'error'"
            [@shake]="true"
        >
            {{ "validation.errors." + codeError | transloco }}
        </fuse-alert>

        <fuse-alert
            class="mb-4 flex justify-center"
            *ngIf="!isLoading && newEmailSent"
            [appearance]="'outline'"
            [showIcon]="true"
            [type]="'success'"
        >
            {{ "validation.new-email-sent" | transloco }}
        </fuse-alert>

        <bslk-classic-button
            (onClick)="sendNewMail()"
            *ngIf="
                !isLoading &&
                userId &&
                codeError &&
                !(isEmailAlreadyValidated$ | async)
            "
            [label]="'validation.send-new-email' | transloco"
            [isLoading]="isLoading"
        ></bslk-classic-button>
    </div>
</auth-template>
