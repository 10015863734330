import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { DualQuizAnswerBodyViewModel, DualQuizChallengeService } from 'app/api';
import { UserActions } from 'app/store/user/user/user.actions';
import { isNil } from 'lodash-es';
import {
    catchError,
    exhaustMap,
    filter,
    interval,
    map,
    mergeMap,
    of,
    switchMap,
    takeUntil,
} from 'rxjs';
import { DualQuizChallengeActions } from './challenge.action';
import { DualQuizChallengeSelectors } from './challenge.selectors';

@Injectable()
export class DualQuizChallengeEffects {
    loadChallenge$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DualQuizChallengeActions.loadChallengeRequest),
            exhaustMap(({ id }) => {
                return this.dualQuizChallengeService
                    .dualQuizChallengeIdGet({ id })
                    .pipe(
                        map((challenge) =>
                            DualQuizChallengeActions.loadChallengeRequestSuccess(
                                { challenge }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                DualQuizChallengeActions.loadChallengeRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    checkIfCanAnswer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DualQuizChallengeActions.answerQuestion),
            concatLatestFrom(() => [
                this.store.select(
                    DualQuizChallengeSelectors.selectIsTimerRunning
                ),
                this.store.select(DualQuizChallengeSelectors.selectLastChoice),
                this.store.select(
                    DualQuizChallengeSelectors.selectQuestionEmittedResponseReceived
                ),
            ]),
            filter(
                ([
                    ,
                    isTimerRunning,
                    lastChoice,
                    questionEmittedResponseReceived,
                ]) =>
                    isTimerRunning &&
                    isNil(lastChoice) &&
                    questionEmittedResponseReceived
            ),
            map(([{ choice }]) =>
                DualQuizChallengeActions.answerQuestionRequest({ choice })
            )
        )
    );

    answerQuestion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DualQuizChallengeActions.answerQuestionRequest),
            concatLatestFrom(() => [
                this.store.select(DualQuizChallengeSelectors.selectChallenge),
                this.store.select(
                    DualQuizChallengeSelectors.selectCurrentQuestion
                ),
                this.store.select(
                    DualQuizChallengeSelectors.selectIsAnsweringLastQuestion
                ),
            ]),
            exhaustMap(([{ choice }, challenge, question, isLastQuestion]) => {
                const dualQuizAnswerBodyViewModel: DualQuizAnswerBodyViewModel =
                    {
                        choice,
                        challengeId: challenge?.id,
                        questionId: question?.id,
                        isLastQuestion,
                    };
                return this.dualQuizChallengeService
                    .dualQuizChallengeAnswerPut({ dualQuizAnswerBodyViewModel })
                    .pipe(
                        mergeMap((response) => {
                            const actions: Action[] = [
                                DualQuizChallengeActions.answerQuestionRequestSuccess(
                                    { response }
                                ),
                            ];

                            if (!challenge.isChallenger && isLastQuestion) {
                                actions.push(UserActions.setHasGainedRewards());
                            }
                            return of(...actions);
                        }),
                        catchError((httpResponse) =>
                            of(
                                DualQuizChallengeActions.answerQuestionRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    triggerStartTimer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DualQuizChallengeActions.emittedCurrentQuestionSuccess),
            concatLatestFrom(() => [
                this.store.select(DualQuizChallengeSelectors.selectChallenge),
            ]),
            map(([, challenge]) =>
                DualQuizChallengeActions.startTimer({
                    duration: challenge.secondsPerQuestion,
                })
            )
        )
    );

    triggerEmittedQuestionEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DualQuizChallengeActions.nextQuestion),
            concatLatestFrom(() => [
                this.store.select(DualQuizChallengeSelectors.selectChallenge),
                this.store.select(
                    DualQuizChallengeSelectors.selectCurrentQuestion
                ),
            ]),
            exhaustMap(([, challenge, question]) => {
                return this.dualQuizChallengeService
                    .dualQuizChallengeChallengeIdQuestionIdEmittedPut({
                        challengeId: challenge.id,
                        questionId: question.id,
                    })
                    .pipe(
                        map(() =>
                            DualQuizChallengeActions.emittedCurrentQuestionSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                DualQuizChallengeActions.emittedCurrentQuestionFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    startTimer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DualQuizChallengeActions.startTimer),
            switchMap(({ duration }) => {
                const endTime = Date.now() + duration * 1000;
                return interval(10).pipe(
                    map(() => {
                        const currentTime = endTime - Date.now();
                        if (currentTime <= 0) {
                            return DualQuizChallengeActions.stopTimer();
                        } else {
                            return DualQuizChallengeActions.updateTimer({
                                timer: currentTime,
                            });
                        }
                    }),
                    takeUntil(
                        this.actions$.pipe(
                            ofType(
                                DualQuizChallengeActions.stopTimer,
                                DualQuizChallengeActions.answerQuestionRequest
                            )
                        )
                    )
                );
            })
        )
    );

    answerOnTimeout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DualQuizChallengeActions.stopTimer),
            map(() =>
                DualQuizChallengeActions.answerQuestionRequest({ choice: 0 })
            )
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private dualQuizChallengeService: DualQuizChallengeService
    ) {}
}
