import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { CompanyAdminDashboardActions } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.actions';
import { CompanyAdminKpi } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.state';
import { SuperAdminDashboardActions } from 'app/store/super-admin/super-admin-dashboard/super-admin-dashboard.actions';
import { SuperAdminKpi } from 'app/store/super-admin/super-admin-dashboard/super-admin-dashboard.state';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'base-kpi-panel',
    templateUrl: './base-kpi-panel.component.html',
    standalone: true,
    imports: [NgxSkeletonLoaderModule, NgIf, MatIconModule, NgClass],
})
export class BaseKpiPanelComponent {
    @Output() expands: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() title: string;
    @Input() kpiName: CompanyAdminKpi | SuperAdminKpi;
    @Input() isLoading = false;
    @Input() isExpandable = false;
    @Input() isExpanded = false;
    @Input() isSuperAdminKpi = false;

    constructor(private store: Store) {}

    toggleExpand() {
        if (!this.isExpanded) {
            if (!this.isSuperAdminKpi) {
                this.store.dispatch(
                    CompanyAdminDashboardActions.expandKpi({
                        kpi: this.kpiName as CompanyAdminKpi,
                    })
                );
            } else {
                this.store.dispatch(
                    SuperAdminDashboardActions.expandKpi({
                        kpi: this.kpiName as SuperAdminKpi,
                    })
                );
            }
        } else {
            if (!this.isSuperAdminKpi) {
                this.store.dispatch(CompanyAdminDashboardActions.minimizeKpi());
            } else {
                this.store.dispatch(SuperAdminDashboardActions.minimizeKpi());
            }
        }
    }
}
