/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserViewModel } from './userViewModel';


export interface CourseRatingViewModel { 
    id?: number;
    dateCreated?: string;
    dateUpdated?: string | null;
    dateArchived?: string | null;
    rating?: number;
    courseId?: string | null;
    comment?: string | null;
    userId?: number;
    user?: UserViewModel;
}

