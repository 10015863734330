/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LocationViewModel { 
    id?: number;
    longitude?: number;
    latitude?: number;
    /**
     * If the location is not a precise address but a radius starting from the center of the coordinates
     */
    radiusKm?: number | null;
    country?: string | null;
    city?: string | null;
    address?: string | null;
    zipCode?: string | null;
    name?: string | null;
}

