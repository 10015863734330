// dialog.service.ts

import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { ProjectFormService } from 'app/shared/services/form/project-form.service';
import { isEmpty, isNil } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class CreateProjectValidationService {
    constructor(
        private projectFormService: ProjectFormService,
        private translocoService: TranslocoService
    ) {}

    get formGroup() {
        return this.projectFormService.getFormGroup;
    }

    get isStep0Valid() {
        return this.getStep0Errors.length === 0;
    }

    get isStep1Valid() {
        return this.getStep1Errors.length === 0;
    }

    get isStep2Valid() {
        return this.getStep2Errors.length === 0;
    }

    get isStep3Valid() {
        return this.getStep3Errors.length === 0;
    }

    isStepValid(stepIndex: number): boolean {
        switch (stepIndex) {
            case 0:
                return this.isStep0Valid;
            case 1:
                return this.isStep1Valid;
            case 2:
                return this.isStep2Valid;
            case 3:
                return this.isStep3Valid;
            default:
                return false;
        }
    }

    getCurrentStepErrors(step: number): string[] {
        switch (step) {
            case 0:
                return this.getStep0Errors;
            case 1:
                return this.getStep1Errors;
            case 2:
                return this.getStep2Errors;
            case 3:
                return this.getStep3Errors;
        }
    }

    get getStep0Errors(): string[] {
        const errors = [];
        if (this.formGroup.controls.name.invalid) {
            errors.push(
                this.translocoService.translate('createproject.name-error')
            );
        }

        if (this.formGroup.controls.activityFields.invalid) {
            errors.push(
                this.translocoService.translate(
                    'createproject.activity-fields-error'
                )
            );
        }

        if (this.formGroup.controls.challenge.invalid) {
            errors.push(
                this.translocoService.translate('createproject.challenge-error')
            );
        }

        if (this.formGroup.controls.solution.invalid) {
            errors.push(
                this.translocoService.translate('createproject.solution-error')
            );
        }

        if (this.formGroup.controls.solutionDetails.invalid) {
            errors.push(
                this.translocoService.translate(
                    'createproject.solution-details-error'
                )
            );
        }
        return errors;
    }

    get getStep1Errors(): string[] {
        const errors = [];

        if (this.formGroup.controls.sustainableDevelopmentGoals.invalid) {
            errors.push(
                this.translocoService.translate(
                    'createproject.sustainable-development-goals-error'
                )
            );
        }

        if (this.formGroup.controls.videoUrl.invalid) {
            errors.push(
                this.translocoService.translate('createproject.video-url-error')
            );
        }

        if (
            !isEmpty(this.formGroup.controls.quote.value) &&
            isEmpty(this.formGroup.controls.quoteAuthor.value)
        ) {
            errors.push(
                this.translocoService.translate(
                    'createproject.quote-author-error'
                )
            );
        }

        if (
            !isEmpty(this.formGroup.controls.quote.value) &&
            isEmpty(this.formGroup.controls.quoteAuthorTitle.value)
        ) {
            errors.push(
                this.translocoService.translate(
                    'createproject.quote-author-title-error'
                )
            );
        }

        return errors;
    }

    get getStep2Errors(): string[] {
        const errors = [];

        const metricsFormArray = this.formGroup.controls.metrics as FormArray;
        metricsFormArray.controls.forEach(
            (metricsFormGroup: FormGroup, index: number) => {
                if (metricsFormGroup.controls.description.invalid) {
                    errors.push(
                        this.translocoService.translate(
                            'createproject.metric-description-error',
                            { index: index + 1 }
                        )
                    );
                }

                if (
                    metricsFormGroup.controls.isRange.value &&
                    isNil(metricsFormGroup.controls.fromNumber.value)
                ) {
                    errors.push(
                        this.translocoService.translate(
                            'createproject.from-number-error',
                            { index: index + 1 }
                        )
                    );
                }

                if (
                    metricsFormGroup.controls.isRange.value &&
                    isNil(metricsFormGroup.controls.toNumber.value)
                ) {
                    errors.push(
                        this.translocoService.translate(
                            'createproject.to-number-error',
                            { index: index + 1 }
                        )
                    );
                }

                if (
                    !metricsFormGroup.controls.isRange.value &&
                    isNil(metricsFormGroup.controls.number.value)
                ) {
                    errors.push(
                        this.translocoService.translate(
                            'createproject.number-error',
                            { index: index + 1 }
                        )
                    );
                }
            }
        );

        return errors;
    }

    get getStep3Errors(): string[] {
        const errors = [];

        if (this.formGroup.controls.baseContributionAmount.invalid) {
            errors.push(
                this.translocoService.translate(
                    'createproject.base-contribution-amount-error'
                )
            );
        }

        if (this.formGroup.controls.currency.invalid) {
            errors.push(
                this.translocoService.translate('createproject.currency-error')
            );
        }

        if (this.formGroup.controls.impactUnitQuantity.invalid) {
            errors.push(
                this.translocoService.translate(
                    'createproject.impact-unit-quantity-error'
                )
            );
        }

        if (this.formGroup.controls.impactUnitDescription.invalid) {
            errors.push(
                this.translocoService.translate(
                    'createproject.impact-unit-description-error'
                )
            );
        }

        if (this.formGroup.controls.purposeOfDonations.invalid) {
            errors.push(
                this.translocoService.translate(
                    'createproject.purpose-of-donations-error'
                )
            );
        }

        const locationsFormArray = this.formGroup.controls
            .locations as FormArray;
        if (locationsFormArray.invalid) {
            errors.push(
                this.translocoService.translate('createproject.locations-error')
            );
        }

        return errors;
    }
}
