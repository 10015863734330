import { AsyncPipe, JsonPipe, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { isEmpty, isNil } from 'lodash-es';
export type BslkAdvancedSelectSyncInput = {
    name: string;
    value: any;
};

@Component({
    selector: 'bslk-advanced-select-sync',
    templateUrl: './advanced-select-sync.component.html',
    styleUrls: ['./advanced-select-sync.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        BslkInfoLabelComponent,
        FormsModule,
        JsonPipe,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        NgFor,
        NgIf,
        ReactiveFormsModule,
    ],
})
export class BslkAdvancedSelectSyncComponent<
    T extends BslkAdvancedSelectSyncInput
> {
    @ViewChild('searchInput') searchInput: ElementRef;

    @Input() initialValue: string;
    @Input() label: string;
    @Input() selectPlaceholder: string;
    @Input() searchPlaceholder: string;
    @Input() controlName: string;
    @Input() icon: string;
    @Input() group: FormGroup;
    @Input() items: T[];
    @Input() itemIsObject = false;
    @Input() tooltip: string;
    @Input() errorMessage;
    @Input() multiple = false;
    @Input() disableSearch = false;

    filteredItems: T[];
    showInitialValue = false;
    search = null;

    constructor() {}

    onSearch(query: string): void {
        this.search = query;
        this.filteredItems = this.items.filter((i) =>
            i.name.toLowerCase().includes(query.toLocaleLowerCase())
        );
    }

    get shouldDisplayFilteredItems() {
        return !isEmpty(this.search) && !isNil(this.filteredItems);
    }

    get displayedItems() {
        if (!this.multiple) {
            return this.items
                .filter((i) => i.name)
                .sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
        }
        const selectedValues =
            this.group.controls[this.controlName].value || [];
        return this.items
            .filter((i) => i.name)
            .sort((a, b) => {
                // Check if both items are selected or not selected
                const bothSelected =
                    selectedValues.includes(a.value) &&
                    selectedValues.includes(b.value);
                const neitherSelected =
                    !selectedValues.includes(a.value) &&
                    !selectedValues.includes(b.value);

                if (bothSelected || neitherSelected) {
                    // If both are selected or both are not selected, sort alphabetically
                    // Except for 'Other' which should always be at the bottom
                    if (b.value === 'Other') {
                        return -1;
                    } else if (a.value === 'Other') {
                        return 1;
                    }
                    return a.name.localeCompare(b.name);
                } else {
                    // If one is selected and the other is not, the selected one comes first
                    return selectedValues.includes(a.value) ? -1 : 1;
                }
            });
    }

    onChange() {
        this.showInitialValue = false;
    }

    shouldDisplayItem(item: T) {
        if (!this.shouldDisplayFilteredItems) {
            return true;
        }
        return this.filteredItems.includes(item);
    }

    clearSearch() {
        this.search = null;
    }

    onMatSelectOpenedChange(opened: boolean) {
        if (!this.disableSearch) {
            if (opened) {
                // Focus the input after a slight delay to ensure the element is visible
                setTimeout(() => this.searchInput?.nativeElement.focus(), 100);
            }
        }
    }
}
