import { createReducer, on } from '@ngrx/store';
import { DonationCampaignCustomizationActions } from './donation-campaign-customization.action';
import { DonationCampaignCustomizationState } from './donation-campaign-customization.state';

export const donationCampaignCustomizationInitialState: DonationCampaignCustomizationState =
    {
        isLoading: false,
    };

export const donationCampaignCustomizationReducer = createReducer(
    donationCampaignCustomizationInitialState,

    on(
        DonationCampaignCustomizationActions.loadDonationCampaignCustomizationRequest,
        (state) => ({
            ...state,
            isLoading: true,
        })
    ),

    on(
        DonationCampaignCustomizationActions.loadDonationCampaignCustomizationRequestSuccess,
        (state, { donationCampaignCustomization }) => ({
            ...state,
            isLoading: false,
            donationCampaignCustomization,
        })
    ),

    on(
        DonationCampaignCustomizationActions.loadDonationCampaignCustomizationRequestFailure,
        (state) => ({
            ...state,
            isLoading: false,
        })
    ),

    on(
        DonationCampaignCustomizationActions.updateDonationCampaignCustomizationRequest,
        (state) => ({
            ...state,
            isLoading: true,
        })
    ),

    on(
        DonationCampaignCustomizationActions.updateDonationCampaignCustomizationRequestSuccess,
        (state) => ({
            ...state,
            isLoading: false,
        })
    ),

    on(
        DonationCampaignCustomizationActions.updateDonationCampaignCustomizationRequestFailure,
        (state) => ({
            ...state,
            isLoading: false,
        })
    ),

    on(
        DonationCampaignCustomizationActions.createOrUpdateSpecificDonationCampaignCustomizationRequest,
        (state) => ({
            ...state,
            isLoading: true,
        })
    ),

    on(
        DonationCampaignCustomizationActions.createOrUpdateSpecificDonationCampaignCustomizationRequestSuccess,
        (state) => ({
            ...state,
            isLoading: false,
        })
    ),

    on(
        DonationCampaignCustomizationActions.createOrUpdateSpecificDonationCampaignCustomizationRequestFailure,
        (state) => ({
            ...state,
            isLoading: false,
        })
    )
);
