import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { findIndex, isEqual } from 'lodash-es';
import { FilesActions } from './files.action';
import { FilesState } from './files.state';

export const filesInitialState: FilesState = {
    isLoading: false,
    isLoadingDownload: false,
    isLoadingUpload: false,
    currentFolderPageIndex: 0,
    path: [],
};

export const filesReducer = createReducer(
    filesInitialState,

    on(FilesActions.reset, () => filesInitialState),

    on(FilesActions.selectFile, (state, { file }) => {
        return {
            ...state,
            selectedFile: file,
            downloadedFileBlobUrl: null,
            downloadedFileError: null,
            associationLegalDocumentWarning: null,
        };
    }),

    on(FilesActions.selectFolder, (state, { folder }) => {
        let path = structuredClone(state.path);
        const index = findIndex(path, (f) => isEqual(folder, f));
        if (index !== -1) {
            // Navigating back
            path = path.slice(0, index + 1);
        } else {
            // Navigating forward
            path.push(folder);
        }
        return {
            ...state,
            selectedFolder: folder,
            currentFolderPageIndex: null,
            currentFolderTotalPages: null,
            currentFolderTotalCount: null,
            currentFolderPageSize: null,
            path,
        };
    }),

    on(FilesActions.unselectFile, (state) => {
        return {
            ...state,
            selectedFile: null,
        };
    }),

    on(FilesActions.unselectFolder, (state) => {
        return {
            ...state,
            selectedFolder: null,
            currentFolderPageIndex: null,
            currentFolderTotalPages: null,
            currentFolderTotalCount: state.data.length,
            currentFolderPageSize: null,
            path: [],
        };
    }),

    on(FilesActions.changeFolderPage, (state, { pageIndex }) => {
        return {
            ...state,
            currentFolderPageIndex: pageIndex,
        };
    }),

    on(FilesActions.loadFilesRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(FilesActions.loadFilesRequestSuccess, (state, { response }) =>
        produce(state, (draftState) => {
            draftState.isLoading = false;
            if (state.selectedFolder) {
                draftState.selectedFolder.children = response.data;
            } else {
                draftState.data = response.data;
            }
            draftState.currentFolderPageIndex = response.pageIndex;
            draftState.currentFolderPageSize = response.pageSize;
            draftState.currentFolderTotalCount = response.totalCount;
            draftState.currentFolderTotalPages = response.totalPages;
        })
    ),

    on(FilesActions.loadFilesRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(FilesActions.downloadSponsorshipAgreementRequest, (state) => {
        return {
            ...state,
            isLoadingDownload: true,
            downloadedFileBlobUrl: null,
            downloadedFileError: null,
            associationLegalDocumentWarning: null,
        };
    }),

    on(
        FilesActions.downloadSponsorshipAgreementRequestSuccess,
        (state, { blob }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileBlobUrl: URL.createObjectURL(blob),
            };
        }
    ),

    on(
        FilesActions.downloadSponsorshipAgreementRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileError: error,
            };
        }
    ),

    on(FilesActions.downloadSponsorshipAmendmentRequest, (state) => {
        return {
            ...state,
            isLoadingDownload: true,
            downloadedFileBlobUrl: null,
            downloadedFileError: null,
            associationLegalDocumentWarning: null,
        };
    }),

    on(
        FilesActions.downloadSponsorshipAmendmentRequestSuccess,
        (state, { blob }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileBlobUrl: URL.createObjectURL(blob),
            };
        }
    ),

    on(
        FilesActions.downloadSponsorshipAmendmentRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileError: error,
            };
        }
    ),

    on(FilesActions.downloadSponsorshipAttendanceRequest, (state) => {
        return {
            ...state,
            isLoadingDownload: true,
            downloadedFileBlobUrl: null,
            downloadedFileError: null,
            associationLegalDocumentWarning: null,
        };
    }),

    on(
        FilesActions.downloadSponsorshipAttendanceRequestSuccess,
        (state, { blob }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileBlobUrl: URL.createObjectURL(blob),
            };
        }
    ),

    on(
        FilesActions.downloadSponsorshipAttendanceRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileError: error,
            };
        }
    ),

    on(
        FilesActions.uploadAssociationLegalDocumentRequest,
        (state, { file }) => {
            return {
                ...state,
                isLoadingUpload: true,
                downloadedFileBlobUrl: URL.createObjectURL(file),
            };
        }
    ),

    on(FilesActions.uploadAssociationLegalDocumentRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingUpload: false,
        };
    }),

    on(FilesActions.uploadAssociationLegalDocumentRequestFail, (state) => {
        return {
            ...state,
            isLoadingUpload: false,
        };
    }),

    on(FilesActions.downloadAssociationLegalDocumentRequest, (state) => {
        return {
            ...state,
            isLoadingDownload: true,
            downloadedFileBlobUrl: null,
            downloadedFileError: null,
            associationLegalDocumentWarning: null,
        };
    }),

    on(
        FilesActions.downloadAssociationLegalDocumentRequestSuccess,
        (state, { blob }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileBlobUrl: URL.createObjectURL(blob),
            };
        }
    ),

    on(
        FilesActions.downloadAssociationLegalDocumentRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingDownload: false,
                associationLegalDocumentWarning: error,
            };
        }
    ),

    on(FilesActions.downloadFundraisingAppealRequest, (state) => {
        return {
            ...state,
            isLoadingDownload: true,
            downloadedFileBlobUrl: null,
            downloadedFileError: null,
            associationLegalDocumentWarning: null,
        };
    }),

    on(
        FilesActions.downloadFundraisingAppealRequestSuccess,
        (state, { blob }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileBlobUrl: URL.createObjectURL(blob),
            };
        }
    ),

    on(
        FilesActions.downloadFundraisingAppealRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileError: error,
            };
        }
    ),

    on(FilesActions.downloadCampaignTaxReceiptRequest, (state) => {
        return {
            ...state,
            isLoadingDownload: true,
            downloadedFileBlobUrl: null,
            downloadedFileError: null,
            associationLegalDocumentWarning: null,
        };
    }),

    on(
        FilesActions.downloadCampaignTaxReceiptRequestSuccess,
        (state, { blob }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileBlobUrl: URL.createObjectURL(blob),
            };
        }
    ),

    on(
        FilesActions.downloadCampaignTaxReceiptRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileError: error,
            };
        }
    ),

    on(FilesActions.downloadSubscriptionTaxReceiptRequest, (state) => {
        return {
            ...state,
            isLoadingDownload: true,
            downloadedFileBlobUrl: null,
            downloadedFileError: null,
            associationLegalDocumentWarning: null,
        };
    }),

    on(
        FilesActions.downloadSubscriptionTaxReceiptRequestSuccess,
        (state, { blob }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileBlobUrl: URL.createObjectURL(blob),
            };
        }
    ),

    on(
        FilesActions.downloadSubscriptionTaxReceiptRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingDownload: false,
                downloadedFileError: error,
            };
        }
    )
);
