<fuse-card class="h-64 mb-5">
    <div class="flex w-full">
        <div class="w-1/4 relative">
            <ng-template #nonActive>
                <div
                    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col text-center"
                    *ngIf="!project.isActive"
                >
                    <mat-icon
                        class="h-15 w-15 m-auto"
                        [svgIcon]="'heroicons_solid:eye-slash'"
                        matPrefix
                    ></mat-icon>
                    <span class="font-bold text-2xl mt-3">{{
                        "associationprojects.not-published" | transloco
                    }}</span>
                </div>
            </ng-template>
            <div *ngIf="project.images.length > 0" class="w-full h-full">
                <ng-container *ngIf="project.images.length === 1">
                    <img
                        [src]="project.images[0].url"
                        class="w-full h-full object-cover"
                    />
                </ng-container>
                <ng-container *ngIf="project.images.length === 2">
                    <div class="w-full h-full grid grid-rows-2 grid-col-1">
                        <img
                            [src]="project.images[0].url"
                            class="object-cover"
                        />
                        <img
                            [src]="project.images[1].url"
                            class="object-cover"
                        />
                    </div>
                </ng-container>
                <ng-container *ngIf="project.images.length === 3">
                    <div class="w-full h-full grid grid-cols-2">
                        <div class="col-span-1">
                            <img
                                [src]="project.images[0].url"
                                class="object-cover h-full"
                            />
                        </div>
                        <div
                            class="col-span-1 grid grid-rows-2 grid-col-1 h-full"
                        >
                            <img
                                [src]="project.images[1].url"
                                class="object-cover h-full w-full"
                            />
                            <img
                                [src]="project.images[2].url"
                                class="object-cover h-full w-full"
                            />
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="project.images.length === 4">
                    <div class="w-full h-full grid grid-cols-2">
                        <div
                            *ngFor="let image of project.images"
                            class="col-span-1"
                        >
                            <img
                                [src]="image.url"
                                class="w-full h-full object-cover"
                            />
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!project.isActive">
                    <ng-container *ngTemplateOutlet="nonActive"></ng-container>
                </ng-container>
            </div>
            <div
                *ngIf="project.images.length === 0"
                class="w-full h-full flex border-r"
            >
                <mat-icon
                    *ngIf="project.isActive; else nonActive"
                    class="icon-size-16 m-auto"
                    [svgIcon]="'heroicons_solid:photo'"
                >
                </mat-icon>
            </div>
            <div class="absolute top-5 right-5">
                <button
                    mat-mini-fab
                    (click)="openUploadCoverDialog(project.id)"
                    color="primary"
                >
                    <mat-icon
                        color="accent"
                        class="icon-size-5 svg-white"
                        matPrefix
                        [svgIcon]="'heroicons_solid:camera'"
                    ></mat-icon>
                </button>
            </div>
        </div>

        <div class="w-2/4 border-r-2 flex flex-col">
            <div class="flex flex-col border-b-2 p-6 pb-3">
                <span class="text-2xl truncate font-bold"
                    >{{ project.name }}
                </span>
                <div class="truncate">
                    <span
                        >{{ "associationprojects.last-edited-the" | transloco }}
                    </span>
                    <span
                        class="truncate font-semibold"
                        [matTooltip]="lastUpdate | date"
                        >{{ lastUpdate | date }}</span
                    >
                </div>
            </div>
            <div class="flex justify-center border-b-2 px-6 py-2">
                <donation-impact [project]="project"></donation-impact>
            </div>
            <div class="flex flex-col overflow-x-scroll px-6 p-2">
                <span class="font-bold">{{
                    "associationprojects.activity-fields" | transloco
                }}</span>

                <enum-tag-list
                    class="mt-2"
                    *ngIf="project.activityFields"
                    [tags]="project.activityFields"
                    [enum]="EActivityField"
                    [maxTagsVisible]="3"
                ></enum-tag-list>
            </div>
        </div>
        <div class="w-1/4 p-6 flex flex-col justify-between">
            <div class="flex flex-col gap-1">
                <span class="font-bold">{{
                    "associationprojects.action-zones" | transloco
                }}</span>

                <div class="flex flex-wrap gap-1">
                    <bslk-tag
                        [background]="'tertiary'"
                        *ngFor="let location of project.locations.slice(0, 4)"
                        >{{ location.name }}</bslk-tag
                    >
                    <span *ngIf="project.locations.length > 4"
                        >& {{ project.locations.length - 4 }}
                        {{ "associationprojects.more" | transloco }}</span
                    >
                </div>
            </div>

            <div>
                <bslk-menu
                    (onAction)="onMenuAction($event)"
                    [icon]="'heroicons_solid:cog-6-tooth'"
                    [label]="'associationprojects.actions' | transloco"
                    [items]="[
                            {
                                icon: 'heroicons_solid:eye',
                                routerLink: '/a/projects/' + this.project.id,
                                label: 'associationprojects.view' | transloco,
                            },
                            {
                                icon: 'heroicons_solid:document-arrow-up',
                                action: 'publish',
                                label: 'associationprojects.publish' | transloco,
                                hide: this.project.isActive,
                            },
                            {
                                icon: 'heroicons_solid:pencil',
                                action: 'modify',
                                label: 'associationprojects.modify' | transloco,
                            },
                            {
                                icon: 'heroicons_solid:archive-box',
                                action: 'archive',
                                label: 'associationprojects.archive' | transloco,
                            },
                    ]"
                ></bslk-menu>
            </div>
        </div>
    </div>
</fuse-card>
