import {
    AsyncPipe,
    JsonPipe,
    KeyValuePipe,
    NgFor,
    NgIf,
} from '@angular/common';
import { Component } from '@angular/core';
import {
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ReportConfigViewModel } from 'app/api';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { ImageUploadDialogInput } from 'app/shared/components/file-upload/file-upload-dialog.component';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { ReportActions } from 'app/store/report/report.action';
import { ReportSelectors } from 'app/store/report/report.selectors';

@Component({
    selector: 'company-admin-dashboard-report-dialog',
    templateUrl: './report-dialog.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BaseDialogComponent,
        BslkClassicButtonComponent,
        BslkClassicToggleComponent,
        JsonPipe,
        KeyValuePipe,
        LetDirective,
        MatButtonModule,
        FuseAlertComponent,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        NgFor,
        NgIf,
        TranslocoModule,
    ],
})
export class CompanyAdminDashboardReportDialogComponent {
    hasSubmitted = false;

    formGroup = this.fb.group({
        lang: [this.translocoService.getActiveLang()],
        logoAsBase64: [null, Validators.required],
        backgroundColor: ['#ee846f', { nonNullable: true }],
        textOnBackgroundColor: ['#000000', { nonNullable: true }],
        primaryColor: ['#f7c242', { nonNullable: true }],
        textOnPrimaryColor: ['#ffffff', { nonNullable: true }],
        secondaryColor: ['#8490c8', { nonNullable: true }],
        textOnSecondaryColor: ['#ffffff', { nonNullable: true }],
    });

    isLoading$ = this.store.select(ReportSelectors.selectIsLoading);

    constructor(
        private store: Store,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<CompanyAdminDashboardReportDialogComponent>,
        private translocoService: TranslocoService,
        private fileUploadDialogService: FileUploadDialogService
    ) {}

    get showError() {
        return (
            this.formGroup.controls.logoAsBase64.invalid && this.hasSubmitted
        );
    }

    close() {
        this.dialogRef.close();
    }

    export() {
        this.hasSubmitted = true;
        this.store.dispatch(
            ReportActions.downloadReportRequest({
                config: this.formGroup.value as ReportConfigViewModel,
            })
        );
    }

    openFileUploadDialog() {
        this.fileUploadDialogService.open({
            formControl: this.formGroup.controls.logoAsBase64,
            maxFileSize: 2000 * 1024,
        } as ImageUploadDialogInput);
    }
}
