/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ECompanyPillar = 'Transparency' | 'DiversityInclusion' | 'EnvironmentalSustainability' | 'EthicalBusinessPractices' | 'CommunityEngagement' | 'EmployeeWellbeing' | 'CustomerSatisfaction' | 'Innovation' | 'EconomicResponsibility' | 'FairLaborPractices' | 'CorporateGovernance' | 'SupplyChainAccountability' | 'HumanRights' | 'RenewableEnergyUse' | 'WasteReduction' | 'CarbonFootprintReduction' | 'ResourceConservation' | 'HealthAndSafety' | 'QualityManagement' | 'StakeholderEngagement' | 'LocalEconomicDevelopment' | 'DataProtectionPrivacy' | 'FairTrade' | 'AntiCorruption' | 'BiodiversityPreservation' | 'WaterConservation' | 'SocialEquity' | 'AntiDiscrimination' | 'GenderEquality' | 'ConsumerProtection' | 'CorporatePhilanthropy' | 'SustainableProcurement' | 'EnergyEfficiency' | 'TalentDevelopment' | 'FinancialTransparency';

export const ECompanyPillar = {
    Transparency: 'Transparency' as ECompanyPillar,
    DiversityInclusion: 'DiversityInclusion' as ECompanyPillar,
    EnvironmentalSustainability: 'EnvironmentalSustainability' as ECompanyPillar,
    EthicalBusinessPractices: 'EthicalBusinessPractices' as ECompanyPillar,
    CommunityEngagement: 'CommunityEngagement' as ECompanyPillar,
    EmployeeWellbeing: 'EmployeeWellbeing' as ECompanyPillar,
    CustomerSatisfaction: 'CustomerSatisfaction' as ECompanyPillar,
    Innovation: 'Innovation' as ECompanyPillar,
    EconomicResponsibility: 'EconomicResponsibility' as ECompanyPillar,
    FairLaborPractices: 'FairLaborPractices' as ECompanyPillar,
    CorporateGovernance: 'CorporateGovernance' as ECompanyPillar,
    SupplyChainAccountability: 'SupplyChainAccountability' as ECompanyPillar,
    HumanRights: 'HumanRights' as ECompanyPillar,
    RenewableEnergyUse: 'RenewableEnergyUse' as ECompanyPillar,
    WasteReduction: 'WasteReduction' as ECompanyPillar,
    CarbonFootprintReduction: 'CarbonFootprintReduction' as ECompanyPillar,
    ResourceConservation: 'ResourceConservation' as ECompanyPillar,
    HealthAndSafety: 'HealthAndSafety' as ECompanyPillar,
    QualityManagement: 'QualityManagement' as ECompanyPillar,
    StakeholderEngagement: 'StakeholderEngagement' as ECompanyPillar,
    LocalEconomicDevelopment: 'LocalEconomicDevelopment' as ECompanyPillar,
    DataProtectionPrivacy: 'DataProtectionPrivacy' as ECompanyPillar,
    FairTrade: 'FairTrade' as ECompanyPillar,
    AntiCorruption: 'AntiCorruption' as ECompanyPillar,
    BiodiversityPreservation: 'BiodiversityPreservation' as ECompanyPillar,
    WaterConservation: 'WaterConservation' as ECompanyPillar,
    SocialEquity: 'SocialEquity' as ECompanyPillar,
    AntiDiscrimination: 'AntiDiscrimination' as ECompanyPillar,
    GenderEquality: 'GenderEquality' as ECompanyPillar,
    ConsumerProtection: 'ConsumerProtection' as ECompanyPillar,
    CorporatePhilanthropy: 'CorporatePhilanthropy' as ECompanyPillar,
    SustainableProcurement: 'SustainableProcurement' as ECompanyPillar,
    EnergyEfficiency: 'EnergyEfficiency' as ECompanyPillar,
    TalentDevelopment: 'TalentDevelopment' as ECompanyPillar,
    FinancialTransparency: 'FinancialTransparency' as ECompanyPillar
};

