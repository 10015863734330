<ng-template #panel>
    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-160 sm:w-160 sm:rounded-2xl overflow-hidden shadow-lg"
    >
        <!-- Header -->
        <div
            *ngIf="!hideHeader"
            [class.bg-primary]="color === 'primary'"
            [class.bg-warn]="color === 'warn'"
            [class.bg-tertiary]="color === 'tertiary'"
            class="flex shrink-0 items-center justify-between py-4 pr-4 pl-6"
        >
            <div class="text-lg font-medium leading-10 text-white">
                <ng-content select="[title]"></ng-content>
            </div>
            <button mat-icon-button (click)="closePanel()">
                <mat-icon
                    class="icon-size-5 text-white"
                    [svgIcon]="'heroicons_solid:x-mark'"
                ></mat-icon>
            </button>
        </div>

        <!-- Content -->
        <div
            [class.sm:max-h-90]="size === 'small'"
            [class.sm:max-h-120]="size === 'medium'"
            [class.sm:max-h-150]="size === 'large'"
            [class.bg-accent-200]="!opaqueBackground"
            [class.bg-white]="opaqueBackground"
            [class.bg-opacity-80]="opaqueBackground"
            [class.overflow-y-auto]="overflowAuto"
            class="relative flex flex-col flex-auto bg-accent-200 p-3"
        >
            <ng-content></ng-content>
        </div>

        <div class="bg-card">
            <ng-content select="[footer]"></ng-content>
        </div>
    </div>
</ng-template>
