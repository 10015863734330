import {
    GoogleLoginProvider,
    SocialAuthService,
} from '@abacritt/angularx-Social-login';
import { Injectable, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import {
    AuthenticationResponseViewModel,
    AuthenticationService,
    EUserRole,
    UserViewModel,
} from 'app/api';
import { USER_SESSION_INFO_LS_KEY } from 'app/shared/utils/local-storage.utils';
import { UserActions } from 'app/store/user/user/user.actions';
import { isNil } from 'lodash-es';
import { Subject, switchMap, takeUntil } from 'rxjs';
import {
    LinkedInLoginProvider,
    LinkedInLoginProviderSignInConfig,
} from './providers/linkedin-login.provider';

export type AuthenticationOperationType =
    | 'sign-in'
    | 'sign-up'
    | 'share-certificate';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private isRegistering = false;

    /**
     * Constructor
     */
    constructor(
        private socialAuthService: SocialAuthService,
        private msalAuthService: MsalService,
        private apiAuthService: AuthenticationService,
        private store: Store
    ) {
        this.socialAuthService.authState
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((socialUser) => {
                if (socialUser.provider === GoogleLoginProvider.PROVIDER_ID) {
                    if (this.isRegistering) {
                        this.store.dispatch(
                            UserActions.signUpUserWithGoogle({
                                user: structuredClone(socialUser),
                            })
                        );
                    } else {
                        this.store.dispatch(
                            UserActions.signedInWithGoogle({
                                user: structuredClone(socialUser),
                            })
                        );
                    }
                }
            });
        this.msalAuthService
            .initialize()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe();
    }

    tryMicrosoftLogin(operationType: AuthenticationOperationType) {
        this.msalAuthService.instance.handleRedirectPromise().then(() => {
            this.apiAuthService
                .authOauthStatePost()
                .pipe(
                    switchMap((state) => {
                        return this.msalAuthService
                            .loginPopup({
                                scopes: [],
                                state,
                            })
                            .pipe(takeUntil(this._unsubscribeAll));
                    })
                )
                .subscribe({
                    next: (result: AuthenticationResult) => {
                        if (operationType === 'sign-in') {
                            this.store.dispatch(
                                UserActions.signedInWithMicrosoft({
                                    result: structuredClone(result),
                                })
                            );
                        } else {
                            this.store.dispatch(
                                UserActions.signUpUserWithMicrosoft({
                                    user: structuredClone(result),
                                })
                            );
                        }

                        this.msalAuthService.instance.setActiveAccount(
                            result.account
                        );
                    },
                });
        });
    }

    trylinkedInLogin(options: LinkedInLoginProviderSignInConfig): void {
        this.socialAuthService
            .signIn(LinkedInLoginProvider.PROVIDER_ID, options)
            .then(() => {});
    }

    trySignInWithEmail(email: string, password: string) {
        this.store.dispatch(UserActions.signedInWithEmail({ email, password }));
    }

    trySignUpWithEmail(user: UserViewModel) {
        this.store.dispatch(UserActions.signUpUserWithEmail({ user }));
    }

    signIn(authenticationResponse: AuthenticationResponseViewModel) {
        localStorage.setItem(
            USER_SESSION_INFO_LS_KEY,
            JSON.stringify(authenticationResponse)
        );
    }

    signOut(): boolean {
        const wasSignedIn = !isNil(
            localStorage.getItem(USER_SESSION_INFO_LS_KEY)
        );
        localStorage.removeItem(USER_SESSION_INFO_LS_KEY);
        return wasSignedIn;
    }

    setIsRegistering(isRegistering: boolean, userRole?: EUserRole) {
        if (isRegistering && userRole) {
            this.store.dispatch(
                UserActions.startBuildingUser({
                    user: {
                        userRole,
                    },
                })
            );
        }
        this.isRegistering = isRegistering;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
