import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store';

const selectState = (state: AppState) => state.donationCampaignCustomization;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectDonationCampaignCustomization = createSelector(
    selectState,
    (state) => state.donationCampaignCustomization
);

export const DonationCampaignCustomizationSelectors = {
    selectIsLoading,
    selectDonationCampaignCustomization,
};
