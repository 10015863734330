export function getEnumValues(enumObj: any): string[] {
    return Object.values(enumObj);
}

export function getEnumIntValue(enumObj: any, enumVal: string) {
    const keys = Object.keys(enumObj);
    return keys.indexOf(enumVal);
}

export function getEnumStringValue(enumObj: any, enumVal: number) {
    const keys = Object.keys(enumObj);
    return keys[enumVal];
}

export type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};
