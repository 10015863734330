import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    AssociationViewModel,
    EAssociationState,
    LocationViewModel,
} from 'app/api';
import { CustomValidators } from 'app/shared/utils/custom-validators.utils';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';
import { IFormService } from './form-service';
import { LocationFormService } from './location-form.service';

@Injectable({
    providedIn: 'root',
})
export class AssociationFormService
    extends LocationFormService
    implements IFormService<AssociationViewModel>
{
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder) {
        super(fb);
        this.initForm();
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            id: [''],
            location: this.initLocationForm(),
            description: [
                '',
                CustomValidators.htmlTextLengthValidator(0, 3000),
            ],
            mediaUrl: ['', CustomValidators.youtubeVideoIdValidator],
            name: ['', [Validators.required, Validators.maxLength(50)]],
            code: [''],
            state: [EAssociationState.Prospect],
            tags: [
                [],
                [
                    Validators.required,
                    CustomValidators.maxArrayLengthValidator(10),
                ],
            ],
            users: [[]],
        });
        return this.formGroup;
    }

    updateForm(entity: AssociationViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
        this.patchLocation(entity.location);
    }

    getEntity(): { [key: string]: AssociationViewModel } {
        const associationViewModel =
            returnOnlyNonNullFields<AssociationViewModel>(
                this.formGroup.value
            ) as AssociationViewModel;

        associationViewModel.location =
            returnOnlyNonNullFields<LocationViewModel>(
                associationViewModel.location
            );

        return { associationViewModel };
    }

    get getFormGroup() {
        return this.formGroup;
    }
}
