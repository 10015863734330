<auth-template
    class="w-full"
    [title]="'signup.sign-in' | transloco"
    *ngrxLet="isLoading$ as isLoading"
>
    <div
        *ngIf="isLoading"
        class="flex flex-col justify-center items-center pt-12 pb-8"
    >
        <mat-progress-spinner
            [diameter]="72"
            [mode]="'indeterminate'"
        ></mat-progress-spinner>
    </div>

    <div *ngrxLet="loginError$ as loginError">
        <fuse-alert
            class="mt-4 flex justify-center"
            *ngIf="loginError"
            [appearance]="'outline'"
            [showIcon]="true"
            [type]="'error'"
            [@shake]="true"
        >
            {{ loginError | error : "login.errors." }}
        </fuse-alert>
    </div>

    <div footer>
        <ng-container *ngIf="!isSignedIn">
            <div class="flex justify-center items-baseline font-medium">
                <a class="font-bold" [routerLink]="'/sign-in'">
                    <button mat-flat-button>
                        {{ "signup.go-back" | transloco }}
                    </button>
                </a>
            </div>
        </ng-container>
    </div>
</auth-template>
