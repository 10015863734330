import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyService } from 'app/api';
import { CompanyFormService } from 'app/shared/services/form/company-form.service';
import {
    createCreateItemEffect,
    createDeleteItemEffect,
    createLoadListRequestEffect,
    createReloadEffect,
    createSelectedItemEffect,
    createStartCreationEffect,
    createUpdateSelectedItemEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { CompanyActions } from './company.actions';
import { CompanySelectors } from './company.selectors';

@Injectable()
export class CompanyEffects {
    loadCompanies$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.companyService,
        CompanyActions,
        CompanySelectors,
        this.companyService.companyGet
    );

    reload$ = createReloadEffect(this.actions$, CompanyActions);

    itemSelected$ = createSelectedItemEffect(
        this.store,
        this.actions$,
        CompanyActions,
        CompanySelectors,
        this.companyFormService
    );

    updateCompany$ = createUpdateSelectedItemEffect(
        this.companyService,
        this.actions$,
        CompanyActions,
        this.companyFormService,
        'company-update',
        this.companyService.companyPut
    );

    createCompany$ = createCreateItemEffect(
        this.companyService,
        this.actions$,
        CompanyActions,
        this.companyFormService,
        'company-create',
        this.companyService.companyPost
    );

    deleteCompany$ = createDeleteItemEffect(
        this.companyService,
        this.actions$,
        CompanyActions,
        'company-delete',
        this.companyService.companyDelete
    );

    startCreation$ = createStartCreationEffect(
        this.actions$,
        CompanyActions,
        this.companyFormService
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private companyService: CompanyService,
        private companyFormService: CompanyFormService
    ) {}
}
