import {
    AsyncPipe,
    NgFor,
    NgIf,
    NgSwitch,
    NgSwitchCase,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormArray, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { ProjectFormService } from 'app/shared/services/form/project-form.service';
import { ProjectListActions } from 'app/store/project/project-list/project-list.actions';
import { ProjectActions } from 'app/store/project/project/project.action';
import { ProjectSelectors } from 'app/store/project/project/project.selectors';
import { QuillEditorComponent } from 'ngx-quill';
import { CreateProjectValidationService } from './create-project-validation.service';
import { CreateProjectStep0Component } from './steps/step0/create-project-step-0.component';
import { CreateProjectStep1Component } from './steps/step1/create-project-step-1.component';
import { CreateProjectStep2Component } from './steps/step2/create-project-step-2.component';
import { CreateProjectStep3Component } from './steps/step3/create-project-step-3.component';

export type CreateProjectDialogInput = {
    isEdit: boolean;
    isFromEntityPage: boolean;
};

@Component({
    selector: 'create-project',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './create-project.component.html',
    styleUrls: ['./create-project.component.scss'],
    animations: fuseAnimations,
    imports: [
        FuseAlertComponent,
        QuillEditorComponent,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        TranslocoModule,
        LetDirective,
        AsyncPipe,
        NgIf,
        NgFor,
        NgSwitch,
        BaseDialogComponent,
        NgSwitchCase,
        MatProgressSpinnerModule,
        MatStepperModule,
        CreateProjectStep0Component,
        CreateProjectStep1Component,
        CreateProjectStep2Component,
        CreateProjectStep3Component,
    ],
})
export class CreateProjectDialogComponent implements OnInit {
    @ViewChild('stepper') private stepper: MatStepper;
    @ViewChild('formDiv') formDiv: ElementRef;

    hasTriedToValidateStep = false;

    isCreatingLoading$ = this.store.select(
        ProjectSelectors.selectIsCreatingLoading
    );

    constructor(
        private store: Store,
        private projectFormService: ProjectFormService,
        private projectValidationService: CreateProjectValidationService,
        @Inject(MAT_DIALOG_DATA) public data: CreateProjectDialogInput
    ) {}
    get formGroup() {
        return this.projectFormService.getFormGroup;
    }

    get hasPreviousStep() {
        return this.stepper?.selectedIndex > 0;
    }

    get isCurrentStepValid() {
        return this.projectValidationService.isStepValid(
            this.stepper?.selectedIndex
        );
    }

    get missionSlotsArray() {
        return this.formGroup.controls.missionSlots as FormArray;
    }

    get getCurrentStepErrors() {
        return this.projectValidationService.getCurrentStepErrors(
            this.stepper?.selectedIndex
        );
    }

    get displayErrorsSummary() {
        return (
            this.hasTriedToValidateStep && this.getCurrentStepErrors.length > 0
        );
    }

    ngOnInit(): void {
        if (!this.data.isEdit) {
            this.projectFormService.initForm();
        }
    }

    nextStep() {
        if (
            this.projectValidationService.isStepValid(
                this.stepper.selectedIndex
            )
        ) {
            this.stepper.next();
        }
    }

    previousStep() {
        this.stepper.previous();
    }

    continue() {
        this.formGroup.updateValueAndValidity();
        if (
            !this.projectValidationService.isStepValid(
                this.stepper.selectedIndex
            )
        ) {
            this.hasTriedToValidateStep = true;
            return;
        }

        this.hasTriedToValidateStep = false;
        if (
            this.stepper.selectedIndex === 3 &&
            this.projectValidationService.isStep3Valid
        ) {
            const actionToDispatch = this.data.isEdit
                ? this.data.isFromEntityPage
                    ? ProjectActions.updateProjectRequest()
                    : ProjectListActions.UpdateSelectedItem()
                : ProjectActions.createProjectRequest();
            this.store.dispatch(actionToDispatch);
            return;
        }
        this.formDiv.nativeElement.scrollTop = 0;
        this.nextStep();
    }

    isStepValid(step: number) {
        return this.projectValidationService.isStepValid(step);
    }

    close() {
        this.store.dispatch(ProjectActions.closeDialog());
    }
}
