import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkClassicProgressBar } from '@bslk/components/progress-bar/classic-progress-bar/classic-progress-bar.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { DonationCampaignSelectors } from 'app/store/donation/donation-campaign/donation-campaign.selectors';

@Component({
    selector: 'donation-campaign-requests-repartition',
    templateUrl: './requests-repartition.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkClassicProgressBar,
        ClassicAvatarComponent,
        LetDirective,
        MatIconModule,
        NgFor,
        NgIf,
        TranslocoModule,
    ],
})
export class DonationCampaignRequestsRepartitionComponent {
    requestsRepartition$ = this.store.select(
        DonationCampaignSelectors.selectRequestsRepartition
    );

    constructor(private store: Store) {}
}
