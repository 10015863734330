import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkAdvancedChartComponent } from '@bslk/components/charts/bslk-advanced-chart/bslk-advanced-chart.component';
import { BslkHexColors } from '@bslk/types/bslk-types';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EGenderDecimalEnumChart } from 'app/api';
import { CompanyAdminDashboardActions } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.actions';
import { CompanyAdminDashboardSelectors } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.selectors';
import { takeUntil } from 'rxjs';
import { CompanyAdminDashboardChartComponent } from '../chart.component';

@Component({
    selector: 'gender-participation-repartition',
    templateUrl: './gender-participation-repartition.component.html',
    standalone: true,
    imports: [
        LetDirective,
        AsyncPipe,
        NgIf,
        BslkAdvancedChartComponent,
        MatIconModule,
        TranslocoModule,
    ],
})
export class ParticipationRateByGenderComponent
    extends CompanyAdminDashboardChartComponent
    implements OnInit, OnDestroy
{
    hasData = false;

    constructor(
        protected store: Store,
        private translocoService: TranslocoService,
        protected cdRef: ChangeDetectorRef,
        protected datePipe: DatePipe
    ) {
        super(store, datePipe, cdRef, 'gender-participation-repartition');
    }

    ngOnInit() {
        this.store.dispatch(
            CompanyAdminDashboardActions.checkIfKpiIsLoaded({
                kpi: this.kpiName,
            })
        );

        this.store
            .select(
                CompanyAdminDashboardSelectors.selectKpiResult(this.kpiName)
            )
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((response: EGenderDecimalEnumChart) => {
                if (response) {
                    if (response) {
                        this.hasData = response.hasData;
                        this.series = Object.values(response.data);
                        this.labels = Object.keys(response.data).map(
                            (genderKey) =>
                                this.translocoService.translate(
                                    'enums.EGender.' + genderKey
                                )
                        );
                        this.buildOptions();
                    }
                }
            });
    }

    buildOptions() {
        this.chartOptions = {
            chart: {
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    offsetY: 10,
                    dataLabels: {
                        name: {
                            show: true,
                        },
                        value: {
                            fontSize: '25px',
                            fontFamily: 'Poppins',
                            fontWeight: 900,
                        },
                    },
                },
            },
            colors: [
                BslkHexColors.primary,
                BslkHexColors.tertiary,
                BslkHexColors.quaternary,
            ],
            legend: {
                show: true,
                fontSize: '15px',
                horizontalAlign: 'center',
                fontFamily: 'Poppins',
                position: 'bottom',
                offsetX: 0,
                offsetY: 10,
            },
            grid: {
                padding: {
                    top: -20,
                    bottom: -20,
                    left: 0,
                    right: 0,
                },
            },
        };
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
