/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EValidationError = 'NoAccount' | 'WrongCode' | 'NoTriesLeft' | 'CodeExpired' | 'EmailAlreadyValidated';

export const EValidationError = {
    NoAccount: 'NoAccount' as EValidationError,
    WrongCode: 'WrongCode' as EValidationError,
    NoTriesLeft: 'NoTriesLeft' as EValidationError,
    CodeExpired: 'CodeExpired' as EValidationError,
    EmailAlreadyValidated: 'EmailAlreadyValidated' as EValidationError
};

