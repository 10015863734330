<div class="flex flex-col gap-1">
    <ng-content></ng-content>
    <div class="flex gap-4">
        <form class="grow p-3 flex flex-col gap-3" [formGroup]="group">
            <div class="w-full flex gap-2">
                <!-- Simple number -->
                <div *ngIf="!group.controls.isRange.value" class="flex-1">
                    <mat-form-field
                        class="w-full"
                        [subscriptSizing]="'dynamic'"
                        [flotLabel]="'always'"
                    >
                        <mat-label>{{
                            "bslkbusiness.metric-configurator.number"
                                | transloco
                        }}</mat-label>
                        <input
                            matInput
                            type="number"
                            [formControlName]="'number'"
                            required
                        />
                    </mat-form-field>
                </div>

                <!-- Range -->
                <div
                    class="flex-1 flex gap-2"
                    *ngIf="group.controls.isRange.value"
                >
                    <div class="flex-1">
                        <mat-form-field
                            class="w-full"
                            [subscriptSizing]="'dynamic'"
                        >
                            <mat-label>{{
                                "bslkbusiness.metric-configurator.from-number"
                                    | transloco
                            }}</mat-label>
                            <input
                                matInput
                                type="number"
                                [formControlName]="'fromNumber'"
                                required
                            />
                        </mat-form-field>
                    </div>

                    <div class="flex-1">
                        <mat-form-field
                            class="w-full"
                            [subscriptSizing]="'dynamic'"
                        >
                            <mat-label>{{
                                "bslkbusiness.metric-configurator.to-number"
                                    | transloco
                            }}</mat-label>
                            <input
                                matInput
                                type="number"
                                [formControlName]="'toNumber'"
                                required
                            />
                        </mat-form-field>
                    </div>
                </div>

                <bslk-advanced-select-sync
                    class="flex-1"
                    *ngIf="unitsTranslationsLoaded"
                    [items]="unitsAdvancedSelectItems"
                    [group]="group"
                    [controlName]="'unit'"
                    [label]="
                        'bslkbusiness.metric-configurator.unit' | transloco
                    "
                    [searchPlaceholder]="
                        'bslkbusiness.metric-configurator.search-unit'
                            | transloco
                    "
                ></bslk-advanced-select-sync>
            </div>

            <div class="w-full flex gap-2">
                <bslk-classic-toggle
                    (valueChange)="isRangeChanged($event)"
                    [label]="
                        'bslkbusiness.metric-configurator.is-range' | transloco
                    "
                    [color]="'warn'"
                    [size]="'small'"
                    [choices]="[
                        { text: 'shared.common.yes' | transloco, value: true },
                        { text: 'shared.common.no' | transloco, value: false }
                    ]"
                    [control]="group.controls.isRange"
                    class="flex-1 flex items-center gap-2 mt-3"
                ></bslk-classic-toggle>
                <bslk-classic-toggle
                    *ngIf="!group.controls.isRange.value"
                    [label]="
                        'bslkbusiness.metric-configurator.is-more-than'
                            | transloco
                    "
                    [color]="'warn'"
                    [size]="'small'"
                    [choices]="[
                        { text: 'shared.common.yes' | transloco, value: true },
                        { text: 'shared.common.no' | transloco, value: false }
                    ]"
                    [control]="group.controls.isMoreThan"
                    class="flex-1 flex items-center gap-2 mt-3"
                ></bslk-classic-toggle>
            </div>

            <div class="w-full flex flex-col">
                <mat-form-field [subscriptSizing]="'dynamic'">
                    <mat-label>{{
                        "bslkbusiness.metric-configurator.description"
                            | transloco
                    }}</mat-label>
                    <textarea
                        matInput
                        [formControlName]="'description'"
                    ></textarea>
                    <mat-error
                        *ngIf="group.controls.description.hasError('required')"
                    >
                        {{ "bslk.form.required" | transloco }}
                    </mat-error>
                </mat-form-field>
                <bslk-length-info
                    [text]="group.controls.description.value"
                    [maxLength]="150"
                ></bslk-length-info>
            </div>
        </form>

        <div class="px-8 flex flex-col gap-4 border-l" *ngIf="showPreview">
            <span class="text-2xl font-bold">{{
                "bslkbusiness.metric-configurator.preview" | transloco
            }}</span>
            <bslk-business-metric
                class="min-w-72"
                [showAnimation]="false"
                [metric]="groupValue"
            ></bslk-business-metric>
        </div>
    </div>
</div>
