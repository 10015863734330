import { createReducer, on } from '@ngrx/store';
import { DemonstrationActions } from './demonstration.action';
import { DemonstrationState } from './demonstration.state';

export const demonstrationInitialState: DemonstrationState = {
    isLoading: false,
    lastExecutedStep: 0,
};

export const demonstrationReducer = createReducer(
    demonstrationInitialState,

    on(DemonstrationActions.loadStepsRequest, (state) => {
        return {
            ...state,
            isLoading: true,
            error: undefined,
        };
    }),
    on(DemonstrationActions.loadStepsRequestSuccess, (state, { steps }) => {
        return {
            ...state,
            isLoading: false,
            steps,
        };
    }),
    on(DemonstrationActions.loadStepsRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            error,
        };
    }),

    on(DemonstrationActions.executeStepRequest, (state) => {
        return {
            ...state,
            isLoading: true,
            error: undefined,
        };
    }),
    on(DemonstrationActions.executeStepRequestSuccess, (state, { step }) => {
        return {
            ...state,
            isLoading: false,
            lastExecutedStep: step,
        };
    }),
    on(DemonstrationActions.executeStepRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoading: false,
            error,
        };
    })
);
