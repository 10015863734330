import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EMissionSortColumn, ESortDirection } from 'app/api';
import { getUserFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { MissionListActions } from 'app/store/mission/mission-list/mission-list.actions';
import { MissionListSelectors } from 'app/store/mission/mission-list/mission-list.selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MissionAssociationCardComponent } from './mission-card/mission-association-card.component';

type MissionSortOption = {
    translationKey: string;
    sortColumn: EMissionSortColumn;
};

@Component({
    selector: 'mission-list',
    standalone: true,
    styleUrls: ['./mission-list.component.scss'],
    templateUrl: './mission-list.component.html',
    imports: [
        AsyncPipe,
        FormsModule,
        LetDirective,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatPaginatorModule,
        MatSelectModule,
        MissionAssociationCardComponent,
        NgClass,
        NgFor,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class MissionListComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    translationsLoaded: boolean = false;
    translations: any;

    missions$ = this.store.select(MissionListSelectors.selectData);
    isLoading$ = this.store.select(MissionListSelectors.selectIsLoading);
    pageIndex$ = this.store.select(MissionListSelectors.selectPageIndex);
    totalPages$ = this.store.select(MissionListSelectors.selectTotalPages);
    totalCount$ = this.store.select(MissionListSelectors.selectTotalCount);
    pageSize$ = this.store.select(MissionListSelectors.selectPageSize);

    missionSortOptions: MissionSortOption[] = [
        {
            translationKey: 'updated-date',
            sortColumn: EMissionSortColumn.DateUpdated,
        },
        {
            translationKey: 'start-date',
            sortColumn: EMissionSortColumn.StartDate,
        },
        {
            translationKey: 'name',
            sortColumn: EMissionSortColumn.Name,
        },
    ];
    sortBy: MissionSortOption = this.missionSortOptions[0];
    sortDirection: ESortDirection = ESortDirection.Desc;

    constructor(
        private store: Store,
        private translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        this.translocoService
            .selectTranslateObject('sorts', {}, 'associationmissions')
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((translations) => {
                this.translations = translations;
                this.translationsLoaded = true;
            });

        this.store.dispatch(
            MissionListActions.FilterListRequest({
                filters: [
                    {
                        key: 'associationId',
                        value: getUserFromLocalStorage().association.id,
                    },
                ],
            })
        );
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            MissionListActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    sortDirectionChanged(sortDirection: ESortDirection) {
        this.sortDirection = sortDirection;
        this.sortChanged();
    }

    sortColumnChanged(sortBy: MissionSortOption) {
        this.sortBy = sortBy;
        this.sortChanged();
    }

    sortChanged() {
        this.store.dispatch(
            MissionListActions.FilterListRequest({
                filters: [
                    { key: 'sortColumn', value: this.sortBy.sortColumn },
                    { key: 'sortDirection', value: this.sortDirection },
                ],
            })
        );
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
