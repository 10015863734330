import { createReducer, on } from '@ngrx/store';
import { FeedbackActions } from './feedback.action';
import { FeedbackState } from './feedback.state';

export const feedbackInitialState: FeedbackState = {
    isLoading: false,
    isFeedbackSent: false,
};

export const feedbackReducer = createReducer(
    feedbackInitialState,
    on(FeedbackActions.resetFeedbackState, () => feedbackInitialState),

    on(FeedbackActions.sendFeedbackRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(FeedbackActions.sendFeedbackRequestSuccess, (state) => {
        return {
            ...state,
            isLoading: false,
            isFeedbackSent: true,
        };
    }),

    on(FeedbackActions.sendFeedbackRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
            isFeedbackSent: false,
        };
    })
);
