import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EAwarenessThematic } from 'app/api';
import { sustainableDevelopmentGoals } from 'app/shared/utils/constants.utils';
import { AwarenessCampaignActions } from 'app/store/awareness/awareness-campaign/awareness-campaign.action';
import { AwarenessCampaignSelectors } from 'app/store/awareness/awareness-campaign/awareness-campaign.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'awareness-campaign-thematic-details',
    templateUrl: './thematic-details.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkClassicButtonComponent,
        BslkTagComponent,
        FuseAlertComponent,
        FuseDrawerComponent,
        LetDirective,
        MatIconModule,
        NgFor,
        NgIf,
        NgxSkeletonLoaderModule,
        TranslocoModule,
    ],
})
export class AwarenessCampaignThematicDetailsComponent implements OnChanges {
    @ViewChild('detailsDrawer') drawer: FuseDrawerComponent;

    @Input() thematic: EAwarenessThematic;
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    sustainableDevlopmentGoals = sustainableDevelopmentGoals;

    details$ = this.store.select(
        AwarenessCampaignSelectors.selectThemathicDetails
    );
    isLoading$ = this.store.select(
        AwarenessCampaignSelectors.selectIsLoadingThematicDetails
    );

    constructor(private store: Store) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.thematic && changes.thematic.currentValue) {
            this.store.dispatch(
                AwarenessCampaignActions.loadThematicDetailsRequest({
                    thematic: this.thematic,
                })
            );
            this.openDrawer();
        }
    }

    openDrawer() {
        if (!this.drawer) {
            return;
        }
        this.drawer.open();
    }

    openedChanged(isOpened: boolean) {
        if (!isOpened) {
            this.thematic = null;
            this.close.emit();
        }
    }
}
