<ng-container *ngIf="!(isLoading$ | async); else loading">
    <div
        *ngrxLet="isAnswering$ as isAnswering"
        class="w-full p-4 md:p-8 flex flex-col gap-4 md:gap-0 justify-center md:justify-between items-center relative"
    >
        <ng-container *ngrxLet="currentQuestionIndex$ as currentQuestionIndex">
            <bslk-tag [background]="'warn'">
                {{ "dualquiz.question" | transloco }}
                {{ (currentQuestionIndex ?? 0) + 1 }}
            </bslk-tag>
        </ng-container>

        <div
            *ngrxLet="showQuestionDetails$ as showQuestionDetails"
            class="flex flex-col lg:flex-row gap-0 md:gap-8"
        >
            <dual-quiz-challenge-question-details
                [ngClass]="{
                    'flex-grow-0 flex-shrink-0 md:w-140 mb-10 md:mb-0':
                        showQuestionDetails,
                    'flex-grow flex-shrink w-0 overflow-hidden':
                        !showQuestionDetails
                }"
                class="duration-500 transition-all"
            ></dual-quiz-challenge-question-details>

            <div
                class="relative z-10 duration-500 transition-all h-min flex-grow"
            >
                <div
                    *ngrxLet="hasAnsweredOnce$ as hasAnsweredOnce"
                    class="bg-white rounded-3xl shadow-xl relative z-20 border px-8 md:px-18 py-10 min-w-full md:min-w-120 max-w-140"
                >
                    <dual-quiz-challenge-question
                        *ngIf="isAnswering || hasAnsweredOnce"
                    ></dual-quiz-challenge-question>
                    <dual-quiz-challenge-pre-question
                        *ngIf="!isAnswering && !hasAnsweredOnce"
                    ></dual-quiz-challenge-pre-question>
                </div>
                <div
                    class="rotate-2 bg-white rounded-3xl p-8 shadow-xl z-0 absolute top-0 left-0 bottom-0 right-0 border"
                ></div>
                <div
                    class="rotate-3 bg-white rounded-3xl p-8 shadow-xl -z-1 absolute top-0 left-0 bottom-0 right-0 border"
                ></div>
            </div>
        </div>

        <div class="h-12 hidden md:block w-full md:w-140">
            <dual-quiz-challenge-bar-timer
                *ngIf="isTimerRunning$ | async"
            ></dual-quiz-challenge-bar-timer>
        </div>
    </div>
</ng-container>

<ng-template #loading>
    <div class="w-full p-8 m-8 flex flex-col justify-between items-center">
        <div class="w-32 h-[2.5em] overflow-hidden shadow-lg rounded-lg">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full"
            ></ngx-skeleton-loader>
        </div>

        <div class="w-full md:w-120 h-72 overflow-hidden shadow-lg rounded-3xl">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full"
            ></ngx-skeleton-loader>
        </div>

        <div></div>
    </div>
</ng-template>
