import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import {
    ConfirmationDialogComponent,
    ConfirmationDialogInput,
} from 'app/shared/components/dialog/confirmation/confirmation-dialog.component';
import { MissionManageComponent } from 'app/shared/components/mission/manage/mission-manage.component';
import { MissionManageActions } from 'app/store/mission/mission-manage/mission-manage.action';
import { MissionManageSelectors } from 'app/store/mission/mission-manage/mission-manage.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';
import { MissionPostRatingComponent } from './comment/mission-post-rating.component';
import { MissionResponsibleUserComponent } from './responsible-user/mission-responsible-user.component';
import { MissionManageStateComponent } from './state/mission-manage-state.component';

@Component({
    selector: 'mission-manage-regular',
    standalone: true,
    templateUrl: './mission-manage-regular.component.html',
    imports: [
        AsyncPipe,
        BslkClassicCardComponent,
        LetDirective,
        MatButtonModule,
        MatIconModule,
        MissionManageComponent,
        MissionPostRatingComponent,
        MissionManageStateComponent,
        MissionResponsibleUserComponent,
        NgIf,
        NgxSkeletonLoaderModule,
        TranslocoModule,
    ],
})
export class MissionManageRegularComponent implements OnDestroy {
    private unsubscribeAll: Subject<any> = new Subject<any>();

    mission$ = this.store.select(MissionManageSelectors.selectMission);

    isLoading$ = this.store.select(MissionManageSelectors.selectIsLoading);

    canCancelParticipation$ = this.store.select(
        MissionManageSelectors.selectCanCancelParticipation
    );

    constructor(private store: Store, private dialog: MatDialog) {}

    cancel(isTeamBuilding: boolean) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                textKey: isTeamBuilding
                    ? 'missionmanage.cancel-team-building-confirmation'
                    : 'missionmanage.cancel-participation-confirmation',
            } as ConfirmationDialogInput,
        });

        dialogRef.componentInstance.onConfirm
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.store.dispatch(
                    MissionManageActions.unregisterMissionSlotRequest()
                );
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
