import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    AsyncPipe,
    DatePipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ETrackedActivityType, UserNotificationViewModel } from 'app/api';
import { ActivityService } from 'app/shared/services/activity/activity.service';
import { NotificationActions } from 'app/store/notification/notification.actions';
import { NotificationSelectors } from 'app/store/notification/notification.selectors';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    standalone: true,
    imports: [
        AsyncPipe,
        DatePipe,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        NgClass,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        RouterLink,
        TranslocoModule,
    ],
})
export class NotificationsComponent implements OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel')
    private _notificationsPanel: TemplateRef<any>;

    userNotifications$ = this.store.select(
        NotificationSelectors.selectNotificationsRich
    );
    unreadCount$: Observable<number> = this.store.select(
        NotificationSelectors.selectUnreadNotificationCount
    );
    hasNotification$: Observable<boolean> = this.store.select(
        NotificationSelectors.selectHasNotification
    );

    private overlayRef: OverlayRef;
    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private store: Store,
        private activityService: ActivityService
    ) {}

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();

        // Dispose the overlay
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this.overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this.overlayRef.attach(
            new TemplatePortal(this._notificationsPanel, this.viewContainerRef)
        );

        this.activityService.sendActivityRequest({
            trackedActivityType: ETrackedActivityType.OpenNotifications,
        });
    }

    closePanel(): void {
        this.store.dispatch(NotificationActions.markAllAsRead());
        this.overlayRef.detach();
    }

    delete(userNotification: UserNotificationViewModel): void {
        // Delete the notification
        this.store.dispatch(
            NotificationActions.deleteNotification({ id: userNotification.id })
        );
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(
                    this._notificationsOrigin._elementRef.nativeElement
                )
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this.overlayRef.backdropClick().subscribe(() => {
            this.closePanel();
        });
    }
}
