import {
    AsyncPipe,
    DatePipe,
    KeyValuePipe,
    NgClass,
    NgFor,
    NgIf,
} from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicProgressBar } from '@bslk/components/progress-bar/classic-progress-bar/classic-progress-bar.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AwarenessUserCampaignProgressionViewModel } from 'app/api';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { EnumTagListComponent } from 'app/shared/components/enum-tag-list/enum-tag-list.component';
import { MissionStateComponent } from 'app/shared/components/mission/state/mission-state.component';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { getUserLevelInformation } from 'app/shared/utils/experience-points.utils';
import { CompanyAdminDashboardActions } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.actions';
import { CompanyAdminDashboardSelectors } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.selectors';
import {
    CompanyAdminKpi,
    CompanyAdminKpiExtendedEvent,
} from 'app/store/company/company-admin-dashboard/company-admin-dashboard.state';
import { isEmpty } from 'lodash-es';
import { tap } from 'rxjs';
import { BaseKpiPanelComponent } from '../base-kpi-panel/base-kpi-panel.component';

@Component({
    selector: 'awareness-progression',
    templateUrl: './awareness-progression.component.html',
    standalone: true,
    imports: [
        BaseKpiPanelComponent,
        BslkClassicProgressBar,
        TranslocoModule,
        LetDirective,
        AsyncPipe,
        NgClass,
        NgFor,
        NgIf,
        KeyValuePipe,
        ClassicAvatarComponent,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        FormsModule,
        EnumTagListComponent,
        MissionStateComponent,
        MatIconModule,
        MatPaginatorModule,
    ],
})
export class AwarenessProgressionComponent implements OnInit {
    @Output() expands: EventEmitter<CompanyAdminKpiExtendedEvent> =
        new EventEmitter<CompanyAdminKpiExtendedEvent>();

    kpiName: CompanyAdminKpi = 'awareness-progression';
    search: string;

    constructor(
        private store: Store,
        private cdRef: ChangeDetectorRef,
        private datePipe: DatePipe
    ) {}

    isLoading$ = this.store
        .select(CompanyAdminDashboardSelectors.selectIsLoading(this.kpiName))
        .pipe(
            tap(() => {
                this.cdRef.detectChanges();
            })
        );

    allUserProgressions$ = this.store.select(
        CompanyAdminDashboardSelectors.selectKpiResult(this.kpiName)
    );

    isExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpanded(this.kpiName)
    );

    ngOnInit() {
        this.store.dispatch(
            CompanyAdminDashboardActions.checkIfKpiIsLoaded({
                kpi: this.kpiName,
            })
        );
    }

    formatDateRange(startDate: string, endDate: string): string {
        return formatDateRange(this.datePipe, startDate, endDate);
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            CompanyAdminDashboardActions.updateKpiFilters({
                kpi: this.kpiName,
                filters: {
                    pageIndex: event.pageIndex,
                },
            })
        );
    }

    getThematics(data: AwarenessUserCampaignProgressionViewModel[]) {
        return Object.keys(data[0].thematicPercentages);
    }

    getLevel(experiencePoints: number) {
        return getUserLevelInformation(experiencePoints).currentLevel;
    }

    clearSearch() {
        this.search = '';
        this.store.dispatch(
            CompanyAdminDashboardActions.updateKpiFilters({
                kpi: this.kpiName,
                filters: {
                    pageIndex: 0,
                    nameContains: null,
                },
            })
        );
    }

    searchEmployee() {
        if (isEmpty(this.search)) {
            this.clearSearch();
            return;
        }
        this.store.dispatch(
            CompanyAdminDashboardActions.updateKpiFilters({
                kpi: this.kpiName,
                filters: {
                    pageIndex: 0,
                    nameContains: this.search,
                },
            })
        );
    }
}
