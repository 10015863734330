import {
    animate,
    animation,
    style,
    transition,
    trigger,
    useAnimation,
} from '@angular/animations';
import { NgClass, NgIf } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ERegularUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { AwarenessCampaignActions } from 'app/store/awareness/awareness-campaign/awareness-campaign.action';
import { sample } from 'lodash-es';

export type CourseChapterState = 'done' | 'current' | 'coming';

const bounceAnimation = animation([
    style({ transform: 'translateY(-25%)' }),
    animate(
        '0.5s cubic-bezier(0.8, 0, 1, 1)',
        style({ transform: 'translateY(0)' })
    ), // 50%
    animate('0.5s cubic-bezier(0, 0, 0.2, 1)'),
]);

@Component({
    selector: 'course-dashboard-token',
    templateUrl: './token.component.html',
    styleUrls: ['./token.component.scss'],
    standalone: true,
    imports: [MatIconModule, NgClass, NgIf, TranslocoModule],
    animations: [
        trigger('bounce', [
            transition(
                '* => bouncing',
                useAnimation(bounceAnimation, {
                    params: { timing: '1s', delay: '0s' },
                })
            ),
        ]),
    ],
})
export class CourseDashboardTokenComponent implements OnInit, AfterViewInit {
    @Input() position: 1 | 2 | 3 | 4 | 5 = 3;
    @Input() state: CourseChapterState;
    @Input() progressionPercentage: number = 0;
    @Input() chapterId: string;
    @Input() title: string;
    @Input() isFirstChapter = false;
    @Input() isReward = false;

    radius: number = 40;
    circumference: number = 2 * Math.PI * this.radius;
    dashoffset: number = this.circumference;
    displayTitle = false;

    basilePosture = sample([
        'enthusiast',
        'flexing',
        'happy',
        'hello',
        'jumping',
        'reading',
        'peeking-left',
        'peeking-right',
        'speaking',
        'writing',
    ]);

    constructor(
        private el: ElementRef,
        private router: Router,
        private store: Store
    ) {}

    ngOnInit(): void {
        this.updateProgress();
    }

    ngAfterViewInit(): void {
        if (this.state === 'current') {
            setTimeout(() => {
                this.el.nativeElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }, 200);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['percentage']) {
            this.updateProgress();
        }
    }

    updateProgress(): void {
        this.dashoffset =
            this.circumference * ((100 - this.progressionPercentage) / 100);
    }

    goToChapter(): void {
        if (this.state !== 'coming') {
            this.router.navigateByUrl(
                UrlHelpers.getRegularUrl(ERegularUrl.RegularCourseChapterPage, {
                    id: this.chapterId,
                })
            );
        }
    }

    claimReward() {
        if (this.state === 'current' && this.isReward) {
            this.store.dispatch(
                AwarenessCampaignActions.claimRewardRequest({
                    chapterId: this.chapterId,
                })
            );
        }
    }

    onMouseOver() {
        this.displayTitle = true;
    }
    onMouseOut() {
        this.displayTitle = false;
    }
}
