import { NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MissionRegisteredViewModel } from "app/api";
import { MissionGridCardComponent } from "app/shared/components/mission/grid/mission-grid-card.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

@Component({
    selector     : 'mission-grid-view',
    standalone   : true,
    templateUrl  : './mission-grid-view.component.html',
    imports : [
        NgFor,
        NgIf,
        MissionGridCardComponent,
        NgxSkeletonLoaderModule,
        NgTemplateOutlet
    ],
})
export class MissionGridViewComponent
{
    @Input() missions: MissionRegisteredViewModel[];
    @Input() isLoading: boolean = false;
}