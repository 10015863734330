import { Component, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { SetupActions } from 'app/store/setup/setup.action';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CompanyAdministrativeContactComponent } from './administrative-contact/administrative-contact.component';
import { CompanyEmployeesComponent } from './company-employees/company-employees.component';

@Component({
    templateUrl: './setup.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        NgxSkeletonLoaderModule,
        CompanyEmployeesComponent,
        CompanyAdministrativeContactComponent,
    ],
})
export class CompanyAdminSetupComponent implements OnInit {
    constructor(private store: Store) {}

    ngOnInit() {
        this.store.dispatch(SetupActions.loadCompanyRequest());
    }
}
