import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkIconButtonComponent } from '@bslk/components/button/icon/icon-button.component';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import {
    EActivityField,
    ETrackedActivityType,
    ProjectViewModel,
} from 'app/api';
import { TrackedActivityActions } from 'app/store/tracked-activity/tracked-activity.actions';
import { ProjectDrawerDetails } from './details/details.component';

@Component({
    selector: 'project-card',
    templateUrl: './card.component.html',
    standalone: true,
    imports: [
        BslkClassicButtonComponent,
        BslkIconButtonComponent,
        FuseCardComponent,
        TranslocoModule,
        MatTooltipModule,
        NgIf,
        NgClass,
        BslkTagComponent,
        BslkTagButtonComponent,
        ProjectDrawerDetails,
        NgFor,
    ],
})
export class ProjectCardComponent {
    EActivityField = EActivityField;

    @Input() project: ProjectViewModel;
    @Input() canSelect = false;
    @Input() canChoose = false;
    @Input() isSelected = false;

    @Output() projectSelected = new EventEmitter<number>();
    @Output() projectUnselected = new EventEmitter<number>();
    @Output() projectChosen = new EventEmitter<number>();

    viewedProjectId: number;

    constructor(private store: Store) {}

    viewProject(projectId: number) {
        this.viewedProjectId = projectId;
        this.store.dispatch(
            TrackedActivityActions.logActivityRequest({
                activity: {
                    trackedActivityType:
                        ETrackedActivityType.DonationGiftProjectView,
                    details: projectId.toString(),
                },
            })
        );
    }

    selectProject(event: MouseEvent) {
        event.stopPropagation();
        this.projectSelected.emit(this.project.id);
    }

    chooseProject(event: MouseEvent) {
        event.stopPropagation();
        this.projectChosen.emit(this.project.id);
    }

    unselectProject(event: MouseEvent) {
        event.stopPropagation();
        this.projectUnselected.emit(this.project.id);
    }

    onProjectChosen() {
        this.projectChosen.emit(this.project.id);
    }
}
