import { DatePipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { BslkMenuComponent } from '@bslk/components/menu/menu.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';

import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { EActivityField, ProjectViewModel } from 'app/api';
import { ProjectDonationImpactComponent } from 'app/modules/regular/project/view/donation-impact/donation-impact.component';
import { EnumTagListComponent } from 'app/shared/components/enum-tag-list/enum-tag-list.component';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { MediaLibraryDialogService } from 'app/shared/components/media-library/media-library-dialog.service';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { ProjectListActions } from 'app/store/project/project-list/project-list.actions';
import { ProjectListSelectors } from 'app/store/project/project-list/project-list.selectors';

@Component({
    selector: 'project-association-card',
    standalone: true,
    templateUrl: './project-association-card.component.html',
    imports: [
        BslkMenuComponent,
        BslkTagComponent,
        DatePipe,
        FuseCardComponent,
        EnumTagListComponent,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        RouterLink,
        TranslocoModule,
        ProjectDonationImpactComponent,
        BslkTagComponent,
    ],
})
export class ProjectAssociationCardComponent {
    @Input() project: ProjectViewModel;

    EActivityField = EActivityField;

    constructor(
        private datePipe: DatePipe,
        private store: Store,
        private fileUploadDialogService: FileUploadDialogService,
        private mediaLibaryDialogService: MediaLibraryDialogService
    ) {}

    get lastUpdate() {
        return this.project.dateUpdated ?? this.project.dateCreated;
    }

    formatDateRange(startDate: string, endDate: string): string {
        return formatDateRange(this.datePipe, startDate, endDate);
    }

    modifyProject() {
        this.store.dispatch(
            ProjectListActions.SelectItem({ id: this.project.id })
        );
    }

    archiveProject() {
        this.store.dispatch(
            ProjectListActions.DeleteItem({ id: this.project.id })
        );
    }

    publishProject() {
        this.store.dispatch(
            ProjectListActions.PublishRequest({ id: this.project.id })
        );
    }

    openUploadCoverDialog(id: number) {
        this.mediaLibaryDialogService.open({
            maximumImages: 4,
            removeImageAction: ProjectListActions.RemoveImage,
            images: this.store.select(
                ProjectListSelectors.selectProjectImages(id)
            ),
            imageUploadDialogInput: {
                id,
                action: ProjectListActions.AddImage,
                isLoading$: this.store.select(
                    ProjectListSelectors.selectIsLoadingImages
                ),
                maxFileSize: 2000 * 1024,
                infoTextKey: 'createproject.info-image',
            },
        });
    }

    onMenuAction(event: string) {
        if (event === 'modify') {
            return this.modifyProject();
        }
        if (event === 'archive') {
            return this.archiveProject();
        }
        if (event === 'publish') {
            return this.publishProject();
        }
    }
}
