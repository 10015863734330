<div class="flex">
    <button
        (click)="answer(true)"
        class="bg-quaternary shadow rounded-xl p-2 items-center flex mr-3"
    >
        <mat-icon
            class="icon-size-5 text-white"
            [svgIcon]="'heroicons_solid:check'"
        ></mat-icon>
    </button>
    <button
        (click)="answer(false)"
        class="bg-primary shadow rounded-xl p-2 items-center flex"
    >
        <mat-icon
            class="icon-size-5 text-white"
            [svgIcon]="'heroicons_solid:x-mark'"
        ></mat-icon>
    </button>
</div>
