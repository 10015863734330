import { AsyncPipe, NgIf } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LocationViewModel } from 'app/api';
import { getLocationRepresentation } from 'app/shared/utils/location-helpers.utils';
import { isNil } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { GoogleMapsApiService } from '../../services/api/google-maps-api.service';
@Component({
    selector: 'location-selection',
    templateUrl: './location-selection.component.html',
    styleUrls: ['./location-selection.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        FuseAlertComponent,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatInputModule,
        MatProgressBarModule,
        TranslocoModule,
        AsyncPipe,
        NgIf,
    ],
})
export class LocationSelectionComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @Input() formGroup: FormGroup;
    @Input() hint?: string;
    @Input() hideLabel = false;
    @Input() locationType: 'address' | 'region' = 'address';

    @Output() locationSelected = new EventEmitter<LocationViewModel>();

    @ViewChild('addresstext') addresstext: any;
    @ViewChild('mapRef') mapRef: ElementRef;

    private unsubscribeAll: Subject<any> = new Subject<any>();

    queryWait: boolean;
    libraryLoaded = false;
    selectedPlaceName: string;

    inputFormControl = new FormControl('');

    constructor(
        private googleMapsApiService: GoogleMapsApiService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        // Sometimes the location will be updated outside this component, so the component needs to stay alert of any change
        if (this.formGroup) {
            this.formGroup.valueChanges
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe((locationViewModel) => {
                    this.inputFormControl.setValue(
                        getLocationRepresentation(locationViewModel)
                    );
                });
            if (
                this.formGroup.valid &&
                !isNil(this.formGroup.controls.id.value)
            ) {
                this.inputFormControl.setValue(
                    getLocationRepresentation(this.formGroup.value)
                );
            }
        }
    }

    ngAfterViewInit(): void {
        this.googleMapsApiService
            .loadLibrary({ places: true })
            .then((libraries) => {
                this.getPlaceAutocomplete(libraries);
            });
    }

    inputChange() {
        // Reset form validity if user changes input without selecting a valid place after he had selected a valid one
        if (this.formGroup) {
            const id = this.formGroup.controls.id.value;
            this.formGroup.reset();
            this.formGroup.controls.id.setValue(id);
            this.formGroup.controls.longitude.setValue(0);
            this.formGroup.controls.latitude.setValue(0);
            this.cdRef.markForCheck();
        }
    }

    private getPlaceAutocomplete(libraries: any) {
        const autocomplete = new libraries.places.Autocomplete(
            this.addresstext.nativeElement,
            {
                fields: ['address_components', 'geometry', 'name'],
                types: [
                    this.locationType === 'address' ? 'address' : '(regions)',
                ],
            }
        );
        autocomplete.addListener('place_changed', () => {
            const place =
                autocomplete.getPlace() as google.maps.places.PlaceResult;
            const locationViewModel =
                this.googleMapsApiService.transformToLocationViewModel(place);
            if (this.formGroup) {
                this.formGroup.patchValue(locationViewModel);
            } else {
                this.inputFormControl.reset();
            }
            this.locationSelected.emit(locationViewModel);
            this.selectedPlaceName = place.name;
            this.cdRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
