/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EDonationCampaignState = 'WaitingForPayment' | 'Active' | 'Expired' | 'Completed';

export const EDonationCampaignState = {
    WaitingForPayment: 'WaitingForPayment' as EDonationCampaignState,
    Active: 'Active' as EDonationCampaignState,
    Expired: 'Expired' as EDonationCampaignState,
    Completed: 'Completed' as EDonationCampaignState
};

