import { createSelector } from '@ngrx/store';
import { AppState } from '../..';

const selectState = (state: AppState) => state.missionRating;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

export const MissionRatingSelectors = {
    selectIsLoading,
};
