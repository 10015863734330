import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkIconButtonComponent } from '@bslk/components/button/icon/icon-button.component';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { CompanyCsrApproachViewModel } from 'app/api';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { CompanyCsrApproachActions } from 'app/store/company/csr-approach/csr-approach.actions';
import { Subject, takeUntil } from 'rxjs';
import { CompanyCsrApproachCardComponent } from '../card/card.component';
import { CompanyCsrApproachEditValueComponent } from './edit/edit.component';

@Component({
    selector: 'company-csr-approach-values',
    templateUrl: './values.component.html',
    standalone: true,
    imports: [
        CompanyCsrApproachCardComponent,
        TranslocoModule,
        MatTooltipModule,
        NgFor,
        NgIf,
        NgClass,
        MatIconModule,
        MatButtonModule,
        CompanyCsrApproachEditValueComponent,
        BslkIconButtonComponent,
    ],
})
export class CompanyCsrApproachValuesComponent
    implements OnInit, OnDestroy, OnChanges
{
    @Input() isLoading: boolean;
    @Input() isCompanyAdmin = false;
    @Input() csrApproach: CompanyCsrApproachViewModel;

    unsubscribeAll: Subject<any> = new Subject<any>();

    isCreating = false;
    isEditing = false;
    editedValueIndex: number;

    valuesPage = 0;
    valuesPerPage = 3;

    constructor(
        private store: Store,
        private formService: CompanyCsrApproachFormService,
        private mediaWatcherService: FuseMediaWatcherService
    ) {}

    get formGroup() {
        return this.formService.getFormGroup;
    }

    get hasNextPage() {
        return (
            !this.isEditing &&
            this.csrApproach?.values &&
            this.csrApproach.values.length >
                this.valuesPerPage * (this.valuesPage + 1)
        );
    }

    get hasPreviousPage() {
        return !this.isEditing && this.valuesPage > 0;
    }

    get displayedValues() {
        return this.isEditing
            ? this.csrApproach?.values
            : this.csrApproach?.values.slice(
                  this.valuesPage * this.valuesPerPage,
                  (this.valuesPage + 1) * this.valuesPerPage
              );
    }

    ngOnInit(): void {
        this.mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.valuesPage = 0;
                this.valuesPerPage =
                    this.mediaWatcherService.responsiveMapValue(
                        matchingAliases,
                        {
                            sm: 1,
                            md: 2,
                            xl: 3,
                        }
                    );
            });
    }

    ngOnChanges(): void {
        this.isEditing =
            this.isEditing ||
            (this.isCompanyAdmin && this.csrApproach?.values.length === 0);
    }

    addValue() {
        this.isCreating = true;
    }

    drawerClosed() {
        this.isCreating = false;
        this.editedValueIndex = null;
    }

    onEdit(isEditing: boolean) {
        this.isEditing = isEditing;
        this.valuesPage = 0;
    }

    onHiding(isHiding: boolean) {
        this.formGroup.controls.hideValues.setValue(isHiding);
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
    }

    editValue(index: number) {
        this.editedValueIndex = index;
    }

    removeValue(index: number) {
        const formArray = this.formGroup.controls.values as FormArray;
        formArray.removeAt(index);
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
    }

    nextPage() {
        this.valuesPage += 1;
    }

    previousPage() {
        this.valuesPage -= 1;
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
