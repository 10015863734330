import { AsyncPipe, NgIf } from '@angular/common';
import {
    AfterViewInit,
    Component,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { NavigationSelectors } from 'app/store/navigation/navigation.selectors';
import { Observable } from 'rxjs';
import { EmptyLayoutComponent } from './layouts/empty/empty.component';
import { AdminLayoutComponent } from './layouts/vertical/admin/admin.component';
import { AssociationAdminLayoutComponent } from './layouts/vertical/association-admin/association-admin-layout.component';
import { CompanyAdminLayoutComponent } from './layouts/vertical/company-admin/company-admin-layout.component';
import { ExternalLayoutComponent } from './layouts/vertical/external/external-layout.component';
import { RegularLayoutComponent } from './layouts/vertical/regular/regular-layout.component';

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        EmptyLayoutComponent,
        RegularLayoutComponent,
        CompanyAdminLayoutComponent,
        AssociationAdminLayoutComponent,
        ExternalLayoutComponent,
        AdminLayoutComponent,
        AsyncPipe,
        LetDirective,
    ],
})
export class LayoutComponent implements OnInit, AfterViewInit {
    layout$: Observable<string> = this._store.select(
        NavigationSelectors.selectLayout
    );
    forceEmptyLayout: boolean;
    forceSplitLayout: boolean;

    forcedLayout: boolean;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _store: Store
    ) {}

    ngOnInit(): void {
        this._activatedRoute.data.subscribe((data: Data) => {
            this.forceEmptyLayout = data.layout === 'empty';
            this.forceSplitLayout = data.layout === 'split';
            this.forcedLayout = this.forceEmptyLayout || this.forceSplitLayout;
        });
    }

    //Temporary hack while I understand why the **** the page sometimes auto scrolls on load
    ngAfterViewInit(): void {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1);
    }
}
