import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { BslkAdvancedSelectSyncComponent } from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { BslkCardToggleComponent } from '@bslk/components/form/toggle/card/card-toggle.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { DonationCampaignFormService } from 'app/shared/services/form/donation-campaign-form.service';
import { QUILL_MODULES } from 'app/shared/utils/constants.utils';
import { fromTodayDatesFilter } from 'app/shared/utils/date-helpers.utils';
import { DateTime } from 'luxon';
import { QuillEditorComponent } from 'ngx-quill';
import { Subject } from 'rxjs';

@Component({
    selector: 'create-donation-campaign-step-2',
    standalone: true,
    templateUrl: './step-2.component.html',
    imports: [
        AsyncPipe,
        BslkAdvancedSelectSyncComponent,
        BslkInfoLabelComponent,
        BslkCardToggleComponent,
        LetDirective,
        MatIconModule,
        MatInputModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
        BslkLengthInfoComponent,
        QuillEditorComponent,
        BslkClassicToggleComponent,
    ],
})
export class CreateDonationCampaignStep2Component {
    unsubscribeAll: Subject<any> = new Subject<any>();
    quillModules = QUILL_MODULES;

    constructor(
        private donationCampaignFormService: DonationCampaignFormService
    ) {}

    get formGroup() {
        return (
            this.donationCampaignFormService.getFormGroup ??
            this.donationCampaignFormService.initForm()
        );
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }

    getDatesFilter(date: DateTime) {
        return fromTodayDatesFilter(date);
    }
}
