<div
    class="bg-[url('assets/images/logo/background.svg')] bg-no-repeat bg-cover bg-white shadow-xl rounded-2xl h-48 overflow-hidden flex w-fit m-auto"
>
    <img
        class="object-cover w-28 h-28 md:w-40 md:h-40 m-auto"
        src="assets/images/logo/logo.svg"
    />

    <div
        *ngIf="!campaign.isOneEnvelope"
        class="p-4 flex flex-col justify-between min-w-50 max-w-74 border-l-2 border-white"
    >
        <span class="text-7xl font-bold text-right"
            >{{ campaign.donationAmount }}€</span
        >
        <span class="text-sm font-bold text-center">
            {{ "donationgift.give-to-project-of-choice" | transloco }}</span
        >

        <ng-container *ngTemplateOutlet="signature"></ng-container>
    </div>

    <div
        *ngIf="campaign.isOneEnvelope"
        class="p-4 flex flex-col min-w-50 max-w-72 border-l-2 border-white"
    >
        <div class="flex flex-col gap-2 items-center justify-center grow">
            <span class="text-sm text-center">
                {{ "donationgift.voting-right-for-project" | transloco }}</span
            >
            <span class="font-bold text-4xl"
                >{{ campaign.donationAmount }}€</span
            >
        </div>
        <ng-container *ngTemplateOutlet="signature"></ng-container>
    </div>

    <ng-template #signature>
        <div class="text-right">
            <span class="text-xs">{{ "donationgift.a-gift" | transloco }}</span>
            <span class="font-bold"
                >&nbsp;{{
                    campaign.isClientCampaign
                        ? campaign.clientName
                        : campaign.company.name
                }}</span
            >
        </div>
    </ng-template>
</div>
