import { createReducer, on } from '@ngrx/store';
import { BadgeActions } from './badge.action';
import { BadgeState } from './badge.state';

export const badgeInitialState: BadgeState = {
    isLoading: false,
    badges: [],
};

export const badgeReducer = createReducer(
    badgeInitialState,

    on(BadgeActions.loadBadgesRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),
    on(BadgeActions.loadBadgesRequestSuccess, (state, { badges }) => {
        return {
            ...state,
            isLoading: false,
            badges,
        };
    }),
    on(BadgeActions.loadBadgesRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    })
);
