<div
    class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent"
>
    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b"
    >
        <!-- Loader -->
        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading$ | async">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>
        <!-- Title -->
        <div class="text-4xl font-extrabold tracking-tight">
            {{ "questions.title" | transloco }}
        </div>
        <!-- Actions -->
        <div class="flex gap-3 shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <mat-form-field
                [subscriptSizing]="'dynamic'"
                [floatLabel]="'always'"
                class="fuse-mat-dense fuse-mat-rounded min-w-120"
            >
                <mat-select
                    [placeholder]="'questions.filter-thematic' | transloco"
                    (ngModelChange)="changeThematicFilter($event)"
                    [(ngModel)]="filteredthematic"
                >
                    <mat-option [value]="null">
                        {{ "questions.all-thematics" | transloco }}
                    </mat-option>
                    <mat-option
                        *ngFor="
                            let thematic of getEnumValues(EAwarenessThematic)
                        "
                        [value]="thematic"
                    >
                        {{ "enums.EAwarenessThematic." + thematic | transloco }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Search -->
            <mat-form-field
                class="fuse-mat-dense fuse-mat-rounded min-w-64"
                [subscriptSizing]="'dynamic'"
            >
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:magnifying-glass'"
                ></mat-icon>
                <input
                    matInput
                    [autocomplete]="'off'"
                    (input)="searchQuestion($event)"
                    [placeholder]="'questions.search-question' | transloco"
                />
            </mat-form-field>
            <button
                class="ml-4"
                mat-flat-button
                (click)="startCreation()"
                [color]="'primary'"
            >
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">{{ "questions.add" | transloco }}</span>
            </button>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <!-- Questions list -->
        <div
            *ngrxLet="selectedQuestion$ as selectedQuestion"
            class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto"
        >
            <ng-container *ngrxLet="questions$ as questions">
                <ng-container *ngIf="questions.length > 0; else noQuestions">
                    <div
                        matSort
                        matSortActive="name"
                        matSortDirection="asc"
                        matSortDisableClear
                        class="grid"
                    >
                        <!-- Header -->
                        <div
                            class="questions-grid z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50"
                        >
                            <div class="truncate" mat-sort-header="thematic">
                                {{ "questions.thematic" | transloco }}
                            </div>
                            <div class="truncate" mat-sort-header="question">
                                {{ "questions.question" | transloco }}
                            </div>

                            <div class="truncate">
                                {{ "questions.date-created" | transloco }}
                            </div>
                            <div class="truncate">
                                {{ "questions.date-updated" | transloco }}
                            </div>
                            <div class="truncate">
                                {{ "questions.details" | transloco }}
                            </div>
                        </div>
                        <!-- Rows -->
                        <ng-container
                            *ngFor="
                                let question of questions;
                                trackBy: trackByFn
                            "
                        >
                            <div
                                class="questions-grid grid items-center gap-4 py-3 px-6 md:px-8 border-b"
                            >
                                <div class="truncate">
                                    <span *ngIf="question.thematic">
                                        {{
                                            "enums.EAwarenessThematic." +
                                                question.thematic | transloco
                                        }}</span
                                    >
                                </div>

                                <div class="truncate">
                                    {{ question.question }}
                                </div>

                                <div class="truncate">
                                    {{
                                        question.dateCreated | date : "dd/MM/YY"
                                    }}
                                </div>
                                <div class="truncate">
                                    {{
                                        question.dateUpdated | date : "dd/MM/YY"
                                    }}
                                </div>

                                <!-- Details button -->
                                <div>
                                    <button
                                        *ngrxLet="isCreating$ as isCreating"
                                        class="min-w-10 min-h-7 h-7 px-2 leading-6"
                                        mat-stroked-button
                                        (click)="
                                            toggleDetails(
                                                question.id,
                                                isCreating,
                                                selectedQuestion
                                            )
                                        "
                                    >
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="
                                                selectedQuestion?.id ===
                                                question.id
                                                    ? 'heroicons_solid:chevron-up'
                                                    : 'heroicons_solid:chevron-down'
                                            "
                                        ></mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="grid">
                                <ng-container
                                    *ngIf="selectedQuestion?.id === question.id"
                                >
                                    <ng-container
                                        *ngTemplateOutlet="
                                            rowDetailsTemplate;
                                            context: { $implicit: question }
                                        "
                                    ></ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>

                    <mat-paginator
                        class="sm:absolute sm:inset-x-0 sm:bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
                        [ngClass]="{
                            'pointer-events-none': isLoading$ | async
                        }"
                        [length]="totalCount$ | async"
                        [pageIndex]="pageIndex$ | async"
                        [pageSize]="pageSize$ | async"
                        [hidePageSize]="true"
                        (page)="pageChangeEvent($event)"
                        [showFirstLastButtons]="true"
                    ></mat-paginator>
                </ng-container>
            </ng-container>

            <ng-template #rowDetailsTemplate let-question>
                <div class="shadow-lg overflow-hidden">
                    <div class="flex border-b">
                        <!-- Selected question form -->
                        <form
                            class="flex flex-col gap-2 w-full"
                            [formGroup]="formGroup"
                        >
                            <div class="p-8 flex flex-col">
                                <div class="flex flex-col gap-2 sm:flex-row">
                                    <div class="flex flex-auto flex-wrap">
                                        <!-- Left Column -->
                                        <div class="flex flex-col w-full">
                                            <div class="flex flex-col gap-2">
                                                <mat-form-field class="grow">
                                                    <mat-label>{{
                                                        "questions.thematic"
                                                            | transloco
                                                    }}</mat-label>
                                                    <mat-select
                                                        [formControlName]="
                                                            'thematic'
                                                        "
                                                    >
                                                        <mat-option
                                                            *ngFor="
                                                                let thematic of getEnumValues(
                                                                    EAwarenessThematic
                                                                )
                                                            "
                                                            [value]="thematic"
                                                        >
                                                            {{
                                                                "enums.EAwarenessThematic." +
                                                                    thematic
                                                                    | transloco
                                                            }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field class="grow">
                                                    <mat-label>{{
                                                        "questions.question"
                                                            | transloco
                                                    }}</mat-label>
                                                    <input
                                                        matInput
                                                        [errorStateMatcher]="
                                                            matcher
                                                        "
                                                        [formControlName]="
                                                            'question'
                                                        "
                                                    />
                                                    <mat-error
                                                        *ngIf="
                                                            formGroup.controls.question.hasError(
                                                                'required'
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            "questions.required-field"
                                                                | transloco
                                                        }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="flex flex-col gap-2 w-full">
                                            <div class="flex gap-2">
                                                <mat-form-field class="grow">
                                                    <mat-label>{{
                                                        "questions.answer1"
                                                            | transloco
                                                    }}</mat-label>
                                                    <input
                                                        matInput
                                                        [errorStateMatcher]="
                                                            matcher
                                                        "
                                                        [formControlName]="
                                                            'answer1'
                                                        "
                                                    />
                                                    <mat-error
                                                        *ngIf="
                                                            formGroup.controls.answer1.hasError(
                                                                'required'
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            "questions.required-field"
                                                                | transloco
                                                        }}
                                                    </mat-error>
                                                </mat-form-field>
                                                <mat-form-field class="grow">
                                                    <mat-label>{{
                                                        "questions.answer2"
                                                            | transloco
                                                    }}</mat-label>
                                                    <input
                                                        matInput
                                                        [errorStateMatcher]="
                                                            matcher
                                                        "
                                                        [formControlName]="
                                                            'answer2'
                                                        "
                                                    />
                                                    <mat-error
                                                        *ngIf="
                                                            formGroup.controls.answer2.hasError(
                                                                'required'
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            "questions.required-field"
                                                                | transloco
                                                        }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="flex gap-2">
                                                <mat-form-field class="grow">
                                                    <mat-label>{{
                                                        "questions.answer3"
                                                            | transloco
                                                    }}</mat-label>
                                                    <input
                                                        matInput
                                                        [errorStateMatcher]="
                                                            matcher
                                                        "
                                                        [formControlName]="
                                                            'answer3'
                                                        "
                                                    />
                                                </mat-form-field>
                                                <mat-form-field class="grow">
                                                    <mat-label>{{
                                                        "questions.answer4"
                                                            | transloco
                                                    }}</mat-label>
                                                    <input
                                                        matInput
                                                        [errorStateMatcher]="
                                                            matcher
                                                        "
                                                        [formControlName]="
                                                            'answer4'
                                                        "
                                                    />
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-col gap-2">
                                    <div class="flex flex-col gap-1">
                                        <bslk-classic-toggle
                                            [control]="
                                                formGroup.controls.correctAnswer
                                            "
                                            [label]="
                                                'questions.correct-answer'
                                                    | transloco
                                            "
                                            [isLabelBold]="true"
                                            [disableDefaultValueSet]="true"
                                            [choices]="[{
                                            value: 1,
                                            text: '1'
                                        }, {
                                            value: 2,
                                            text: '2'
                                        }, {
                                            value: 3,
                                            text: '3',
                                        }, {
                                            value: 4,
                                            text: '4'
                                        }]"
                                        >
                                        </bslk-classic-toggle>
                                        <span
                                            *ngIf="
                                                formGroup.controls.correctAnswer
                                                    .invalid && isSubmitted
                                            "
                                            class="text-primary text-sm"
                                            >{{
                                                "questions.correct-answer-required"
                                                    | transloco
                                            }}</span
                                        >
                                    </div>

                                    <mat-form-field class="grow">
                                        <mat-label>{{
                                            "questions.explanation" | transloco
                                        }}</mat-label>
                                        <textarea
                                            matInput
                                            [errorStateMatcher]="matcher"
                                            [formControlName]="'explanation'"
                                        >
                                        </textarea>
                                    </mat-form-field>

                                    <mat-form-field class="grow">
                                        <mat-label>{{
                                            "questions.source" | transloco
                                        }}</mat-label>
                                        <input
                                            matInput
                                            [errorStateMatcher]="matcher"
                                            [formControlName]="'source'"
                                        />
                                    </mat-form-field>
                                </div>
                            </div>

                            <div
                                *ngrxLet="isLoading$ as isLoading"
                                class="flex items-center justify-between w-full border-t px-8 py-4"
                            >
                                <ng-container
                                    *ngrxLet="isCreating$ as isCreating"
                                >
                                    <button
                                        class="-ml-4 text-white"
                                        mat-flat-button
                                        [color]="'warn'"
                                        [disabled]="isLoading"
                                        (click)="
                                            deleteQuestion(
                                                question.id,
                                                isCreating
                                            )
                                        "
                                    >
                                        <div class="flex items-center">
                                            <mat-progress-spinner
                                                class="mr-2"
                                                *ngIf="isLoading"
                                                [diameter]="24"
                                                [mode]="'indeterminate'"
                                            >
                                            </mat-progress-spinner>
                                            <span>{{
                                                "questions.delete" | transloco
                                            }}</span>
                                        </div>
                                    </button>
                                    <button
                                        *ngIf="isCreating"
                                        mat-flat-button
                                        [color]="'primary'"
                                        class="text-white"
                                        [disabled]="
                                            isLoading ||
                                            (formGroup.invalid && isSubmitted)
                                        "
                                        (click)="createQuestion()"
                                    >
                                        <div class="flex items-center">
                                            <mat-progress-spinner
                                                class="mr-2"
                                                *ngIf="isLoading"
                                                [diameter]="24"
                                                [mode]="'indeterminate'"
                                            >
                                            </mat-progress-spinner>
                                            <span class="text-white">{{
                                                "questions.add" | transloco
                                            }}</span>
                                        </div>
                                    </button>

                                    <button
                                        *ngIf="!isCreating"
                                        mat-flat-button
                                        [color]="'primary'"
                                        class="text-white"
                                        [disabled]="
                                            isLoading ||
                                            (formGroup.invalid && isSubmitted)
                                        "
                                        (click)="updateSelectedQuestion()"
                                    >
                                        <div class="flex items-center">
                                            <mat-progress-spinner
                                                class="mr-2"
                                                *ngIf="isLoading"
                                                [diameter]="24"
                                                [mode]="'indeterminate'"
                                            >
                                            </mat-progress-spinner>
                                            <span>{{
                                                "questions.update" | transloco
                                            }}</span>
                                        </div>
                                    </button>
                                </ng-container>
                            </div>
                        </form>
                    </div>
                </div>
            </ng-template>

            <ng-template #noQuestions>
                <div
                    *ngrxLet="isLoading$ as isLoading"
                    class="flex justify-center h-full p-8 sm:p-16 border-t text-4xl font-semibold tracking-tight text-center"
                >
                    <span
                        class="flex flex-col justify-center"
                        *ngIf="isLoading"
                    >
                        <mat-progress-spinner
                            *ngIf="isLoading"
                            [diameter]="60"
                            [mode]="'indeterminate'"
                        >
                        </mat-progress-spinner>
                    </span>
                    <span *ngIf="!isLoading">{{
                        "questions.no-question" | transloco
                    }}</span>
                </div>
            </ng-template>
        </div>
    </div>
</div>
