<div class="relative">
    <course-dashboard-thematic-lock
        [startDate]="startDate"
        class="sticky top-30 z-80"
    ></course-dashboard-thematic-lock>
    <div [class.blur-sm]="!isStarted">
        <div
            *ngFor="
                let course of courses;
                let courseIndex = index;
                let lastCourse = last
            "
        >
            <div class="flex justify-center my-8">
                <div
                    class="bg-warn-gradient shadow-light shadow-warn text-white rounded-2xl py-4 px-6 font-bold mb-3 flex items-center gap-2"
                >
                    <mat-icon
                        [svgIcon]="'bslk_thematics:' + thematic"
                    ></mat-icon>
                    <span>{{ course.name }}</span>
                </div>
            </div>

            <!-- Chapters -->
            <div
                *ngFor="
                    let chapter of course.chapters;
                    let chapterIndex = index
                "
                class="flex flex-col -mt-3"
            >
                <course-dashboard-token
                    [chapterId]="chapter.chapterId"
                    [position]="calculatePosition(chapterIndex, courseIndex)"
                    [state]="getState(chapter, courseIndex, chapterIndex)"
                    [progressionPercentage]="chapter.progressionPercentage"
                    [isFirstChapter]="chapterIndex === 0"
                    [isReward]="chapter.isReward"
                    [title]="chapter.name"
                ></course-dashboard-token>
            </div>
        </div>
    </div>
</div>
