import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { isNil } from 'lodash-es';
import { MissionManageActions } from './mission-manage.action';
import { MissionManageState } from './mission-manage.state';
export const missionManageInitialState: MissionManageState = {
    isLoading: false,
    isLoadingUserValidation: false,
    isLoadingTasks: false,
};

export const missionManageReducer = createReducer(
    missionManageInitialState,

    on(MissionManageActions.loadMissionManageRequest, (state) => {
        return {
            ...state,
            // Only show loading animation when there is no mission to show
            isLoading: isNil(state.mission),
        };
    }),
    on(
        MissionManageActions.loadMissionManageRequestSuccess,
        (state, { mission, missionSlotId }) => {
            // We keep the same selected slot id only if the previous state selected slot id is in the mission
            // Happens when we reload after an user action (ex: mission validation)
            // Else, we take the first slot (or the one from url)
            const selectedSlotId =
                !isNil(state.selectedSlotId) &&
                mission.missionSlots
                    .map((ms) => ms.id)
                    .includes(state.selectedSlotId)
                    ? state.selectedSlotId
                    : missionSlotId ?? mission.missionSlots?.at(0)?.id;
            return {
                ...state,
                mission,
                isLoading: false,
                selectedSlotId,
            };
        }
    ),
    on(
        MissionManageActions.loadMissionManageRequestFail,
        (state, { error }) => {
            return {
                ...state,
                error,
                isLoading: false,
            };
        }
    ),

    on(MissionManageActions.selectMissionSlot, (state, { id }) => {
        return {
            ...state,
            selectedSlotId: id,
        };
    }),

    on(MissionManageActions.registerMissionSlotRequest, (state) => {
        return {
            ...state,
            isLoadingUserValidation: true,
        };
    }),
    on(
        MissionManageActions.registerMissionSlotRequestSuccess,
        (state, { slotId }) =>
            produce(state, (draftState) => {
                draftState.isLoadingUserValidation = false;
                draftState.missionSlotRegisteredId = slotId;
            })
    ),
    on(
        MissionManageActions.registerMissionSlotRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingUserValidation: false,
                error,
            };
        }
    ),

    on(MissionManageActions.proposeMissionSlotRequest, (state) => {
        return {
            ...state,
            isLoadingUserValidation: true,
        };
    }),
    on(
        MissionManageActions.proposeMissionSlotRequestSuccess,
        (state, { slotId }) =>
            produce(state, (draftState) => {
                draftState.isLoadingUserValidation = false;
                draftState.missionSlotRegisteredId = slotId;
            })
    ),
    on(
        MissionManageActions.proposeMissionSlotRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingUserValidation: false,
                error,
            };
        }
    ),

    on(MissionManageActions.unregisterMissionSlotRequest, (state) => {
        return {
            ...state,
            isLoadingUserValidation: true,
        };
    }),
    on(MissionManageActions.unregisterMissionSlotRequestSuccess, (state) =>
        produce(state, (draftState) => {
            draftState.isLoadingUserValidation = false;
            draftState.missionSlotRegisteredId = null;
        })
    ),
    on(
        MissionManageActions.unregisterMissionSlotRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingUserValidation: false,
                error,
            };
        }
    ),

    on(MissionManageActions.acceptUserInSlotRequest, (state) => {
        return {
            ...state,
            isLoadingUserValidation: true,
        };
    }),
    on(MissionManageActions.acceptUserInSlotRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingUserValidation: false,
        };
    }),
    on(MissionManageActions.acceptUserInSlotRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoadingUserValidation: false,
            error,
        };
    }),

    on(MissionManageActions.denyUserInSlotRequest, (state) => {
        return {
            ...state,
            isLoadingUserValidation: true,
        };
    }),
    on(MissionManageActions.denyUserInSlotRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingUserValidation: false,
        };
    }),
    on(MissionManageActions.denyUserInSlotRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoadingUserValidation: false,
            error,
        };
    }),

    on(MissionManageActions.addTeamBuildingUserRequest, (state) => {
        return {
            ...state,
            isLoadingUserValidation: true,
        };
    }),
    on(MissionManageActions.addTeamBuildingUserRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingUserValidation: false,
        };
    }),
    on(
        MissionManageActions.addTeamBuildingUserRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingUserValidation: false,
                error,
            };
        }
    ),

    on(MissionManageActions.removeTeamBuildingUserRequest, (state) => {
        return {
            ...state,
            isLoadingUserValidation: true,
        };
    }),
    on(MissionManageActions.removeTeamBuildingUserRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingUserValidation: false,
        };
    }),
    on(
        MissionManageActions.removeTeamBuildingUserRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingUserValidation: false,
                error,
            };
        }
    ),

    on(MissionManageActions.updateMissionTasksRequest, (state) => {
        return {
            ...state,
            isLoadingTasks: true,
        };
    }),
    on(
        MissionManageActions.updateMissionTasksRequestSuccess,
        (state, { tasks }) =>
            produce(state, (draftState) => {
                draftState.isLoadingTasks = false;
                draftState.mission.tasks = tasks;
            })
    ),
    on(
        MissionManageActions.updateMissionTasksRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingTasks: false,
                error,
            };
        }
    ),

    on(
        MissionManageActions.updateMissionTaskCompletionStatusRequest,
        (state) => {
            return {
                ...state,
                isLoadingTasks: true,
            };
        }
    ),
    on(
        MissionManageActions.updateMissionTaskCompletionStatusRequestSuccess,
        (state, { id, isCompleted }) =>
            produce(state, (draftState) => {
                draftState.isLoadingTasks = false;
                const task = draftState.mission.tasks
                    .flatMap((t) => t.subtasks)
                    .find((t) => t.id === id);
                if (!isNil(task)) {
                    task.isCompleted = isCompleted;
                }
            })
    ),
    on(
        MissionManageActions.updateMissionTaskCompletionStatusRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingTasks: false,
                error,
            };
        }
    ),

    on(MissionManageActions.validateSlotRequest, (state) => {
        return {
            ...state,
            isLoadingUserValidation: true,
        };
    }),
    on(MissionManageActions.validateSlotRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingUserValidation: false,
        };
    }),
    on(MissionManageActions.validateSlotRequestFail, (state, { error }) => {
        return {
            ...state,
            isLoadingUserValidation: false,
            error,
        };
    }),

    on(MissionManageActions.acceptValidationSlotRequest, (state) => {
        return {
            ...state,
            isLoadingUserValidation: true,
        };
    }),

    on(MissionManageActions.acceptValidationSlotRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingUserValidation: false,
        };
    }),
    on(
        MissionManageActions.acceptValidationSlotRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingUserValidation: false,
                error,
            };
        }
    ),

    on(MissionManageActions.denyValidationSlotRequest, (state) => {
        return {
            ...state,
            isLoadingUserValidation: true,
        };
    }),

    on(MissionManageActions.denyValidationSlotRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingUserValidation: false,
        };
    }),
    on(
        MissionManageActions.denyValidationSlotRequestFail,
        (state, { error }) => {
            return {
                ...state,
                isLoadingUserValidation: false,
                error,
            };
        }
    )
);
