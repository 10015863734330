<div class="h-fit md:h-2/3 max-h-[60vh] overflow-y-scroll">
    <div *ngIf="!isLoading; else loading" class="flex flex-wrap gap-4 p-3">
        <div
            *ngIf="showUnequiped"
            (click)="onPreviewAsset(null)"
            [class.bg-blue-200]="noItemSelected"
            class="relative bg-accent rounded-xl shadow w-24 h-24 p-3 flex cursor-pointer"
        >
            <mat-icon
                class="h-fit w-fit text-primary"
                [svgIcon]="'heroicons_outline:no-symbol'"
            ></mat-icon>
            <div class="absolute right-1 bottom-0">
                <mat-icon
                    [matTooltip]="'basile.equiped' | transloco"
                    *ngIf="isAssetEquiped(null)"
                    class="text-red-500"
                    [svgIcon]="'heroicons_solid:heart'"
                ></mat-icon>
            </div>
        </div>
        <div
            (click)="onPreviewAsset(asset)"
            [class.bg-blue-200]="isAssetSelected(asset)"
            class="relative bg-accent rounded-xl shadow w-24 h-24 flex cursor-pointer overflow-hidden"
            *ngFor="let asset of selectedTypeAssets"
        >
            <img
                class="absolute"
                [style]="getAssetPosition(asset)"
                [src]="getAssetPath(asset)"
                [alt]="asset.name"
            />
            <div class="absolute right-1 bottom-0">
                <div
                    class="flex mb-1 text-sm items-center"
                    *ngIf="!asset.isOwned"
                >
                    {{ asset.price }}
                    <mat-icon
                        class="ml-1 text-warn icon-size-4"
                        [svgIcon]="'bslk_core:purpose-coins'"
                    ></mat-icon>
                </div>
                <mat-icon
                    [matTooltip]="'basile.owned' | transloco"
                    *ngIf="asset.isOwned"
                    class="text-green-500"
                    [svgIcon]="'heroicons_solid:check-circle'"
                ></mat-icon>
                <mat-icon
                    [matTooltip]="'basile.equiped' | transloco"
                    *ngIf="isAssetEquiped(asset)"
                    class="text-red-500"
                    [svgIcon]="'heroicons_solid:heart'"
                ></mat-icon>
            </div>
        </div>
    </div>
</div>
<div class="h-fit md:h-1/3 border-t flex flex-col">
    <div class="bg-primary text-white p-2">
        <div *ngIf="!selectedAsset">
            <div class="text-4xl text-center">
                {{ "basile.no" | transloco }}
                {{
                    (
                        "basile.asset-types-singular." +
                            selectedAssetType.toString().toLowerCase()
                        | transloco
                    ).toLowerCase()
                }}
            </div>
        </div>

        <div *ngIf="selectedAsset" class="">
            <div class="text-4xl text-center font-bold">
                {{ "basile.assets." + selectedAsset.name | transloco }}
            </div>
        </div>
    </div>

    <div class="p-4 grow flex flex-col justify-around bg-accent-100">
        <div
            *ngIf="
                isSelectedItemEquiped ||
                isSelectedItemOwned ||
                isSelectedItemBuyable
            "
            class="text-2xl w-fit mx-auto"
        >
            <div class="flex items-center" *ngIf="isSelectedItemEquiped">
                <mat-icon
                    class="text-red-500"
                    [svgIcon]="'heroicons_solid:heart'"
                ></mat-icon>
                <span class="ml-2">{{
                    "basile.currently-equiped" | transloco
                }}</span>
            </div>
            <div class="flex items-center mt-2" *ngIf="isSelectedItemOwned">
                <mat-icon
                    class="text-green-500"
                    [svgIcon]="'heroicons_solid:check-circle'"
                ></mat-icon>
                <span class="ml-2">{{ "basile.asset-owned" | transloco }}</span>
            </div>
            <div class="flex items-center" *ngIf="isSelectedItemBuyable">
                <mat-icon
                    class="text-warn"
                    [svgIcon]="'bslk_core:purpose-coins'"
                ></mat-icon>
                <span class="ml-2"
                    >{{ selectedAsset.price }}
                    {{ "basile.purpose-coins" | transloco }}</span
                >
            </div>
        </div>
        <div class="flex" *ngIf="isSelectedItemBuyable">
            <button
                [disabled]="!hasEnoughBalanceForSelectedItem"
                (click)="onBuySelectedAsset()"
                [color]="'accent'"
                class="text-white m-auto"
                mat-flat-button
            >
                <mat-icon [svgIcon]="'heroicons_outline:banknotes'"></mat-icon>
                <span class="ml-1">{{ "basile.buy" | transloco }}</span>
            </button>
        </div>
        <div class="flex" *ngIf="isSelectedItemEquipable">
            <button
                (click)="onEquipSelectedAsset()"
                [color]="'accent'"
                class="text-white m-auto"
                mat-flat-button
            >
                <mat-icon [svgIcon]="'heroicons_outline:heart'"></mat-icon>
                <span class="ml-1">{{ "basile.equip" | transloco }}</span>
            </button>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="flex h-full">
        <mat-progress-spinner
            class="m-auto"
            [diameter]="50"
            [mode]="'indeterminate'"
        >
        </mat-progress-spinner>
    </div>
</ng-template>
