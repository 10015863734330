import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { DualQuizQuestionActions } from './questions.actions';
import { DualQuizQuestionState } from './questions.state';

export const dualQuizQuestionsInitialState: DualQuizQuestionState =
    ressourceListInitialState;

export const dualQuizQuestionReducer = createRessourceListReducer(
    ressourceListInitialState,
    DualQuizQuestionActions
);
