import { createReducer, on } from '@ngrx/store';
import { ECourseSectionType, EReward } from 'app/api';
import { getRewardValues } from 'app/shared/utils/rewards.utils';
import { produce } from 'immer';
import { CourseActions } from './course.action';
import { CourseState } from './course.state';

export const courseInitialState: CourseState = {
    isLoadingChapter: false,
    isLoadingReview: false,
    isLoadingRating: false,
    activeChapterSectionIndex: 0,
    mostAdvancedChapterSectionIndex: 0,
    purposeCoinsGained: 0,
    experienceGained: 0,
};

export const courseReducer = createReducer(
    courseInitialState,

    on(CourseActions.loadChapterRequest, (state) => {
        return {
            ...state,
            isLoadingChapter: true,
        };
    }),
    on(CourseActions.loadChapterRequestSuccess, (state, { chapter }) => {
        // Advance to current section if chapter has previous progression
        let activeSectionIndex = chapter?.lastReadSectionId
            ? chapter.sections.indexOf(
                  chapter.sections.find(
                      (s) => s.id === chapter.lastReadSectionId
                  )
              ) + 1
            : 0;

        // If chapter is already fully done, don't advance to a non existing (out of range) section but go to conclusion (last)
        if (activeSectionIndex >= chapter.sections.length) {
            activeSectionIndex = chapter.sections.length - 1;
        }
        return {
            ...state,
            isLoadingChapter: false,
            activeChapter: chapter,
            activeChapterSectionIndex: activeSectionIndex,
            mostAdvancedChapterSectionIndex: activeSectionIndex,
        };
    }),
    on(CourseActions.loadChapterRequestFail, (state) => {
        return {
            ...state,
            isLoadingChapter: false,
        };
    }),
    on(CourseActions.nextSection, (state, { isLastSection }) => {
        const newSectionIndex = isLastSection
            ? state.activeChapterSectionIndex
            : state.activeChapterSectionIndex + 1;

        const isFirstTimeSectionCompletion =
            newSectionIndex > state.mostAdvancedChapterSectionIndex;
        const rewards = getRewardValues(EReward.CourseChapterSectionRead);
        return {
            ...state,
            activeChapterSectionIndex: newSectionIndex,
            mostAdvancedChapterSectionIndex: isFirstTimeSectionCompletion
                ? newSectionIndex
                : state.mostAdvancedChapterSectionIndex,
            purposeCoinsGained: isFirstTimeSectionCompletion
                ? state.purposeCoinsGained + rewards.purposeCoins
                : state.purposeCoinsGained,
            experienceGained: isFirstTimeSectionCompletion
                ? state.experienceGained + rewards.experiencePoints
                : state.experienceGained,
            isLastAnswerCorrect: undefined,
        };
    }),
    on(CourseActions.goToSection, (state, { sectionIndex }) => {
        return {
            ...state,
            activeChapterSectionIndex: sectionIndex,
        };
    }),
    on(CourseActions.sendAnswerRequest, (state, { isCorrect }) =>
        produce(state, (draftState) => {
            draftState.isLastAnswerCorrect = isCorrect;
            const rewards = getRewardValues(
                EReward.CourseChapterQuestionCorrectlyAnswered
            );
            draftState.purposeCoinsGained = isCorrect
                ? state.purposeCoinsGained + rewards.purposeCoins
                : state.purposeCoinsGained;
            draftState.experienceGained = isCorrect
                ? state.experienceGained + rewards.experiencePoints
                : state.experienceGained;

            const questionType: ECourseSectionType =
                state.activeChapter.sections[state.activeChapterSectionIndex]
                    .type;
            if (questionType === ECourseSectionType.MultipleChoiceQuestion) {
                draftState.activeChapter.sections[
                    draftState.activeChapterSectionIndex
                ].multipleChoiceQuestion.isUserCorrect = isCorrect;
            } else {
                draftState.activeChapter.sections[
                    draftState.activeChapterSectionIndex
                ].trueFalseQuestion.isUserCorrect = isCorrect;
            }
        })
    ),
    on(CourseActions.loadCourseReviewRequest, (state) => {
        return {
            ...state,
            isLoadingReview: true,
        };
    }),
    on(CourseActions.loadCourseReviewRequestSuccess, (state, { review }) => {
        return {
            ...state,
            isLoadingReview: false,
            review,
        };
    }),
    on(CourseActions.loadCourseReviewRequestFail, (state) => {
        return {
            ...state,
            isLoadingReview: false,
        };
    }),

    on(CourseActions.sendCourseRatingRequest, (state) => {
        return {
            ...state,
            isLoadingRating: true,
        };
    }),

    on(CourseActions.sendCourseRatingRequestSuccess, (state) => {
        return {
            ...state,
            isLoadingRating: false,
        };
    }),

    on(CourseActions.sendCourseRatingRequestFail, (state) => {
        return {
            ...state,
            isLoadingRating: false,
        };
    })
);
