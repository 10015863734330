<div
    #goodNewsOrigin
    (click)="openPanel()"
    class="flex cursor-pointer hover:scale-[1.03] duration-300"
>
    <div
        class="flex m-auto bg-white rounded-full bg-opacity-50 w-16 h-16 shadow"
    >
        <mat-icon
            class="text-primary m-auto w-10 h-10"
            [svgIcon]="'heroicons_outline:newspaper'"
        ></mat-icon>
    </div>
    <div
        [matTooltip]="'Lire la bonne nouvelle se la semaine'"
        class="flex flex-col justify-center pl-4 max-w-44 xl:max-w-48"
    >
        <span class="truncate">{{
            "regulardashboard.good-news" | transloco
        }}</span>
        <span class="font-bold text-lg">{{
            "regulardashboard.read" | transloco
        }}</span>
    </div>
</div>

<bslk-classic-overlay
    *ngIf="isViewInitialized"
    [isOpen]="isPanelOpen"
    [origin]="goodNewsOriginRef"
    (close)="onClose()"
>
    <span title>{{
        "regulardashboard.good-news-of-the-week" | transloco
    }}</span>
    <div class="px-3 py-1" *ngrxLet="page$ as page">
        <bslk-page *ngIf="page; else loading" [page]="page">
            <div class="flex justify-center" signature>
                <img class="w-24" src="assets/basile-postures/writing.png" />
                <div class="flex flex-col justify-center">
                    <span class="font-bold text-xl text-tertiary">Basile,</span>
                    <span class="text-gra"
                        >{{ "shared.common.the" | transloco }}
                        {{ page.startDate | date }}
                    </span>
                </div>
            </div>
        </bslk-page>
    </div>
</bslk-classic-overlay>

<ng-template #loading>
    <div class="flex flex-col justify-center">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full h-30 lg:h-40 xxl:h-50 mb-4"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader count="16" class="w-full"></ngx-skeleton-loader>
    </div>
</ng-template>
