import {
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MatCheckboxChange,
    MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { MissionListActions } from 'app/store/mission/mission-list/mission-list.actions';
import { MissionListSelectors } from 'app/store/mission/mission-list/mission-list.selectors';
import { ResourceListFilter } from 'app/store/shared/ressource-list/ressource-list-state';
import { remove } from 'lodash-es';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable, Subscription } from 'rxjs';
import { MissionFiltersService } from '../mission-filters.service';
@Component({
    selector: 'location-filter',
    standalone: true,
    templateUrl: './location-filter.component.html',
    imports: [
        AsyncPipe,
        BslkClassicToggleComponent,
        FormsModule,
        LetDirective,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatTooltipModule,
        NgClass,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        NgxSkeletonLoaderModule,
        TranslocoModule,
    ],
})
export class LocationFilterComponent implements OnInit, OnDestroy {
    @Input() clearEvent: Observable<ResourceListFilter[]>;
    @Output() locationChange: EventEmitter<void> = new EventEmitter<void>();

    private clearEventSubscription: Subscription;

    cities$ = this.store.select(MissionListSelectors.selectCities);
    isLoading$ = this.store.select(MissionListSelectors.selectIsLoadingCities);
    hasCities$ = this.store.select(MissionListSelectors.selectHasCities);

    search: string = '';
    isExpanded = false;
    hasExpandedOnce = false;

    isRemote: boolean | null = null;

    constructor(
        private store: Store,
        private missionFiltersService: MissionFiltersService
    ) {}

    get filteredCities() {
        return this.missionFiltersService.getFilteredCities;
    }

    ngOnInit() {
        if (this.clearEvent) {
            this.clearEventSubscription = this.clearEvent.subscribe((types) => {
                if (types.includes('isRemote')) {
                    this.isRemote = null;
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.clearEventSubscription) {
            this.clearEventSubscription.unsubscribe();
        }
    }

    toggleIsExpanded() {
        this.isExpanded = !this.isExpanded;

        if (!this.hasExpandedOnce) {
            this.hasExpandedOnce = true;
            this.store.dispatch(
                MissionListActions.LoadCities({ search: null })
            );
        }
    }

    searchChanged(search: string) {
        this.store.dispatch(MissionListActions.LoadCities({ search }));
    }

    clearSearch() {
        this.search = '';
        this.store.dispatch(MissionListActions.LoadCities({ search: null }));
    }

    citiesChanged(event: MatCheckboxChange, city: string) {
        if (event.checked) {
            this.filteredCities.push(city);
        } else {
            remove(this.filteredCities, (item) => item === city);
        }
        this.locationChange.emit();
    }

    setIsRemote(value: boolean | null) {
        this.isRemote = value;
        this.missionFiltersService.setIsRemote(value);
        this.locationChange.emit();
    }

    // Needed otherwise checkbox doesn't lose focus on click and extra click on page is needed to do any action
    checkboxClicked(event: MouseEvent): void {
        (event.target as HTMLElement).blur();
    }
}
