import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getUserSessionFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { UserActions } from 'app/store/user/user/user.actions';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { map, take } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (_, state) => {
    const router: Router = inject(Router);
    const store: Store = inject(Store);

    return store.select(UserSelectors.selectIsSignedIn).pipe(
        take(1),
        map((isSignedIn) => {
            if (!isSignedIn) {
                const userSession = getUserSessionFromLocalStorage();
                if (
                    userSession &&
                    new Date(userSession.expirationDate) > new Date()
                ) {
                    store.dispatch(
                        UserActions.alreadySignedIn({ session: userSession })
                    );
                    return true;
                }
                const redirectURL =
                    state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
                const urlTree = router.parseUrl(`sign-in?${redirectURL}`);
                return urlTree;
            } else {
                return true;
            }
        })
    );
};
