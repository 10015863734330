import { TextFieldModule } from '@angular/cdk/text-field';
import {
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import {
    EActivityField,
    EMissionType,
    ESkill,
    ETrackedActivityType,
} from 'app/api';
import { SafePipe } from 'app/shared/pipes/safe.pipe';
import { ActivityService } from 'app/shared/services/activity/activity.service';
import { TranslationService } from 'app/shared/services/transloco/translation.service';
import { MissionListActions } from 'app/store/mission/mission-list/mission-list.actions';
import { ResourceListFilter } from 'app/store/shared/ressource-list/ressource-list-state';
import { isEmpty, isNil } from 'lodash-es';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AvailabilityFilterComponent } from './availability-filter/availability-filter.component';
import {
    CheckboxFilter,
    CheckboxFilterItem,
} from './checkbox-filter/checkbox-filter.component';
import { DatesFilterComponent } from './dates-filter/dates-filter.component';
import { LocationFilterComponent } from './location-filter/location-filter.component';
import { MissionFiltersService } from './mission-filters.service';
import { TeamBuildingFilter } from './team-building-filter/team-building-filter.component';
export type ViewType = 'grid' | 'row';

export type MissionFiltersInput = {
    view: ViewType;
};

export type ActiveFilter = {
    name?: string;
    translocoKey?: boolean;
    value?: any;
    type: ResourceListFilter;
};

@Component({
    selector: 'mission-filters',
    standalone: true,
    templateUrl: './mission-filters.component.html',
    imports: [
        AsyncPipe,
        AvailabilityFilterComponent,
        BslkTagComponent,
        CheckboxFilter,
        DatesFilterComponent,
        FormsModule,
        FuseCardComponent,
        LetDirective,
        LocationFilterComponent,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTooltipModule,
        NgClass,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        NgxSkeletonLoaderModule,
        RouterLink,
        SafePipe,
        TeamBuildingFilter,
        TextFieldModule,
        TranslocoModule,
    ],
})
export class MissionFiltersComponent implements OnInit {
    @Input() input: MissionFiltersInput;
    @Output() filterClear: EventEmitter<ResourceListFilter[]> =
        new EventEmitter<ResourceListFilter[]>();

    ESkill = ESkill;
    EActivityField = EActivityField;

    unsubscribeAll: Subject<any> = new Subject<any>();
    clearFiltersSubject = new Subject<ResourceListFilter[]>();

    skillsTranslationsLoaded = false;
    skillsTranslations: CheckboxFilterItem[];
    filteredSkills: ESkill[] = this.missionFiltersService.getFilteredSkills;

    activityFieldsTranslationsLoaded = false;
    activityFieldsTranslations: CheckboxFilterItem[];
    filteredActivityFields: EActivityField[] =
        this.missionFiltersService.getFilteredActivityFields;

    missionTypesTranslationsLoaded = false;
    missionTypesTranslations: CheckboxFilterItem[];
    filteredMissionTypes: EMissionType[] =
        this.missionFiltersService.getFilteredMissionTypes;

    availabilitiesTranslationsLoaded = false;
    availabilitiesTranslations: CheckboxFilterItem[];

    constructor(
        private store: Store,
        private translationService: TranslationService,
        private missionFiltersService: MissionFiltersService,
        private activityService: ActivityService
    ) {}

    get isGridView() {
        return this.input.view === 'grid';
    }

    get isRowView() {
        return this.input.view === 'row';
    }

    get hasActiveFilters() {
        return !isEmpty(this.activeFilters);
    }

    get activeFilters() {
        return [
            ...(!isNil(this.missionFiltersService.getNameContains)
                ? [
                      {
                          name: this.missionFiltersService.getNameContains,
                          type: 'nameContains',
                          translocoKey: 'contains',
                      },
                  ]
                : []),
            ...this.missionFiltersService.getFilteredSkills.map((value) => ({
                name: this.skillsTranslations.find((st) => st.value === value)
                    ?.name,
                value,
                type: 'skills',
            })),
            ...this.missionFiltersService.getFilteredActivityFields.map(
                (value) => ({
                    name: this.activityFieldsTranslations.find(
                        (af) => af.value === value
                    )?.name,
                    value,
                    type: 'activityFields',
                })
            ),
            ...this.missionFiltersService.getFilteredMissionTypes.map(
                (value) => ({
                    name: this.missionTypesTranslations.find(
                        (mt) => mt.value === value
                    )?.name,
                    value,
                    type: 'missionTypes',
                })
            ),
            ...this.missionFiltersService.getFilteredCities.map((value) => ({
                name: value,
                value: value,
                type: 'cities',
            })),
            ...(!isNil(this.missionFiltersService.getStartDate)
                ? [
                      {
                          name:
                              '>=  ' +
                              this.missionFiltersService.getStartDate.toFormat(
                                  'd MMM yyyy'
                              ),
                          type: 'startDate',
                      },
                  ]
                : []),
            ...(!isNil(this.missionFiltersService.getEndDate)
                ? [
                      {
                          name:
                              '<=  ' +
                              this.missionFiltersService.getEndDate.toFormat(
                                  'd MMM yyyy'
                              ),
                          type: 'endDate',
                      },
                  ]
                : []),
            ...(!isNil(this.missionFiltersService.getIsRemote)
                ? [
                      {
                          translocoKey: this.missionFiltersService.getIsRemote
                              ? 'remote'
                              : 'on-site',
                          type: 'isRemote',
                          value: this.missionFiltersService.getIsRemote,
                      },
                  ]
                : []),
            ...(this.missionFiltersService.getIsTeamBuilding
                ? [
                      {
                          translocoKey: 'team-building',
                          type: 'isTeamBuilding',
                          value: this.missionFiltersService.getIsTeamBuilding,
                      },
                  ]
                : []),
        ];
    }

    ngOnInit(): void {
        this.translationService
            .getTranslatedEnum(ESkill, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.skillsTranslations = translatedItems;
                this.skillsTranslationsLoaded = true;
            });

        this.translationService
            .getTranslatedEnum(EActivityField, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.activityFieldsTranslations = translatedItems;
                this.activityFieldsTranslationsLoaded = true;
            });

        this.translationService
            .getTranslatedEnum(EMissionType, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.missionTypesTranslations = translatedItems;
                this.missionTypesTranslationsLoaded = true;
            });

        if (this.activeFilters.length > 0) {
            this.dispatchFiltersAction();
        }
    }

    removeFilter(activeFilter: ActiveFilter) {
        this.clearFiltersSubject.next([activeFilter.type]);
        this.missionFiltersService.removeFilter(activeFilter);
        this.filterClear.emit([activeFilter.type]);
        this.dispatchFiltersAction();
    }

    removeAllFilters() {
        const filters: ResourceListFilter[] = [
            'startDate',
            'endDate',
            'isRemote',
            'nameContains',
            'isTeamBuilding',
        ];
        this.clearFiltersSubject.next(filters);
        this.filterClear.emit(filters);
        this.missionFiltersService.clearAllFilters();
        this.dispatchFiltersAction();
    }

    skillsChanged() {
        this.dispatchFiltersAction();
        if (!isEmpty(this.missionFiltersService.getFilteredSkills)) {
            this.activityService.sendActivityRequest({
                trackedActivityType: ETrackedActivityType.MissionFilterBySkill,
                details:
                    this.missionFiltersService.getFilteredSkills.join(' - '),
            });
        }
    }

    activityFieldsChanged() {
        this.dispatchFiltersAction();
        if (!isEmpty(this.missionFiltersService.getFilteredActivityFields)) {
            this.activityService.sendActivityRequest({
                trackedActivityType:
                    ETrackedActivityType.MissionFilterByActivityField,
                details:
                    this.missionFiltersService.getFilteredActivityFields.join(
                        ' - '
                    ),
            });
        }
    }

    missionTypesChanged() {
        this.dispatchFiltersAction();
        if (!isEmpty(this.missionFiltersService.getFilteredMissionTypes)) {
            //TODO: Add tracked activity for filtered missionTypes
        }
    }

    teamBuildingChanged() {
        this.dispatchFiltersAction();
        //TODO: Add tracked activity for team building filter
    }

    locationsChanged() {
        this.dispatchFiltersAction();
        if (!isEmpty(this.missionFiltersService.getFilteredCities)) {
            this.activityService.sendActivityRequest({
                trackedActivityType:
                    ETrackedActivityType.MissionFilterByLocation,
                details:
                    this.missionFiltersService.getFilteredCities.join(' - '),
            });
        }
    }

    datesChanged(event: 'start' | 'end') {
        this.activityService.sendActivityRequest({
            trackedActivityType:
                event === 'start'
                    ? ETrackedActivityType.MissionFilterByStartDate
                    : ETrackedActivityType.MissionFilterByEndDate,
            details:
                event === 'start'
                    ? this.missionFiltersService.getStartDate.toString()
                    : this.missionFiltersService.getEndDate.toString(),
        });
        this.dispatchFiltersAction();
    }

    dispatchFiltersAction() {
        this.store.dispatch(
            MissionListActions.FilterListRequest({
                filters: this.missionFiltersService.filters,
            })
        );
    }

    changeView(viewType: ViewType) {
        this.input.view = viewType;
        this.activityService.sendActivityRequest({
            trackedActivityType:
                viewType === 'row'
                    ? ETrackedActivityType.MissionSwitchToRowView
                    : ETrackedActivityType.MissionSwitchToGridView,
        });
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }
}
