import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { isNil } from "lodash-es";
import { filter, tap } from "rxjs";
import { HighlightActions } from "./highlight.action";

@Injectable()
export class HighlightEffects {

  redirectIfNeeded$ = createEffect(() => this.actions$.pipe(
    ofType(HighlightActions.createHighlightEvent),
    filter(({ event }) => !isNil(event.targetUrl) && !this.router.url.includes(event.targetUrl)),
    tap(({ event }) => {
      this.router.navigateByUrl(event.targetUrl);
    })
  ), { dispatch: false });

  constructor(
    private store: Store,
    private actions$: Actions,
    private router: Router

  ) {}
}