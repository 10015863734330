/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ENotificationType = 'FirstConnection' | 'CallForFeedback' | 'UserRegisteredMissionForAssociation' | 'RegularCanceledMission' | 'AssoAdminAcceptedRegular' | 'AssoAdminDeniedRegular' | 'RegularValidatedMission' | 'AssoAdminChangedEstimation' | 'AssoAdminValidatedMission' | 'AssoAdminDeniedValidationMission' | 'RegularChangedEstimation' | 'UserRegisteredMissionForManager' | 'ManagerAcceptedRegular' | 'ManagerDeniedRegular' | 'RegularAddedToTeamBuilding' | 'TeamRegisteredToTeamBuildingForAssoAdmin' | 'TeamRegisteredToTeamBuildingForManager' | 'ManagerAcceptedTeamBuilding' | 'RegularRemovedFromTeamBuilding' | 'ChallengedToDualQuiz' | 'ChallengeeCompletedDualQuiz';

export const ENotificationType = {
    FirstConnection: 'FirstConnection' as ENotificationType,
    CallForFeedback: 'CallForFeedback' as ENotificationType,
    UserRegisteredMissionForAssociation: 'UserRegisteredMissionForAssociation' as ENotificationType,
    RegularCanceledMission: 'RegularCanceledMission' as ENotificationType,
    AssoAdminAcceptedRegular: 'AssoAdminAcceptedRegular' as ENotificationType,
    AssoAdminDeniedRegular: 'AssoAdminDeniedRegular' as ENotificationType,
    RegularValidatedMission: 'RegularValidatedMission' as ENotificationType,
    AssoAdminChangedEstimation: 'AssoAdminChangedEstimation' as ENotificationType,
    AssoAdminValidatedMission: 'AssoAdminValidatedMission' as ENotificationType,
    AssoAdminDeniedValidationMission: 'AssoAdminDeniedValidationMission' as ENotificationType,
    RegularChangedEstimation: 'RegularChangedEstimation' as ENotificationType,
    UserRegisteredMissionForManager: 'UserRegisteredMissionForManager' as ENotificationType,
    ManagerAcceptedRegular: 'ManagerAcceptedRegular' as ENotificationType,
    ManagerDeniedRegular: 'ManagerDeniedRegular' as ENotificationType,
    RegularAddedToTeamBuilding: 'RegularAddedToTeamBuilding' as ENotificationType,
    TeamRegisteredToTeamBuildingForAssoAdmin: 'TeamRegisteredToTeamBuildingForAssoAdmin' as ENotificationType,
    TeamRegisteredToTeamBuildingForManager: 'TeamRegisteredToTeamBuildingForManager' as ENotificationType,
    ManagerAcceptedTeamBuilding: 'ManagerAcceptedTeamBuilding' as ENotificationType,
    RegularRemovedFromTeamBuilding: 'RegularRemovedFromTeamBuilding' as ENotificationType,
    ChallengedToDualQuiz: 'ChallengedToDualQuiz' as ENotificationType,
    ChallengeeCompletedDualQuiz: 'ChallengeeCompletedDualQuiz' as ENotificationType
};

