import { createReducer, on } from '@ngrx/store';
import { ReportActions } from './report.action';
import { ReportState } from './report.state';

export const reportInitialState: ReportState = {
    isLoading: false,
};

export const reportReducer = createReducer(
    reportInitialState,

    on(ReportActions.downloadReportRequest, (state) => ({
        ...state,
        isLoading: true,
        error: undefined,
    })),

    on(ReportActions.downloadReportRequestSuccess, (state) => ({
        ...state,
        isLoading: false,
        error: undefined,
    })),

    on(ReportActions.downloadReportRequestFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
    }))
);
