import { createAction, props } from '@ngrx/store';
import {
    AssociationUserViewModel,
    AssociationUserViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const AssociationUsersActions = {
    ...createRessourceListActionGroup<
        AssociationUserViewModel,
        AssociationUserViewModelPaginatedListDto
    >('AssociationUsers'),
    AnswerJoinRequest: createAction(
        `[AssociationUsers] Answer Join Request`,
        props<{ id: number; accepted: boolean }>()
    ),
    AnswerJoinRequestSuccess: createAction(
        `[AssociationUsers] Answer Join Request Success`
    ),
    AnswerJoinRequestFail: createAction(
        `[AssociationUsers] Answer Join Request Fail`
    ),
};
