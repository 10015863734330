/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EBadge = 'FirstGoodAnswer' | 'ThreeGoodAnswersInARow' | 'FirstChapterCompleted' | 'FirstCourseCompleted' | 'SurveyFoundAllAnswers';

export const EBadge = {
    FirstGoodAnswer: 'FirstGoodAnswer' as EBadge,
    ThreeGoodAnswersInARow: 'ThreeGoodAnswersInARow' as EBadge,
    FirstChapterCompleted: 'FirstChapterCompleted' as EBadge,
    FirstCourseCompleted: 'FirstCourseCompleted' as EBadge,
    SurveyFoundAllAnswers: 'SurveyFoundAllAnswers' as EBadge
};

