import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ESharedUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { DualQuizChallengeActions } from 'app/store/dual-quiz/challenge/challenge.action';

@Component({
    selector: 'dual-quiz-challenge-next-button',
    templateUrl: './next-button.component.html',
    standalone: true,
    imports: [AsyncPipe, LetDirective, TranslocoModule, NgIf],
})
export class DualQuizChallengeNextButtonComponent {
    @Input() isStarting: boolean;
    @Input() isEnding: boolean = false;
    @Input() challengeId: number;

    constructor(private store: Store, private router: Router) {}

    next() {
        this.store.dispatch(
            DualQuizChallengeActions.nextQuestion({
                isStarting: this.isStarting,
            })
        );
    }

    seeResults() {
        this.router.navigateByUrl(
            UrlHelpers.getSharedUrl(
                ESharedUrl.SharedDualQuizChallengeResultsPage,
                {
                    id: this.challengeId,
                }
            )
        );
    }
}
