import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { BslkAdvancedSelectSyncComponent } from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ECurrency, LocationViewModel } from 'app/api';
import { ProjectDonationImpactComponent } from 'app/modules/regular/project/view/donation-impact/donation-impact.component';
import { LocationSelectionComponent } from 'app/shared/components/location-selection/location-selection.component';
import { ProjectFormService } from 'app/shared/services/form/project-form.service';
import { QUILL_MODULES } from 'app/shared/utils/constants.utils';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { QuillEditorComponent } from 'ngx-quill';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'create-project-step-3',
    standalone: true,
    templateUrl: './create-project-step-3.component.html',
    imports: [
        AsyncPipe,
        BslkAdvancedSelectSyncComponent,
        BslkInfoLabelComponent,
        BslkLengthInfoComponent,
        MatFormFieldModule,
        MatSelectModule,
        LetDirective,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        NgIf,
        NgFor,
        ReactiveFormsModule,
        TranslocoModule,
        ProjectDonationImpactComponent,
        QuillEditorComponent,
        LocationSelectionComponent,
        BslkTagComponent,
    ],
})
export class CreateProjectStep3Component implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    ECurrency = ECurrency;
    quillModules = QUILL_MODULES;

    translationsLoaded = false;

    isSuperAdmin$ = this.store.select(UserSelectors.selectIsSuperAdmin);

    constructor(
        private store: Store,
        private translocoService: TranslocoService,
        private projectFormService: ProjectFormService
    ) {}

    get formGroup() {
        return this.projectFormService.getFormGroup;
    }

    get locationsFormArray() {
        return this.formGroup.controls.locations as FormArray;
    }

    get isPurposeOfDonationsErrorDisplayed() {
        const descriptionCtrl = this.formGroup.get('purposeOfDonations');
        return descriptionCtrl.errors && descriptionCtrl.touched;
    }

    ngOnInit(): void {
        this.translocoService
            .selectTranslateObject('ECurrency', {}, 'enums')
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.translationsLoaded = true;
            });
    }

    onLocationSelected(location: LocationViewModel) {
        this.locationsFormArray.push(
            this.projectFormService.createLocation(location)
        );
    }

    removeActionZone(locationFormGroup: FormGroup) {
        this.locationsFormArray.removeAt(
            this.locationsFormArray.controls.indexOf(locationFormGroup)
        );
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }

    ngOnDestroy() {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
