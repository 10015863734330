import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { RankingExperienceActions } from './ranking-experience.actions';
import { RankingExperienceState } from './ranking-experience.state';

export const initialState: RankingExperienceState = ressourceListInitialState;

export const rankingExperienceReducer = createRessourceListReducer(
    ressourceListInitialState,
    RankingExperienceActions
);
