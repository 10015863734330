import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BslkColor, BslkSize } from '@bslk/types/bslk-types';
import { BasileConfigurationViewModel } from 'app/api';
import { MyBasileComponent } from '../../my-basile/my-basile.component';

@Component({
    standalone: true,
    selector: 'basile-avatar',
    templateUrl: './basile-avatar.component.html',
    imports: [AsyncPipe, MyBasileComponent, NgClass, NgIf],
})
export class BasileAvatarComponent {
    @Input() disableZooming = false;
    @Input() ringSize: BslkSize;
    @Input() hideBackground = false;
    @Input() configuration: BasileConfigurationViewModel;
    @Input() background: BslkColor = 'warn';
}
