import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import packageJson from '../../../../package.json';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private _httpClient: HttpClient) {}

    getTranslation(lang: string): Observable<Translation> {
        const version = packageJson.version;
        const cacheKey = `translations-${lang}-v${version}`;
        const cached = localStorage.getItem(cacheKey);

        if (cached && environment.production) {
            return of(JSON.parse(cached));
        } else {
            // If not cached or version changed, fetch from server and update cache
            return this._httpClient
                .get<Translation>(`./assets/i18n/${lang}.json`)
                .pipe(
                    map((translation) => {
                        // Clear outdated translations from localStorage
                        this.clearOutdatedTranslations(lang, version);
                        // Cache the new version
                        localStorage.setItem(
                            cacheKey,
                            JSON.stringify(translation)
                        );
                        return translation;
                    })
                );
        }
    }

    private clearOutdatedTranslations(
        lang: string,
        currentVersion: string
    ): void {
        // Iterate over all localStorage items
        for (let i = localStorage.length - 1; i >= 0; i--) {
            const key = localStorage.key(i);
            if (
                key &&
                key.startsWith(`translations-${lang}`) &&
                !key.endsWith(`-v${currentVersion}`)
            ) {
                // Remove item if it's an outdated translation
                localStorage.removeItem(key);
            }
        }
    }
}
