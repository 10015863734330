/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EActivityField = 'AnimalWelfare' | 'ArtAndCulture' | 'ChildrenAndYouth' | 'ClimateAction' | 'DigitalInclusion' | 'DisabilitySupport' | 'EducationAndTraining' | 'ElderlyCare' | 'EmergencyRelief' | 'EmploymentAndCareerDevelopment' | 'EnvironmentalConservation' | 'GenderEquality' | 'GlobalPartnership' | 'HealthAndWellbeing' | 'HumanRights' | 'InnovationAndResearch' | 'LGBTQIARights' | 'MentalHealth' | 'PovertyAndPrecariousness' | 'EqualOpportunity' | 'RefugeeSupport' | 'SocialAndIntergenerationalBond' | 'SocialJustice' | 'SubstanceAbusePrevention' | 'SustainableConsumptionAndFood' | 'SexualViolencePrevention' | 'WomenEmpowerment' | 'Shelter' | 'Other' | 'DisabilityInclusion' | 'InclusionThroughSports' | 'AntiDiscrimination' | 'SustainableDevelopment' | 'EntrepreneurshipSupport' | 'DiversityPromotion' | 'VictimSupport' | 'BiodiversityProtection' | 'OceansAndSeasProtection' | 'ForestProtection' | 'Awareness' | 'SupportForResearch' | 'HealthyLivingAndEating' | 'SustainableMobility' | 'RegionalDynamics' | 'JobsOfTheFuture';

export const EActivityField = {
    AnimalWelfare: 'AnimalWelfare' as EActivityField,
    ArtAndCulture: 'ArtAndCulture' as EActivityField,
    ChildrenAndYouth: 'ChildrenAndYouth' as EActivityField,
    ClimateAction: 'ClimateAction' as EActivityField,
    DigitalInclusion: 'DigitalInclusion' as EActivityField,
    DisabilitySupport: 'DisabilitySupport' as EActivityField,
    EducationAndTraining: 'EducationAndTraining' as EActivityField,
    ElderlyCare: 'ElderlyCare' as EActivityField,
    EmergencyRelief: 'EmergencyRelief' as EActivityField,
    EmploymentAndCareerDevelopment: 'EmploymentAndCareerDevelopment' as EActivityField,
    EnvironmentalConservation: 'EnvironmentalConservation' as EActivityField,
    GenderEquality: 'GenderEquality' as EActivityField,
    GlobalPartnership: 'GlobalPartnership' as EActivityField,
    HealthAndWellbeing: 'HealthAndWellbeing' as EActivityField,
    HumanRights: 'HumanRights' as EActivityField,
    InnovationAndResearch: 'InnovationAndResearch' as EActivityField,
    LgbtqiaRights: 'LGBTQIARights' as EActivityField,
    MentalHealth: 'MentalHealth' as EActivityField,
    PovertyAndPrecariousness: 'PovertyAndPrecariousness' as EActivityField,
    EqualOpportunity: 'EqualOpportunity' as EActivityField,
    RefugeeSupport: 'RefugeeSupport' as EActivityField,
    SocialAndIntergenerationalBond: 'SocialAndIntergenerationalBond' as EActivityField,
    SocialJustice: 'SocialJustice' as EActivityField,
    SubstanceAbusePrevention: 'SubstanceAbusePrevention' as EActivityField,
    SustainableConsumptionAndFood: 'SustainableConsumptionAndFood' as EActivityField,
    SexualViolencePrevention: 'SexualViolencePrevention' as EActivityField,
    WomenEmpowerment: 'WomenEmpowerment' as EActivityField,
    Shelter: 'Shelter' as EActivityField,
    Other: 'Other' as EActivityField,
    DisabilityInclusion: 'DisabilityInclusion' as EActivityField,
    InclusionThroughSports: 'InclusionThroughSports' as EActivityField,
    AntiDiscrimination: 'AntiDiscrimination' as EActivityField,
    SustainableDevelopment: 'SustainableDevelopment' as EActivityField,
    EntrepreneurshipSupport: 'EntrepreneurshipSupport' as EActivityField,
    DiversityPromotion: 'DiversityPromotion' as EActivityField,
    VictimSupport: 'VictimSupport' as EActivityField,
    BiodiversityProtection: 'BiodiversityProtection' as EActivityField,
    OceansAndSeasProtection: 'OceansAndSeasProtection' as EActivityField,
    ForestProtection: 'ForestProtection' as EActivityField,
    Awareness: 'Awareness' as EActivityField,
    SupportForResearch: 'SupportForResearch' as EActivityField,
    HealthyLivingAndEating: 'HealthyLivingAndEating' as EActivityField,
    SustainableMobility: 'SustainableMobility' as EActivityField,
    RegionalDynamics: 'RegionalDynamics' as EActivityField,
    JobsOfTheFuture: 'JobsOfTheFuture' as EActivityField
};

