import { Injectable } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import {
    DonationCampaignViewModel,
    EDonationCampaignWhitelistType,
} from 'app/api';
import { CustomValidators } from 'app/shared/utils/custom-validators.utils';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';
import { getUserFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { round2Decimals } from 'app/shared/utils/number.utils';
import { isEmpty } from 'lodash-es';
import { IFormService } from './form-service';

@Injectable({
    providedIn: 'root',
})
export class DonationCampaignFormService
    implements IFormService<DonationCampaignViewModel>
{
    private formGroup: FormGroup;

    constructor(
        protected fb: FormBuilder,
        private translocoService: TranslocoService
    ) {
        this.initForm();
    }

    get getFormGroup() {
        return this.formGroup;
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group(
            {
                id: [''],
                name: ['', [Validators.required, Validators.maxLength(200)]],
                dateCreated: [null],
                dateUpdated: [null],
                dateArchived: [null],
                expirationDate: [null],
                donationAmount: [
                    null,
                    [Validators.required, Validators.min(1)],
                ],
                numberOfDonations: [null, Validators.min(1)],
                isOneEnvelope: [false],
                onlyAllowProfessionalEmails: [false],
                message: [this.getBaseMessage(), Validators.maxLength(600)],
                projectIds: [
                    [],
                    [Validators.required, Validators.minLength(2)],
                ],
                whitelistType: [EDonationCampaignWhitelistType.None],
                accessToken: ['', CustomValidators.validUrlSegmentValidator],
                whitelistedEmails: [[]],
                codesToGenerate: [null],
                isPaid: [false],
                isClientCampaign: [false],
                clientName: [null],
            },
            { validators: this.dynamicValidator() }
        );

        return this.formGroup;
    }
    updateForm(entity: DonationCampaignViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);

        if (entity.isPaid) {
            this.formGroup.get('numberOfDonations')?.disable();
            this.formGroup.get('donationAmount')?.disable();
            this.formGroup.get('isOneEnvelope')?.disable();
        } else {
            this.formGroup.get('numberOfDonations')?.enable();
            this.formGroup.get('donationAmount')?.enable();
            this.formGroup.get('isOneEnvelope')?.enable();
        }
    }

    getEntity(): { [key: string]: DonationCampaignViewModel } {
        const donationCampaignViewModel =
            returnOnlyNonNullFields<DonationCampaignViewModel>(
                this.formGroup.getRawValue() // raw value to include disabled components
            ) as DonationCampaignViewModel;
        return { donationCampaignViewModel };
    }

    getTotalPrice() {
        const donationAmount = this.formGroup.get('donationAmount').value;
        const isOneEnvelope = this.formGroup.get('isOneEnvelope').value;
        const numberOfDonations = this.formGroup.get('numberOfDonations').value;
        return round2Decimals(
            isOneEnvelope ? donationAmount : donationAmount * numberOfDonations
        );
    }

    getPriceAfterTaxDeductible() {
        return round2Decimals(this.getTotalPrice() * 0.4);
    }

    getBaseMessage() {
        const user = getUserFromLocalStorage();
        if (!user?.company?.name) {
            return '';
        }
        return this.translocoService.translate(
            'donationcampaigns.base-message',
            {
                companyName: user?.company?.name,
            }
        );
    }

    dynamicValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const formGroup = control as FormGroup;

            if (
                formGroup.controls.whitelistType.value ===
                    EDonationCampaignWhitelistType.Email &&
                (isEmpty(formGroup.controls.whitelistedEmails.value) ||
                    formGroup.controls.whitelistedEmails.value.every((e) =>
                        isEmpty(e)
                    ))
            ) {
                return { emptyEmails: true };
            }

            if (
                formGroup.controls.whitelistType.value ===
                    EDonationCampaignWhitelistType.Code &&
                formGroup.controls.isOneEnvelope.value
            ) {
                if (!formGroup.controls.codesToGenerate.value) {
                    return { emptyCodesToGenerate: true };
                }
            }

            return null;
        };
    }
}
