import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import {
    EMissionRecurrenceType,
    ETimePeriod,
    MissionSlotViewModel,
} from 'app/api';
import { MissionFormService } from 'app/shared/services/form/mission-form.service';
import {
    areDatesOnSameDay,
    fromTodayDatesFilter,
    weekendsDatesFilter,
} from 'app/shared/utils/date-helpers.utils';
import { MissionSlotUtils } from 'app/shared/utils/extensions/mission-slots/mission-slots.utils';
import { isEmpty } from 'lodash-es';
import { DateTime } from 'luxon';
import { Subject, takeUntil } from 'rxjs';
import { MissionSlotBuilderService } from '../../mission-slot-builder.service';
import {
    RemoveSlotDialogComponent,
    RemoveSlotDialogEvent,
} from './remove-slot-dialog/remove-slot-dialog.component';

@Component({
    selector: 'create-mission-edit-slot',
    standalone: true,
    templateUrl: './create-mission-edit-slot.component.html',
    styleUrls: ['../../../../../../../../styles/full-calendar.scss'],
    imports: [
        FuseAlertComponent,
        BslkClassicButtonComponent,
        ReactiveFormsModule,
        MatIconModule,
        MatNativeDateModule,
        MatTooltipModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatSelectModule,
        MatOptionModule,
        MatDatepickerModule,
        MatProgressBarModule,
        TranslocoModule,
        AsyncPipe,
        NgIf,
        NgFor,
    ],
})
export default class CreateMissionEditSlotComponent
    implements OnChanges, OnDestroy
{
    @Input() missionSlotGuid: string;

    private unsubscribeAll: Subject<any> = new Subject<any>();
    private removeSlotDialogRef: MatDialogRef<RemoveSlotDialogComponent>;

    EMissionRecurrenceType = EMissionRecurrenceType;
    ETimePeriod = ETimePeriod;

    editedMissionSlotFormGroup?: FormGroup;
    previousStartDate: DateTime;
    previousEndDate: DateTime;

    constructor(
        private missionFormService: MissionFormService,
        private missionSlotBuilder: MissionSlotBuilderService,
        private dialog: MatDialog
    ) {}

    get missionSlotsFormArray(): FormArray {
        return this.missionFormService.getFormGroup.controls
            .missionSlots as FormArray;
    }

    get selectedSlotIndex(): number {
        return this.missionSlotsFormArray.controls.indexOf(
            this.editedMissionSlotFormGroup
        );
    }

    get isDrawing(): boolean {
        return this.missionSlotBuilder.isDrawing;
    }

    get isSlotsEmpty() {
        return isEmpty(this.missionSlotsFormArray.controls);
    }

    get displayRecurrency(): boolean {
        if (!this.editedMissionSlotFormGroup) {
            return false;
        }
        return isEmpty(
            this.editedMissionSlotFormGroup.controls.parentGuid.value
        );
    }

    get displayTimePicker(): boolean {
        if (!this.editedMissionSlotFormGroup) {
            return false;
        }
        const startDate =
            this.editedMissionSlotFormGroup.controls.startDate.value;
        const endDate = this.editedMissionSlotFormGroup.controls.endDate.value;
        return areDatesOnSameDay(startDate, endDate);
    }

    get displayCustomRecurrence(): boolean {
        if (!this.editedMissionSlotFormGroup) {
            return false;
        }
        return (
            this.displayRecurrency &&
            this.editedMissionSlotFormGroup.controls.recurrenceType.value ===
                EMissionRecurrenceType.Custom
        );
    }

    ngOnChanges(event: SimpleChanges) {
        const missionSlotsFormArray = this.missionFormService.getFormGroup
            .controls.missionSlots as FormArray;
        this.editedMissionSlotFormGroup = missionSlotsFormArray.controls.find(
            (group: FormGroup) => {
                return (
                    group.controls.guid.value ===
                    event.missionSlotGuid.currentValue
                );
            }
        ) as FormGroup;

        if (this.editedMissionSlotFormGroup) {
            // Update start time on start date
            MissionSlotUtils.subscribeToFormGroupValueChanges(
                this.editedMissionSlotFormGroup,
                this.unsubscribeAll
            );
        }
    }

    removeSlot() {
        if (this.isSelectedSlotRecurrent()) {
            this.removeSlotDialogRef = this.dialog.open(
                RemoveSlotDialogComponent
            );
            this.removeSlotDialogRef.componentInstance.onConfirm
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe((result: RemoveSlotDialogEvent) => {
                    if (result === 'all') {
                        this.removeRecurrentSlots();
                    } else {
                        this.removeSingleSlot();
                    }
                    this.editedMissionSlotFormGroup = undefined;
                });
        } else {
            this.removeSingleSlot();
            this.editedMissionSlotFormGroup = undefined;
        }
    }

    recurrencyChange() {
        this.missionSlotBuilder.handleRecurrencyChange(
            this.editedMissionSlotFormGroup
        );
    }

    isSelectedSlotRecurrent() {
        const missionSlot: MissionSlotViewModel =
            this.editedMissionSlotFormGroup.value;
        const hasChildSlots = this.missionSlotsFormArray.controls.some(
            (group: FormGroup) => {
                return group.controls.parentGuid.value === this.missionSlotGuid;
            }
        );
        // To check if slot is recurrent, we check if slot has a parentGuid or
        // if at least one other slot has its parentGuid as its guid
        return !isEmpty(missionSlot.parentGuid) || hasChildSlots;
    }

    removeSingleSlot() {
        const index = this.missionSlotsFormArray.controls.indexOf(
            this.editedMissionSlotFormGroup
        );
        this.missionSlotsFormArray.removeAt(index);
    }

    removeRecurrentSlots() {
        // Check if the selected slot is a child slot by checking for a parentGuid
        const parentGuid =
            this.editedMissionSlotFormGroup.controls.parentGuid?.value;
        if (parentGuid) {
            // It's a child slot, remove all slots with this parentGuid, including the parent slot itself
            const slotsToRemove = this.missionSlotsFormArray.controls.filter(
                (group: FormGroup) =>
                    group.controls.parentGuid?.value === parentGuid ||
                    group.controls.guid.value === parentGuid
            );
            slotsToRemove.forEach((slot) => {
                const indexToRemove =
                    this.missionSlotsFormArray.controls.indexOf(slot);
                if (indexToRemove !== -1) {
                    this.missionSlotsFormArray.removeAt(indexToRemove);
                }
            });
        } else {
            // It's a parent slot, remove this slot and all its children
            const slotsToRemove = this.missionSlotsFormArray.controls.filter(
                (group: FormGroup) =>
                    group.controls.parentGuid?.value === this.missionSlotGuid
            );
            slotsToRemove.forEach((slot) => {
                const indexToRemove =
                    this.missionSlotsFormArray.controls.indexOf(slot);
                if (indexToRemove !== -1) {
                    this.missionSlotsFormArray.removeAt(indexToRemove);
                }
            });
            // Finally, remove the parent slot itself
            this.missionSlotsFormArray.removeAt(this.selectedSlotIndex);
        }
    }

    getDatesFilter(date: DateTime) {
        return weekendsDatesFilter(date) && fromTodayDatesFilter(date);
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }

    ngOnDestroy() {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
