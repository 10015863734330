/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CourseSectionViewModel } from './courseSectionViewModel';
import { EAwarenessThematic } from './eAwarenessThematic';


export interface CourseChapterViewModel { 
    courseId?: string | null;
    courseTitle?: string | null;
    id?: string | null;
    title?: string | null;
    index?: number;
    sections?: Array<CourseSectionViewModel> | null;
    lastReadSectionId?: string | null;
    isLastChapter?: boolean;
    thematic?: EAwarenessThematic;
}
export namespace CourseChapterViewModel {
}


