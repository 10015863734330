<base-dialog
    (onClose)="close()"
    [title]="'Nouveau challenge' | transloco"
    [showActions]="true"
>
    <div
        class="w-full h-full flex flex-col justify-center items-center gap-5 min-w-160"
        body
    >
        <!-- Challengee -->
        <div class="flex flex-col gap-3 w-full">
            <div
                [class.bg-primary-50]="
                    formGroup.controls.challengeeType.value === 'registered'
                "
                [class.cursor-pointer]="
                    formGroup.controls.challengeeType.value === 'unregistered'
                "
                (click)="setChallengeeType('registered')"
                class="p-4 border border-primary bg-primary-50 rounded-3xl flex gap-3 items-center cursor-pointer"
            >
                <div
                    class="w-6 h-6 border border-primary rounded-full"
                    [class.bg-primary]="
                        formGroup.controls.challengeeType.value === 'registered'
                    "
                ></div>
                <single-select-async
                    *ngIf="isRegular$ | async"
                    class="grow"
                    [label]="
                        'dualquiz.select-colleague-to-challenge' | transloco
                    "
                    [selectPlaceholder]="
                        'dualquiz.select-colleague' | transloco
                    "
                    [searchPlaceholder]="
                        'dualquiz.search-colleague' | transloco
                    "
                    [actions]="CompanyUsersActions"
                    [selectors]="CompanyUsersSelectors"
                    [excludedIds]="[userId$ | async]"
                    [valueIsId]="true"
                    [control]="formGroup.controls.challengeeId"
                    [noDataText]="'dualquiz.no-colleague-available' | transloco"
                >
                </single-select-async>
                <single-select-async
                    *ngIf="isExternal$ | async"
                    class="grow"
                    [label]="
                        'dualquiz.select-opponent-to-challenge' | transloco
                    "
                    [selectPlaceholder]="'dualquiz.select-opponent' | transloco"
                    [searchPlaceholder]="'dualquiz.search-opponent' | transloco"
                    [actions]="InteractedWithUsersActions"
                    [selectors]="InteractedWithUsersSelectors"
                    [excludedIds]="[userId$ | async]"
                    [valueIsId]="true"
                    [control]="formGroup.controls.challengeeId"
                    [noDataText]="'dualquiz.no-opponent-available' | transloco"
                >
                </single-select-async>
            </div>

            <div class="flex items-center">
                <div class="flex-auto mt-px border-t"></div>
                <div class="mx-4">{{ "dualquiz.or" | transloco }}</div>
                <div class="flex-auto mt-px border-t"></div>
            </div>

            <div
                [class.bg-primary-50]="
                    formGroup.controls.challengeeType.value === 'unregistered'
                "
                [class.cursor-pointer]="
                    formGroup.controls.challengeeType.value === 'registered'
                "
                (click)="setChallengeeType('unregistered')"
                class="p-4 border border-primary bg-primary-50 rounded-3xl flex gap-3 items-center"
            >
                <div
                    class="w-6 h-6 border border-primary rounded-full"
                    [class.bg-primary]="
                        formGroup.controls.challengeeType.value ===
                        'unregistered'
                    "
                ></div>
                <mat-form-field [subscriptSizing]="'dynamic'" class="w-full">
                    <mat-label>
                        <bslk-info-label
                            [tooltip]="
                                'dualquiz.invite-anyone-tooltip' | transloco
                            "
                        >
                            {{ "dualquiz.invite-anyone-by-email" | transloco }}
                        </bslk-info-label>
                    </mat-label>
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:at-symbol'"
                        matPrefix
                    ></mat-icon>
                    <input
                        type="email"
                        matInput
                        [formControl]="formGroup.controls.challengeeEmail"
                    />
                    <mat-error
                        *ngIf="formGroup.controls.challengeeEmail.invalid"
                    >
                        {{ "dualquiz.invalid-email" | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- Thematic -->
        <!-- <div class="flex flex-col gap-2">
            <span class="font-bold">{{
                "Choisis une thématique" | transloco
            }}</span>
            <div class="grid grid-cols-3 gap-2">
                <div
                    [class.grayscale]="
                        formGroup.controls.thematic.value &&
                        formGroup.controls.thematic.value !==
                            firstRandomThematic
                    "
                    (click)="setThematic(firstRandomThematic)"
                    (mouseenter)="hoverThematic = firstRandomThematic"
                    (mouseleave)="hoverThematic = null"
                    class="rounded-2xl bg-accent p-8 border-4 border-primary col-span-1 flex items-center overflow-hidden group cursor-pointer"
                >
                    <mat-icon
                        *ngIf="firstRandomThematic"
                        class="h-24 w-24 group-hover:scale-125 transition-transform duration-300"
                        [class.scale-125]="
                            chosenThematic === firstRandomThematic
                        "
                        [svgIcon]="'bslk_thematics:' + firstRandomThematic"
                    ></mat-icon>
                </div>
                <div
                    [class.grayscale]="
                        formGroup.controls.thematic.value &&
                        formGroup.controls.thematic.value !==
                            secondRandomThematic
                    "
                    (click)="setThematic(secondRandomThematic)"
                    (mouseenter)="hoverThematic = secondRandomThematic"
                    (mouseleave)="hoverThematic = null"
                    class="rounded-2xl bg-accent p-8 border-4 border-warn col-span-1 flex items-center overflow-hidden group cursor-pointer"
                >
                    <mat-icon
                        *ngIf="secondRandomThematic"
                        class="h-24 w-24 group-hover:scale-125 transition-transform duration-300"
                        [class.scale-125]="
                            chosenThematic === secondRandomThematic
                        "
                        [svgIcon]="'bslk_thematics:' + secondRandomThematic"
                    ></mat-icon>
                </div>
                <div
                    (click)="setThematic(thirdRandomThematic)"
                    (mouseenter)="hoverThematic = thirdRandomThematic"
                    (mouseleave)="hoverThematic = null"
                    [class.grayscale]="
                        formGroup.controls.thematic.value &&
                        formGroup.controls.thematic.value !==
                            thirdRandomThematic
                    "
                    class="rounded-2xl bg-accent p-8 border-4 border-tertiary col-span-1 flex items-center overflow-hidden group cursor-pointer"
                >
                    <mat-icon
                        *ngIf="thirdRandomThematic"
                        class="h-24 w-24 group-hover:scale-125 transition-transform duration-300"
                        [class.scale-125]="
                            chosenThematic === thirdRandomThematic
                        "
                        [svgIcon]="'bslk_thematics:' + thirdRandomThematic"
                    ></mat-icon>
                </div>
            </div>

            <div class="min-h-[1.5em]">
                <span
                    *ngIf="formGroup.controls.thematic.value && !hoverThematic"
                    >{{
                        "enums.EAwarenessThematic." +
                            formGroup.controls.thematic.value | transloco
                    }}</span
                >
                <span *ngIf="hoverThematic">{{
                    "enums.EAwarenessThematic." + hoverThematic | transloco
                }}</span>
            </div>

            <fuse-alert
                *ngIf="hasTriedStartingChallenge && formGroupErrors.length > 0"
                class="mx-auto flex"
                [type]="'error'"
            >
                <ul>
                    <li *ngFor="let error of formGroupErrors">
                        {{ "newchallenge.errors." + error | transloco }}
                    </li>
                </ul>
            </fuse-alert>
        </div> -->

        <!-- Seconds Per Question -->
        <bslk-card-toggle
            class="w-full"
            [label]="'dualquiz.cadence' | transloco"
            [labelTooltip]="'dualquiz.cadence-tooltip' | transloco"
            [hideSelectedTag]="true"
            [truncateDescription]="true"
            [choices]="[
                {
                    value: 8,
                    icon: 'heroicons_outline:bolt',
                    text: 'dualquiz.turbo' | transloco,
                    description: 8 + ' ' + ('dualquiz.seconds' | transloco)
                },
                {
                    value: 15,
                    icon: 'feather:fast-forward',
                    text: 'dualquiz.classic' | transloco,
                    description: 15 + ' ' + ('dualquiz.seconds' | transloco)
                },
                {
                    value: 30,
                    icon: 'feather:coffee',
                    text: 'dualquiz.relaxed' | transloco,
                    description: 30 + ' ' + ('dualquiz.seconds' | transloco)
                }
            ]"
            [control]="formGroup.controls.secondsPerQuestion"
        >
        </bslk-card-toggle>

        <!-- Number Of Questions -->
        <bslk-card-toggle
            class="w-full"
            [label]="'dualquiz.number-of-questions' | transloco"
            [hideSelectedTag]="true"
            [color]="'warn'"
            [truncateDescription]="true"
            [choices]="[
                {
                    value: 3,
                    icon: 'feather:wind',
                    text: 'dualquiz.sprint' | transloco,
                    description: 3 + ' ' + ('dualquiz.questions' | transloco)
                },
                {
                    value: 5,
                    icon: 'feather:watch',
                    text: 'dualquiz.endurance' | transloco,
                    description: 5 + ' ' + ('dualquiz.questions' | transloco)
                },
                {
                    value: 10,
                    icon: 'feather:activity',
                    text: 'dualquiz.marathon' | transloco,
                    description: 10 + ' ' + ('dualquiz.questions' | transloco)
                }
            ]"
            [control]="formGroup.controls.questionsCount"
        >
        </bslk-card-toggle>
    </div>

    <div class="flex justify-end" actions>
        <bslk-classic-button
            (onClick)="startChallenge()"
            [isDisabled]="
                hasTriedStartingChallenge && formGroupErrors.length > 0
            "
            [label]="'dualquiz.launch' | transloco"
            [icon]="'heroicons_outline:rocket-launch'"
            [isLoading]="isLoading$ | async"
        >
        </bslk-classic-button>
    </div>
</base-dialog>
