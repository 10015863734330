import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TrackedActivityViewModel } from 'app/api';

export const TrackedActivityActions = createActionGroup({
    source: 'TrackedActivity',
    events: {
        'Log Activity Request': props<{ activity: TrackedActivityViewModel }>(),
        'Log Activity Request Success': emptyProps(),
        'Log Activity Request Fail': props<{ error: string }>(),
    },
});
