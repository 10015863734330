import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BslkBusinessMetricComponent } from '@bslk-business/components/metric/metric.component';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import {
    BslkAdvancedSelectSyncComponent,
    BslkAdvancedSelectSyncInput,
} from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { TranslocoModule } from '@ngneat/transloco';
import { EUnit } from 'app/api';
import { TranslationService } from 'app/shared/services/transloco/translation.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'bslk-business-metric-configurator',
    templateUrl: './metric-configurator.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        BslkClassicToggleComponent,
        BslkLengthInfoComponent,
        FormsModule,
        BslkAdvancedSelectSyncComponent,
        MatInputModule,
        NgIf,
        BslkBusinessMetricComponent,
    ],
})
export class BslkBusinessMetricConfiguratorComponent
    implements OnInit, OnDestroy
{
    @Input() group: FormGroup;
    @Input() showPreview: boolean = true;
    @Input() label: string;

    unsubscribeAll: Subject<any> = new Subject<any>();

    unitsTranslationsLoaded: boolean = false;
    unitsAdvancedSelectItems: BslkAdvancedSelectSyncInput[];

    constructor(private translationService: TranslationService) {}

    get groupValue() {
        return this.group.value;
    }

    ngOnInit(): void {
        this.translationService
            .getTranslatedEnum(EUnit, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.unitsAdvancedSelectItems = translatedItems;
                this.unitsTranslationsLoaded = true;
            });
    }

    isRangeChanged(event: boolean) {}

    ngOnDestroy() {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
