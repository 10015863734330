import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store';

const selectState = (state: AppState) => state.importSheet;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectEmails = createSelector(selectState, (state) => state.emails);

const selectCompanyEmployees = createSelector(
    selectState,
    (state) => state.employees
);

const selectError = createSelector(selectState, (state) => state.error);

export const ImportSheetSelectors = {
    selectIsLoading,
    selectEmails,
    selectError,
    selectCompanyEmployees,
};
