import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    standalone: true,
    selector: 'bslk-info-label',
    templateUrl: './info-label.component.html',
    imports: [MatTooltipModule, MatIconModule, NgIf],
})
export class BslkInfoLabelComponent {
    @Input() tooltip: string;
    @Input() isRequired = false;

    constructor() {}
}
