import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { CompanyCsrApproachViewModel } from 'app/api';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { SafePipe } from 'app/shared/pipes/safe.pipe';
import { SanitizePipe } from 'app/shared/pipes/sanitize.pipe';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { QUILL_MODULES } from 'app/shared/utils/constants.utils';
import { CompanyCsrApproachActions } from 'app/store/company/csr-approach/csr-approach.actions';
import { CompanyCsrApproachSelectors } from 'app/store/company/csr-approach/csr-approach.selectors';
import { QuillEditorComponent } from 'ngx-quill';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'company-csr-approach-description',
    templateUrl: './description.component.html',
    standalone: true,
    imports: [
        BslkClassicButtonComponent,
        BslkLengthInfoComponent,
        LetDirective,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        NgxSkeletonLoaderModule,
        QuillEditorComponent,
        ReactiveFormsModule,
        SafePipe,
        SanitizePipe,
        TranslocoModule,
    ],
})
export class CompanyCsrApproachDescriptionComponent {
    @Input() isLoading: boolean;
    @Input() isCompanyAdmin: boolean;
    @Input() csrApproach: CompanyCsrApproachViewModel;

    isEditing = false;
    quillModules = QUILL_MODULES;

    constructor(
        private store: Store,
        private formService: CompanyCsrApproachFormService,
        private fileUploadDialogService: FileUploadDialogService
    ) {}

    get formGroup() {
        return this.formService.getFormGroup;
    }

    update() {
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
        this.isEditing = false;
    }

    openUploadimageDialog(): void {
        this.fileUploadDialogService.open({
            action: CompanyCsrApproachActions.updateImageRequest,
            isLoading$: this.store.select(
                CompanyCsrApproachSelectors.selectIsLoadingImageUpload
            ),
            maxFileSize: 2000 * 1024,
            infoTextKey: 'csrapproach.image-info',
        });
    }

    removeImage() {
        this.store.dispatch(CompanyCsrApproachActions.removeImageRequest());
    }
}
