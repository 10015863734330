import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
    name: 'error',
    standalone: true,
})
export class ErrorPipe implements PipeTransform {
    constructor(private translocoService: TranslocoService) {}

    transform(value: string, prefix: string = ''): string {
        if (!value || typeof value !== 'string') {
            let message = this.translocoService.translate(
                'alerts.error.generic.message'
            );
            return message;
        }

        // Split the error code and variables
        const [errorCode, ...variables] = value.split(' ');

        // Construct the translation key
        const translationKey = `${prefix}${errorCode}`;

        // Translate the message
        let message = this.translocoService.translate(translationKey);

        // Replace variables in the message
        variables.forEach((variable, index) => {
            message = message.replace(`{${index}}`, variable);
        });

        return message;
    }
}
