<div *ngIf="!isStarted" class="flex justify-center">
    <div
        class="bg-white shadow-light shadow-warn p-8 rounded-xl flex flex-col items-center gap-8"
    >
        <div
            class="h-24 w-24 grayscale bg-quaternary rounded-full border-b-[16px] border-quaternary-800 shadow-xl flex items-center justify-center"
        >
            <mat-icon
                [svgIcon]="'heroicons_outline:lock-closed'"
                class="text-white icon-size-12"
            ></mat-icon>
        </div>
        <span class="text-lg font-bold">{{
            "courses.timeline.unlocked-in" | transloco
        }}</span>

        <bslk-countdown
            [color]="'warn'"
            [showSeconds]="true"
            [targetDate]="startDateDateTime"
        ></bslk-countdown>
    </div>
</div>
