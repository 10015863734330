import {
    AsyncPipe,
    DatePipe,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterLink } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { MissionSlotViewModel } from 'app/api';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { MissionManageActions } from 'app/store/mission/mission-manage/mission-manage.action';
import { MissionManageSelectors } from 'app/store/mission/mission-manage/mission-manage.selectors';
import { MissionSelectors } from 'app/store/mission/mission/mission.selectors';
import { isNil } from 'lodash-es';
import { DateTime } from 'luxon';
import { Subject, takeUntil } from 'rxjs';
import { RegistrationSuccessComponent } from '../shared/registration-success/registration-success/registration-success.component';
export type MissionRegisterSlotDialogInput = {
    missionId: number;
    missionSlots: MissionSlotViewModel[];
};

@Component({
    selector: 'mission-register-dialog',
    templateUrl: './mission-register-slot-dialog.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkClassicButtonComponent,
        BaseDialogComponent,
        FormsModule,
        FuseAlertComponent,
        LetDirective,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        RegistrationSuccessComponent,
        RouterLink,
        TranslocoModule,
    ],
})
export class MissionRegisterSlotDialogComponent implements OnInit, OnDestroy {
    isLoading$ = this.store.select(
        MissionManageSelectors.selectIsLoadingUserValidation
    );
    alreadyRegisteredIds$ = this.store.select(
        MissionSelectors.selectUserRegisteredTimeslotIds
    );

    unsubscribeAll: Subject<any> = new Subject<any>();
    selectedSlotId: number;
    registeredSlotId: number;

    constructor(
        private store: Store,
        private datePipe: DatePipe,
        private dialogRef: MatDialogRef<MissionRegisterSlotDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MissionRegisterSlotDialogInput
    ) {}

    get missionId() {
        return this.data.missionId.toString();
    }

    get showRegistrationSuccess() {
        return (
            !isNil(this.registeredSlotId) &&
            this.registeredSlotId === this.selectedSlotId
        );
    }

    get slotsWithAvailableSpots() {
        return this.data.missionSlots.filter(
            (ms) =>
                ms.availableSpotsLeft > 0 &&
                DateTime.fromISO(ms.startDate) > DateTime.now()
        );
    }

    get isSubmitDisabled() {
        return isNil(this.selectedSlotId);
    }

    ngOnInit(): void {
        if (this.data.missionSlots?.length === 1) {
            this.selectedSlotId = this.data.missionSlots[0].id;
        }
        this.store
            .select(MissionManageSelectors.selectMissionSlotRegisteredId)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((id) => {
                this.registeredSlotId = id;
            });
    }

    formatDateRange(missionSlot: MissionSlotViewModel) {
        return formatDateRange(
            this.datePipe,
            missionSlot.startDate,
            missionSlot.endDate
        );
    }

    register() {
        this.store.dispatch(
            MissionManageActions.registerMissionSlotRequest({
                id: this.selectedSlotId,
            })
        );
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
