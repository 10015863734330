import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { ActionReducer, META_REDUCERS, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { AlertEffects } from './alert/alert.effects';
import { AssociationRatingListEffects } from './association/association-rating/association-rating.effects';
import { associationRatingListReducer } from './association/association-rating/association-rating.reducers';
import { AssociationUsersEffects } from './association/association-users/association-users.effects';
import { associationUsersReducer } from './association/association-users/association-users.reducers';
import { AssociationEffects } from './association/association/association.effects';
import { associationReducer } from './association/association/association.reducers';
import { AssociationListEffects } from './association/associations/associations.effects';
import { associationListReducer } from './association/associations/associations.reducers';
import { AwarenessCampaignEffects } from './awareness/awareness-campaign/awareness-campaign.effects';
import { awarenessCampaignReducer } from './awareness/awareness-campaign/awareness-campaign.reducers';
import { BadgeEffects } from './badge/badge.effects';
import { badgeReducer } from './badge/badge.reducers';
import { ChatEffects } from './chat/chat.effects';
import { chatReducer } from './chat/chat.reducers';
import { CompanyAdminDashboardEffects } from './company/company-admin-dashboard/company-admin-dashboard.effects';
import { companyAdminDashboardReducer } from './company/company-admin-dashboard/company-admin-dashboard.reducer';
import { CompanyEmployeeEffects } from './company/company-employee/company-employee.effects';
import { companyEmployeeReducer } from './company/company-employee/company-employee.reducers';
import { CompanyFiltersEffects } from './company/company-filters/company-filters.effects';
import { companyFiltersReducer } from './company/company-filters/company-filters.reducers';
import { CompanyEffects } from './company/company/company.effects';
import { companyReducer } from './company/company/company.reducers';
import { CompanyCsrApproachEffects } from './company/csr-approach/csr-approach.effects';
import { companyCsrApproachReducer } from './company/csr-approach/csr-approach.reducer';
import { CourseSectionCommentEffects } from './course/course-section-comments/course-section-comments.effects';
import { courseSecitonCommentsReducer } from './course/course-section-comments/course-section-comments.reducers';
import { CourseEffects } from './course/course/course.effects';
import { courseReducer } from './course/course/course.reducers';
import { DemonstrationEffects } from './demonstration/demonstration.effects';
import { demonstrationReducer } from './demonstration/demonstration.reducers';
import { DialogEffects } from './dialog/dialog.effects';
import { DonationCampaignCustomizationEffects } from './donation/donation-campaign-customization/donation-campaign-customization.effects';
import { donationCampaignCustomizationReducer } from './donation/donation-campaign-customization/donation-campaign-customization.reducers';
import { DonationCampaignListEffects } from './donation/donation-campaign-list/donation-campaign-list.effects';
import { donationCampaignListReducer } from './donation/donation-campaign-list/donation-campaign-list.reducers';
import { DonationCampaignEffects } from './donation/donation-campaign/donation-campaign.effects';
import { donationCampaignReducer } from './donation/donation-campaign/donation-campaign.reducers';
import { DualQuizChallengeEffects } from './dual-quiz/challenge/challenge.effects';
import { dualQuizChallengeReducer } from './dual-quiz/challenge/challenge.reducers';
import { DualQuizChallengesEffects } from './dual-quiz/challenges/challenges.effects';
import { dualQuizChallengesReducer } from './dual-quiz/challenges/challenges.reducers';
import { DualQuizMatchUpsEffects } from './dual-quiz/matchups/matchups.effects';
import { dualQuizMatchUpsReducer } from './dual-quiz/matchups/matchups.reducers';
import { DualQuizQuestionEffects } from './dual-quiz/questions/questions.effects';
import { dualQuizQuestionReducer } from './dual-quiz/questions/questions.reducers';
import { DynamicContentEffects } from './dynamic-content/dynamic-content.effects';
import { dynamicContentReducer } from './dynamic-content/dynamic-content.reducers';
import { FeedbackEffects } from './feedback/feedback.effects';
import { feedbackReducer } from './feedback/feedback.reducers';
import { FilesEffects } from './files/files.effects';
import { filesReducer } from './files/files.reducers';
import { HighlightEffects } from './highlight/highlight.effects';
import { highlightReducer } from './highlight/highlight.reducers';
import { MissionListEffects } from './mission/mission-list/mission-list.effects';
import { missionListReducer } from './mission/mission-list/mission-list.reducers';
import { MissionManageEffects } from './mission/mission-manage/mission-manage.effects';
import { missionManageReducer } from './mission/mission-manage/mission-manage.reducers';
import { MissionRatingEffects } from './mission/mission-rating/mission-rating.effects';
import { missionRatingReducer } from './mission/mission-rating/mission-rating.reducers';
import { MissionRatingListEffects } from './mission/mission-ratings/mission-ratings.effects';
import { missionRatingListReducer } from './mission/mission-ratings/mission-ratings.reducers';
import { MissionEffects } from './mission/mission/mission.effects';
import { missionReducer } from './mission/mission/mission.reducers';
import { MyBasilikEffects } from './my-basile/my-basile.effects';
import { myBasilikReducer } from './my-basile/my-basile.reducers';
import { NavigationEffects } from './navigation/navigation.effects';
import { navigationReducer } from './navigation/navigation.reducers';
import { NotificationEffects } from './notification/notification.effects';
import { notificationReducer } from './notification/notification.reducers';
import { ProjectListFiltersEffects } from './project/project-list-filters/project-list-filters.effects';
import { projectListFiltersReducer } from './project/project-list-filters/project-list-filters.reducers';
import { ProjectListEffects } from './project/project-list/project-list.effects';
import { projectListReducer } from './project/project-list/project-list.reducers';
import { ProjectEffects } from './project/project/project.effects';
import { projectReducer } from './project/project/project.reducers';
import { RankingAnswersEffects } from './ranking/ranking-answers/ranking-answers.effects';
import { rankingAnswersReducer } from './ranking/ranking-answers/ranking-answers.reducers';
import { RankingExperienceEffects } from './ranking/ranking-experience/ranking-experience.effects';
import { rankingExperienceReducer } from './ranking/ranking-experience/ranking-experience.reducers';
import { RegularDashboardEffects } from './regular-dashboard/regular-dashboard.effects';
import { regularDashboardReducer } from './regular-dashboard/regular-dashboard.reducer';
import { ReportEffects } from './report/report.effects';
import { reportReducer } from './report/report.reducers';
import { ImportSheetEffects } from './sheet/import/import.effects';
import { importSheetReducer } from './sheet/import/import.reducers';
import { SuperAdminDashboardEffects } from './super-admin/super-admin-dashboard/super-admin-dashboard.effects';
import { superAdminDashboardReducer } from './super-admin/super-admin-dashboard/super-admin-dashboard.reducer';
import { TrackedActivityEffects } from './tracked-activity/tracked-activity.effects';
import { CompanyUsersEffects } from './user/company-users/company-users.effects';
import { companyUsersReducer } from './user/company-users/company-users.reducers';
import { InteractedWithUsersEffects } from './user/interacted-with-users/interacted-with-users.effects';
import { interactedWithUsersReducer } from './user/interacted-with-users/interacted-with-users.reducers';
import { ManagedUsersEffects } from './user/managed-users/managed-users.effects';
import { managedUsersReducer } from './user/managed-users/managed-users.reducers';
import { UserActions } from './user/user/user.actions';
import { UserEffects } from './user/user/user.effects';
import { userReducer } from './user/user/user.reducer';
import { UsersEffects } from './user/users/users.effects';
import { usersReducer } from './user/users/users.reducers';
import { ValidationEffects } from './validation/validation.effects';
import { validationReducer } from './validation/validation.reducers';

export const provideNgrxStore = (): Array<Provider | EnvironmentProviders> => {
    return [
        provideStore({
            association: associationReducer,
            associationList: associationListReducer,
            associationRatingList: associationRatingListReducer,
            associationUsers: associationUsersReducer,
            awarenessCampaign: awarenessCampaignReducer,
            badge: badgeReducer,
            chat: chatReducer,
            companyAdminDashboard: companyAdminDashboardReducer,
            companyList: companyReducer,
            companyUserList: companyUsersReducer,
            companyCsrApproach: companyCsrApproachReducer,
            course: courseReducer,
            courseSectionCommentList: courseSecitonCommentsReducer,
            companyFilters: companyFiltersReducer,
            companyEmployeeList: companyEmployeeReducer,
            demonstration: demonstrationReducer,
            donationCampaignList: donationCampaignListReducer,
            donationCampaignCustomization: donationCampaignCustomizationReducer,
            donationCampaign: donationCampaignReducer,
            dualQuizChallengeList: dualQuizChallengesReducer,
            dualQuizChallenge: dualQuizChallengeReducer,
            dualQuizQuestionList: dualQuizQuestionReducer,
            dualQuizMatchUpsList: dualQuizMatchUpsReducer,
            dynamicContent: dynamicContentReducer,
            feedback: feedbackReducer,
            files: filesReducer,
            highlight: highlightReducer,
            interactedWithUserList: interactedWithUsersReducer,
            importSheet: importSheetReducer,
            managedUserList: managedUsersReducer,
            mission: missionReducer,
            missionList: missionListReducer,
            missionManage: missionManageReducer,
            missionRating: missionRatingReducer,
            missionRatingList: missionRatingListReducer,
            myBasilik: myBasilikReducer,
            navigation: navigationReducer,
            notificationList: notificationReducer,
            project: projectReducer,
            projectList: projectListReducer,
            projectListFilters: projectListFiltersReducer,
            rankingAnswers: rankingAnswersReducer,
            rankingExperience: rankingExperienceReducer,
            regularDashboard: regularDashboardReducer,
            report: reportReducer,
            user: userReducer,
            userList: usersReducer,
            validation: validationReducer,
            superAdminDashboard: superAdminDashboardReducer,
        }),
        provideStoreDevtools({
            logOnly: environment.production,
        }),
        provideEffects([
            AlertEffects,
            AssociationEffects,
            AssociationListEffects,
            AssociationRatingListEffects,
            AssociationUsersEffects,
            AwarenessCampaignEffects,
            BadgeEffects,
            ChatEffects,
            CompanyAdminDashboardEffects,
            CompanyCsrApproachEffects,
            CompanyEmployeeEffects,
            CompanyEffects,
            CompanyUsersEffects,
            CourseEffects,
            CourseSectionCommentEffects,
            CompanyFiltersEffects,
            DemonstrationEffects,
            DialogEffects,
            DonationCampaignCustomizationEffects,
            DonationCampaignEffects,
            DonationCampaignListEffects,
            DualQuizChallengeEffects,
            DualQuizChallengesEffects,
            DualQuizMatchUpsEffects,
            DualQuizQuestionEffects,
            DynamicContentEffects,
            FeedbackEffects,
            FilesEffects,
            HighlightEffects,
            ImportSheetEffects,
            InteractedWithUsersEffects,
            ManagedUsersEffects,
            MissionEffects,
            MissionListEffects,
            MissionManageEffects,
            MissionRatingEffects,
            MissionRatingListEffects,
            MyBasilikEffects,
            NavigationEffects,
            NotificationEffects,
            ProjectEffects,
            ProjectListEffects,
            ProjectListFiltersEffects,
            RankingAnswersEffects,
            RankingExperienceEffects,
            RegularDashboardEffects,
            ReportEffects,
            TrackedActivityEffects,
            UserEffects,
            UsersEffects,
            ValidationEffects,
            SuperAdminDashboardEffects,
        ]),
        {
            provide: META_REDUCERS,
            useValue: resetOnLogout,
            multi: true,
        },
    ];
};

function resetOnLogout(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (action.type === UserActions.signOut.type) {
            return reducer(undefined, action);
        }
        return reducer(state, action);
    };
}
