import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AwarenessCampaignService } from 'app/api';
import { TrophyChapterDialogService } from 'app/modules/regular/awareness/courses/trophy-chapter-dialog/trophy-chapter-dialog.service';
import { AwarenessCampaignFormService } from 'app/shared/services/form/awareness-campaign-form.service';
import { AlertActions } from 'app/store/alert/alert.actions';
import { catchError, exhaustMap, map, mergeMap, of } from 'rxjs';
import { AwarenessCampaignActions } from './awareness-campaign.action';

@Injectable()
export class AwarenessCampaignEffects {
    loadCurrentCampaignProgress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AwarenessCampaignActions.loadCurrentCampaignProgressRequest,
                AwarenessCampaignActions.claimRewardRequestSuccess
            ),
            exhaustMap(() => {
                return this.awarenessCampaignService
                    .awarenessCampaignProgressionGet()
                    .pipe(
                        map((thematicsProgress) =>
                            AwarenessCampaignActions.loadCurrentCampaignProgressRequestSuccess(
                                { thematicsProgress }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                AwarenessCampaignActions.loadCurrentCampaignProgressRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    loadActiveCampaign$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AwarenessCampaignActions.loadActiveCampaignRequest,
                AwarenessCampaignActions.createCampaignRequestSuccess,
                AwarenessCampaignActions.updateCampaignRequestSuccess
            ),
            exhaustMap(() => {
                return this.awarenessCampaignService
                    .awarenessCampaignActiveGet()
                    .pipe(
                        map((awarenessCampaign) => {
                            if (awarenessCampaign) {
                                this.formService.updateForm(awarenessCampaign);
                            }
                            return AwarenessCampaignActions.loadActiveCampaignRequestSuccess(
                                { awarenessCampaign }
                            );
                        }),
                        catchError((httpResponse) =>
                            of(
                                AwarenessCampaignActions.loadActiveCampaignRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    loadThematicDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AwarenessCampaignActions.loadThematicDetailsRequest),
            exhaustMap(({ thematic }) => {
                return this.awarenessCampaignService
                    .awarenessCampaignThematicThematicGet({ thematic })
                    .pipe(
                        map((thematicDetails) =>
                            AwarenessCampaignActions.loadThematicDetailsRequestSuccess(
                                { thematicDetails }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                AwarenessCampaignActions.loadThematicDetailsRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    createCampaign$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AwarenessCampaignActions.createCampaignRequest),
            exhaustMap(() => {
                const entity = this.formService.getEntity();
                return this.awarenessCampaignService
                    .awarenessCampaignPost({
                        awarenessCampaignViewModel:
                            entity.awarenessCampaignViewModel,
                    })
                    .pipe(
                        mergeMap(() => {
                            return [
                                AlertActions.displaySuccess({
                                    key: 'awareness-campaign-create',
                                }),
                                AwarenessCampaignActions.createCampaignRequestSuccess(),
                            ];
                        }),
                        catchError((httpResponse) =>
                            of(
                                AwarenessCampaignActions.createCampaignRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    updateCampaign$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AwarenessCampaignActions.updateCampaignRequest),
            exhaustMap(() => {
                const entity = this.formService.getEntity();
                return this.awarenessCampaignService
                    .awarenessCampaignPut({
                        awarenessCampaignViewModel:
                            entity.awarenessCampaignViewModel,
                    })
                    .pipe(
                        mergeMap(() => {
                            return [
                                AlertActions.displaySuccess({
                                    key: 'awareness-campaign-update',
                                }),
                                AwarenessCampaignActions.updateCampaignRequest(),
                            ];
                        }),
                        catchError((httpResponse) =>
                            of(
                                AwarenessCampaignActions.updateCampaignRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    claimTrophyChapterReward$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AwarenessCampaignActions.claimRewardRequest),
            exhaustMap(({ chapterId }) => {
                return this.awarenessCampaignService
                    .awarenessCampaignClaimRewardChapterIdPut({ chapterId })
                    .pipe(
                        map(() => {
                            this.trophyChapterDialogService.open();
                            return AwarenessCampaignActions.claimRewardRequestSuccess();
                        }),
                        catchError((httpResponse) =>
                            of(
                                AwarenessCampaignActions.claimRewardRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private awarenessCampaignService: AwarenessCampaignService,
        private formService: AwarenessCampaignFormService,
        private trophyChapterDialogService: TrophyChapterDialogService
    ) {}
}
