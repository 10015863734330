import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    FormControl,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AuthTemplateComponent } from 'app/shared/components/templates/auth-template/auth-template.component';
import { ErrorPipe } from 'app/shared/pipes/error.pipe';
import { UserActions } from 'app/store/user/user/user.actions';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'linkedin-sign-up-callback',
    templateUrl: './linkedin-sign-up-callback.component.html',
    standalone: true,
    animations: fuseAnimations,
    imports: [
        AuthTemplateComponent,
        LetDirective,
        TranslocoModule,
        FormsModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FuseAlertComponent,
        MatButtonModule,
        AsyncPipe,
        NgIf,
        ErrorPipe,
        BslkClassicButtonComponent,
        MatProgressSpinnerModule,
        RouterLink,
    ],
})
export class LinkedInSignUpCallback implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    isLoading$ = this.store.select(UserSelectors.selectIsLoading);
    needsToLinkProfessionalEmail$ = this.store.select(
        UserSelectors.selectNeedsToLinkProfessionalEmail
    );
    needsToValidateEmail$ = this.store.select(
        UserSelectors.selectNeedsToValidateEmail
    );
    firstName$ = this.store.select(UserSelectors.selectFirstName);
    email$ = this.store.select(UserSelectors.selectEmail);
    emailPlaceholderPrefix$ = this.store.select(
        UserSelectors.selectEmailPlaceholderPrefix
    );
    signUpError$ = this.store.select(UserSelectors.selectSignUpError);
    isSignedIn$ = this.store.select(UserSelectors.selectIsSignedIn);

    professionalEmailFormControl = new FormControl('', [
        Validators.required,
        Validators.email,
    ]);
    code: string;
    state: string;
    isSubmitted = false;

    constructor(private activatedRoute: ActivatedRoute, private store: Store) {}

    ngOnInit() {
        this.activatedRoute.queryParams
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                this.code = params['code'];
                this.state = params['state'];
                this.store.dispatch(
                    UserActions.signUpUserWithLinkedIn({
                        code: this.code,
                        stateGuid: this.state,
                    })
                );
            });
    }

    validateProfessionalEmail() {
        this.isSubmitted = true;
        if (this.professionalEmailFormControl.valid) {
            this.store.dispatch(
                UserActions.signUpUserWithLinkedInWithProfessionalEmailLinked({
                    professionalEmail: this.professionalEmailFormControl.value,
                    stateGuid: this.state,
                })
            );
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
