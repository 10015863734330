import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UserService } from 'app/api';
import {
    createLoadListRequestEffect,
    createReloadEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { CompanyUsersActions } from './company-users.actions';
import { CompanyUsersSelectors } from './company-users.selectors';

@Injectable()
export class CompanyUsersEffects {
    loadUsers$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.userService,
        CompanyUsersActions,
        CompanyUsersSelectors,
        this.userService.userCompanyGet
    );

    reload$ = createReloadEffect(this.actions$, CompanyUsersActions);

    constructor(
        private actions$: Actions,
        private store: Store,
        private userService: UserService
    ) {}
}
