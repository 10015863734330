<div
    (click)="view()"
    [class.flipped]="showCardBack"
    class="flip-card h-96 w-full transition duration-300 hover:scale-[1.03] cursor-pointer"
>
    <div class="flip-card-inner w-full h-full">
        <fuse-card class="flip-card-front flex flex-col w-full h-full">
            <div class="h-2/6 w-full relative">
                <img
                    *ngIf="mission.coverUrl"
                    class="w-full h-full object-cover"
                    [src]="mission.coverUrl"
                    alt="Cover image"
                />
                <div
                    *ngIf="!mission.coverUrl"
                    class="w-full h-full flex border-b"
                >
                    <mat-icon
                        class="icon-size-16 m-auto"
                        [svgIcon]="'heroicons_solid:photo'"
                    >
                    </mat-icon>
                </div>
            </div>
            <div class="flex flex-col h-4/6 w-full px-4 py-1">
                <div class="h-1/4 flex flex-col border-b-2 pt-2">
                    <span
                        [matTooltip]="mission.name"
                        class="font-bold text-lg truncate"
                        >{{ mission.name }}
                    </span>
                    <div class="truncate">
                        <span
                            >{{
                                "missions.card.created-by" | transloco
                            }}&nbsp;</span
                        >
                        <span class="whitespace-nowrap text-primary font-bold"
                            >{{ mission.association.name }}
                        </span>
                    </div>
                </div>
                <div class="h-1/4 flex flex-col justify-center">
                    <div
                        *ngIf="availableSlots.length > 1; else oneOrNoSlot"
                        class="flex justify-between"
                    >
                        <div class="flex flex-col">
                            <span
                                class="font-bold text-warn w-full text-left"
                                >{{ availableSlots.length }}</span
                            >
                            <span
                                class="font-normal text-default w-full text-left"
                                >{{
                                    "missions.card.mission-dates" | transloco
                                }}</span
                            >
                        </div>
                        <button
                            (click)="toggleCardSide($event)"
                            mat-icon-button
                        >
                            <mat-icon
                                [svgIcon]="'heroicons_solid:eye'"
                            ></mat-icon>
                        </button>
                    </div>

                    <ng-template #oneOrNoSlot>
                        <div
                            class="flex flex-col"
                            *ngIf="mission.isDateSelectionVolunteerBased"
                        >
                            <span class="text-warn font-bold">
                                {{
                                    "enums.EDuration." + mission.duration
                                        | transloco
                                }}
                            </span>
                            <span>{{
                                "missions.card.dates-tbd" | transloco
                            }}</span>
                        </div>

                        <div
                            class="flex flex-col"
                            *ngIf="!mission.isDateSelectionVolunteerBased"
                        >
                            <span class="text-warn font-bold">
                                {{
                                    formatDateRange(
                                        availableSlots[0].startDate,
                                        availableSlots[0].endDate
                                    )
                                }}
                            </span>
                            <span>{{
                                "missions.card.mission-dates" | transloco
                            }}</span>
                        </div>
                    </ng-template>
                </div>
                <div class="h-2/4 pt-2 flex flex-col border-t-2">
                    <div class="text-right flex flex-col">
                        <span class="font-bold text-warn text-left mb-1">{{
                            "missions.card.mission-location" | transloco
                        }}</span>
                        <div class="flex items-center justify-between">
                            <div *ngIf="mission.location" class="flex truncate">
                                <mat-icon
                                    class="icon-size-5 mr-1"
                                    [svgIcon]="'heroicons_solid:map-pin'"
                                >
                                </mat-icon>
                                <span class="truncate"
                                    >{{ mission.location.zipCode }}
                                    {{ mission.location.city }}</span
                                >
                            </div>
                            <span
                                *ngIf="mission.isRemote"
                                class="flex truncate"
                            >
                                <mat-icon
                                    class="icon-size-5 mr-1"
                                    [svgIcon]="
                                        'heroicons_solid:computer-desktop'
                                    "
                                >
                                </mat-icon>
                                <span class="truncate">{{
                                    "missions.card.remote" | transloco
                                }}</span>
                            </span>
                        </div>
                    </div>
                    <div
                        *ngIf="!mission?.isTeamBuilding"
                        class="my-auto overflow-y-scroll flex flex-wrap gap-x-2 gap-y-1"
                    >
                        <bslk-tag
                            [background]="'tertiary'"
                            [size]="'small'"
                            *ngFor="let skill of mission.skills"
                            >{{ "enums.ESkill." + skill | transloco }}</bslk-tag
                        >
                    </div>
                    <div
                        *ngIf="mission?.isTeamBuilding"
                        class="my-auto overflow-y-scroll flex flex-wrap gap-x-2 gap-y-1"
                    >
                        <bslk-tag
                            [size]="'small'"
                            [background]="'primary-light'"
                            [textColor]="'primary'"
                            [icon]="'heroicons_outline:user-group'"
                            >{{
                                "missions.card.team-building" | transloco
                            }}</bslk-tag
                        >
                        <bslk-tag
                            [size]="'small'"
                            [background]="'warn-light'"
                            [textColor]="'warn'"
                            [icon]="'heroicons_outline:banknotes'"
                        >
                            <span *ngIf="mission.isPaidTeamBuilding">
                                {{ mission.paidTeamBuildingPricePerPerson
                                }}{{
                                    "enums.ECurrency." +
                                        mission.paidTeamBuildingCurrency
                                        | transloco
                                }}/{{ "missions.card.pers" | transloco }}</span
                            >
                            <span *ngIf="!mission.isPaidTeamBuilding">{{
                                "missions.card.free" | transloco
                            }}</span>
                        </bslk-tag>
                    </div>
                </div>
            </div>
        </fuse-card>

        <fuse-card class="flip-card-back h-full flex flex-col px-4 py-2">
            <div class="flex justify-between items-center">
                <span class="text-lg font-bold text-warn">{{
                    "missions.card.mission-dates" | transloco
                }}</span>
                <button (click)="toggleCardSide($event)" mat-icon-button>
                    <mat-icon
                        [svgIcon]="'heroicons_solid:arrow-uturn-left'"
                    ></mat-icon>
                </button>
            </div>

            <ul class="overflow-y-scroll grow">
                <li *ngFor="let slot of availableSlots">
                    <span class="">{{
                        formatDateRange(slot.startDate, slot.endDate)
                    }}</span>
                </li>
            </ul>
        </fuse-card>
    </div>
</div>
