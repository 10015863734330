import {
    CourseSectionCommentViewModel,
    CourseSectionCommentViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const CourseSectionCommentsActions = createRessourceListActionGroup<
    CourseSectionCommentViewModel,
    CourseSectionCommentViewModelPaginatedListDto
>('CourseSectionComments');
