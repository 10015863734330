import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { getUserSessionFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { of } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = () => {
    const userSession = getUserSessionFromLocalStorage();
    const isLoggedIn =
        userSession && new Date(userSession.expirationDate) > new Date();
    if (!isLoggedIn) {
        return of(true);
    }
    const router: Router = inject(Router);
    return router.parseUrl(`/`);
};
