<div
    class="rounded-full relative transition-all group w-full h-full bg-opacity-30"
    [ngClass]="{
        'hover:scale-150': !disableZooming,
        'ring-4 border-4 ring-white': ringSize === 'large',
        'ring-1 border-1 ring-white': ringSize === 'small',
        'bg-primary border-warn': !hideBackground && background === 'primary',
        'bg-warn border-primary': !hideBackground && background === 'warn',
        'bg-slate-200 border-slate': !hideBackground && background === 'gray',
        'bg-tertiary border-primary':
            !hideBackground && background === 'tertiary',
        'bg-transparent ring-transparent border-transparent': hideBackground
    }"
>
    <my-basile
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all"
        [configuration]="configuration"
    ></my-basile>
</div>
