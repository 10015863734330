import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FeedbackActions = createActionGroup({
    source: 'Feedback',
    events: {
        'Send Feedback Request': emptyProps(),
        'Send Feedback Request Success': emptyProps(),
        'Send Feedback Request Fail': props<{ error: string }>(),

        'Reset Feedback State': emptyProps(),
    },
});
