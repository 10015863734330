import { BslkAdvancedSelectSyncInput } from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { createSelector } from '@ngrx/store';
import { ELoginType, EReward, EUserRole } from 'app/api';
import { OnboardingTask } from 'app/shared/components/onboarding-progress/onboarding-progress.component';
import { getUserLevelInformation } from 'app/shared/utils/experience-points.utils';
import { getLocationRepresentation } from 'app/shared/utils/location-helpers.utils';
import { getRewardValues } from 'app/shared/utils/rewards.utils';
import { EAccountUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { isEmpty, isNil } from 'lodash-es';
import { AppState } from '../..';
import { HighlightActions } from '../../highlight/highlight.action';
import { UserState } from './user.state';

const selectState = (state: AppState) => state.user;

const selectLoginError = createSelector(
    selectState,
    (state) => state.loginError
);

const selectSignUpError = createSelector(
    selectState,
    (state) => state.signUpError
);

const selectUpdatePasswordErrorError = createSelector(
    selectState,
    (state) => state.updatePasswordError
);

const selectAccessToken = createSelector(
    selectState,
    (state: UserState) => state.tokenId
);

const selectBuiltUser = createSelector(
    selectState,
    (state: UserState) => state.builtUser
);

const selectSession = createSelector(
    selectState,
    (state: UserState) => state.sessionInfo
);

const selectUser = createSelector(selectSession, (session) => session?.user);

const selectIsLoggedIn = createSelector(
    selectSession,
    (session) => !isNil(session)
);

const selectUserId = createSelector(selectUser, (user) => user?.id);

const selectDisplayedName = createSelector(
    selectSession,
    (session) => `${session?.user.firstName} ${session?.user.familyName}`
);

const selectLoginType = createSelector(
    selectSession,
    (session) => session?.user?.loginType
);

const selectAllowedFeatures = createSelector(
    selectSession,
    (session) => session?.allowedFeatures
);

const selectIsClassicAccount = createSelector(
    selectLoginType,
    (loginType) => loginType === ELoginType.Classic
);

const selectCompanyName = createSelector(
    selectSession,
    (session) => session?.user.company?.name
);

const selectHasCompany = createSelector(
    selectCompanyName,
    (companyName) => !isEmpty(companyName)
);

const selectCompanyHasAgencies = createSelector(
    selectSession,
    (session) => !isEmpty(session.user?.company?.agencies)
);

const selectCompanyAgencies = createSelector(
    selectCompanyHasAgencies,
    selectSession,
    (hasAgencies, session) => {
        if (!hasAgencies) {
            return [];
        }
        return session.user.company.agencies.map(
            (a) =>
                ({
                    value: a.id,
                    name: getLocationRepresentation(a.location),
                } as BslkAdvancedSelectSyncInput)
        );
    }
);

const selectFirstName = createSelector(
    selectSession,
    (session) => session?.user?.firstName
);

const selectNameFirstLetter = createSelector(selectFirstName, (firstName) =>
    firstName?.charAt(0)
);

const selectEmail = createSelector(
    selectSession,
    (session) => session?.user.email
);

const selectEmailPlaceholderPrefix = createSelector(
    selectSession,
    (session) =>
        session?.user?.firstName?.toLocaleLowerCase() +
        '.' +
        session?.user?.familyName?.toLocaleLowerCase()
);

const selectAvatarUrl = createSelector(
    selectSession,
    // Take basilik profile avatar first, SSO avatar second
    (session) => session?.user?.avatarUrl ?? session?.avatarUrl
);

const selectHasAvatar = createSelector(
    selectAvatarUrl,
    (avatar) => !isEmpty(avatar)
);

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectIsSignedIn = createSelector(
    selectSession,
    (session) =>
        session != null && new Date() < new Date(session.expirationDate)
);

const selectNeedsToValidateEmail = createSelector(
    selectSession,
    (session) => session?.needsToValidateEmail
);

const selectNeedsToLinkProfessionalEmail = createSelector(
    selectSession,
    (session) => session?.needsToLinkProfessionalEmail
);

const selectLoginFailed = createSelector(
    selectState,
    (state: UserState) => state.loginFailed
);

const selectUserRole = createSelector(
    selectSession,
    (session) => session?.user.userRole
);

const selectCanSwitchRole = createSelector(
    selectSession,
    (session) => session?.canSwitchRole
);

const selectIsAssoAdmin = createSelector(
    selectUserRole,
    (userRole) => userRole === EUserRole.AssoAdmin
);

const selectIsSuperAdmin = createSelector(
    selectUserRole,
    (userRole) => userRole === EUserRole.SuperAdmin
);

const selectIsAssoAdminOrSuperAdmin = createSelector(
    selectUserRole,
    (userRole) =>
        userRole === EUserRole.AssoAdmin || userRole === EUserRole.SuperAdmin
);

const selectIsRegular = createSelector(
    selectUserRole,
    (userRole) => userRole === EUserRole.Regular
);

const selectIsExternal = createSelector(
    selectUserRole,
    (userRole) => userRole === EUserRole.External
);

const selectIsCompanyAdmin = createSelector(
    selectUserRole,
    (userRole) => userRole === EUserRole.CompanyAdmin
);

const selectIsManager = createSelector(
    selectSession,
    (session) => session?.isManager
);

const selectIsManagerEmailEmpty = createSelector(selectUser, (user) =>
    isEmpty(user.managerEmail)
);

const selectIsJobTitleEmpty = createSelector(selectUser, (user) =>
    isEmpty(user.jobTitle)
);

const selectIsLoadingReminderConfiguration = createSelector(
    selectState,
    (state) => state.isLoadingReminderConfiguration
);

const selectReminderConfiguration = createSelector(
    selectState,
    (state) => state.reminderConfiguration
);

const selectHasSignature = createSelector(
    selectUser,
    (user) => user.hasSignature
);

const selectSignatureUrl = createSelector(
    selectState,
    (state) => state.signatureUrl
);

const selectIsLoadingSignature = createSelector(
    selectState,
    (state) => state.isLoadingSignature
);

const selectHasGainedRewards = createSelector(
    selectState,
    (state) => state.hasGainedRewards
);

const selectIsCreatingAsso = createSelector(
    selectUser,
    (user) => isNil(user) || isNil(user?.association)
);

const selectHasAssociation = createSelector(
    selectUser,
    (user) => user?.association !== null && user?.association !== undefined
);

const selectAssociationId = createSelector(
    selectUser,
    (user) => user?.association?.id
);

const selectUserTitle = createSelector(
    selectHasAssociation,
    selectHasCompany,
    selectUser,
    (hasAssociation, hasCompany, user) => {
        if (hasCompany) {
            return user.company.name;
        }
        if (hasAssociation) {
            return user.association.name;
        }
        return 'Super Admin';
    }
);

const selectOnboardingTasks = createSelector(selectUser, (user) => {
    const rewardValues = getRewardValues(EReward.ProfileInformationFilled);
    const tasks: OnboardingTask[] = [
        {
            translocoKey: 'job-title',
            completed: !isNil(user.jobTitle),
            rewards: rewardValues,
            action: HighlightActions.createHighlightEvent({
                event: {
                    highlightedElement: 'account-job-title',
                    targetUrl: UrlHelpers.getAccountUrl(
                        EAccountUrl.AccountSettings
                    ),
                },
            }),
        },
        {
            translocoKey: 'signature',
            completed: user.hasSignature,
            rewards: rewardValues,
            action: HighlightActions.createHighlightEvent({
                event: {
                    highlightedElement: 'account-signature',
                    targetUrl: UrlHelpers.getAccountUrl(
                        EAccountUrl.AccountSettings
                    ),
                },
            }),
        },
        {
            translocoKey: 'phone-number',
            completed: !isNil(user.phoneNumber),
            rewards: rewardValues,
            action: HighlightActions.createHighlightEvent({
                event: {
                    highlightedElement: 'account-phone-number',
                    targetUrl: UrlHelpers.getAccountUrl(
                        EAccountUrl.AccountSettings
                    ),
                },
            }),
        },
        {
            translocoKey: 'manager-email',
            completed: !isNil(user.managerEmail),
            rewards: rewardValues,
            action: HighlightActions.createHighlightEvent({
                event: {
                    highlightedElement: 'account-manager-email',
                    targetUrl: UrlHelpers.getAccountUrl(
                        EAccountUrl.AccountSettings
                    ),
                },
            }),
        },
        {
            translocoKey: 'skills',
            completed: !isEmpty(user.skills),
            rewards: rewardValues,
            action: HighlightActions.createHighlightEvent({
                event: {
                    highlightedElement: 'account-skills',
                    targetUrl: UrlHelpers.getAccountUrl(
                        EAccountUrl.AccountSettings
                    ),
                },
            }),
        },
        {
            translocoKey: 'gender',
            completed: !isNil(user.gender),
            rewards: rewardValues,
            action: HighlightActions.createHighlightEvent({
                event: {
                    highlightedElement: 'account-gender',
                    targetUrl: UrlHelpers.getAccountUrl(
                        EAccountUrl.AccountSettings
                    ),
                },
            }),
        },
        {
            translocoKey: 'department',
            completed: !isNil(user.department),
            rewards: rewardValues,
            action: HighlightActions.createHighlightEvent({
                event: {
                    highlightedElement: 'account-department',
                    targetUrl: UrlHelpers.getAccountUrl(
                        EAccountUrl.AccountSettings
                    ),
                },
            }),
        },
    ];

    if (!isEmpty(user.company.agencies)) {
        tasks.push({
            translocoKey: 'agency',
            completed: !isNil(user.companyAgencyId),
            rewards: rewardValues,
            action: HighlightActions.createHighlightEvent({
                event: {
                    highlightedElement: 'account-agency',
                    targetUrl: UrlHelpers.getAccountUrl(
                        EAccountUrl.AccountSettings
                    ),
                },
            }),
        });
    }
    return tasks;
});

const showOnboardingTasks = createSelector(selectOnboardingTasks, (tasks) =>
    tasks.some((t) => !t.completed)
);

const selectUserLevelInformation = createSelector(selectUser, (user) =>
    getUserLevelInformation(user.experiencePoints)
);

export const UserSelectors = {
    selectAccessToken,
    selectAllowedFeatures,
    showOnboardingTasks,
    selectAssociationId,
    selectAvatarUrl,
    selectBuiltUser,
    selectCanSwitchRole,
    selectCompanyAgencies,
    selectCompanyHasAgencies,
    selectCompanyName,
    selectDisplayedName,
    selectEmail,
    selectEmailPlaceholderPrefix,
    selectFirstName,
    selectHasAssociation,
    selectHasAvatar,
    selectHasGainedRewards,
    selectHasSignature,
    selectIsAssoAdmin,
    selectIsAssoAdminOrSuperAdmin,
    selectIsClassicAccount,
    selectIsCompanyAdmin,
    selectIsCreatingAsso,
    selectIsJobTitleEmpty,
    selectIsLoading,
    selectIsLoadingReminderConfiguration,
    selectIsLoadingSignature,
    selectIsManager,
    selectIsManagerEmailEmpty,
    selectIsRegular,
    selectIsExternal,
    selectIsSignedIn,
    selectIsSuperAdmin,
    selectLoginError,
    selectLoginFailed,
    selectLoginType,
    selectNameFirstLetter,
    selectNeedsToLinkProfessionalEmail,
    selectNeedsToValidateEmail,
    selectOnboardingTasks,
    selectReminderConfiguration,
    selectSignatureUrl,
    selectSignUpError,
    selectUpdatePasswordErrorError,
    selectUser,
    selectUserId,
    selectUserLevelInformation,
    selectUserRole,
    selectIsLoggedIn,
    selectUserTitle,
};
