<ng-container *ngrxLet="isGroupSlot$ as isGroupSlot">
    <bslk-classic-card
        [title]="'missionmanage.mission-members' | transloco"
        [isLoading]="isLoading$ | async"
        *ngrxLet="estimatedHours$ as estimatedHours"
    >
        <div
            headerActions
            *ngIf="(isManager$ | async) && (isTeamBuilding$ | async)"
        >
            <div
                [matTooltip]="'missionmanage.is-full' | transloco"
                [matTooltipDisabled]="!(slotIsFull$ | async)"
                (click)="startAddingUsers()"
                class="flex justify-end"
            >
                <button
                    [disabled]="slotIsFull$ | async"
                    class="flex items-center"
                    mat-button
                >
                    <mat-icon
                        class="icon-size-5 text-default"
                        [svgIcon]="'heroicons_solid:plus'"
                    >
                    </mat-icon>
                    <span class="ml-2 font-semibold">{{
                        "missionmanage.add" | transloco
                    }}</span>
                </button>
            </div>
        </div>

        <div class="mb-2" *ngIf="isAddingUsers">
            <single-select-async
                [label]="'missionmanage.my-colleagues' | transloco"
                [selectPlaceholder]="'missionmanage.add-employee' | transloco"
                [searchPlaceholder]="
                    'missionmanage.search-employee' | transloco
                "
                [actions]="CompanyUsersActions"
                [selectors]="CompanyUsersSelectors"
                [control]="addedUserFormControl"
                [excludedIds]="alreadyRegisteredUserIds$ | async"
                [valueIsId]="true"
                [noDataText]="
                    'missionmanage.already-invited-everyone' | transloco
                "
            >
            </single-select-async>
        </div>

        <div *ngIf="hasMembers; else noMembers" class="flex flex-col gap-4">
            <div *ngFor="let member of members">
                <div class="flex items-center">
                    <classic-avatar
                        [size]="14"
                        [textSize]="4"
                        [avatarUrl]="member.user.avatarUrl"
                        [fallbackLetter]="member.user.firstName[0]"
                    >
                        <ng-container *ngIf="isAdmin">
                            <mat-icon
                                *ngIf="member.isAcceptedByAssociation"
                                class="absolute -left-2 -top-2 icon-size-5 text-quarternary-900"
                                [svgIcon]="'heroicons_solid:check-circle'"
                            >
                            </mat-icon>
                            <mat-icon
                                *ngIf="!member.isAcceptedByAssociation"
                                class="absolute -left-2 -top-2 icon-size-5 text-primary-900"
                                [svgIcon]="'heroicons_solid:exclamation-circle'"
                            >
                            </mat-icon>

                            <bslk-round-icon
                                (click)="buildChat(member.user)"
                                [matTooltip]="
                                    'shared.common.contact' | transloco
                                "
                                [size]="'small'"
                                [icon]="
                                    'heroicons_outline:chat-bubble-left-ellipsis'
                                "
                                class="absolute cursor-pointer -right-2 -bottom-2"
                            ></bslk-round-icon>
                        </ng-container>
                    </classic-avatar>
                    <div class="flex flex-col ml-4">
                        <span class="font-bold">{{
                            member.user.firstName + " " + member.user.familyName
                        }}</span>
                        <div class="text-sm">
                            <span *ngIf="member.user.jobTitle">
                                {{ member.user.jobTitle }}
                                {{ "missionmanage.at" | transloco }}
                            </span>
                            <span>{{ member.user.company.name }}</span>
                        </div>
                    </div>
                    <div
                        class="flex grow justify-end"
                        *ngIf="isManager$ | async"
                    >
                        <mat-icon
                            *ngIf="canRemoveUser(member, user$ | async)"
                            (click)="removeUser(member.user.id)"
                            class="transition duration-300 hover:text-warn cursor-pointer"
                            [matTooltip]="'missionmanage.delete' | transloco"
                            [svgIcon]="'heroicons_solid:x-mark'"
                        >
                        </mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #noMembers>
            <div class="flex flex-col">
                <mat-icon
                    class="m-auto"
                    [svgIcon]="'heroicons_outline:face-frown'"
                >
                </mat-icon>
                <span class="text-center">{{
                    "missionmanage.no-members-yet" | transloco
                }}</span>
            </div>
        </ng-template>

        <div *ngIf="isAdmin">
            <div *ngIf="hasPendingAcceptation" class="my-6 border-t"></div>
            <!-- Pending requests -->
            <div *ngIf="hasPendingAcceptation">
                <div class="font-bold text-lg flex items-center">
                    <span>{{
                        "missionmanage.waiting-for-acceptation" | transloco
                    }}</span>
                    <mat-icon
                        [matTooltip]="
                            'missionmanage.waiting-for-acceptation-tooltip'
                                | transloco
                        "
                        class="icon-size-4 text-blue-500 ml-1"
                        [svgIcon]="'heroicons_solid:information-circle'"
                    >
                    </mat-icon>
                </div>
                <mission-member-request
                    (onRequestAnswer)="onAcceptationAnswer($event)"
                    [user]="pendingAcceptation.user"
                    [showActions]="
                        pendingAcceptation.addedByUserId === undefined
                    "
                    *ngFor="let pendingAcceptation of pendingAcceptations"
                >
                </mission-member-request>
            </div>

            <div *ngIf="hasPendingValidation" class="my-6 border-t"></div>

            <!-- Pending validations -->
            <div *ngIf="hasPendingValidation">
                <div class="font-bold text-lg">
                    <span>{{
                        "missionmanage.waiting-for-validation" | transloco
                    }}</span>
                    <mat-icon
                        [matTooltip]="
                            'missionmanage.waiting-for-validation-tooltip'
                                | transloco
                        "
                        class="icon-size-4 text-blue-500 ml-1"
                        [svgIcon]="'heroicons_solid:information-circle'"
                    >
                    </mat-icon>
                </div>
                <mission-member-request
                    (onRequestAnswer)="
                        onValidationAnswer($event, isGroupSlot, estimatedHours)
                    "
                    [user]="pendingValidation.user"
                    *ngFor="let pendingValidation of pendingValidations"
                >
                </mission-member-request>
            </div>
        </div>

        <ng-container *ngrxLet="isPaidTeamBuilding$ as isPaidTeamBuilding">
            <div
                class="flex flex-wrap justify-center gap-2 my-3"
                *ngIf="isTeamBuilding$ | async"
            >
                <bslk-tag
                    [size]="'small'"
                    [textColor]="'primary'"
                    [background]="'primary-light'"
                >
                    {{ spotsLeft$ | async }}
                    {{ "missionmanage.spots-left" | transloco }}
                </bslk-tag>
                <ng-container *ngrxLet="minSpotsToFill$ as minSpotsToFill">
                    <bslk-tag
                        *ngIf="isPaidTeamBuilding && minSpotsToFill > 0"
                        [size]="'small'"
                        [textColor]="'warn'"
                        [background]="'warn-light'"
                    >
                        {{ minSpotsToFill }}
                        {{ "missionmanage.min-spots-to-fill" | transloco }}
                    </bslk-tag>
                </ng-container>
            </div>
            <div
                class="flex gap-1 items-center mt-2"
                *ngIf="isPaidTeamBuilding"
            >
                <mat-icon [svgIcon]="'heroicons_outline:banknotes'"></mat-icon>
                {{ "missionmanage.cost" | transloco }}
                <mat-icon
                    class="icon-size-3 text-current"
                    [svgIcon]="'heroicons_outline:arrow-right'"
                ></mat-icon>
                <span class="font-bold">
                    {{ paidTeamBuildingPrice$ | async
                    }}{{
                        "enums.ECurrency." + (paidTeamBuildingCurrency$ | async)
                            | transloco
                    }}
                </span>
            </div>
        </ng-container>
    </bslk-classic-card>
</ng-container>
