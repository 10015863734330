import { createSelector } from '@ngrx/store';
import { ENotificationType, UserNotificationViewModel } from 'app/api';
import {
    EAssociationUrl,
    ERegularUrl,
    ESharedUrl,
    UrlHelpers,
} from 'app/shared/utils/url-helpers.utils';
import { AppState } from '..';

const selectState = (state: AppState) => state.notificationList;

const selectNotifications = createSelector(selectState, (state) => state.data);

const selectNotificationsRich = createSelector(
    selectNotifications,
    (notifications) =>
        notifications.map((notification) => ({
            ...notification,
            icon: getIconFromNotification(notification),
            path: getPathFromNotification(notification),
            variables: reduceVariables(notification),
        }))
);

const reduceVariables = (userNotification: UserNotificationViewModel) =>
    userNotification.notification.variables?.reduce((dict, variable) => {
        if (variable.key !== undefined) {
            dict[variable.key] = variable.value;
        }
        return dict;
    }, {});

const getIconFromNotification = (
    userNotification: UserNotificationViewModel
) => {
    if (userNotification.notification.iconUrl) {
        return null;
    }

    switch (userNotification.notification.type) {
        case ENotificationType.RegularAddedToTeamBuilding:
            return 'heroicons_outline:user-group';
        case ENotificationType.FirstConnection:
            return 'heroicons_outline:face-smile';
        case ENotificationType.CallForFeedback:
            return 'heroicons_outline:chat-bubble-left';
        case ENotificationType.TeamRegisteredToTeamBuildingForManager:
        case ENotificationType.UserRegisteredMissionForManager:
            return 'mat_outline:approval';
        case ENotificationType.TeamRegisteredToTeamBuildingForAssoAdmin:
        case ENotificationType.UserRegisteredMissionForAssociation:
            return 'heroicons_outline:user-plus';
        case ENotificationType.RegularCanceledMission:
            return 'heroicons_outline:user-minus';
        case ENotificationType.RegularValidatedMission:
            return 'heroicons_outline:check';
        case ENotificationType.AssoAdminAcceptedRegular:
        case ENotificationType.ManagerAcceptedRegular:
            return 'heroicons_outline:face-smile';
        case ENotificationType.ManagerDeniedRegular:
        case ENotificationType.AssoAdminDeniedRegular:
        case ENotificationType.RegularRemovedFromTeamBuilding:
            return 'heroicons_outline:face-frown';
        case ENotificationType.RegularChangedEstimation:
        case ENotificationType.AssoAdminChangedEstimation:
            return 'heroicons_outline:clock';
        case ENotificationType.AssoAdminValidatedMission:
            return 'heroicons_outline:check';
        case ENotificationType.AssoAdminDeniedValidationMission:
            return 'heroicons_outline:no-symbol';
        case ENotificationType.ChallengedToDualQuiz:
        case ENotificationType.ChallengeeCompletedDualQuiz:
            return 'heroicons_outline:question-mark-circle';
    }
};

const getPathFromNotification = (
    userNotification: UserNotificationViewModel
) => {
    const missionId = userNotification.notification.variables.find(
        (n) => n.key === 'missionId'
    )?.value;
    const missionSlotId = userNotification.notification.variables.find(
        (n) => n.key === 'missionSlotId'
    )?.value;
    const dualQuizChallengeId = userNotification.notification.variables.find(
        (n) => n.key === 'dualQuizChallengeId'
    )?.value;
    switch (userNotification.notification.type) {
        case ENotificationType.UserRegisteredMissionForAssociation:
        case ENotificationType.RegularCanceledMission:
        case ENotificationType.RegularValidatedMission:
        case ENotificationType.RegularChangedEstimation:
        case ENotificationType.TeamRegisteredToTeamBuildingForAssoAdmin:
            return UrlHelpers.getAssociationUrl(
                EAssociationUrl.AssociationMissionManagePage,
                {
                    id: missionId,
                    missionSlotId,
                }
            );
        case ENotificationType.ManagerAcceptedTeamBuilding:
        case ENotificationType.AssoAdminAcceptedRegular:
        case ENotificationType.AssoAdminChangedEstimation:
        case ENotificationType.AssoAdminDeniedValidationMission:
        case ENotificationType.AssoAdminValidatedMission:
            return UrlHelpers.getRegularUrl(
                ERegularUrl.RegularMissionManagePage,
                {
                    id: missionId,
                }
            );
        case ENotificationType.RegularRemovedFromTeamBuilding:
        case ENotificationType.RegularAddedToTeamBuilding:
        case ENotificationType.ManagerDeniedRegular:
        case ENotificationType.AssoAdminDeniedRegular:
            return UrlHelpers.getRegularUrl(ERegularUrl.RegularMissionPage, {
                id: missionId,
            });
        case ENotificationType.UserRegisteredMissionForManager:
        case ENotificationType.TeamRegisteredToTeamBuildingForManager:
            return UrlHelpers.getRegularUrl(ERegularUrl.RegularDashboardPage);
        case ENotificationType.ChallengedToDualQuiz:
            return UrlHelpers.getSharedUrl(
                ESharedUrl.SharedDualQuizChallengePage,
                { id: dualQuizChallengeId }
            );
        case ENotificationType.ChallengeeCompletedDualQuiz:
            return UrlHelpers.getSharedUrl(
                ESharedUrl.SharedDualQuizChallengeResultsPage,
                { id: dualQuizChallengeId }
            );
        default:
            return null;
    }
};

const selectUnreadNotificationCount = createSelector(
    selectNotifications,
    (notifications) =>
        notifications.filter((notification) => !notification.isAcknowledged)
            .length
);

const selectHasNotification = createSelector(
    selectNotifications,
    (notifications) => notifications !== null && notifications.length > 0
);

export const NotificationSelectors = {
    selectNotifications,
    selectUnreadNotificationCount,
    selectHasNotification,
    selectNotificationsRich,
};
