<div *ngIf="text">
    <div class="relative w-fit" [ngSwitch]="headingType">
        <h1
            class="text-2xl font-bold tracking-tight text-warn"
            *ngSwitchCase="'h1'"
        >
            {{ text }}
        </h1>
        <h2
            class="text-xl font-bold tracking-tight text-warn"
            *ngSwitchCase="'h2'"
        >
            {{ text }}
        </h2>
        <h3
            class="text-lg font-bold tracking-tight text-warn"
            *ngSwitchCase="'h3'"
        >
            {{ text }}
        </h3>
    </div>
</div>
