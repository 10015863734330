import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DynamicContentService } from 'app/api';
import { catchError, exhaustMap, filter, map, of } from 'rxjs';
import { DynamicContentActions } from './dynamic-content.action';
import { DynamicContentSelectors } from './dynamic-content.selectors';

@Injectable()
export class DynamicContentEffects {
    loadGoodNewsOfTheWeekIfNeeded = createEffect(() =>
        this.actions$.pipe(
            ofType(DynamicContentActions.loadGoodNewsOfTheWeekIfNeeded),
            concatLatestFrom(() =>
                this.store.select(
                    DynamicContentSelectors.selectIsGoodNewsOfTheWeekLoaded
                )
            ),
            filter(([, isLoaded]) => !isLoaded),
            map(() => DynamicContentActions.loadGoodNewsOfTheWeekRequest())
        )
    );

    loadGoodNewsOfTheWeek$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DynamicContentActions.loadGoodNewsOfTheWeekRequest),
            exhaustMap(() => {
                return this.dynamicContentService
                    .dynamicContentGoodNewsWeekGet()
                    .pipe(
                        map((response) =>
                            DynamicContentActions.loadGoodNewsOfTheWeekRequestSuccess(
                                { response }
                            )
                        ),
                        catchError((httpResponse) =>
                            of(
                                DynamicContentActions.loadGoodNewsOfTheWeekRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private dynamicContentService: DynamicContentService
    ) {}
}
