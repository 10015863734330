/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ETrackedActivityType = 'MissionFilterByActivityField' | 'MissionFilterByDuration' | 'MissionFilterByEndDate' | 'MissionFilterByLocation' | 'MissionFilterBySkill' | 'MissionFilterByStartDate' | 'MissionSwitchToGridView' | 'MissionSwitchToRowView' | 'MissionManageEditTasks' | 'OpenMessages' | 'OpenNotifications' | 'SignOut' | 'ViewAssociationPage' | 'ViewAvatarPage' | 'ViewDashboardPage' | 'ViewManageMissionPage' | 'ViewMissionsPage' | 'ViewProfilePage' | 'ViewSingleMissionPage' | 'DonationGiftLink' | 'DonationGiftOpen' | 'DonationGiftProjectView' | 'DonationGiftChooseDialog' | 'DonationGiftChooseRequest';

export const ETrackedActivityType = {
    MissionFilterByActivityField: 'MissionFilterByActivityField' as ETrackedActivityType,
    MissionFilterByDuration: 'MissionFilterByDuration' as ETrackedActivityType,
    MissionFilterByEndDate: 'MissionFilterByEndDate' as ETrackedActivityType,
    MissionFilterByLocation: 'MissionFilterByLocation' as ETrackedActivityType,
    MissionFilterBySkill: 'MissionFilterBySkill' as ETrackedActivityType,
    MissionFilterByStartDate: 'MissionFilterByStartDate' as ETrackedActivityType,
    MissionSwitchToGridView: 'MissionSwitchToGridView' as ETrackedActivityType,
    MissionSwitchToRowView: 'MissionSwitchToRowView' as ETrackedActivityType,
    MissionManageEditTasks: 'MissionManageEditTasks' as ETrackedActivityType,
    OpenMessages: 'OpenMessages' as ETrackedActivityType,
    OpenNotifications: 'OpenNotifications' as ETrackedActivityType,
    SignOut: 'SignOut' as ETrackedActivityType,
    ViewAssociationPage: 'ViewAssociationPage' as ETrackedActivityType,
    ViewAvatarPage: 'ViewAvatarPage' as ETrackedActivityType,
    ViewDashboardPage: 'ViewDashboardPage' as ETrackedActivityType,
    ViewManageMissionPage: 'ViewManageMissionPage' as ETrackedActivityType,
    ViewMissionsPage: 'ViewMissionsPage' as ETrackedActivityType,
    ViewProfilePage: 'ViewProfilePage' as ETrackedActivityType,
    ViewSingleMissionPage: 'ViewSingleMissionPage' as ETrackedActivityType,
    DonationGiftLink: 'DonationGiftLink' as ETrackedActivityType,
    DonationGiftOpen: 'DonationGiftOpen' as ETrackedActivityType,
    DonationGiftProjectView: 'DonationGiftProjectView' as ETrackedActivityType,
    DonationGiftChooseDialog: 'DonationGiftChooseDialog' as ETrackedActivityType,
    DonationGiftChooseRequest: 'DonationGiftChooseRequest' as ETrackedActivityType
};

