import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AvatarAssetViewModel } from 'app/api/model/avatarAssetViewModel';
import { BasileConfigurationViewModel } from 'app/api/model/basileConfigurationViewModel';
import { EAvatarAssetType } from 'app/api/model/eAvatarAssetType';

export const MyBasileActions = createActionGroup({
    source: 'MyBasile',
    events: {
        'Load Configuration Request': emptyProps(),
        'Load Configuration Request Success': props<{
            configuration: BasileConfigurationViewModel;
        }>(),
        'Load Configuration Request Fail': props<{ error: string }>(),

        'Load Assets Request': emptyProps(),
        'Load Assets Request Success': props<{
            assets: AvatarAssetViewModel[];
        }>(),
        'Load Assets Request Fail': props<{ error: string }>(),

        'Equip Asset Request': props<{ asset: AvatarAssetViewModel }>(),
        'Equip Asset Request Success': emptyProps(),
        'Equip Asset Request Fail': props<{ error: string }>(),

        'Unequip Asset Request': props<{ assetType: EAvatarAssetType }>(),
        'Unequip Asset Request Success': emptyProps(),
        'Unequip Asset Request Fail': props<{ error: string }>(),

        'Buy Asset Request': props<{ asset: AvatarAssetViewModel }>(),
        'Buy Asset Request Success': props<{ asset: AvatarAssetViewModel }>(),
        'Buy Asset Request Fail': props<{ error: string }>(),
    },
});
