import { createReducer, on } from '@ngrx/store';
import { UserNotificationViewModel } from 'app/api';
import { ressourceListInitialState } from '../shared/ressource-list/ressource-list-state';
import { NotificationActions } from './notification.actions';
import { NotificationState } from './notification.state';

export const initialState: NotificationState = ressourceListInitialState;

export const notificationReducer = createReducer(
    initialState,

    on(NotificationActions.loadNotificationsRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),
    on(
        NotificationActions.loadNotificationsRequestSuccess,
        (state, { response }) => {
            return {
                ...state,
                isLoading: false,
                data: response.data,
                pageIndex: response.pageIndex,
                totalPages: response.totalPages,
            };
        }
    ),
    on(NotificationActions.loadNotificationsRequestFailure, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),
    on(NotificationActions.markAllAsRead, (state) => {
        return {
            ...state,
            data: state.data?.map(
                (userNotification) =>
                    ({
                        ...userNotification,
                        isAcknowledged: true,
                    } as UserNotificationViewModel)
            ),
        };
    }),
    on(NotificationActions.deleteNotification, (state, { id }) => {
        return {
            ...state,
            data: state.data?.filter(
                (userNotification) => userNotification.id !== id
            ),
        };
    })
);
