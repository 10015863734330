import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FooterComponent } from 'app/shared/components/footer/footer.component';

@Component({
    selector: 'not-found',
    templateUrl: './not-found.component.html',
    standalone: true,
    imports: [FooterComponent, TranslocoModule, BslkTagButtonComponent],
})
export class NotFoundComponent {
    constructor(private router: Router) {}

    goBack() {
        this.router.navigateByUrl('/');
    }
}
