<div
    *ngrxLet="isLoading$ as isLoading"
    class="w-full bg-primary bg-opacity-20 rounded-xl py-4 px-6"
>
    <div class="flex justify-between items-center mb-4">
        <div class="flex flex-col xxl:flex-row xxl:items-center truncate gap-1">
            <div class="text-2xl font-bold truncate xxl:inline hidden">
                {{ "courses.ranking.title" | transloco }}
            </div>
            <div class="text-lg">
                <span
                    ><span class="hidden xxl:inline">&nbsp;-</span>
                    {{ "courses.ranking.you-are" | transloco }}
                    <span class="font-bold"
                        >#{{ userRank$ | async }}</span
                    ></span
                >
            </div>
        </div>
        <bslk-classic-toggle
            (valueChange)="rankingTypeChange($event)"
            [color]="'primary'"
            [size]="'very-small'"
            [value]="rankingBy"
            [choices]="[
                {
                    text: 'courses.ranking.levels' | transloco,
                    value: 'level'
                },
                {
                    text: 'courses.ranking.questions' | transloco,
                    value: 'answers'
                }
            ]"
        ></bslk-classic-toggle>
    </div>

    <div
        *ngIf="!isLoading; else loading"
        class="grid grid-cols-3 gap-x-8 justify-between items-end"
    >
        <div
            class="bg-gradient-to-t from-primary-300 to-transparent rounded-xl flex flex-col justify-between items-center mt-10"
            *ngrxLet="second$ as second"
        >
            <div
                *ngIf="second"
                class="-mt-10 flex flex-col items-center gap-3 pb-10 xl:pb-16"
            >
                <basile-avatar
                    [background]="'primary'"
                    class="flex flex-shrink-0 w-20 h-20"
                    [configuration]="second.basileConfiguration"
                ></basile-avatar>
                <div class="text-center">{{ second.user.name }}</div>
                <div
                    class="px-4 py-2 bg-tertiary bg-opacity-20 w-fit rounded-xl flex"
                >
                    <span
                        class="text-3xl font-extrabold uppercase text-center text-transparent bg-clip-text bg-gradient-to-r to-primary-600 from-primary-800"
                        >{{
                            rankingBy === "level"
                                ? (second.metric | level)
                                : second.metric
                        }}</span
                    >
                </div>
            </div>
            <mat-icon
                class="text-gray-400 mb-3"
                [svgIcon]="'heroicons_solid:trophy'"
            ></mat-icon>
        </div>
        <div
            class="bg-gradient-to-t from-tertiary-300 to-transparent rounded-xl flex flex-col justify-around items-center mt-10"
            *ngrxLet="first$ as first"
        >
            <div class="-mt-10 flex flex-col items-center gap-3 pb-20 xl:pb-26">
                <basile-avatar
                    [background]="'tertiary'"
                    class="flex flex-shrink-0 w-20 h-20"
                    [configuration]="first.basileConfiguration"
                ></basile-avatar>
                <div class="text-center">{{ first.user.name }}</div>
                <div
                    class="px-4 py-2 bg-tertiary bg-opacity-20 w-fit rounded-xl flex"
                >
                    <span
                        class="text-3xl font-extrabold uppercase text-center text-transparent bg-clip-text bg-gradient-to-r to-tertiary-600 from-tertiary-800"
                        >{{
                            rankingBy === "level"
                                ? (first.metric | level)
                                : first.metric
                        }}</span
                    >
                </div>
            </div>
            <mat-icon
                class="text-yellow-600 mb-3"
                [svgIcon]="'heroicons_solid:trophy'"
            ></mat-icon>
        </div>
        <div
            class="bg-gradient-to-t from-warn-300 to-transparent rounded-xl flex flex-col justify-between items-center mt-10"
            *ngrxLet="third$ as third"
        >
            <div
                *ngIf="third"
                class="-mt-10 flex flex-col items-center gap-3 pb-6"
            >
                <basile-avatar
                    [background]="'warn'"
                    class="flex flex-shrink-0 w-20 h-20"
                    [configuration]="third.basileConfiguration"
                ></basile-avatar>
                <div class="text-center">{{ third.user.name }}</div>
                <div
                    class="px-4 py-2 bg-tertiary bg-opacity-20 w-fit rounded-xl flex"
                >
                    <span
                        class="text-3xl font-extrabold uppercase text-center text-transparent bg-clip-text bg-gradient-to-r to-warn-600 from-warn-800"
                        >{{
                            rankingBy === "level"
                                ? (third.metric | level)
                                : third.metric
                        }}</span
                    >
                </div>
            </div>
            <mat-icon
                class="text-amber-800 mb-3"
                [svgIcon]="'heroicons_solid:trophy'"
            ></mat-icon>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="grid grid-cols-3 gap-x-8 justify-between items-end">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full flex h-48"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full flex h-60"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full flex h-36"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
