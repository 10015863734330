<div class="flex flex-col w-full">
    <div class="p-4 md:p-6 xxl:p-8 w-full grow flex flex-col gap-8">
        <div *ngIf="showFilters" class="flex flex-col gap-5">
            <div class="flex">
                <ng-content></ng-content>
            </div>

            <project-list-filters></project-list-filters>
        </div>

        <div
            *ngIf="!(isLoading$ | async); else loading"
            [ngClass]="{
                'max-w-screen-md':
                    projects?.length === 2 || projects?.length === 1,
                'lg:grid-cols-3': projects?.length >= 3,
                'xl:grid-cols-4': !projects || projects.length >= 4
            }"
            class="gap-4 lg:gap-8 grid grid-cols-1 md:grid-cols-2 flex-wrap justify-center mx-auto"
        >
            <project-card
                (projectSelected)="onProjectSelected($event)"
                (projectUnselected)="onProjectUnselected($event)"
                (projectChosen)="onProjectChosen($event)"
                *ngFor="let project of projectsAsync | async"
                [project]="project"
                [canSelect]="canSelect"
                [canChoose]="canChoose"
                [isSelected]="selectedProjectIds.includes(project.id)"
            ></project-card>
        </div>
    </div>

    <mat-paginator
        *ngIf="isSourceAsync && (totalPages$ | async) > 1"
        class="bg-white w-full"
        [ngClass]="{ 'pointer-events-none': isLoading$ | async }"
        [length]="totalCount$ | async"
        [pageIndex]="pageIndex$ | async"
        [pageSize]="pageSize$ | async"
        [hidePageSize]="true"
        (page)="pageChangeEvent($event)"
        [showFirstLastButtons]="true"
    >
    </mat-paginator>
</div>

<ng-template #loading>
    <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8"
    >
        <div
            class="rounded-2xl shadow-lg h-96 overflow-hidden"
            *ngFor="let _ of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
        >
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full h-full"
            ></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
