import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AssociationJoinRequestService, UserService } from 'app/api';
import { catchError, exhaustMap, map, mergeMap, of } from 'rxjs';
import { AlertActions } from '../../alert/alert.actions';
import {
    createLoadListRequestEffect,
    createReloadEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { AssociationUsersActions } from './association-users.actions';
import { AssociationUsersSelectors } from './association-users.selectors';

@Injectable()
export class AssociationUsersEffects {
    loadUsers$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.userService,
        AssociationUsersActions,
        AssociationUsersSelectors,
        this.userService.userAssociationGet
    );

    reload$ = createReloadEffect(this.actions$, AssociationUsersActions);

    answerJoinRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationUsersActions.AnswerJoinRequest),
            exhaustMap(({ id, accepted }) => {
                return this.associationJoinRequestService
                    .associationJoinRequestPut({
                        associationJoinRequestViewModel: {
                            id,
                            accepted,
                        },
                    })
                    .pipe(
                        map(() =>
                            AssociationUsersActions.AnswerJoinRequestSuccess()
                        ),
                        catchError(() =>
                            of(AssociationUsersActions.AnswerJoinRequestFail())
                        )
                    );
            })
        )
    );

    reloadAfterJoinRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationUsersActions.AnswerJoinRequestSuccess),
            mergeMap(() => [
                AssociationUsersActions.LoadListRequest(),
                AlertActions.displaySuccess({ key: 'join-request-success' }),
            ])
        )
    );

    joinRequestError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AssociationUsersActions.AnswerJoinRequestFail),
            map(() => AlertActions.displayGenericError())
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private userService: UserService,
        private associationJoinRequestService: AssociationJoinRequestService
    ) {}
}
