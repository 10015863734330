<div class="flex flex-col gap-y-2">
    <div *ngFor="let block of blocks; let last = last">
        <div [ngSwitch]="block.blockType">
            <bslk-heading
                *ngSwitchCase="EBlockType.HeadingOne"
                [block]="block"
            ></bslk-heading>
            <bslk-heading
                *ngSwitchCase="EBlockType.HeadingTwo"
                [block]="block"
            ></bslk-heading>
            <bslk-heading
                *ngSwitchCase="EBlockType.HeadingThree"
                [block]="block"
            ></bslk-heading>
            <bslk-paragraph
                *ngSwitchCase="EBlockType.Paragraph"
                [block]="block"
            ></bslk-paragraph>
            <bslk-image
                *ngSwitchCase="EBlockType.Image"
                [block]="block"
            ></bslk-image>
            <bslk-quote
                *ngSwitchCase="EBlockType.Quote"
                [block]="block"
            ></bslk-quote>
            <bslk-link-preview
                *ngSwitchCase="EBlockType.LinkPreview"
                [block]="block"
            ></bslk-link-preview>
            <bslk-bullet-list-item
                *ngSwitchCase="EBlockType.BulletListItem"
                [block]="block"
            >
            </bslk-bullet-list-item>
        </div>
    </div>
</div>
