<div class="p-8 flex flex-col items-center h-full w-full gap-4 relative">
    <div class="flex flex-col gap-1">
        <basile-avatar
            class="flex flex-shrink-0 w-40 h-40"
            [configuration]="basileConfiguration"
        ></basile-avatar>
        <span class="text-center font-bold text-lg">{{ name }}</span>
    </div>

    <div *ngIf="isDone" class="flex flex-col w-full grow gap-6">
        <!-- Statistics -->
        <div class="flex flex-col gap-2">
            <span class="font-bold">{{
                "dualquiz.statistics" | transloco
            }}</span>
            <div class="flex flex-col gap-1">
                <div class="flex justify-between">
                    <div class="flex items-center gap-1">
                        <mat-icon
                            class="text-current icon-size-4"
                            [svgIcon]="'heroicons_outline:check'"
                        ></mat-icon>
                        <div>{{ "dualquiz.correct-answers" | transloco }}</div>
                    </div>
                    <span class="font-bold"
                        >{{ correctAnswersCount }} /
                        {{ challenge.questions.length }}</span
                    >
                </div>
                <div class="flex justify-between">
                    <div class="flex items-center gap-1">
                        <mat-icon
                            class="text-current icon-size-4"
                            [svgIcon]="'feather:percent'"
                        ></mat-icon>
                        <div>
                            {{ "dualquiz.correct-answers-rate" | transloco }}
                        </div>
                    </div>
                    <span class="font-bold"
                        >{{ Math.round(correctAnswersPercentage) }}%</span
                    >
                </div>
            </div>
        </div>

        <!-- Answer details -->
        <div class="flex flex-col gap-2">
            <bslk-info-label
                [tooltip]="'dualquiz.answer-details-tooltip' | transloco"
                ><span class="font-bold">{{
                    "dualquiz.answer-details" | transloco
                }}</span></bslk-info-label
            >
            <div class="grid grid-cols-4 md:grid-cols-5 gap-y-2">
                <div
                    *ngFor="let question of challenge.questions; let i = index"
                    class="col-span-1 flex flex-col border min-w-16"
                >
                    <div
                        [matTooltip]="question.question"
                        class="p-2 font-bold text-center"
                    >
                        {{ i + 1 }}
                    </div>
                    <div class="border-t flex items-center justify-center p-2">
                        <mat-icon
                            *ngIf="answers[i]"
                            class="text-tertiary"
                            [svgIcon]="'heroicons_outline:check'"
                        ></mat-icon>
                        <mat-icon
                            *ngIf="!answers[i]"
                            class="text-primary"
                            [svgIcon]="'heroicons_outline:x-mark'"
                        ></mat-icon>
                    </div>
                </div>
            </div>
        </div>

        <!-- Rewards -->
        <div class="flex flex-col gap-2">
            <span class="font-bold">{{ "dualquiz.rewards" | transloco }}</span>
            <div
                *ngIf="!challenge.result"
                class="flex justify-center items-center gap-1"
            >
                <mat-icon
                    [svgIcon]="'mat_outline:hourglass_empty'"
                    class="text-current"
                ></mat-icon>
                <span class="flex flex-col">
                    {{ "dualquiz.waiting-opponent" | transloco }}
                </span>
            </div>

            <div *ngIf="challenge.result" class="flex flex-col gap-1">
                <div class="flex justify-between">
                    <div class="flex items-center gap-1">
                        <mat-icon
                            class="text-tertiary icon-size-4"
                            [svgIcon]="'basile-flat'"
                        ></mat-icon>
                        <div>
                            {{ "dualquiz.experience-points" | transloco }}
                        </div>
                    </div>
                    <span class="font-bold">{{
                        rewards.experiencePoints
                    }}</span>
                </div>
                <div class="flex justify-between">
                    <div class="flex items-center gap-1">
                        <mat-icon
                            class="text-warn icon-size-4"
                            [svgIcon]="'bslk_core:purpose-coins'"
                        ></mat-icon>
                        <div>
                            {{ "dualquiz.purpose-coins" | transloco }}
                        </div>
                    </div>
                    <span class="font-bold">{{ rewards.purposeCoins }}</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isDone" class="grow flex flex-col justify-center">
        <div class="flex justify-center">
            <div
                class="flex items-center gap-2 border rounded-lg border-warn px-4 py-2"
            >
                <div class="rounded-full bg-warn h-5 w-5 animate-pulse"></div>
                <span class="uppercase font-bold text-lg">{{
                    "dualquiz.waiting" | transloco
                }}</span>
            </div>
        </div>
    </div>

    <!-- Fireworks -->
    <div
        *ngIf="hasWon"
        class="absolute w-full h-full overflow-hidden pointer-events-none pyro"
    >
        <div class="before"></div>
        <div class="after"></div>
    </div>
</div>
