import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeedbackViewModel } from 'app/api';
import { IFormService } from 'app/shared/services/form/form-service';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';

@Injectable({
    providedIn: 'root',
})
export class FeedbackFormService implements IFormService<FeedbackViewModel> {
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder) {
        this.initForm();
    }

    get getFormGroup() {
        return this.formGroup;
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            text: ['', Validators.required],
            type: [null],
            rating: [null, Validators.required],
            fileAsBase64: [null],
        });
        return this.formGroup;
    }

    updateForm(entity: FeedbackViewModel) {
        this.formGroup.patchValue(entity);
    }

    getEntity(): { [key: string]: FeedbackViewModel } {
        const feedbackViewModel = returnOnlyNonNullFields<FeedbackViewModel>(
            this.formGroup.value
        ) as FeedbackViewModel;

        return { feedbackViewModel };
    }
}
