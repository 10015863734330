<div [class.min-h-[1em]]="!isDynamicHeight">
    <span
        *ngIf="text"
        class="text-sm"
        [class.text-primary]="textLength > maxLength"
        >{{ textLength }}/{{ maxLength }}
        {{ "bslk.length-info.allowed-characters" | transloco }}
        <span *ngIf="minLength">
            ({{ minLength }}
            {{ "bslk.length-info.min-characters" | transloco }})</span
        >
    </span>
</div>
