<div class="min-h-screen min-w-screen flex flex-col">
    <div
        *ngIf="!(isLoadingRequest$ | async); else loading"
        class="grow flex flex-col items-center justify-center"
    >
        <div
            class="flex flex-col bg-white items-center my-4 py-4 md:p-4 rounded-2xl shadow-lg"
        >
            <donation-certificate
                class="z-10"
                (imageCapture)="onImageCapture($event)"
                *ngIf="isRequestLoaded$ | async"
                [hideActions]="true"
                [triggerCapture]="triggerCapture"
            ></donation-certificate>

            <div
                *ngIf="!(hasShared$ | async); else hasShared"
                class="flex flex-col mt-3 gap-3 w-full px-4"
            >
                <div class="flex flex-col">
                    <mat-form-field [subscriptSizing]="'dynamic'">
                        <mat-label>{{
                            "donationgift.optional-text" | transloco
                        }}</mat-label>
                        <textarea matInput [(ngModel)]="postText"></textarea>
                    </mat-form-field>
                    <bslk-length-info
                        [text]="postText"
                        [maxLength]="1500"
                    ></bslk-length-info>
                </div>

                <bslk-tag-button
                    (onClick)="post()"
                    [isLoading]="isLoadingShare$ | async"
                    class="self-end mt-3"
                    [icon]="'heroicons_outline:paper-airplane'"
                    >{{ "donationgift.post" | transloco }}</bslk-tag-button
                >
            </div>
        </div>
    </div>
    <bslk-footer></bslk-footer>
</div>

<ng-template #hasShared>
    <div
        *ngrxLet="hasFailedToShare$ as hasFailedToShare"
        class="mt-4 w-full md:max-w-120"
    >
        <fuse-alert *ngIf="!hasFailedToShare" type="info">
            {{ "donationgift.certificate-shared" | transloco }}</fuse-alert
        >

        <fuse-alert *ngIf="hasFailedToShare" type="error">
            {{ "donationgift.share-failed" | transloco }}</fuse-alert
        >
    </div></ng-template
>

<ng-template #loading>
    <div class="grow flex flex-col items-center justify-center">
        <mat-spinner diameter="90"></mat-spinner>
    </div>
</ng-template>
