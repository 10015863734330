import { on } from '@ngrx/store';
import { createRessourceListReducer } from 'app/store/shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from 'app/store/shared/ressource-list/ressource-list-state';
import { DualQuizChallengesActions } from './challenges.action';
import { DualQuizChallengesState } from './challenges.state';

export const dualQuizChallengesInitialState: DualQuizChallengesState =
    ressourceListInitialState;

const additionalActions = [
    on(
        DualQuizChallengesActions.NewChallengeRequest,
        (state: DualQuizChallengesState) => {
            return {
                ...state,
                isLoading: true,
            };
        }
    ),
    on(
        DualQuizChallengesActions.NewChallengeRequestSuccess,
        (state: DualQuizChallengesState) => {
            return {
                ...state,
                isLoading: false,
            };
        }
    ),
    on(
        DualQuizChallengesActions.NewChallengeRequestFail,
        (state: DualQuizChallengesState) => {
            return {
                ...state,
                isLoading: false,
            };
        }
    ),
];

export const dualQuizChallengesReducer = createRessourceListReducer(
    ressourceListInitialState,
    DualQuizChallengesActions,
    additionalActions
);
