import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { DualQuizChallengeSelectors } from 'app/store/dual-quiz/challenge/challenge.selectors';

@Component({
    selector: 'dual-quiz-challenge-round-timer',
    templateUrl: './round-timer.component.html',
    standalone: true,
    imports: [AsyncPipe, MatIconModule],
})
export class DualQuizChallengeRoundTimerComponent {
    timeLeftInSeconds$ = this.store.select(
        DualQuizChallengeSelectors.selectTimeLeftInSeconds
    );

    constructor(private store: Store) {}
}
