import { createReducer, on } from '@ngrx/store';
import { ValidationActions } from './validation.actions';
import { ValidationState } from './validation.state';

export const initialState: ValidationState = {
    isLoading: false,
    stepIndex: 0,
};

export const validationReducer = createReducer(
    initialState,

    on(ValidationActions.sendEmailRequest, (state, { email }) => {
        return {
            ...state,
            email,
            isLoading: true,
        };
    }),
    on(ValidationActions.sendEmailRequestSuccess, (state) => {
        return {
            ...state,
            stepIndex: 1,
            isLoading: false,
        };
    }),
    on(ValidationActions.sendEmailRequestFail, (state, { error }) => {
        return {
            ...state,
            emailError: error,
            isLoading: false,
        };
    }),

    on(ValidationActions.sendCodeRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),
    on(ValidationActions.sendCodeRequestSuccess, (state) => {
        return {
            ...state,
            stepIndex: 2,
            isLoading: false,
        };
    }),
    on(ValidationActions.sendCodeRequestFail, (state, { error }) => {
        return {
            ...state,
            codeError: error,
            isLoading: false,
        };
    }),

    on(ValidationActions.sendSetPasswordRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),
    on(ValidationActions.sendSetPasswordRequestSuccess, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),
    on(ValidationActions.sendSetPasswordRequestFail, (state, { error }) => {
        return {
            ...state,
            passwordError: error,
            isLoading: false,
        };
    }),
    on(ValidationActions.validateEmailRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),
    on(ValidationActions.validateEmailRequestSuccess, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),
    on(ValidationActions.validateEmailRequestFail, (state, { error }) => {
        return {
            ...state,
            codeError: error,
            isLoading: false,
        };
    }),
    on(ValidationActions.newValidationEmailRequest, (state) => {
        return {
            ...state,
            codeError: null,
            isLoading: true,
        };
    }),
    on(ValidationActions.newValidationEmailRequestSuccess, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),
    on(ValidationActions.newValidationEmailRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    })
);
