import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MissionRatingViewModel } from 'app/api';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';
import { IFormService } from './form-service';

@Injectable({
    providedIn: 'root',
})
export class MissionRatingFormService
    implements IFormService<MissionRatingViewModel>
{
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder) {
        this.initForm();
    }

    get getFormGroup() {
        return this.formGroup;
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            id: [null],
            experienceRating: [
                null,
                [Validators.required, Validators.min(1), Validators.max(5)],
            ],
            purposeRating: [
                null,
                [Validators.required, Validators.min(1), Validators.max(5)],
            ],
            associationRating: [
                null,
                [Validators.required, Validators.min(1), Validators.max(5)],
            ],
            associationComment: ['', Validators.required],
            feelingsFelt: [null, Validators.required],
            newSelfAndOthersLearning: ['', Validators.required],
            isCompanyInitiativesImportant: [null, Validators.required],
            isCompanyInitiativesImportantComment: [null, Validators.required],
            isActionImprovingCompanyImage: [null, Validators.required],
            isActionContributingToPersonalGrowthAtCompany: [
                null,
                Validators.required,
            ],
            preferredActivityFields: [[], Validators.required],
            otherPreferredActivityFields: [''],
            isMissionRecommendable: [null, Validators.required],
            desiredDaysPerYear: [
                null,
                [Validators.required, Validators.min(1), Validators.max(365)],
            ],
            otherComments: ['', Validators.required],
        });
        return this.formGroup;
    }

    updateForm(entity: MissionRatingViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
    }

    getEntity(): { [key: string]: MissionRatingViewModel } {
        const missionRatingViewModel =
            returnOnlyNonNullFields<MissionRatingViewModel>(
                this.formGroup.value
            ) as MissionRatingViewModel;

        return { missionRatingViewModel };
    }
}
