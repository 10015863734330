import { createReducer, on } from '@ngrx/store';
import { MissionRatingActions } from './mission-rating.action';
import { MissionRatingState } from './mission-rating.state';

export const missionRatingInitialState: MissionRatingState = {
    isLoading: false,
};

export const missionRatingReducer = createReducer(
    missionRatingInitialState,
    on(MissionRatingActions.postRatingRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(MissionRatingActions.postRatingRequestSuccess, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(MissionRatingActions.postRatingRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    })
);
