import { NgClass, NgIf } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { fuseAnimations } from '@fuse/animations';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { HighlightActions } from 'app/store/highlight/highlight.action';
import { BasileComponent } from '../basile/basile.component';

@Component({
    selector: 'element-highlighter',
    standalone: true,
    templateUrl: './element-highlighter.component.html',
    styleUrls: ['./element-highlighter.component.scss'],
    animations: fuseAnimations,
    imports: [NgIf, MatIconModule, BasileComponent, NgClass, TranslocoModule],
})
export class ElementHighlighterComponent implements AfterViewInit {
    @ViewChild('elementHighlighter') elementHighlighter: ElementRef;

    @Input() target: any;
    @Input() position: 'left' | 'right' = 'right';

    constructor(private renderer: Renderer2, private store: Store) {}

    ngOnInit() {
        if (this.target) {
            this.target.classList.add('shadow-highlight', 'relative');
            this.renderer.listen(this.target, 'click', () => {
                this.removeHighlighting();
            });
        }
    }

    ngAfterViewInit(): void {
        // Time out needed for scroll to work after redirection
        setTimeout(() => {
            this.elementHighlighter.nativeElement.scrollIntoView({
                behavior: 'smooth',
            });
        }, 100);
    }

    // Prevent click on the target if the highlighted element is a button
    onClick(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }

    removeHighlighting() {
        this.store.dispatch(HighlightActions.stopHighlightEvent());
        this.target.classList.remove('shadow-highlight', 'relative');
    }
}
