import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterLink } from '@angular/router';
import { BslkChoiceCardComponent } from '@bslk/components/cards/choice/choice-card.component';
import {
    BslkAdvancedSelectSyncComponent,
    BslkAdvancedSelectSyncInput,
} from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EActivityField, EUserRole } from 'app/api';
import { SingleSelectAsyncComponent } from 'app/shared/components/form/select/async/single-select-async.component';
import { AuthTemplateComponent } from 'app/shared/components/templates/auth-template/auth-template.component';
import { TranslationService } from 'app/shared/services/transloco/translation.service';
import { CustomValidators } from 'app/shared/utils/custom-validators.utils';
import { AssociationActions } from 'app/store/association/association/association.action';
import { AssociationSelectors } from 'app/store/association/association/association.selectors';
import { AssociationListActions } from 'app/store/association/associations/associations.actions';
import { AssociationListSelectors } from 'app/store/association/associations/associations.selectors';
import { UserActions } from 'app/store/user/user/user.actions';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { Subject, takeUntil } from 'rxjs';
import { SignupAuthComponent } from '../auth/sign-up-auth.component';

@Component({
    selector: 'association-sign-up',
    templateUrl: './association-sign-up.component.html',
    styleUrls: ['./association-sign-up.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        AsyncPipe,
        AuthTemplateComponent,
        BslkAdvancedSelectSyncComponent,
        BslkChoiceCardComponent,
        FormsModule,
        FuseAlertComponent,
        FuseCardComponent,
        LetDirective,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatStepperModule,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        ReactiveFormsModule,
        RouterLink,
        SignupAuthComponent,
        SingleSelectAsyncComponent,
        TranslocoModule,
    ],
})
export class AssociationSignUpComponent implements OnInit, OnDestroy {
    private unsubscribeAll: Subject<any> = new Subject<any>();

    EUserRole = EUserRole;

    isLoading$ = this.store.select(AssociationSelectors.selectIsLoading);
    isSignedIn$ = this.store.select(UserSelectors.selectIsSignedIn);
    associationCreationError$ = this.store.select(
        AssociationSelectors.selectError
    );
    hasPendingRequest$ = this.store.select(
        AssociationSelectors.selectHasPendingJoinRequest
    );

    associationFormGroup = this.fb.group({
        name: ['', Validators.required],
        tags: [
            [],
            [Validators.required, CustomValidators.maxArrayLengthValidator(10)],
        ],
    });

    joinAssociationFormGroup = this.fb.group({
        associationId: ['', [Validators.required]],
    });

    associationAction: 'create' | 'join' | null = null;

    associationSelectors = AssociationListSelectors;
    associationActions = AssociationListActions;

    translationsLoaded: boolean = false;
    activityFieldsAdvancedSelectItems: BslkAdvancedSelectSyncInput[];

    constructor(
        private store: Store,
        private fb: FormBuilder,
        private translationService: TranslationService
    ) {}

    ngOnInit(): void {
        this.store.dispatch(UserActions.resetErrors());
        this.store.dispatch(AssociationActions.resetErrors());

        this.translationService
            .getTranslatedEnum(EActivityField, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.activityFieldsAdvancedSelectItems = translatedItems;
                this.translationsLoaded = true;
            });
    }

    createAssociation() {
        if (this.associationFormGroup.valid) {
            this.store.dispatch(
                AssociationActions.createRequest({
                    data: {
                        name: this.associationFormGroup.controls.name.value,
                        tags: this.associationFormGroup.controls.tags.value,
                    },
                })
            );
        }
    }

    joinAssociation() {
        if (this.joinAssociationFormGroup.valid) {
            const associationId = parseInt(
                this.joinAssociationFormGroup.controls.associationId.value
            );
            this.store.dispatch(
                AssociationActions.joinRequest({ associationId })
            );
        }
    }

    selectAssociationAction(action: 'create' | 'join') {
        this.associationAction = action;
    }

    cancelAction() {
        this.associationAction = null;
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
