<div *ngrxLet="items$ as items" class="single-select">
    <mat-form-field
        floatLabel="always"
        [subscriptSizing]="'dynamic'"
        class="w-full"
    >
        <mat-label>{{ label }} </mat-label>
        <span class="whitespace-nowrap w-full" *ngIf="showInitialValue">{{
            initialValue
        }}</span>
        <mat-select
            [placeholder]="selectPlaceholder"
            (openedChange)="onMatSelectOpenedChange($event)"
            (selectionChange)="onChange()"
            [formControl]="control"
        >
            <div class="relative">
                <div class="search sticky top-0 z-99 border-b-2">
                    <mat-form-field
                        [subscriptSizing]="'dynamic'"
                        class="search-input py-2 w-full px-1 bg-warn-100"
                    >
                        <input
                            #searchInput
                            [placeholder]="searchPlaceholder"
                            matInput
                            (keyup)="onSearch($event.target.value)"
                        />
                    </mat-form-field>
                </div>

                <mat-option
                    *ngFor="let item of items"
                    [value]="valueIsId ? item.id : item"
                    >{{ item.name }}</mat-option
                >
            </div>
        </mat-select>
    </mat-form-field>
    <fuse-alert
        class="mt-2"
        *ngIf="
            items?.length === 0 &&
            isSearchEmpty &&
            noDataText &&
            !(isLoading$ | async)
        "
        [type]="'warn'"
    >
        {{ noDataText }}
    </fuse-alert>
</div>
