<div class="flex flex-col w-full">
    <div class="p-4 lg:p-8 xl:p-12 w-full grow">
        <div class="text-4xl font-extrabold tracking-tight">
            {{ "associationprojects.title" | transloco }}
        </div>
        <div class="flex justify-between mt-4">
            <span class="hidden lg:inline">{{
                "associationprojects.subtitle" | transloco
            }}</span>
        </div>

        <div class="mt-5" *ngrxLet="projects$ as projects">
            <div
                class="text-4xl font-bold text-center mt-10"
                *ngIf="!projects || projects.length === 0; else projectsList"
            >
                <span>{{ "associationprojects.no-project" | transloco }}</span>
            </div>

            <ng-template #projectsList>
                <project-association-card
                    [project]="project"
                    *ngFor="let project of projects"
                >
                </project-association-card>
            </ng-template>
        </div>
    </div>
    <mat-paginator
        class="bg-white border-b border-t"
        [ngClass]="{ 'pointer-events-none': isLoading$ | async }"
        [length]="totalCount$ | async"
        [pageIndex]="pageIndex$ | async"
        [pageSize]="pageSize$ | async"
        [hidePageSize]="true"
        (page)="pageChangeEvent($event)"
        [showFirstLastButtons]="true"
    >
    </mat-paginator>
</div>
