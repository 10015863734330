import { LocationViewModel } from 'app/api';
import { isEmpty, isNil } from 'lodash-es';
export function getLocationRepresentation(
    locationViewModel: LocationViewModel
) {
    if (isNil(locationViewModel)) {
        return;
    }
    let str = locationViewModel.address;
    if (!isEmpty(locationViewModel.city)) {
        str += ', ' + locationViewModel.city;
    }
    if (!isEmpty(locationViewModel.zipCode)) {
        str += ' ' + locationViewModel.zipCode;
    }
    if (!isEmpty(locationViewModel.country)) {
        str += ', ' + locationViewModel.country;
    }
    return str;
}
