<mission-row-card [mission]="mission">
    <div class="flex p-3 sm:p-0 justify-center h-full">
        <div class="flex flex-col justify-center truncate mx-auto px-4">
            <span class="text-2xl font-bold text-warn">{{
                formatDateRange(mission.startDate, mission.endDate)
            }}</span>
            <span>{{ "missions.card.mission-dates" | transloco }}</span>
        </div>
        <div
            *ngIf="mission.registeredeVolunteers > 1"
            class="hidden xl:flex flex-col justify-center px-4 border-l"
        >
            <span class="text-2xl font-bold text-warn">{{
                mission.registeredeVolunteers
            }}</span>
            <span>{{ "missions.card.participants" | transloco }}</span>
        </div>
    </div>

    <!-- Mission State -->
    <div footer class="flex flex-col py-1 px-2">
        <mission-state
            class="p-1"
            [state]="mission.missionState"
        ></mission-state>
    </div>

    <div actions>
        <a
            *ngIf="!isArchived"
            [routerLink]="'/missions/' + mission.id + '/manage'"
        >
            <button
                class="flex bg-tertiary items-center mt-3 mx-auto text-white"
                mat-flat-button
            >
                <mat-icon
                    class="icon-size-5 svg-white"
                    [svgIcon]="'heroicons_solid:play'"
                >
                </mat-icon>
                <span class="ml-2">{{
                    "missions.card.manage" | transloco
                }}</span>
            </button>
        </a>

        <a [routerLink]="'/missions/' + mission.id">
            <button
                color="warn"
                class="flex items-center mt-3 mx-auto text-white"
                mat-flat-button
            >
                <mat-icon
                    class="icon-size-5 svg-white"
                    [svgIcon]="'heroicons_solid:eye'"
                >
                </mat-icon>
                <span class="ml-2">{{ "missions.card.view" | transloco }}</span>
            </button>
        </a>
    </div>
</mission-row-card>
