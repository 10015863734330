<div
    *ngrxLet="reaction$ as reaction"
    class="flex items-start space-x-4 relative"
>
    <img
        [src]="'assets/basile-postures/' + reaction.posture + '.png'"
        class=""
    />
    <div class="absolute top-10 flex flex-col">
        <!-- Speech Bubble -->
        <div
            class="relative bg-white p-4 rounded-xl max-w-xs min-w-48 shadow-lg"
        >
            <p
                class="font-semibold font-italic text-center text-black text-2xl"
            >
                {{ "coursesection." + reaction.textKey | transloco }}
            </p>
            <div
                class="w-0 h-0 border-8 border-transparent border-b-white absolute -bottom-4 transform left-3/4 -translate-x-1/2 rotate-180"
            ></div>
        </div>
    </div>
</div>
