import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AuthTemplateComponent } from 'app/shared/components/templates/auth-template/auth-template.component';
import { ErrorPipe } from 'app/shared/pipes/error.pipe';
import { UserActions } from 'app/store/user/user/user.actions';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'linkedin-sign-in-callback',
    templateUrl: './linkedin-sign-in-callback.component.html',
    standalone: true,
    animations: fuseAnimations,
    imports: [
        AuthTemplateComponent,
        LetDirective,
        MatProgressSpinnerModule,
        TranslocoModule,
        FuseAlertComponent,
        ErrorPipe,
        FuseAlertComponent,
        MatButtonModule,
        RouterLink,
        NgIf,
    ],
})
export class LinkedInSignInCallback implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    isLoading$ = this.store.select(UserSelectors.selectIsLoading);
    loginError$ = this.store.select(UserSelectors.selectLoginError);

    constructor(private activatedRoute: ActivatedRoute, private store: Store) {}

    ngOnInit() {
        this.activatedRoute.queryParams
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                const code = params['code'];
                const state = params['state'];
                if (code) {
                    this.store.dispatch(
                        UserActions.signedInWithLinkedIn({ code, state })
                    );
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
