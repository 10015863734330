<fuse-drawer
    class="drawer z-999 overflow-auto min-w-screen lg:min-w-[1280px]"
    fixed
    (openedChanged)="openedChanged($event)"
    [mode]="'over'"
    [transparentOverlay]="false"
    [name]="'projectDetailsDrawer'"
    [position]="'right'"
    #detailsDrawer
>
    <div class="overflow-auto w-full">
        <project-view
            (close)="onClose()"
            (choose)="onChoose()"
            [emitOnClose]="true"
            [closeFixedPosition]="true"
            [canChoose]="true"
            *ngIf="projectId"
        ></project-view>
    </div>
</fuse-drawer>
