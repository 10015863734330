import { Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { CourseSelectors } from 'app/store/course/course/course.selectors';

@Component({
    selector: 'chapter-basile',
    templateUrl: './basile.component.html',
    standalone: true,
    imports: [LetDirective, TranslocoModule],
})
export class ChapterBasileComponent {
    reaction$ = this.store.select(CourseSelectors.selectBasileReaction);

    constructor(private store: Store) {}
}
