<bslk-classic-card [title]="'companyfilters.associations' | transloco">
    <bslk-info
        headerActions
        [size]="'large'"
        [tooltip]="'companyfilters.associations-tooltip' | transloco"
    ></bslk-info>

    <span class="font-bold text-lg">{{
        "companyfilters.my-blacklist" | transloco
    }}</span>
    <div
        class="flex flex-wrap gap-2 py-2.5 px-6 border border-gray-300 rounded-3xl mb-4 min-h-11"
    >
        <bslk-tag
            (click)="removeAssociation(association)"
            class="cursor-pointer"
            [size]="'small'"
            [isRemovable]="true"
            [background]="'black'"
            *ngFor="let association of formControl.value"
        >
            {{ association.name }}
        </bslk-tag>

        <span *ngIf="formControl.value.length === 0" class="text-hint">{{
            "companyfilters.no-associations-in-blacklist" | transloco
        }}</span>
    </div>

    <table
        *ngrxLet="associations$ as associations"
        class="w-full border text-left mt-3"
    >
        <thead
            class="shadow-outside border-b text-lg shadow-warn-50 text-white"
        >
            <tr>
                <th colspan="2" class="px-6 py-3">
                    <mat-form-field
                        class="fuse-mat-dense fuse-mat-rounded w-full"
                        [subscriptSizing]="'dynamic'"
                    >
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'heroicons_solid:magnifying-glass'"
                        ></mat-icon>
                        <input
                            matInput
                            (input)="onSearch()"
                            [(ngModel)]="search"
                            [autocomplete]="'off'"
                            [placeholder]="
                                'companyfilters.search-association' | transloco
                            "
                        />
                        <button
                            mat-icon-button
                            matSuffix
                            *ngIf="search"
                            (click)="clearSearch()"
                        >
                            <mat-icon
                                class="mr-1"
                                [svgIcon]="'heroicons_solid:x-mark'"
                            >
                            </mat-icon>
                        </button>
                    </mat-form-field>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!(isLoading$ | async)">
            <tr
                *ngFor="let association of associations"
                class="bg-white border-b"
            >
                <td class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4">
                    <div class="flex items-center">
                        <img
                            *ngIf="association.logoUrl"
                            class="w-10 h-10 my-auto rounded-lg mr-2"
                            [src]="association.logoUrl"
                        />
                        <div
                            *ngIf="!association.logoUrl"
                            class="w-10 h-10 my-auto rounded-lg mr-2 bg-gray-200"
                        ></div>
                        <div class="flex flex-col truncate">
                            <a
                                target="_blank"
                                [routerLink]="
                                    '/ca/organizations/' + association.id
                                "
                                class="font-bold truncate"
                                >{{ association.name }}</a
                            >
                        </div>
                    </div>
                </td>
                <td class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4">
                    <bslk-tag-button
                        class="flex justify-end"
                        (onClick)="addAssociation(association)"
                        [icon]="'heroicons_outline:minus-circle'"
                        >{{
                            "companyfilters.exclude" | transloco
                        }}</bslk-tag-button
                    >
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="isLoading$ | async">
            <tr
                *ngFor="let _ of [1, 2, 3, 4, 5, 6, 7, 8]"
                class="bg-white border-b"
            >
                <td class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4">
                    <div class="flex items-center">
                        <div
                            class="w-10 h-10 my-auto rounded-lg mr-2 bg-gray-200"
                        ></div>
                        <ngx-skeleton-loader class="w-56"></ngx-skeleton-loader>
                    </div>
                </td>
                <td class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4">
                    <bslk-tag-button
                        class="flex justify-end"
                        [icon]="'heroicons_outline:minus-circle'"
                        >{{
                            "companyfilters.exclude" | transloco
                        }}</bslk-tag-button
                    >
                </td>
            </tr>
        </tbody>
    </table>

    <mat-paginator
        class="bg-white w-full border-b border-x"
        [ngClass]="{ 'pointer-events-none': isLoading$ | async }"
        [length]="totalCount$ | async"
        [pageIndex]="pageIndex$ | async"
        [pageSize]="pageSize$ | async"
        [hidePageSize]="true"
        (page)="pageChangeEvent($event)"
        [showFirstLastButtons]="true"
    >
    </mat-paginator>
</bslk-classic-card>
