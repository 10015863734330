import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { BslkColor } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslationService } from 'app/shared/services/transloco/translation.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    standalone: true,
    selector: 'enum-tag-list',
    templateUrl: './enum-tag-list.component.html',
    imports: [
        BslkTagComponent,
        NgIf,
        NgFor,
        MatTooltipModule,
        TranslocoModule,
        MatIconModule,
    ],
})
export class EnumTagListComponent<T> implements OnInit {
    @Input() tags: T[];
    @Input() enum: T;
    @Input() maxTagsVisible: number;
    @Input() background: BslkColor = 'primary';

    private unsubscribeAll: Subject<any> = new Subject<any>();

    tagTranslationsLoaded = false;
    tooltipText: string;
    visibleTagsTranslations: string[];

    constructor(private translationService: TranslationService) {}

    get visibleTags() {
        return this.tags.slice(0, this.maxTagsVisible);
    }

    get tooltipTags() {
        return this.tags.slice(this.maxTagsVisible, this.tags.length);
    }

    ngOnInit(): void {
        this.maxTagsVisible ??= this.tags.length;
        this.translationService
            .getTranslatedEnum(this.enum, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.tagTranslationsLoaded = true;
                this.visibleTagsTranslations = this.visibleTags.map(
                    (v) => translatedItems.find((t) => t.value === v).name
                );
                this.tooltipText = this.tooltipTags
                    .map((v) => translatedItems.find((t) => t.value === v).name)
                    .join(', ');
            });
    }
}
