import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { HighlightableElement } from './highlight.state';

const selectState = (state: AppState) => state.highlight;

const selectHighlightedElement = createSelector(
    selectState,
    (state) => state.highlightedElement
);

const selectIsElementHighlighted = (element: HighlightableElement) =>
    createSelector(
        selectHighlightedElement,
        (highlightedElement) => highlightedElement === element
    );

export const HighlightSelectors = {
    selectIsElementHighlighted,
};
