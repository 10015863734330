<bslk-classic-card [title]="'setup.employees' | transloco">
    <div headerActions class="flex gap-5 items-center">
        <bslk-tag-button
            (onClick)="openImportDialog()"
            [icon]="'feather:upload'"
            >{{ "shared.common.import" | transloco }}</bslk-tag-button
        >

        <bslk-info
            [size]="'large'"
            [tooltip]="'setup.employees-tooltip' | transloco"
        ></bslk-info>
    </div>

    <table
        *ngrxLet="employees$ as employees"
        class="w-full border text-left mt-3"
    >
        <thead
            class="shadow-outside border-b text-lg shadow-warn-50 text-white"
        >
            <tr>
                <th colspan="2" class="px-6 py-3">
                    <mat-form-field
                        class="fuse-mat-dense fuse-mat-rounded w-full"
                        [subscriptSizing]="'dynamic'"
                    >
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'heroicons_solid:magnifying-glass'"
                        ></mat-icon>
                        <input
                            matInput
                            (input)="onSearch()"
                            [(ngModel)]="search"
                            [autocomplete]="'off'"
                            [placeholder]="'setup.search-employee' | transloco"
                        />
                        <button
                            mat-icon-button
                            matSuffix
                            *ngIf="search"
                            (click)="clearSearch()"
                        >
                            <mat-icon
                                class="mr-1"
                                [svgIcon]="'heroicons_solid:x-mark'"
                            >
                            </mat-icon>
                        </button>
                    </mat-form-field>
                </th>
                <th colspan="2" class="px-6 py-3">
                    <div
                        *ngrxLet="properties$ as properties"
                        class="flex justify-end text-black"
                    >
                        <span *ngIf="properties?.registrationRate">
                            <span class="font-normal"
                                >{{ "Taux d'inscription" | transloco }} :</span
                            >
                            {{ properties.registrationRate }}%
                        </span>
                    </div>
                </th>
            </tr>
            <tr
                class="bg-warn shadow-outside text-lg shadow-warn-50 text-white"
            >
                <th scope="col" class="px-6 py-3">
                    {{ "setup.family-name" | transloco }}
                </th>
                <th scope="col" class="px-6 py-3">
                    {{ "setup.first-name" | transloco }}
                </th>
                <th scope="col" class="px-6 py-3">
                    {{ "setup.email" | transloco }}
                </th>
                <th scope="col" class="px-6 py-3">
                    {{ "setup.registered" | transloco }}
                </th>
            </tr>
        </thead>
        <tbody *ngIf="!(isLoading$ | async)">
            <tr *ngFor="let employee of employees" class="bg-white border-b">
                <td class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4">
                    {{ employee.familyName }}
                </td>
                <td class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4">
                    {{ employee.firstName }}
                </td>
                <td class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4">
                    {{ employee.email }}
                </td>
                <td class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4">
                    <mat-icon
                        *ngIf="employee.isRegistered"
                        class="text-quaternary"
                        [svgIcon]="'heroicons_outline:check'"
                    ></mat-icon>
                    <mat-icon
                        *ngIf="!employee.isRegistered"
                        class="text-primary"
                        [svgIcon]="'heroicons_outline:x-mark'"
                    ></mat-icon>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="isLoading$ | async">
            <tr
                *ngFor="let _ of [1, 2, 3, 4, 5, 6, 7, 8]"
                class="bg-white border-b"
            >
                <td class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4">
                    <div class="flex items-center">
                        <div
                            class="w-10 h-10 my-auto rounded-lg mr-2 bg-gray-200"
                        ></div>
                        <ngx-skeleton-loader class="w-56"></ngx-skeleton-loader>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <mat-paginator
        class="bg-white w-full border-b border-x"
        [ngClass]="{ 'pointer-events-none': isLoading$ | async }"
        [length]="totalCount$ | async"
        [pageIndex]="pageIndex$ | async"
        [pageSize]="pageSize$ | async"
        [hidePageSize]="true"
        (page)="pageChangeEvent($event)"
        [showFirstLastButtons]="true"
    >
    </mat-paginator>
</bslk-classic-card>
