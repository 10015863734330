import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DualQuizChallengeSelectors } from 'app/store/dual-quiz/challenge/challenge.selectors';
import { EDualQuizChallengePreQuestionState } from 'app/store/dual-quiz/challenge/challenge.state';
import { DualQuizChallengeNextButtonComponent } from '../next-question-button/next-button.component';

@Component({
    selector: 'dual-quiz-challenge-pre-question',
    templateUrl: './pre-question.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        LetDirective,
        TranslocoModule,
        MatIconModule,
        NgIf,
        DualQuizChallengeNextButtonComponent,
    ],
})
export class DualQuizChallengePreQuestionComponent {
    EDualQuizChallengePreQuestionState = EDualQuizChallengePreQuestionState;

    questionIndex$ = this.store.select(
        DualQuizChallengeSelectors.selectCurrentQuestionIndex
    );

    secondsPerQuestion$ = this.store.select(
        DualQuizChallengeSelectors.selectSecondsPerQuestion
    );

    questionsLeft$ = this.store.select(
        DualQuizChallengeSelectors.selectQuestionsLeft
    );

    constructor(private store: Store) {}
}
