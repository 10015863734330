/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EFileType = 'SponsorshipAgreement' | 'SponsorshipAmendment' | 'SponsorshipAttendance' | 'DonationCampaignFundraisingAppeal' | 'DonationCampaignTaxReceipt' | 'AssociationLegalDocument' | 'DeductibleSubscriptionTaxReceipt';

export const EFileType = {
    SponsorshipAgreement: 'SponsorshipAgreement' as EFileType,
    SponsorshipAmendment: 'SponsorshipAmendment' as EFileType,
    SponsorshipAttendance: 'SponsorshipAttendance' as EFileType,
    DonationCampaignFundraisingAppeal: 'DonationCampaignFundraisingAppeal' as EFileType,
    DonationCampaignTaxReceipt: 'DonationCampaignTaxReceipt' as EFileType,
    AssociationLegalDocument: 'AssociationLegalDocument' as EFileType,
    DeductibleSubscriptionTaxReceipt: 'DeductibleSubscriptionTaxReceipt' as EFileType
};

