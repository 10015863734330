<base-dialog
    (onClose)="close()"
    [title]="'companyadmindashboard.report.title' | transloco"
    [showActions]="true"
    [size]="'large'"
    [scrollablePadding]="true"
>
    <div class="w-full h-full overflow-y-scroll" body>
        <div class="flex flex-col gap-8 justify-center overflow-y-scroll">
            <div class="flex gap-8 justify-center">
                <form
                    [formGroup]="formGroup"
                    class="flex flex-col gap-3 min-w-64 grow"
                >
                    <!-- Remove autofocus trick -->
                    <div style="max-width: 0; max-height: 0; overflow: hidden">
                        <input autofocus="true" />
                    </div>

                    <div class="flex flex-col gap-1">
                        <span class="font-bold">
                            {{
                                "companyadmindashboard.report.logo" | transloco
                            }}
                        </span>
                        <bslk-classic-button
                            class="flex justify-center"
                            [background]="'primary'"
                            (onClick)="openFileUploadDialog()"
                            [icon]="'heroicons_outline:photo'"
                            >{{
                                "companyadmindashboard.report.add" | transloco
                            }}</bslk-classic-button
                        >
                        <span *ngIf="showError" class="text-primary">{{
                            "companyadmindashboard.report.logo-required"
                                | transloco
                        }}</span>
                    </div>

                    <div class="flex flex-col gap-5 min-w-64'">
                        <div class="flex flex-col gap-1">
                            <mat-form-field
                                floatLabel="always"
                                subscriptSizing="dynamic"
                                class="w-full"
                            >
                                <mat-label>{{
                                    "companyadmindashboard.report.background-color"
                                        | transloco
                                }}</mat-label>
                                <input
                                    matInput
                                    autofocus="false"
                                    [formControlName]="'backgroundColor'"
                                    type="color"
                                />
                            </mat-form-field>
                            <span
                                (click)="
                                    formGroup.controls.backgroundColor.reset()
                                "
                                class="text-sm font-bold text-right cursor-pointer"
                                >{{
                                    "companyadmindashboard.report.reset"
                                        | transloco
                                }}</span
                            >
                        </div>

                        <div class="flex flex-col gap-1">
                            <mat-form-field
                                floatLabel="always"
                                subscriptSizing="dynamic"
                                class="w-full"
                            >
                                <mat-label>{{
                                    "companyadmindashboard.report.text-on-background-color"
                                        | transloco
                                }}</mat-label>
                                <input
                                    matInput
                                    [formControlName]="'textOnBackgroundColor'"
                                    type="color"
                                />
                            </mat-form-field>
                            <span
                                (click)="
                                    formGroup.controls.textOnBackgroundColor.reset()
                                "
                                class="text-sm font-bold text-right cursor-pointer"
                                >{{
                                    "companyadmindashboard.report.reset"
                                        | transloco
                                }}</span
                            >
                        </div>

                        <div class="flex flex-col gap-1">
                            <mat-form-field
                                floatLabel="always"
                                subscriptSizing="dynamic"
                                class="w-full"
                            >
                                <mat-label>{{
                                    "companyadmindashboard.report.primary-color"
                                        | transloco
                                }}</mat-label>
                                <input
                                    matInput
                                    [formControlName]="'primaryColor'"
                                    type="color"
                                />
                            </mat-form-field>
                            <span
                                (click)="
                                    formGroup.controls.primaryColor.reset()
                                "
                                class="text-sm font-bold text-right cursor-pointer"
                                >{{
                                    "companyadmindashboard.report.reset"
                                        | transloco
                                }}</span
                            >
                        </div>

                        <div class="flex flex-col gap-1">
                            <mat-form-field
                                floatLabel="always"
                                subscriptSizing="dynamic"
                                class="w-full"
                            >
                                <mat-label>{{
                                    "companyadmindashboard.report.text-on-primary-color"
                                        | transloco
                                }}</mat-label>
                                <input
                                    matInput
                                    [formControlName]="'textOnPrimaryColor'"
                                    type="color"
                                />
                            </mat-form-field>
                            <span
                                (click)="
                                    formGroup.controls.textOnPrimaryColor.reset()
                                "
                                class="text-sm font-bold text-right cursor-pointer"
                                >{{
                                    "companyadmindashboard.report.reset"
                                        | transloco
                                }}</span
                            >
                        </div>

                        <div class="flex flex-col gap-1">
                            <mat-form-field
                                floatLabel="always"
                                subscriptSizing="dynamic"
                                class="w-full"
                            >
                                <mat-label>{{
                                    "companyadmindashboard.report.secondary-color"
                                        | transloco
                                }}</mat-label>
                                <input
                                    matInput
                                    [formControlName]="'secondaryColor'"
                                    type="color"
                                />
                            </mat-form-field>
                            <span
                                (click)="
                                    formGroup.controls.secondaryColor.reset()
                                "
                                class="text-sm font-bold text-right cursor-pointer"
                                >{{
                                    "companyadmindashboard.report.reset"
                                        | transloco
                                }}</span
                            >
                        </div>

                        <div class="flex flex-col gap-1">
                            <mat-form-field
                                floatLabel="always"
                                subscriptSizing="dynamic"
                                class="w-full"
                            >
                                <mat-label>{{
                                    "companyadmindashboard.report.text-on-secondary-color"
                                        | transloco
                                }}</mat-label>
                                <input
                                    matInput
                                    [formControlName]="'textOnSecondaryColor'"
                                    type="color"
                                />
                            </mat-form-field>
                            <span
                                (click)="
                                    formGroup.controls.textOnSecondaryColor.reset()
                                "
                                class="text-sm font-bold text-right cursor-pointer"
                                >{{
                                    "companyadmindashboard.report.reset"
                                        | transloco
                                }}</span
                            >
                        </div>
                    </div>
                </form>

                <div class="flex flex-col gap-1 grow min-w-120 max-w-140">
                    <span class="font-bold">{{
                        "companyadmindashboard.report.preview" | transloco
                    }}</span>
                    <div
                        [style]="
                            'backgroundColor:' +
                            formGroup.controls.backgroundColor.value
                        "
                        class="border rounded h-80 flex flex-col mb-3"
                    >
                        <div
                            [style]="
                                'backgroundColor:' +
                                formGroup.controls.secondaryColor.value
                            "
                            class="mx-4 mt-4 h-14 flex justify-center items-center text-xl"
                        >
                            <span
                                [style]="
                                    'color:' +
                                    formGroup.controls.textOnSecondaryColor
                                        .value
                                "
                                >Aenean at maximus eros</span
                            >
                        </div>
                        <div
                            class="grow justify-around flex flex-col items-center"
                        >
                            <img
                                *ngIf="formGroup.controls.logoAsBase64.value"
                                class="w-24"
                                [src]="formGroup.controls.logoAsBase64.value"
                            />
                            <div
                                *ngIf="!formGroup.controls.logoAsBase64.value"
                                class="w-24 text-sm text-center border border-black p-4"
                            >
                                {{ "Logo manquant" | transloco }}
                            </div>
                            <span
                                class="text-lg p-4"
                                [style]="
                                    'color:' +
                                    formGroup.controls.textOnBackgroundColor
                                        .value
                                "
                                >Cras tempus et tortor suscipit pulvinar.
                                Vivamus nulla elit, vestibulum ac cursus nec,
                                placerat ultrices tellus.</span
                            >
                        </div>
                    </div>
                    <div
                        [style]="
                            'backgroundColor:' +
                            formGroup.controls.backgroundColor.value
                        "
                        class="border rounded h-80 flex flex-col"
                    >
                        <div
                            [style]="
                                'backgroundColor:' +
                                formGroup.controls.secondaryColor.value
                            "
                            class="mx-4 mt-4 h-14 flex justify-center items-center text-xl"
                        >
                            <span
                                [style]="
                                    'color:' +
                                    formGroup.controls.textOnSecondaryColor
                                        .value
                                "
                                >Aenean at maximus eros</span
                            >
                        </div>
                        <div
                            [style]="
                                'backgroundColor:' +
                                formGroup.controls.primaryColor.value
                            "
                            class="mx-4 mb-4 grow p-4 text-lg flex flex-col gap-2 justify-around"
                        >
                            <span
                                [style]="
                                    'color:' +
                                    formGroup.controls.textOnPrimaryColor.value
                                "
                                >Mauris et egestas mi. Morbi volutpat dolor
                                odio, in mollis quam mattis sit amet.</span
                            >
                            <span
                                [style]="
                                    'color:' +
                                    formGroup.controls.textOnPrimaryColor.value
                                "
                                >Suspendisse consequat est lacus, eu rhoncus
                                turpis laoreet et. In gravida quis tortor eu
                                dapibus.</span
                            >
                            <span
                                [style]="
                                    'color:' +
                                    formGroup.controls.textOnPrimaryColor.value
                                "
                                >Morbi eu lacus pretium, vulputate nisi
                                faucibus, ornare odio.</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-center">
                <fuse-alert type="info">{{
                    "companyadmindashboard.report.info" | transloco
                }}</fuse-alert>
            </div>
        </div>
    </div>

    <div class="items-center flex gap-4 justify-between" actions>
        <div>
            <span *ngIf="showError" class="text-primary">{{
                "companyadmindashboard.report.logo-required" | transloco
            }}</span>
        </div>
        <bslk-classic-button
            [isLoading]="isLoading$ | async"
            [isDisabled]="showError"
            (onClick)="export()"
            [icon]="'heroicons_outline:arrow-down-tray'"
            >{{ "shared.common.download" | transloco }}</bslk-classic-button
        >
    </div>
</base-dialog>
