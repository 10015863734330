import {
    Int64UserRankingViewModel,
    Int64UserRankingViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const RankingExperienceActions = createRessourceListActionGroup<
    Int64UserRankingViewModel,
    Int64UserRankingViewModelPaginatedListDto
>('RankingExperience');
