import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { CourseActions } from 'app/store/course/course/course.action';

@Component({
    selector: 'chapter-progress-bar',
    templateUrl: './progress-bar.component.html',
    standalone: true,
    imports: [
        NgFor,
        LetDirective,
        NgClass,
        NgIf,
        AsyncPipe,
        TranslocoModule,
        MatTooltipModule,
    ],
})
export class ChapterProgressBarComponent {
    @Input() sectionsCount: number = 7;
    @Input() activeSectionIndex: number = 0;

    constructor(private store: Store) {}

    get indices() {
        return Array(this.sectionsCount)
            .fill(0)
            .map((x, i) => i);
    }

    get progressPercentage() {
        return Math.round(
            (this.activeSectionIndex / Math.max(this.sectionsCount - 1, 1)) *
                100
        );
    }

    goToSection(sectionIndex: number) {
        if (sectionIndex < this.activeSectionIndex) {
            this.store.dispatch(CourseActions.goToSection({ sectionIndex }));
        }
    }
}
