<div class="flex flex-col gap-5" [formGroup]="formGroup">
    <div class="flex flex-col items-center gap-5">
        <div class="w-full flex flex-col gap-1">
            <bslk-info-label class="font-bold">{{
                "donationcampaigns.attached-message" | transloco
            }}</bslk-info-label>
            <mat-form-field
                floatLabel="always"
                subscriptSizing="dynamic"
                class="w-full"
            >
                <textarea matInput [formControlName]="'message'"> </textarea>
            </mat-form-field>
            <bslk-length-info
                [text]="formGroup.controls.message.value"
                [maxLength]="600"
            ></bslk-length-info>
        </div>

        <div class="w-full flex flex-col gap-1">
            <bslk-info-label class="font-bold">{{
                "donationcampaigns.expiration-date" | transloco
            }}</bslk-info-label>
            <mat-form-field
                [subscriptSizing]="'dynamic'"
                floatLabel="always"
                (click)="picker.open()"
                class="flex-1"
            >
                <input
                    class="cursor-pointer"
                    matInput
                    readonly
                    [matDatepicker]="picker"
                    [matDatepickerFilter]="getDatesFilter"
                    [formControl]="formGroup.controls.expirationDate"
                />
                <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="w-full grid grid-cols-2">
            <bslk-classic-toggle
                [label]="'donationcampaigns.is-client-campaign' | transloco"
                [infoTooltip]="
                    'donationcampaigns.is-client-campaign-tooltip' | transloco
                "
                [color]="'warn'"
                [size]="'small'"
                [isLabelBold]="true"
                [choices]="[
                    { text: 'shared.common.yes' | transloco, value: true },
                    { text: 'shared.common.no' | transloco, value: false }
                ]"
                [control]="formGroup.controls.isClientCampaign"
                [value]="formGroup.controls.isClientCampaign.value"
                class="flex items-center gap-2"
            ></bslk-classic-toggle>
            <mat-form-field
                *ngIf="formGroup.controls.isClientCampaign.value"
                subscriptSizing="dynamic"
                floatLabel="always"
            >
                <mat-label>{{
                    "donationcampaigns.client-name" | transloco
                }}</mat-label>
                <input [formControlName]="'clientName'" matInput />
            </mat-form-field>
        </div>
    </div>
</div>
