<div
    class="min-h-screen min-w-screen flex flex-col"
    *ngrxLet="campaign$ as campaign"
>
    <div
        *ngIf="(hasChosenProject$ | async) && (chosenProject$ | async)"
        class="flex flex-col lg:flex-row gap-8 h-full grow justify-center items-center py-8 mx-3"
        [style]="'color:' + campaign?.customization.textColor"
    >
        <div
            *ngrxLet="chosenProject$ as chosenProject"
            [style]="
                'backgroundColor:' +
                campaign?.customization.headerBackgroundColor +
                ';color:' +
                campaign?.customization.textColor
            "
            class="rounded-xl shadow-xl p-6 flex flex-col gap-4 items-center relative mx-3 max-w-140"
        >
            <classic-avatar
                [size]="24"
                [borderSize]="4"
                [avatarUrl]="chosenProject.association.logoUrl"
                [fallbackLetter]="chosenProject.association.name.at(0)"
            >
            </classic-avatar>
            <div class="text-center text-xl font-semibold flex flex-col">
                <ng-container *ngIf="!campaign.isOneEnvelope">
                    <div class="mb-3 text-2xl">
                        {{ "donationgift.you-enabled-to-fund" | transloco }}
                        <span class="font-bold"
                            >{{ contribution$ | async }}
                            {{ chosenProject.impactUnitDescription }}</span
                        >.
                    </div>

                    {{ chosenProject.association.name }}
                    {{ "donationgift.thanks-support" | transloco }}
                </ng-container>

                <ng-container *ngIf="campaign.isOneEnvelope">
                    {{ chosenProject.association.name }}
                    {{ "donationgift.thanks-vote" | transloco }}
                    <span class="font-bold"
                        >{{ contribution$ | async }}
                        {{ chosenProject.impactUnitDescription }}</span
                    >
                    🙂 !
                </ng-container>

                <div class="flex gap-4 mt-5 text-md">
                    <div
                        class="flex flex-col md:flex-row gap-2"
                        *ngIf="
                            !(hasAskedToBeContacted$ | async);
                            else contactMeMessage
                        "
                    >
                        <span>
                            {{ "donationgift.interested" | transloco }}</span
                        >
                        <div class="flex justify-center">
                            <bslk-tag-button
                                (onClick)="onContactMe()"
                                [size]="'small'"
                                [isLoading]="isLoadingContactMe$ | async"
                                [icon]="'heroicons_outline:gift'"
                                >{{ "donationgift.contact-me" | transloco }}
                            </bslk-tag-button>
                        </div>
                    </div>
                    <ng-template #contactMeMessage>{{
                        "donationgift.understood" | transloco
                    }}</ng-template>
                </div>
            </div>

            <mat-icon
                class="absolute icon-size-12 rotate-12 text-primary opacity-60 top-5 right-5"
                [svgIcon]="'heroicons_outline:heart'"
            ></mat-icon>
        </div>
        <donation-certificate
            *ngIf="!campaign.isOneEnvelope"
        ></donation-certificate>
    </div>
    <bslk-footer></bslk-footer>
</div>
