<ng-container *ngIf="!(isLoading$ | async); else loading">
    <ng-container *ngrxLet="thematicsProgress$ as thematicsProgress">
        <div
            *ngIf="hasOngoingCampaign$ | async; else noOngoingCampaign"
            class="p-4 md:p-6 lg:p-8 bg-accent rounded-2xl shadow-md flex flex-col"
        >
            <div
                class="mb-24"
                *ngFor="let progress of thematicsProgress; let index = index"
            >
                <!-- Thematic header -->
                <div class="sticky top-0 z-90 flex flex-col gap-15">
                    <course-dashboard-thematic-header
                        [thematic]="progress.thematic.thematic"
                        [startDate]="progress.thematic.startDate"
                        [endDate]="progress.thematic.endDate"
                        [courses]="progress.courses"
                        [index]="index"
                    ></course-dashboard-thematic-header>
                </div>

                <div class="my-12"></div>

                <!-- Courses -->
                <course-dashboard-thematic-tokens
                    [thematic]="progress.thematic.thematic"
                    [courses]="progress.courses"
                    [startDate]="progress.thematic.startDate"
                ></course-dashboard-thematic-tokens>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #loading>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-480"
    ></ngx-skeleton-loader>
</ng-template>

<ng-template #noOngoingCampaign>
    <div class="h-full flex flex-col justify-center items-center gap-8">
        <mat-icon
            [svgIcon]="'heroicons_outline:academic-cap'"
            class="icon-size-14 text-war"
        ></mat-icon>
        <div class="flex flex-col gap-2 justify-center items-center">
            <div class="text-center font-bold text-xl">
                {{ "courses.timeline.no-campaign-title" | transloco }}
            </div>

            <div class="text-hint text-lg">
                {{ "courses.timeline.no-campaign-subtitle" | transloco }}
            </div>
        </div>
    </div>
</ng-template>
