import { on } from '@ngrx/store';
import { createRessourceListReducer } from 'app/store/shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from 'app/store/shared/ressource-list/ressource-list-state';
import { DualQuizMatchUpsActions } from './matchups.action';
import { DualQuizMatchUpsState } from './matchups.state';

const additionalActions = [
    on(
        DualQuizMatchUpsActions.LoadUserResultsRequest,
        (state: DualQuizMatchUpsState) => {
            return {
                ...state,
            };
        }
    ),
    on(
        DualQuizMatchUpsActions.LoadUserResultsRequestSuccess,
        (state: DualQuizMatchUpsState, { results }) => {
            return {
                ...state,
                results,
            };
        }
    ),
    on(
        DualQuizMatchUpsActions.LoadUserResultsRequestFail,
        (state: DualQuizMatchUpsState) => {
            return {
                ...state,
            };
        }
    ),
];

export const dualQuizMatchUpsReducer = createRessourceListReducer(
    ressourceListInitialState,
    DualQuizMatchUpsActions,
    additionalActions
);
