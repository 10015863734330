<ng-container *ngrxLet="recap$ as recap">
    <div
        *ngrxLet="isLoading$ as isLoading"
        class="flex flex-col w-full pb-2 px-4 md:px-6 xl:pb-6 xl:px-10"
    >
        <!-- First row -->
        <div class="flex flex-col lg:flex-row items-center justify-between">
            <!-- Avatar -->
            <div class="flex">
                <basile-avatar
                    [configuration]="basileConfig$ | async"
                    [hideBackground]="true"
                    [disableZooming]="true"
                    class="flex flex-shrink-0 w-40 h-40"
                >
                </basile-avatar>

                <div class="w-min pl-4 flex flex-col justify-center">
                    <span class="text-lg text-gray-400">{{
                        "regulardashboard.welcome" | transloco
                    }}</span>
                    <span class="font-bold text-2xl">{{
                        displayedName$ | async
                    }}</span>
                </div>
            </div>

            <div class="hidden md:flex gap-x-8 mb-3 lg:mb-0">
                <good-news></good-news>

                <!-- Coins -->
                <div *ngIf="!isLoading; else skeleton30" class="flex">
                    <div
                        class="flex m-auto bg-white rounded-full bg-opacity-50 w-16 h-16 shadow"
                    >
                        <mat-icon
                            class="text-warn m-auto w-10 h-10"
                            [svgIcon]="'bslk_core:purpose-coins'"
                        ></mat-icon>
                    </div>
                    <div class="flex flex-col justify-center pl-4">
                        <span>{{
                            "regulardashboard.purpose-coins" | transloco
                        }}</span>
                        <span class="font-bold text-lg">{{
                            recap?.sensCoins
                        }}</span>
                    </div>
                </div>

                <!-- Comments -->
                <ng-container *ngIf="!isLoading; else skeleton30">
                    <my-ratings
                        [ratingsCount]="recap.commentsCount"
                    ></my-ratings>
                </ng-container>
            </div>

            <!-- Find mission -->
            <a class="hidden xxl:flex" [routerLink]="'/missions'">
                <button
                    class="hidden lg:flex font-semibold p-6 border border-solid border-primary"
                    mat-flat-button
                >
                    {{ "regulardashboard.find-mission" | transloco }}
                </button>
            </a>
        </div>

        <div class="border-b-2 border-slate-300 w-full mb-6"></div>

        <div
            *ngIf="!isLoading; else skeletonHeader"
            class="grid grid-cols-4 gap-4"
        >
            <!-- KPIs-->
            <div class="col-span-4 lg:col-span-2 grid grid-cols-2 gap-4">
                <!-- Upcoming Missions -->
                <div
                    class="rounded-2xl bg-tertiary hover:bg-opacity-70 bg-opacity-60 shadow-lg flex p-2 relative overflow-hidden min-h-24 hover:scale-105 transition-all duration-500 group"
                >
                    <div
                        class="mx-auto gap-2 flex flex-col md:flex-row justify-around items-center z-10"
                    >
                        <div class="font-bold text-3xl xl:text-4xl">
                            <span>{{ recap?.incomingMissions }}</span>
                        </div>
                        <span>{{
                            "regulardashboard.upcoming-missions" | transloco
                        }}</span>
                    </div>

                    <mat-icon
                        class="absolute text-white h-28 w-28 opacity-30 -rotate-15 top-1/2 -translate-y-1/2 -left-2 group-hover:-left-100 transition-all duration-500"
                        [svgIcon]="'mat_outline:hourglass_empty'"
                    ></mat-icon>
                </div>
                <!-- Completed missions -->
                <div
                    class="rounded-2xl bg-error hover:bg-opacity-70 bg-opacity-60 shadow-lg flex p-2 relative overflow-hidden min-h-24 hover:scale-105 transition-all duration-500 group"
                >
                    <div
                        class="mx-auto gap-2 flex flex-col md:flex-row justify-around items-center z-10"
                    >
                        <div class="font-bold text-3xl xl:text-4xl">
                            <span>{{
                                recap?.missionStats.missionsAccomplished
                            }}</span>
                        </div>
                        <span>{{
                            "regulardashboard.completed-missions" | transloco
                        }}</span>
                    </div>

                    <mat-icon
                        class="absolute text-white h-28 w-28 opacity-30 -rotate-15 top-1/2 -translate-y-1/2 -left-2 group-hover:-left-100 transition-all duration-500"
                        [svgIcon]="'heroicons_outline:check-circle'"
                    ></mat-icon>
                </div>
                <!-- Patron hours -->
                <div
                    class="rounded-2xl bg-warn hover:bg-opacity-70 bg-opacity-60 shadow-lg flex p-2 relative overflow-hidden min-h-24 hover:scale-105 transition-all duration-500 group"
                >
                    <div
                        class="mx-auto gap-2 flex flex-col md:flex-row justify-around items-center z-10"
                    >
                        <div class="font-bold text-3xl xl:text-4xl">
                            <span>{{ recap?.missionStats.totalHours }}</span>
                        </div>
                        <span>{{
                            "regulardashboard.patrons-hours" | transloco
                        }}</span>
                    </div>

                    <mat-icon
                        class="absolute text-white h-28 w-28 opacity-30 -rotate-15 top-1/2 -translate-y-1/2 -left-2 group-hover:-left-100 transition-all duration-500"
                        [svgIcon]="'heroicons_outline:face-smile'"
                    ></mat-icon>
                </div>
                <!-- Volunteer days left -->
                <div
                    class="rounded-2xl bg-primary hover:bg-opacity-70 bg-opacity-60 shadow-lg flex p-2 relative overflow-hidden min-h-24 hover:scale-105 transition-all duration-500 group"
                >
                    <div
                        class="mx-auto gap-2 flex flex-col md:flex-row justify-around items-center z-10"
                    >
                        <div class="font-bold text-3xl xl:text-4xl">
                            <span
                                *ngIf="recap?.missionStats.volunteerDaysLeft"
                                >{{
                                    recap?.missionStats.volunteerDaysLeft
                                }}</span
                            >
                            <span
                                *ngIf="!recap?.missionStats.volunteerDaysLeft"
                                >{{
                                    "regulardashboard.volunteering-days-left-unlimited"
                                        | transloco
                                }}</span
                            >
                        </div>
                        <div>
                            <span>{{
                                "regulardashboard.volunteering-days-left"
                                    | transloco
                            }}</span>
                        </div>
                    </div>

                    <mat-icon
                        class="absolute text-white h-28 w-28 opacity-30 -rotate-15 top-1/2 -translate-y-1/2 -left-2 group-hover:-left-100 transition-all duration-500"
                        [svgIcon]="'heroicons_outline:calendar-days'"
                    ></mat-icon>
                </div>
            </div>

            <!-- Awareness -->
            <div
                class="col-span-4 md:col-span-2 lg:col-span-1 rounded-2xl bg-white bg-opacity-60 shadow-lg flex flex-col p-4 lg:p-2 xl:p-4"
            >
                <span class="text-2xl font-bold text-center">{{
                    "regulardashboard.awareness" | transloco
                }}</span>

                <div class="flex flex-col">
                    <div class="flex">
                        <img
                            class="max-w-24"
                            [src]="
                                'assets/basile-postures/' +
                                basilePosture +
                                '.png'
                            "
                        />
                        <div
                            class="flex flex-col justify-center items-center grow gap-2"
                        >
                            <div class="flex w-min items-center gap-2">
                                <span class="font-bold text-5xl">{{
                                    recap?.chaptersCompletedCount
                                }}</span>
                                <span>{{
                                    "regulardashboard.chapters-completed-" +
                                        (recap?.chaptersCompletedCount > 1
                                            ? "plural"
                                            : "singular") | transloco
                                }}</span>
                            </div>
                            <a
                                [routerLink]="
                                    'getRegularCoursesPage' | urlHelper
                                "
                                [matTooltip]="
                                    'regulardashboard.no-active-campaign'
                                        | transloco
                                "
                                [matTooltipDisabled]="
                                    recap?.hasOngoingAwarenessCampaign
                                "
                                [ngClass]="
                                    !recap?.hasOngoingAwarenessCampaign
                                        ? 'grayscale cursor-default'
                                        : 'hover:translate-x-1 hover:translate-y-1 hover:shadow-none'
                                "
                                class="bg-warn-gradient mt-3 transition-all py-2 px-4 rounded-full shadow-light shadow-primary flex text-center mx-auto transform"
                            >
                                <span
                                    class="w-full text-lg font-bold text-white"
                                    >{{
                                        "regulardashboard.continue" | transloco
                                    }}</span
                                >
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Level information -->
            <!-- <div
                class="col-span-4 md:col-span-2 lg:col-span-1 shadow-lg relative rounded-2xl p-4 flex items-center gap-6 text-white bg-gradient-to-br from-tertiary-600 to-tertiary-400"
            >
                <experience-bar
                    class="z-10"
                    [secondaryColor]="'white'"
                    [barColor]="'warn-gradient'"
                ></experience-bar>
                <mat-icon
                    class="absolute text-white h-48 w-48 opacity-20 -rotate-15 top-1/2 -translate-y-1/2 -left-2"
                    [svgIcon]="'basile-flat'"
                ></mat-icon>
            </div> -->
            <level-information></level-information>
        </div>

        <!-- Bottom -->
        <div class="flex mt-6 xl:mt-10">
            <!-- Left -->
            <div class="flex flex-col gap-y-8 w-full lg:w-2/3 xxl:w-3/4">
                <!-- My pending approvals -->
                <regulard-dashboard-manager-approvals
                    *ngIf="isManager$ | async"
                ></regulard-dashboard-manager-approvals>

                <!-- My missions -->
                <div class="flex flex-col">
                    <div
                        class="flex items-end truncate justify-between pb-1 mb-6 border-b-2 border-slate-300"
                    >
                        <span class="text-3xl md:text-4xl truncate font-bold">{{
                            "regulardashboard.my-missions" | transloco
                        }}</span>
                        <!-- <a
                            [routerLink]="'my-missions'"
                            class="text-lg font-semibold truncate"
                            >{{
                                "regulardashboard.see-all-my-missions"
                                    | transloco
                            }}</a
                        > -->
                    </div>
                    <ng-container *ngIf="!isLoading; else skeletonRowCard">
                        <mission-registered-row-card
                            class="mb-6"
                            *ngFor="let mission of recap.missions"
                            [mission]="mission"
                        >
                        </mission-registered-row-card>
                        <div
                            *ngIf="recap.missions.length === 0"
                            class="flex flex-col w-full text-center"
                        >
                            <span
                                class="flex items-center font-bold m-auto text-2xl"
                            >
                                {{
                                    "regulardashboard.no-active-missions"
                                        | transloco
                                }}
                                <mat-icon
                                    class="ml-1 text-black text-2xl"
                                    [svgIcon]="'heroicons_outline:face-frown'"
                                ></mat-icon>
                            </span>
                            <span class="mt-1">{{
                                "regulardashboard.no-active-missions-description"
                                    | transloco
                            }}</span>
                        </div>
                    </ng-container>
                </div>

                <!-- Recommended missions -->
                <div class="flex flex-col">
                    <div
                        class="flex flex-col md:flex-row md:items-end justify-between pb-1 mb-6 border-b-2 border-slate-300"
                    >
                        <span class="text-3xl md:text-4xl truncate font-bold">{{
                            "regulardashboard.missions-of-interest" | transloco
                        }}</span>
                        <a
                            [routerLink]="'/missions'"
                            class="text-lg truncate font-semibold"
                            >{{
                                "regulardashboard.see-all-missions" | transloco
                            }}</a
                        >
                    </div>
                    <div
                        class="grid grid-cols-1 md:grid-cols-2 xxl:grid-cols-3 gap-4"
                        *ngIf="!isLoading; else skeletonRowCard"
                    >
                        <mission-grid-card
                            *ngIf="recap.recommendedMissions.at(0)"
                            [mission]="recap.recommendedMissions.at(0)"
                        >
                        </mission-grid-card>

                        <mission-grid-card
                            *ngIf="recap.recommendedMissions.at(1)"
                            [mission]="recap.recommendedMissions.at(1)"
                        >
                        </mission-grid-card>

                        <mission-grid-card
                            class="hidden xxl:block"
                            *ngIf="recap.recommendedMissions.at(2)"
                            [mission]="recap.recommendedMissions.at(2)"
                        >
                        </mission-grid-card>
                    </div>
                </div>
            </div>

            <!-- Right -->
            <div class="w-1/3 xxl:w-1/4 pl-6 gap-8 hidden lg:flex flex-col">
                <ng-container *ngIf="!isLoading; else skeleton48">
                    <onboarding-progress
                        *ngIf="showOnboardingTasks$ | async"
                        [lightMode]="false"
                        [tasks]="onboardingTasks$ | async"
                        [actionsBackground]="'bg-white'"
                    ></onboarding-progress
                ></ng-container>

                <regular-dashboard-calendar
                    *ngIf="!isLoading; else skeleton48"
                    [missions]="recap.missions"
                >
                </regular-dashboard-calendar>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #skeletonRowCard>
    <div class="shadow rounded-2xl h-64 w-full overflow-hidden">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full"
        ></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #skeleton48>
    <div class="rounded-2xl w-full h-48 mb-8 shadow overflow-hidden">
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="w-full"
        ></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #skeletonHeader>
    <div class="grid grid-cols-4 gap-4">
        <div class="col-span-4 lg:col-span-2 grid grid-cols-2 gap-4">
            <div class="rounded-2xl w-full min-h-24 shadow-lg overflow-hidden">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>
            <div class="rounded-2xl w-full min-h-24 shadow-lg overflow-hidden">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>
            <div class="rounded-2xl w-full min-h-24 shadow-lg overflow-hidden">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>
            <div class="rounded-2xl w-full min-h-24 shadow-lg overflow-hidden">
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>
        </div>

        <div
            class="col-span-2 lg:col-span-1 rounded-2xl shadow-lg overflow-hidden"
        >
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full h-full"
            ></ngx-skeleton-loader>
        </div>
        <div
            class="col-span-2 lg:col-span-1 rounded-2xl shadow-lg overflow-hidden"
        >
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full h-full"
            ></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
