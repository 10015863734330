<div
    *ngrxLet="requestsRepartition$ as requestsRepartition"
    class="w-full p-6 bg-white shadow-lg rounded-2xl flex flex-col gap-4"
>
    <div class="flex gap-2 items-center">
        <mat-icon [svgIcon]="'feather:bar-chart-2'"></mat-icon>
        <span class="text-2xl font-bold">{{
            "donationcampaign.choices-repartition" | transloco
        }}</span>
    </div>

    <div class="flex flex-col gap-4 divide-y-2">
        <div
            class="grid grid-cols-12 gap-8 pt-4 items-center"
            *ngFor="let requestRepartition of requestsRepartition"
        >
            <div class="col-span-5 flex gap-6">
                <classic-avatar
                    [size]="16"
                    [borderSize]="4"
                    [avatarUrl]="requestRepartition.project.association.logoUrl"
                    [fallbackLetter]="
                        requestRepartition.project.association.name.at(0)
                    "
                >
                </classic-avatar>
                <div class="flex flex-col justify-center gap-2">
                    <span class="font-bold text-xl">{{
                        requestRepartition.project.name
                    }}</span>
                    <span>{{
                        requestRepartition.project.association.name
                    }}</span>
                </div>
            </div>

            <div
                class="col-span-2 flex flex-col gap-2 items-center justify-center text-center"
            >
                <span class="text-3xl font-extrabold">{{
                    requestRepartition.requestsCount
                }}</span>
                <span>{{
                    (requestRepartition.requestsCount > 1
                        ? "donationcampaign.selections"
                        : "donationcampaign.selection"
                    ) | transloco
                }}</span>
            </div>

            <div class="col-span-5">
                <bslk-classic-progress-bar
                    [progress]="requestRepartition.percentage"
                ></bslk-classic-progress-bar>
            </div>
        </div>
    </div>
</div>
