<base-dialog
    [title]="
        'shared.upload-image.title-' +
            (this.data.extraAllowedFormats ? 'file' : 'image') | transloco
    "
    (onClose)="close()"
    [showActions]="true"
>
    <div body class="pb-2 max-w-full w-full">
        <div class="flex flex-col md:flex-row gap-4">
            <div
                bslkFileDnd
                (fileDropped)="onFileDropped($event)"
                class="flex bg-accent-100 shadow-md px-10 flex-col justify-center items-center w-full min-w-120 md:w-7/12 h-64 border-2 border-dashed border-primary"
            >
                <mat-icon
                    class="text-primary-400 icon-size-14"
                    [svgIcon]="'heroicons_outline:folder-arrow-down'"
                ></mat-icon>
                <span class="text-2xl truncate font-bold text-primary">
                    {{ "shared.upload-image.drag-and-drop" | transloco }}
                </span>
                <div class="flex items-center w-36 my-4">
                    <div class="flex-auto mt-px border-t"></div>
                    <div class="mx-2 text-secondary">
                        {{ "shared.upload-image.or" | transloco }}
                    </div>
                    <div class="flex-auto mt-px border-t"></div>
                </div>
                <bslk-classic-button
                    class="mr-2"
                    [icon]="'heroicons_outline:arrow-down-tray'"
                    [background]="'primary'"
                    (onClick)="fileInput.click()"
                    [label]="'shared.upload-image.choose-file' | transloco"
                >
                </bslk-classic-button>
            </div>
            <div
                *ngIf="data?.infoTextKey || fileHasError || selectedFile"
                class="flex w-full md:w-5/12 flex-col gap-y-4"
            >
                <fuse-alert
                    *ngIf="data?.infoTextKey"
                    class="mb-4"
                    [appearance]="'soft'"
                    [type]="'info'"
                >
                    {{ data.infoTextKey | transloco }}
                </fuse-alert>

                <div
                    *ngIf="selectedFile"
                    class="border-2 justify-between truncate flex items-center px-4 py-2"
                >
                    <div
                        class="flex gap-x-3 items-center justify-between w-full"
                    >
                        <div class="flex truncate">
                            <mat-icon
                                class="icon-size-10"
                                [svgIcon]="'heroicons_outline:photo'"
                            ></mat-icon>
                            <div class="ml-2 flex flex-col truncate">
                                <span class="font-bold text-xl truncate">
                                    {{ selectedFile.name }}
                                </span>
                                <span class="font-semibold truncate">{{
                                    formatSize
                                }}</span>
                            </div>
                        </div>
                        <mat-icon
                            (click)="removeFile()"
                            class="icon-size-6 cursor-pointer"
                            [svgIcon]="'heroicons_outline:x-mark'"
                        ></mat-icon>
                    </div>
                </div>

                <fuse-alert
                    *ngIf="fileHasError || data.error$ | async"
                    class="mt-4 flex justify-center"
                    [appearance]="'outline'"
                    [type]="'error'"
                >
                    <div class="flex flex-col gap-1">
                        <span *ngIf="fileTypeError">
                            {{
                                "shared.upload-image.wrong-entension"
                                    | transloco
                            }}
                        </span>
                        <span *ngIf="fileSizeError">
                            {{ "shared.upload-image.too-big" | transloco }}
                        </span>
                        <span *ngIf="data.error$ | async">
                            {{
                                "shared.upload-image.errors." +
                                    (data.error$ | async) | transloco
                            }}
                        </span>
                    </div>
                </fuse-alert>
            </div>
        </div>
    </div>

    <!-- Actions -->
    <div actions class="flex justify-end">
        <input
            hidden
            #fileInput
            type="file"
            (input)="onFileSelected($event)"
            [accept]="acceptString"
        />
        <bslk-classic-button
            [background]="'warn'"
            (onClick)="onUpload()"
            [isDisabled]="
                !selectedFile || (data?.isLoading$ | async) || fileHasError
            "
            [isLoading]="data?.isLoading$ | async"
            [label]="'shared.upload-image.upload' | transloco"
        >
        </bslk-classic-button>
    </div>
</base-dialog>
