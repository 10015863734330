import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { BslkChoiceCardComponent } from '@bslk/components/cards/choice/choice-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';

@Component({
    selector: 'regular-or-asso',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './regular-or-asso-dialog.component.html',
    imports: [
        MatDialogModule,
        TranslocoModule,
        MatIconModule,
        BaseDialogComponent,
        BslkChoiceCardComponent,
        RouterLink,
    ],
})
export class RegularOrAssoDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<RegularOrAssoDialogComponent>,
        private router: Router
    ) {}

    onClick(event: 'regular' | 'asso') {
        if (event === 'asso') {
            this.router.navigateByUrl('/a/sign-up');
        } else {
            this.router.navigateByUrl('/sign-up');
        }
        this.close();
    }

    close() {
        this.dialogRef.close();
    }
}
