import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkRadioGroupBinaryComponent } from '@bslk/components/form/radio-group-binary/radio-group-binary.component';
import { BslkStarRatingComponent } from '@bslk/components/form/rating/star-rating.component';
import {
    BslkAdvancedSelectSyncComponent,
    BslkAdvancedSelectSyncInput,
} from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { BslkSliderComponent } from '@bslk/components/form/slider/slider.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EActivityField } from 'app/api';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { MissionRatingFormService } from 'app/shared/services/form/mission-rating-form.service';
import { TranslationService } from 'app/shared/services/transloco/translation.service';
import { MissionManageSelectors } from 'app/store/mission/mission-manage/mission-manage.selectors';
import { MissionRatingActions } from 'app/store/mission/mission-rating/mission-rating.action';
import { MissionRatingSelectors } from 'app/store/mission/mission-rating/mission-rating.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'mission-post-rating',
    standalone: true,
    templateUrl: './mission-post-rating.component.html',
    imports: [
        AsyncPipe,
        ClassicAvatarComponent,
        BslkClassicCardComponent,
        BslkClassicButtonComponent,
        BslkRadioGroupBinaryComponent,
        BslkAdvancedSelectSyncComponent,
        BslkSliderComponent,
        FormsModule,
        FormsModule,
        FuseAlertComponent,
        MatButtonModule,
        MatFormFieldModule,
        MatSliderModule,
        MatInputModule,
        BslkStarRatingComponent,
        ReactiveFormsModule,
        LetDirective,
        NgIf,
        TranslocoModule,
    ],
})
export class MissionPostRatingComponent implements OnInit, OnDestroy {
    private unsubscribeAll: Subject<any> = new Subject<any>();

    nameFirstLetter$ = this.store.select(UserSelectors.selectNameFirstLetter);
    avatarUrl$ = this.store.select(UserSelectors.selectAvatarUrl);

    canPostRating$ = this.store.select(
        MissionManageSelectors.selectCanPostRating
    );

    hasPostedRating$ = this.store.select(
        MissionManageSelectors.selectHasPostedRating
    );

    isLoading$ = this.store.select(MissionRatingSelectors.selectIsLoading);

    activityFieldsAdvancedSelectItems: BslkAdvancedSelectSyncInput[];
    translationsLoaded = false;
    formSubmitted = false;

    constructor(
        private store: Store,
        private missionRatingFormService: MissionRatingFormService,
        private translationService: TranslationService
    ) {}
    get formGroup() {
        return this.missionRatingFormService.getFormGroup;
    }

    ngOnInit(): void {
        this.translationService
            .getTranslatedEnum(EActivityField, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.activityFieldsAdvancedSelectItems = translatedItems;
                this.translationsLoaded = true;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }

    post() {
        this.formSubmitted = true;
        if (!this.formGroup.valid) {
            this.formGroup.markAllAsTouched();
            return;
        }
        this.store.dispatch(MissionRatingActions.postRatingRequest());
    }
}
