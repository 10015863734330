<base-dialog [showHeader]="false" (onClose)="close()" [showActions]="true">
    <mat-radio-group
        body
        class="flex flex-col"
        [(ngModel)]="selectedOption"
        aria-label="Select an option"
    >
        <mat-radio-button value="single">{{
            "createmission.remove-single-slot" | transloco
        }}</mat-radio-button>
        <mat-radio-button value="all">{{
            "createmission.remove-all-slots" | transloco
        }}</mat-radio-button>
    </mat-radio-group>

    <div class="flex justify-end gap-2" actions>
        <button mat-stroked-button (click)="close()">
            {{ "shared.common.cancel" | transloco }}
        </button>
        <button (click)="confirm()" mat-flat-button class="bg-warn text-white">
            <span class="ml-1">{{ "shared.common.confirm" | transloco }}</span>
        </button>
    </div>
</base-dialog>
