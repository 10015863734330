import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DualQuizChallengeActions } from 'app/store/dual-quiz/challenge/challenge.action';
import { DualQuizChallengeSelectors } from 'app/store/dual-quiz/challenge/challenge.selectors';

@Component({
    selector: 'dual-quiz-challenge-answer',
    templateUrl: './answer.component.html',
    standalone: true,
    imports: [LetDirective, AsyncPipe, NgClass, NgIf, MatIconModule],
})
export class DualQuizChallengeAnswerComponent {
    @Input() choice: number;
    @Input() answer: string;

    correctAnswer$ = this.store.select(
        DualQuizChallengeSelectors.selectCorrectAnswer
    );

    lastChoice$ = this.store.select(
        DualQuizChallengeSelectors.selectLastChoice
    );

    constructor(private store: Store) {}

    onAnswer() {
        this.store.dispatch(
            DualQuizChallengeActions.answerQuestion({
                choice: this.choice,
            })
        );
    }
}
