import { NgIf } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { FormControlComponent } from '../form-control-component';

@Component({
    selector: 'bslk-slider',
    standalone: true,
    templateUrl: './slider.component.html',
    imports: [
        MatSliderModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatRadioModule,
        NgIf,
    ],
})
export class BslkSliderComponent extends FormControlComponent {
    @Input() min: number = 1;
    @Input() max: number = 50;
    @Input() step = 1;
    @Input() unitLabel: string;

    ngModelValue: any; // Fallback value when FormControl is not provided

    view: 'slider' | 'input';

    onValueChange(newValue: number) {
        this.setValue(newValue);
        if (this.value >= this.max) {
            this.view = 'input';
        }
    }

    extraOnChanges = (changes: SimpleChanges) => {
        if (changes.value) {
            if (this.value >= this.max) {
                this.view = 'input';
            }
        }
    };

    extraOnInit = () => {
        this.view = this.value >= this.max ? 'input' : 'slider';
    };
}
