import {
    MissionRatingViewModel,
    MissionRatingViewModelPaginatedListDto,
} from 'app/api';
import { createRessourceListActionGroup } from '../../shared/ressource-list/ressource-list-action-group-creator';

export const MissionRatingListActions = createRessourceListActionGroup<
    MissionRatingViewModel,
    MissionRatingViewModelPaginatedListDto
>('MissionRatingList');
