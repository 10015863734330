import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkColor } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'bslk-classic-progress-bar',
    standalone: true,
    templateUrl: './classic-progress-bar.component.html',
    imports: [
        NgIf,
        MatIconModule,
        TranslocoModule,
        MatTooltipModule,
        TranslocoModule,
    ],
})
export class BslkClassicProgressBar {
    @Input() progress: number;

    get color(): BslkColor {
        if (this.progress > 75) {
            return 'quaternary';
        } else if (this.progress > 25) {
            return 'warn';
        }
        return 'primary';
    }
}
