<ng-container *ngrxLet="isSignedIn$ as isSignedIn">
    <auth-template
        class="w-full"
        [title]="'signup.sign-up' | transloco"
        *ngrxLet="isLoading$ as isLoading"
    >
        <div
            *ngrxLet="needsToValidateEmail$ as needsToValidateEmail"
            class="mt-4"
        >
            <sign-up-auth
                *ngIf="!needsToValidateEmail"
                [userRole]="EUserRole.Regular"
            >
                <div class="mt-4">
                    <bslk-classic-button
                        class="w-full"
                        [background]="'primary'"
                        [size]="'large'"
                        [type]="'submit'"
                        [label]="'signup.continue' | transloco"
                    ></bslk-classic-button>
                </div>
            </sign-up-auth>
            <fuse-alert
                *ngIf="needsToValidateEmail"
                class="flex justify-center"
                [appearance]="'outline'"
                [type]="'info'"
            >
                {{ "signup.validate-email" | transloco }}
            </fuse-alert>
        </div>

        <div footer>
            <ng-container *ngIf="!isSignedIn">
                <div class="flex justify-center items-baseline font-medium">
                    {{ "signup.already-registered" | transloco }} &nbsp;
                    <a class="font-bold" [routerLink]="'/sign-in'">
                        <button mat-flat-button>
                            {{ "signup.sign-in" | transloco }}
                        </button>
                    </a>
                </div>
            </ng-container>
            <ng-container *ngIf="isSignedIn">
                <div class="flex justify-center items-baseline font-medium">
                    <a class="font-bold" [routerLink]="'/sign-out'">{{
                        "signup.sign-out" | transloco
                    }}</a>
                </div>
            </ng-container>

            <div
                class="flex justify-center items-baseline mt-0.5 text-sm text-gray-500"
            >
                *{{ "signup.required-information" | transloco }}
            </div>
        </div>
    </auth-template>
</ng-container>
