import { NgIf } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormArray, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import {
    BslkAdvancedSelectSyncComponent,
    BslkAdvancedSelectSyncInput,
} from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { EUnit } from 'app/api';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { TranslationService } from 'app/shared/services/transloco/translation.service';
import { CompanyCsrApproachActions } from 'app/store/company/csr-approach/csr-approach.actions';
import { isNil } from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'company-csr-approach-edit-metric',
    templateUrl: './edit.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        BslkAdvancedSelectSyncComponent,
        BslkLengthInfoComponent,
        FuseDrawerComponent,
        NgIf,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        BslkClassicButtonComponent,
        MatIconModule,
    ],
})
export class CompanyCsrApproachEditMetricComponent
    implements OnInit, OnChanges, OnDestroy
{
    @ViewChild('editMetricDrawer') drawer: FuseDrawerComponent;

    @Input() isCreating: boolean = false;
    @Input() editedMetricIndex: number;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    EUnit: EUnit;

    unsubscribeAll: Subject<any> = new Subject<any>();

    unitsTranslationsLoaded: boolean = false;
    unitsAdvancedSelectItems: BslkAdvancedSelectSyncInput[];

    constructor(
        private formService: CompanyCsrApproachFormService,
        private store: Store,
        private translationService: TranslationService
    ) {}

    get formArray() {
        return this.formService.getFormGroup.controls.metrics as FormArray;
    }

    get formGroup() {
        // Return edited metric form group, or last form group if is creating
        return this.formArray.controls.at(
            this.editedMetricIndex ?? this.formArray.controls.length - 1
        );
    }

    ngOnInit(): void {
        this.translationService
            .getTranslatedEnum(EUnit, this.unsubscribeAll)
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((translatedItems) => {
                this.unitsAdvancedSelectItems = translatedItems;
                this.unitsTranslationsLoaded = true;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            !isNil(changes.editedMetricIndex?.currentValue) ||
            changes.isCreating?.currentValue
        ) {
            this.drawer.open();
        }

        if (changes.isCreating?.currentValue) {
            this.formArray.controls.push(this.formService.createMetric());
        }
    }

    openedChanged(isOpened: boolean) {
        if (!isOpened) {
            if (this.isCreating) {
                // Remove non-validated created form array
                this.formArray.controls.pop();
            }

            this.close.emit();
            this.isCreating = false;
            this.editedMetricIndex = null;
        }
    }

    update() {
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
        this.isCreating = false;
        this.editedMetricIndex = null;
        this.drawer.close();
    }

    ngOnDestroy() {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
