// dialog.service.ts

import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { EMissionRecurrenceType, MissionSlotViewModel } from 'app/api';
import { MissionFormService } from 'app/shared/services/form/mission-form.service';
import { computeWorkingHoursBetweenDates } from 'app/shared/utils/date-helpers.utils';
import { MissionSlotUtils } from 'app/shared/utils/extensions/mission-slots/mission-slots.utils';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MissionSlotBuilderService {
    constructor(private missionFormService: MissionFormService) {}

    slots: Subject<MissionSlotViewModel[]> = new Subject<
        MissionSlotViewModel[]
    >();

    currentSlotBuilder: MissionSlotViewModel = {
        id: 0,
    };

    get isCurrentSlotReady() {
        return (
            this.currentSlotBuilder.startDate && this.currentSlotBuilder.endDate
        );
    }

    get slotsSubject() {
        return this.slots;
    }

    get isDrawing() {
        return (
            this.currentSlotBuilder.startDate &&
            !this.currentSlotBuilder.endDate
        );
    }

    softReset() {
        this.currentSlotBuilder = {
            id: 0,
        };
    }

    hardReset() {
        this.softReset();
        this.missionFormService.resetMissionSlotFormArray();
    }

    handleDate(dateStr: string) {
        let dateTime = DateTime.fromISO(dateStr).set({ hour: 17 });
        // Either there is no start date set yet or date is before start date, so we set the new start date in both cases
        if (
            !this.currentSlotBuilder.startDate ||
            DateTime.fromISO(this.currentSlotBuilder.startDate) >= dateTime
        ) {
            dateTime = dateTime.set({ hour: 9 });
            this.currentSlotBuilder.startDate = dateTime.toString();
        } else if (!this.currentSlotBuilder.endDate) {
            this.currentSlotBuilder.estimatedTimeInHours =
                computeWorkingHoursBetweenDates(
                    DateTime.fromISO(this.currentSlotBuilder.startDate),
                    dateTime
                );
            this.currentSlotBuilder.endDate = dateTime.toString();
        }

        return this.isCurrentSlotReady ? this.buildSlot() : null;
    }

    buildSlot() {
        const missionSlotsFormArray = this.missionFormService.getFormGroup
            .controls.missionSlots as FormArray;
        const builtSlotFormGroup = this.missionFormService.createMissionSlot(
            this.currentSlotBuilder
        );

        missionSlotsFormArray.push(builtSlotFormGroup);
        this.currentSlotBuilder = { id: 0 };
        return builtSlotFormGroup;
    }

    handleRecurrencyChange(missionSlotFormGroup: FormGroup) {
        const missionSlot: MissionSlotViewModel = missionSlotFormGroup.value;
        if (
            missionSlot.recurrenceType === EMissionRecurrenceType.Custom &&
            (!missionSlot.customRecurrenceUnit ||
                !missionSlot.customRecurrenceType)
        ) {
            return;
        }
        const recurrencySlotsFormGroups =
            MissionSlotUtils.generateRecurrentSlots(missionSlot).map((s) =>
                this.missionFormService.createMissionSlot(s)
            );
        const slotsFormArray = this.missionFormService.getFormGroup.controls
            .missionSlots as FormArray;

        // Remove old recurrency form groups
        for (let i = slotsFormArray.length - 1; i >= 0; i--) {
            const currentSlot = slotsFormArray.at(i)
                .value as MissionSlotViewModel;

            if (
                currentSlot.parentGuid &&
                currentSlot.parentGuid === missionSlot.guid
            ) {
                slotsFormArray.removeAt(i, { emitEvent: false });
            }
        }

        // Add the new recurrency form groups
        recurrencySlotsFormGroups.forEach((formGroup) => {
            slotsFormArray.push(formGroup, { emitEvent: false });
        });

        // Manually trigger the value and status change once
        slotsFormArray.updateValueAndValidity({ emitEvent: true });
    }
}
