<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    (openedChanged)="openedChanged($event)"
    [name]="'editPillarDrawer'"
    [position]="'right'"
    #editPillarDrawer
>
    <div class="flex flex-col w-full max-h-screen bg-card">
        <div class="flex gap-2 flex-row items-center p-6 text-white bg-warn">
            <div class="ml-3 text-2xl font-semibold tracking-tight">
                {{ "csrapproach.configure-a-pillar" | transloco }}
            </div>
            <button
                class="ml-auto"
                mat-icon-button
                (click)="editPillarDrawer.close()"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"
                ></mat-icon>
            </button>
        </div>

        <div
            class="p-4 flex flex-col gap-5 justify-between h-full overflow-auto"
        >
            <form
                *ngIf="formGroup"
                class="p-3 flex flex-col gap-3"
                [formGroup]="formGroup"
            >
                <div class="w-full flex flex-col">
                    <mat-form-field [subscriptSizing]="'dynamic'">
                        <mat-label>{{
                            "csrapproach.name" | transloco
                        }}</mat-label>
                        <input matInput [formControlName]="'name'" required />
                        <mat-error
                            *ngIf="formGroup.controls.name.hasError('required')"
                        >
                            {{ "bslk.form.required" | transloco }}
                        </mat-error>
                    </mat-form-field>
                    <bslk-length-info
                        [text]="formGroup.controls.name.value"
                        [maxLength]="100"
                    ></bslk-length-info>
                </div>
                <div class="w-full flex flex-col">
                    <mat-form-field [subscriptSizing]="'dynamic'">
                        <mat-label>{{
                            "csrapproach.description" | transloco
                        }}</mat-label>
                        <textarea
                            matInput
                            [formControlName]="'description'"
                        ></textarea>
                        <mat-error
                            *ngIf="
                                formGroup.controls.description.hasError(
                                    'required'
                                )
                            "
                        >
                            {{ "bslk.form.required" | transloco }}
                        </mat-error>
                    </mat-form-field>

                    <bslk-length-info
                        [text]="formGroup.controls.description.value"
                        [maxLength]="250"
                    ></bslk-length-info>
                </div>

                <div class="flex flex-col gap-2">
                    <span class="font-bold">{{
                        "csrapproach.actions-implemented" | transloco
                    }}</span>

                    <!-- Action forms -->
                    <div
                        *ngFor="
                            let actionFormGroup of actionsFormArray.controls;
                            let index = index
                        "
                        class="border-b"
                    >
                        <mat-accordion>
                            <mat-expansion-panel
                                class="mat-elevation-z0 remove-mat-expansion-panel-padding"
                            >
                                <mat-expansion-panel-header class="pl-0">
                                    <mat-panel-description
                                        class="text-warn font-bold"
                                    >
                                        {{ "csrapproach.action" | transloco }}
                                        #{{ index + 1 }}
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div class="flex flex-col gap-1">
                                    <div class="w-full flex flex-col">
                                        <mat-form-field
                                            [subscriptSizing]="'dynamic'"
                                        >
                                            <mat-label>{{
                                                "csrapproach.name" | transloco
                                            }}</mat-label>
                                            <input
                                                matInput
                                                [formControl]="
                                                    actionFormGroup.controls
                                                        .name
                                                "
                                                required
                                            />
                                            <mat-error
                                                *ngIf="
                                                    actionFormGroup.controls.name.hasError(
                                                        'required'
                                                    )
                                                "
                                            >
                                                {{
                                                    "bslk.form.required"
                                                        | transloco
                                                }}
                                            </mat-error>
                                        </mat-form-field>
                                        <bslk-length-info
                                            [text]="
                                                actionFormGroup.controls.name
                                                    .value
                                            "
                                            [maxLength]="100"
                                        ></bslk-length-info>
                                    </div>
                                    <div class="w-full flex flex-col">
                                        <mat-form-field
                                            [subscriptSizing]="'dynamic'"
                                        >
                                            <mat-label>{{
                                                "csrapproach.description"
                                                    | transloco
                                            }}</mat-label>
                                            <input
                                                matInput
                                                [formControl]="
                                                    actionFormGroup.controls
                                                        .description
                                                "
                                                required
                                            />
                                            <mat-error
                                                *ngIf="
                                                    actionFormGroup.controls.description.hasError(
                                                        'required'
                                                    )
                                                "
                                            >
                                                {{
                                                    "bslk.form.required"
                                                        | transloco
                                                }}
                                            </mat-error>
                                        </mat-form-field>
                                        <bslk-length-info
                                            [text]="
                                                actionFormGroup.controls
                                                    .description.value
                                            "
                                            [maxLength]="250"
                                        ></bslk-length-info>
                                    </div>

                                    <bslk-classic-button
                                        class="self-end mb-2"
                                        [icon]="'heroicons_outline:trash'"
                                        (onClick)="removeAction(index)"
                                        [label]="
                                            'csrapproach.remove' | transloco
                                        "
                                    ></bslk-classic-button>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                    <bslk-classic-button
                        class="mt-2"
                        [background]="'primary'"
                        [icon]="'heroicons_outline:plus'"
                        (onClick)="addAction()"
                        [label]="'csrapproach.add-an-action' | transloco"
                    ></bslk-classic-button>
                </div>

                <div class="flex items-center justify-end border-t p-4 mt-4">
                    <bslk-classic-button
                        [isDisabled]="!formGroup.valid"
                        (onClick)="update()"
                        [label]="'csrapproach.validate' | transloco"
                    ></bslk-classic-button>
                </div>
            </form>
        </div>
    </div>
</fuse-drawer>
