import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ReportService } from 'app/api';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { ReportActions } from './report.action';

@Injectable()
export class ReportEffects {
    downloadReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportActions.downloadReportRequest),
            mergeMap(({ config }) => {
                return this.reportService
                    .reportPost({ reportConfigViewModel: config }, 'response')
                    .pipe(
                        map((response) => {
                            const contentDisposition = response.headers.get(
                                'Content-Disposition'
                            );
                            let filename = 'exported_data.csv'; // Default filename
                            if (contentDisposition) {
                                const matches =
                                    /filename[^;=\n]*=(['"]?)([^'";\n]*)\1/.exec(
                                        contentDisposition
                                    );
                                if (matches != null && matches[2]) {
                                    filename = matches[2];
                                }
                            }
                            return ReportActions.downloadReportRequestSuccess({
                                blob: response.body,
                                filename,
                            });
                        }),
                        catchError((error) =>
                            of(
                                ReportActions.downloadReportRequestFailure({
                                    error: error?.error ?? error,
                                })
                            )
                        )
                    );
            })
        )
    );

    triggerDownload$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ReportActions.downloadReportRequestSuccess),
                tap(({ blob, filename }) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                })
            ),
        { dispatch: false }
    );
    constructor(
        private actions$: Actions,
        private reportService: ReportService
    ) {}
}
