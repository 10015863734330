import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { MissionManageActions } from 'app/store/mission/mission-manage/mission-manage.action';

export type ConfirmEstimationDialogInput = {
    isAdmin: boolean;
    userId?: number;
    estimatedTimeInHours: number;
};

@Component({
    selector: 'confirm-estimation-dialog',
    standalone: true,
    templateUrl: './confirm-estimation-dialog.component.html',
    imports: [
        BaseDialogComponent,
        FuseAlertComponent,
        NgIf,
        MatDialogModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        TranslocoModule,
    ],
})
export class ConfirmEstimationDialogComponent implements OnInit {
    estimatedTimeInHours: number;

    constructor(
        private store: Store,
        private dialogRef: MatDialogRef<ConfirmEstimationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmEstimationDialogInput
    ) {}

    ngOnInit(): void {
        this.estimatedTimeInHours = this.data.estimatedTimeInHours;
    }

    confirm() {
        if (this.data.isAdmin) {
            this.store.dispatch(
                MissionManageActions.acceptValidationSlotRequest({
                    userId: this.data.userId,
                    estimatedTimeInHours: this.estimatedTimeInHours,
                })
            );
        } else {
            this.store.dispatch(
                MissionManageActions.validateSlotRequest({
                    estimatedTimeInHours: this.estimatedTimeInHours,
                })
            );
        }
        this.close();
    }

    close() {
        this.dialogRef.close();
    }
}
