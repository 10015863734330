import { DatePipe, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { BslkMenuComponent } from '@bslk/components/menu/menu.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { DonationCampaignViewModel, EDonationCampaignState } from 'app/api';
import {
    ECompanyAdminUrl,
    EExternalUrl,
    UrlHelpers,
} from 'app/shared/utils/url-helpers.utils';
import { DonationCampaignListActions } from 'app/store/donation/donation-campaign-list/donation-campaign-list.actions';

@Component({
    selector: 'donation-campaign-card',
    templateUrl: './card.component.html',
    standalone: true,
    imports: [
        MatIconModule,
        TranslocoModule,
        BslkTagComponent,
        NgSwitchCase,
        NgSwitch,
        BslkMenuComponent,
        DatePipe,
    ],
})
export class DonationCampaignCardComponent {
    @Input() campaign: DonationCampaignViewModel;

    EDonationCampaignState = EDonationCampaignState;

    constructor(private store: Store, private router: Router) {}

    open() {
        this.router.navigateByUrl(
            UrlHelpers.getCompanyAdminUrl(
                ECompanyAdminUrl.CompanyAdminDonationCampaignPage,
                { id: this.campaign.id }
            )
        );
    }

    onMenuAction(event: string) {
        if (event === 'modify') {
            return this.modifyCampaign();
        }
        if (event === 'archive') {
            return this.archiveCampaign();
        }
        if (event === 'preview') {
            return this.openPreview();
        }
    }

    modifyCampaign() {
        this.store.dispatch(
            DonationCampaignListActions.SelectItem({ id: this.campaign.id })
        );
    }

    archiveCampaign() {
        this.store.dispatch(
            DonationCampaignListActions.DeleteItem({ id: this.campaign.id })
        );
    }

    openPreview() {
        window.open(
            UrlHelpers.getExternalUrl(EExternalUrl.DonationGiftPreview, {
                id: this.campaign.accessToken,
            }),
            '_blank'
        );
    }
}
