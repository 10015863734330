import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkAdvancedChartComponent } from '@bslk/components/charts/bslk-advanced-chart/bslk-advanced-chart.component';
import { BslkColor, BslkHexColors } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DecimalValueChart } from 'app/api';
import { CompanyAdminDashboardActions } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.actions';
import { CompanyAdminDashboardSelectors } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.selectors';
import { isNil } from 'lodash-es';
import { takeUntil } from 'rxjs';
import { CompanyAdminDashboardChartComponent } from '../chart.component';

@Component({
    selector: 'satisfaction-rate',
    templateUrl: './satisfaction-rate.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        BslkAdvancedChartComponent,
        MatIconModule,
        TranslocoModule,
        LetDirective,
    ],
})
export class SatisfactionRateComponent
    extends CompanyAdminDashboardChartComponent
    implements OnInit
{
    hasData = true;

    constructor(
        protected store: Store,
        protected cdRef: ChangeDetectorRef,
        protected datePipe: DatePipe
    ) {
        super(store, datePipe, cdRef, 'satisfaction-rate');
    }

    get ratingVariables(): {
        bslkColor: BslkColor;
        hexaCode: string;
        icon: string;
    } {
        const rate = this.series?.at(0) as number;
        if (isNil(rate)) {
            return null;
        }
        if (rate >= 75) {
            return {
                bslkColor: 'quaternary',
                hexaCode: BslkHexColors.quaternary,
                icon: 'feather:smile',
            };
        } else if (rate >= 50) {
            return {
                bslkColor: 'warn',
                hexaCode: BslkHexColors.warn,
                icon: 'feather:meh',
            };
        }
        return {
            bslkColor: 'primary',
            hexaCode: BslkHexColors.primary,
            icon: 'feather:frown',
        };
    }

    get gradientColor(): BslkColor {
        return this.ratingVariables?.bslkColor;
    }

    get hexColor(): string {
        return this.ratingVariables?.hexaCode;
    }

    get icon(): string {
        return this.ratingVariables?.icon;
    }

    ngOnInit(): void {
        this.store.dispatch(
            CompanyAdminDashboardActions.checkIfKpiIsLoaded({
                kpi: this.kpiName,
            })
        );

        this.store
            .select(
                CompanyAdminDashboardSelectors.selectKpiResult(this.kpiName)
            )
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((response: DecimalValueChart) => {
                if (response) {
                    this.hasData = response.hasData;
                    if (this.hasData) {
                        this.series = [response.value];
                        this.buildOptions();
                    }
                }
            });
    }

    buildOptions() {
        this.chartOptions = {
            chart: {
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    startAngle: -105,
                    endAngle: 105,
                    offsetY: 40,
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            fontSize: '50px',
                            fontFamily: 'Poppins',
                            fontWeight: 900,
                            offsetY: 80,
                        },
                    },
                },
            },
            grid: {
                padding: {
                    top: -70,
                    bottom: -50,
                    left: -50,
                    right: -50,
                },
            },
            fill: {
                colors: [this.hexColor],
            },
            responsive: [
                {
                    breakpoint: 1536,
                    options: {
                        plotOptions: {
                            radialBar: {
                                dataLabels: {
                                    value: {
                                        fontSize: '40px',
                                        fontFamily: 'Poppins',
                                        fontWeight: 900,
                                        offsetY: 60,
                                    },
                                },
                            },
                        },
                    },
                },
            ],
        };
    }
}
