<div
    (click)="toggleIsExpanded()"
    class="cursor-pointer flex w-full justify-between"
>
    <span class="font-bold"
        >{{ "missions.filters.location" | transloco }}
    </span>
    <div>
        <mat-icon
            *ngIf="!isExpanded"
            class="font-bold icon-size-5 mr-1"
            [svgIcon]="'heroicons_solid:chevron-down'"
        >
        </mat-icon>
        <mat-icon
            *ngIf="isExpanded"
            class="font-bold icon-size-5 mr-1"
            [svgIcon]="'heroicons_solid:chevron-up'"
        >
        </mat-icon>
    </div>
</div>
<div *ngIf="isExpanded" class="flex flex-col w-full mt-2">
    <bslk-classic-toggle
        [choices]="[
            { text: 'missions.filters.any' | transloco, value: null },
            { text: 'missions.filters.remote' | transloco, value: true },
            { text: 'missions.filters.on-site' | transloco, value: false }
        ]"
        [value]="isRemote"
        [disableDefaultValueSet]="true"
        [size]="'very-small'"
        (valueChange)="setIsRemote($event)"
    ></bslk-classic-toggle>

    <ng-container *ngIf="isRemote !== true">
        <mat-form-field [subscriptSizing]="'dynamic'" class="w-full mb-2 mt-3">
            <input
                (ngModelChange)="searchChanged($event)"
                [(ngModel)]="search"
                [placeholder]="
                    'missions.filters.city-search-placeholder' | transloco
                "
                matInput
            />
            <button
                mat-icon-button
                matSuffix
                *ngIf="search"
                (click)="clearSearch()"
            >
                <mat-icon
                    class="font-bold icon-size-5 mr-1"
                    [svgIcon]="'heroicons_solid:x-mark'"
                >
                </mat-icon>
            </button>
        </mat-form-field>
        <ng-container *ngIf="!(isLoading$ | async); else isLoadingCities">
            <ng-container *ngIf="hasCities$ | async; else noCities">
                <mat-checkbox
                    (click)="checkboxClicked($event)"
                    (change)="citiesChanged($event, city)"
                    *ngFor="let city of cities$ | async"
                    [value]="city"
                    [checked]="filteredCities.includes(city)"
                    color="primary"
                >
                    <span class="font-semibold">{{ city }}</span>
                </mat-checkbox>
            </ng-container>
            <ng-template #noCities>
                <div class="flex justify-center items-center p-3">
                    <mat-icon
                        class="icon-size-5 mr-1"
                        [svgIcon]="'heroicons_outline:building-office-2'"
                    >
                    </mat-icon>
                    <span>{{ "missions.filters.no-cities" | transloco }}</span>
                </div>
            </ng-template>
        </ng-container>
    </ng-container>
</div>

<ng-template #isLoadingCities>
    <div class="p-3 w-full">
        <mat-progress-spinner
            class="m-auto"
            [diameter]="30"
            [mode]="'indeterminate'"
        >
        </mat-progress-spinner>
    </div>
</ng-template>
