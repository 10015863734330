<ng-container *ngIf="!hasAnswered; else answered">
    <p class="text-xl mb-3">{{ section.multipleChoiceQuestion.question }}</p>
    <div class="flex flex-col gap-y-3 mb-8">
        <div
            *ngFor="
                let answer of section.multipleChoiceQuestion.answers;
                let index = index
            "
        >
            <button
                (click)="toggleAnswer(index)"
                [ngClass]="{
                    'shadow-none translate-x-1 translate-y-1':
                        isAnswerChosen(index)
                }"
                class="relative w-full mt-3 shadow-light bg-tertiary shadow-tertiary-300 transition-all px-16 py-4 rounded-full flex text-center mx-auto hover:shadow-none transform hover:translate-x-1 hover:translate-y-1"
            >
                <span class="w-full text-xl font-bold text-white">{{
                    answer
                }}</span>
                <div
                    class="absolute left-10 w-6 h-6 top-1/2 -translate-y-1/2 border border-white rounded-full"
                    [class.bg-white]="isAnswerChosen(index)"
                ></div>
            </button>
        </div>
    </div>

    <next-chapter-section-button
        (click)="onAnswer()"
        [disabled]="hasNotPickedAnyAnswer"
        [text]="'coursesection.validate' | transloco"
    ></next-chapter-section-button>
</ng-container>

<ng-template #answered>
    <div class="flex justify-center">
        <div
            *ngIf="isCorrect"
            class="w-24 h-24 bg-quaternary rounded-full border-b-8 border-quaternary-900 shadow-xl flex items-center justify-center"
        >
            <mat-icon
                [svgIcon]="'heroicons_outline:check'"
                class="text-white icon-size-12"
            ></mat-icon>
        </div>
    </div>
    <div class="flex justify-center">
        <div
            *ngIf="!isCorrect"
            class="w-24 h-24 bg-primary rounded-full border-b-8 border-primary-900 shadow-xl flex items-center justify-center"
        >
            <mat-icon
                [svgIcon]="'heroicons_outline:x-mark'"
                class="text-white icon-size-12"
            ></mat-icon>
        </div>
    </div>

    <div class="my-5">
        <span class="font-bold">{{
            "coursesection." +
                (isCorrect ? "right" : "wrong") +
                "." +
                randomNum.toString() | transloco
        }}</span>
        <span *ngIf="!isCorrect"
            >&nbsp;{{
                "coursesection.correct-answer-was-" +
                    (hasMultipleGoodAnswers ? "plural" : "singular") | transloco
            }}
            <span class="font-bold">{{ correctAnswer }}</span
            >.</span
        >
        <span>&nbsp;{{ section.multipleChoiceQuestion.explications }}</span>
    </div>

    <next-chapter-section-button
        *ngrxLet="isLastSection$ as isLastSection"
        (click)="nextSection(isLastSection)"
        [text]="'coursesection.continue' | transloco"
    ></next-chapter-section-button>
</ng-template>
