import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component } from '@angular/core';
import { EBlockType } from 'app/api';
import { BaseRichTextBlockComponent } from '../base/rich-text-block/base-rich-text-block.component';

@Component({
    selector: 'bslk-heading',
    templateUrl: './heading.component.html',
    standalone: true,
    imports: [NgIf, NgSwitchCase, NgSwitch],
})
export class BslkHeadingComponent extends BaseRichTextBlockComponent {
    get headingType() {
        switch (this.block.blockType) {
            case EBlockType.HeadingOne:
                return 'h1';
            case EBlockType.HeadingTwo:
                return 'h2';
            case EBlockType.HeadingThree:
            default:
                return 'h3';
        }
    }

    get text() {
        return this.richText?.at(0).plainText;
    }
}
