import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { generateGUID } from 'app/shared/utils/guid.utils';
import { ProjectListFiltersActions } from 'app/store/project/project-list-filters/project-list-filters.action';
import { ProjectListFiltersSelectors } from 'app/store/project/project-list-filters/project-list-filters.selectors';
import { ProjectsActivityFieldsFiltersComponent } from './activity-fields-filters/activity-fields-filters.component';
import { ProjectLocationsFiltersComponent } from './locations-filters/locations-filters.component';

@Component({
    selector: 'project-list-filters',
    templateUrl: './filters.component.html',
    standalone: true,
    imports: [
        BslkTagButtonComponent,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        FormsModule,
        TranslocoModule,
        ProjectsActivityFieldsFiltersComponent,
        ProjectLocationsFiltersComponent,
        NgIf,
        AsyncPipe,
    ],
})
export class ProjectListFiltersComponent implements OnInit {
    nameContains: string;
    activityFieldsOpenEvent: string;
    locationsOpenEvent: string;

    activityFieldsCount$ = this.store.select(
        ProjectListFiltersSelectors.selectActivityFieldsCount
    );
    locationsCount$ = this.store.select(
        ProjectListFiltersSelectors.selectLocationsCount
    );

    hasAnyActiveFilter$ = this.store.select(
        ProjectListFiltersSelectors.selectHasAnyActiveFilter
    );

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(
            ProjectListFiltersActions.loadAvailableFiltersRequest()
        );
    }

    searchProject() {
        this.store.dispatch(
            ProjectListFiltersActions.setNameContains({
                nameContains: this.nameContains,
            })
        );
    }

    reset() {
        this.nameContains = undefined;
        this.store.dispatch(ProjectListFiltersActions.resetAll());
    }

    clearSearch() {
        this.nameContains = undefined;
        this.store.dispatch(ProjectListFiltersActions.resetNameContains());
    }

    openActivityFieldsFilters() {
        this.activityFieldsOpenEvent = generateGUID();
    }

    openLocationsFilters() {
        this.locationsOpenEvent = generateGUID();
    }
}
