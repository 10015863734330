import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {
    APP_INITIALIZER,
    EnvironmentProviders,
    importProvidersFrom,
    inject,
    LOCALE_ID,
    Provider,
} from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER,
    TRANSLOCO_SCOPE,
    translocoConfig,
    TranslocoModule,
    TranslocoService,
} from '@ngneat/transloco';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { TranslocoMatPaginatoIntl } from 'app/shared/services/transloco/transloco-mat-paginator.service';
import { environment } from 'environments/environment';

export const LOCAL_STORAGE_LANG_KEY = 'prefered_language';

const getDefaultLang = () =>
    localStorage.getItem(LOCAL_STORAGE_LANG_KEY)
        ? localStorage.getItem(LOCAL_STORAGE_LANG_KEY)
        : 'fr';

export const provideTransloco = (): Array<Provider | EnvironmentProviders> => {
    registerLocaleData(localeFr);

    return [
        importProvidersFrom(TranslocoModule),
        {
            provide: LOCALE_ID,
            deps: [TranslocoService],
            useFactory: (translocoService: TranslocoService) =>
                translocoService.getActiveLang(),
        },
        {
            // Provide the default Transloco configuration
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: [
                    {
                        id: 'en',
                        label: 'English',
                    },
                    {
                        id: 'fr',
                        label: 'Français',
                    },
                ],
                defaultLang: getDefaultLang(),
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: environment.production,
            }),
        },
        ...translocoPagesProviders,
        {
            // Provide the default Transloco loader
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader,
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            useFactory: () => {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);
                return () => translocoService.load(defaultLang).toPromise();
            },
            multi: true,
        },
        { provide: MatPaginatorIntl, useClass: TranslocoMatPaginatoIntl },
    ];
};

// There seems to be an issue with loading files with "-" included in the name
const allFiles = [
    'alerts',
    'associationedit',
    'associationmissions',
    'associationprojects',
    'associations',
    'associationusers',
    'associationview',
    'basile',
    'bslk',
    'bslkbusiness',
    'companies',
    'companyadmindashboard',
    'companyfilters',
    'courses',
    'coursesection',
    'createmission',
    'createproject',
    'csrapproach',
    'donationcampaign',
    'donationcampaigns',
    'donationgift',
    'dualquiz',
    'enums',
    'files',
    'login',
    'missionmanage',
    'missions',
    'missionview',
    'notifications',
    'projectview',
    'projects',
    'questions',
    'regulardashboard',
    'rewards',
    'shared',
    'signup',
    'users',
    'usersettings',
    'validation',
    'setup',
];

const translocoPagesProviders = allFiles.map((f) => ({
    provide: TRANSLOCO_SCOPE,
    useValue: f,
    multi: true,
}));
