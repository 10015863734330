<div
    *ngIf="!(isLoading$ | async); else loading"
    class="w-full h-full flex flex-col justify-around bg-tertiary bg-opacity-20 rounded-xl py-4 px-6"
>
    <div class="flex justify-between">
        <div class="text-2xl font-bold mb-4">
            {{ "shared.common.badges" | transloco }}
        </div>

        <bslk-tag
            *ngIf="currentBadge.dateCreated"
            [size]="'small'"
            [background]="'tertiary'"
            [icon]="'feather:award'"
            >{{ currentBadge.dateCreated | date : "dd/MM/yyyy" }}</bslk-tag
        >
    </div>
    <bslk-business-badge
        class="flex justify-center"
        [badge]="currentBadge"
        [isDisabled]="currentBadge.id === undefined"
    ></bslk-business-badge>

    <div class="class flex justify-between mt-8">
        <mat-icon
            (click)="previous()"
            [svgIcon]="'heroicons_outline:chevron-left'"
            class="icon-size-8"
            [ngClass]="hasPrevious ? 'cursor-pointer' : 'text-transparent'"
        ></mat-icon>
        <span class="font-bold text-xl"
            >{{ currentBadgeIndex + 1 }} / {{ allBadges.length }}
        </span>
        <mat-icon
            (click)="next()"
            [svgIcon]="'heroicons_outline:chevron-right'"
            class="icon-size-8"
            [ngClass]="hasNext ? 'cursor-pointer' : 'text-transparent'"
        ></mat-icon>
    </div>
</div>

<ng-template #loading>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full flex h-72"
    ></ngx-skeleton-loader>
</ng-template>
