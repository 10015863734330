/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ENotificationTargetType = 'Single' | 'AllRegulars' | 'AllCompanyAdmins' | 'AllAssociationAdmins' | 'AllAdmins' | 'AllCompany' | 'AllCompanyRegulars' | 'AllCompanyCompanyAdmins';

export const ENotificationTargetType = {
    Single: 'Single' as ENotificationTargetType,
    AllRegulars: 'AllRegulars' as ENotificationTargetType,
    AllCompanyAdmins: 'AllCompanyAdmins' as ENotificationTargetType,
    AllAssociationAdmins: 'AllAssociationAdmins' as ENotificationTargetType,
    AllAdmins: 'AllAdmins' as ENotificationTargetType,
    AllCompany: 'AllCompany' as ENotificationTargetType,
    AllCompanyRegulars: 'AllCompanyRegulars' as ENotificationTargetType,
    AllCompanyCompanyAdmins: 'AllCompanyCompanyAdmins' as ENotificationTargetType
};

