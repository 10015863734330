import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserNotificationViewModelPaginatedListDto } from 'app/api';

export const NotificationActions = createActionGroup({
    source: 'Notification',
    events: {
        'Load Notifications Request': emptyProps(),
        'Load Notifications Request Success': props<{
            response: UserNotificationViewModelPaginatedListDto;
        }>(),
        'Load Notifications Request Failure': emptyProps(),
        'Mark All As Read': emptyProps(),
        'Delete Notification': props<{ id: number }>(),
    },
});
