<div class="w-full">
    <div class="max-w-lg mx-auto">
        <div
            class="h-full w-full mx-auto sm:mx-0 p-3 md:p-0 flex flex-col justify-center"
        >
            <!-- Logo -->
            <div class="w-1/2 mx-auto">
                <img src="assets/images/logo/logo-text.svg" />
            </div>

            <div
                class="bg-white shadow-solid-primary rounded-2xl p-6 pb-10 mt-8"
            >
                <div
                    class="mt-2 text-4xl font-extrabold tracking-tight text-center"
                >
                    {{ title }}
                </div>

                <ng-content></ng-content>
            </div>
        </div>
        <div class="mt-6 mb-3">
            <ng-content select="[footer]"></ng-content>
        </div>
    </div>
</div>
