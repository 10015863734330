import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { merge } from 'lodash-es';
import { SuperAdminDashboardActions } from './super-admin-dashboard.actions';
import { SuperAdminDashboardState } from './super-admin-dashboard.state';

export const SuperAdminDashboardInitialState: SuperAdminDashboardState = {
    isLoading: {
        'pending-missions': false,
        'active-users': false,
    },
    kpiFilters: {
        'pending-missions': {},
        'active-users': {},
    },
    kpiResults: {},
};

export const superAdminDashboardReducer = createReducer(
    SuperAdminDashboardInitialState,

    on(SuperAdminDashboardActions.initDashboard, (state) =>
        produce(state, (draftState) => {
            draftState.expandedKpi = null;
        })
    ),
    on(SuperAdminDashboardActions.loadKpiRequest, (state, { kpi }) =>
        produce(state, (draftState) => {
            draftState.isLoading[kpi] = true;
        })
    ),
    on(
        SuperAdminDashboardActions.loadKpiRequestSuccess,
        (state, { kpi, response }) =>
            produce(state, (draftState) => {
                draftState.isLoading[kpi] = false;
                draftState.kpiResults[kpi] = response;
            })
    ),
    on(SuperAdminDashboardActions.loadKpiRequestFail, (state, { kpi }) =>
        produce(state, (draftState) => {
            draftState.isLoading[kpi] = false;
        })
    ),
    on(SuperAdminDashboardActions.expandKpi, (state, { kpi }) =>
        produce(state, (draftState) => {
            draftState.expandedKpi = kpi;
        })
    ),
    on(SuperAdminDashboardActions.minimizeKpi, (state) =>
        produce(state, (draftState) => {
            draftState.expandedKpi = null;
        })
    ),
    on(SuperAdminDashboardActions.updateKpiFilters, (state, { kpi, filters }) =>
        produce(state, (draftState) => {
            let newFilters = merge({}, state.kpiFilters[kpi], filters);
            draftState.kpiFilters[kpi] = newFilters;
        })
    )
);
