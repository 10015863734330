<ng-container *ngIf="!(isLoading$ | async); else loading">
    <div
        *ngrxLet="challenge$ as challenge"
        class="p-4 md:p-8 flex flex-col gap-8 justify-around items-center w-full"
    >
        <div
            class="text-3xl bg-warn-gradient font-bold text-center px-4 py-2 text-white shadow-light shadow-primary rounded-xl"
        >
            {{
                ("dualquiz.challenge-result" | transloco) + " #" + challenge.id
            }}
        </div>
        <div
            class="md:min-w-200 bg-white rounded-3xl shadow-solid-primary flex flex-col md:flex-row relative"
        >
            <div
                *ngIf="
                    challenge.result ===
                        EDualQuizChallengeResult.ChallengeeWon ||
                    challenge.result === EDualQuizChallengeResult.ChallengerWon
                "
                [ngClass]="{
                    '-left-20 -rotate-30':
                        challenge.result ===
                        EDualQuizChallengeResult.ChallengerWon,
                    '-right-20 rotate-30':
                        challenge.result ===
                        EDualQuizChallengeResult.ChallengeeWon
                }"
                class="absolute -top-20"
            >
                <mat-icon
                    class="text-current w-64 h-64 animate-reward"
                    [svgIcon]="'trophy'"
                >
                </mat-icon>
            </div>

            <div
                class="absolute top-0 left-5 -translate-x-1/2 -rotate-30 px-4 py-2 rounded-2xl border-primary font-bold text-3xl uppercase bg-white border-2 text-primary"
                *ngIf="challenge.result === EDualQuizChallengeResult.Draw"
            >
                {{ "dualquiz.draw" | transloco }}
            </div>

            <div
                class="absolute top-1/2 md:top-15 left-1/2 transform -translate-y-1/2 md:translate-y-0 -translate-x-1/2 font-bold bg-white border-2 border-warn rounded-lg px-2 py-1 z-20"
            >
                <span
                    class="text-transparent bg-clip-text bg-gradient-to-r to-primary from-warn uppercase text-4xl tracking-wide"
                    >{{ "dualquiz.vs" | transloco }}</span
                >
            </div>
            <div
                class="absolute top-1/2 md:top-auto md:left-1/2 transform -translate-y-1/2 md:translate-y-0 md:-translate-x-1/2 w-full md:w-1 h-1 md:h-full bg-primary z-10"
            ></div>

            <dual-quiz-challenge-result-column
                [basileConfiguration]="
                    challenge.isChallenger
                        ? (userBasileConfiguration$ | async)
                        : challenge.opponentBasileConfiguration
                "
                [name]="challenge.challenger.name"
                [correctAnswersCount]="challenge.challengerCorrectAnswersCount"
                [challenge]="challenge"
                [answers]="challenge.challengerAnswers"
                [isChallenger]="true"
                class="w-full h-full flex flex-col flex-1"
            ></dual-quiz-challenge-result-column>
            <dual-quiz-challenge-result-column
                [basileConfiguration]="
                    !challenge.isChallenger
                        ? (userBasileConfiguration$ | async)
                        : challenge.opponentBasileConfiguration
                "
                [name]="challenge.challengee?.name ?? challenge.challengeeEmail"
                [correctAnswersCount]="challenge.challengeeCorrectAnswersCount"
                [challenge]="challenge"
                [answers]="challenge.challengeeAnswers"
                [isChallenger]="false"
                class="w-full h-full flex flex-col flex-1"
            ></dual-quiz-challenge-result-column>
        </div>
        <bslk-classic-button
            (click)="goToChallenges()"
            [icon]="'heroicons_outline:arrow-uturn-left'"
            [label]="'dualquiz.return-to-challenges' | transloco"
        >
        </bslk-classic-button>
    </div>
</ng-container>

<ng-template #loading>
    <div class="p-8 flex flex-col gap-8 justify-around items-center w-full">
        <div class="w-90 h-[4em] overflow-hidden shadow-lg rounded-3xl">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full"
            ></ngx-skeleton-loader>
        </div>

        <div class="w-200 h-140 overflow-hidden shadow-lg rounded-3xl">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full"
            ></ngx-skeleton-loader>
        </div>

        <bslk-classic-button
            (click)="goToChallenges()"
            [icon]="'heroicons_outline:arrow-uturn-left'"
            [label]="'dualquiz.return-to-challenges' | transloco"
        >
        </bslk-classic-button>
    </div>
</ng-template>
