import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BslkDragService {
    private dragging = new BehaviorSubject<boolean>(false);
    private dragData = new BehaviorSubject<any>(null);

    dragging$ = this.dragging.asObservable();

    startDrag() {
        this.dragging.next(true);
    }

    endDrag() {
        this.dragging.next(false);
    }

    setDragData(data: any) {
        this.dragData.next(data);
    }
    getDragData(): Observable<any> {
        return this.dragData.asObservable();
    }
}
