import { TextFieldModule } from '@angular/cdk/text-field';
import {
    AsyncPipe,
    DatePipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { MissionUnregisteredViewModel } from 'app/api';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { ERegularUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MissionAvailabilityComponent } from '../availability/mission-availability.component';

@Component({
    selector: 'mission-grid-card',
    standalone: true,
    styleUrls: ['./mission-grid-card.component.scss'],
    templateUrl: './mission-grid-card.component.html',
    imports: [
        AsyncPipe,
        BslkTagComponent,
        FuseCardComponent,
        LetDirective,
        MatButtonModule,
        MatChipsModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        MissionAvailabilityComponent,
        NgClass,
        NgFor,
        NgIf,
        NgTemplateOutlet,
        NgxSkeletonLoaderModule,
        RouterLink,
        TextFieldModule,
        TranslocoModule,
    ],
})
export class MissionGridCardComponent {
    @Input() mission: MissionUnregisteredViewModel;

    showCardBack = false;

    constructor(private datePipe: DatePipe, private router: Router) {}

    get availableSlots() {
        return this.mission.missionSlots.filter(
            (s) => s.availableSpotsLeft > 0
        );
    }

    formatDateRange(startDate: string, endDate: string): string {
        return formatDateRange(this.datePipe, startDate, endDate);
    }

    toggleCardSide(event: PointerEvent) {
        event.stopPropagation();
        this.showCardBack = !this.showCardBack;
    }

    view() {
        this.router.navigateByUrl(
            UrlHelpers.getRegularUrl(ERegularUrl.RegularMissionPage, {
                id: this.mission.id,
            })
        );
    }
}
