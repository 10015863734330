<mission-manage class="w-full">
    <div headerActions>
        <bslk-classic-button
            *ngIf="hasSelectedSlot$ | async"
            (onClick)="removeSlot()"
            [disabledTooltip]="
                'missionmanage.delete-slot-disabled-tooltip' | transloco
            "
            [background]="'warn'"
            [label]="'missionmanage.delete-slot' | transloco"
            [icon]="'heroicons_outline:x-mark'"
            [isDisabled]="!(canRemoveSlot$ | async)"
        >
        </bslk-classic-button>
    </div>
</mission-manage>
