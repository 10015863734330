import { Component } from '@angular/core';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { ProjectListComponent } from 'app/shared/components/project/project-list/project-list.component';
import { DonationCampaignFormService } from 'app/shared/services/form/donation-campaign-form.service';
import { QUILL_MODULES } from 'app/shared/utils/constants.utils';
import { fromTodayDatesFilter } from 'app/shared/utils/date-helpers.utils';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';

@Component({
    selector: 'create-donation-campaign-step-1',
    standalone: true,
    templateUrl: './step-1.component.html',
    imports: [
        ProjectListComponent,
        TranslocoModule,
        FuseAlertComponent,
        FuseAlertComponent,
    ],
})
export class CreateDonationCampaignStep1Component {
    unsubscribeAll: Subject<any> = new Subject<any>();
    quillModules = QUILL_MODULES;

    constructor(
        private donationCampaignFormService: DonationCampaignFormService
    ) {}

    get formGroup() {
        return (
            this.donationCampaignFormService.getFormGroup ??
            this.donationCampaignFormService.initForm()
        );
    }

    get selectedProjectIds() {
        return this.formGroup.controls.projectIds.value;
    }

    get selectedProjectsCount() {
        return this.selectedProjectIds.length;
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }

    getDatesFilter(date: DateTime) {
        return fromTodayDatesFilter(date);
    }

    onSelectedProjectsChange(selectedProjects: number[]) {
        this.formGroup.controls.projectIds.setValue(selectedProjects);
    }
}
