import { AsyncPipe, NgIf } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkFileDndDirective } from '@bslk/directives/file-dnd.directive';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { ActionCreator, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { Observable } from 'rxjs';
import { BaseDialogComponent } from '../dialog/base/base-dialog.component';

export type ActionWithFileUpload = ActionCreator<
    any,
    (props: { file: File; id?: any }) => {
        file: File;
        id?: any;
    } & TypedAction<any>
>;

export type ImageUploadDialogInput = {
    action?: ActionWithFileUpload;
    id?: any;
    isLoading$: Observable<boolean>;
    maxFileSize: number;
    infoTextKey?: string;
    formControl?: FormControl<string>;
    extraAllowedFormats?: string[];
    disableImages?: boolean;
    error$?: Observable<string>;
};

@Component({
    selector: 'file-upload',
    templateUrl: './file-upload-dialog.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BaseDialogComponent,
        BslkClassicButtonComponent,
        BslkFileDndDirective,
        FormsModule,
        FuseAlertComponent,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class ImageUploadDialogComponent {
    @ViewChild('fileInput') fileInput: ElementRef;

    selectedFile: File | null = null;
    fileTypeError = false;
    fileSizeError = false;

    constructor(
        private store: Store,
        private dialogRef: MatDialogRef<ImageUploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ImageUploadDialogInput
    ) {}

    get fileHasError() {
        return this.fileSizeError || this.fileSizeError;
    }

    get formatSize() {
        if (!this.selectedFile) {
            return;
        }

        if (this.selectedFile.size === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(this.selectedFile.size) / Math.log(k));
        return (
            parseFloat((this.selectedFile.size / Math.pow(k, i)).toFixed(dm)) +
            ' ' +
            sizes[i]
        );
    }

    get acceptString() {
        let formats = !this.data.disableImages ? '.jpg,.jpeg,.png' : '.';
        if (this.data.extraAllowedFormats) {
            formats += ',.' + this.data.extraAllowedFormats.join(',.');
        }
        return formats;
    }

    onFileSelected(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files[0]) {
            const file = input.files[0];
            this.handleFile(file);
        }
    }

    onFileDropped(event: FileList) {
        if (event?.item(0)) {
            this.handleFile(event.item(0));
        }
    }

    handleFile(file: File) {
        this.fileTypeError = false;
        this.fileSizeError = false;
        this.selectedFile = null;

        if (
            file.type.startsWith('image/') ||
            (this.data.extraAllowedFormats?.includes('pdf') &&
                file.type === 'application/pdf') ||
            (this.data.extraAllowedFormats?.includes('csv') &&
                file.type === 'text/csv') ||
            (this.data.extraAllowedFormats?.includes('xlsx') &&
                file.type ===
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        ) {
            if (file.size <= this.data.maxFileSize) {
                this.selectedFile = file;
            } else {
                this.fileSizeError = true;
            }
        } else {
            this.fileTypeError = true;
        }
    }

    onUpload() {
        if (this.selectedFile) {
            if (this.data.action) {
                this.store.dispatch(
                    this.data.action({
                        file: this.selectedFile,
                        id: this.data.id,
                    })
                );
            }
            if (this.data.formControl) {
                var reader = new FileReader();
                const that = this;
                reader.readAsDataURL(this.selectedFile);
                reader.onload = function () {
                    that.data.formControl.setValue(reader.result as string);
                    that.dialogRef.close();
                };
            }
        }
    }

    removeFile() {
        this.selectedFile = null;
        this.fileInput.nativeElement.value = null;
    }

    close() {
        this.dialogRef.close();
    }
}
