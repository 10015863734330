import { DonationCampaignViewModel } from 'app/api';
import { AppState } from '../..';
import {
    RessourceListSelectors,
    createRessourceListSelectors,
} from '../../shared/ressource-list/ressource-list-selectors.creator';

const selectState = (state: AppState) => state.donationCampaignList;

interface AdditionalSelectors {}

type DonationCampaignListSelectors =
    RessourceListSelectors<DonationCampaignViewModel> & AdditionalSelectors;

export const DonationCampaignListSelectors: DonationCampaignListSelectors = {
    ...createRessourceListSelectors('donationCampaignList'),
};
