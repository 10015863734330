import { NgClass, NgFor, NgIf } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkColor } from '@bslk/types/bslk-types';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { TranslocoModule, isNil } from '@ngneat/transloco';
import { CompanyPillarViewModel } from 'app/api';

@Component({
    selector: 'company-csr-approach-view-pillar',
    templateUrl: './view.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        FuseDrawerComponent,
        NgIf,
        NgFor,
        NgClass,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatIconModule,
    ],
})
export class CompanyCsrApproachViewPillarComponent implements OnChanges {
    @ViewChild('viewPillarDrawer') drawer: FuseDrawerComponent;

    @Input() pillar: CompanyPillarViewModel;
    @Input() color: BslkColor = 'primary';

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (!isNil(changes.pillar?.currentValue)) {
            this.drawer.open();
        }
    }

    openedChanged(isOpened: boolean) {
        if (!isOpened) {
            this.close.emit();
        }
    }
}
