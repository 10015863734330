import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { CourseSectionCommentsActions } from './course-section-comments.actions';
import { CourseSectionCommentState } from './course-section-comments.state';

export const initialState: CourseSectionCommentState =
    ressourceListInitialState;

export const courseSecitonCommentsReducer = createRessourceListReducer(
    ressourceListInitialState,
    CourseSectionCommentsActions
);
