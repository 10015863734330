import { createReducer, on } from '@ngrx/store';
import { ProjectListFiltersActions } from './project-list-filters.action';
import { ProjectListFiltersState } from './project-list-filters.state';

export const projectListFiltersInitialState: ProjectListFiltersState = {
    isLoading: false,
};

export const projectListFiltersReducer = createReducer(
    projectListFiltersInitialState,

    on(
        ProjectListFiltersActions.setNameContains,
        (state, { nameContains }) => ({
            ...state,
            nameContains,
        })
    ),

    on(ProjectListFiltersActions.resetNameContains, (state) => ({
        ...state,
        nameContains: undefined,
    })),

    on(
        ProjectListFiltersActions.addActivityField,
        (state, { activityField }) => ({
            ...state,
            activityFields: state.activityFields
                ? [...state.activityFields, activityField]
                : [activityField],
        })
    ),

    on(
        ProjectListFiltersActions.removeActivityField,
        (state, { activityField }) => ({
            ...state,
            activityFields: state.activityFields
                ? state.activityFields.filter(
                      (field) => field !== activityField
                  )
                : [],
        })
    ),

    on(ProjectListFiltersActions.addLocation, (state, { location }) => ({
        ...state,
        locations: state.locations
            ? [...state.locations, location]
            : [location],
    })),

    on(ProjectListFiltersActions.removeLocation, (state, { location }) => ({
        ...state,
        locations: state.locations
            ? state.locations.filter((loc) => loc !== location)
            : [],
    })),

    on(ProjectListFiltersActions.resetAll, (state) => ({
        ...state,
        locations: undefined,
        activityFields: undefined,
        nameContains: undefined,
    })),

    on(ProjectListFiltersActions.loadAvailableFiltersRequest, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(
        ProjectListFiltersActions.loadAvailableFiltersRequestSuccess,
        (state, { availableFilters }) => ({
            ...state,
            isLoading: false,
            availableFilters,
        })
    ),

    on(
        ProjectListFiltersActions.loadAvailableFiltersRequestFailure,
        (state) => ({
            ...state,
            isLoading: false,
        })
    )
);
