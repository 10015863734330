<div
    *ngrxLet="activeSection$ as activeSection"
    class="bg-white shadow-solid-tertiary rounded-xl p-8 flex flex-col gap-y-3"
>
    <div>
        <span class="font-bold">{{ courseTitle$ | async }}</span>
        · {{ chapterTitle$ | async }}
    </div>

    <div class="text-3xl">
        {{ activeSectionIndex + 1 }}/{{ sectionsCount }}
        <span class="text-warn">- </span>
        <span class="font-bold">{{ activeSection.title }}</span>
    </div>

    <div class="text-lg" [ngSwitch]="activeSection.type">
        <bslk-page-content
            *ngSwitchCase="ECourseSectionType.Text"
            [blocks]="activeSection.blocks"
        ></bslk-page-content>
        <chapter-card-anecdote-section
            *ngSwitchCase="ECourseSectionType.Anecdote"
        >
            <bslk-page-content
                [blocks]="activeSection.blocks"
            ></bslk-page-content>
        </chapter-card-anecdote-section>
        <true-false-question-component
            *ngSwitchCase="ECourseSectionType.TrueFalseQuestion"
            [section]="activeSection"
        ></true-false-question-component>
        <mcq-question-component
            *ngSwitchCase="ECourseSectionType.MultipleChoiceQuestion"
            [section]="activeSection"
        ></mcq-question-component>
        <survey-component
            *ngSwitchCase="ECourseSectionType.Survey"
            [section]="activeSection"
        ></survey-component>
        <chapter-card-video-section
            *ngSwitchCase="ECourseSectionType.Video"
            [videoUrl]="activeSection.videoUrl"
        >
            <bslk-page-content
                [blocks]="activeSection.blocks"
            ></bslk-page-content>
        </chapter-card-video-section>
    </div>

    <ng-container *ngrxLet="isLastSection$ as isLastSection">
        <next-chapter-section-button
            (click)="nextSection(isLastSection)"
            [text]="'coursesection.continue' | transloco"
            *ngIf="
                activeSection.type !== ECourseSectionType.TrueFalseQuestion &&
                activeSection.type !==
                    ECourseSectionType.MultipleChoiceQuestion &&
                activeSection.type !== ECourseSectionType.Survey
            "
        ></next-chapter-section-button>
    </ng-container>
</div>
