import { Injectable } from '@angular/core';
import { EActivityField, EMissionType, ESkill } from 'app/api';
import { ResourceListFilter } from 'app/store/shared/ressource-list/ressource-list-state';
import { remove } from 'lodash-es';
import { DateTime } from 'luxon';
import { ActiveFilter } from './mission-filters.component';
@Injectable({ providedIn: 'root' })
export class MissionFiltersService {
    nameContains: string | null = null;
    filteredSkills: ESkill[] = [];
    filteredActivityFields: EActivityField[] = [];
    filteredMissionTypes: EMissionType[] = [];
    filteredCities: string[] = [];
    startDate: DateTime | null = null;
    endDate: DateTime | null = null;
    isRemote: boolean | null = null;
    isTeamBuilding: boolean | null = null;

    get getNameContains() {
        return this.nameContains;
    }

    get getFilteredSkills() {
        return this.filteredSkills;
    }

    get getFilteredMissionTypes() {
        return this.filteredMissionTypes;
    }

    get getFilteredActivityFields() {
        return this.filteredActivityFields;
    }

    get getFilteredCities() {
        return this.filteredCities;
    }

    get getStartDate() {
        return this.startDate;
    }

    get getEndDate() {
        return this.endDate;
    }

    get getIsRemote() {
        return this.isRemote;
    }

    get getIsTeamBuilding() {
        return this.isTeamBuilding;
    }

    get filters(): Array<{
        key: ResourceListFilter;
        value: any;
    }> {
        const f: Array<{
            key: ResourceListFilter;
            value: any;
        }> = [
            {
                key: 'nameContains',
                value: this.nameContains,
            },
            {
                key: 'skills',
                value: structuredClone(this.filteredSkills),
            },
            {
                key: 'missionTypes',
                value: structuredClone(this.filteredMissionTypes),
            },
            {
                key: 'activityFields',
                value: structuredClone(this.filteredActivityFields),
            },
            {
                key: 'cities',
                value: structuredClone(this.filteredCities),
            },
            {
                key: 'startDate',
                value: this.startDate
                    ? structuredClone(this.startDate.toISO())
                    : null,
            },
            {
                key: 'endDate',
                value: this.endDate
                    ? structuredClone(this.endDate.toISO())
                    : null,
            },
            {
                key: 'isRemote',
                value: structuredClone(this.isRemote),
            },
            {
                key: 'isTeamBuilding',
                value: structuredClone(this.isTeamBuilding),
            },
        ];
        return f;
    }

    setStartDate(date: DateTime | null) {
        this.startDate = date;
    }

    setEndDate(date: DateTime | null) {
        this.endDate = date;
    }

    setIsRemote(isRemote: boolean | null) {
        this.isRemote = isRemote;
        if (this.isRemote) {
            this.filteredCities.length = 0;
        }
    }

    setIsTeamBuilding(isTeamBuilding: boolean) {
        this.isTeamBuilding = isTeamBuilding;
    }

    setNameContains(nameContains: string | null) {
        this.nameContains = nameContains;
    }

    clearAllFilters() {
        this.nameContains = null;
        this.startDate = null;
        this.endDate = null;
        this.isRemote = null;
        this.isTeamBuilding = false;
        this.filteredSkills.length = 0;
        this.filteredActivityFields.length = 0;
        this.filteredCities.length = 0;
        this.filteredMissionTypes.length = 0;
    }

    removeFilter(activeFilter: ActiveFilter) {
        if (activeFilter.type === 'skills') {
            remove(this.filteredSkills, (item) => item === activeFilter.value);
        } else if (activeFilter.type === 'activityFields') {
            remove(
                this.filteredActivityFields,
                (item) => item === activeFilter.value
            );
        } else if (activeFilter.type === 'missionTypes') {
            remove(
                this.filteredMissionTypes,
                (item) => item === activeFilter.value
            );
        } else if (activeFilter.type === 'cities') {
            remove(this.filteredCities, (item) => item === activeFilter.value);
        } else if (activeFilter.type === 'startDate') {
            this.setStartDate(null);
        } else if (activeFilter.type === 'endDate') {
            this.setEndDate(null);
        } else if (activeFilter.type === 'isRemote') {
            this.isRemote = null;
        } else if (activeFilter.type === 'isTeamBuilding') {
            this.isTeamBuilding = false;
        } else if (activeFilter.type === 'nameContains') {
            this.nameContains = null;
        }
    }
}
