<div
    class="bg-warn-gradient px-6 py-3 font-bold text-xl rounded-2xl shadow-lg text-white"
    *ngIf="project"
>
    <div class="flex gap-3 items-center">
        <mat-icon
            class="text-current icon-size-8"
            [svgIcon]="'heroicons_outline:bolt'"
        ></mat-icon>
        <div class="inline">
            {{ project.baseContributionAmount }}
            <span *ngIf="project.currency">{{
                "enums.ECurrency." + project.currency | transloco
            }}</span>
            <span class="mx-1">=</span>
            {{ project.impactUnitQuantity }}
            {{ project.impactUnitDescription }}
        </div>
    </div>
</div>
