import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslKCountdownComponent } from '@bslk/components/countdown/countdown.component';
import { TranslocoModule } from '@ngneat/transloco';
import {
    CourseChapterUserCompletionViewModel,
    CourseUserCompletionViewModel,
    EAwarenessThematic,
} from 'app/api';
import { DateTime } from 'luxon';
import { CourseDashboardThematicLock } from '../lock/lock.component';
import {
    CourseChapterState,
    CourseDashboardTokenComponent,
} from './token/token.component';

@Component({
    selector: 'course-dashboard-thematic-tokens',
    templateUrl: './tokens.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        MatIconModule,
        NgIf,
        NgClass,
        NgFor,
        BslKCountdownComponent,
        CourseDashboardTokenComponent,
        CourseDashboardThematicLock,
    ],
})
export class CourseDashboardThematicTokensComponent {
    @Input() thematic: EAwarenessThematic;
    @Input() courses: CourseUserCompletionViewModel[];
    @Input() startDate: string;

    positionIndex: number;
    isLastChapterDone: boolean;

    get isStarted() {
        return DateTime.fromISO(this.startDate) < DateTime.now();
    }

    calculatePosition(currentIndex: number, courseIndex: number): number {
        const sequence = [3, 2, 1, 2, 3, 4, 5, 4];
        if (currentIndex === 0 && courseIndex === 0) {
            this.positionIndex = 0;
        } else {
            this.positionIndex += 1;
        }
        return sequence[this.positionIndex % sequence.length];
    }

    getState(
        chapter: CourseChapterUserCompletionViewModel,
        courseIndex: number,
        chapterIndex: number
    ): CourseChapterState {
        if (!this.isStarted) {
            return 'coming';
        }

        if (chapter.isDone) {
            this.isLastChapterDone = true;
            return 'done';
        }
        const state =
            chapter.progressionPercentage > 0 ||
            this.isLastChapterDone ||
            (courseIndex === 0 && chapterIndex === 0)
                ? 'current'
                : 'coming';
        this.isLastChapterDone = false;
        return state;
    }
}
