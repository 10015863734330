<div class="flex flex-col mb-2">
    <div class="flex gap-2">
        <basile-avatar
            class="w-10 h-10 lg:h-15 lg:w-15 xl:w-20 xl:h-20 flex flex-shrink-0"
            [configuration]="comment.basileConfiguration"
        ></basile-avatar>
        <div class="flex flex-col">
            <span class="text-xl truncate font-bold text-warn">{{
                comment.firstName
            }}</span>
            <span class="text-xs truncate">{{
                comment.dateCreated | date : "shortDate"
            }}</span>
            <span class="mt-2 hidden xl:inline">{{ comment.comment }}</span>
        </div>
    </div>
    <span class="mt-2 inline xl:hidden">{{ comment.comment }}</span>
</div>
