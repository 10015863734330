import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { DualQuizChallengesComponent as DualQuizChallengeComponent } from './challenges/challenges.component';
import { DualQuizHeaderComponent } from './header/header.component';
import { DualQuizChallengeMatchUpsComponent } from './match-ups/match-ups.component';

@Component({
    selector: 'dual-quiz',
    templateUrl: './dual-quiz.component.html',
    standalone: true,
    imports: [
        LetDirective,
        AsyncPipe,
        TranslocoModule,
        DualQuizHeaderComponent,
        DualQuizChallengeComponent,
        DualQuizChallengeMatchUpsComponent,
    ],
})
export class DualQuizComponent {}
