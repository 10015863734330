import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { EUserRole } from 'app/api';
import { UserActions } from 'app/store/user/user/user.actions';
import { UserSelectors } from 'app/store/user/user/user.selectors';

@Component({
    selector: 'switch-role',
    templateUrl: './switch-role.component.html',
    standalone: true,
    imports: [AsyncPipe, BslkClassicToggleComponent, NgIf, TranslocoModule],
})
export class SwitchRoleComponent {
    EUserRole = EUserRole;

    userRole$ = this.store.select(UserSelectors.selectUserRole);
    canSwitchRole$ = this.store.select(UserSelectors.selectCanSwitchRole);

    constructor(private store: Store) {}

    switchRole() {
        this.store.dispatch(UserActions.switchRoleRequest({}));
    }
}
