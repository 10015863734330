import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'classic-avatar',
    templateUrl: './classic-avatar.component.html',
    imports: [NgIf, NgClass, AsyncPipe],
})
export class ClassicAvatarComponent {
    @Input() avatarUrl: string;
    @Input() fallbackLetter: string;
    @Input() size: number = 32;
    @Input() borderSize: number = 8;
    @Input() textSize: 2 | 4 | 7 = 7;

    classCombos = {
        combo1: ['ring-bg-card', 'bg-tertiary', 'border-primary'],
        combo2: ['ring-bg-card', 'bg-primary', 'border-tertiary'],
        combo3: ['ring-bg-card  ', 'bg-warn', 'border-primary'],
    };

    letterToComboMapping = {
        A: 'combo1',
        B: 'combo1',
        C: 'combo1',
        D: 'combo1',
        E: 'combo1',
        F: 'combo1',
        G: 'combo1',
        H: 'combo1',
        I: 'combo1',
        J: 'combo2',
        K: 'combo2',
        L: 'combo2',
        M: 'combo2',
        N: 'combo2',
        O: 'combo2',
        P: 'combo2',
        Q: 'combo2',
        R: 'combo2',
        S: 'combo3',
        T: 'combo3',
        U: 'combo3',
        V: 'combo3',
        W: 'combo3',
        X: 'combo3',
        Y: 'combo3',
        Z: 'combo3',
    };

    get fallbackLetterUppercase() {
        return this.fallbackLetter?.toUpperCase();
    }

    get ringSize() {
        return this.borderSize / 2;
    }

    get letterCombo() {
        return this.letterToComboMapping[
            this.fallbackLetter?.at(0)?.toUpperCase() ?? 'A'
        ];
    }

    get backgroundColor() {
        return this.classCombos[this.letterCombo]
            ? this.classCombos[this.letterCombo].at(1)
            : this.classCombos['combo1'].at(1);
    }

    get ringColor() {
        return this.classCombos[this.letterCombo]
            ? this.classCombos[this.letterCombo].at(0)
            : this.classCombos['combo1'].at(0);
    }

    get borderColor() {
        return this.classCombos[this.letterCombo]
            ? this.classCombos[this.letterCombo].at(2)
            : this.classCombos['combo1'].at(2);
    }
}
