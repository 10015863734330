import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { MissionRatingListActions } from './mission-ratings.actions';
import { MissionRatingListState } from './mission-ratings.state';

export const initialState: MissionRatingListState = ressourceListInitialState;

export const missionRatingListReducer = createRessourceListReducer(
    ressourceListInitialState,
    MissionRatingListActions
);
