import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { generateGUID } from 'app/shared/utils/guid.utils';
import { CourseSelectors } from 'app/store/course/course/course.selectors';
import { CourseSectionCommentComponent } from '../comment/comment.component';
import { ChapterSectionDrawerCommentsComponent } from './comments-panel/comments-panel.component';

@Component({
    selector: 'chapter-section-preview-comments',
    templateUrl: './preview.component.html',
    standalone: true,
    imports: [
        LetDirective,
        TranslocoModule,
        MatTooltipModule,
        NgFor,
        DatePipe,
        AsyncPipe,
        NgIf,
        MatIconModule,
        ChapterSectionDrawerCommentsComponent,
        CourseSectionCommentComponent,
    ],
})
export class ChapterSectionPreviewCommentsComponent {
    activeSection$ = this.store.select(
        CourseSelectors.selectActiveChapterSection
    );

    activeChapterId$ = this.store.select(CourseSelectors.selectActiveChapterId);
    comments$ = this.store.select(
        CourseSelectors.selectActiveChapterSectionComments
    );
    commentsCount$ = this.store.select(
        CourseSelectors.selectActiveChapterSectionCommentsCount
    );

    openDrawerEvent: string;

    constructor(private store: Store) {}

    comment() {
        // Trigger event change, simpler than working with booleans
        this.openDrawerEvent = generateGUID();
    }
}
