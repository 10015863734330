<div class="flex w-full flex-col">
    <div class="font-bold">
        {{ label }}
    </div>
    <div [class.mt-2]="label" class="flex flex-1 justify-between items-center">
        <div *ngIf="minLabel" class="hidden sm:inline-block flex-1 text-center">
            {{ minLabel }}
        </div>
        <div
            [class.justify-center]="justify === 'center'"
            [class.justify-start]="justify === 'start'"
            [class.gap-2]="size === 'medium'"
            class="flex grow items-center"
        >
            <bslk-star
                (click)="onStarClick(1)"
                [size]="size"
                [isReadonly]="isReadonly"
                [isFilled]="isFilled1"
            ></bslk-star>
            <bslk-star
                (click)="onStarClick(2)"
                [size]="size"
                [isReadonly]="isReadonly"
                [isFilled]="isFilled2"
            ></bslk-star>
            <bslk-star
                (click)="onStarClick(3)"
                [size]="size"
                [isReadonly]="isReadonly"
                [isFilled]="isFilled3"
            ></bslk-star>
            <bslk-star
                (click)="onStarClick(4)"
                [size]="size"
                [isReadonly]="isReadonly"
                [isFilled]="isFilled4"
            ></bslk-star>
            <bslk-star
                (click)="onStarClick(5)"
                [size]="size"
                [isReadonly]="isReadonly"
                [isFilled]="isFilled5"
            ></bslk-star>
        </div>

        <div *ngIf="maxLabel" class="hidden sm:inline-block flex-1 text-center">
            {{ maxLabel }}
        </div>
    </div>
</div>
<span *ngIf="isInvalidAndTouched" class="text-primary text-sm">{{
    "bslk.form.required" | transloco
}}</span>
