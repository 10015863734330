<div [formGroup]="formGroup" class="flex flex-col gap-3">
    <div class="w-full">
        <bslk-info-label
            class="font-bold"
            [tooltip]="'createproject.sdg-impact-info' | transloco"
            >{{ "createproject.sdg-impact" | transloco }}*</bslk-info-label
        >
        <bslk-business-sdg-picker
            [control]="formGroup.controls.sustainableDevelopmentGoals"
            [justify]="'start'"
            [displayValueInField]="true"
        ></bslk-business-sdg-picker>
    </div>

    <div class="flex flex-col gap-1 w-full">
        <mat-form-field floatLabel="always" subscriptSizing="dynamic">
            <mat-label
                ><bslk-info-label
                    [tooltip]="'createproject.quote-tooltip' | transloco"
                    >{{ "createproject.quote" | transloco }}</bslk-info-label
                ></mat-label
            >
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:chat-bubble-oval-left-ellipsis'"
                matPrefix
            ></mat-icon>
            <input
                [placeholder]="'createproject.quote-placeholder' | transloco"
                [formControlName]="'quote'"
                matInput
            />
        </mat-form-field>
        <bslk-length-info
            [text]="formGroup.controls.quote.value"
            [maxLength]="300"
        ></bslk-length-info>
    </div>

    <div class="flex gap-3">
        <div class="flex flex-col gap-1 flex-1">
            <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                <mat-label
                    ><bslk-info-label
                        [tooltip]="
                            'createproject.quote-author-tooltip' | transloco
                        "
                        >{{
                            "createproject.quote-author" | transloco
                        }}</bslk-info-label
                    ></mat-label
                >
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:user'"
                    matPrefix
                ></mat-icon>
                <input
                    [placeholder]="
                        'createproject.quote-author-placeholder' | transloco
                    "
                    [formControlName]="'quoteAuthor'"
                    matInput
                />
            </mat-form-field>
            <bslk-length-info
                [text]="formGroup.controls.quoteAuthor.value"
                [maxLength]="50"
            ></bslk-length-info>
        </div>
        <div class="flex flex-col gap-1 flex-1">
            <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                <mat-label
                    ><bslk-info-label
                        [tooltip]="
                            'createproject.quote-author-title-tooltip'
                                | transloco
                        "
                        >{{
                            "createproject.quote-author-title" | transloco
                        }}</bslk-info-label
                    ></mat-label
                >
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'mat_outline:badge'"
                    matPrefix
                ></mat-icon>
                <input
                    [placeholder]="
                        'createproject.quote-author-title-placeholder'
                            | transloco
                    "
                    [formControlName]="'quoteAuthorTitle'"
                    matInput
                />
            </mat-form-field>
            <bslk-length-info
                [text]="formGroup.controls.quoteAuthorTitle.value"
                [maxLength]="50"
            ></bslk-length-info>
        </div>
    </div>

    <mat-form-field
        class="w-full fuse-mat-emphasized-affix"
        [subscriptSizing]="'dynamic'"
    >
        <mat-label>
            <bslk-info-label>{{
                "createproject.video-url" | transloco
            }}</bslk-info-label>
        </mat-label>
        <div class="text-secondary" matPrefix>youtube.com/watch?v=</div>
        <input
            [placeholder]="'syzBGumIpD8'"
            [formControlName]="'videoUrl'"
            (input)="onVideoUrlInputChange($event.target.value)"
            matInput
        />
    </mat-form-field>
</div>
