import {
    AsyncPipe,
    JsonPipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
    MatCheckboxChange,
    MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { isEmpty, remove, sortBy } from 'lodash-es';

export type CheckboxFilterItem = {
    value: any;
    name: string;
};

export type CheckboxFilterEvent = {
    value: any;
    isChecked: boolean;
};

@Component({
    selector: 'checkbox-filter',
    standalone: true,
    templateUrl: './checkbox-filter.component.html',
    imports: [
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatCheckboxModule,
        MatTooltipModule,
        NgClass,
        AsyncPipe,
        NgIf,
        LetDirective,
        NgFor,
        JsonPipe,
        TranslocoModule,
        NgTemplateOutlet,
    ],
})
export class CheckboxFilter {
    @Input() items: CheckboxFilterItem[];
    @Input() selectedValues: any[];
    @Input() title: string;
    @Input() filterPlaceholder: string;
    @Input() enableSearch = true;

    @Output() filterChanged: EventEmitter<void> = new EventEmitter<void>();

    search: string;
    maximumShownItems = 10;
    isExpanded = false;

    get searchIsEmpty() {
        return isEmpty(this.search);
    }

    get shownItems() {
        const shownItems = this.searchIsEmpty
            ? this.sortItems(this.items).slice(0, this.maximumShownItems)
            : this.sortItems(this.filteredItems);
        return shownItems;
    }

    get filteredItems() {
        return this.items.filter((i) =>
            i.name.toLowerCase().includes(this.search.toLowerCase())
        );
    }

    checkboxChange(event: MatCheckboxChange, value: any) {
        if (event.checked) {
            this.selectedValues.push(value);
        } else {
            remove(this.selectedValues, (item) => item === value);
        }
        this.filterChanged.emit();
    }

    // Needed otherwise checkbox doesn't lose focus on click and extra click on page is needed to do any action
    checkboxClicked(event: MouseEvent): void {
        (event.target as HTMLElement).blur();
    }

    loadMore() {
        this.maximumShownItems += 10;
    }

    toggleIsExpanded() {
        this.isExpanded = !this.isExpanded;
    }

    sortItems(items: CheckboxFilterItem[]) {
        return sortBy(items, ['name']);
    }
}
