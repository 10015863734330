import { NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MissionRegisteredViewModel } from "app/api";
import { MissionUnregisterdRowCardComponent } from "app/shared/components/mission/row/unregistered/mission-unregistered-row-card.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

@Component({
    selector     : 'mission-row-view',
    standalone   : true,
    templateUrl  : './mission-row-view.component.html',
    imports : [
       NgFor,
       NgIf,
       MissionUnregisterdRowCardComponent,
       NgxSkeletonLoaderModule,
       NgTemplateOutlet
    ],
})
export class MissionRowViewComponent 
{
    @Input() missions: MissionRegisteredViewModel[];
    @Input() isLoading: boolean;

}