import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProjectService } from 'app/api';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { ProjectListActions } from '../project-list/project-list.actions';
import { ProjectListFiltersActions } from './project-list-filters.action';
import { ProjectListFiltersSelectors } from './project-list-filters.selectors';

@Injectable()
export class ProjectListFiltersEffects {
    triggerApiCall$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ProjectListFiltersActions.setNameContains,
                ProjectListFiltersActions.resetNameContains,
                ProjectListFiltersActions.addActivityField,
                ProjectListFiltersActions.removeActivityField,
                ProjectListFiltersActions.addLocation,
                ProjectListFiltersActions.removeLocation,
                ProjectListFiltersActions.resetAll
            ),
            concatLatestFrom(() =>
                this.store.select(
                    ProjectListFiltersSelectors.selectFiltersForApi
                )
            ),
            map(([, filters]) =>
                ProjectListActions.FilterListRequest({ filters })
            )
        )
    );

    loadAvailableFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectListFiltersActions.loadAvailableFiltersRequest),
            exhaustMap(() => {
                return this.projectService.projectAvailableFiltersGet().pipe(
                    map((availableFilters) =>
                        ProjectListFiltersActions.loadAvailableFiltersRequestSuccess(
                            {
                                availableFilters,
                            }
                        )
                    ),
                    catchError((httpResponse) =>
                        of(
                            ProjectListFiltersActions.loadAvailableFiltersRequestFailure(
                                {
                                    error: httpResponse.error,
                                }
                            )
                        )
                    )
                );
            })
        )
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private projectService: ProjectService
    ) {}
}
