<bslk-classic-card
    *ngrxLet="isLoading$ as isLoading"
    [isLoading]="isLoading"
    [title]="'associationview.user-ratings' | transloco"
>
    <div class="flex divide-x justify-between mb-5 border-b">
        <div class="flex w-1/2 flex-col pr-3 pb-3">
            <span class="text-lg font-semibold">{{
                "associationview.feedbacks" | transloco
            }}</span>
            <div
                *ngrxLet="isInitialized$ as isInitialized"
                class="flex items-center justify-center"
            >
                <span *ngIf="isInitialized" class="font-bold text-2xl mr-5">{{
                    totalCount$ | async
                }}</span>
                <ngx-skeleton-loader
                    *ngIf="!isInitialized"
                    [theme]="{ height: '100%' }"
                    class="w-8 h-full mr-1"
                ></ngx-skeleton-loader>
                <mat-icon
                    class="text-warn"
                    [svgIcon]="'heroicons_solid:chat-bubble-bottom-center-text'"
                ></mat-icon>
            </div>
            <span class="text-tertiary text-sm">{{
                "associationview.feedbacks-hint" | transloco
            }}</span>
        </div>
        <div class="flex w-1/2 flex-col pl-3 pb-3">
            <span class="text-lg font-semibold">{{
                "associationview.rating" | transloco
            }}</span>
            <div class="flex flex-col">
                <div class="flex justify-center items-center">
                    <span class="font-bold text-2xl mr-5"
                        >{{ association.rating | number : "1.0-1" }}/5</span
                    >
                    <mat-icon
                        class="text-warn"
                        [svgIcon]="'heroicons_solid:star'"
                    ></mat-icon>
                </div>
                <span class="text-tertiary text-sm">{{
                    "associationview.rating-hint" | transloco
                }}</span>
            </div>
        </div>
    </div>

    <div
        *ngIf="isInitialized$ | async; else initializing"
        class="grid grid-cols-1 divide-y"
    >
        <div
            class="w-full flex py-5 first:pt-0 last:pb-0"
            *ngFor="let rating of ratings$ | async"
        >
            <div class="flex flex-col pr-10">
                <span class="font-semibold"
                    >{{ rating.userFirstName }}
                    {{ rating.userFamilyName }}</span
                >
                <span class="text-tertiary">{{
                    rating.dateCreated | date
                }}</span>
                <bslk-star-rating
                    [size]="'small'"
                    [justify]="'start'"
                    [isReadonly]="true"
                    [value]="rating.rating"
                ></bslk-star-rating>
            </div>
            <div class="flex flex-col">
                <div class="text-lg font-semibold">
                    {{ rating.missionName }}
                </div>
                <div class="">{{ rating.comment }}</div>
            </div>
        </div>
    </div>

    <mat-paginator
        footer
        *ngIf="(totalPages$ | async) > 1"
        class="bg-primary-100"
        [ngClass]="{
            'pointer-events-none': isLoading
        }"
        [length]="totalCount$ | async"
        [pageIndex]="pageIndex$ | async"
        [pageSize]="pageSize$ | async"
        [hidePageSize]="true"
        (page)="pageChangeEvent($event)"
        [showFirstLastButtons]="false"
    ></mat-paginator>

    <ng-template #initializing>
        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="rounded-2xl w-full h-36 mb-8 shadow"
        ></ngx-skeleton-loader>

        <ngx-skeleton-loader
            [theme]="{ height: '100%' }"
            class="rounded-2xl w-full h-36 shadow"
        ></ngx-skeleton-loader>
    </ng-template>
</bslk-classic-card>
