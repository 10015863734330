import {
    animate,
    keyframes,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkColor } from '@bslk/types/bslk-types';
import { setIntervalX } from 'app/shared/utils/js.utils';
import { Observable } from 'rxjs';

@Component({
    selector: 'chapter-gain',
    templateUrl: './gain.component.html',
    standalone: true,
    imports: [MatIconModule, AsyncPipe, NgIf, NgFor, NgClass],
    animations: [
        trigger('flyInOut', [
            transition('* => *', [
                animate(
                    '3s ease-out',
                    keyframes([
                        style({
                            opacity: 1,
                            transform: 'translateX(0px) translateY(0px)',
                            offset: 0,
                        }),
                        style({
                            opacity: 0,
                            transform:
                                'translateX({{xFinal}}px) translateY({{yFinal}}px)',
                            offset: 1,
                        }),
                    ])
                ),
            ]),
        ]),
        trigger('textGrow', [
            transition('* => *', [
                animate(
                    '1s ease-out',
                    keyframes([
                        style({ transform: 'scale(1)', offset: 0 }),
                        style({ transform: 'scale(1.1)', offset: 0.5 }),
                        style({ transform: 'scale(1)', offset: 1 }),
                    ])
                ),
            ]),
        ]),
    ],
})
export class ChapterGainComponent implements OnInit {
    @Input() selector: Observable<number>;
    @Input() icon: string;
    @Input() color: BslkColor;

    Math = Math;
    lastValue: number = 0;
    diffValue: number = 0;
    displayedValue: number = 0;
    nextId: number = 0;
    intervalId: number;
    animations: { id: number; value: number; x: number; y: number }[] = [];

    constructor() {}

    ngOnInit(): void {
        this.selector.subscribe((currentValue) => {
            if (currentValue > 0) {
                this.diffValue = currentValue - this.lastValue;
                this.addDifferenceAnimation(this.diffValue);
                this.addIncrementAnimation();
            }
            this.lastValue = currentValue;
        });
    }

    addDifferenceAnimation(value: number) {
        let xFinal = -(Math.random() * 200 + 50);
        let yFinal = Math.random() * 200 + 50;
        this.animations.push({
            id: this.nextId++,
            value,
            x: xFinal,
            y: yFinal,
        });
        setTimeout(() => {
            this.animations = this.animations.filter(
                (a) => a.id > this.nextId - 1
            );
        }, 3000);
    }

    addIncrementAnimation() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.displayedValue = this.lastValue;
        }
        this.intervalId = setIntervalX(
            () => {
                this.displayedValue += this.diffValue / 40;
            },
            20,
            40
        );
    }
}
