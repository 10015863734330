<bslk-advanced-chart
    (expands)="onExpand($event)"
    (filterChanges)="onFilterChange($event)"
    class="w-full"
    [title]="'companyadmindashboard.kpis.awareness-rate' | transloco"
    [isLoading]="isLoading$ | async"
    [series]="series"
    [labels]="labels"
    [isExpandable]="true"
    [isExpanded]="isExpanded$ | async"
    [isDownloadEnabled]="true"
    [options]="chartOptions"
    [expandOptions]="expandOptions"
    [isTimePeriodSelectionEnabled]="true"
    [dateRange]="dateRange$ | async"
>
    <div
        [ngClass]="(isExpanded$ | async) ? 'w-1/6' : 'w-min'"
        class="text-center m-auto font-extrabold text-5xl"
    >
        {{ currentParticipationRate }}%
    </div>
</bslk-advanced-chart>
