import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ETrackedActivityType } from 'app/api';
import { BasileConfigurationViewModel } from 'app/api/model/basileConfigurationViewModel';
import { EAvatarAssetType } from 'app/api/model/eAvatarAssetType';
import { MyBasileComponent } from 'app/shared/components/my-basile/my-basile.component';
import { ActivityService } from 'app/shared/services/activity/activity.service';
import { MyBasileActions } from 'app/store/my-basile/my-basile.action';
import { MyBasileSelectors } from 'app/store/my-basile/my-basilie.selectors';
import { isNil } from 'lodash-es';
import { Subject, takeUntil, tap } from 'rxjs';
import {
    AssetPicker,
    AvatarAssetSelectEvent,
} from './asset-picker/asset-picker.component';
import { AssetTypePicker } from './asset-type-picker/asset-type-picker.component';
@Component({
    selector: 'edit-my-basile',
    templateUrl: './edit-my-basile.component.html',
    standalone: true,
    imports: [
        AssetPicker,
        AssetTypePicker,
        AsyncPipe,
        AsyncPipe,
        LetDirective,
        MatIconModule,
        MyBasileComponent,
        NgFor,
        NgIf,
        TranslocoModule,
    ],
})
export class EditMyBasileComponent implements OnInit, OnDestroy {
    private unsubscribeAll: Subject<any> = new Subject<any>();

    EAvatarAssetType = EAvatarAssetType;

    previewConfiguration: BasileConfigurationViewModel;

    assetTypes = [
        { type: EAvatarAssetType.Hat, icon: 'heroicons_outline:academic-cap' },
        { type: EAvatarAssetType.Face, icon: 'mat_outline:face' },
        {
            type: EAvatarAssetType.Beard,
            icon: 'mat_outline:carpenter',
        },
        {
            type: EAvatarAssetType.Mustache,
            icon: 'mat_outline:air',
        },
        { type: EAvatarAssetType.Glasses, icon: 'heroicons_outline:eye' },
        { type: EAvatarAssetType.Neck, icon: 'heroicons_outline:lifebuoy' },
        { type: EAvatarAssetType.Top, icon: 'feather:user' },
        {
            type: EAvatarAssetType.Cape,
            icon: 'feather:bookmark',
        },
        {
            type: EAvatarAssetType.Bottom,
            icon: 'mat_outline:airline_seat_legroom_extra',
        },
        {
            type: EAvatarAssetType.Shoes,
            icon: 'mat_outline:pets',
        },
    ];

    selectedAssetType: EAvatarAssetType = EAvatarAssetType.Face;

    equipedConfiguration$ = this.store
        .select(MyBasileSelectors.selectBasileConfiguration)
        .pipe(
            takeUntil(this.unsubscribeAll),
            tap((configuration) => {
                this.previewConfiguration = structuredClone(configuration);
            })
        );

    isLoading$ = this.store.select(MyBasileSelectors.selectIsLoading);

    assets$ = this.store.select(MyBasileSelectors.selectAssets);

    currentBalance$ = this.store.select(
        MyBasileSelectors.selectPurposeCoinsBalance
    );

    constructor(
        private store: Store,
        private activityService: ActivityService
    ) {}

    ngOnInit(): void {
        this.store.dispatch(MyBasileActions.loadAssetsRequest());
        this.activityService.sendActivityRequest({
            trackedActivityType: ETrackedActivityType.ViewAvatarPage,
        });
    }

    selectAssetType(
        type: EAvatarAssetType,
        equipedConfiguration: BasileConfigurationViewModel
    ) {
        this.selectedAssetType = type;
        this.previewConfiguration = structuredClone(equipedConfiguration);
    }

    onAssetPreviewed(event: AvatarAssetSelectEvent) {
        switch (event.type) {
            case EAvatarAssetType.Face:
                this.previewConfiguration.face = event.asset;
                break;
            case EAvatarAssetType.Glasses:
                this.previewConfiguration.glasses = event.asset;
                break;
            case EAvatarAssetType.Hat:
                this.previewConfiguration.hat = event.asset;
                break;
            case EAvatarAssetType.Neck:
                this.previewConfiguration.neck = event.asset;
                break;
            case EAvatarAssetType.Top:
                this.previewConfiguration.top = event.asset;
                break;
            case EAvatarAssetType.Bottom:
                this.previewConfiguration.bottom = event.asset;
                break;
            case EAvatarAssetType.Shoes:
                this.previewConfiguration.shoes = event.asset;
                break;
            case EAvatarAssetType.Beard:
                this.previewConfiguration.beard = event.asset;
                break;
            case EAvatarAssetType.Mustache:
                this.previewConfiguration.mustache = event.asset;
                break;
            case EAvatarAssetType.Cape:
                this.previewConfiguration.cape = event.asset;
                break;
        }
    }

    onAssetEquiped(event: AvatarAssetSelectEvent) {
        if (isNil(event.asset)) {
            this.store.dispatch(
                MyBasileActions.unequipAssetRequest({ assetType: event.type })
            );
        } else {
            this.store.dispatch(
                MyBasileActions.equipAssetRequest({ asset: event.asset })
            );
        }
    }

    onAssetBought(event: AvatarAssetSelectEvent) {
        this.store.dispatch(
            MyBasileActions.buyAssetRequest({ asset: event.asset })
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
