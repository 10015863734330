import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DualQuizQuestionService } from 'app/api';
import { DualQuizQuestionFormService } from 'app/shared/services/form/dual-quiz-question-form.service';
import {
    createCreateItemEffect,
    createDeleteItemEffect,
    createLoadListRequestEffect,
    createReloadEffect,
    createSelectedItemEffect,
    createStartCreationEffect,
    createUpdateSelectedItemEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { DualQuizQuestionActions } from './questions.actions';
import { DualQuizQuestionSelectors } from './questions.selectors';

@Injectable()
export class DualQuizQuestionEffects {
    loadQuestions$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.dualQuizQuestionService,
        DualQuizQuestionActions,
        DualQuizQuestionSelectors,
        this.dualQuizQuestionService.dualQuizQuestionGet
    );

    reload$ = createReloadEffect(this.actions$, DualQuizQuestionActions);

    itemSelected$ = createSelectedItemEffect(
        this.store,
        this.actions$,
        DualQuizQuestionActions,
        DualQuizQuestionSelectors,
        this.dualQuizQuestionFormService
    );

    updateQuestion$ = createUpdateSelectedItemEffect(
        this.dualQuizQuestionService,
        this.actions$,
        DualQuizQuestionActions,
        this.dualQuizQuestionFormService,
        'question-update',
        this.dualQuizQuestionService.dualQuizQuestionPut
    );

    createQuestion$ = createCreateItemEffect(
        this.dualQuizQuestionService,
        this.actions$,
        DualQuizQuestionActions,
        this.dualQuizQuestionFormService,
        'question-create',
        this.dualQuizQuestionService.dualQuizQuestionPost
    );

    deleteQuestion$ = createDeleteItemEffect(
        this.dualQuizQuestionService,
        this.actions$,
        DualQuizQuestionActions,
        'question-delete',
        this.dualQuizQuestionService.dualQuizQuestionDelete
    );

    startCreation$ = createStartCreationEffect(
        this.actions$,
        DualQuizQuestionActions,
        this.dualQuizQuestionFormService
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private dualQuizQuestionService: DualQuizQuestionService,
        private dualQuizQuestionFormService: DualQuizQuestionFormService
    ) {}
}
