import { NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BlockViewModel, EBlockType } from 'app/api';
import { BslkBulletListItemComponent } from '../blocks/bullet-list-item/bullet-list-item.component';
import { BslkHeadingComponent } from '../blocks/heading/heading.component';
import { BslkImageComponent } from '../blocks/image/image.component';
import { BslkLinkPreviewComponent } from '../blocks/link-preview/link-preview.component';
import { BslkParagraphComponent } from '../blocks/paragraph/paragraph.component';
import { BslkQuoteComponent } from '../blocks/quote/quote.component';

@Component({
    selector: 'bslk-page-content',
    templateUrl: './content.component.html',
    standalone: true,
    imports: [
        BslkHeadingComponent,
        BslkImageComponent,
        BslkParagraphComponent,
        BslkQuoteComponent,
        BslkLinkPreviewComponent,
        BslkBulletListItemComponent,
        NgFor,
        NgIf,
        NgSwitch,
        NgSwitchCase,
    ],
})
export class BslkPageContentComponent {
    @Input() blocks: Array<BlockViewModel>;

    EBlockType = EBlockType;
}
