<div
    *ngIf="!(isLoading$ | async)"
    class="w-full p-6 bg-white shadow-lg rounded-2xl flex flex-col gap-4 overflow-hidden"
>
    <div class="flex justify-between items-center">
        <div class="flex gap-2 items-center">
            <mat-icon [svgIcon]="'feather:key'"></mat-icon>
            <span class="text-2xl font-bold">{{
                "donationcampaign.codes" | transloco
            }}</span>
        </div>

        <div class="flex gap-4">
            <div class="flex gap-2 items-center">
                <div class="h-3 w-3 rounded-full bg-tertiary"></div>
                <span class="font-bold">{{
                    "donationcampaign.available-codes" | transloco
                }}</span>
            </div>
            <div class="flex gap-2 items-center">
                <div class="h-3 w-3 rounded-full bg-primary"></div>
                <span class="font-bold">{{
                    "donationcampaign.used-codes" | transloco
                }}</span>
            </div>
        </div>
    </div>

    <div
        *ngrxLet="whitelistedCodes$ as whitelistedCodes"
        class="flex flex-wrap gap-1"
    >
        <bslk-tag
            *ngFor="let whitelistedCode of whitelistedCodes"
            (click)="copyToClipboard(whitelistedCode)"
            [class.cursor-pointer]="!whitelistedCode.isUsed"
            [size]="'small'"
            [icon]="
                copiedCodeId === whitelistedCode.id
                    ? 'heroicons_outline:check'
                    : null
            "
            [matTooltip]="
                whitelistedCode.isUsed
                    ? ('donationcampaign.used-by' | transloco) +
                      ' ' +
                      whitelistedCode.usedBy
                    : ('donationcampaign.click-to-copy-code' | transloco)
            "
            [background]="whitelistedCode.isUsed ? 'primary' : 'tertiary'"
        >
            <span *ngIf="copiedCodeId !== whitelistedCode.id">{{
                whitelistedCode.code
            }}</span>
            <span *ngIf="copiedCodeId === whitelistedCode.id">
                <span>{{ "donationcampaign.copied" | transloco }}</span>
            </span>
        </bslk-tag>
    </div>
</div>
