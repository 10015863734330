import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule, TranslocoService, isNil } from '@ngneat/transloco';
import { MissionSlotViewModel } from 'app/api';
import { MissionSlotBuilderService } from 'app/modules/association/create-mission/steps/step1/mission-slot-builder.service';
import { MissionFormService } from 'app/shared/services/form/mission-form.service';
import {
    areDatesOnSameDay,
    areStringDatesOnSameDay,
    weekendsDatesFilter,
} from 'app/shared/utils/date-helpers.utils';
import { MissionSlotUtils } from 'app/shared/utils/extensions/mission-slots/mission-slots.utils';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'propose-slot-calendar',
    standalone: true,
    templateUrl: './propose-slot-calendar.component.html',
    styleUrls: ['../../../../../../../../styles/full-calendar.scss'],
    imports: [
        AsyncPipe,
        BslkClassicButtonComponent,
        FormsModule,
        FullCalendarModule,
        FuseAlertComponent,
        FuseCardComponent,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class ProposeSlotCalendarComponent implements OnInit {
    @Input() expirationDate: string;

    unsubscribeAll: Subject<any> = new Subject<any>();
    calendarOptions: CalendarOptions;
    builtSlotFormGroup?: FormGroup;
    eventSubject: BehaviorSubject<EventInput[]> = new BehaviorSubject<
        EventInput[]
    >([]);
    calendarEvents: Observable<EventInput[]> = this.eventSubject.asObservable();

    constructor(
        private translocoService: TranslocoService,
        private missionFormService: MissionFormService,
        private missionSlotBuilderService: MissionSlotBuilderService
    ) {}

    ngOnInit(): void {
        this.setCalendarOptions();
    }

    get hasStartedDrawing() {
        return !isNil(
            this.missionSlotBuilderService.currentSlotBuilder.startDate
        );
    }

    get isDrawing() {
        return this.missionSlotBuilderService.isDrawing;
    }

    get displayTimePicker(): boolean {
        if (!this.builtSlotFormGroup) {
            return false;
        }
        const startDate = this.builtSlotFormGroup.controls.startDate.value;
        const endDate = this.builtSlotFormGroup.controls.endDate.value;
        return areDatesOnSameDay(startDate, endDate);
    }

    setCalendarOptions(): void {
        this.calendarOptions = {
            plugins: [dayGridPlugin, interactionPlugin],
            locale: this.translocoService.getActiveLang(),
            buttonText: {
                today: this.translocoService.translate(
                    'createmission.this-month'
                ),
            },
            headerToolbar: {
                left: 'prev,next',
                center: 'title',
                right: 'today',
            },
            weekends: false,
            dayHeaderFormat: { weekday: 'narrow' },
            initialView: 'dayGridMonth',
            contentHeight: 'auto',
            displayEventEnd: true,
            fixedWeekCount: false,
            dayCellClassNames: (arg) => {
                var today = new Date();
                today.setHours(0, 0, 0, 0);

                var expirationDate = new Date(this.expirationDate);
                expirationDate.setHours(0, 0, 0, 0);

                var cellDate = new Date(arg.date);
                cellDate.setHours(0, 0, 0, 0);

                if (cellDate < today || cellDate > expirationDate) {
                    return ['fc-non-selectable'];
                } else {
                    return [];
                }
            },
            dateClick: (info: DateClickArg) => this.dateClick(info),
            datesSet: () => this.dateSets(),
        };
    }

    subscribeToMissionSlots(): void {
        this.missionFormService.getFormGroup.controls.missionSlots.valueChanges
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((slots: MissionSlotViewModel[]) => {
                const slotTranslation =
                    this.translocoService.translate('createmission.slot');
                const events = slots.map((slot) => {
                    const allDay = !areStringDatesOnSameDay(
                        slot.startDate,
                        slot.endDate
                    );
                    const isValid =
                        DateTime.fromISO(slot.startDate) <
                        DateTime.fromISO(slot.endDate);
                    return {
                        title: allDay ? slotTranslation : '',
                        start: new Date(slot.startDate),
                        // Must add 1 day to end date because of how fullcalendar allday exclusive end date works
                        end: DateTime.fromISO(slot.endDate)
                            .plus({ days: allDay ? 1 : 0 })
                            .toJSDate(),
                        allDay,
                        backgroundColor: isValid ? 'var(--fuse-warn)' : 'red',
                        borderColor: isValid ? 'var(--fuse-warn)' : 'red',
                    };
                });
                this.eventSubject.next(events);
            });
    }

    dateSets(): void {
        var calendarEl = document.querySelector('.full-calendar');
        var dateElements = calendarEl.querySelectorAll('.fc-daygrid-day');
        // After each month change, we must register date mouse hover to draw event that is being created
        dateElements.forEach((dateElement) => {
            dateElement.addEventListener('mouseenter', (event: MouseEvent) => {
                if (this.missionSlotBuilderService.isDrawing) {
                    const date = (event.target as HTMLElement).getAttribute(
                        'data-date'
                    );
                    const endDate = DateTime.fromISO(date).set({ hour: 17 });
                    if (
                        endDate >
                        DateTime.fromISO(
                            this.missionSlotBuilderService.currentSlotBuilder
                                .startDate
                        )
                    ) {
                        this.drawHoverEvent(endDate);
                    }
                }
            });
        });
    }

    dateClick(event: DateClickArg) {
        this.handleEventDateSet(event.dateStr);
    }

    handleEventDateSet(dateStr: string) {
        if (DateTime.fromISO(dateStr) < DateTime.utc()) {
            return;
        }

        // If user redraws another event, remove previous form group has we want only one slot proposal
        if (!this.hasStartedDrawing) {
            this.missionSlotBuilderService.hardReset();
            this.subscribeToMissionSlots();
        }

        this.builtSlotFormGroup =
            this.missionSlotBuilderService.handleDate(dateStr);
        if (this.builtSlotFormGroup) {
            this.subscribeToFormGroupValueChanges();
        }
        // Draw instantly placeholder even that is being created as one day event (until user hovers another date)
        if (this.missionSlotBuilderService.isDrawing) {
            const drawnEvent: EventInput = {
                start: new Date(
                    this.missionSlotBuilderService.currentSlotBuilder.startDate
                ),
                end: DateTime.fromISO(
                    this.missionSlotBuilderService.currentSlotBuilder.startDate
                )
                    .set({ hour: 17 })
                    .toString(),
                backgroundColor: 'var(--fuse-primary)',
            };
            this.eventSubject.next([drawnEvent]);
        }
    }
    // Draw event that is being created
    drawHoverEvent(endDate: DateTime) {
        const startDate =
            this.missionSlotBuilderService.currentSlotBuilder.startDate;
        const allDay = !areStringDatesOnSameDay(startDate, endDate.toString());
        const drawnEvent: EventInput = {
            start: new Date(startDate),
            // Must add 1 day to end date because of how fullcalendar allday exclusive end date works
            end: endDate.plus({ days: allDay ? 1 : 0 }).toJSDate(),
            allDay,
            backgroundColor: 'var(--fuse-primary)',
            borderColor: 'var(--fuse-primary)',
        };
        this.eventSubject.next([drawnEvent]);
    }

    subscribeToFormGroupValueChanges() {
        MissionSlotUtils.subscribeToFormGroupValueChanges(
            this.builtSlotFormGroup,
            this.unsubscribeAll
        );
    }

    removeSlot() {
        this.builtSlotFormGroup = null;
        this.missionSlotBuilderService.hardReset();
        this.eventSubject.next([]);
    }

    getWeekendsDatesFilter(date: DateTime) {
        return weekendsDatesFilter(date);
    }

    ngOnDestroy() {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
