<ng-container *ngrxLet="answerResponse$ as answerResponse">
    <div
        *ngIf="answerResponse"
        class="relative bg-white rounded-xl shadow-lg md:w-140"
    >
        <div
            class="absolute top-0 left-0 flex items-center gap-2 transform -translate-y-1/2 bg-white text-tertiary font-bold text-lg px-4 py-2 rounded-t-lg shadow-[1px_-1px_-2px_-2px_rgba(0,0,0,0.25)]"
        >
            <mat-icon
                [svgIcon]="'heroicons_outline:information-circle'"
                class="text-current"
            ></mat-icon>
            {{ "dualquiz.informations" | transloco }}
        </div>
        <div class="flex p-8 flex-col gap-8">
            <!-- Didn't answer in time -->
            <div
                *ngIf="!answerResponse.hasAnsweredInTime"
                class="w-full flex items-center gap-1 bg-warn-100 text-warn font-bold rounded-xl text-lg py-2 px-4"
            >
                <mat-icon
                    [svgIcon]="'mat_outline:hourglass_empty'"
                    class="text-warn"
                ></mat-icon>
                <span>{{ "dualquiz.no-answer-in-time" | transloco }}</span>
            </div>

            <!-- Explanation -->
            <div
                *ngrxLet="isExplanationAnImage$ as isExplanationAnImage"
                class="flex flex-col gap-1"
            >
                <div
                    *ngIf="answerResponse.explanation"
                    class="bg-tertiary-200 rounded-xl"
                >
                    <div
                        class="flex items-center gap-1 p-3 bg-tertiary text-white rounded-tl-xl rounded-tr-xl"
                    >
                        <mat-icon
                            [svgIcon]="'basile-flat'"
                            class="text-current"
                        ></mat-icon>

                        {{ "dualquiz.more-details" | transloco }}
                    </div>
                    <div class="p-3 relative">
                        <span *ngIf="!isExplanationAnImage">{{
                            answerResponse.explanation
                        }}</span>
                        <img
                            *ngIf="isExplanationAnImage"
                            alt="'Explanation'"
                            [src]="answerResponse.explanation"
                        />
                    </div>
                </div>
                <ng-container *ngrxLet="isSourceALink$ as isSourceALink">
                    <div
                        *ngIf="answerResponse.source"
                        class="flex gap-1 items-center justify-end text-tertiary"
                    >
                        <mat-icon
                            [svgIcon]="
                                'heroicons_outline:' +
                                (isSourceALink ? 'globe-alt' : 'book-open')
                            "
                            class="text-current icon-size-5"
                        >
                        </mat-icon>
                        <a
                            *ngIf="isSourceALink"
                            target="_blank"
                            [href]="answerResponse.source"
                            >{{ "dualquiz.source" | transloco }}</a
                        >
                        <span *ngIf="!isSourceALink">{{
                            answerResponse.source
                        }}</span>
                    </div>
                </ng-container>
            </div>

            <!-- Success rate -->
            <div class="w-full flex flex-col">
                <bslk-info-label
                    [tooltip]="'dualquiz.difficulty-tooltip' | transloco"
                    ><span class="font-bold text-lg">{{
                        "dualquiz.question-difficulty" | transloco
                    }}</span></bslk-info-label
                >
                <div class="w-full relative">
                    <div
                        [style]="
                            'transform:translateX(' +
                            Math.min(answerResponse.correctPercentage, 96) +
                            '%);'
                        "
                        class="flex flex-col"
                    >
                        <div
                            [ngClass]="{
                                'text-tertiary':
                                    answerResponse.correctPercentage >= 66,
                                'text-warn':
                                    answerResponse.correctPercentage >= 33 &&
                                    answerResponse.correctPercentage < 66,
                                'text-primary':
                                    answerResponse.correctPercentage < 33
                            }"
                            class="mb-0.5 text-lg font-bold"
                        >
                            {{ Math.round(answerResponse.correctPercentage) }}%
                        </div>
                        <div
                            [ngClass]="{
                                'border-b-tertiary':
                                    answerResponse.correctPercentage >= 66,
                                'border-b-warn':
                                    answerResponse.correctPercentage >= 33 &&
                                    answerResponse.correctPercentage < 66,
                                'border-b-primary':
                                    answerResponse.correctPercentage < 33
                            }"
                            class="w-0 h-0 border-l-8 border-r-8 border-b-8 border-l-transparent border-r-transparent"
                        ></div>
                    </div>
                </div>
                <div
                    class="w-full flex h-10 relative bg-gradient-to-r from-primary via-warn to-tertiary"
                >
                    <div
                        class="absolute -translate-y-1/2 top-1/2 flex justify-between w-full z-20"
                    >
                        <div class="h-12 w-1 bg-gray-300"></div>
                        <div class="h-12 w-1 bg-gray-300"></div>
                        <div class="h-12 w-1 bg-gray-300"></div>
                        <div class="h-12 w-1 bg-gray-300"></div>
                    </div>
                    <div
                        class="flex-1 flex items-center justify-center text-sm text-center uppercase text-white"
                    >
                        {{ "dualquiz.difficult" | transloco }}
                    </div>
                    <div
                        class="flex-1 flex items-center justify-center text-sm text-center uppercase text-white"
                    >
                        {{ "dualquiz.medium" | transloco }}
                    </div>
                    <div
                        class="flex-1 flex items-center justify-center text-sm text-center uppercase text-white"
                    >
                        {{ "dualquiz.easy" | transloco }}
                    </div>
                </div>

                <div class="w-full flex justify-between text-sm mt-1">
                    <span>0%</span>
                    <span>33%</span>
                    <span>66%</span>
                    <span>100%</span>
                </div>
            </div>
            <!-- Next question -->
            <dual-quiz-challenge-next-button
                [isStarting]="false"
                [challengeId]="challengeId$ | async"
                [isEnding]="noQuestionLeft$ | async"
            ></dual-quiz-challenge-next-button>
        </div>
    </div>
</ng-container>
