import { ElementRef } from '@angular/core';
import { LocationViewModel } from 'app/api';

export function initializeMapWithLocation(
    libraries: any,
    elementRef: ElementRef,
    location: LocationViewModel,
    zoom: number = 14
) {
    if (!elementRef) {
        return;
    }

    const myLatLng = new libraries.core.LatLng(
        location.latitude,
        location.longitude
    );
    const mapOptions: google.maps.MapOptions = {
        center: myLatLng,
        zoom,
    };

    const map = new libraries.maps.Map(elementRef.nativeElement, mapOptions);

    new libraries.marker.Marker({
        position: myLatLng,
        map: map,
    });
}

export function initializeMapWithLocations(
    libraries: any,
    elementRef: ElementRef,
    locations: LocationViewModel[]
) {
    if (!elementRef || !locations || locations.length === 0) {
        return;
    }

    if (locations.length === 1) {
        initializeMapWithLocation(libraries, elementRef, locations[0], 4);
        return;
    }

    const bounds = new libraries.core.LatLngBounds();

    const map = new libraries.maps.Map(elementRef.nativeElement);

    locations.forEach((location) => {
        const myLatLng = new libraries.core.LatLng(
            location.latitude,
            location.longitude
        );

        new libraries.marker.Marker({
            position: myLatLng,
            map: map,
        });

        bounds.extend(myLatLng);
    });

    map.fitBounds(bounds);
}
