<div
    [ngClass]="{
        'bg-tertiary': hasWon,
        'bg-primary': hasLost,
        'bg-warn': hasDrawn,
        'bg-white': ongoingChallenge,
        'animate-wizz': isPendingForUser,
        'cursor-pointer': !isPendingForUser
    }"
    [class.animate-wizz]="isPendingForUser"
    (click)="goToChallengeResults()"
    class="bg-opacity-60 hover:bg-opacity-80 transition-all duration-500 rounded-2xl shadow-lg p-4 group relative"
>
    <div class="flex flex-col gap-3">
        <div class="flex justify-between">
            <div>
                <span class="text-sm">{{
                    (challenge.isChallenger
                        ? "dualquiz.you-challenged"
                        : "dualquiz.challenged-by"
                    ) | transloco
                }}</span
                >&nbsp;
                <span class="font-bold">{{
                    challenge.isChallenger
                        ? challenge.challengee?.name ??
                          challenge.challengeeEmail
                        : challenge.challenger.name
                }}</span>
            </div>
            <bslk-tag
                [background]="resultColor + '-light'"
                [textColor]="resultColor"
                [size]="'small'"
            >
                <div *ngIf="ongoingChallenge" class="flex items-center gap-2">
                    <div
                        class="rounded-full bg-warn h-3 w-3 animate-pulse"
                    ></div>
                    <span class="uppercase">{{
                        "dualquiz.ongoing" | transloco
                    }}</span>
                </div>
                <span *ngIf="!ongoingChallenge">{{
                    challenge.dateCreated | date : "dd/MM/yyyy"
                }}</span>
            </bslk-tag>
        </div>
        <div class="flex gap-3 h-28">
            <basile-avatar
                [disableZooming]="true"
                [ringSize]="'medium'"
                [background]="resultColor"
                class="flex flex-shrink-0 w-26 h-26"
                *ngIf="challenge.opponentBasileConfiguration"
                [configuration]="challenge.opponentBasileConfiguration"
            ></basile-avatar>
            <div
                class="flex flex-col justify-between gap-4 grow w-full h-full truncate"
            >
                <div class="flex grow justify-center">
                    <div
                        *ngIf="challenge.result"
                        [ngClass]="{
                            'bg-tertiary-300': hasWon,
                            'bg-primary-300': hasLost,
                            'bg-warn-300': hasDrawn
                        }"
                        class="rounded-2xl gap-3 text-white group-hover:scale-105 py-2 px-4 flex items-center transition-all duration-300 ease-in-out"
                    >
                        <div
                            [ngClass]="{
                                'bg-tertiary border-tertiary-800': hasWon,
                                'bg-primary border-primary-800': hasLost,
                                'bg-warn border-warn-800': hasDrawn
                            }"
                            class="h-14 w-14 rounded-full border-b-[6px] shadow flex items-center justify-center"
                        >
                            <mat-icon
                                [svgIcon]="resultIcon"
                                class="text-white icon-size-8"
                            ></mat-icon>
                        </div>
                        <span class="flex flex-col">
                            <span
                                class="text-2xl uppercase font-extrabold flex"
                            >
                                <span *ngIf="hasWon">{{
                                    "dualquiz.victory" | transloco
                                }}</span>
                                <span *ngIf="hasLost">{{
                                    "dualquiz.defeat" | transloco
                                }}</span>
                                <span *ngIf="hasDrawn">{{
                                    "dualquiz.draw" | transloco
                                }}</span>
                            </span>
                            <span class="text-center text-lg"
                                >{{
                                    (challenge.isChallenger
                                        ? challenge.challengerCorrectAnswersCount
                                        : challenge.challengeeCorrectAnswersCount) ??
                                        0
                                }}
                                -
                                {{
                                    (challenge.isChallenger
                                        ? challenge.challengeeCorrectAnswersCount
                                        : challenge.challengerCorrectAnswersCount) ??
                                        0
                                }}</span
                            >
                        </span>
                    </div>
                    <div
                        class="flex flex-col gap-2 py-2 px-4 rounded-2xl"
                        *ngIf="isPendingForUser"
                    >
                        <button
                            (click)="goToChallenge()"
                            class="bg-warn-gradient px-6 py-2 transition-all rounded-full shadow-light shadow-primary flex text-center mx-auto transform hover:translate-x-1 hover:translate-y-1 hover:shadow-none"
                        >
                            <span class="w-full text-xl font-bold text-white">{{
                                "dualquiz.continue" | transloco
                            }}</span>
                        </button>
                    </div>
                    <div
                        *ngIf="!isPendingForUser && ongoingChallenge"
                        class="flex items-center gap-1"
                    >
                        <mat-icon
                            [svgIcon]="'mat_outline:hourglass_empty'"
                            class="text-current"
                        ></mat-icon>
                        <span class="flex flex-col">
                            {{ "dualquiz.waiting-for-opponent" | transloco }}
                        </span>
                    </div>
                </div>

                <div class="flex items-center gap-2">
                    <mat-icon
                        class="text-current"
                        [svgIcon]="
                            challenge.thematic
                                ? 'bslk_thematics:' + challenge.thematic
                                : 'feather:shuffle'
                        "
                    >
                    </mat-icon>
                    <span class="font-bold truncate">
                        <span *ngIf="challenge.thematic">{{
                            "enums.EAwarenessThematic." + challenge.thematic
                                | transloco
                        }}</span>
                        <span *ngIf="!challenge.thematic">{{
                            "dualquiz.all-thematics" | transloco
                        }}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
