import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MissionRatingService, MissionRatingViewModel } from 'app/api';
import { RewardDialogService } from 'app/shared/components/dialog/reward/reward-dialog.service';
import { MissionRatingFormService } from 'app/shared/services/form/mission-rating-form.service';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { UserSelectors } from '../../user/user/user.selectors';
import { MissionManageSelectors } from '../mission-manage/mission-manage.selectors';
import { MissionRatingActions } from './mission-rating.action';

@Injectable()
export class MissionRatingEffects {
    postMissionRating$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionRatingActions.postRatingRequest),
            concatLatestFrom(() => [
                this.store.select(UserSelectors.selectUserId),
                this.store.select(MissionManageSelectors.selectMission),
            ]),
            exhaustMap(([, userId, mission]) => {
                const missionRatingViewModel: MissionRatingViewModel =
                    this.missionRatingFormService.getEntity()
                        .missionRatingViewModel;
                missionRatingViewModel.userId = userId;
                missionRatingViewModel.missionId = mission.id;
                return this.missionRatingService
                    .missionRatingPost({ missionRatingViewModel })
                    .pipe(
                        map((reward) => {
                            this.rewardDialogService.open({
                                rewards: [reward],
                            });
                            return MissionRatingActions.postRatingRequestSuccess();
                        }),
                        catchError((httpResponse) =>
                            of(
                                MissionRatingActions.postRatingRequestFail({
                                    error: httpResponse?.error ?? httpResponse,
                                })
                            )
                        )
                    );
            })
        )
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private missionRatingService: MissionRatingService,
        private missionRatingFormService: MissionRatingFormService,
        private rewardDialogService: RewardDialogService
    ) {}
}
