<!-- Messages toggle -->
<a
    (click)="chatLinkClicked()"
    *ngrxLet="unreadCount$ as unreadCount"
    [routerLink]="'/s/chat'"
>
    <button mat-icon-button #messagesOrigin>
        <ng-container *ngIf="unreadCount > 0">
            <span
                class="absolute top-0 right-0 left-0 flex items-center justify-center h-3"
            >
                <span
                    class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-warn text-warn-50 text-xs font-medium"
                >
                    {{ unreadCount }}
                </span>
            </span>
        </ng-container>
        <mat-icon
            [svgIcon]="'heroicons_outline:chat-bubble-oval-left'"
        ></mat-icon>
    </button>
</a>
