import { createSelector } from '@ngrx/store';
import { ECourseSectionType } from 'app/api';
import { randomNumberFrom1To } from 'app/shared/utils/number.utils';
import { isNil, sample } from 'lodash-es';
import { AppState } from '../..';
import { CourseSectionCommentSelectors } from '../course-section-comments/course-section-comments.selectors';
import { BasileReaction } from './course.state';

const selectState = (state: AppState) => state.course;

const selectActiveChapter = createSelector(
    selectState,
    (state) => state.activeChapter
);

const selectActiveChapterId = createSelector(
    selectActiveChapter,
    (chapter) => chapter?.id
);

const selectHasActiveChapter = createSelector(
    selectActiveChapter,
    (activeChapter) => !isNil(activeChapter)
);

const selectIsLoadingChapter = createSelector(
    selectState,
    (state) => state.isLoadingChapter
);

const selectActiveChapterSectionIndex = createSelector(
    selectState,
    (state) => state.activeChapterSectionIndex
);

const selectMostAdvancedChapterSectionIndex = createSelector(
    selectState,
    (state) => state.mostAdvancedChapterSectionIndex
);

const selectActiveChapterSection = createSelector(selectState, (state) =>
    state.activeChapter?.sections.at(state.activeChapterSectionIndex)
);

const selectHasAlreadyCompletedSection = createSelector(
    selectMostAdvancedChapterSectionIndex,
    selectActiveChapterSectionIndex,
    (mostAdvancedChapterSectionIndex, activeChapterSectionIndex) =>
        mostAdvancedChapterSectionIndex > activeChapterSectionIndex
);

const selectHasLoadedActiveChapterSectionComments = createSelector(
    selectState,
    CourseSectionCommentSelectors.selectData,
    (state, data) => {
        return (
            data?.length > 0 &&
            data[0].chapterId === state.activeChapter.id &&
            data[0].sectionId ===
                state.activeChapter.sections[state.activeChapterSectionIndex].id
        );
    }
);

const selectActiveChapterSectionComments = createSelector(
    selectState,
    selectHasLoadedActiveChapterSectionComments,
    CourseSectionCommentSelectors.selectData,
    (state, hasLoadedAllComments, data) =>
        hasLoadedAllComments
            ? data.slice(0, 3)
            : state.activeChapter?.sections.at(state.activeChapterSectionIndex)
                  .lastComments
);

const selectActiveChapterSectionCommentsCount = createSelector(
    selectState,
    selectHasLoadedActiveChapterSectionComments,
    CourseSectionCommentSelectors.selectTotalCount,
    (state, hasLoadedAllComments, totalCount) =>
        hasLoadedAllComments
            ? totalCount
            : state.activeChapter?.sections[state.activeChapterSectionIndex]
                  .totalComments
);

const selectActiveChapterSectionId = createSelector(
    selectActiveChapterSection,
    (section) => section?.id
);

const selectIsActiveChapterSectionLastOfChapter = createSelector(
    selectState,
    (state) =>
        state.activeChapterSectionIndex ===
        state.activeChapter?.sections.length - 1
);

const selectActiveChapterSectionsCount = createSelector(
    selectActiveChapter,
    (activeChapter) => activeChapter?.sections.length
);

const selectActiveChapterCourseTitle = createSelector(
    selectActiveChapter,
    (activeChapter) => activeChapter?.courseTitle
);

const selectActiveChapterTitle = createSelector(
    selectActiveChapter,
    (activeChapter) => activeChapter?.title
);

const selectIsLastAnswerCorrect = createSelector(
    selectState,
    (state) => state.isLastAnswerCorrect
);

const selectIsLoadingReview = createSelector(
    selectState,
    (state) => state.isLoadingReview
);

const selectReview = createSelector(selectState, (state) => state.review);

const selectBasileReaction = createSelector(
    selectActiveChapterSectionsCount,
    selectActiveChapterSectionIndex,
    selectActiveChapterSection,
    selectIsLastAnswerCorrect,
    (
        sectionsCount,
        activeSectionIndex,
        activeSection,
        isLastAnswerCorrect
    ): BasileReaction => {
        if (activeSectionIndex === 0) {
            return {
                posture: sample(['hello', 'peeking-right']),
                textKey: 'chapter-start.' + randomNumberFrom1To(10).toString(),
            };
        } else if (activeSectionIndex === sectionsCount - 1) {
            return {
                posture: sample(['enthusiast', 'happy', 'flexing']),
                textKey: 'chapter-end.' + randomNumberFrom1To(10).toString(),
            };
        } else if (!isNil(isLastAnswerCorrect)) {
            if (isLastAnswerCorrect) {
                return {
                    posture: 'good-answer',
                    textKey:
                        'good-answer.' + randomNumberFrom1To(10).toString(),
                };
            }
            return {
                posture: 'bad-answer',
                textKey: 'bad-answer.' + randomNumberFrom1To(10).toString(),
            };
        }
        switch (activeSection.type) {
            case ECourseSectionType.Anecdote:
                return {
                    posture: 'smiling',
                    textKey: 'anecdote.' + randomNumberFrom1To(10).toString(),
                };
            case ECourseSectionType.Text:
                return {
                    posture: sample(['guessing', 'reading', 'pointing']),
                    textKey: 'text.' + randomNumberFrom1To(20).toString(),
                };
            case ECourseSectionType.Video:
                return {
                    posture: 'jumping',
                    textKey: 'video.' + randomNumberFrom1To(10).toString(),
                };
            case ECourseSectionType.TrueFalseQuestion:
                return {
                    posture: 'asking',
                    textKey:
                        'true-or-false.' + randomNumberFrom1To(10).toString(),
                };
            case ECourseSectionType.MultipleChoiceQuestion:
                return {
                    posture: 'asking',
                    textKey:
                        'open-question.' + randomNumberFrom1To(10).toString(),
                };
            case ECourseSectionType.Survey:
                return {
                    posture: 'asking',
                    textKey: 'survey.' + randomNumberFrom1To(10).toString(),
                };
        }
    }
);

const selectExperienceGained = createSelector(
    selectState,
    (state) => state.experienceGained
);
const selectPurposeCoinsGained = createSelector(
    selectState,
    (state) => state.purposeCoinsGained
);

const selectIsLoadingRating = createSelector(
    selectState,
    (state) => state.isLoadingRating
);

export const CourseSelectors = {
    selectActiveChapter,
    selectActiveChapterCourseTitle,
    selectActiveChapterId,
    selectActiveChapterSection,
    selectActiveChapterSectionComments,
    selectActiveChapterSectionCommentsCount,
    selectActiveChapterSectionId,
    selectActiveChapterSectionIndex,
    selectActiveChapterSectionsCount,
    selectActiveChapterTitle,
    selectBasileReaction,
    selectHasActiveChapter,
    selectIsActiveChapterSectionLastOfChapter,
    selectIsLoadingChapter,
    selectIsLoadingReview,
    selectMostAdvancedChapterSectionIndex,
    selectHasAlreadyCompletedSection,
    selectReview,
    selectPurposeCoinsGained,
    selectExperienceGained,
    selectIsLoadingRating,
};
