<ng-container [formGroup]="formGroup">
    <bslk-card-toggle
        [label]="'createmission.slot-type' | transloco"
        [control]="formGroup.controls.isDateSelectionVolunteerBased"
        [choices]="[
            {
                text: 'createmission.specific-or-recurring-slots' | transloco,
                description:
                    'createmission.specific-or-recurring-slots-description'
                    | transloco,
                icon: 'heroicons_outline:calendar',
                value: false
            },
            {
                text:
                    'createmission.slots-to-be-defined-with-volunteers'
                    | transloco,
                description:
                    'createmission.slots-to-be-defined-with-volunteers-description'
                    | transloco,
                icon: 'heroicons_outline:chat-bubble-left-right',
                value: true
            }
        ]"
        [value]="formGroup.controls.isDateSelectionVolunteerBased.value"
        (valueChange)="slotTypeChange($event)"
    ></bslk-card-toggle>

    <div class="w-full md:w-1/2 mt-3">
        <bslk-slider
            *ngIf="displayNumberOfSpotsPerSlot"
            [label]="'createmission.number-of-spots-per-slot' | transloco"
            [max]="10"
            [unitLabel]="'createmission.spots-per-slot' | transloco"
            [control]="formGroup.controls.maxVolunteersBySlot"
            [value]="formGroup.controls.maxVolunteersBySlot.value"
        ></bslk-slider>
    </div>

    <div *ngIf="!isDateSelectionVolunteerBased">
        <create-mission-calendar></create-mission-calendar>
    </div>

    <div
        class="flex mt-6 gap-x-6 justify-between"
        *ngIf="isDateSelectionVolunteerBased"
    >
        <mat-form-field
            [subscriptSizing]="'dynamic'"
            floatLabel="always"
            (click)="picker.open()"
            class="flex-1"
        >
            <mat-label
                >{{ "createmission.expiration-date" | transloco }}
                <bslk-info
                    [tooltip]="'createmission.expiration-date-info' | transloco"
                ></bslk-info>
            </mat-label>
            <input
                class="cursor-pointer"
                matInput
                readonly
                [matDatepicker]="picker"
                [matDatepickerFilter]="getDatesFilter"
                [formControl]="formGroup.controls.expirationDate"
            />
            <mat-datepicker-toggle
                matPrefix
                [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="flex-1" [subscriptSizing]="'dynamic'">
            <mat-label
                >{{ "createmission.max-unique-slots-proposals" | transloco }}
                <bslk-info
                    [tooltip]="
                        'createmission.max-unique-slots-proposals-info'
                            | transloco
                    "
                ></bslk-info>
            </mat-label>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_outline:user-plus'"
                matPrefix
            ></mat-icon>
            <input
                [formControl]="formGroup.controls.maxUniqueSlotProposals"
                type="number"
                matInput
            />
        </mat-form-field>
    </div>
</ng-container>
