import { createSelector } from '@ngrx/store';
import { AppState } from '..';

const selectState = (state: AppState) => state.feedback;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectIsFeedbackSent = createSelector(
    selectState,
    (state) => state.isFeedbackSent
);

export const FeedbackSelectors = {
    selectIsLoading,
    selectIsFeedbackSent,
};
