import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ETrackedActivityType } from 'app/api';
import { ActivityService } from 'app/shared/services/activity/activity.service';
import { MissionManageActions } from 'app/store/mission/mission-manage/mission-manage.action';
import { MissionManageSelectors } from 'app/store/mission/mission-manage/mission-manage.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';
import { CoverTemplateComponent } from '../../templates/cover-template/cover-template.component';
import { MissionMembersComponent } from './members/mission-members.component';
import { MissionSlotPickerComponent } from './slot-picker/mission-slot-picker.component';
import { MissionTasksComponent } from './tasks/mission-tasks.component';

@Component({
    selector: 'mission-manage',
    standalone: true,
    templateUrl: './mission-manage.component.html',
    imports: [
        AsyncPipe,
        CoverTemplateComponent,
        LetDirective,
        MatButtonModule,
        MatIconModule,
        MissionMembersComponent,
        MissionSlotPickerComponent,
        MissionTasksComponent,
        NgIf,
        NgxSkeletonLoaderModule,
        RouterLink,
        TranslocoModule,
    ],
})
export class MissionManageComponent implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();

    isAdmin$ = this.store.select(UserSelectors.selectIsAssoAdmin);

    isLoading$ = this.store.select(MissionManageSelectors.selectIsLoading);

    selectedSlotUsers$ = this.store.select(
        MissionManageSelectors.selectSelectedSlotUsers
    );

    mission$ = this.store.select(MissionManageSelectors.selectMission);

    hasSelectedSlot$ = this.store.select(
        MissionManageSelectors.selectHasSelectedSlot
    );

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private activityService: ActivityService
    ) {}

    ngOnInit(): void {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                if (params['id']) {
                    const missionSlotId = params['missionSlotId'];
                    this.store.dispatch(
                        MissionManageActions.loadMissionManageRequest({
                            id: params['id'],
                            missionSlotId: missionSlotId
                                ? parseInt(missionSlotId)
                                : null,
                        })
                    );
                    this.activityService.sendActivityRequest({
                        trackedActivityType:
                            ETrackedActivityType.ViewManageMissionPage,
                        details: params['id'],
                    });
                }
            });
    }

    onSlotPicked(slotId: number) {
        this.store.dispatch(
            MissionManageActions.selectMissionSlot({ id: slotId })
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
