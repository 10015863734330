import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    AwarenessCampaignThematicViewModel,
    AwarenessCampaignViewModel,
} from 'app/api';
import { returnOnlyNonNullFields } from 'app/shared/utils/generic-helpers.utils';
import { DateTime } from 'luxon';
import { IFormService } from './form-service';

@Injectable({
    providedIn: 'root',
})
export class AwarenessCampaignFormService
    implements IFormService<AwarenessCampaignViewModel>
{
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder) {
        this.initForm();
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            id: [null],
            name: [null, Validators.required],
            thematics: this.fb.array([
                this.createThematic(),
                this.createThematic(),
            ]),
            dateCreated: [DateTime.now().toISO()],
        });
        return this.formGroup;
    }

    createThematic(thematic?: AwarenessCampaignThematicViewModel): FormGroup {
        return this.fb.group({
            id: [thematic?.id ?? 0],
            thematic: [thematic?.thematic ?? null, Validators.required],
            startDate: [
                thematic?.startDate
                    ? DateTime.fromISO(thematic.startDate)
                    : null,
                Validators.required,
            ],
            endDate: [
                thematic?.endDate ? DateTime.fromISO(thematic.endDate) : null,
                Validators.required,
            ],
            dateCreated: [thematic?.dateCreated ?? DateTime.now().toISO()],
        });
    }

    updateForm(entity: AwarenessCampaignViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
        this.formGroup.controls.thematics = this.fb.array([]);
        const thematicsFormControl = this.formGroup.controls
            .thematics as FormArray;
        entity.thematics.forEach((thematic) => {
            const thematicControl = this.createThematic(thematic);
            thematicsFormControl.push(thematicControl);
        });
        this.formGroup.updateValueAndValidity();
    }

    getEntity(): { [key: string]: AwarenessCampaignViewModel } {
        const awarenessCampaignViewModel =
            returnOnlyNonNullFields<AwarenessCampaignViewModel>(
                this.formGroup.value
            ) as AwarenessCampaignViewModel;

        awarenessCampaignViewModel.thematics =
            awarenessCampaignViewModel.thematics.map(
                (t) =>
                    returnOnlyNonNullFields<AwarenessCampaignThematicViewModel>(
                        t
                    ) as AwarenessCampaignThematicViewModel
            );

        return { awarenessCampaignViewModel };
    }

    get getFormGroup() {
        return this.formGroup;
    }
}
