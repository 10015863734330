<div class="w-full p-8">
    <company-admin-dashboard-header></company-admin-dashboard-header>

    <div class="mt-8 grid grid-cols-12 gap-8">
        <ng-container *ngIf="isParticipationRateDisplayed$ | async">
            <participation-rate
                [ngClass]="
                    (isParticipationRateExpanded$ | async)
                        ? 'col-span-12 order-first'
                        : 'col-span-12 md:col-span-7 xl:col-span-4'
                "
                class="h-64 xxl:h-80"
            ></participation-rate>
        </ng-container>
        <ng-container *ngIf="isAwarenessRateDisplayed$ | async">
            <awareness-rate
                [ngClass]="
                    (isAwarenessRateExpanded$ | async)
                        ? 'col-span-12 order-first'
                        : 'col-span-12 md:col-span-7 xl:col-span-4'
                "
                class="h-64 xxl:h-80"
            ></awareness-rate>
        </ng-container>
        <ng-container *ngIf="isEmployeeOnAssignmentDisplayed$ | async">
            <employees-on-assignment
                [ngClass]="
                    (isEmployeeOnAssignmentExpanded$ | async)
                        ? 'col-span-12 order-first'
                        : 'h-64 xxl:h-80 col-span-12 md:col-span-6 lg:col-span-4'
                "
            ></employees-on-assignment>
        </ng-container>
        <ng-container *ngIf="isSatisfactionRateDisplayed$ | async">
            <satisfaction-rate
                class="h-64 xxl:h-80 col-span-12"
                [ngClass]="
                    (isSatisfactionRateExpanded$ | async)
                        ? 'order-first'
                        : 'md:col-span-5 xl:col-span-3'
                "
            ></satisfaction-rate>
        </ng-container>
        <ng-container *ngIf="isTrackingMyImpactDisplayed$ | async">
            <tracking-my-impact
                class="col-span-12 h-64 xxl:h-80"
                [ngClass]="
                    (isTrackingMyImpactExpanded$ | async)
                        ? 'order-first'
                        : 'md:col-span-7 xl:col-span-5'
                "
            ></tracking-my-impact>
        </ng-container>
        <ng-container
            *ngIf="isGenderParticipationRepartitionDisplayed$ | async"
        >
            <gender-participation-repartition
                [ngClass]="
                    (isGenderParticipationRepartitionExpanded$ | async)
                        ? 'order-first'
                        : 'md:col-span-5 xl:col-span-3'
                "
                class="h-64 xxl:h-80 col-span-12"
            ></gender-participation-repartition>
        </ng-container>

        <ng-container *ngIf="isMissionByGoalsDisplayed$ | async">
            <missions-by-goals
                [ngClass]="
                    (isMissionByGoalsExpanded$ | async)
                        ? 'order-first'
                        : 'md:col-span-5'
                "
                class="col-span-12 h-64 xxl:h-80"
            ></missions-by-goals>
        </ng-container>
        <ng-container *ngIf="isAllCompanyMissionsDisplayed$ | async">
            <all-company-missions
                [ngClass]="
                    (isAllCompanyMissionsExpanded$ | async)
                        ? 'col-span-12 order-first'
                        : 'h-64 xxl:h-80 col-span-12 md:col-span-6 lg:col-span-4 xxl:col-span-3'
                "
            ></all-company-missions>
        </ng-container>

        <ng-container *ngIf="isDualQuizAnswersBySdgDisplayed$ | async">
            <dual-quiz-answers-by-sdg
                [ngClass]="
                    (isDualQuizAnswersBySdgExpanded$ | async)
                        ? 'order-first'
                        : 'md:col-span-5'
                "
                class="col-span-12 h-64 xxl:h-80"
            ></dual-quiz-answers-by-sdg>
        </ng-container>

        <ng-container *ngIf="isAwarenessProgressionDisplayed$ | async">
            <awareness-progression class="col-span-12"></awareness-progression>
        </ng-container>
    </div>
</div>
