import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterLink } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { CustomValidators } from 'app/shared/utils/custom-validators.utils';
import { ValidationActions } from 'app/store/validation/validation.actions';
import { ValidationSelectors } from 'app/store/validation/validation.selectors';

@Component({
    selector: 'auth-forgot-password',
    styleUrls: ['./forgot-password.component.scss'],
    templateUrl: './forgot-password.component.html',
    animations: fuseAnimations,
    standalone: true,
    imports: [
        NgIf,
        FuseAlertComponent,
        MatStepperModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        RouterLink,
        LetDirective,
        TranslocoModule,
        NgTemplateOutlet,
        AsyncPipe,
    ],
})
export class AuthForgotPasswordComponent {
    emailForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
    });

    codeForm = this.formBuilder.group({
        code: ['', [Validators.required, CustomValidators.code]],
    });

    newPasswordForm = this.formBuilder.group({
        password: ['', [Validators.required, CustomValidators.password]],
    });

    isLoading$ = this.store.select(ValidationSelectors.selectIsLoading);

    stepIndex$ = this.store.select(ValidationSelectors.selectStepIndex);

    emailError$ = this.store.select(ValidationSelectors.selectEmailError);

    codeError$ = this.store.select(ValidationSelectors.selectCodeError);

    passwordError$ = this.store.select(ValidationSelectors.selectPasswordError);

    constructor(
        private formBuilder: UntypedFormBuilder,
        private store: Store
    ) {}

    sendCode() {
        if (this.emailForm.valid) {
            this.store.dispatch(
                ValidationActions.sendEmailRequest({
                    email: this.emailForm.controls.email.value,
                })
            );
        }
    }

    validateCode() {
        if (this.codeForm.valid) {
            this.store.dispatch(
                ValidationActions.sendCodeRequest({
                    code: this.codeForm.controls.code.value,
                })
            );
        }
    }

    setPassword() {
        if (this.newPasswordForm.valid) {
            this.store.dispatch(
                ValidationActions.sendSetPasswordRequest({
                    password: this.newPasswordForm.controls.password.value,
                })
            );
        }
    }
}
