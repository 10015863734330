import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AssociationService } from 'app/api';
import { AssociationFormService } from 'app/shared/services/form/association-form.service';
import {
    createCreateItemEffect,
    createDeleteItemEffect,
    createLoadListRequestEffect,
    createReloadEffect,
    createSelectedItemEffect,
    createStartCreationEffect,
    createUpdateSelectedItemEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { AssociationListActions } from './associations.actions';
import { AssociationListSelectors } from './associations.selectors';

@Injectable()
export class AssociationListEffects {
    loadAssociations$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.associationService,
        AssociationListActions,
        AssociationListSelectors,
        this.associationService.associationGet
    );

    reload$ = createReloadEffect(this.actions$, AssociationListActions);

    itemSelected$ = createSelectedItemEffect(
        this.store,
        this.actions$,
        AssociationListActions,
        AssociationListSelectors,
        this.associationFormService
    );

    updateAssociation$ = createUpdateSelectedItemEffect(
        this.associationService,
        this.actions$,
        AssociationListActions,
        this.associationFormService,
        'association-update',
        this.associationService.associationPut
    );

    createAssociation$ = createCreateItemEffect(
        this.associationService,
        this.actions$,
        AssociationListActions,
        this.associationFormService,
        'association-create',
        this.associationService.associationPost
    );

    deleteAssociation$ = createDeleteItemEffect(
        this.associationService,
        this.actions$,
        AssociationListActions,
        'association-delete',
        this.associationService.associationDelete
    );

    startCreation$ = createStartCreationEffect(
        this.actions$,
        AssociationListActions,
        this.associationFormService
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private associationService: AssociationService,
        private associationFormService: AssociationFormService
    ) {}
}
