import {
    AsyncPipe,
    DatePipe,
    JsonPipe,
    NgClass,
    NgFor,
    NgIf,
} from '@angular/common';
import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { CourseSectionCommentFormService } from 'app/shared/services/form/course-section-comment-form.service';
import { CourseSectionCommentsActions } from 'app/store/course/course-section-comments/course-section-comments.actions';
import { CourseSectionCommentSelectors } from 'app/store/course/course-section-comments/course-section-comments.selectors';
import { isEmpty } from 'lodash-es';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CourseSectionCommentComponent } from '../../comment/comment.component';

@Component({
    selector: 'chapter-section-drawer-comments',
    templateUrl: './comments-panel.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        DatePipe,
        FormsModule,
        FuseDrawerComponent,
        JsonPipe,
        LetDirective,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        NgClass,
        MatPaginatorModule,
        NgFor,
        NgIf,
        NgxSkeletonLoaderModule,
        ReactiveFormsModule,
        TranslocoModule,
        CourseSectionCommentComponent,
    ],
})
export class ChapterSectionDrawerCommentsComponent implements OnChanges {
    @ViewChild('commentsDrawer') drawer: FuseDrawerComponent;

    @Input() openEvent: string;
    @Input() chapterId: string;
    @Input() sectionId: string;

    comments$ = this.store.select(CourseSectionCommentSelectors.selectData);
    isLoading$ = this.store.select(
        CourseSectionCommentSelectors.selectIsLoading
    );
    totalCount$ = this.store.select(
        CourseSectionCommentSelectors.selectTotalCount
    );
    pageIndex$ = this.store.select(
        CourseSectionCommentSelectors.selectPageIndex
    );
    pageSize$ = this.store.select(CourseSectionCommentSelectors.selectPageSize);
    totalPages$ = this.store.select(
        CourseSectionCommentSelectors.selectTotalPages
    );

    constructor(
        private store: Store,
        private courseSectionCommentFormService: CourseSectionCommentFormService
    ) {}

    get formGroup() {
        return this.courseSectionCommentFormService.getFormGroup;
    }

    get isCommentEmpty() {
        return isEmpty(this.formGroup.controls.comment.value);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.openEvent) {
            this.openDrawer();
        }
        if (changes.chapterId) {
            this.formGroup.controls.chapterId.setValue(
                changes.chapterId.currentValue
            );
        }
        if (changes.sectionId) {
            this.formGroup.controls.sectionId.setValue(
                changes.sectionId.currentValue
            );
        }
    }

    openDrawer() {
        if (!this.drawer) {
            return;
        }
        this.drawer.open();
        this.store.dispatch(
            CourseSectionCommentsActions.FilterListRequest({
                filters: [
                    {
                        key: 'chapterId',
                        value: this.chapterId,
                    },
                    {
                        key: 'sectionId',
                        value: this.sectionId,
                    },
                ],
            })
        );
    }

    comment() {
        if (!this.isCommentEmpty) {
            this.store.dispatch(CourseSectionCommentsActions.CreateItem());
            this.formGroup.controls.comment.reset();
        }
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            CourseSectionCommentsActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
    }
}
