import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { BslkDragService } from '@bslk/services/drag.service';
import { Subscription } from 'rxjs';

export type BslkDragHoverEvent = { hovered: boolean; data: any };
export type BslkDragDropEvent = { data: any };

@Directive({
    selector: '[bslkDropZone]',
    standalone: true,
})
export class BslkDropZoneDirective {
    @Output() hovered = new EventEmitter<BslkDragHoverEvent>();
    @Output() dropped = new EventEmitter<BslkDragDropEvent>();

    private isDragging: boolean = false;
    private currentDragData: any;
    private dragSubscription: Subscription;
    private dragDataSubscription: Subscription;

    constructor(private dragService: BslkDragService) {
        this.dragSubscription = this.dragService.dragging$.subscribe(
            (isDragging) => {
                this.isDragging = isDragging;
            }
        );
        this.dragDataSubscription = this.dragService
            .getDragData()
            .subscribe((data) => {
                this.currentDragData = data;
            });
    }

    @HostListener('mouseenter', ['$event'])
    onEnter(event: CustomEvent): void {
        if (!this.isDragging) return;
        this.hovered.emit({ hovered: true, data: this.currentDragData });
    }

    @HostListener('mouseleave', ['$event'])
    onLeave(event: CustomEvent): void {
        if (!this.isDragging) return;
        this.hovered.emit({ hovered: false, data: this.currentDragData });
    }

    @HostListener('mouseup', ['$event'])
    onDrop(event: MouseEvent): void {
        if (!this.isDragging) return;
        this.dropped.emit({ data: this.currentDragData });
        this.dragService.endDrag();
    }

    ngOnDestroy() {
        this.dragSubscription.unsubscribe();
        this.dragDataSubscription.unsubscribe();
    }
}
