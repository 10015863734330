import { createSelector } from '@ngrx/store';
import { MissionUnregisteredViewModel } from 'app/api';
import { AppState } from '../..';
import {
    RessourceListSelectors,
    createRessourceListSelectors,
} from '../../shared/ressource-list/ressource-list-selectors.creator';

const selectState = (state: AppState) => state.missionList;

const selectCities = createSelector(selectState, (state) => state.cities);

const selectIsLoadingCities = createSelector(
    selectState,
    (state) => state.isLoadingCities
);

const selectHasCities = createSelector(
    selectState,
    (state) => state.cities.length > 0
);

interface AdditionalSelectors {
    selectCities: (state: any) => string[];
    selectIsLoadingCities: (state: any) => boolean;
    selectHasCities: (state: any) => boolean;
}

type MissionListSelectors =
    RessourceListSelectors<MissionUnregisteredViewModel> & AdditionalSelectors;

export const MissionListSelectors: MissionListSelectors = {
    ...createRessourceListSelectors('missionList'),
    selectCities,
    selectIsLoadingCities,
    selectHasCities,
};
