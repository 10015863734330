export function extractTextFromHtml(value: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, 'text/html');
    return doc.body.textContent || '';
}

export function extractYouTubeVideoId(url: string): string {
    const regex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([\w-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
}
