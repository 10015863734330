import { difference } from 'lodash-es';

export function hasExactlyOneAdditionalValue(arr1, arr2) {
    return (
        arr2.length === arr1.length + 1 && difference(arr2, arr1).length === 1
    );
}
export function getAdditionalValueLodash(arr1, arr2): any {
    if (arr2.length === arr1.length + 1) {
        let diff = difference(arr2, arr1);
        if (diff.length === 1) {
            return diff[0];
        }
    }
    return null;
}

// Transform a dictionary to a value sorted tuple
export function dictionaryToSortedTuple(dict: {
    [key: string]: number;
}): [string, number][] {
    const entries = Object.entries(dict);

    const sortedEntries = entries.sort((a, b) => b[1] - a[1]);

    return sortedEntries;
}
