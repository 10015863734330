<ng-container *ngIf="!asOverlay">
    <div
        class="flex flex-wrap gap-2 py-2.5 px-6 border border-gray-300 rounded-3xl mb-4 min-h-11"
        *ngIf="displayValueInField"
    >
        <bslk-tag
            class="cursor-pointer"
            (click)="sdgClicked(sdg.key)"
            [size]="'small'"
            [icon]="sdg.value.icon"
            [background]="null"
            [isRemovable]="true"
            [backgroundHexaColor]="
                value?.includes(sdg.key) || !reduceNonSelectedOpacity
                    ? sdg.value.color
                    : sdg.value.color + 'B3'
            "
            *ngFor="let sdg of pickedSustainableDevelopmentGoals | keyvalue"
        >
            {{ sdg.value.translation }}
        </bslk-tag>
    </div>
    <ng-container *ngTemplateOutlet="picker"></ng-container>
</ng-container>

<ng-container *ngIf="asOverlay">
    <div #pickerOrigin class="flex">
        <bslk-tag
            *ngIf="!value"
            class="cursor-pointer text-white"
            (click)="openPanel()"
            [size]="'small'"
        >
            <span>{{ "bslkbusiness.sdg-picker.pick" | transloco }}</span>
            <mat-icon
                [svgIcon]="'heroicons_outline:chevron-down'"
                class="ml-1 text-current"
            ></mat-icon>
        </bslk-tag>
        <div *ngIf="value && isSingularValue">
            <bslk-tag
                class="cursor-pointer"
                (click)="openPanel()"
                [size]="'small'"
                [icon]="sustainableDevelopmentGoals[value].icon"
                [background]="null"
                [backgroundHexaColor]="sustainableDevelopmentGoals[value].color"
            >
                {{ sustainableDevelopmentGoals[value].translation }}
                <mat-icon
                    [svgIcon]="'heroicons_outline:chevron-down'"
                    class="ml-1 text-current"
                ></mat-icon>
            </bslk-tag>
        </div>
    </div>
</ng-container>

<bslk-classic-overlay
    [isOpen]="isPanelOpen"
    [origin]="pickerOrigin"
    [opaqueBackground]="true"
    [hideHeader]="true"
    (close)="onClose()"
>
    <ng-container *ngTemplateOutlet="picker"></ng-container>
</bslk-classic-overlay>

<ng-template #picker>
    <div *ngIf="displayNonSelectedLabel" class="font-bold mb-2">
        {{ "bslkbusiness.sdg-picker.non-selected" | transloco }}
    </div>
    <span
        class="text-hint"
        *ngIf="displayNonSelectedLabel && isEverySdgSelected"
        >{{ "bslkbusiness.sdg-picker.no-non-selected" | transloco }}</span
    >
    <div
        [class.justify-center]="justify === 'center'"
        [class.justify-start]="justify === 'start'"
        [ngClass]="columnDisplay ? 'flex-col' : 'items-center'"
        class="flex flex-wrap gap-1"
    >
        <bslk-tag
            class="cursor-pointer"
            (click)="sdgClicked(sdg.key)"
            [size]="
                value?.includes(sdg.key) && !displayValueInField
                    ? 'normal'
                    : 'small'
            "
            [isDisabled]="!value?.includes(sdg.key)"
            [icon]="sdg.value.icon"
            [background]="null"
            [backgroundHexaColor]="
                value?.includes(sdg.key) || !reduceNonSelectedOpacity
                    ? sdg.value.color
                    : sdg.value.color + 'B3'
            "
            *ngFor="
                let sdg of (displayValueInField
                    ? notPickedSustainableDevelopmentGoals
                    : sustainableDevelopmentGoals
                ) | keyvalue
            "
        >
            {{ sdg.value.translation }}
        </bslk-tag>
    </div>
</ng-template>
