import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SuperAdminDashboardService } from 'app/api';
import { Observable, catchError, filter, map, mergeMap, of, tap } from 'rxjs';
import { SuperAdminDashboardActions } from './super-admin-dashboard.actions';
import { SuperAdminDashboardSelectors } from './super-admin-dashboard.selectors';
import {
    SuperAdminKpi,
    SuperAdminKpiResponse,
} from './super-admin-dashboard.state';

@Injectable()
export class SuperAdminDashboardEffects {
    checkIfKpiIsLoaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SuperAdminDashboardActions.checkIfKpiIsLoaded),
            concatLatestFrom(({ kpi }) =>
                this.store.select(
                    SuperAdminDashboardSelectors.selectIsKpiResultReady(kpi)
                )
            ),
            filter(([, isKpiResultReady]) => !isKpiResultReady),
            map(([{ kpi }]) =>
                SuperAdminDashboardActions.loadKpiRequest({
                    kpi,
                })
            )
        )
    );

    triggerLoadOnFilterUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SuperAdminDashboardActions.updateKpiFilters),
            map(({ kpi }) =>
                SuperAdminDashboardActions.loadKpiRequest({
                    kpi,
                })
            )
        )
    );

    scrollTopOnExpand$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SuperAdminDashboardActions.expandKpi),
                tap(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
            ),
        { dispatch: false }
    );

    loadKpi$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SuperAdminDashboardActions.loadKpiRequest),
            concatLatestFrom(({ kpi }) =>
                this.store.select(
                    SuperAdminDashboardSelectors.selectKpiFilters(kpi)
                )
            ),
            mergeMap(([{ kpi }, filters]) => {
                const apiCall = this.getKpiApiCall(kpi);
                const boundApiCall = apiCall.bind(
                    this.superAdminDashboardService
                );
                const apiObservable = boundApiCall(
                    filters
                ) as Observable<SuperAdminKpiResponse>;
                return apiObservable.pipe(
                    map((response) =>
                        SuperAdminDashboardActions.loadKpiRequestSuccess({
                            kpi,
                            response,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            SuperAdminDashboardActions.loadKpiRequestFail({
                                kpi,
                                error: httpResponse?.error ?? httpResponse,
                            })
                        )
                    )
                );
            })
        )
    );

    getKpiApiCall(kpi: SuperAdminKpi) {
        switch (kpi) {
            case 'pending-missions':
                return this.superAdminDashboardService
                    .superAdminDashboardPendingMissionSlotsGet;
            case 'active-users':
                return this.superAdminDashboardService
                    .superAdminDashboardActiveUsersGet;
            default:
                throw Error(`KPI ${kpi} not supported`);
        }
    }

    constructor(
        private actions$: Actions,
        private store: Store,
        private superAdminDashboardService: SuperAdminDashboardService
    ) {}
}
