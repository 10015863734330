<base-dialog
    (onClose)="close()"
    [title]="'donationcampaign.configure-share-campaign' | transloco"
    [showActions]="true"
    [scrollablePadding]="true"
>
    <div
        class="w-full h-full justify-center flex flex-col gap-4 overflow-y-scroll"
        body
    >
        <div class="overflow-y-scroll pr-2">
            <form [formGroup]="formGroup" class="flex flex-col gap-4">
                <bslk-card-toggle
                    (valueChange)="onWhitelistTypeChange($event)"
                    [label]="
                        'donationcampaign.participation-restriction' | transloco
                    "
                    [control]="formGroup.controls.whitelistType"
                    [choices]="[
                        {
                            text: 'donationcampaign.no-restriction' | transloco,
                            description:
                                'donationcampaign.no-restriction-description'
                                | transloco,
                            icon: 'feather:shield-off',
                            value: EDonationCampaignWhitelistType.None
                        },
                        {
                            text: 'donationcampaign.email-list' | transloco,
                            description:
                                'donationcampaign.email-list-description'
                                | transloco,
                            icon: 'feather:at-sign',
                            value: EDonationCampaignWhitelistType.Email
                        },
                        {
                            text: 'donationcampaign.code-list' | transloco,
                            description:
                                'donationcampaign.code-list-description'
                                | transloco,
                            icon: 'feather:key',
                            value: EDonationCampaignWhitelistType.Code
                        }
                    ]"
                    [value]="formGroup.controls.whitelistType.value"
                ></bslk-card-toggle>

                <div
                    *ngIf="
                        hasSwitchedToCodeWhitelist &&
                        !initialWhitelistTypeIsCode &&
                        formGroup.controls.whitelistType.value ===
                            EDonationCampaignWhitelistType.Code
                    "
                    class="flex flex-col gap-2"
                >
                    <mat-form-field
                        *ngIf="formGroup.controls.isOneEnvelope.value"
                        class="w-full"
                        [subscriptSizing]="'dynamic'"
                    >
                        <mat-label>{{
                            "donationcampaign.number-of-codes-to-generate"
                                | transloco
                        }}</mat-label>
                        <input
                            matInput
                            type="number"
                            [formControlName]="'codesToGenerate'"
                        />
                    </mat-form-field>

                    <div
                        *ngIf="
                            hasSubmitted &&
                            formGroup.hasError('emptyCodesToGenerate')
                        "
                        class="flex justify-center"
                    >
                        <fuse-alert [type]="'error'">
                            {{
                                "donationcampaign.empty-codes-to-generate-error"
                                    | transloco
                            }}
                        </fuse-alert>
                    </div>

                    <div class="flex justify-center">
                        <fuse-alert [type]="'info'"
                            >{{
                                "donationcampaign.codes-generation-info"
                                    | transloco
                            }}
                        </fuse-alert>
                    </div>
                </div>

                <bslk-sheet-data-editor
                    *ngIf="
                        formGroup.controls.whitelistType.value ===
                        EDonationCampaignWhitelistType.Email
                    "
                    (dataChanged)="onDataChanged($event)"
                    (importClicked)="onImportClicked()"
                    [entities]="emails"
                    [fields]="['email']"
                    [placeholders]="{
                        email: 'email@nomdedomaine.com'
                    }"
                ></bslk-sheet-data-editor>

                <div
                    *ngIf="hasSubmitted && formGroup.hasError('emptyEmails')"
                    class="flex justify-center"
                >
                    <fuse-alert [type]="'error'">
                        {{ "donationcampaign.empty-emails-error" | transloco }}
                    </fuse-alert>
                </div>

                <div class="flex flex-col gap-1">
                    <bslk-info-label
                        class="font-bold"
                        [tooltip]="
                            'donationcampaign.link-customization-tooltip'
                                | transloco
                        "
                        >{{
                            "donationcampaign.link-customization" | transloco
                        }}</bslk-info-label
                    >
                    <mat-form-field
                        class="w-full fuse-mat-emphasized-affix"
                        [subscriptSizing]="'dynamic'"
                    >
                        <span class="text-secondary" matPrefix>{{
                            urlPrefix
                        }}</span>
                        <input
                            (input)="onCustomUrlChange()"
                            matInput
                            type="text"
                            [formControlName]="'accessToken'"
                            required
                        />
                        <mat-error
                            >{{
                                "donationcampaign.link-customization-error"
                                    | transloco
                            }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="flex justify-center" *ngrxLet="error$ as error">
                    <fuse-alert *ngIf="error" [type]="'error'">
                        {{ "donationcampaign.errors." + error | transloco }}
                    </fuse-alert>
                </div>
            </form>

            <div *ngIf="!hasChangedCustomUrl" class="flex flex-col">
                <span class="font-bold">{{
                    "donationcampaign.qr-code" | transloco
                }}</span>
                <div class="flex justify-between items-center">
                    <qrcode
                        (qrCodeURL)="onChangeURL($event)"
                        [qrdata]="donationGiftUrl$ | async"
                        [width]="256"
                        [errorCorrectionLevel]="'M'"
                    ></qrcode>
                    <div class="flex grow justify-center">
                        <a [href]="qrCodeDownloadLink" download="qrcode">
                            <bslk-tag-button
                                [icon]="'heroicons_outline:arrow-down-tray'"
                            >
                                {{
                                    "donationcampaign.download-qr-code"
                                        | transloco
                                }}
                            </bslk-tag-button>
                        </a>
                    </div>
                </div>
            </div>

            <div *ngIf="hasChangedCustomUrl" class="flex justify-center">
                <fuse-alert type="warn">
                    {{ "donationcampaign.link-modified-warning" | transloco }}
                </fuse-alert>
            </div>

            <bslk-classic-toggle
                *ngIf="
                    formGroup.controls.whitelistType.value !==
                    EDonationCampaignWhitelistType.Email
                "
                [label]="'donationcampaign.only-profesional-emails' | transloco"
                [infoTooltip]="
                    'donationcampaign.only-profesional-emails-tooltip'
                        | transloco
                "
                [color]="'warn'"
                [size]="'small'"
                [isLabelBold]="true"
                [choices]="[
                    { text: 'shared.common.yes' | transloco, value: true },
                    { text: 'shared.common.no' | transloco, value: false }
                ]"
                [control]="formGroup.controls.onlyAllowProfessionalEmails"
                [value]="formGroup.controls.onlyAllowProfessionalEmails.value"
                class="flex items-center gap-2"
            ></bslk-classic-toggle>
        </div>
    </div>

    <div class="flex justify-end" actions>
        <bslk-classic-button
            (click)="submit()"
            [isLoading]="isLoading$ | async"
            >{{ "donationcampaign.save" | transloco }}</bslk-classic-button
        >
    </div>
</base-dialog>
