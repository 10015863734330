import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CourseSectionCommentService } from 'app/api';
import { CourseSectionCommentFormService } from 'app/shared/services/form/course-section-comment-form.service';
import {
    createCreateItemEffect,
    createLoadListRequestEffect,
    createReloadEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { CourseSectionCommentsActions } from './course-section-comments.actions';
import { CourseSectionCommentSelectors } from './course-section-comments.selectors';

@Injectable()
export class CourseSectionCommentEffects {
    loadComments$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.courseSectionCommentService,
        CourseSectionCommentsActions,
        CourseSectionCommentSelectors,
        this.courseSectionCommentService.courseSectionCommentGet
    );

    reload$ = createReloadEffect(this.actions$, CourseSectionCommentsActions);

    createComment$ = createCreateItemEffect(
        this.courseSectionCommentService,
        this.actions$,
        CourseSectionCommentsActions,
        this.courseSectionCommentFormService,
        'course-section-comment-create',
        this.courseSectionCommentService.courseSectionCommentPost
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private courseSectionCommentService: CourseSectionCommentService,
        private courseSectionCommentFormService: CourseSectionCommentFormService
    ) {}
}
