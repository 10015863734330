import { Injectable } from '@angular/core';
import { BslkAdvancedSelectSyncInput } from '@bslk/components/form/select/sync/advanced-select-sync.component';
import { TranslocoService } from '@ngneat/transloco';
import {
    EActivityField,
    EDepartment,
    EGender,
    EMissionType,
    ESkill,
    ESustainableDevelopmentGoal,
    EUnit,
} from 'app/api';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    constructor(private translocoService: TranslocoService) {}

    getTranslatedEnum(
        enumObj: any,
        unsubscribeSignal: Subject<any>
    ): Observable<BslkAdvancedSelectSyncInput[]> {
        return this.translocoService
            .selectTranslateObject(this.mapEnumPath(enumObj), {}, 'enums')
            .pipe(
                takeUntil(unsubscribeSignal),
                map((translations) => {
                    return this.mapEnumToTranslatedArray(enumObj, translations);
                })
            );
    }

    private mapEnumToTranslatedArray(
        enumObj: any,
        translations: { [key: string]: string }
    ): BslkAdvancedSelectSyncInput[] {
        return Object.values(enumObj).map((enumValue: string) => ({
            name: translations[enumValue],
            value: enumValue,
        }));
    }

    private mapEnumPath(enumObj: any) {
        switch (enumObj) {
            case EActivityField:
                return 'EActivityField';
            case ESkill:
                return 'ESkill';
            case EDepartment:
                return 'EDepartment';
            case EGender:
                return 'EGender';
            case ESustainableDevelopmentGoal:
                return 'ESustainableDevelopmentGoal';
            case EMissionType:
                return 'EMissionType';
            case EUnit:
                return 'EUnit';
            default:
                return '';
        }
    }
}
