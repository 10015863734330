import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { MissionService } from 'app/api';
import { CreateMissionDialogService } from 'app/modules/association/create-mission/create-mission-dialog.service';
import { MissionRegisterDialogService } from 'app/modules/regular/mission/view/register/mission-register-dialog.service';
import { MissionFormService } from 'app/shared/services/form/mission-form.service';
import { EAccountUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { isNil } from 'lodash-es';
import { catchError, exhaustMap, filter, map, of, switchMap, tap } from 'rxjs';
import { AlertActions } from '../../alert/alert.actions';
import { AssociationSelectors } from '../../association/association/association.selectors';
import { HighlightActions } from '../../highlight/highlight.action';
import { UserSelectors } from '../../user/user/user.selectors';
import { MissionActions } from './mission.action';
import { MissionSelectors } from './mission.selectors';
@Injectable()
export class MissionEffects {
    openDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MissionActions.openDialog),
                tap(({ input }) => {
                    this.createMissionDialogService.open(input);
                })
            ),
        { dispatch: false }
    );

    closeDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MissionActions.closeDialog),
                tap(() => {
                    this.createMissionDialogService.close();
                })
            ),
        { dispatch: false }
    );

    copyLocation$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MissionActions.copyLocation),
                concatLatestFrom(() =>
                    this.store.select(AssociationSelectors.selectLocation)
                ),
                tap(([{ checked }, location]) => {
                    const locationFormGroup =
                        this.missionFormService.getFormGroup.controls.location;
                    if (checked) {
                        const copiedLocation = structuredClone(location);
                        copiedLocation.id = 0;
                        locationFormGroup.patchValue(copiedLocation);
                    } else {
                        locationFormGroup.reset();
                    }
                })
            ),
        { dispatch: false }
    );

    createMission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionActions.createMissionRequest),
            exhaustMap(() => {
                const mission = this.missionFormService.getEntity();
                return this.missionService.missionPost(mission).pipe(
                    map((id) =>
                        MissionActions.createMissionRequestSuccess({ id })
                    ),
                    catchError((httpResponse) =>
                        of(
                            MissionActions.createMissionRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    openUpdateMissionDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionActions.editMission),
            concatLatestFrom(() => [
                this.store.select(MissionSelectors.selectMission),
                this.store.select(UserSelectors.selectUserId),
            ]),
            tap(([, mission, userId]) => {
                this.missionFormService.updateFormFromUser(mission, userId);
            }),
            map(() =>
                MissionActions.openDialog({
                    input: { isEdit: true, isFromEntityPage: true },
                })
            )
        )
    );

    updateMission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionActions.updateMissionRequest),
            exhaustMap(() => {
                const missionUnregisteredViewModel =
                    this.missionFormService.getEntity();
                return this.missionService
                    .missionPut(missionUnregisteredViewModel)
                    .pipe(
                        map(() => MissionActions.updateMissionRequestSuccess()),
                        catchError((httpResponse) =>
                            of(
                                MissionActions.updateMissionRequestFail({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    reloadOnUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionActions.updateMissionRequestSuccess),
            concatLatestFrom(() => [
                this.store.select(MissionSelectors.selectMission),
            ]),
            tap(() => {
                this.createMissionDialogService.close();
            }),
            map(([, mission]) =>
                MissionActions.loadMissionRequest({ id: mission.id })
            )
        )
    );

    uploadCover$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionActions.createMissionRequestSuccess),
            concatLatestFrom(() =>
                this.store.select(MissionSelectors.selectCoverFile)
            ),
            filter(([, coverFile]) => !isNil(coverFile)),
            exhaustMap(([{ id }, file]) => {
                return this.missionService.missionIdCoverPut({ id, file }).pipe(
                    map(() => MissionActions.updateCoverRequestSuccess()),
                    catchError((httpResponse) =>
                        of(
                            MissionActions.updateCoverRequestFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    createMissionSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionActions.createMissionRequestSuccess),
            tap(() => {
                this.createMissionDialogService.close();
            }),
            map(() => {
                return AlertActions.displaySuccess({ key: 'mission-create' });
            })
        )
    );

    createMissionFail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                MissionActions.createMissionRequestFail,
                MissionActions.updateMissionRequestFail,
                MissionActions.copyMissionRequestFail
            ),
            map(() => {
                return AlertActions.displayGenericError();
            })
        )
    );

    loadMission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionActions.loadMissionRequest),
            exhaustMap(({ id }) => {
                return this.missionService.missionIdGet({ id }).pipe(
                    map((response) =>
                        MissionActions.loadMissionRequestSuccess({
                            mission: response,
                        })
                    ),
                    catchError((httpResponse) =>
                        of(
                            MissionActions.createMissionRequestFail({
                                error: httpResponse.error,
                            })
                        )
                    )
                );
            })
        )
    );

    showProfileNeedsCompletionBeforeRegistrationAlert$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionActions.registerMission),
            concatLatestFrom(() => [
                this.store.select(UserSelectors.selectIsManagerEmailEmpty),
                this.store.select(UserSelectors.selectIsJobTitleEmpty),
                this.store.select(UserSelectors.selectHasSignature),
            ]),
            filter(
                ([, isManagerEmailEmpty, isJobTitleEmpty, hasSignature]) =>
                    isManagerEmailEmpty || isJobTitleEmpty || !hasSignature
            ),
            switchMap(
                ([, isManagerEmailEmpty, isJobTitleEmpty, hasSignature]) => {
                    const actions: Action[] = [];
                    if (isManagerEmailEmpty) {
                        actions.push(
                            AlertActions.displayInfo({
                                key: 'manager-email-needed',
                                action: HighlightActions.createHighlightEvent({
                                    event: {
                                        highlightedElement:
                                            'account-manager-email',
                                        targetUrl: UrlHelpers.getAccountUrl(
                                            EAccountUrl.AccountSettings
                                        ),
                                    },
                                }),
                            })
                        );
                    }
                    if (isJobTitleEmpty) {
                        actions.push(
                            AlertActions.displayInfo({
                                key: 'job-title-needed',
                                action: HighlightActions.createHighlightEvent({
                                    event: {
                                        highlightedElement: 'account-job-title',
                                        targetUrl: UrlHelpers.getAccountUrl(
                                            EAccountUrl.AccountSettings
                                        ),
                                    },
                                }),
                            })
                        );
                    }

                    if (!hasSignature) {
                        actions.push(
                            AlertActions.displayInfo({
                                key: 'signature-needed',
                                action: HighlightActions.createHighlightEvent({
                                    event: {
                                        highlightedElement: 'account-signature',
                                        targetUrl: UrlHelpers.getAccountUrl(
                                            EAccountUrl.AccountSettings
                                        ),
                                    },
                                }),
                            })
                        );
                    }

                    return actions;
                }
            )
        )
    );

    openRegisterMissionDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MissionActions.registerMission),
                concatLatestFrom(() => [
                    this.store.select(MissionSelectors.selectMission),
                    this.store.select(UserSelectors.selectIsManagerEmailEmpty),
                    this.store.select(UserSelectors.selectIsJobTitleEmpty),
                    this.store.select(UserSelectors.selectHasSignature),
                ]),
                filter(
                    ([
                        ,
                        ,
                        isManagerEmailEmpty,
                        isJobTitleEmpty,
                        hasSignature,
                    ]) =>
                        !isManagerEmailEmpty && !isJobTitleEmpty && hasSignature
                ),
                tap(([, mission]) =>
                    this.missionRegisterDialogService.open(mission)
                )
            ),
        { dispatch: false }
    );

    copyMission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionActions.copyMissionRequest),
            exhaustMap(({ id }) => {
                return this.missionService.missionIdCopyPost({ id }).pipe(
                    map(() => MissionActions.copyMissionRequestSuccess()),
                    catchError((httpResponse) =>
                        of(
                            MissionActions.copyMissionRequestFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    copyMissionSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionActions.copyMissionRequestSuccess),
            map(() => {
                return AlertActions.displaySuccess({ key: 'mission-copy' });
            })
        )
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private missionFormService: MissionFormService,
        private missionService: MissionService,
        private createMissionDialogService: CreateMissionDialogService,
        private missionRegisterDialogService: MissionRegisterDialogService
    ) {}
}
