/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AssociationUserViewModelPaginatedListDto } from '../model/associationUserViewModelPaginatedListDto';
// @ts-ignore
import { ELoginType } from '../model/eLoginType';
// @ts-ignore
import { RewardViewModel } from '../model/rewardViewModel';
// @ts-ignore
import { UserPasswordChangeViewModel } from '../model/userPasswordChangeViewModel';
// @ts-ignore
import { UserViewModel } from '../model/userViewModel';
// @ts-ignore
import { UserViewModelPaginatedListDto } from '../model/userViewModelPaginatedListDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface UserAssociationGetRequestParams {
    nameContains?: string;
    emailContains?: string;
    companyId?: number;
    associationId?: number;
    managerEmail?: string;
    interactedWithId?: number;
    loginType?: ELoginType;
    excludeIds?: Array<number>;
    /** Gets the n-th page of results */
    pageIndex?: number;
    /** Override default pige size. Cannot be superior to default */
    pageSize?: number;
    /** Filter results with id contained in this array */
    ids?: Array<number>;
    /** Returns only minimal data if set to true */
    minimalIncludes?: boolean;
    /** Extra properties to return */
    extraIncludes?: Array<string>;
    /** Returns archived data is set to true */
    includeArchived?: boolean;
}

export interface UserAvatarPutRequestParams {
    file?: Blob;
}

export interface UserChangePasswordPutRequestParams {
    userPasswordChangeViewModel?: UserPasswordChangeViewModel;
}

export interface UserCompanyGetRequestParams {
    nameContains?: string;
    emailContains?: string;
    companyId?: number;
    associationId?: number;
    managerEmail?: string;
    interactedWithId?: number;
    loginType?: ELoginType;
    excludeIds?: Array<number>;
    /** Gets the n-th page of results */
    pageIndex?: number;
    /** Override default pige size. Cannot be superior to default */
    pageSize?: number;
    /** Filter results with id contained in this array */
    ids?: Array<number>;
    /** Returns only minimal data if set to true */
    minimalIncludes?: boolean;
    /** Extra properties to return */
    extraIncludes?: Array<string>;
    /** Returns archived data is set to true */
    includeArchived?: boolean;
}

export interface UserDeleteRequestParams {
    id?: number;
}

export interface UserGetRequestParams {
    nameContains?: string;
    emailContains?: string;
    companyId?: number;
    associationId?: number;
    managerEmail?: string;
    interactedWithId?: number;
    loginType?: ELoginType;
    excludeIds?: Array<number>;
    /** Gets the n-th page of results */
    pageIndex?: number;
    /** Override default pige size. Cannot be superior to default */
    pageSize?: number;
    /** Filter results with id contained in this array */
    ids?: Array<number>;
    /** Returns only minimal data if set to true */
    minimalIncludes?: boolean;
    /** Extra properties to return */
    extraIncludes?: Array<string>;
    /** Returns archived data is set to true */
    includeArchived?: boolean;
}

export interface UserIdGetRequestParams {
    id: number;
}

export interface UserInteractedWithGetRequestParams {
    nameContains?: string;
    emailContains?: string;
    companyId?: number;
    associationId?: number;
    managerEmail?: string;
    interactedWithId?: number;
    loginType?: ELoginType;
    excludeIds?: Array<number>;
    /** Gets the n-th page of results */
    pageIndex?: number;
    /** Override default pige size. Cannot be superior to default */
    pageSize?: number;
    /** Filter results with id contained in this array */
    ids?: Array<number>;
    /** Returns only minimal data if set to true */
    minimalIncludes?: boolean;
    /** Extra properties to return */
    extraIncludes?: Array<string>;
    /** Returns archived data is set to true */
    includeArchived?: boolean;
}

export interface UserManagedGetRequestParams {
    nameContains?: string;
    emailContains?: string;
    companyId?: number;
    associationId?: number;
    managerEmail?: string;
    interactedWithId?: number;
    loginType?: ELoginType;
    excludeIds?: Array<number>;
    /** Gets the n-th page of results */
    pageIndex?: number;
    /** Override default pige size. Cannot be superior to default */
    pageSize?: number;
    /** Filter results with id contained in this array */
    ids?: Array<number>;
    /** Returns only minimal data if set to true */
    minimalIncludes?: boolean;
    /** Extra properties to return */
    extraIncludes?: Array<string>;
    /** Returns archived data is set to true */
    includeArchived?: boolean;
}

export interface UserPostRequestParams {
    userViewModel?: UserViewModel;
}

export interface UserPutRequestParams {
    userViewModel?: UserViewModel;
}


@Injectable({
  providedIn: 'root'
})
export class UserService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * [AssoAdmin] Get users from association
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userAssociationGet(requestParameters: UserAssociationGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AssociationUserViewModelPaginatedListDto>;
    public userAssociationGet(requestParameters: UserAssociationGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AssociationUserViewModelPaginatedListDto>>;
    public userAssociationGet(requestParameters: UserAssociationGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AssociationUserViewModelPaginatedListDto>>;
    public userAssociationGet(requestParameters: UserAssociationGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const nameContains = requestParameters.nameContains;
        const emailContains = requestParameters.emailContains;
        const companyId = requestParameters.companyId;
        const associationId = requestParameters.associationId;
        const managerEmail = requestParameters.managerEmail;
        const interactedWithId = requestParameters.interactedWithId;
        const loginType = requestParameters.loginType;
        const excludeIds = requestParameters.excludeIds;
        const pageIndex = requestParameters.pageIndex;
        const pageSize = requestParameters.pageSize;
        const ids = requestParameters.ids;
        const minimalIncludes = requestParameters.minimalIncludes;
        const extraIncludes = requestParameters.extraIncludes;
        const includeArchived = requestParameters.includeArchived;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (emailContains !== undefined && emailContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>emailContains, 'EmailContains');
        }
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (associationId !== undefined && associationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>associationId, 'AssociationId');
        }
        if (managerEmail !== undefined && managerEmail !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>managerEmail, 'ManagerEmail');
        }
        if (interactedWithId !== undefined && interactedWithId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>interactedWithId, 'InteractedWithId');
        }
        if (loginType !== undefined && loginType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>loginType, 'LoginType');
        }
        if (excludeIds) {
            excludeIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'ExcludeIds');
            })
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'PageSize');
        }
        if (ids) {
            ids.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Ids');
            })
        }
        if (minimalIncludes !== undefined && minimalIncludes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minimalIncludes, 'MinimalIncludes');
        }
        if (extraIncludes) {
            extraIncludes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'ExtraIncludes');
            })
        }
        if (includeArchived !== undefined && includeArchived !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeArchived, 'IncludeArchived');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/association`;
        return this.httpClient.request<AssociationUserViewModelPaginatedListDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [AllUsers] Update association cover
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userAvatarPut(requestParameters: UserAvatarPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<string>;
    public userAvatarPut(requestParameters: UserAvatarPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<string>>;
    public userAvatarPut(requestParameters: UserAvatarPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<string>>;
    public userAvatarPut(requestParameters: UserAvatarPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const file = requestParameters.file;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/avatar`;
        return this.httpClient.request<string>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [AllUsers] Update user password
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userChangePasswordPut(requestParameters: UserChangePasswordPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public userChangePasswordPut(requestParameters: UserChangePasswordPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public userChangePasswordPut(requestParameters: UserChangePasswordPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public userChangePasswordPut(requestParameters: UserChangePasswordPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const userPasswordChangeViewModel = requestParameters.userPasswordChangeViewModel;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/changePassword`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: userPasswordChangeViewModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [Regular, CompanyAdmin] Get users from same company than user
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userCompanyGet(requestParameters: UserCompanyGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserViewModelPaginatedListDto>;
    public userCompanyGet(requestParameters: UserCompanyGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserViewModelPaginatedListDto>>;
    public userCompanyGet(requestParameters: UserCompanyGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserViewModelPaginatedListDto>>;
    public userCompanyGet(requestParameters: UserCompanyGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const nameContains = requestParameters.nameContains;
        const emailContains = requestParameters.emailContains;
        const companyId = requestParameters.companyId;
        const associationId = requestParameters.associationId;
        const managerEmail = requestParameters.managerEmail;
        const interactedWithId = requestParameters.interactedWithId;
        const loginType = requestParameters.loginType;
        const excludeIds = requestParameters.excludeIds;
        const pageIndex = requestParameters.pageIndex;
        const pageSize = requestParameters.pageSize;
        const ids = requestParameters.ids;
        const minimalIncludes = requestParameters.minimalIncludes;
        const extraIncludes = requestParameters.extraIncludes;
        const includeArchived = requestParameters.includeArchived;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (emailContains !== undefined && emailContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>emailContains, 'EmailContains');
        }
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (associationId !== undefined && associationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>associationId, 'AssociationId');
        }
        if (managerEmail !== undefined && managerEmail !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>managerEmail, 'ManagerEmail');
        }
        if (interactedWithId !== undefined && interactedWithId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>interactedWithId, 'InteractedWithId');
        }
        if (loginType !== undefined && loginType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>loginType, 'LoginType');
        }
        if (excludeIds) {
            excludeIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'ExcludeIds');
            })
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'PageSize');
        }
        if (ids) {
            ids.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Ids');
            })
        }
        if (minimalIncludes !== undefined && minimalIncludes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minimalIncludes, 'MinimalIncludes');
        }
        if (extraIncludes) {
            extraIncludes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'ExtraIncludes');
            })
        }
        if (includeArchived !== undefined && includeArchived !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeArchived, 'IncludeArchived');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/company`;
        return this.httpClient.request<UserViewModelPaginatedListDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [SuperAdmin] Delete an user
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userDelete(requestParameters: UserDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public userDelete(requestParameters: UserDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public userDelete(requestParameters: UserDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public userDelete(requestParameters: UserDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [SuperAdmin] Get all users
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userGet(requestParameters: UserGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserViewModelPaginatedListDto>;
    public userGet(requestParameters: UserGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserViewModelPaginatedListDto>>;
    public userGet(requestParameters: UserGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserViewModelPaginatedListDto>>;
    public userGet(requestParameters: UserGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const nameContains = requestParameters.nameContains;
        const emailContains = requestParameters.emailContains;
        const companyId = requestParameters.companyId;
        const associationId = requestParameters.associationId;
        const managerEmail = requestParameters.managerEmail;
        const interactedWithId = requestParameters.interactedWithId;
        const loginType = requestParameters.loginType;
        const excludeIds = requestParameters.excludeIds;
        const pageIndex = requestParameters.pageIndex;
        const pageSize = requestParameters.pageSize;
        const ids = requestParameters.ids;
        const minimalIncludes = requestParameters.minimalIncludes;
        const extraIncludes = requestParameters.extraIncludes;
        const includeArchived = requestParameters.includeArchived;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (emailContains !== undefined && emailContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>emailContains, 'EmailContains');
        }
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (associationId !== undefined && associationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>associationId, 'AssociationId');
        }
        if (managerEmail !== undefined && managerEmail !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>managerEmail, 'ManagerEmail');
        }
        if (interactedWithId !== undefined && interactedWithId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>interactedWithId, 'InteractedWithId');
        }
        if (loginType !== undefined && loginType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>loginType, 'LoginType');
        }
        if (excludeIds) {
            excludeIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'ExcludeIds');
            })
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'PageSize');
        }
        if (ids) {
            ids.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Ids');
            })
        }
        if (minimalIncludes !== undefined && minimalIncludes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minimalIncludes, 'MinimalIncludes');
        }
        if (extraIncludes) {
            extraIncludes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'ExtraIncludes');
            })
        }
        if (includeArchived !== undefined && includeArchived !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeArchived, 'IncludeArchived');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user`;
        return this.httpClient.request<UserViewModelPaginatedListDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [SuperAdmin] Get user from its id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userIdGet(requestParameters: UserIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserViewModel>;
    public userIdGet(requestParameters: UserIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserViewModel>>;
    public userIdGet(requestParameters: UserIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserViewModel>>;
    public userIdGet(requestParameters: UserIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling userIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
        return this.httpClient.request<UserViewModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [External] Get users from association
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userInteractedWithGet(requestParameters: UserInteractedWithGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserViewModelPaginatedListDto>;
    public userInteractedWithGet(requestParameters: UserInteractedWithGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserViewModelPaginatedListDto>>;
    public userInteractedWithGet(requestParameters: UserInteractedWithGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserViewModelPaginatedListDto>>;
    public userInteractedWithGet(requestParameters: UserInteractedWithGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const nameContains = requestParameters.nameContains;
        const emailContains = requestParameters.emailContains;
        const companyId = requestParameters.companyId;
        const associationId = requestParameters.associationId;
        const managerEmail = requestParameters.managerEmail;
        const interactedWithId = requestParameters.interactedWithId;
        const loginType = requestParameters.loginType;
        const excludeIds = requestParameters.excludeIds;
        const pageIndex = requestParameters.pageIndex;
        const pageSize = requestParameters.pageSize;
        const ids = requestParameters.ids;
        const minimalIncludes = requestParameters.minimalIncludes;
        const extraIncludes = requestParameters.extraIncludes;
        const includeArchived = requestParameters.includeArchived;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (emailContains !== undefined && emailContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>emailContains, 'EmailContains');
        }
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (associationId !== undefined && associationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>associationId, 'AssociationId');
        }
        if (managerEmail !== undefined && managerEmail !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>managerEmail, 'ManagerEmail');
        }
        if (interactedWithId !== undefined && interactedWithId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>interactedWithId, 'InteractedWithId');
        }
        if (loginType !== undefined && loginType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>loginType, 'LoginType');
        }
        if (excludeIds) {
            excludeIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'ExcludeIds');
            })
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'PageSize');
        }
        if (ids) {
            ids.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Ids');
            })
        }
        if (minimalIncludes !== undefined && minimalIncludes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minimalIncludes, 'MinimalIncludes');
        }
        if (extraIncludes) {
            extraIncludes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'ExtraIncludes');
            })
        }
        if (includeArchived !== undefined && includeArchived !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeArchived, 'IncludeArchived');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/interacted-with`;
        return this.httpClient.request<UserViewModelPaginatedListDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [Manager] Get users managed by user
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userManagedGet(requestParameters: UserManagedGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserViewModelPaginatedListDto>;
    public userManagedGet(requestParameters: UserManagedGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserViewModelPaginatedListDto>>;
    public userManagedGet(requestParameters: UserManagedGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserViewModelPaginatedListDto>>;
    public userManagedGet(requestParameters: UserManagedGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const nameContains = requestParameters.nameContains;
        const emailContains = requestParameters.emailContains;
        const companyId = requestParameters.companyId;
        const associationId = requestParameters.associationId;
        const managerEmail = requestParameters.managerEmail;
        const interactedWithId = requestParameters.interactedWithId;
        const loginType = requestParameters.loginType;
        const excludeIds = requestParameters.excludeIds;
        const pageIndex = requestParameters.pageIndex;
        const pageSize = requestParameters.pageSize;
        const ids = requestParameters.ids;
        const minimalIncludes = requestParameters.minimalIncludes;
        const extraIncludes = requestParameters.extraIncludes;
        const includeArchived = requestParameters.includeArchived;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (nameContains !== undefined && nameContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>nameContains, 'NameContains');
        }
        if (emailContains !== undefined && emailContains !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>emailContains, 'EmailContains');
        }
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'CompanyId');
        }
        if (associationId !== undefined && associationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>associationId, 'AssociationId');
        }
        if (managerEmail !== undefined && managerEmail !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>managerEmail, 'ManagerEmail');
        }
        if (interactedWithId !== undefined && interactedWithId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>interactedWithId, 'InteractedWithId');
        }
        if (loginType !== undefined && loginType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>loginType, 'LoginType');
        }
        if (excludeIds) {
            excludeIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'ExcludeIds');
            })
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'PageIndex');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'PageSize');
        }
        if (ids) {
            ids.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'Ids');
            })
        }
        if (minimalIncludes !== undefined && minimalIncludes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minimalIncludes, 'MinimalIncludes');
        }
        if (extraIncludes) {
            extraIncludes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'ExtraIncludes');
            })
        }
        if (includeArchived !== undefined && includeArchived !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>includeArchived, 'IncludeArchived');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/managed`;
        return this.httpClient.request<UserViewModelPaginatedListDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [AllUsers] Get user own base information
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userMeGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserViewModel>;
    public userMeGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserViewModel>>;
    public userMeGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserViewModel>>;
    public userMeGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/me`;
        return this.httpClient.request<UserViewModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [SuperAdmin] Create an user
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userPost(requestParameters: UserPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public userPost(requestParameters: UserPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public userPost(requestParameters: UserPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public userPost(requestParameters: UserPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const userViewModel = requestParameters.userViewModel;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: userViewModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [AllUsers] Update an user
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userPut(requestParameters: UserPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<RewardViewModel>>;
    public userPut(requestParameters: UserPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<RewardViewModel>>>;
    public userPut(requestParameters: UserPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<RewardViewModel>>>;
    public userPut(requestParameters: UserPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const userViewModel = requestParameters.userViewModel;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user`;
        return this.httpClient.request<Array<RewardViewModel>>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: userViewModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [AllUsers] Get user signature transient url
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userSignatureGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<string>;
    public userSignatureGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<string>>;
    public userSignatureGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<string>>;
    public userSignatureGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/signature`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
