import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AvatarAssetViewModel } from 'app/api/model/avatarAssetViewModel';
import { BasileConfigurationViewModel } from 'app/api/model/basileConfigurationViewModel';
import { lightenColor } from 'app/shared/utils/colors.utils';
import { getUserLevelInformation } from 'app/shared/utils/experience-points.utils';
import { getAvatarAssetPath } from 'app/shared/utils/file-helpers.utils';

export type AssetPosition = {
    top: number;
    left: number;
    width: number;
    zindex?: number;
};

@Component({
    selector: 'my-basile',
    templateUrl: './my-basile.component.html',
    standalone: true,
    imports: [MatIconModule, NgIf],
})
export class MyBasileComponent {
    @Input() configuration: BasileConfigurationViewModel;
    @Input() overrideLevel: number;

    assetsPositions: { [key: string]: AssetPosition } = {
        face: {
            top: 0,
            left: 0,
            width: 100,
            zindex: 1,
        },
        hat: {
            top: 0,
            left: 0,
            width: 100,
            zindex: 2,
        },
        glasses: {
            top: 0,
            left: 0,
            width: 100,
            zindex: 6,
        },
        neck: {
            top: 0,
            left: 0,
            width: 100,
            zindex: 2,
        },
        top: {
            top: 0,
            left: 0,
            width: 100,
            zindex: 1,
        },
        bottom: {
            top: 0,
            left: 0,
            width: 100,
            zindex: 2,
        },
        shoes: {
            top: 0,
            left: 0,
            width: 100,
            zindex: 1,
        },
        beard: {
            top: 0,
            left: 0,
            width: 100,
            zindex: 3,
        },
        mustache: {
            top: 0,
            left: 0,
            width: 100,
            zindex: 5,
        },
        cape: {
            top: 0,
            left: 0,
            width: 100,
            zindex: 0,
        },
    };

    get userLevel() {
        return (
            this.overrideLevel ??
            getUserLevelInformation(this.configuration.experiencePoints)
                .currentLevel
        );
    }

    getAssetPosition(asset: string) {
        return (
            'z-index: ' +
            this.assetsPositions[asset].zindex +
            '; ' +
            'top:' +
            this.assetsPositions[asset].top +
            '%; ' +
            'left:' +
            this.assetsPositions[asset].left +
            '%; ' +
            'width:' +
            this.assetsPositions[asset].width +
            '%'
        );
    }

    getAssetPath(asset: AvatarAssetViewModel) {
        if (!asset) {
            return null;
        }
        return getAvatarAssetPath(asset);
    }

    get levelColor() {
        if (this.userLevel > 100) {
            return '#99dc78';
        }
        // Rotate color every 5 levels
        const colorGroupIndex = Math.floor((this.userLevel - 1) / 5) % 20;
        const colorGroups = [
            '#00FFFF', // Cyan
            '#87CEEB', // Light Blue
            '#1E90FF', // Dodger Blue
            '#4682B4', // Steel Blue
            '#9370DB', // Medium Purple
            '#FF00FF', // Magenta
            '#FF69B4', // Hot Pink
            '#FFB6C1', // Light Pink
            '#CD5C5C', // Indian Red
            '#FF6347', // Tomato
            '#FF8C00', // Dark Orange
            '#FFD700', // Gold
            '#FFFF00', // Yellow
            '#FFA07A', // Light Salmon
            '#F08080', // Light Coral
            '#DA70D6', // Orchid
            '#BA55D3', // Medium Orchid
            '#EE82EE', // Violet
            '#DDA0DD', // Plum
            '#99dc78', // Final Color
        ];

        const baseColor = colorGroups[colorGroupIndex];

        const opacity = [60, 45, 30, 15, 0][(this.userLevel - 1) % 5];
        const adjustedColor = lightenColor(baseColor, opacity);
        return adjustedColor;
    }
}
