import { createReducer, on } from '@ngrx/store';
import { ImportSheetActions } from './import.action';
import { ImportSheetState } from './import.state';

export const importSheetInitialState: ImportSheetState = {
    isLoading: false,
};

export const importSheetReducer = createReducer(
    importSheetInitialState,

    on(ImportSheetActions.importEmailsRequest, (state) => ({
        ...state,
        isLoading: true,
        error: undefined,
    })),

    on(ImportSheetActions.importEmailsRequestSuccess, (state, { emails }) => ({
        ...state,
        isLoading: false,
        emails,
        error: undefined,
    })),

    on(ImportSheetActions.importEmailsRequestFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
    })),

    on(ImportSheetActions.resetEmails, (state) => ({
        ...state,
        emails: undefined,
        error: undefined,
    })),

    on(ImportSheetActions.importCompanyEmployeesRequest, (state) => ({
        ...state,
        isLoading: true,
        error: undefined,
    })),

    on(
        ImportSheetActions.importCompanyEmployeesRequestSuccess,
        (state, { employees }) => ({
            ...state,
            isLoading: false,
            employees,
            error: undefined,
        })
    ),

    on(
        ImportSheetActions.importCompanyEmployeesRequestFailure,
        (state, { error }) => ({
            ...state,
            isLoading: false,
            error,
        })
    ),

    on(ImportSheetActions.resetCompanyEmployees, (state) => ({
        ...state,
        employees: undefined,
        error: undefined,
    }))
);
