export type BslkSize = 'small' | 'medium' | 'large';

export type BslkColor =
    | 'primary'
    | 'primary-light'
    | 'warn'
    | 'warn-light'
    | 'tertiary'
    | 'tertiary-light'
    | 'quaternary'
    | 'quaternary-light'
    | 'blue'
    | 'blue-light'
    | 'gray'
    | 'gray-light'
    | 'error'
    | 'error-light'
    | 'white'
    | 'black';

export type BslkJustify = 'center' | 'between' | 'start';

export type BslkSizeExtended = BslkSize | ('very-small' | 'very-large');

export const BslkHexColors = {
    primary: '#EE846F',
    quaternary: '#78CF95',
    warn: '#F29727',
    tertiary: '#8490c8',
};
