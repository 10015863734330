/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EMissionState = 'Archived' | 'Upcoming' | 'InProgress' | 'WaitingForManagerAcceptance' | 'WaitingForAssociationAcceptance' | 'WaitingForUserRating' | 'WaitingForUserValidation' | 'WaitingForAssociationValidation' | 'Done';

export const EMissionState = {
    Archived: 'Archived' as EMissionState,
    Upcoming: 'Upcoming' as EMissionState,
    InProgress: 'InProgress' as EMissionState,
    WaitingForManagerAcceptance: 'WaitingForManagerAcceptance' as EMissionState,
    WaitingForAssociationAcceptance: 'WaitingForAssociationAcceptance' as EMissionState,
    WaitingForUserRating: 'WaitingForUserRating' as EMissionState,
    WaitingForUserValidation: 'WaitingForUserValidation' as EMissionState,
    WaitingForAssociationValidation: 'WaitingForAssociationValidation' as EMissionState,
    Done: 'Done' as EMissionState
};

