<bslk-classic-card
    *ngrxLet="hasPostedRating$ as hasPostedRating"
    [disabled]="!(canPostRating$ | async) && !hasPostedRating"
    [disabledText]="'missionmanage.rating.disabled-text' | transloco"
    [title]="'missionmanage.rating.title' | transloco"
>
    <div
        *ngIf="!hasPostedRating; else alreadyPostedRating"
        class="flex flex-col gap-5"
    >
        <fuse-alert
            class="flex"
            [appearance]="'soft'"
            [dismissible]="true"
            [showIcon]="true"
            [type]="'info'"
        >
            {{ "missionmanage.rating.info" | transloco }}
        </fuse-alert>

        <bslk-star-rating
            [control]="formGroup.controls.experienceRating"
            [label]="'missionmanage.rating.experience-rating-label' | transloco"
            [minLabel]="
                'missionmanage.rating.experience-rating-min-label' | transloco
            "
            [maxLabel]="
                'missionmanage.rating.experience-rating-max-label' | transloco
            "
        ></bslk-star-rating>

        <bslk-star-rating
            [control]="formGroup.controls.purposeRating"
            [label]="'missionmanage.rating.purpose-rating-label' | transloco"
            [minLabel]="
                'missionmanage.rating.purpose-rating-min-label' | transloco
            "
            [maxLabel]="
                'missionmanage.rating.purpose-rating-max-label' | transloco
            "
        ></bslk-star-rating>

        <div>
            <div class="font-bold mb-1">
                {{
                    "missionmanage.rating.feelings-perceived-label" | transloco
                }}
            </div>
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <input
                    [formControl]="formGroup.controls.feelingsFelt"
                    matInput
                />
                <mat-error> {{ "bslk.form.required" | transloco }} </mat-error>
            </mat-form-field>
        </div>

        <div>
            <div class="font-bold mb-1">
                {{
                    "missionmanage.rating.learning-new-about-self-label"
                        | transloco
                }}
            </div>
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <input
                    [formControl]="formGroup.controls.newSelfAndOthersLearning"
                    matInput
                />
                <mat-error> {{ "bslk.form.required" | transloco }} </mat-error>
            </mat-form-field>
        </div>

        <bslk-radio-group-binary
            [label]="
                'missionmanage.rating.company-initiatives-important-label'
                    | transloco
            "
            [control]="formGroup.controls.isCompanyInitiativesImportant"
        ></bslk-radio-group-binary>

        <div>
            <div class="font-bold mb-1">
                {{ "missionmanage.rating.why-label" | transloco }}
            </div>
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <input
                    [formControl]="
                        formGroup.controls.isCompanyInitiativesImportantComment
                    "
                    matInput
                />
                <mat-error> {{ "bslk.form.required" | transloco }} </mat-error>
            </mat-form-field>
        </div>

        <bslk-radio-group-binary
            [label]="
                'missionmanage.rating.improving-company-image-label' | transloco
            "
            [control]="formGroup.controls.isActionImprovingCompanyImage"
        ></bslk-radio-group-binary>

        <bslk-radio-group-binary
            [label]="
                'missionmanage.rating.contributing-to-personal-growth-label'
                    | transloco
            "
            [control]="
                formGroup.controls.isActionContributingToPersonalGrowthAtCompany
            "
        ></bslk-radio-group-binary>

        <bslk-advanced-select-sync
            class="w-full"
            *ngIf="translationsLoaded"
            [items]="activityFieldsAdvancedSelectItems"
            [multiple]="'multiple'"
            [group]="formGroup"
            [controlName]="'preferredActivityFields'"
            [label]="
                'missionmanage.rating.preferred-activity-fields-label'
                    | transloco
            "
            [errorMessage]="
                'missionmanage.rating.activity-fields-error-message' | transloco
            "
            [searchPlaceholder]="
                'missionmanage.rating.search-activity-placeholder' | transloco
            "
        ></bslk-advanced-select-sync>

        <div>
            <div class="font-bold mb-1">
                {{
                    "missionmanage.rating.other-causes-interest-label"
                        | transloco
                }}
            </div>
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <input
                    [formControl]="
                        formGroup.controls.otherPreferredActivityFields
                    "
                    matInput
                />
            </mat-form-field>
        </div>

        <div>
            <div class="font-bold mb-1">
                {{
                    "missionmanage.rating.desired-days-per-year-label"
                        | transloco
                }}
            </div>
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <input
                    type="number"
                    min="1"
                    max="365"
                    [formControl]="formGroup.controls.desiredDaysPerYear"
                    matInput
                />
                <span matSuffix>{{
                    "missionmanage.rating.days-per-year-suffix" | transloco
                }}</span>
                <mat-error> {{ "bslk.form.required" | transloco }} </mat-error>
            </mat-form-field>
        </div>

        <bslk-radio-group-binary
            [label]="'missionmanage.rating.recommend-mission-label' | transloco"
            [control]="formGroup.controls.isMissionRecommendable"
        ></bslk-radio-group-binary>

        <div>
            <div class="font-bold mb-1">
                {{ "missionmanage.rating.other-comments-label" | transloco }}
            </div>
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <textarea
                    [formControl]="formGroup.controls.otherComments"
                    matInput
                >
                </textarea>
                <mat-error>
                    {{ "bslk.form.required" | transloco }}
                </mat-error>
            </mat-form-field>
        </div>

        <fuse-alert
            class="flex"
            [appearance]="'soft'"
            [dismissible]="true"
            [showIcon]="true"
            [type]="'info'"
        >
            {{ "missionmanage.rating.association-rating-info" | transloco }}
        </fuse-alert>

        <bslk-star-rating
            [control]="formGroup.controls.associationRating"
            [label]="
                'missionmanage.rating.association-rating-label' | transloco
            "
            [minLabel]="
                'missionmanage.rating.association-rating-label-min-label'
                    | transloco
            "
            [maxLabel]="
                'missionmanage.rating.association-rating-label-max-label'
                    | transloco
            "
        ></bslk-star-rating>

        <div>
            <div class="font-bold mb-1">
                {{
                    "missionmanage.rating.association-comment-label" | transloco
                }}
            </div>
            <mat-form-field class="w-full" subscriptSizing="dynamic">
                <textarea
                    [formControl]="formGroup.controls.associationComment"
                    matInput
                >
                </textarea>
                <mat-error> {{ "bslk.form.required" | transloco }} </mat-error>
            </mat-form-field>
        </div>

        <div class="flex justify-center">
            <fuse-alert
                class="flex"
                *ngIf="formSubmitted && !formGroup.valid"
                [appearance]="'soft'"
                [showIcon]="true"
                [type]="'error'"
            >
                {{ "missionmanage.rating.form-error-alert" | transloco }}
            </fuse-alert>
        </div>

        <div class="flex mt-3 justify-end">
            <bslk-classic-button
                [label]="
                    'missionmanage.rating.send-feedback-button' | transloco
                "
                [isDisabled]="formSubmitted && !formGroup.valid"
                [isLoading]="isLoading$ | async"
                (onClick)="post()"
            ></bslk-classic-button>
        </div>
    </div>
    <ng-template #alreadyPostedRating>
        <fuse-alert
            class="flex"
            [appearance]="'soft'"
            [dismissed]="false"
            [showIcon]="true"
            [type]="'success'"
        >
            {{ "missionmanage.rating.already-posted-rating-alert" | transloco }}
        </fuse-alert>
    </ng-template>
</bslk-classic-card>
