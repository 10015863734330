<div
    (click)="toggleIsExpanded()"
    class="cursor-pointer flex w-full justify-between"
>
    <span class="font-bold">{{ title }}</span>
    <div>
        <mat-icon
            *ngIf="!isExpanded"
            class="font-bold icon-size-5 mr-1"
            [svgIcon]="'heroicons_solid:chevron-down'"
        >
        </mat-icon>
        <mat-icon
            *ngIf="isExpanded"
            class="font-bold icon-size-5 mr-1"
            [svgIcon]="'heroicons_solid:chevron-up'"
        >
        </mat-icon>
    </div>
</div>
<div *ngIf="isExpanded" class="flex flex-col w-full mt-2">
    <mat-form-field
        *ngIf="enableSearch"
        [subscriptSizing]="'dynamic'"
        class="w-full mb-2"
    >
        <input
            class=""
            [(ngModel)]="search"
            [placeholder]="filterPlaceholder"
            matInput
        />
        <button mat-icon-button matSuffix *ngIf="search" (click)="search = ''">
            <mat-icon
                class="font-bold icon-size-5 mr-1"
                [svgIcon]="'heroicons_solid:x-mark'"
            >
            </mat-icon>
        </button>
    </mat-form-field>
    <mat-checkbox
        (click)="checkboxClicked($event)"
        (change)="checkboxChange($event, item.value)"
        *ngFor="let item of shownItems"
        [value]="item.value"
        [checked]="selectedValues.includes(item.value)"
        color="primary"
    >
        <span class="font-semibold">{{ item.name }}</span>
    </mat-checkbox>

    <button
        class="mt-2"
        *ngIf="items.length > maximumShownItems && searchIsEmpty"
        mat-stroked-button
        (click)="loadMore()"
    >
        {{ "missions.filters.load-more" | transloco }}
    </button>

    <ng-content></ng-content>
</div>
