<div *ngIf="canSwitchRole$ | async" class="mx-6 flex items-center">
    <span class="mr-3 font-semibold truncate hidden md:inline-block">{{
        "shared.navigation.connected-as" | transloco
    }}</span>
    <bslk-classic-toggle
        class="hidden md:flex"
        [color]="'warn'"
        (valueChange)="switchRole()"
        [value]="userRole$ | async"
        [choices]="[
        {
          value: EUserRole.CompanyAdmin,
          text: 'enums.ERole.CompanyAdmin' | transloco,
        },
        {
          value: EUserRole.Regular,
          text: 'enums.ERole.Regular' | transloco,
        }
      ]"
    ></bslk-classic-toggle>
    <bslk-classic-toggle
        class="md:hidden"
        [color]="'warn'"
        (valueChange)="switchRole()"
        [value]="userRole$ | async"
        [size]="'very-small'"
        [choices]="[
        {
          value: EUserRole.CompanyAdmin,
          text: 'enums.ERole.CompanyAdmin' | transloco,
        },
        {
          value: EUserRole.Regular,
          text: 'enums.ERole.Regular' | transloco,
        }
      ]"
    ></bslk-classic-toggle>
</div>
