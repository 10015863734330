<div class="forgot-password mx-auto" *ngrxLet="isLoading$ as isLoading">
    <div
        class="h-full forgot-password__card w-full mx-auto sm:mx-0 flex flex-col justify-center"
    >
        <!-- Logo -->
        <div class="w-1/2 mx-auto">
            <img src="assets/images/logo/logo-text.svg" />
        </div>

        <div class="bg-white shadow-solid-primary rounded-2xl p-6 mt-8">
            <div
                class="mt-2 text-4xl font-extrabold tracking-tight text-center"
            >
                {{ "validation.reset-password" | transloco }}
            </div>

            <mat-stepper
                [linear]="false"
                [selectedIndex]="stepIndex$ | async"
                #stepper
            >
                <!-- Email step -->
                <mat-step [stepControl]="emailForm">
                    <div
                        class="flex justify-center items-baseline mt-4 font-medium"
                    >
                        {{ "validation.email-details" | transloco }}
                    </div>

                    <form
                        [formGroup]="emailForm"
                        (ngSubmit)="sendCode()"
                        class="mt-4"
                    >
                        <!-- Email field -->
                        <mat-form-field class="w-full">
                            <mat-label>{{
                                "validation.email" | transloco
                            }}</mat-label>
                            <input
                                autocomplete="email"
                                [placeholder]="
                                    'validation.email-placeholder' | transloco
                                "
                                matInput
                                [formControlName]="'email'"
                            />
                            <mat-error>
                                {{ "validation.email-required" | transloco }}
                            </mat-error>
                        </mat-form-field>

                        <div *ngrxLet="emailError$ as emailError">
                            <fuse-alert
                                class="mb-4"
                                *ngIf="emailError"
                                [appearance]="'outline'"
                                [showIcon]="true"
                                [type]="'error'"
                                [@shake]="true"
                            >
                                {{
                                    "validation.errors." + emailError
                                        | transloco
                                }}
                            </fuse-alert>
                        </div>

                        <ng-container
                            [ngTemplateOutlet]="continueButton"
                        ></ng-container>
                    </form>
                </mat-step>

                <!-- Code step -->
                <mat-step [stepControl]="codeForm">
                    <div
                        class="flex justify-center items-baseline mt-4 font-medium"
                    >
                        {{ "validation.code-details" | transloco }}
                    </div>

                    <form
                        [formGroup]="codeForm"
                        (ngSubmit)="validateCode()"
                        class="mt-4"
                    >
                        <mat-form-field floatLabel="always" class="w-full">
                            <mat-label>{{
                                "validation.code" | transloco
                            }}</mat-label>
                            <input
                                [placeholder]="
                                    'validation.code-placeholder' | transloco
                                "
                                matInput
                                [formControlName]="'code'"
                            />
                            <mat-error>
                                {{ "validation.code-required" | transloco }}
                            </mat-error>
                        </mat-form-field>

                        <div *ngrxLet="codeError$ as codeError">
                            <fuse-alert
                                class="mb-4"
                                *ngIf="codeError"
                                [appearance]="'outline'"
                                [showIcon]="true"
                                [type]="'error'"
                                [@shake]="true"
                            >
                                {{
                                    "validation.errors." + codeError | transloco
                                }}
                            </fuse-alert>
                        </div>

                        <ng-container
                            [ngTemplateOutlet]="continueButton"
                        ></ng-container>
                    </form>
                </mat-step>

                <!-- New password step -->
                <mat-step [stepControl]="newPasswordForm">
                    <form
                        [formGroup]="newPasswordForm"
                        (ngSubmit)="setPassword()"
                        class="mt-4"
                    >
                        <div
                            class="flex justify-center items-baseline my-4 font-medium"
                        >
                            {{ "validation.new-password-details" | transloco }}
                        </div>

                        <mat-form-field class="w-full">
                            <mat-label>{{
                                "validation.new-password" | transloco
                            }}</mat-label>
                            <input
                                [placeholder]="
                                    'validation.password-placeholder'
                                        | transloco
                                "
                                matInput
                                type="password"
                                autocomplete="new-password"
                                [formControlName]="'password'"
                                #passwordField
                            />
                            <button
                                mat-icon-button
                                type="button"
                                (click)="
                                    passwordField.type === 'password'
                                        ? (passwordField.type = 'text')
                                        : (passwordField.type = 'password')
                                "
                                matSuffix
                            >
                                <mat-icon
                                    class="icon-size-5"
                                    *ngIf="passwordField.type === 'password'"
                                    [svgIcon]="'heroicons_solid:eye'"
                                ></mat-icon>
                                <mat-icon
                                    class="icon-size-5"
                                    *ngIf="passwordField.type === 'text'"
                                    [svgIcon]="'heroicons_solid:eye-slash'"
                                ></mat-icon>
                            </button>
                            <mat-error>
                                {{ "validation.password-error" | transloco }}
                            </mat-error>
                        </mat-form-field>

                        <div *ngrxLet="passwordError$ as passwordError">
                            <fuse-alert
                                class="mb-4"
                                *ngIf="passwordError"
                                [appearance]="'outline'"
                                [showIcon]="true"
                                [type]="'error'"
                                [@shake]="true"
                            >
                                {{
                                    "validation.errors." + passwordError
                                        | transloco
                                }}
                            </fuse-alert>
                        </div>

                        <ng-container
                            [ngTemplateOutlet]="continueButton"
                        ></ng-container>
                    </form>
                </mat-step>
            </mat-stepper>
        </div>

        <div class="flex justify-center items-baseline mt-6 font-medium">
            {{ "validation.remember" | transloco }} &nbsp;
            <a class="font-bold" [routerLink]="'/sign-in'">{{
                "validation.sign-in" | transloco
            }}</a>
        </div>
        <div
            class="flex justify-center items-baseline mt-0.5 text-sm text-gray-500"
        >
            *{{ "validation.required-information" | transloco }}
        </div>
    </div>

    <ng-template #continueButton>
        <button
            class="fuse-mat-button-large w-full text-accent m-1 mt-6"
            mat-flat-button
            [disabled]="isLoading"
            [color]="'primary'"
            type="submit"
        >
            <div class="flex items-center">
                <mat-progress-spinner
                    *ngIf="isLoading"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                ></mat-progress-spinner>
                <span class="ml-4">
                    {{ "validation.continue" | transloco }}
                </span>
            </div>
        </button>
    </ng-template>
</div>
