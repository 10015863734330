/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ECourseSectionType = 'Text' | 'TrueFalseQuestion' | 'MultipleChoiceQuestion' | 'Video' | 'Anecdote' | 'Survey';

export const ECourseSectionType = {
    Text: 'Text' as ECourseSectionType,
    TrueFalseQuestion: 'TrueFalseQuestion' as ECourseSectionType,
    MultipleChoiceQuestion: 'MultipleChoiceQuestion' as ECourseSectionType,
    Video: 'Video' as ECourseSectionType,
    Anecdote: 'Anecdote' as ECourseSectionType,
    Survey: 'Survey' as ECourseSectionType
};

