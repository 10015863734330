<fuse-card
    [class]="
        'w-full h-full flex flex-col justify-between relative text-white rounded-2xl transition duration-300 px-8 py-6'
    "
    [ngClass]="{
        'bg-tertiary': backgroundColor === 'tertiary',
        'bg-primary': backgroundColor === 'primary',
        'bg-warn': backgroundColor === 'warn',
    }"
    [class.hover:scale-105]="!disabled"
    [class.bg-gray-400]="disabled"
    [class.opacity-70]="disabled"
    [class.cursor-pointer]="!disabled"
>
    <div *ngIf="backgroundIcon" class="absolute -mt-3 right-3 opacity-30">
        <mat-icon
            class="h-15 w-15 text-white"
            [svgIcon]="backgroundIcon"
            matPrefix
        >
        </mat-icon>
    </div>

    <div class="flex">
        <mat-icon
            *ngIf="!hideTitleIcon"
            class="icon-size-5 text-white mr-2"
            [svgIcon]="titleIcon"
            matPrefix
        >
        </mat-icon>
        <span class="text-lg font-bold">{{ title }}</span>
    </div>
    <div class="mt-2">
        {{ description }}
    </div>
    <div class="flex items-center mt-3 justify-between">
        <bslk-tag [background]="'warn'" class="mx-auto" *ngIf="tag">
            <mat-icon
                *ngIf="tagIcon"
                class="icon-size-55 text-white mr-2"
                [svgIcon]="tagIcon"
            >
            </mat-icon>
            {{ tag }}
        </bslk-tag>
        <div *ngIf="!disabled" class="mt-3 -mx-3 ml-auto">
            <button mat-icon-button>
                <mat-icon
                    class="icon-size-5 text-white"
                    [svgIcon]="'heroicons_outline:arrow-right'"
                >
                </mat-icon>
            </button>
        </div>
    </div>
</fuse-card>
