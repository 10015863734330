<div class="w-full" *ngrxLet="project$ as project">
    <cover-template
        *ngrxLet="isLoading$ as isLoading"
        [coverUrl]="project?.coverUrl"
        [logoUrl]="project?.association.logoUrl"
        [logoFirstLetter]="project?.association?.name[0]"
        [title]="project?.name"
        [subtitle]="project?.association?.name"
        [isLoading]="isLoading"
        [imagesUrl]="projectImagesUrl$ | async"
        [emitOnClose]="emitOnClose"
        [closeFixedPosition]="closeFixedPosition"
        (close)="onClose()"
    >
        <!-- Cover actions -->
        <div coverActions>
            <!-- Activity fields -->
            <enum-tag-list
                class="hidden md:flex"
                *ngIf="!isLoading"
                [tags]="project.activityFields"
                [enum]="EActivityField"
                [maxTagsVisible]="3"
            ></enum-tag-list>
        </div>

        <!-- Regular Header actions -->
        <div class="ml-auto flex gap-x-4" headerActions>
            <bslk-tag-button
                *ngIf="canChoose"
                (click)="chooseProject($event)"
                [background]="'primary-light'"
                [textColor]="'primary'"
                [icon]="'heroicons_outline:heart'"
                ><span class="font-bold">{{
                    "projectview.i-choose-this-project" | transloco
                }}</span></bslk-tag-button
            >

            <!-- Modify -->
            <bslk-classic-button
                *ngIf="isAssoAdminOrSuperAdmin$ | async"
                [label]="'shared.common.edit' | transloco"
                [icon]="'heroicons_outline:pencil'"
                (onClick)="editProject()"
            ></bslk-classic-button>

            <bslk-classic-button
                *ngIf="isAssoAdmin$ | async"
                [background]="'tertiary'"
                [label]="'shared.common.follow' | transloco"
                [icon]="'heroicons_outline:play'"
                [href]="'/a/projects/' + project.id + '/manage'"
            >
            </bslk-classic-button>
        </div>

        <!-- Left Column -->
        <div class="w-full flex flex-col gap-4" leftColumn>
            <donation-impact
                class="hidden lg:block"
                *ngIf="!isLoading; else skeleton50"
                [project]="project"
            ></donation-impact>

            <ng-container *ngIf="!isLoading; else skeleton50">
                <bslk-classic-card *ngIf="project.quote">
                    <figure class="max-w-screen-md mx-auto text-center">
                        <svg
                            class="w-10 h-10 mx-auto mb-3 text-warn"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 14"
                        >
                            <path
                                d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"
                            />
                        </svg>
                        <blockquote>
                            <p
                                class="text-2xl italic font-medium text-gray-900 dark:text-white"
                            >
                                "{{ project.quote }}"
                            </p>
                        </blockquote>
                        <figcaption
                            class="flex items-center justify-center mt-6 space-x-3 rtl:space-x-reverse"
                        >
                            <img
                                *ngIf="project.quoteAuthorImageUrl"
                                class="w-6 h-6 rounded-full"
                                [src]="project.quoteAuthorImageUrl"
                                alt="Quote author profile picture"
                            />
                            <div
                                class="flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-500 dark:divide-gray-700"
                            >
                                <cite
                                    class="pe-3 font-medium text-gray-900 dark:text-white"
                                    >{{ project.quoteAuthor }}</cite
                                >
                                <cite
                                    class="ps-3 text-sm text-gray-500 dark:text-gray-400"
                                    >{{ project.quoteAuthorTitle }}</cite
                                >
                            </div>
                        </figcaption>
                    </figure>
                </bslk-classic-card>
            </ng-container>

            <ng-container *ngIf="!isLoading; else skeleton50">
                <bslk-classic-card *ngIf="project.basilikComment">
                    <div class="truncate flex items-center gap-1" title>
                        <img
                            src="assets/images/logo/logo.svg"
                            class="w-9 h-9"
                        />
                        <span class="truncate">{{
                            "projectview.basilik-comment" | transloco
                        }}</span>
                    </div>
                    <span class="text-lg">{{ project.basilikComment }}</span>
                </bslk-classic-card>
            </ng-container>

            <bslk-classic-card
                *ngIf="!isLoading; else skeleton50"
                [title]="'projectview.location' | transloco"
            >
                <div
                    *ngIf="showGoogleMaps"
                    #googleMaps
                    class="w-full h-60 mb-4"
                ></div>
                <div class="flex flex-wrap gap-1">
                    <bslk-tag
                        *ngFor="let location of project.locations"
                        [size]="'small'"
                        [icon]="'heroicons_outline:map-pin'"
                        [background]="'warn'"
                    >
                        {{ location.name }}
                    </bslk-tag>
                </div>
            </bslk-classic-card>

            <bslk-classic-card
                *ngIf="!isLoading; else skeleton50"
                [title]="
                    'projectview.sustainable-development-goals' | transloco
                "
            >
                <div class="flex gap-1 flex-wrap">
                    <bslk-tag
                        *ngFor="let sdg of project.sustainableDevelopmentGoals"
                        [size]="'small'"
                        [icon]="sustainableDevlopmentGoals[sdg].icon"
                        [background]="null"
                        [backgroundHexaColor]="
                            sustainableDevlopmentGoals[sdg].color
                        "
                    >
                        {{
                            "enums.ESustainableDevelopmentGoal." + sdg
                                | transloco
                        }}
                    </bslk-tag>
                </div>
            </bslk-classic-card>
        </div>

        <!-- Main -->

        <div *ngIf="!isLoading" class="flex w-full flex-col gap-y-5">
            <donation-impact
                class="lg:hidden"
                *ngIf="!isLoading"
                [project]="project"
            ></donation-impact>

            <div class="grid grid-cols-3 gap-4 order-2 md:order-1">
                <bslk-business-metric
                    class="col-span-3 md:col-span-1"
                    [metric]="project.metrics[0]"
                ></bslk-business-metric>
                <bslk-business-metric
                    class="col-span-3 md:col-span-1"
                    [color]="'warn'"
                    [metric]="project.metrics[1]"
                ></bslk-business-metric>
                <bslk-business-metric
                    class="col-span-3 md:col-span-1"
                    [color]="'tertiary'"
                    [metric]="project.metrics[2]"
                ></bslk-business-metric>
            </div>

            <!-- Project Description -->
            <bslk-classic-card
                class="w-full order-1 md:order-2"
                *ngIf="!isLoading; else skeleton50"
                [title]="'projectview.description' | transloco"
            >
                <div class="flex flex-col gap-4">
                    <div class="flex flex-col gap-1">
                        <div class="font-bold flex items-center gap-2">
                            <mat-icon
                                [svgIcon]="'heroicons_outline:rocket-launch'"
                            ></mat-icon>
                            <span>{{
                                "projectview.challenge" | transloco
                            }}</span>
                        </div>
                        <div
                            class="flex flex-col gap-y-3 items-start px-2 md:px-6 overflow-x-scroll"
                        >
                            <div
                                [innerHTML]="sanitize(project.challenge)"
                            ></div>
                        </div>
                    </div>

                    <div class="flex flex-col gap-1">
                        <div class="font-bold flex items-center gap-2">
                            <mat-icon
                                [svgIcon]="'heroicons_outline:light-bulb'"
                            ></mat-icon>
                            <span>{{
                                "projectview.solution" | transloco
                            }}</span>
                        </div>
                        <div
                            class="flex flex-col gap-y-3 items-start px-2 md:px-6 overflow-x-scroll"
                        >
                            <div
                                [innerHTML]="
                                    sanitize(
                                        showSolutionDetails
                                            ? project.solutionDetails
                                            : project.solution
                                    )
                                "
                            ></div>
                        </div>

                        <span
                            *ngIf="project.solutionDetails"
                            (click)="showSolutionDetails = !showSolutionDetails"
                            class="self-end font-bold cursor-pointer"
                            >{{
                                "projectview.read-" +
                                    (showSolutionDetails ? "less" : "more")
                                    | transloco
                            }}</span
                        >
                    </div>
                </div>
            </bslk-classic-card>

            <fuse-card
                *ngIf="!isLoading && project.videoUrl"
                class="media-wrapper w-full order-3"
            >
                <iframe
                    [src]="videoUrl$ | async | safe"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                >
                </iframe>
            </fuse-card>

            <!-- Purpose of donations -->
            <bslk-classic-card
                class="w-full order-4"
                *ngIf="!isLoading; else skeleton50"
                [title]="'projectview.purpose-of-donations' | transloco"
                [titleIcon]="'heroicons_outline:banknotes'"
            >
                <div
                    class="flex flex-col sm:flex-row items-start px-2 md:px-6 overflow-x-scroll"
                >
                    <div
                        [innerHTML]="sanitize(project.purposeOfDonations)"
                    ></div>
                </div>
            </bslk-classic-card>

            <!-- Association description -->
            <ng-container *ngIf="!isLoading; else skeleton30">
                <bslk-classic-card
                    *ngIf="project.association.description"
                    [title]="
                        project.association.name +
                        ' ' +
                        ('projectview.few-words' | transloco)
                    "
                    class="flex w-full order-5"
                >
                    <div headerActions>
                        <a
                            target="_blank"
                            [matTooltip]="
                                'projectview.see-asso-profile' | transloco
                            "
                            [routerLink]="
                                '/organizations/' + project.association.id
                            "
                        >
                            <mat-icon
                                class="text-current"
                                [svgIcon]="
                                    'heroicons_outline:information-circle'
                                "
                            ></mat-icon>
                        </a>
                    </div>
                    <div
                        class="flex flex-col sm:flex-row items-start px-2 md:px-6 overflow-x-scroll"
                    >
                        <div
                            class="pb-1"
                            [innerHTML]="
                                sanitize(project.association.description)
                            "
                        ></div>
                    </div>
                </bslk-classic-card>
            </ng-container>
        </div>
    </cover-template>
</div>
