import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store';
import { isEmpty, isNil } from 'lodash-es';

const selectState = (state: AppState) => state.awarenessCampaign;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectHasOngoingCampaign = createSelector(
    selectState,
    (state) => !state.isLoading && !isEmpty(state.thematicsProgress)
);

const selectThemathicsProgress = createSelector(
    selectState,
    (state) => state.thematicsProgress
);

const selectIsLoadingThematicDetails = createSelector(
    selectState,
    (state) => state.isLoadingThematicDetails
);

const selectThemathicDetails = createSelector(
    selectState,
    (state) => state.thematicDetails
);

const selectIsLoadingActiveCampaign = createSelector(
    selectState,
    (state) => state.isLoadingActiveCampaign
);

const selectActiveCampaign = createSelector(
    selectState,
    (state) => state.activeCampaign
);
const selectHasActiveCampaign = createSelector(
    selectState,
    (state) => !isNil(state.activeCampaign)
);

export const AwarenessCampaignSelectors = {
    selectHasOngoingCampaign,
    selectIsLoading,
    selectIsLoadingThematicDetails,
    selectThemathicDetails,
    selectThemathicsProgress,
    selectIsLoadingActiveCampaign,
    selectActiveCampaign,
    selectHasActiveCampaign,
};
