import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MissionRatingService } from 'app/api';
import {
    createLoadListRequestEffect,
    createReloadEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { AssociationRatingListActions } from './association-rating.actions';
import { AssociationRatingListSelectors } from './association-rating.selectors';

@Injectable()
export class AssociationRatingListEffects {
    loadAssociations$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.missionRatingService,
        AssociationRatingListActions,
        AssociationRatingListSelectors,
        this.missionRatingService.missionRatingAssociationGet
    );

    reload$ = createReloadEffect(this.actions$, AssociationRatingListActions);

    constructor(
        private actions$: Actions,
        private store: Store,
        private missionRatingService: MissionRatingService
    ) {}
}
