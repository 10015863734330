<base-dialog
    (onClose)="close()"
    [showFireworks]="true"
    [title]="'basile.reward-dialog.title' | transloco"
    [showActions]="true"
>
    <div class="w-full h-full flex flex-col justify-center" body>
        <div class="text-center p-2">
            <div class="text-2xl font-bold">
                {{ "basile.reward-dialog.congrats" | transloco }} 🎉
            </div>
            <div class="text-xl">
                <span
                    >{{
                        "basile.reward-dialog.you-unlocked-rewards" | transloco
                    }}
                </span>
            </div>
            <div class="flex flex-col items-center gap-4 mt-3">
                <div *ngFor="let reward of rewards">
                    <bslk-reward
                        class="mt-2 flex justify-center"
                        [purposeCoins]="reward.purposeCoins"
                        [experiencePoints]="reward.experiencePoints"
                        [size]="'large'"
                    ></bslk-reward>
                    <div class="mt-2 flex justify-center items-center">
                        <span>{{
                            "basile.reward-dialog.for-having-accomplished"
                                | transloco
                        }}</span>
                        <span class="font-semibold flex">
                            <mat-icon
                                class="ml-2 mr-1 text-green-900 align-middle"
                                [svgIcon]="'heroicons_outline:check-circle'"
                            >
                            </mat-icon>
                            {{ "rewards.list." + reward.eReward | transloco }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex justify-end" actions>
        <button
            (click)="close()"
            [color]="'accent'"
            class="z-10 text-white m-auto"
            mat-flat-button
        >
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            <span class="ml-1">{{
                "basile.reward-dialog.close" | transloco
            }}</span>
        </button>
    </div>
</base-dialog>
