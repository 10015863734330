import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DualQuizChallengeSelectors } from 'app/store/dual-quiz/challenge/challenge.selectors';
import { DualQuizChallengeRoundTimerComponent } from '../timers/round-timer/round-timer.component';
import { DualQuizChallengeAnswerComponent } from './answer/answer.component';

@Component({
    selector: 'dual-quiz-challenge-question',
    templateUrl: './question.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        LetDirective,
        MatIconModule,
        TranslocoModule,
        BslkTagComponent,
        NgIf,
        NgClass,
        DualQuizChallengeAnswerComponent,
        DualQuizChallengeRoundTimerComponent,
    ],
})
export class DualQuizChallengeQuestionComponent {
    question$ = this.store.select(
        DualQuizChallengeSelectors.selectCurrentQuestion
    );

    questionEmittedResponseReceived$ = this.store.select(
        DualQuizChallengeSelectors.selectQuestionEmittedResponseReceived
    );

    isTimerRunning$ = this.store.select(
        DualQuizChallengeSelectors.selectIsTimerRunning
    );

    constructor(private store: Store) {}
}
