import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { isEmpty } from 'lodash-es';
import { RegularDashboardActions } from './regular-dashboard.actions';
import { RegularDashboardState } from './regular-dashboard.state';

export const initialState: RegularDashboardState = {
    isLoadingRecap: false,
    isLoadingManagerPendingApprovals: false,
};

export const regularDashboardReducer = createReducer(
    initialState,

    on(RegularDashboardActions.loadRecapRequest, (state) => {
        return {
            ...state,
            isLoadingRecap: true,
        };
    }),

    on(RegularDashboardActions.loadRecapRequestSuccess, (state, { recap }) => {
        return {
            ...state,
            isLoadingRecap: false,
            recap,
        };
    }),

    on(RegularDashboardActions.loadRecapRequestFail, (state) => {
        return {
            ...state,
            isLoadingRecap: false,
        };
    }),

    on(RegularDashboardActions.loadPendingManagerApprovalsRequest, (state) => {
        return {
            ...state,
            isLoadingManagerPendingApprovals: true,
        };
    }),

    on(
        RegularDashboardActions.loadPendingManagerApprovalsRequestSuccess,
        (state, { approvalsInfo }) => {
            return {
                ...state,
                isLoadingManagerPendingApprovals: false,
                managerPendingApprovals: approvalsInfo,
            };
        }
    ),

    on(
        RegularDashboardActions.loadPendingManagerApprovalsRequestFail,
        (state) => {
            return {
                ...state,
                isLoadingManagerPendingApprovals: false,
            };
        }
    ),

    on(
        RegularDashboardActions.answerApproveRequest,
        (state, { userId, missionSlotId }) =>
            produce(state, (draftState) => {
                const missionSlot = draftState.managerPendingApprovals.find(
                    (s) => s.missionSlotId == missionSlotId
                );
                missionSlot.users = missionSlot.users.filter(
                    (u) => u.id !== userId
                );
                if (isEmpty(missionSlot.users)) {
                    draftState.managerPendingApprovals =
                        draftState.managerPendingApprovals.filter(
                            (a) => a.missionSlotId !== missionSlotId
                        );
                }
            })
    )
);
