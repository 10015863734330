import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { EUserRole } from 'app/api';
import { getUserSessionFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { UserActions } from 'app/store/user/user/user.actions';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';

export const authInterceptor = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const store = inject(Store);

    return next(request).pipe(
        catchError((error: HttpErrorResponse) => {
            if (
                error.status === 401 &&
                error.url &&
                error.url.includes(environment.apiBaseUrl)
            ) {
                const exceptionUrls = ['donationCampaignAnon/token'];

                if (exceptionUrls.some((url) => error.url.includes(url))) {
                    return throwError(() => error);
                }
                const userRole: EUserRole =
                    getUserSessionFromLocalStorage()?.user?.userRole;

                // When a company admin signs in with a redirect url that needs to be accessed as regular, he will first be unauthorized
                // so we need to switch automatically and reload the page
                if (
                    userRole === EUserRole.CompanyAdmin &&
                    !error.url.includes('switch-role')
                ) {
                    const redirect = window.location.pathname;
                    store.dispatch(
                        UserActions.switchRoleRequest({
                            redirect,
                        })
                    );
                } else if (
                    environment.redirectIfUnauthorized &&
                    authService.signOut()
                ) {
                    location.reload();
                }
            }
            return throwError(() => error);
        })
    );
};
