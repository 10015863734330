import { Component, Input } from '@angular/core';
import { BlockViewModel } from 'app/api';

@Component({
    selector: 'bslk-base-block',
    templateUrl: './base-block.component.html',
})
export abstract class BaseBlockComponent {
    @Input() block: BlockViewModel;
}
