import { createReducer, on } from '@ngrx/store';
import { MyBasileActions } from './my-basile.action';
import { MyBasileState } from './my-basilie.state';

export const myBasileInitialState: MyBasileState = {
    assets: [],
    isLoading: false,
    configuration: {
        purposeCoins: 0,
    },
};

export const myBasilikReducer = createReducer(
    myBasileInitialState,

    on(MyBasileActions.loadConfigurationRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(
        MyBasileActions.loadConfigurationRequestSuccess,
        (state, { configuration }) => {
            return {
                ...state,
                isLoading: false,
                configuration,
            };
        }
    ),

    on(MyBasileActions.loadConfigurationRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(MyBasileActions.loadAssetsRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(MyBasileActions.loadAssetsRequestSuccess, (state, { assets }) => {
        return {
            ...state,
            isLoading: false,
            assets,
        };
    }),

    on(MyBasileActions.loadAssetsRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(MyBasileActions.equipAssetRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(MyBasileActions.equipAssetRequestSuccess, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(MyBasileActions.equipAssetRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(MyBasileActions.unequipAssetRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(MyBasileActions.unequipAssetRequestSuccess, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(MyBasileActions.unequipAssetRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(MyBasileActions.buyAssetRequest, (state) => {
        return {
            ...state,
            isLoading: true,
        };
    }),

    on(MyBasileActions.buyAssetRequestSuccess, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    }),

    on(MyBasileActions.buyAssetRequestFail, (state) => {
        return {
            ...state,
            isLoading: false,
        };
    })
);
