import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkBusinessSdgPickerComponent } from '@bslk-business/components/form/sdg-picker/sdg-picker.component';
import { BslkAdvancedChartComponent } from '@bslk/components/charts/bslk-advanced-chart/bslk-advanced-chart.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import {
    ESustainableDevelopmentGoal,
    ESustainableDevelopmentGoalSustainableDevelopmentGoalMissionKpisEnumChart,
} from 'app/api';
import { sustainableDevelopmentGoals } from 'app/shared/utils/constants.utils';
import { CompanyAdminDashboardActions } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.actions';
import { CompanyAdminDashboardSelectors } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.selectors';
import { keys, maxBy } from 'lodash-es';
import { takeUntil } from 'rxjs';
import { CompanyAdminDashboardChartComponent } from '../chart.component';

@Component({
    selector: 'tracking-my-impact',
    templateUrl: './tracking-my-impact.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        BslkAdvancedChartComponent,
        MatIconModule,
        BslkBusinessSdgPickerComponent,
        TranslocoModule,
    ],
})
export class TrackingMyImpactComponent
    extends CompanyAdminDashboardChartComponent
    implements OnInit, OnDestroy
{
    totalSdgHours: number;
    totalSdgMissions: number;
    selectedSdg: ESustainableDevelopmentGoal;
    response: ESustainableDevelopmentGoalSustainableDevelopmentGoalMissionKpisEnumChart;

    constructor(
        protected store: Store,
        protected datePipe: DatePipe,
        protected cdRef: ChangeDetectorRef
    ) {
        super(store, datePipe, cdRef, 'tracking-my-impact');
    }

    ngOnInit(): void {
        this.store.dispatch(
            CompanyAdminDashboardActions.checkIfKpiIsLoaded({
                kpi: this.kpiName,
            })
        );

        this.store
            .select(
                CompanyAdminDashboardSelectors.selectKpiResult(this.kpiName)
            )
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(
                (
                    response: ESustainableDevelopmentGoalSustainableDevelopmentGoalMissionKpisEnumChart
                ) => {
                    if (response) {
                        this.response = response;
                        // Pick default sdg
                        const sdgWithHighestParticipation = maxBy(
                            keys(this.response.data),
                            (key) =>
                                this.response.data[key].percentageOfTotalHours
                        ) as ESustainableDevelopmentGoal;
                        this.onSdgChange(sdgWithHighestParticipation);
                    }
                }
            );
    }

    buildOptions() {
        this.chartOptions = {
            chart: {
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        value: {
                            fontSize: '35px',
                            fontFamily: 'Poppins',
                            fontWeight: 900,
                        },
                        name: {
                            show: false,
                        },
                    },
                },
            },

            grid: {
                padding: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
            fill: {
                colors: [sustainableDevelopmentGoals[this.selectedSdg].color],
            },
        };
    }

    onSdgChange(value: ESustainableDevelopmentGoal) {
        this.selectedSdg = value;
        this.totalSdgHours =
            this.response.data[this.selectedSdg]?.hoursTotal ?? 0;
        this.totalSdgMissions =
            this.response.data[this.selectedSdg]?.missionsTotal ?? 0;
        this.series = [
            this.response.data[this.selectedSdg]?.percentageOfTotalHours ?? 0,
        ];
        this.buildOptions();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
