import { NgFor, NgIf } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { AvatarAssetViewModel } from 'app/api/model/avatarAssetViewModel';
import { EAvatarAssetType } from 'app/api/model/eAvatarAssetType';
import {
    AssetPosition,
    MyBasileComponent,
} from 'app/shared/components/my-basile/my-basile.component';
import { getAvatarAssetPath } from 'app/shared/utils/file-helpers.utils';
import { isNil } from 'lodash-es';
export type AvatarAssetSelectEvent = {
    asset?: AvatarAssetViewModel;
    type: EAvatarAssetType;
};

@Component({
    selector: 'asset-picker',
    templateUrl: './asset-picker.component.html',
    standalone: true,
    imports: [
        MyBasileComponent,
        MatIconModule,
        MatButtonModule,
        NgFor,
        NgIf,
        MatTooltipModule,
        MatProgressSpinnerModule,
        TranslocoModule,
    ],
})
export class AssetPicker implements OnChanges {
    @Input() isLoading = true;
    @Input() assets: AvatarAssetViewModel[];
    @Input() selectedAssetType: EAvatarAssetType;
    @Input() purposeCoinsBalance = 0;

    @Output() assetPreviewed: EventEmitter<AvatarAssetSelectEvent> =
        new EventEmitter<AvatarAssetSelectEvent>();
    @Output() assetEquiped: EventEmitter<AvatarAssetSelectEvent> =
        new EventEmitter<AvatarAssetSelectEvent>();
    @Output() assetBought: EventEmitter<AvatarAssetSelectEvent> =
        new EventEmitter<AvatarAssetSelectEvent>();

    selectedAsset?: AvatarAssetViewModel = null;

    assetsPositions: { [key: string]: AssetPosition } = {
        [EAvatarAssetType.Hat]: {
            top: 10,
            left: 0,
            width: 100,
        },
        [EAvatarAssetType.Face]: {
            top: -10,
            left: 0,
            width: 100,
        },
        [EAvatarAssetType.Beard]: {
            top: -30,
            left: 0,
            width: 100,
        },
        [EAvatarAssetType.Mustache]: {
            top: -30,
            left: 0,
            width: 100,
        },
        [EAvatarAssetType.Neck]: {
            top: -35,
            left: 0,
            width: 100,
        },
        [EAvatarAssetType.Top]: {
            top: -55,
            left: 0,
            width: 100,
        },
        [EAvatarAssetType.Cape]: {
            top: -75,
            left: 0,
            width: 100,
        },
        [EAvatarAssetType.Bottom]: {
            top: -70,
            left: 0,
            width: 100,
        },
        [EAvatarAssetType.Shoes]: {
            top: -100,
            left: 0,
            width: 100,
        },
        [EAvatarAssetType.Glasses]: {
            top: -20,
            left: 0,
            width: 100,
        },
    };

    // Get all the assets of the current selected asset type
    get selectedTypeAssets() {
        return this.assets.filter(
            (a) => a.avatarAssetType === this.selectedAssetType
        );
    }

    // Get the equiped asset (if any) of the current selected asset type
    get equipedSelectedTypeAsset(): AvatarAssetViewModel | undefined {
        return this.assets.find(
            (v: AvatarAssetViewModel) =>
                v.avatarAssetType === this.selectedAssetType && v.isEquiped
        );
    }

    get showUnequiped() {
        // Can't unequip base assets
        return ![
            EAvatarAssetType.Face,
            EAvatarAssetType.Top,
            EAvatarAssetType.Bottom,
            EAvatarAssetType.Shoes,
        ].includes(this.selectedAssetType);
    }

    get noItemSelected() {
        return isNil(this.selectedAsset);
    }

    get noItemEquiped() {
        return isNil(this.equipedSelectedTypeAsset);
    }

    get isSelectedItemBuyable() {
        return !isNil(this.selectedAsset) && !this.selectedAsset.isOwned;
    }

    get hasEnoughBalanceForSelectedItem() {
        return this.purposeCoinsBalance >= this.selectedAsset?.price;
    }

    get isSelectedItemEquipable() {
        return (
            (isNil(this.selectedAsset) &&
                !isNil(this.equipedSelectedTypeAsset)) ||
            (!isNil(this.selectedAsset) &&
                this.selectedAsset.isOwned &&
                this.selectedAsset.id !== this.equipedSelectedTypeAsset?.id)
        );
    }

    get isSelectedItemEquiped() {
        return (
            (isNil(this.selectedAsset) &&
                isNil(this.equipedSelectedTypeAsset)) ||
            this.selectedAsset?.id === this.equipedSelectedTypeAsset?.id
        );
    }

    get isSelectedItemOwned() {
        return this.selectedAsset?.isOwned;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedAssetType || changes.assets) {
            this.selectedAsset = this.equipedSelectedTypeAsset;
        }
    }

    getAssetPosition(asset: AvatarAssetViewModel) {
        return (
            'top:' +
            this.assetsPositions[asset.avatarAssetType].top +
            '%; ' +
            'left:' +
            this.assetsPositions[asset.avatarAssetType].left +
            '%; ' +
            'width:' +
            this.assetsPositions[asset.avatarAssetType].width +
            '%'
        );
    }

    isAssetEquiped(asset: AvatarAssetViewModel) {
        return (
            (isNil(asset) && isNil(this.equipedSelectedTypeAsset)) ||
            asset?.id === this.equipedSelectedTypeAsset?.id
        );
    }

    isAssetSelected(asset: AvatarAssetViewModel) {
        return asset.id === this.selectedAsset?.id;
    }

    getAssetPath(asset: AvatarAssetViewModel) {
        return getAvatarAssetPath(asset);
    }

    onPreviewAsset(asset: AvatarAssetViewModel) {
        this.selectedAsset = asset;
        this.assetPreviewed.emit({
            asset: asset,
            type: this.selectedAssetType,
        });
    }

    onEquipSelectedAsset() {
        this.assetEquiped.emit({
            asset: this.selectedAsset,
            type: this.selectedAssetType,
        });
    }

    onBuySelectedAsset() {
        this.assetBought.emit({
            asset: this.selectedAsset,
            type: this.selectedAssetType,
        });
    }
}
