<div class="flex flex-col gap-3" [formGroup]="formGroup">
    <div class="flex gap-3">
        <div class="flex flex-col gap-1 flex-1">
            <mat-form-field subscriptSizing="dynamic" floatLabel="always">
                <mat-label>{{ "createproject.name" | transloco }}</mat-label>
                <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_solid:pencil'"
                    matPrefix
                ></mat-icon>
                <input
                    [placeholder]="'createproject.name-placeholder' | transloco"
                    [formControlName]="'name'"
                    matInput
                />
            </mat-form-field>
            <bslk-length-info
                [text]="formGroup.controls.name.value"
                [maxLength]="100"
            ></bslk-length-info>
        </div>

        <bslk-advanced-select-sync
            class="flex-1"
            *ngIf="activityFieldsTranslationsLoaded"
            [items]="activityFieldsAdvancedSelectItems"
            [multiple]="'multiple'"
            [group]="formGroup"
            [icon]="'mat_solid:category'"
            [controlName]="'activityFields'"
            [label]="'createproject.activity-fields' | transloco"
            [selectPlaceholder]="
                'createproject.activity-fields-placeholder' | transloco
            "
            [searchPlaceholder]="
                'createproject.search-activity-fields-placeholder' | transloco
            "
        ></bslk-advanced-select-sync>
    </div>

    <div class="flex gap-3">
        <div class="flex flex-col gap-1 flex-1">
            <bslk-info-label
                class="font-bold"
                [tooltip]="'createproject.challenge-tooltip' | transloco"
                >{{ "createproject.challenge" | transloco }}</bslk-info-label
            >
            <mat-form-field subscriptSizing="dynamic" floatLabel="always">
                <textarea
                    class="min-h-24"
                    [placeholder]="
                        'createproject.challenge-placeholder' | transloco
                    "
                    [formControlName]="'challenge'"
                    matInput
                ></textarea>
            </mat-form-field>
            <bslk-length-info
                [text]="formGroup.controls.challenge.value"
                [maxLength]="1000"
                [minLength]="100"
            ></bslk-length-info>
        </div>
        <div class="flex flex-col gap-1 flex-1">
            <bslk-info-label
                class="font-bold"
                [tooltip]="'createproject.solution-tooltip' | transloco"
                >{{ "createproject.solution" | transloco }}</bslk-info-label
            >
            <mat-form-field subscriptSizing="dynamic" floatLabel="always">
                <textarea
                    class="min-h-24"
                    [placeholder]="
                        'createproject.solution-placeholder' | transloco
                    "
                    [formControlName]="'solution'"
                    matInput
                ></textarea>
            </mat-form-field>
            <bslk-length-info
                [text]="formGroup.controls.solution.value"
                [maxLength]="1000"
                [minLength]="100"
            ></bslk-length-info>
        </div>
    </div>

    <div class="flex">
        <div class="flex-1 flex justify-center">
            <bslk-classic-toggle
                [label]="'createproject.is-active' | transloco"
                [infoTooltip]="'createproject.is-active-tooltip' | transloco"
                [color]="'warn'"
                [size]="'small'"
                [choices]="[
                    { text: 'shared.common.yes' | transloco, value: true },
                    { text: 'shared.common.no' | transloco, value: false }
                ]"
                [control]="formGroup.controls.isActive"
                [value]="formGroup.controls.isActive.value"
                class="flex items-center gap-2"
            ></bslk-classic-toggle>
        </div>

        <div class="flex-1 flex flex-col gap-1">
            <bslk-info-label
                class="font-bold"
                [tooltip]="'createproject.solution-details-tooltip' | transloco"
                >{{
                    "createproject.solution-details" | transloco
                }}</bslk-info-label
            >
            <quill-editor
                class="mt-2 w-full"
                [formControlName]="'solutionDetails'"
                [bounds]="'self'"
                [placeholder]="
                    'createproject.solution-details-placeholder' | transloco
                "
                [modules]="quillModules"
            ></quill-editor>
            <bslk-length-info
                [text]="formGroup.controls.solutionDetails.value"
                [maxLength]="3000"
                [isHtml]="true"
            ></bslk-length-info>
        </div>
    </div>
</div>
