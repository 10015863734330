<div
    [class.grayscale]="!thematic"
    bslkDropZone
    (hovered)="onHovered($event)"
    (dropped)="onDropped($event)"
    class="w-full bg-warn-400 text-white rounded-xl shadow flex overflow-hidden"
>
    <div
        class="w-40 xl:w-50 xxl:w-60 border-r border-white flex justify-center items-center"
    >
        <mat-icon
            class="icon-size-20"
            *ngIf="thematic"
            [svgIcon]="'bslk_thematics:' + thematic"
        ></mat-icon>
    </div>

    <div class="flex flex-col p-4 w-full">
        <div class="flex gap-2 justify-between h-20">
            <span class="font-bold text-lg xl:text-xl">{{
                (thematic
                    ? "enums.EAwarenessThematic." + thematic
                    : "courses.campaign-creation.new-thematic"
                ) | transloco
            }}</span>
            <mat-icon
                (click)="removeThematic()"
                [svgIcon]="'heroicons_outline:x-mark'"
                class="text-current cursor-pointer"
            ></mat-icon>
        </div>

        <div class="flex">
            <div class="mt-4 flex flex-col gap-3 grow">
                <span>{{ "courses.campaign-creation.dates" | transloco }}</span>
                <div class="flex items-center justify-around">
                    <mat-form-field
                        [subscriptSizing]="'dynamic'"
                        floatLabel="always"
                        (click)="canSetupDates ? pickerStart.open() : null"
                        [matTooltip]="
                            'courses.campaign-creation.date-tooltip' | transloco
                        "
                        [matTooltipDisabled]="canSetupDates"
                        class="h-min"
                    >
                        <input
                            class="cursor-pointer text-center"
                            matInput
                            [formControl]="formGroup.controls.startDate"
                            [placeholder]="
                                'courses.campaign-creation.date-placeholder'
                                    | transloco
                            "
                            readonly
                            [matDatepicker]="pickerStart"
                            [matDatepickerFilter]="startDateFilter"
                            (dateChange)="dateChanged()"
                        />

                        <mat-datepicker
                            #pickerStart
                            [startAt]="minStartDate"
                        ></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field
                        [subscriptSizing]="'dynamic'"
                        floatLabel="always"
                        (click)="canSetupDates ? pickerEnd.open() : null"
                        [matTooltip]="
                            'courses.campaign-creation.date-tooltip' | transloco
                        "
                        [matTooltipDisabled]="canSetupDates"
                        class="h-min"
                    >
                        <input
                            class="cursor-pointer text-center"
                            matInput
                            [formControl]="formGroup.controls.endDate"
                            [placeholder]="
                                'courses.campaign-creation.date-placeholder'
                                    | transloco
                            "
                            readonly
                            [matDatepicker]="pickerEnd"
                            [matDatepickerFilter]="endDateFilter"
                            (dateChange)="dateChanged()"
                        />

                        <mat-datepicker
                            #pickerEnd
                            [startAt]="formGroup.controls.startDate.value"
                        ></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex flex-col gap-2 justify-between">
                <mat-icon
                    *ngIf="hasPrevious"
                    (click)="swapWithPreviousThematic()"
                    class="cursor-pointer"
                    [svgIcon]="'heroicons_outline:arrow-up'"
                ></mat-icon>
                <mat-icon
                    *ngIf="hasNext"
                    (click)="swapWithNextThematic()"
                    class="cursor-pointer"
                    [svgIcon]="'heroicons_outline:arrow-down'"
                ></mat-icon>
            </div>
        </div>
    </div>
</div>
