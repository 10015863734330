<ng-container *ngFor="let text of richText">
    <ng-container *ngIf="text.plainText">
        <a
            class="underline text-tertiary"
            target="_blank"
            *ngIf="text.href"
            [href]="text.href"
            >{{ text.plainText }}</a
        >
        <span
            [class.italic]="text.annotations.isItalic"
            [class.font-bold]="text.annotations.isBold"
            [class.underline]="text.annotations.isUnderline"
            *ngIf="!text.href"
            >{{ text.plainText }}</span
        >
    </ng-container>
</ng-container>
