import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkClassicProgressBar } from '@bslk/components/progress-bar/classic-progress-bar/classic-progress-bar.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { MissionRegisteredViewModel } from 'app/api';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { EnumTagListComponent } from 'app/shared/components/enum-tag-list/enum-tag-list.component';
import { MissionStateComponent } from 'app/shared/components/mission/state/mission-state.component';
import { sustainableDevelopmentGoals } from 'app/shared/utils/constants.utils';
import {
    calculateDateRangeCompletion,
    formatDateRange,
} from 'app/shared/utils/date-helpers.utils';
import { CompanyAdminDashboardActions } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.actions';
import { CompanyAdminDashboardSelectors } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.selectors';
import {
    CompanyAdminKpi,
    CompanyAdminKpiExtendedEvent,
} from 'app/store/company/company-admin-dashboard/company-admin-dashboard.state';
import { tap } from 'rxjs';
import { BaseKpiPanelComponent } from '../base-kpi-panel/base-kpi-panel.component';

@Component({
    selector: 'all-company-missions',
    templateUrl: './all-company-missions.component.html',
    standalone: true,
    imports: [
        BaseKpiPanelComponent,
        BslkClassicProgressBar,
        TranslocoModule,
        LetDirective,
        AsyncPipe,
        NgClass,
        NgFor,
        NgIf,
        ClassicAvatarComponent,
        MatTooltipModule,
        EnumTagListComponent,
        MissionStateComponent,
        MatIconModule,
        MatPaginatorModule,
    ],
})
export class AllCompanyMissionsComponent implements OnInit {
    @Output() expands: EventEmitter<CompanyAdminKpiExtendedEvent> =
        new EventEmitter<CompanyAdminKpiExtendedEvent>();

    kpiName: CompanyAdminKpi = 'all-company-missions';
    sustainableDevelopmentGoals = sustainableDevelopmentGoals;

    constructor(
        private store: Store,
        private cdRef: ChangeDetectorRef,
        private datePipe: DatePipe
    ) {}

    isLoading$ = this.store
        .select(CompanyAdminDashboardSelectors.selectIsLoading(this.kpiName))
        .pipe(
            tap(() => {
                this.cdRef.detectChanges();
            })
        );

    allMissions$ = this.store.select(
        CompanyAdminDashboardSelectors.selectKpiResult(this.kpiName)
    );

    isExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpanded(this.kpiName)
    );

    ngOnInit() {
        this.store.dispatch(
            CompanyAdminDashboardActions.checkIfKpiIsLoaded({
                kpi: this.kpiName,
            })
        );
    }

    formatDateRange(startDate: string, endDate: string): string {
        return formatDateRange(this.datePipe, startDate, endDate);
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            CompanyAdminDashboardActions.updateKpiFilters({
                kpi: this.kpiName,
                filters: {
                    pageIndex: event.pageIndex,
                },
            })
        );
    }

    calculateDateRangeCompletion(mission: MissionRegisteredViewModel) {
        return calculateDateRangeCompletion(mission.startDate, mission.endDate);
    }
}
