<fuse-card
    (click)="view()"
    class="h-auto sm:h-64 transition duration-300 hover:scale-[1.03] cursor-pointer"
>
    <div class="flex flex-col sm:flex-row w-full">
        <ng-container>
            <div class="w-full h-24 sm:h-auto sm:w-1/4 relative">
                <img
                    *ngIf="mission.coverUrl"
                    class="w-full h-full object-cover"
                    [src]="mission.coverUrl"
                    alt="Cover image"
                />
                <div
                    *ngIf="!mission.coverUrl"
                    class="w-full h-full flex border-r"
                >
                    <mat-icon
                        class="icon-size-16 m-auto"
                        [svgIcon]="'heroicons_solid:photo'"
                    >
                    </mat-icon>
                </div>
                <mission-availability
                    class="absolute left-1/2 -translate-x-1/2 bottom-2"
                    [mission]="mission"
                ></mission-availability>
            </div>

            <div class="w-full sm:w-2/4 border-r-2 flex flex-col">
                <div
                    class="flex justify-between items-center border-b-2 p-3 xxl:p-4"
                >
                    <div class="flex flex-col truncate">
                        <span
                            [matTooltip]="mission.name"
                            class="font-bold text-2xl truncate"
                            >{{ mission.name }}
                        </span>
                        <div class="truncate">
                            <span class="whitespace-nowrap"
                                >{{
                                    "missions.card.created-by" | transloco
                                }}&nbsp;</span
                            >
                            <span
                                class="whitespace-nowrap text-primary font-bold"
                                >{{ mission.association.name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    class="flex-grow text-center flex justify-center flex-col border-b-2"
                >
                    <ng-content></ng-content>
                </div>
                <ng-content select="[footer]"></ng-content>
            </div>
            <div class="w-full sm:w-1/4 p-6 flex flex-col justify-between">
                <div class="text-right flex flex-col">
                    <span class="font-bold text-warn mb-2">{{
                        "missions.card.mission-location" | transloco
                    }}</span>
                    <div *ngIf="mission.location" class="flex justify-end">
                        <mat-icon
                            class="icon-size-5 m-auto mr-1"
                            [svgIcon]="'heroicons_solid:map-pin'"
                        >
                        </mat-icon>
                        <span class="truncate"
                            >{{ mission.location.zipCode }}
                            {{ mission.location.city }}</span
                        >
                    </div>
                    <span
                        *ngIf="mission.isRemote"
                        [class.mt-2]="mission.isRemote"
                        class="flex justify-end"
                    >
                        <mat-icon
                            class="icon-size-5 mr-1"
                            [svgIcon]="'heroicons_solid:computer-desktop'"
                        >
                        </mat-icon>
                        {{ "missions.card.remote" | transloco }}
                    </span>
                </div>
                <ng-content select="[actions]"></ng-content>
            </div>
        </ng-container>
    </div>
</fuse-card>
