/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EAvatarAssetType = 'Face' | 'Hat' | 'Glasses' | 'Neck' | 'Top' | 'Bottom' | 'Shoes' | 'Beard' | 'Mustache' | 'Cape';

export const EAvatarAssetType = {
    Face: 'Face' as EAvatarAssetType,
    Hat: 'Hat' as EAvatarAssetType,
    Glasses: 'Glasses' as EAvatarAssetType,
    Neck: 'Neck' as EAvatarAssetType,
    Top: 'Top' as EAvatarAssetType,
    Bottom: 'Bottom' as EAvatarAssetType,
    Shoes: 'Shoes' as EAvatarAssetType,
    Beard: 'Beard' as EAvatarAssetType,
    Mustache: 'Mustache' as EAvatarAssetType,
    Cape: 'Cape' as EAvatarAssetType
};

