<div *ngIf="!(isLoading$ | async); else loading">
    <ng-container *ngrxLet="campaign$ as campaign">
        <ng-container *ngIf="isPreview || campaign?.isPaid; else notPaid">
            <div
                *ngIf="
                    campaign.state === EDonationCampaignState.Active ||
                        isPreview;
                    else finished
                "
                [style]="
                    'backgroundColor:' +
                        campaign?.customization.bodyBackgroundColor ??
                    '#f8eee1'
                "
                class="flex flex-col min-h-screen w-full relative transition-all duration-300"
            >
                <bslk-tag
                    *ngIf="isPreview"
                    class="top-3 left-3 fixed z-10 animate-pulse"
                    [background]="'warn'"
                    [icon]="'feather:monitor'"
                    >{{ "donationgift.preview" | transloco }}</bslk-tag
                >

                <div class="grow transition-all duration-300 flex flex-col">
                    <div
                        *ngIf="giftOpened && !giftOpenedEndAnimation"
                        class="pyro absolute top-0 left-0 right-0 bottom-0 overflow-hidden"
                    >
                        <div class="before overflow-hidden"></div>
                        <div class="after overflow-hidden"></div>
                    </div>

                    <div
                        [@fadeIn]="true"
                        *ngIf="
                            giftOpenedEndAnimation &&
                            !(hasChosenProject$ | async)
                        "
                        [style]="
                            'backgroundColor:' +
                            campaign?.customization.headerBackgroundColor
                        "
                        class="w-full min-h-48 relative flex justify-center items-center transition-all duration-300"
                    >
                        <div
                            class="flex flex-col lg:flex-row gap-2 mt-2 items-center justify-center px-2"
                        >
                            <img
                                *ngIf="campaign?.customization.imageUrl"
                                class="max-w-24 md:max-w-36"
                                [src]="campaign?.customization.imageUrl"
                            />
                            <div
                                *ngIf="campaign?.customization.imageUrl"
                                [style]="
                                    'borderColor:' +
                                    campaign?.customization.textColor
                                "
                                class="hidden lg:flex border-2 border-l h-30 ml-6 mr-3"
                            ></div>

                            <div
                                [style]="
                                    'color:' + campaign?.customization.textColor
                                "
                                class="rounded-xl text-lg xxl:text-xl font-semibold max-w-lg p-4"
                                *ngIf="campaign.message"
                            >
                                {{ campaign.message }}
                            </div>
                            <gift-card
                                *ngIf="campaign && giftOpenedEndAnimation"
                                [campaign]="campaign"
                                class="px-1.5 scale-75"
                            ></gift-card>
                        </div>
                        <div
                            class="absolute top-0 w-full h-full overflow-hidden pointer-events-none pyro"
                        >
                            <div class="before"></div>
                            <div class="after"></div>
                        </div>
                    </div>

                    <project-list
                        class="w-screen"
                        *ngIf="
                            giftOpenedEndAnimation &&
                            campaign &&
                            !(hasChosenProject$ | async)
                        "
                        [@fadeIn]="true"
                        [canChoose]="true"
                        [projects]="campaign.projects"
                        (projectChosen)="onProjectChosen($event)"
                    ></project-list>

                    <open-gift
                        class="m-auto"
                        *ngIf="!giftOpenedEndAnimation"
                        (giftOpened)="onGiftOpened()"
                        [campaign]="campaign"
                    ></open-gift>

                    <gift-card
                        *ngIf="
                            !(isLoading$ | async) &&
                            !(hasChosenProject$ | async)
                        "
                        [ngClass]="{
                            'opacity-0 -z-1':
                                giftOpenedEndAnimation,
                            'scale-[0.20] xxl:scale-75': !giftOpened,
                            'z-10': giftOpened && !giftOpenedEndAnimation,
                        }"
                        [isOpened]="giftOpened"
                        class="absolute transition-all duration-1000 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-full md:w-auto px-1.5 md:px-0"
                        [campaign]="campaign"
                    ></gift-card>
                </div>
                <bslk-footer></bslk-footer>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #notPaid>
    <div
        class="flex flex-col gap-6 justify-center items-center min-w-screen min-h-screen"
    >
        <fuse-alert type="error" class="flex justify-center">
            {{ "donationgift.fund-appeal-unpaid" | transloco }}
        </fuse-alert>

        <a target="_blank" [href]="previewUrl$ | async">
            <bslk-tag-button [icon]="'heroicons_outline:computer-desktop'">{{
                "donationgift.view-preview" | transloco
            }}</bslk-tag-button>
        </a>
    </div>
</ng-template>

<ng-template #finished>
    <div
        class="flex flex-col gap-6 justify-center items-center min-w-screen min-h-screen"
    >
        <fuse-alert type="error" class="flex justify-center">
            {{ "donationgift.finished-campaign" | transloco }}
        </fuse-alert>
    </div>
</ng-template>

<ng-template #loading>
    <div class="w-screen h-screen flex flex-col items-center justify-center">
        <mat-spinner diameter="90"></mat-spinner>
    </div>
</ng-template>
