import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { extractTextFromHtml } from 'app/shared/utils/text.utils';

@Component({
    selector: 'bslk-length-info',
    standalone: true,
    templateUrl: './length-info.component.html',
    imports: [NgIf, TranslocoModule],
})
export class BslkLengthInfoComponent {
    @Input() text: string;
    @Input() maxLength: number;
    @Input() minLength: number;

    @Input() isHtml = false;
    @Input() isDynamicHeight = true;

    get textLength() {
        if (!this.isHtml) {
            return this.text.length;
        }
        return extractTextFromHtml(this.text).length;
    }
}
