import { createReducer, on } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { RessourceListActions } from './ressource-list-action-group-creator';
import {
    IResourceList,
    RessourceListState,
    resourceCreationId,
} from './ressource-list-state';

export function createRessourceListReducer<
    T extends { id?: number },
    TList extends IResourceList<T>
>(
    ressourceListInitialState: RessourceListState<T>,
    ressourceListActions: RessourceListActions<T, TList>,
    additionalReducerActions?: any
) {
    return createReducer(
        ressourceListInitialState,

        on(ressourceListActions.Initialize, () => ressourceListInitialState),

        on(ressourceListActions.InitializeWithFilters, (state, { filters }) => {
            // Only initialize if base filters are not already active, if they are we don't want to override previous applied filters
            if (filters.every((f) => !isNil(state.filters[f.key]))) {
                return state;
            }

            const filtersObj = {};
            for (const filter of filters) {
                filtersObj[filter.key] = filter.value;
            }
            return {
                ...ressourceListInitialState,
                filters: filtersObj,
            };
        }),

        on(ressourceListActions.LoadListRequest, (state) => {
            return {
                ...state,
                isLoading: true,
                selectedItemId: null,
            };
        }),

        on(ressourceListActions.LoadListSuccess, (state, { response }) => {
            return {
                ...state,
                isLoading: false,
                data: response.data,
                pageIndex: response.pageIndex,
                totalPages: response.totalPages,
                totalCount: response.totalCount,
                pageSize: response.pageSize,
                properties: response.properties,
                isInitialized: true,
            };
        }),

        on(ressourceListActions.LoadListFailure, (state) => {
            return {
                ...state,
                isLoading: false,
            };
        }),

        on(ressourceListActions.FilterListRequest, (state, { filters }) => {
            let newFilters = { ...state.filters };
            // Reset page index if any other filter changed
            if (!Object.keys(filters).includes('pageIndex')) {
                newFilters['pageIndex'] = 0;
            }

            for (const filter of filters) {
                newFilters[filter.key] = filter.value;
            }

            return {
                ...state,
                filters: newFilters,
            };
        }),

        on(ressourceListActions.SelectItem, (state, { id }) => {
            return {
                ...state,
                selectedItemId: id,
                newItem: null,
                data: state.data.filter((d) => d.id !== resourceCreationId),
            };
        }),

        on(ressourceListActions.UnselectItems, (state) => {
            return {
                ...state,
                selectedItemId: null,
            };
        }),

        on(ressourceListActions.UpdateSelectedItem, (state) => {
            return {
                ...state,
                isLoading: true,
                data: state.data.filter((d) => d.id !== resourceCreationId),
            };
        }),

        on(ressourceListActions.UpdateSelectedItemSuccess, (state) => {
            return {
                ...state,
                selectedItemId: null,
                isLoading: false,
            };
        }),

        on(ressourceListActions.UpdateSelectedItemFail, (state) => {
            return {
                ...state,
                isLoading: false,
            };
        }),

        on(ressourceListActions.StartCreation, (state) => {
            // Already in the creating process
            if (state.selectedItemId === resourceCreationId) {
                return state;
            }
            const newItem = { id: resourceCreationId } as T;
            const data = [...state.data];
            data.unshift(newItem);
            return {
                ...state,
                selectedItemId: resourceCreationId,
                data,
            };
        }),

        on(ressourceListActions.CancelCreation, (state) => {
            return {
                ...state,
                data: state.data.filter((d) => d.id !== resourceCreationId),
                selectedItemId: null,
            };
        }),

        on(ressourceListActions.CreateItem, (state) => {
            return {
                ...state,
                isLoading: true,
            };
        }),

        on(ressourceListActions.CreateItemSuccess, (state) => {
            return {
                ...state,
                isLoading: false,
                selectedItemId: null,
                data: state.data.filter((d) => d.id !== resourceCreationId),
            };
        }),

        on(ressourceListActions.CreateItemFail, (state) => {
            return {
                ...state,
                isLoading: false,
            };
        }),

        on(ressourceListActions.DeleteItem, (state) => {
            return {
                ...state,
                isLoading: true,
            };
        }),

        on(ressourceListActions.DeleteItemSuccess, (state) => {
            return {
                ...state,
                selectedItemId: null,
                isLoading: false,
            };
        }),

        on(ressourceListActions.DeleteItemFail, (state) => {
            return {
                ...state,
                isLoading: false,
            };
        }),

        ...(additionalReducerActions ? additionalReducerActions : [])
    );
}
