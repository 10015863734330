<div
    class="w-full bg-white rounded-2xl shadow-lg p-4 md:p-6 xxl:p-8 flex flex-col gap-3"
>
    <div class="flex flex-col gap-1">
        <span class="text-2xl font-bold">{{
            "courses.campaign-creation.thematic-list" | transloco
        }}</span>
    </div>

    <div
        class="text-center m-auto font-warn text-xl"
        *ngIf="thematics.length === 0"
    >
        {{ "courses.campaign-creation.no-more-thematic" | transloco }}
    </div>
    <div class="grid grid-cols-1 gap-4">
        <!-- class="p-4 bg-gray-100 rounded-xl shadow-xl col-span-2 xl:col-span-1 flex flex-col justify-between" -->
        <div
            class="p-4 bg-accent overflow-hidden rounded-xl shadow-xl col-span-1 flex"
            *ngFor="let thematic of thematics"
            bslkDraggable
            [dragData]="thematic"
        >
            <mat-icon
                class="icon-size-24"
                [svgIcon]="'bslk_thematics:' + thematic"
            ></mat-icon>
            <div class="p-4 z-20 h-full grow flex flex-col justify-between">
                <div class="flex items-center justify-between">
                    <div class="flex gap-3 items-center">
                        <span class="text-lg font-bold"
                            >{{
                                "enums.EAwarenessThematic." + thematic
                                    | transloco
                            }}
                        </span>
                    </div>
                    <mat-icon
                        class="text-current"
                        [svgIcon]="'mat_solid:drag_indicator'"
                    ></mat-icon>
                </div>
                <div class="flex items-center justify-between mt-4">
                    <bslk-classic-button
                        (onClick)="addThematic(thematic)"
                        class="inline"
                        [icon]="'heroicons_outline:plus'"
                    >
                        <span>{{
                            "courses.campaign-creation.add" | transloco
                        }}</span>
                    </bslk-classic-button>

                    <button
                        (click)="selectedThematicDetails = thematic"
                        mat-flat-button
                        class="border-2 border-solid border-warn bg-white"
                    >
                        <mat-icon
                            class="text-current"
                            [svgIcon]="'heroicons_outline:eye'"
                        ></mat-icon>
                        <span class="ml-2">{{
                            "courses.campaign-creation.details" | transloco
                        }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<awareness-campaign-thematic-details
    (close)="selectedThematicDetails = null"
    [thematic]="selectedThematicDetails"
></awareness-campaign-thematic-details>
