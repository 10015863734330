import { EReward } from 'app/api'; // Import the EReward type from app/api

const EXPERIENCE_POINTS_FACTOR = 1471;
const PURPOSE_COINS_FACTOR = 50;

export type RewardValues = {
    purposeCoins: number;
    experiencePoints: number;
};

const rewardsDictionary: Record<string, number> = {
    [EReward.ProfileInformationFilled]: 0.3,
    [EReward.MissionRatingPosted]: 35,
    [EReward.CourseChapterSectionRead]: 0.068,
    [EReward.CourseChapterQuestionCorrectlyAnswered]: 0.27,
    [EReward.CourseChapterSectionCommentPosted]: 1,
    [EReward.CourseTrophyChapterCompleted]: 2,
    [EReward.DualQuizChallengeWon]: 3,
    [EReward.DualQuizChallengeTied]: 1.25,
    [EReward.DualQuizChallengeLost]: 0.75,
};

export function getRewardValues(reward: EReward): RewardValues {
    const coeff = rewardsDictionary[reward];
    return {
        purposeCoins: Math.round(coeff * PURPOSE_COINS_FACTOR),
        experiencePoints: Math.round(coeff * EXPERIENCE_POINTS_FACTOR),
    };
}
