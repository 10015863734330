import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MissionService } from 'app/api';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { MissionFormService } from 'app/shared/services/form/mission-form.service';
import { isNil } from 'lodash-es';
import {
    catchError,
    debounceTime,
    exhaustMap,
    filter,
    map,
    of,
    tap,
} from 'rxjs';
import {
    createDeleteItemEffect,
    createLoadItemRequestEffect,
    createLoadListRequestEffect,
    createReloadEffect,
    createUpdateSelectedItemEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { UserSelectors } from '../../user/user/user.selectors';
import { MissionActions } from '../mission/mission.action';
import { MissionSelectors } from '../mission/mission.selectors';
import { MissionListActions } from './mission-list.actions';
import { MissionListSelectors } from './mission-list.selectors';
@Injectable()
export class MissionListEffects {
    loadMissions$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.missionService,
        MissionListActions,
        MissionListSelectors,
        this.missionService.missionGet
    );

    loadMission$ = createLoadItemRequestEffect(
        this.store,
        this.actions$,
        this.missionService,
        MissionListActions,
        MissionListSelectors,
        this.missionService.missionIdGet
    );

    reload$ = createReloadEffect(this.actions$, MissionListActions, [
        MissionActions.createMissionRequestSuccess,
        MissionActions.updateCoverRequestSuccess,
        MissionActions.copyMissionRequestSuccess,
    ]);

    deleteMission$ = createDeleteItemEffect(
        this.missionService,
        this.actions$,
        MissionListActions,
        'mission-delete',
        this.missionService.missionDelete
    );

    openUpdateMissionDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionListActions.SelectItem),
            concatLatestFrom(() => [
                this.store.select(MissionListSelectors.selectSelectedItem),
                this.store.select(UserSelectors.selectUserId),
            ]),
            tap(([, selectedItem, userId]) => {
                this.missionFormService.updateFormFromUser(
                    selectedItem,
                    userId
                );
            }),
            map(() =>
                MissionActions.openDialog({
                    input: { isEdit: true, isFromEntityPage: false },
                })
            )
        )
    );

    publishRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionListActions.PublishRequest),
            concatLatestFrom(() =>
                this.store.select(MissionListSelectors.selectSelectedItem)
            ),
            tap(([, selectedItem]) => {
                const missionCopy = structuredClone(selectedItem);
                missionCopy.isActive = true;
                this.missionFormService.updateForm(missionCopy);
            }),
            map(() => MissionListActions.UpdateSelectedItem())
        )
    );

    updateMission$ = createUpdateSelectedItemEffect(
        this.missionService,
        this.actions$,
        MissionListActions,
        this.missionFormService,
        'mission-update',
        this.missionService.missionPut
    );

    updateCover$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionActions.setCoverRequest),
            concatLatestFrom(() => [
                this.store.select(MissionListSelectors.selectSelectedItem),
                this.store.select(MissionSelectors.selectCoverFile),
            ]),
            // Only update when is updating a mission
            filter(
                ([, selectedItem, coverFile]) =>
                    !isNil(selectedItem) && !isNil(coverFile)
            ),
            exhaustMap(([, selectedItem, file]) => {
                this.fileUploadDialogService.close();
                return this.missionService
                    .missionIdCoverPut({ id: selectedItem.id, file })
                    .pipe(
                        map(() => MissionActions.updateCoverRequestSuccess())
                    );
            })
        )
    );

    updateCoverFromList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionListActions.UpdateCover),
            exhaustMap(({ file, id }) => {
                return this.missionService.missionIdCoverPut({ id, file }).pipe(
                    map(() => {
                        this.fileUploadDialogService.close();
                        return MissionActions.updateCoverRequestSuccess();
                    })
                );
            })
        )
    );

    closeAfterUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionListActions.UpdateSelectedItemSuccess),
            map(() => MissionActions.closeDialog())
        )
    );

    loadCities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissionListActions.LoadCities),
            debounceTime(300),
            exhaustMap(({ search }) =>
                this.missionService.missionCitiesGet({ search }).pipe(
                    map((cities) =>
                        MissionListActions.LoadCitiesSuccess({ cities })
                    ),
                    catchError((httpResponse) =>
                        of(
                            MissionListActions.LoadCitiesFail({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private missionService: MissionService,
        private fileUploadDialogService: FileUploadDialogService,
        private missionFormService: MissionFormService
    ) {}
}
