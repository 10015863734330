import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderByValueDesc',
    standalone: true,
})
export class OrderByValueDescPipe implements PipeTransform {
    transform(object: any): any {
        if (!object) {
            return [];
        }

        return Object.entries(object)
            .sort((a, b) => {
                const valueA = typeof a[1] === 'number' ? a[1] : Number(a[1]);
                const valueB = typeof b[1] === 'number' ? b[1] : Number(b[1]);

                return valueB - valueA;
            })
            .map((entry) => ({ key: entry[0], value: entry[1] }));
    }
}
