<button
    [matTooltip]="tooltip"
    [ngClass]="{
        'bg-primary hover:bg-primary-100': color === 'primary',
        'bg-warn hover:bg-warn-100': color === 'warn',
        'bg-tertiary hover:bg-tertiary-100': color === 'tertiary'
    }"
    class="flex p-1 rounded-lg transition-all duration-100 group/button"
>
    <mat-icon
        [ngClass]="{
            'group-hover/button:text-primary ': color === 'primary',
            'group-hover/button:text-warn': color === 'warn',
            'group-hover/button:text-tertiary': color === 'tertiary'
        }"
        class="text-white"
        [svgIcon]="icon"
    ></mat-icon>
</button>
