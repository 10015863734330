import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkClassicProgressBar } from '@bslk/components/progress-bar/classic-progress-bar/classic-progress-bar.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ClassicAvatarComponent } from 'app/shared/components/avatar/classic/classic-avatar.component';
import { DonationCampaignActions } from 'app/store/donation/donation-campaign/donation-campaign.action';
import { DonationCampaignSelectors } from 'app/store/donation/donation-campaign/donation-campaign.selectors';

@Component({
    selector: 'donation-campaign-requests-list',
    templateUrl: './requests-list.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkClassicProgressBar,
        BslkTagButtonComponent,
        DatePipe,
        ClassicAvatarComponent,
        MatIconModule,
        LetDirective,
        NgFor,
        NgIf,
        TranslocoModule,
    ],
})
export class DonationCampaignRequestsListComponent {
    isLoading$ = this.store.select(DonationCampaignSelectors.selectIsLoading);

    requests$ = this.store.select(
        DonationCampaignSelectors.selectDisplayedRequests
    );

    canShowMore$ = this.store.select(
        DonationCampaignSelectors.selectCanDisplayMoreRequests
    );
    canShowLess$ = this.store.select(
        DonationCampaignSelectors.selectCanDisplayLessRequests
    );

    selectRequestsCount$ = this.store.select(
        DonationCampaignSelectors.selectRequestsCount
    );

    constructor(private store: Store) {}

    showMore() {
        this.store.dispatch(DonationCampaignActions.showMoreRequests());
    }

    showLess() {
        this.store.dispatch(DonationCampaignActions.showLessRequests());
    }
}
