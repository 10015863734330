import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormArray, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkSliderComponent } from '@bslk/components/form/slider/slider.component';
import { BslkCardToggleComponent } from '@bslk/components/form/toggle/card/card-toggle.component';
import { BslkInfoComponent } from '@bslk/components/info/info.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { TranslocoModule } from '@ngneat/transloco';
import { MissionFormService } from 'app/shared/services/form/mission-form.service';
import {
    fromTodayDatesFilter,
    weekendsDatesFilter,
} from 'app/shared/utils/date-helpers.utils';
import { DateTime } from 'luxon';
import { CreateMissionCalendarComponent } from './calendar/create-mission-calendar.component';

@Component({
    selector: 'create-mission-step-1',
    standalone: true,
    templateUrl: './create-mission-step-1.component.html',
    imports: [
        AsyncPipe,
        BslkCardToggleComponent,
        BslkInfoComponent,
        BslkSliderComponent,
        CreateMissionCalendarComponent,
        FuseAlertComponent,
        FuseScrollbarDirective,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatTooltipModule,
        NgFor,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class CreateMissionStep1Component {
    constructor(private missionFormService: MissionFormService) {}

    get formGroup() {
        return this.missionFormService.getFormGroup;
    }

    get missionSlotsFormArray() {
        return this.formGroup.controls.missionSlots as FormArray;
    }

    get isDateSelectionVolunteerBased() {
        return this.formGroup.controls.isDateSelectionVolunteerBased.value;
    }

    get displayNumberOfSpotsPerSlot() {
        return (
            !this.isDateSelectionVolunteerBased ||
            this.formGroup.controls.isTeamBuilding.value
        );
    }

    getDatesFilter(date: DateTime) {
        return weekendsDatesFilter(date) && fromTodayDatesFilter(date);
    }

    slotTypeChange(isDateSelectionVolunteerBased: boolean) {
        if (isDateSelectionVolunteerBased) {
            this.missionSlotsFormArray.clear();
        }
    }
}
