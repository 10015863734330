import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BslkBadgeComponent } from '@bslk-business/components/badge/badge.component';
import { TranslocoModule } from '@ngneat/transloco';
import { BadgeViewModel } from 'app/api';
import { BaseDialogComponent } from '../base/base-dialog.component';

export type NewBadgeDialogInput = {
    badges: BadgeViewModel[];
};

@Component({
    selector: 'new-badge-dialog',
    standalone: true,
    templateUrl: './new-badge-dialog.component.html',
    imports: [
        BaseDialogComponent,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        TranslocoModule,
        BslkBadgeComponent,
        NgIf,
    ],
})
export class NewBadgeDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<NewBadgeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: NewBadgeDialogInput
    ) {}

    currentBadgeIndex = 0;

    get currentBadge() {
        return this.data.badges[this.currentBadgeIndex];
    }

    get badgeLength() {
        return this.data.badges.length;
    }

    close() {
        this.dialogRef.close();
    }
}
