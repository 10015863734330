/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EReward = 'ProfileInformationFilled' | 'MissionRatingPosted' | 'CourseChapterSectionRead' | 'CourseChapterQuestionCorrectlyAnswered' | 'CourseChapterSectionCommentPosted' | 'CourseTrophyChapterCompleted' | 'DualQuizChallengeWon' | 'DualQuizChallengeLost' | 'DualQuizChallengeTied';

export const EReward = {
    ProfileInformationFilled: 'ProfileInformationFilled' as EReward,
    MissionRatingPosted: 'MissionRatingPosted' as EReward,
    CourseChapterSectionRead: 'CourseChapterSectionRead' as EReward,
    CourseChapterQuestionCorrectlyAnswered: 'CourseChapterQuestionCorrectlyAnswered' as EReward,
    CourseChapterSectionCommentPosted: 'CourseChapterSectionCommentPosted' as EReward,
    CourseTrophyChapterCompleted: 'CourseTrophyChapterCompleted' as EReward,
    DualQuizChallengeWon: 'DualQuizChallengeWon' as EReward,
    DualQuizChallengeLost: 'DualQuizChallengeLost' as EReward,
    DualQuizChallengeTied: 'DualQuizChallengeTied' as EReward
};

