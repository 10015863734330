<div class="mx-1" [class.cursor-pointer]="!isReadonly">
    <mat-icon
        *ngIf="!isFilled"
        class="flex"
        [class.icon-size-5]="size === 'small'"
        [svgIcon]="'heroicons_outline:star'"
    ></mat-icon>
    <mat-icon
        *ngIf="isFilled"
        [class.icon-size-5]="size === 'small'"
        class="text-warn flex"
        [svgIcon]="'heroicons_solid:star'"
    ></mat-icon>
</div>
