import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    BadgeViewModel,
    CourseChapterViewModel,
    CourseUserReviewViewModel,
} from 'app/api';

export const CourseActions = createActionGroup({
    source: 'Course',
    events: {
        'Load Chapter Request': props<{
            chapterId: string;
        }>(),
        'Load Chapter Request Success': props<{
            chapter: CourseChapterViewModel;
        }>(),
        'Load Chapter Request Fail': props<{ error: string }>(),
        'Next Section': props<{ isLastSection: boolean }>(),
        'Go To Section': props<{ sectionIndex: number }>(),

        'Update Chapter Completion Request Success': props<{
            badges: BadgeViewModel[];
        }>(),
        'Update Chapter Completion Request Fail': props<{ error: string }>(),

        'Send Answer Request': props<{
            sectionId: string;
            isCorrect: boolean;
        }>(),
        'Send Answer Request Success': props<{ badges: BadgeViewModel[] }>(),
        'Send Answer Request Fail': props<{ error: string }>(),

        'Send Survey Answer Request': props<{
            sectionId: string;
            answer: string;
            hasFoundAllAnswers: boolean;
        }>(),
        'Send Survey Answer Request Success': props<{
            badges: BadgeViewModel[];
        }>(),
        'Send Survey Answer Request Fail': props<{ error: string }>(),

        'Load Course Review Request': props<{ courseId: string }>(),
        'Load Course Review Request Success': props<{
            review: CourseUserReviewViewModel;
        }>(),
        'Load Course Review Request Fail': props<{ error: string }>(),

        'Send Course Rating Request': props<{
            rating: number;
            comment: string;
        }>(),
        'Send Course Rating Request Success': emptyProps(),
        'Send Course Rating Request Fail': props<{ error: string }>(),
    },
});
