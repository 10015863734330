import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserNotificationService } from 'app/api';
import { SnackAlertService } from 'app/layout/common/snack-alerts/snack-alert.service';
import { catchError, exhaustMap, map, mergeMap, of } from 'rxjs';
import { AlertActions } from '../alert/alert.actions';
import { UserActions } from '../user/user/user.actions';
import { NotificationActions } from './notification.actions';

@Injectable()
export class NotificationEffects {
    initNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.signInSuccess, UserActions.alreadySignedIn),
            map(() => NotificationActions.loadNotificationsRequest())
        )
    );

    loadNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.loadNotificationsRequest),
            exhaustMap(() =>
                this.userNotificationService.userNotificationGet({}).pipe(
                    map((response) =>
                        NotificationActions.loadNotificationsRequestSuccess({
                            response,
                        })
                    ),
                    catchError(() =>
                        of(
                            NotificationActions.loadNotificationsRequestFailure()
                        )
                    )
                )
            )
        )
    );

    markAsRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.markAllAsRead),
            mergeMap(() =>
                this.userNotificationService.userNotificationPut().pipe(
                    map(() => AlertActions.doNotDisplayError()),
                    catchError(() => of(AlertActions.displayGenericError()))
                )
            )
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.deleteNotification),
            mergeMap(({ id }) =>
                this.userNotificationService
                    .userNotificationIdDelete({ id })
                    .pipe(
                        map(() => AlertActions.doNotDisplayError()),
                        catchError(() => of(AlertActions.displayGenericError()))
                    )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private userNotificationService: UserNotificationService,
        private snackAlertService: SnackAlertService
    ) {}
}
