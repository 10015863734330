<company-csr-approach-card
    (editing)="onEdit($event)"
    (hiding)="onHiding($event)"
    [isLoading]="isLoading"
    [isCompanyAdmin]="isCompanyAdmin"
    [isHidden]="csrApproach?.hideValues"
    [isEditing]="isEditing"
    [title]="'csrapproach.our-values' | transloco"
>
    <div class="flex items-center gap-2">
        <mat-icon
            *ngIf="hasPreviousPage"
            (click)="previousPage()"
            class="icon-size-7 cursor-pointer transition-all hover:scale-110"
            [svgIcon]="'heroicons_solid:chevron-left'"
        ></mat-icon>
        <div
            class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 w-full"
        >
            <div
                *ngIf="isEditing"
                (click)="addValue()"
                class="col-span-1 rounded-xl cursor-pointer bg-gray-100 text-gray-500 flex flex-col items-center justify-center gap-5 min-h-40"
            >
                <span class="text-sm">{{
                    "csrapproach.new-value" | transloco
                }}</span>
                <div class="flex items-center gap-2 text-2xl">
                    <mat-icon [svgIcon]="'heroicons_solid:plus'"></mat-icon>
                    <span class="font-extrabold">
                        {{ "csrapproach.add" | transloco }}</span
                    >
                </div>
            </div>
            <div
                class="col-span-1 bg-opacity-60 rounded-2xl shadow-md min-h-40 p-4 flex flex-col gap-2 text-center relative"
                [ngClass]="{
                    'bg-warn': index % this.valuesPerPage === 0,
                    'bg-primary': index % this.valuesPerPage === 1,
                    'bg-tertiary': index % this.valuesPerPage === 2
                }"
                *ngFor="let value of displayedValues; let index = index"
            >
                <div
                    *ngIf="isEditing"
                    class="absolute bottom-2 right-2 flex items-center gap-2"
                >
                    <bslk-icon-button
                        (click)="editValue(index)"
                        [matTooltip]="'csrapproach.edit' | transloco"
                        [icon]="'heroicons_solid:pencil'"
                    ></bslk-icon-button>
                    <bslk-icon-button
                        (click)="removeValue(index)"
                        [matTooltip]="'csrapproach.remove' | transloco"
                        [icon]="'heroicons_solid:x-mark'"
                    ></bslk-icon-button>
                </div>
                <span
                    class="font-extrabold text-3xl md:text-2xl lg:text-3xl xl:text-2xl xxl:text-3xl truncate"
                    >{{ value.name }}</span
                >
                <div class="flex flex-col grow items-center justify-center">
                    <span>{{ value.description }}</span>
                </div>
            </div>
        </div>
        <mat-icon
            *ngIf="hasNextPage"
            (click)="nextPage()"
            class="icon-size-7 cursor-pointer transition-all hover:scale-110"
            [svgIcon]="'heroicons_solid:chevron-right'"
        ></mat-icon>
    </div>
</company-csr-approach-card>

<company-csr-approach-edit-value
    (close)="drawerClosed()"
    [isCreating]="isCreating"
    [editedValueIndex]="editedValueIndex"
></company-csr-approach-edit-value>
