import { GoogleSigninButtonModule } from '@abacritt/angularx-Social-login';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ELoginType, EUserRole } from 'app/api';
import { AuthService } from 'app/core/auth/auth.service';
import { CustomValidators } from 'app/shared/utils/custom-validators.utils';
import { addRequiredValidatorToAll } from 'app/shared/utils/form-helpers.utils';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { environment } from 'environments/environment';

@Component({
    selector: 'sign-up-auth',
    standalone: true,
    encapsulation: ViewEncapsulation.Emulated,
    animations: fuseAnimations,
    templateUrl: './sign-up-auth.component.html',
    imports: [
        AsyncPipe,
        FormsModule,
        FuseAlertComponent,
        FuseCardComponent,
        GoogleSigninButtonModule,
        LetDirective,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        NgIf,
        NgTemplateOutlet,
        ReactiveFormsModule,
        RouterLink,
        TranslocoModule,
    ],
})
export class SignupAuthComponent implements OnInit {
    @Input() userRole: EUserRole;

    enableGoogleSso = environment.enableGoogleSso;

    signUpError$ = this.store.select(UserSelectors.selectSignUpError);

    signUpFormGroup = this.fb.group({
        email: '',
        password: '',
        firstName: '',
        familyName: '',
    });

    constructor(
        private store: Store,
        private authService: AuthService,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.authService.setIsRegistering(true, this.userRole);
    }

    signUpWithMicrosoft() {
        this.authService.tryMicrosoftLogin('sign-up');
    }

    signUpWithLinkedIn(): void {
        this.authService.trylinkedInLogin({ operationType: 'sign-up' });
    }

    signUp() {
        // Dynamically add validators to not block stepper if user signs up via SSO
        this.signUpFormGroup.controls.email.setValidators(Validators.email);
        this.signUpFormGroup.controls.password.setValidators(
            CustomValidators.password
        );
        addRequiredValidatorToAll(this.signUpFormGroup);

        this.signUpFormGroup.controls.password.updateValueAndValidity();
        if (this.signUpFormGroup.valid) {
            this.authService.trySignUpWithEmail({
                email: this.signUpFormGroup.controls.email.value,
                password: this.signUpFormGroup.controls.password.value,
                firstName: this.signUpFormGroup.controls.firstName.value,
                familyName: this.signUpFormGroup.controls.familyName.value,
                loginType: ELoginType.Classic,
                userRole: this.userRole,
            });
        }
    }
}
