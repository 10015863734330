<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [transparentOverlay]="false"
    [name]="'legalDocumentsDrawer'"
    [position]="'right'"
    #legalDocumentsDrawer
>
    <file-manager-details
        class="w-full"
        *ngIf="selectedItem$ | async"
        [item]="selectedItem$ | async"
    ></file-manager-details>
</fuse-drawer>
