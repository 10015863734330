<ng-container *ngIf="!hasAnswered; else answered">
    <p class="text-xl mb-3">{{ section.trueFalseQuestion.question }}</p>

    <div class="flex gap-5">
        <button
            (click)="onAnswer(true)"
            class="w-full mt-3 shadow-light bg-tertiary shadow-tertiary-300 transition-all px-4 md:px-16 py-4 rounded-full flex text-center mx-auto hover:shadow-none transform hover:translate-x-1 hover:translate-y-1"
        >
            <span class="w-full text-xl font-bold text-white">{{
                "coursesection.true" | transloco
            }}</span>
        </button>
        <button
            (click)="onAnswer(false)"
            class="w-full mt-3 shadow-light bg-tertiary shadow-tertiary-300 transition-all px-4 md:px-16 py-4 rounded-full flex text-center mx-auto hover:shadow-none transform hover:translate-x-1 hover:translate-y-1"
        >
            <span class="w-full text-xl font-bold text-white">{{
                "coursesection.false" | transloco
            }}</span>
        </button>
    </div>
</ng-container>

<ng-template #answered>
    <div class="flex justify-center">
        <div
            *ngIf="isCorrect"
            class="w-24 h-24 bg-quaternary rounded-full border-b-8 border-quaternary-900 shadow-xl flex items-center justify-center"
        >
            <mat-icon
                [svgIcon]="'heroicons_outline:check'"
                class="text-white icon-size-12"
            ></mat-icon>
        </div>
    </div>
    <div class="flex justify-center">
        <div
            *ngIf="!isCorrect"
            class="w-24 h-24 bg-primary rounded-full border-b-8 border-primary-900 shadow-xl flex items-center justify-center"
        >
            <mat-icon
                [svgIcon]="'heroicons_outline:x-mark'"
                class="text-white icon-size-12"
            ></mat-icon>
        </div>
    </div>

    <div class="my-5">
        <span class="font-bold">{{
            "coursesection." +
                (isCorrect ? "right" : "wrong") +
                "." +
                randomNum.toString() | transloco
        }}</span
        ><span>&nbsp;{{ section.trueFalseQuestion.explications }}</span>
    </div>

    <next-chapter-section-button
        *ngrxLet="isLastSection$ as isLastSection"
        (click)="nextSection(isLastSection)"
        [text]="'coursesection.continue' | transloco"
    ></next-chapter-section-button>
</ng-template>
