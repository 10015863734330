<mission-manage *ngrxLet="mission$ as mission" class="w-full">
    <div headerActions>
        <button
            *ngIf="canCancelParticipation$ | async"
            (click)="cancel(mission?.isTeamBuilding)"
            mat-flat-button
            class="bg-warn text-white"
        >
            <mat-icon
                class="icon-size-5 text-current"
                [svgIcon]="'heroicons_outline:x-mark'"
            ></mat-icon>
            <span class="ml-1">{{
                "missionmanage.cancel-" +
                    (mission?.isTeamBuilding
                        ? "team-building"
                        : "participation") | transloco
            }}</span>
        </button>
    </div>

    <mission-post-rating class="mt-6 w-full"></mission-post-rating>

    <div class="w-full" rightColumn>
        <div *ngIf="mission?.additionalInformation" class="mb-6">
            <bslk-classic-card
                [title]="'missionmanage.additional-informations' | transloco"
                >{{ mission.additionalInformation }}</bslk-classic-card
            >
        </div>
    </div>

    <div
        *ngrxLet="isLoading$ as isLoading"
        leftColumn
        class="flex flex-col gap-6"
    >
        <div>
            <mission-manage-state
                *ngIf="!isLoading; else skeleton"
            ></mission-manage-state>
        </div>
        <div *ngIf="!isLoading; else skeleton">
            <mission-responsible-user
                [mission]="mission"
            ></mission-responsible-user>
        </div>
    </div>
</mission-manage>

<ng-template #skeleton>
    <ngx-skeleton-loader
        [theme]="{ height: '100%' }"
        class="w-full h-48 mb-8"
    ></ngx-skeleton-loader>
</ng-template>
