import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import {
    DonationCampaignCustomizeDialogComponent,
    DonationCampaignCustomizeDialogInput,
} from './customize-dialog.component';

@Injectable({ providedIn: 'root' })
export class DonationCampaignCustomizeDialogService implements IDialogService {
    private dialogRef: MatDialogRef<DonationCampaignCustomizeDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open(input: DonationCampaignCustomizeDialogInput) {
        this.dialogRef = this.dialog.open(
            DonationCampaignCustomizeDialogComponent,
            {
                data: input,
            }
        );
    }

    close() {
        this.dialogRef.close();
    }
}
