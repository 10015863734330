import { on } from '@ngrx/store';
import { produce } from 'immer';
import { createRessourceListReducer } from '../../shared/ressource-list/ressource-list-reducer-creator';
import { ressourceListInitialState } from '../../shared/ressource-list/ressource-list-state';
import { ProjectListActions } from './project-list.actions';
import { ProjectListState } from './project-list.state';

export const initialState: ProjectListState = {
    ...ressourceListInitialState,
    regions: [],
    isLoadingRegions: false,
    isLoadingImages: false,
};

const additionalActions = [
    on(ProjectListActions.PublishRequest, (state: ProjectListState, { id }) => {
        return {
            ...state,
            selectedItemId: id,
        };
    }),
    on(ProjectListActions.LoadRegions, (state: ProjectListState) => {
        return {
            ...state,
            regions: [],
            isLoadingRegions: true,
        };
    }),
    on(
        ProjectListActions.LoadRegionsSuccess,
        (state: ProjectListState, { regions }) => {
            return {
                ...state,
                regions,
                isLoadingRegions: false,
            };
        }
    ),
    on(ProjectListActions.LoadRegionsFail, (state: ProjectListState) => {
        return {
            ...state,
            isLoadingRegions: false,
        };
    }),

    on(ProjectListActions.AddImage, (state: ProjectListState) => {
        return {
            ...state,
            isLoadingImages: true,
        };
    }),

    on(
        ProjectListActions.AddImageSuccess,
        (state: ProjectListState, { projectId, image }) =>
            produce(state, (draftState) => {
                draftState.isLoadingImages = false;
                draftState.data
                    .find((project) => project.id === projectId)
                    .images.push(image);
            })
    ),

    on(
        ProjectListActions.RemoveImage,
        (state: ProjectListState, { parentId, id }) =>
            produce(state, (draftState) => {
                draftState.isLoadingImages = true;
                draftState.data.find(
                    (project) => project.id === parentId
                ).images = state.data
                    .find((project) => project.id === parentId)
                    .images.filter((image) => image.id !== id);
            })
    ),

    on(ProjectListActions.RemoveImageSuccess, (state: ProjectListState) => {
        return {
            ...state,
            isLoadingImages: false,
        };
    }),

    on(ProjectListActions.UpdateImageFail, (state: ProjectListState) => {
        return {
            ...state,
            isLoadingImages: false,
        };
    }),
];

export const projectListReducer = createRessourceListReducer(
    ressourceListInitialState,
    ProjectListActions,
    additionalActions
);
