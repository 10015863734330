import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import {
    Component,
    Inject,
    Input,
    LOCALE_ID,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import en from 'apexcharts/dist/locales/en.json';
import fr from 'apexcharts/dist/locales/fr.json';
import { isNil } from 'lodash-es';
import {
    ApexAxisChartSeries,
    ApexOptions,
    ChartComponent,
    NgApexchartsModule,
} from 'ng-apexcharts';

@Component({
    selector: 'bslk-chart',
    templateUrl: './bslk-chart.component.html',
    standalone: true,
    imports: [NgApexchartsModule, AsyncPipe, NgIf, JsonPipe],
})
export class BslkChartComponent implements OnChanges {
    @ViewChild('chart') chart: ChartComponent;

    @Input() options: Partial<ApexOptions>;
    @Input() series: ApexAxisChartSeries;
    @Input() labels: string[];
    chartOptions: Partial<ApexOptions>;
    isReady = false;

    constructor(@Inject(LOCALE_ID) private locale: string) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!isNil(changes.series?.currentValue)) {
            this.chartOptions = this.buildOptions();
            this.isReady = true;
        }

        if (changes.options && !changes.options.firstChange) {
            this.chartOptions = this.buildOptions();
            this.chart?.updateOptions(this.chartOptions);
        }
    }

    download(type: 'csv' | 'png' | 'svg') {
        const ctx = (this.chart as any).chartObj.ctx;
        switch (type) {
            case 'csv':
                ctx.exports.exportToCSV({
                    series: this.series,
                    columnDelimiter: ',',
                });
                break;
            case 'png':
                ctx.exports.exportToPng(ctx);
                break;
            case 'svg':
                ctx.exports.exportToSVG(ctx);
                break;
        }
    }

    buildOptions(): Partial<ApexOptions> {
        return {
            chart: {
                locales: [en, fr],
                defaultLocale: this.locale,

                type: this.options?.chart?.type ?? 'area',
                height: '100%',
                width: '100%',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: this.options?.colors,
            dataLabels: this.options?.dataLabels ?? {},
            stroke: {
                curve: 'smooth',
                colors: this.options?.stroke?.colors,
            },
            xaxis: {
                labels: {
                    show: this.options?.xaxis?.labels?.show,
                    style: this.options?.xaxis?.labels?.style ?? {},
                },
                categories: this.options?.xaxis?.categories ?? {},
            },
            yaxis: this.options?.yaxis ?? {},
            legend: {
                show: this.options?.legend?.show,
                position: this?.options?.legend?.position ?? 'bottom',
                formatter: this?.options?.legend?.formatter,
            },
            grid: this.options?.grid ?? {},
            plotOptions: this.options?.plotOptions ?? {},
            fill: this.options?.fill ?? {},
            responsive: this.options?.responsive ?? [],
            tooltip: this.options?.tooltip ?? {},
            labels: this.labels ?? [],
            series: this.series,
        };
    }
}
