<div class="p-4 md:p-6 xxl:p-8 w-full">
    <div class="flex justify-between mb-6">
        <div class="flex flex-col gap-1">
            <span class="text-3xl font-bold">{{
                "setup.title" | transloco
            }}</span>
            <span class="text-lg">{{ "setup.subtitle" | transloco }}</span>
        </div>
    </div>

    <div class="grid gap-4">
        <administrative-contact></administrative-contact>
        <company-employees></company-employees>
    </div>
</div>
