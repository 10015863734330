<div [formGroup]="formGroup" class="flex flex-col gap-4">
    <!-- Donation impact -->
    <div *ngIf="isSuperAdmin$ | async" class="w-full gap-1">
        <mat-form-field floatLabel="always" class="w-full">
            <mat-label>{{
                "createproject.basilik-comment" | transloco
            }}</mat-label>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'basile-flat'"
                matPrefix
            ></mat-icon>
            <input [formControlName]="'basilikComment'" matInput />
        </mat-form-field>
        <bslk-length-info
            [text]="formGroup.controls.basilikComment.value"
            [maxLength]="350"
        ></bslk-length-info>
    </div>

    <div class="flex items-center gap-2">
        <div class="flex-1 flex items-center gap-1">
            <div class="flex flex-col grow gap-1">
                <bslk-info-label
                    class="font-bold"
                    [tooltip]="
                        'createproject.base-contribution-amount-tooltip'
                            | transloco
                    "
                    >{{
                        "createproject.base-contribution-amount" | transloco
                    }}</bslk-info-label
                >
                <mat-form-field
                    class="grow"
                    [subscriptSizing]="'dynamic'"
                    [floatLabel]="'always'"
                >
                    <input
                        matInput
                        type="number"
                        [formControlName]="'baseContributionAmount'"
                        [placeholder]="
                            'createproject.base-contribution-amount-placeholder'
                                | transloco
                        "
                        required
                    />
                </mat-form-field>
            </div>
            <div class="flex flex-col gap-1">
                <bslk-info-label
                    class="font-bold"
                    [tooltip]="'createproject.currency-tooltip' | transloco"
                    >{{ "createproject.currency" | transloco }}</bslk-info-label
                >
                <mat-form-field
                    *ngIf="translationsLoaded"
                    [subscriptSizing]="'dynamic'"
                >
                    <mat-select [formControl]="formGroup.controls.currency">
                        <mat-option
                            *ngFor="let currency of getEnumValues(ECurrency)"
                            [value]="currency"
                        >
                            {{ "enums.ECurrency." + currency | transloco }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <span class="text-4xl font-bold self-end ml-2">=</span>
        </div>
        <div class="flex-1 flex flex-col gap-1">
            <bslk-info-label
                class="font-bold"
                [tooltip]="
                    'createproject.impact-unit-quantity-tooltip' | transloco
                "
                >{{
                    "createproject.impact-unit-quantity" | transloco
                }}</bslk-info-label
            >
            <mat-form-field
                class="w-full"
                [subscriptSizing]="'dynamic'"
                [floatLabel]="'always'"
            >
                <input
                    matInput
                    type="number"
                    [formControlName]="'impactUnitQuantity'"
                    [placeholder]="
                        'createproject.impact-unit-quantity-placeholder'
                            | transloco
                    "
                    required
                />
            </mat-form-field>
        </div>

        <div class="flex-1 flex flex-col gap-1">
            <bslk-info-label
                class="font-bold"
                [tooltip]="
                    'createproject.impact-unit-description-tooltip' | transloco
                "
                >{{
                    "createproject.impact-unit-description" | transloco
                }}</bslk-info-label
            >
            <mat-form-field
                [subscriptSizing]="'dynamic'"
                [floatLabel]="'always'"
            >
                <input
                    matInput
                    type="text"
                    [formControlName]="'impactUnitDescription'"
                    [placeholder]="
                        'createproject.impact-unit-description-placeholder'
                            | transloco
                    "
                    required
                />
            </mat-form-field>
            <bslk-length-info
                [text]="formGroup.controls.impactUnitDescription.value"
                [maxLength]="100"
            ></bslk-length-info>
        </div>
    </div>

    <div class="flex flex-col gap-1">
        <bslk-info-label
            class="font-bold"
            [tooltip]="
                'createproject.donation-impact-preview-tooltip' | transloco
            "
        >
            {{
                "createproject.donation-impact-preview" | transloco
            }}</bslk-info-label
        >

        <donation-impact
            class="flex"
            [project]="formGroup.value"
        ></donation-impact>
    </div>

    <!-- Purpose of donations -->
    <div class="w-full">
        <bslk-info-label
            class="font-bold"
            [tooltip]="'createproject.purpose-of-donations-tooltip' | transloco"
            >{{
                "createproject.purpose-of-donations" | transloco
            }}</bslk-info-label
        >
        <quill-editor
            class="mt-2 w-full"
            [formControlName]="'purposeOfDonations'"
            [bounds]="'self'"
            [placeholder]="
                'createproject.purpose-of-donations-placeholder' | transloco
            "
            [modules]="quillModules"
        ></quill-editor>
        <small class="text-primary" *ngIf="isPurposeOfDonationsErrorDisplayed">
            {{ "createproject.purpose-of-donations-error" | transloco }}
        </small>
        <bslk-length-info
            [text]="formGroup.controls.purposeOfDonations.value"
            [minLength]="100"
            [maxLength]="1500"
            [isHtml]="true"
        ></bslk-length-info>
    </div>

    <!-- Action zones -->
    <div class="flex flex-col gap-2">
        <bslk-info-label
            [tooltip]="'createproject.action-zone-tooltip' | transloco"
            class="font-bold"
            >{{ "createproject.action-zone" | transloco }}
        </bslk-info-label>

        <location-selection
            [hideLabel]="true"
            [locationType]="'region'"
            (locationSelected)="onLocationSelected($event)"
        ></location-selection>

        <div class="flex flex-wrap gap-2">
            <bslk-tag
                (click)="removeActionZone(locationFormGroup)"
                [isRemovable]="true"
                *ngFor="let locationFormGroup of locationsFormArray.controls"
            >
                {{ locationFormGroup.controls.name.value }}
            </bslk-tag>
        </div>
    </div>
</div>
