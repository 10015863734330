import { AsyncPipe, JsonPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DualQuizMatchUpsActions } from 'app/store/dual-quiz/matchups/matchups.action';
import { DualQuizMatchUpsSelectors } from 'app/store/dual-quiz/matchups/matchups.selectors';
import { NewChallengeDialogService } from './new-challenge-dialog/new-challenge-dialog.service';

@Component({
    selector: 'dual-quiz-header',
    templateUrl: './header.component.html',
    standalone: true,
    imports: [
        MatButtonModule,
        TranslocoModule,
        NgIf,
        NgClass,
        AsyncPipe,
        LetDirective,
        JsonPipe,
    ],
})
export class DualQuizHeaderComponent implements OnInit {
    constructor(
        private store: Store,
        private newChallengeDialogService: NewChallengeDialogService
    ) {}

    isLoading$ = this.store.select(
        DualQuizMatchUpsSelectors.selectIsLoadingResults
    );

    results$ = this.store.select(DualQuizMatchUpsSelectors.selectResults);

    percentages$ = this.store.select(
        DualQuizMatchUpsSelectors.selectResultsPercentages
    );

    ngOnInit() {
        this.store.dispatch(DualQuizMatchUpsActions.LoadUserResultsRequest());
    }

    openNewChallengeDialog() {
        this.newChallengeDialogService.open();
    }
}
