<div class="min-w-screen min-h-screen flex flex-col">
    <div class="grow flex flex-col justify-center items-center py-8">
        <img
            class="max-w-48 md:max-w-64 lg:max-w-80"
            src="assets/basile-postures/guessing.png"
        />
        <div class="flex flex-col gap-2 justify-center items-center">
            <span class="font-bold text-xl text-warn">{{
                "shared.not-found.page-not-found" | transloco
            }}</span>
            <bslk-tag-button
                (click)="goBack()"
                [icon]="'feather:corner-up-left'"
                >{{ "shared.not-found.go-back" | transloco }}</bslk-tag-button
            >
        </div>
    </div>
    <bslk-footer></bslk-footer>
</div>
