import { NgFor, NgIf } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { CompanyCsrApproachActions } from 'app/store/company/csr-approach/csr-approach.actions';
import { isNil } from 'lodash-es';

@Component({
    selector: 'company-csr-approach-edit-pillar',
    templateUrl: './edit.component.html',
    standalone: true,
    imports: [
        BslkClassicButtonComponent,
        BslkLengthInfoComponent,
        FuseDrawerComponent,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgFor,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class CompanyCsrApproachEditPillarComponent implements OnChanges {
    @ViewChild('editPillarDrawer') drawer: FuseDrawerComponent;

    @Input() isCreating: boolean = false;
    @Input() editedPillarIndex: number;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private formService: CompanyCsrApproachFormService,
        private store: Store
    ) {}

    get formArray() {
        return this.formService.getFormGroup.controls.pillars as FormArray;
    }

    get formGroup(): FormGroup {
        // Return edited pillar form group, or last form group if is creating
        return this.formArray.controls.at(
            this.editedPillarIndex ?? this.formArray.controls.length - 1
        ) as FormGroup;
    }

    get actionsFormArray() {
        return this.formGroup.controls.actions as FormArray;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            !isNil(changes.editedPillarIndex?.currentValue) ||
            changes.isCreating?.currentValue
        ) {
            this.drawer.open();
        }

        if (changes.isCreating?.currentValue) {
            this.formArray.controls.push(this.formService.createPillar());
        }
    }

    openedChanged(isOpened: boolean) {
        if (!isOpened) {
            if (this.isCreating) {
                // Remove non-validated created form array
                this.formArray.controls.pop();
            }

            this.close.emit();
            this.isCreating = false;
            this.editedPillarIndex = null;
        }
    }

    update() {
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
        this.isCreating = false;
        this.editedPillarIndex = null;
        this.drawer.close();
    }

    addAction() {
        this.actionsFormArray.push(this.formService.createAction());
    }

    removeAction(index: number) {
        this.actionsFormArray.removeAt(index);
    }
}
