import {
    AsyncPipe,
    JsonPipe,
    NgIf,
    NgSwitch,
    NgSwitchCase,
} from '@angular/common';
import { Component, Input } from '@angular/core';
import { BslkPageContentComponent } from '@bslk/components/dynamic-content/page/content/content.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ECourseSectionType } from 'app/api';
import { CourseActions } from 'app/store/course/course/course.action';
import { CourseSelectors } from 'app/store/course/course/course.selectors';
import { ChapterCardAnecdoteSectionComponent } from './anecdote/anecdote.component';
import { NextChapterSectionButtonComponent } from './next-button/next-button.component';
import { SectionMcqQuestionComponent } from './questions/mcq/mcq.component';
import { SectionSurveyComponent } from './questions/survey/survey.component';
import { SectionTrueFalseQuestionComponent } from './questions/true-false/true-false.component';
import { ChapterCardVideoSectionComponent } from './video/video.component';

@Component({
    selector: 'chapter-card',
    templateUrl: './card.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkPageContentComponent,
        ChapterCardAnecdoteSectionComponent,
        JsonPipe,
        LetDirective,
        NextChapterSectionButtonComponent,
        NgIf,
        NgSwitch,
        NgSwitchCase,
        TranslocoModule,
        SectionTrueFalseQuestionComponent,
        SectionMcqQuestionComponent,
        SectionSurveyComponent,
        ChapterCardVideoSectionComponent,
    ],
})
export class ChapterCardComponent {
    @Input() sectionsCount: number = 7;
    @Input() activeSectionIndex: number = 0;

    ECourseSectionType = ECourseSectionType;

    courseTitle$ = this.store.select(
        CourseSelectors.selectActiveChapterCourseTitle
    );
    chapterTitle$ = this.store.select(CourseSelectors.selectActiveChapterTitle);
    activeSection$ = this.store.select(
        CourseSelectors.selectActiveChapterSection
    );
    isLastSection$ = this.store.select(
        CourseSelectors.selectIsActiveChapterSectionLastOfChapter
    );

    constructor(private store: Store) {}

    nextSection(isLastSection: boolean) {
        this.store.dispatch(CourseActions.nextSection({ isLastSection }));
    }
}
