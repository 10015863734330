<bslk-advanced-chart
    *ngrxLet="isExpanded$ as isExpanded"
    (expands)="onExpand($event)"
    (filterChanges)="onFilterChange($event)"
    class="w-full"
    [title]="'companyadmindashboard.kpis.dual-quiz-answers-by-sdg' | transloco"
    [isLoading]="isLoading$ | async"
    [series]="series"
    [labels]="labels"
    [options]="chartOptions"
    [expandOptions]="expandOptions"
    [isExpandable]="true"
    [isDownloadEnabled]="true"
    [isExpanded]="isExpanded"
    [isTimePeriodSelectionEnabled]="true"
    [dateRange]="dateRange$ | async"
>
</bslk-advanced-chart>
