import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { BaseDialogComponent } from '../base/base-dialog.component';

export type ConfirmationDialogInput = {
    textKey: string;
};

@Component({
    selector: 'confirmation-dialog',
    standalone: true,
    templateUrl: './confirmation-dialog.component.html',
    imports: [
        BslkClassicButtonComponent,
        BaseDialogComponent,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        FuseAlertComponent,
        TranslocoModule,
        TranslocoModule,
    ],
})
export class ConfirmationDialogComponent {
    @Output() onConfirm: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogInput
    ) {}

    confirm() {
        this.onConfirm.emit();
        this.close();
    }

    close() {
        this.dialogRef.close();
    }
}
