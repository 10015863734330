import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { AwarenessCampaignFormService } from 'app/shared/services/form/awareness-campaign-form.service';
import { AwarenessCampaignActions } from 'app/store/awareness/awareness-campaign/awareness-campaign.action';
import { AwarenessCampaignSelectors } from 'app/store/awareness/awareness-campaign/awareness-campaign.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AwarenessCampaignConfigurationComponent } from './configuration/configuration.component';
import { AwarenessCampaignThematicDetailsComponent } from './thematic-details/thematic-details.component';
import { AwarenessCampaignThematicsListComponent } from './thematics-list/thematics-list.component';

@Component({
    selector: 'awareness-campaign',
    templateUrl: './campaign.component.html',
    standalone: true,
    imports: [
        AwarenessCampaignConfigurationComponent,
        AwarenessCampaignThematicDetailsComponent,
        AwarenessCampaignThematicsListComponent,
        BslkClassicButtonComponent,
        NgIf,
        NgxSkeletonLoaderModule,
        LetDirective,
        TranslocoModule,
    ],
})
export class AwarenessCampaignComponent implements OnInit {
    hasTriedSaving = false;

    hasActiveCampaign$ = this.store.select(
        AwarenessCampaignSelectors.selectHasActiveCampaign
    );

    constructor(
        private store: Store,
        private formService: AwarenessCampaignFormService
    ) {}

    get formGroup() {
        return this.formService.getFormGroup;
    }

    get disableSaveButton() {
        return this.formGroup.invalid && this.hasTriedSaving;
    }

    ngOnInit(): void {
        this.store.dispatch(
            AwarenessCampaignActions.loadActiveCampaignRequest()
        );
    }

    validateCampaign() {
        this.hasTriedSaving = true;
        if (this.formGroup.valid) {
            this.hasTriedSaving = false;
            this.store.dispatch(
                AwarenessCampaignActions.createCampaignRequest()
            );
        }
    }

    saveCampaign() {
        this.hasTriedSaving = true;
        if (this.formGroup.valid) {
            this.hasTriedSaving = false;
            this.store.dispatch(
                AwarenessCampaignActions.updateCampaignRequest()
            );
        }
    }
}
