<div
    class="text-xl grid grid-cols-2 xl:flex text-white justify-center items-center gap-2 h-full"
>
    <div
        [ngClass]="{
            'bg-tertiary': color === 'tertiary',
            'bg-warn': color === 'warn',
            'bg-primary': color === 'primary'
        }"
        class="w-14 xl:w-18 p-2 bg-opacity-80 rounded-lg"
    >
        <div class="leading-none text-center">{{ days }}</div>
        <div class="uppercase text-xs leading-none text-center">
            {{ "bslk.countdown.days" | transloco }}
        </div>
    </div>
    <div
        [ngClass]="{
            'bg-tertiary': color === 'tertiary',
            'bg-warn': color === 'warn',
            'bg-primary': color === 'primary'
        }"
        class="w-14 xl:w-18 p-2 bg-opacity-80 rounded-lg"
    >
        <div class="leading-none text-center">{{ hours }}</div>
        <div class="uppercase text-xs leading-none text-center">
            {{ "bslk.countdown.hours" | transloco }}
        </div>
    </div>
    <div
        [ngClass]="{
            'bg-tertiary': color === 'tertiary',
            'bg-warn': color === 'warn',
            'bg-primary': color === 'primary'
        }"
        class="hidden xl:block xl:w-18 p-2 bg-opacity-80 rounded-lg"
    >
        <div class="leading-none text-center">{{ minutes }}</div>
        <div class="uppercase text-xs leading-none text-center">
            {{ "bslk.countdown.minutes" | transloco }}
        </div>
    </div>
    <div
        [ngClass]="{
            'bg-tertiary': color === 'tertiary',
            'bg-warn': color === 'warn',
            'bg-primary': color === 'primary'
        }"
        *ngIf="showSeconds"
        class="w-14 xl:w-18 p-2 bg-opacity-80 rounded-lg"
    >
        <div class="leading-none text-center">{{ seconds }}</div>
        <div class="uppercase text-xs leading-none text-center">
            {{ "bslk.countdown.seconds" | transloco }}
        </div>
    </div>
</div>
