<base-dialog
    (onClose)="close()"
    [title]="'donationcampaigns.customize-title' | transloco"
    [showActions]="true"
>
    <div class="w-full h-full flex flex-col gap-4" body>
        <fuse-alert type="info">
            <span *ngIf="data.isDefaultCustomization">
                {{ "donationcampaigns.defaut-customization" | transloco }}</span
            >
            <span *ngIf="!data.isDefaultCustomization">
                {{
                    "donationcampaigns.specific-customization" | transloco
                }}</span
            >
        </fuse-alert>

        <div class="flex gap-8 justify-center">
            <form [formGroup]="formGroup" class="flex flex-col gap-3 min-w-64">
                <!-- Remove autofocus trick -->
                <div style="max-width: 0; max-height: 0; overflow: hidden">
                    <input autofocus="true" />
                </div>

                <div class="flex flex-col gap-1">
                    <span class="font-bold">
                        {{ "donationcampaigns.logo" | transloco }}
                    </span>
                    <bslk-classic-button
                        class="flex justify-center"
                        [background]="'primary'"
                        (onClick)="openFileUploadDialog()"
                        [icon]="'heroicons_outline:photo'"
                        >{{
                            (formGroup.controls.imageAsBase64.value ||
                            formGroup.controls.imageUrl.value
                                ? "donationcampaigns.edit"
                                : "donationcampaigns.add"
                            ) | transloco
                        }}</bslk-classic-button
                    >
                </div>

                <div class="flex flex-col gap-1">
                    <mat-form-field
                        floatLabel="always"
                        subscriptSizing="dynamic"
                        class="w-full"
                    >
                        <mat-label>{{
                            "donationcampaigns.header-background-color"
                                | transloco
                        }}</mat-label>
                        <input
                            matInput
                            autofocus="false"
                            [formControlName]="'headerBackgroundColor'"
                            type="color"
                        />
                    </mat-form-field>
                    <span
                        (click)="
                            formGroup.controls.headerBackgroundColor.reset()
                        "
                        class="text-sm font-bold text-right cursor-pointer"
                        >{{ "donationcampaigns.reset" | transloco }}</span
                    >
                </div>

                <div class="flex flex-col gap-1">
                    <mat-form-field
                        floatLabel="always"
                        subscriptSizing="dynamic"
                        class="w-full"
                    >
                        <mat-label>{{
                            "donationcampaigns.text-color" | transloco
                        }}</mat-label>
                        <input
                            matInput
                            [formControlName]="'textColor'"
                            type="color"
                        />
                    </mat-form-field>
                    <span
                        (click)="formGroup.controls.textColor.reset()"
                        class="text-sm font-bold text-right cursor-pointer"
                        >{{ "donationcampaigns.reset" | transloco }}</span
                    >
                </div>
                <div class="flex flex-col gap-1">
                    <mat-form-field
                        floatLabel="always"
                        subscriptSizing="dynamic"
                        class="w-full"
                    >
                        <mat-label>{{
                            "donationcampaigns.body-background-color"
                                | transloco
                        }}</mat-label>
                        <input
                            matInput
                            [formControlName]="'bodyBackgroundColor'"
                            type="color"
                        />
                    </mat-form-field>
                    <span
                        (click)="formGroup.controls.bodyBackgroundColor.reset()"
                        class="text-sm font-bold text-right cursor-pointer"
                        >{{ "donationcampaigns.reset" | transloco }}</span
                    >
                </div>
            </form>

            <div class="flex flex-col gap-1 grow min-w-120">
                <span class="font-bold">{{
                    "donationcampaigns.preview" | transloco
                }}</span>
                <div class="border rounded">
                    <div
                        [style]="
                            'backgroundColor:' +
                            formGroup.controls.headerBackgroundColor.value
                        "
                        class="min-h-26 flex justify-center items-center"
                    >
                        <div class="flex gap-2 items-center">
                            <img
                                *ngIf="
                                    formGroup.controls.imageAsBase64.value ||
                                    formGroup.controls.imageUrl.value
                                "
                                class="w-24"
                                [src]="
                                    formGroup.controls.imageAsBase64.value ??
                                    formGroup.controls.imageUrl.value
                                "
                            />
                            <div
                                *ngIf="
                                    formGroup.controls.imageAsBase64.value ||
                                    formGroup.controls.imageUrl.value
                                "
                                [style]="
                                    'borderColor:' +
                                    formGroup.controls.textColor.value
                                "
                                class="border-l border min-h-10"
                            ></div>
                            <span
                                [style]="
                                    'color:' +
                                    formGroup.controls.textColor.value
                                "
                                class="text-xs max-w-50"
                                >Et est admodum mirum videre plebem innumeram
                                mentibus ardore.</span
                            >
                        </div>
                    </div>
                    <div
                        [style]="
                            'backgroundColor:' +
                            formGroup.controls.bodyBackgroundColor.value
                        "
                        class="grow"
                    >
                        <div class="grid grid-cols-4 gap-4 p-4">
                            <div
                                class="rounded-lg flex flex-col overflow-hidden"
                                *ngFor="let _ of [1, 2, 3, 4, 5, 6, 7, 8]"
                            >
                                <div class="h-12 bg-white"></div>
                                <div class="h-8 bg-slate-100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex justify-end" actions>
        <bslk-classic-button
            (click)="submit()"
            [isLoading]="isLoading$ | async"
            >{{ "donationcampaigns.save" | transloco }}</bslk-classic-button
        >
    </div>
</base-dialog>
