import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    CompanyCsrApproachViewModel,
    CompanyMetricViewModel,
    CompanyPillarActionViewModel,
    CompanyPillarViewModel,
    CompanyTimelineDateViewModel,
    CompanyValueViewModel,
    EUnit,
} from 'app/api';
import { CustomValidators } from 'app/shared/utils/custom-validators.utils';
import { DateTime } from 'luxon';
import { IFormService } from './form-service';

@Injectable({
    providedIn: 'root',
})
export class CompanyCsrApproachFormService
    implements IFormService<CompanyCsrApproachViewModel>
{
    private formGroup: FormGroup;

    constructor(protected fb: FormBuilder) {
        this.initForm();
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            id: [null],
            companyId: [null],
            imageUrl: [null],
            title: [null, Validators.maxLength(80)],
            description: [
                null,
                CustomValidators.htmlTextLengthValidator(0, 1000),
            ],
            hidePillars: [false],
            hideValues: [false],
            hideMetrics: [false],
            hideTimeline: [false],
            pillars: this.fb.array([]),
            values: this.fb.array([]),
            metrics: this.fb.array([]),
            timelineDates: this.fb.array([]),
            dateCreated: [DateTime.now().toISO()],
        });
        return this.formGroup;
    }

    createPillar(pillar?: CompanyPillarViewModel): FormGroup {
        return this.fb.group({
            id: [pillar?.id ?? 0],
            name: [pillar?.name ?? null, Validators.required],
            pillar: [pillar?.pillar ?? null],
            description: [pillar?.description ?? null],
            imageUrl: [pillar?.imageUrl ?? null],
            actions: this.fb.array(
                pillar?.actions?.map((action) => this.createAction(action)) ||
                    []
            ),
        });
    }

    createAction(action?: CompanyPillarActionViewModel): FormGroup {
        return this.fb.group({
            id: [action?.id ?? 0],
            name: [
                action?.name ?? null,
                [Validators.required, Validators.maxLength(100)],
            ],
            description: [
                action?.description ?? null,
                Validators.maxLength(250),
            ],
        });
    }

    createValue(value?: CompanyValueViewModel): FormGroup {
        return this.fb.group({
            id: [value?.id ?? 0],
            name: [
                value?.name ?? null,
                [Validators.required, Validators.maxLength(100)],
            ],
            description: [
                value?.description ?? null,
                Validators.maxLength(250),
            ],
        });
    }

    createMetric(metric?: CompanyMetricViewModel): FormGroup {
        return this.fb.group({
            id: [metric?.id ?? 0],
            number: [metric?.number ?? null, Validators.required],
            name: [
                metric?.name ?? null,
                [Validators.required, Validators.maxLength(150)],
            ],
            unit: [metric?.unit ?? EUnit.None, Validators.required],
        });
    }

    createTimelineDate(timelineDate?: CompanyTimelineDateViewModel): FormGroup {
        return this.fb.group({
            id: [timelineDate?.id ?? 0],
            date: [timelineDate?.date ?? null, Validators.required],
            name: [timelineDate?.name ?? null],
            description: [timelineDate?.description ?? null],
        });
    }

    updateForm(entity: CompanyCsrApproachViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
        ['pillars', 'values', 'metrics', 'timelineDates'].forEach((field) => {
            const control = this.formGroup.get(field) as FormArray;
            control.clear();
            entity[field]?.forEach((item: any) => {
                let group: FormGroup;
                switch (field) {
                    case 'pillars':
                        group = this.createPillar(item);
                        break;
                    case 'values':
                        group = this.createValue(item);
                        break;
                    case 'metrics':
                        group = this.createMetric(item);
                        break;
                    case 'timelineDates':
                        group = this.createTimelineDate(item);
                        break;
                }
                control.push(group);
            });
        });
    }

    getEntity(): { [key: string]: CompanyCsrApproachViewModel } {
        this.formGroup.controls.values.updateValueAndValidity();
        this.formGroup.controls.pillars.updateValueAndValidity();

        (this.formGroup.controls.pillars as FormArray).controls.forEach(
            (p: FormGroup) => {
                p.controls.actions.updateValueAndValidity();
            }
        );
        this.formGroup.controls.timelineDates.updateValueAndValidity();
        this.formGroup.controls.metrics.updateValueAndValidity();
        this.formGroup.updateValueAndValidity();

        return {
            companyCsrApproachViewModel: this.formGroup
                .value as CompanyCsrApproachViewModel,
        };
    }

    get getFormGroup() {
        return this.formGroup;
    }
}
