import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconsService {
    /**
     * Constructor
     */
    constructor() {
        const domSanitizer = inject(DomSanitizer);
        const matIconRegistry = inject(MatIconRegistry);

        // Register icon sets
        matIconRegistry.addSvgIconSet(
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/material-twotone.svg'
            )
        );
        matIconRegistry.addSvgIconSetInNamespace(
            'mat_outline',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/material-outline.svg'
            )
        );
        matIconRegistry.addSvgIconSetInNamespace(
            'mat_solid',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/material-solid.svg'
            )
        );
        matIconRegistry.addSvgIconSetInNamespace(
            'feather',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/feather.svg'
            )
        );
        matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_outline',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/heroicons-outline.svg'
            )
        );
        matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_solid',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/heroicons-solid.svg'
            )
        );
        matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_mini',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/heroicons-mini.svg'
            )
        );

        matIconRegistry.addSvgIconSetInNamespace(
            'bslk_solid',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/bslk-solid.svg'
            )
        );

        matIconRegistry.addSvgIconSetInNamespace(
            'bslk_core',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/bslk-core.svg'
            )
        );

        matIconRegistry.addSvgIconSetInNamespace(
            'bslk_thematics',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/bslk-thematics.svg'
            )
        );

        matIconRegistry.addSvgIcon(
            'basile-flat',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/basile-flat.svg'
            )
        );
        matIconRegistry.addSvgIcon(
            'basile-shadow',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/basile-shadow.svg'
            )
        );
        matIconRegistry.addSvgIcon(
            'trophy',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/trophy.svg'
            )
        );

        matIconRegistry.addSvgIcon(
            'basile-leaf',
            domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/basile-leaf.svg'
            )
        );
    }
}
