import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    MissionManageViewModel,
    MissionSlotViewModel,
    MissionTaskViewModel,
} from 'app/api';

export const MissionManageActions = createActionGroup({
    source: 'MissionManage',
    events: {
        'Load Mission Manage Request': props<{
            id: number;
            missionSlotId?: number;
        }>(),
        'Load Mission Manage Request Success': props<{
            mission: MissionManageViewModel;
            missionSlotId?: number;
        }>(),
        'Load Mission Manage Request Fail': props<{ error: string }>(),

        'Select Mission Slot': props<{ id: number }>(),

        'Update Mission Tasks Request': props<{
            tasks: MissionTaskViewModel[];
        }>(),
        'Update Mission Tasks Request Success': props<{
            tasks: MissionTaskViewModel[];
        }>(),
        'Update Mission Tasks Request Fail': props<{ error: string }>(),

        'Update Mission Task Completion Status Request': props<{
            id: number;
            isCompleted: boolean;
        }>(),
        'Update Mission Task Completion Status Request Success': props<{
            id: number;
            isCompleted: boolean;
        }>(),
        'Update Mission Task Completion Status Request Fail': props<{
            error: string;
        }>(),

        'Register Mission Slot Request': props<{ id: number }>(),
        'Register Mission Slot Request Success': props<{
            slotId: number;
            userId: number;
        }>(),
        'Register Mission Slot Request Fail': props<{ error: string }>(),

        'Propose Mission Slot Request': props<{
            missionSlot: MissionSlotViewModel;
        }>(),
        'Propose Mission Slot Request Success': props<{
            slotId: number;
        }>(),
        'Propose Mission Slot Request Fail': props<{ error: string }>(),

        'Unregister Mission Slot Request': emptyProps(),
        'Unregister Mission Slot Request Success': emptyProps(),
        'Unregister Mission Slot Request Fail': props<{ error: string }>(),

        'Accept User In Slot Request': props<{ userId: number }>(),
        'Accept User In Slot Request Success': emptyProps(),
        'Accept User In Slot Request Fail': props<{ error: string }>(),

        'Deny User In Slot Request': props<{ userId: number }>(),
        'Deny User In Slot Request Success': emptyProps(),
        'Deny User In Slot Request Fail': props<{ error: string }>(),

        'Add Team Building User Request': props<{ userId: number }>(),
        'Add Team Building User Request Success': emptyProps(),
        'Add Team Building User Request Fail': props<{ error: string }>(),

        'Remove Team Building User Request': props<{ userId: number }>(),
        'Remove Team Building User Request Success': emptyProps(),
        'Remove Team Building User Request Fail': props<{ error: string }>(),

        'Validate Slot': emptyProps(),
        'Validate Slot Request': props<{ estimatedTimeInHours?: number }>(),
        'Validate Slot Request Success': emptyProps(),
        'Validate Slot Request Fail': props<{ error: string }>(),

        'Accept Validation Slot Request': props<{
            userId: number;
            estimatedTimeInHours?: number;
        }>(),
        'Accept Validation Slot Request Success': emptyProps(),
        'Accept Validation Slot Request Fail': props<{ error: string }>(),

        'Deny Validation Slot Request': props<{ userId: number }>(),
        'Deny Validation Slot Request Success': emptyProps(),
        'Deny Validation Slot Request Fail': props<{ error: string }>(),

        'Delete Mission Slot Request': emptyProps(),
        'Delete Mission Slot Request Success': props<{
            wasOnlySlot: boolean;
        }>(),
        'Delete Mission Slot Request Fail': props<{ error: string }>(),
    },
});
