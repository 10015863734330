// dialog.service.ts

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import { RegularOrAssoDialogComponent } from './regular-or-asso-dialog.component';

@Injectable({ providedIn: 'root' })
export class RegularOrAssoDialogService implements IDialogService {
    private dialogRef: MatDialogRef<RegularOrAssoDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open() {
        this.dialogRef = this.dialog.open(RegularOrAssoDialogComponent);
    }

    close() {
        this.dialogRef.close();
    }
}
