import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkIconButtonComponent } from '@bslk/components/button/icon/icon-button.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { ActionCreator, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { Observable } from 'rxjs';
import { BaseDialogComponent } from '../dialog/base/base-dialog.component';
import { ImageUploadDialogInput } from '../file-upload/file-upload-dialog.component';
import { FileUploadDialogService } from '../file-upload/file-upload-dialog.service';

export type ActionRemoveImage = ActionCreator<
    any,
    (props: { id: number; parentId: number }) => {
        id: number;
        parentId: number;
    } & TypedAction<any>
>;

export interface MediaLibraryItem {
    id: number;
    url: string;
}

export type MediaLibraryDialogInput = {
    maximumImages: number;
    removeImageAction: ActionRemoveImage;
    images: Observable<MediaLibraryItem[]>;
    imageUploadDialogInput: ImageUploadDialogInput;
};

@Component({
    selector: 'media-library-dialog',
    templateUrl: './media-library-dialog.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BaseDialogComponent,
        FuseAlertComponent,
        NgIf,
        LetDirective,
        NgClass,
        NgFor,
        TranslocoModule,
        MatIconModule,
        BslkClassicButtonComponent,
        BslkIconButtonComponent,
    ],
})
export class MediaLibraryDialogComponent implements OnInit {
    loop: number[];

    constructor(
        private store: Store,
        private fileUploadDialogService: FileUploadDialogService,
        private dialogRef: MatDialogRef<MediaLibraryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MediaLibraryDialogInput
    ) {}

    ngOnInit() {
        this.loop = Array.from(
            { length: this.data.maximumImages },
            (_, i) => i
        );
    }

    addImage() {
        this.fileUploadDialogService.open(this.data.imageUploadDialogInput);
    }

    removeImage(id: number) {
        this.store.dispatch(
            this.data.removeImageAction({
                id,
                parentId: this.data.imageUploadDialogInput.id,
            })
        );
    }

    close() {
        this.dialogRef.close();
    }
}
