/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EBlockType = 'Paragraph' | 'Image' | 'Quote' | 'HeadingOne' | 'HeadingTwo' | 'HeadingThree' | 'LinkPreview' | 'BulletListItem' | 'NumberedListItem';

export const EBlockType = {
    Paragraph: 'Paragraph' as EBlockType,
    Image: 'Image' as EBlockType,
    Quote: 'Quote' as EBlockType,
    HeadingOne: 'HeadingOne' as EBlockType,
    HeadingTwo: 'HeadingTwo' as EBlockType,
    HeadingThree: 'HeadingThree' as EBlockType,
    LinkPreview: 'LinkPreview' as EBlockType,
    BulletListItem: 'BulletListItem' as EBlockType,
    NumberedListItem: 'NumberedListItem' as EBlockType
};

