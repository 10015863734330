import { BaseLoginProvider, SocialUser } from '@abacritt/angularx-Social-login';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/api';
import { firstValueFrom, tap } from 'rxjs';
import { AuthenticationOperationType } from '../auth.service';

export type LinkedInLoginProviderSignInConfig = {
    operationType: AuthenticationOperationType;
    extraQueryParams?: string;
};

@Injectable({
    providedIn: 'root',
})
export class LinkedInLoginProvider extends BaseLoginProvider {
    public static readonly PROVIDER_ID: string = 'LINKEDIN';

    private clientId: string = '860a0fiwpo481u';
    private redirectBaseUri: string = window.location.origin;
    private responseType: string = 'code';
    private scope: string = 'profile email openid w_member_social';

    constructor(private authenticationService: AuthenticationService) {
        super();
    }

    initialize(): Promise<void> {
        return Promise.resolve();
    }

    getLoginStatus(): Promise<SocialUser> {
        return Promise.resolve(new SocialUser());
    }

    signIn(options: LinkedInLoginProviderSignInConfig): Promise<SocialUser> {
        const postObservable = this.authenticationService
            .authOauthStatePost()
            .pipe(
                tap((state) => {
                    const authorizationUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=${
                        this.responseType
                    }&client_id=${
                        this.clientId
                    }&redirect_uri=${this.getCallbackUrl(
                        options
                    )}&scope=${encodeURIComponent(this.scope)}&state=${state}`;
                    window.location.href = authorizationUrl;
                })
            );

        // Convertir l'Observable en Promise pour attendre le premier résultat émis
        return firstValueFrom(postObservable)
            .then(() => {
                // Supposons que vous devez gérer le résultat de l'authentification ici
                return new Promise<SocialUser>((resolve, reject) => {
                    // Exemple de résolution avec un utilisateur fictif
                    resolve(new SocialUser()); // Remplacer par une logique adéquate
                });
            })
            .catch((error) => {
                console.error(
                    "Erreur lors de l'enregistrement de l'état LinkedIn:",
                    error
                );
                throw error;
            });
    }

    signOut(): Promise<any> {
        return Promise.resolve();
    }

    getCallbackUrl(options: LinkedInLoginProviderSignInConfig): string {
        if (
            options.operationType === 'sign-in' ||
            options.operationType === 'sign-up'
        ) {
            return `${this.redirectBaseUri}/linkedin-${options.operationType}-callback`;
        }
        if (options.operationType === 'share-certificate') {
            return `${this.redirectBaseUri}/share-certificate?${options.extraQueryParams}`;
        }
    }
}
