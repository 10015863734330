import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Subject, takeUntil } from 'rxjs';
import { CourseDashboardExperienceBarComponent } from './experience-bar/experience-bar.component';
import { CourseDashboardMyBadgesComponent } from './my-badges/my-badges.component';
import { CourseDashboardRankingComponent } from './ranking/ranking.component';
import { CourseDashboardThematicHeader } from './thematic-timeline/header/header.component';
import { CourseDashboardThematicTimeline } from './thematic-timeline/thematic-timeline.component';
import { CourseDashboardTokenComponent } from './thematic-timeline/tokens/token/token.component';

@Component({
    selector: 'course-dashboard',
    templateUrl: './course-dashboard.component.html',
    standalone: true,
    imports: [
        MatIconModule,
        CourseDashboardTokenComponent,
        CourseDashboardThematicHeader,
        CourseDashboardThematicTimeline,
        CourseDashboardRankingComponent,
        CourseDashboardMyBadgesComponent,
        CourseDashboardExperienceBarComponent,
    ],
})
export class CourseDashboardComponent implements OnInit, OnDestroy {
    unsubscribeAll: Subject<any> = new Subject<any>();
    lastMatchingAliases: string[];

    constructor(
        private mediaWatcherService: FuseMediaWatcherService,
        private navigationService: FuseNavigationService
    ) {}

    ngOnInit(): void {
        this.mediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.lastMatchingAliases = matchingAliases;
                this.checkIfShouldOpenNavigation();
            });

        this.navigationService
            .onComponentRegistered()
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                this.checkIfShouldOpenNavigation();
            });
    }

    checkIfShouldOpenNavigation() {
        const navigation = this.getMainNavigation();
        if (navigation && this.lastMatchingAliases.includes('md')) {
            navigation.open();
        }
    }

    getMainNavigation() {
        return this.navigationService.getComponent<FuseVerticalNavigationComponent>(
            'mainNavigation'
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
