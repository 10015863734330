import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkIconButtonComponent } from '@bslk/components/button/icon/icon-button.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DualQuizChallengeMatchUpResultViewModel } from 'app/api';
import { BasileAvatarComponent } from 'app/shared/components/avatar/basile/basile-avatar.component';
import { DualQuizMatchUpsActions } from 'app/store/dual-quiz/matchups/matchups.action';
import { DualQuizMatchUpsSelectors } from 'app/store/dual-quiz/matchups/matchups.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NewChallengeDialogService } from '../header/new-challenge-dialog/new-challenge-dialog.service';

@Component({
    selector: 'dual-quiz-challenge-match-ups',
    templateUrl: './match-ups.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkIconButtonComponent,
        LetDirective,
        TranslocoModule,
        MatPaginatorModule,
        MatTooltipModule,
        BasileAvatarComponent,
        NgClass,
        MatTooltipModule,
        MatIconModule,
        NgFor,
        NgIf,
        BslkTagComponent,
        NgxSkeletonLoaderModule,
    ],
})
export class DualQuizChallengeMatchUpsComponent implements OnInit {
    isLoading$ = this.store.select(DualQuizMatchUpsSelectors.selectIsLoading);
    matchUps$ = this.store.select(DualQuizMatchUpsSelectors.selectData);
    pageIndex$ = this.store.select(DualQuizMatchUpsSelectors.selectPageIndex);
    totalPages$ = this.store.select(DualQuizMatchUpsSelectors.selectTotalPages);
    totalCount$ = this.store.select(DualQuizMatchUpsSelectors.selectTotalCount);
    pageSize$ = this.store.select(DualQuizMatchUpsSelectors.selectPageSize);

    constructor(
        private store: Store,
        private startChallengeDialog: NewChallengeDialogService
    ) {}

    ngOnInit() {
        this.store.dispatch(DualQuizMatchUpsActions.Initialize());
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            DualQuizMatchUpsActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
    }

    getColor(matchUp: DualQuizChallengeMatchUpResultViewModel) {
        if (matchUp.victoryCount > matchUp.defeatCount) {
            return 'tertiary';
        } else if (matchUp.victoryCount === matchUp.defeatCount) {
            return 'warn';
        }
        return 'primary';
    }

    startChallenge(userId: number) {
        this.startChallengeDialog.open({ userId });
    }
}
