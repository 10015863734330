import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { EAvatarAssetType } from 'app/api/model/eAvatarAssetType';
import { MyBasileComponent } from 'app/shared/components/my-basile/my-basile.component';

@Component({
    selector: 'asset-type-picker',
    templateUrl: './asset-type-picker.component.html',
    standalone: true,
    imports: [
        MyBasileComponent,
        MatIconModule
    ],
})
export class AssetTypePicker {

    @Input() title: string;
    @Input() icon: string;
    @Input() assetType = EAvatarAssetType;
    @Input() selectedAssetType = EAvatarAssetType;


    get isSelected() {
        return this.assetType === this.selectedAssetType;
    }

}
