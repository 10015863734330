import { Injectable } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MatDialogState,
} from '@angular/material/dialog';
import { MissionUnregisteredViewModel } from 'app/api';
import { IDialogService } from 'app/shared/services/dialog-service';
import {
    MissionProposeSlotDialogComponent,
    MissionProposeSlotDialogInput,
} from './propose-slot/mission-propose-slot-dialog.component';
import {
    MissionRegisterSlotDialogComponent,
    MissionRegisterSlotDialogInput,
} from './register-slot/mission-register-slot-dialog.component';

@Injectable({ providedIn: 'root' })
export class MissionRegisterDialogService implements IDialogService {
    private registerSlotDialogRef: MatDialogRef<MissionRegisterSlotDialogComponent>;
    private proposeSlotDialogRef: MatDialogRef<MissionProposeSlotDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open(input: MissionUnregisteredViewModel) {
        if (!input.isDateSelectionVolunteerBased) {
            this.registerSlotDialogRef = this.dialog.open(
                MissionRegisterSlotDialogComponent,
                {
                    data: {
                        missionId: input.id,
                        missionSlots: input.missionSlots,
                    } as MissionRegisterSlotDialogInput,
                }
            );
        } else {
            this.proposeSlotDialogRef = this.dialog.open(
                MissionProposeSlotDialogComponent,
                {
                    data: {
                        missionId: input.id,
                        expirationDate: input.expirationDate,
                    } as MissionProposeSlotDialogInput,
                    maxWidth: '100vw',
                    maxHeight: '100vh',
                }
            );
        }
    }

    close() {
        if (this.registerSlotDialogRef.getState() === MatDialogState.OPEN) {
            this.registerSlotDialogRef.close();
        }
        if (this.proposeSlotDialogRef.getState() === MatDialogState.OPEN) {
            this.proposeSlotDialogRef.close();
        }
    }
}
