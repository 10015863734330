import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatNumber',
    standalone: true,
})
export class FormatNumberPipe implements PipeTransform {
    transform(value: any): string {
        let [integer, fraction] = value.toString().split('.');
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return fraction ? `${integer}.${fraction}` : integer;
    }
}
