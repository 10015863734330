<div
    (click)="open()"
    class="p-6 bg-white rounded-xl shadow-lg hover:bg-warn-200 duration-300 transition-all cursor-pointer"
>
    <div class="grid grid-cols-12 gap-4 items-center">
        <div class="col-span-4 flex flex-col justify-around gap-2 truncate">
            <span class="text-2xl font-bold text-warn truncate">{{
                campaign.name
            }}</span>
            <span class="flex items-center gap-2">
                <mat-icon
                    class="text-current"
                    [svgIcon]="
                        campaign.isOneEnvelope
                            ? 'heroicons_outline:envelope'
                            : 'feather:gift'
                    "
                ></mat-icon>
                <span>{{
                    (campaign.isOneEnvelope
                        ? "donationcampaigns.envelope"
                        : "donationcampaigns.individual-gift-donations"
                    ) | transloco
                }}</span>
            </span>
        </div>

        <div class="col-span-3 flex flex-col gap-2 text-center">
            <span class="text-4xl font-bold">{{ campaign.totalCost }}€</span>
            <span>{{ "donationcampaigns.total-cost" | transloco }}</span>
        </div>

        <div class="col-span-3 flex flex-col text-center gap-2">
            <span class="text-4xl font-bold">{{
                campaign.participantCount
            }}</span>
            <span>{{ "donationcampaigns.participants" | transloco }}</span>
        </div>

        <div class="col-span-2 flex flex-col justify-between items-end h-full">
            <bslk-menu
                (click)="$event.stopPropagation()"
                (onAction)="onMenuAction($event)"
                [icon]="'feather:more-vertical'"
                [items]="[
                            {
                                icon: 'heroicons_solid:pencil',
                                action: 'modify',
                                label: 'donationcampaigns.modify' | transloco,
                            },
                            {
                                icon: 'heroicons_solid:computer-desktop',
                                action: 'preview',
                                label: 'donationcampaigns.preview' | transloco,
                            },
                            {
                                icon: 'heroicons_solid:archive-box',
                                action: 'archive',
                                label: 'donationcampaigns.archive' | transloco,
                            },
                    ]"
            ></bslk-menu>

            <div
                [ngSwitch]="campaign.state"
                class="flex items-center justify-center"
            >
                <bslk-tag
                    [size]="'small'"
                    [icon]="'heroicons_outline:play'"
                    [background]="'tertiary'"
                    *ngSwitchCase="EDonationCampaignState.Active"
                    >{{ "donationcampaigns.active" | transloco }}</bslk-tag
                >
                <bslk-tag
                    [size]="'small'"
                    [icon]="'heroicons_outline:credit-card'"
                    [background]="'warn'"
                    *ngSwitchCase="EDonationCampaignState.WaitingForPayment"
                    >{{
                        "donationcampaigns.pending-payment" | transloco
                    }}</bslk-tag
                >
                <bslk-tag
                    [size]="'small'"
                    [icon]="'heroicons_outline:calendar'"
                    [background]="'primary'"
                    *ngSwitchCase="EDonationCampaignState.Expired"
                    >{{ "donationcampaigns.expired" | transloco }}</bslk-tag
                >
                <bslk-tag
                    [size]="'small'"
                    [icon]="'feather:star'"
                    [background]="'quaternary'"
                    *ngSwitchCase="EDonationCampaignState.Completed"
                    >{{ "donationcampaigns.completed" | transloco }}</bslk-tag
                >
            </div>
        </div>
    </div>
</div>
