import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    EAssociationLegalDocument,
    EFolderType,
    FileViewModel,
    FileViewModelPaginatedListDto,
} from 'app/api';

export const FilesActions = createActionGroup({
    source: 'Files',
    events: {
        Reset: emptyProps(),
        'Load Files Request': props<{
            folderType?: EFolderType;
            missionId?: number;
        }>(),
        'Load Files Request Success': props<{
            response: FileViewModelPaginatedListDto;
        }>(),
        'Load Files Request Fail': props<{ error: string }>(),
        'Select File': props<{ file: FileViewModel }>(),
        'Select Folder': props<{ folder: FileViewModel }>(),
        'Unselect File': emptyProps(),
        'Unselect Folder': emptyProps(),
        'Change Folder Page': props<{ pageIndex: number }>(),

        'Download Sponsorship Agreement Request': props<{
            file: FileViewModel;
        }>(),
        'Download Sponsorship Agreement Request Success': props<{
            blob: Blob;
        }>(),
        'Download Sponsorship Agreement Request Fail': props<{
            error: string;
        }>(),

        'Download Sponsorship Amendment Request': props<{
            file: FileViewModel;
        }>(),
        'Download Sponsorship Amendment Request Success': props<{
            blob: Blob;
        }>(),
        'Download Sponsorship Amendment Request Fail': props<{
            error: string;
        }>(),

        'Download Sponsorship Attendance Request': props<{
            file: FileViewModel;
        }>(),
        'Download Sponsorship Attendance Request Success': props<{
            blob: Blob;
        }>(),
        'Download Sponsorship Attendance Request Fail': props<{
            error: string;
        }>(),

        'Download Association Legal Document Request': props<{
            documentType: EAssociationLegalDocument;
        }>(),
        'Download Association Legal Document Request Success': props<{
            blob: Blob;
        }>(),
        'Download Association Legal Document Request Fail': props<{
            error: string;
        }>(),

        'Upload Association Legal Document Request': props<{
            file: File;
            id: EAssociationLegalDocument;
        }>(),
        'Upload Association Legal Document Request Success': props<{
            url: string;
        }>(),
        'Upload Association Legal Document Request  Fail': props<{
            error: string;
        }>(),

        'Download Fundraising Appeal Request': props<{ id: number }>(),
        'Download Fundraising Appeal Request Success': props<{
            blob: Blob;
        }>(),
        'Download Fundraising Appeal Request Fail': props<{
            error: string;
        }>(),

        'Download Campaign Tax Receipt Request': props<{ id: number }>(),
        'Download Campaign Tax Receipt Request Success': props<{
            blob: Blob;
        }>(),
        'Download Campaign Tax Receipt Request Fail': props<{
            error: string;
        }>(),

        'Download Subscription Tax Receipt Request': emptyProps(),
        'Download Subscription Tax Receipt Request Success': props<{
            blob: Blob;
        }>(),
        'Download Subscription Tax Receipt Request Fail': props<{
            error: string;
        }>(),
    },
});
