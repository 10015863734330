import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { CourseSectionViewModel } from 'app/api';
import { randomNumberFrom1To } from 'app/shared/utils/number.utils';
import { CourseActions } from 'app/store/course/course/course.action';
import { CourseSelectors } from 'app/store/course/course/course.selectors';
import { isEmpty, isEqual, isNil } from 'lodash-es';
import { NextChapterSectionButtonComponent } from '../../next-button/next-button.component';

@Component({
    selector: 'mcq-question-component',
    templateUrl: './mcq.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        NgIf,
        NgFor,
        NgClass,
        MatIconModule,
        LetDirective,
        NextChapterSectionButtonComponent,
    ],
})
export class SectionMcqQuestionComponent implements OnChanges {
    @Input() section: CourseSectionViewModel;

    isLastSection$ = this.store.select(
        CourseSelectors.selectIsActiveChapterSectionLastOfChapter
    );

    randomNum: number = randomNumberFrom1To(10);
    isCorrect: boolean | undefined;
    answerIndexes: number[] = [];

    get hasAnswered() {
        return !isNil(this.isCorrect);
    }

    get correctAnswer() {
        return this.section.multipleChoiceQuestion.correctAnswer
            .map((i) => this.section.multipleChoiceQuestion.answers[i - 1])
            .join(', ');
    }

    get hasNotPickedAnyAnswer() {
        return isEmpty(this.answerIndexes);
    }

    get hasMultipleGoodAnswers() {
        return this.section.multipleChoiceQuestion.correctAnswer.length > 1;
    }

    constructor(private store: Store) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.section) {
            this.isCorrect = this.section.multipleChoiceQuestion.isUserCorrect;
            this.answerIndexes = [];
            this.randomNum = randomNumberFrom1To(10);
        }
    }

    nextSection(isLastSection: boolean) {
        this.store.dispatch(CourseActions.nextSection({ isLastSection }));
    }

    toggleAnswer(index: number) {
        const adjustedIndex = index + 1; // indexes on notion start at 1 for easier comprehension for non-tech people
        if (this.answerIndexes.includes(adjustedIndex)) {
            this.answerIndexes = this.answerIndexes.filter(
                (i) => i !== adjustedIndex
            );
        } else {
            this.answerIndexes.push(adjustedIndex);
        }
    }

    isAnswerChosen(index: number) {
        return this.answerIndexes.includes(index + 1);
    }

    onAnswer() {
        if (this.hasNotPickedAnyAnswer) {
            return;
        }
        this.isCorrect = isEqual(
            [...this.answerIndexes].sort(),
            [...this.section.multipleChoiceQuestion.correctAnswer].sort()
        );
        this.store.dispatch(
            CourseActions.sendAnswerRequest({
                sectionId: this.section.id,
                isCorrect: this.isCorrect,
            })
        );
    }
}
