import { createReducer, on } from '@ngrx/store';
import { CompanyFiltersActions } from './company-filters.action';
import { CompanyFiltersState } from './company-filters.state';

export const companyFiltersInitialState: CompanyFiltersState = {
    isLoading: false,
    isLoadingUpdate: false,
};

export const companyFiltersReducer = createReducer(
    companyFiltersInitialState,

    on(CompanyFiltersActions.loadFiltersRequest, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(
        CompanyFiltersActions.loadFiltersRequestSuccess,
        (state, { filters }) => ({
            ...state,
            isLoading: false,
            filters,
        })
    ),

    on(CompanyFiltersActions.loadFiltersRequestFail, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
    })),

    on(CompanyFiltersActions.updateFiltersRequest, (state) => ({
        ...state,
        isLoadingUpdate: true,
    })),

    on(CompanyFiltersActions.updateFiltersRequestSuccess, (state) => ({
        ...state,
        isLoadingUpdate: false,
    })),

    on(CompanyFiltersActions.updateFiltersRequestFail, (state, { error }) => ({
        ...state,
        isLoadingUpdate: false,
        error,
    }))
);
