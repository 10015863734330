import { TextFieldModule } from '@angular/cdk/text-field';
import {
    AsyncPipe,
    DatePipe,
    NgClass,
    NgFor,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgTemplateOutlet,
} from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule, isNil } from '@ngneat/transloco';
import { EMissionState, MissionRegisteredViewModel } from 'app/api';
import { formatDateRange } from 'app/shared/utils/date-helpers.utils';
import { MissionStateComponent } from '../../state/mission-state.component';
import { MissionRowCardComponent } from '../mission-row-card.component';

@Component({
    selector: 'mission-registered-row-card',
    standalone: true,
    templateUrl: './mission-registered-row-card.component.html',
    imports: [
        AsyncPipe,
        DatePipe,
        MatButtonModule,
        MatChipsModule,
        MatDividerModule,
        MatExpansionModule,
        MatIconModule,
        MatTooltipModule,
        MissionRowCardComponent,
        MissionStateComponent,
        NgClass,
        NgFor,
        NgIf,
        NgSwitch,
        NgSwitchCase,
        NgTemplateOutlet,
        RouterLink,
        BslkTagComponent,
        TextFieldModule,
        TranslocoModule,
    ],
})
export class MissionRegisteredRowCardComponent {
    @Input() mission: MissionRegisteredViewModel;

    EMissionState = EMissionState;

    constructor(private datePipe: DatePipe) {}

    get isArchived() {
        return !isNil(this.mission.dateArchived);
    }

    formatDateRange(startDate: string, endDate: string): string {
        return formatDateRange(this.datePipe, startDate, endDate);
    }
}
