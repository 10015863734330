import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkColor } from '@bslk/types/bslk-types';

@Component({
    selector: 'bslk-tag',
    standalone: true,
    templateUrl: './tag.component.html',
    imports: [MatIconModule, NgIf, NgClass],
})
export class BslkTagComponent {
    @Input() fontWeight: 'normal' | 'semibold' | 'bold' = 'semibold';
    @Input() size: 'very-small' | 'small' | 'normal' | 'big' | 'very-big' =
        'normal';
    @Input() appearance: 'sharp' | 'rounded-sm' | 'rounded' | 'rounded-xl' =
        'rounded-sm';
    @Input() background?: BslkColor = 'primary';
    @Input() backgroundHexaColor?: string;
    @Input() icon: string;
    @Input() isDisabled = false;
    @Input() textColor: BslkColor = 'white';
    @Input() isRemovable = false;
}
