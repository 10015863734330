/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserViewModel } from './userViewModel';
import { ECompanyState } from './eCompanyState';
import { CompanyAgencyViewModel } from './companyAgencyViewModel';
import { LocationViewModel } from './locationViewModel';
import { EFeature } from './eFeature';
import { ELoginType } from './eLoginType';


export interface CompanyViewModel { 
    id?: number;
    dateCreated?: string;
    dateUpdated?: string | null;
    dateArchived?: string | null;
    name?: string | null;
    location?: LocationViewModel;
    loginType?: ELoginType;
    state?: ECompanyState;
    /**
     * E-mail domain of the company, for instance basilik.io, we use this for auto-account creation
     */
    domain?: string | null;
    /**
     * Maximum users for a company, if an user tries to login for the first time and its company reached maximum users,  account will not be created
     */
    maximumUsers?: number;
    /**
     * Maximum number of days each
     */
    yearlyVolunteeringDays?: number | null;
    rcsLocation?: string | null;
    rcsNumber?: string | null;
    capital?: string | null;
    companyLegalId?: string | null;
    legalForm?: string | null;
    administrativeContactId?: number | null;
    contractEndDate?: string | null;
    contractStartDate?: string | null;
    taxDeductibleSubscriptionAmount?: number | null;
    directContact?: UserViewModel;
    allowedFeatures?: Array<EFeature> | null;
    agencies?: Array<CompanyAgencyViewModel> | null;
}
export namespace CompanyViewModel {
}


