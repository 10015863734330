import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { CompanyAdminDashboardActions } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.actions';
import { CompanyAdminDashboardSelectors } from 'app/store/company/company-admin-dashboard/company-admin-dashboard.selectors';
import { AwarenessRateComponent } from './blocks/charts/awareness-rate/awareness-rate.component';
import { DualQuizAnswersBySdgComponent } from './blocks/charts/dual-quiz-answers-by-sdg/dual-quiz-answers-by-sdg.component';
import { ParticipationRateByGenderComponent } from './blocks/charts/gender-participation-repartition/gender-participation-repartition.component';
import { MissionsByGoalsComponent } from './blocks/charts/missions-by-goals/missions-by-goals.component';
import { ParticipationRateComponent } from './blocks/charts/participation-rate/participation-rate.component';
import { SatisfactionRateComponent } from './blocks/charts/satisfaction-rate/satisfaction-rate.component';
import { TrackingMyImpactComponent } from './blocks/charts/tracking-my-impact/tracking-my-impact.component';
import { AllCompanyMissionsComponent } from './blocks/panels/all-company-missions/all-company-missions.component';
import { AwarenessProgressionComponent } from './blocks/panels/awareness-progression/awareness-progression.component';
import { EmployeesOnAssignmentComponent } from './blocks/panels/employees-on-assignment/employees-on-assignment.component';
import { CompanyAdminDashboardHeaderComponent } from './header/header.component';

@Component({
    selector: 'company-admin-dashboard',
    templateUrl: './company-admin-dashboard.component.html',
    standalone: true,
    imports: [
        AllCompanyMissionsComponent,
        AsyncPipe,
        AwarenessProgressionComponent,
        AwarenessRateComponent,
        CompanyAdminDashboardHeaderComponent,
        EmployeesOnAssignmentComponent,
        LetDirective,
        MissionsByGoalsComponent,
        NgClass,
        NgIf,
        ParticipationRateByGenderComponent,
        ParticipationRateComponent,
        SatisfactionRateComponent,
        TrackingMyImpactComponent,
        DualQuizAnswersBySdgComponent,
    ],
})
export class CompanyAdminDashboardComponent implements OnInit {
    isParticipationRateExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpanded('participation-rate')
    );

    isAnyExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpandedAnyOf([
            'participation-rate',
        ])
    );

    isParticipationRateDisplayed$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiDisplayed(
            'participation-rate'
        )
    );
    isSatisfactionRateDisplayed$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiDisplayed('satisfaction-rate')
    );

    isSatisfactionRateExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpanded('satisfaction-rate')
    );

    isGenderParticipationRepartitionDisplayed$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiDisplayed(
            'gender-participation-repartition'
        )
    );
    isGenderParticipationRepartitionExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpanded(
            'gender-participation-repartition'
        )
    );
    isTrackingMyImpactDisplayed$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiDisplayed(
            'tracking-my-impact'
        )
    );
    isTrackingMyImpactExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpanded('tracking-my-impact')
    );
    isEmployeeOnAssignmentDisplayed$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiDisplayed(
            'employees-on-assignment'
        )
    );

    isEmployeeOnAssignmentExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpanded(
            'employees-on-assignment'
        )
    );

    isMissionByGoalsDisplayed$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiDisplayed('missions-by-goals')
    );

    isMissionByGoalsExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpanded('missions-by-goals')
    );

    isAllCompanyMissionsDisplayed$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiDisplayed(
            'all-company-missions'
        )
    );

    isAllCompanyMissionsExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpanded(
            'all-company-missions'
        )
    );

    isAwarenessRateExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpanded('awareness-rate')
    );
    isAwarenessRateDisplayed$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiDisplayed('awareness-rate')
    );

    isAwarenessProgressionExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpanded(
            'awareness-progression'
        )
    );
    isAwarenessProgressionDisplayed$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiDisplayed(
            'awareness-progression'
        )
    );

    isDualQuizAnswersBySdgExpanded$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiExpanded(
            'dual-quiz-answers-by-sdg'
        )
    );

    isDualQuizAnswersBySdgDisplayed$ = this.store.select(
        CompanyAdminDashboardSelectors.selectIsKpiDisplayed(
            'dual-quiz-answers-by-sdg'
        )
    );

    constructor(private store: Store) {}

    ngOnInit() {
        this.store.dispatch(CompanyAdminDashboardActions.initDashboard());
    }
}
