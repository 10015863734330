/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SdgDonationCampaignKpi } from './sdgDonationCampaignKpi';


export interface ESustainableDevelopmentGoalSdgDonationCampaignKpiEnumChart { 
    startDate?: string | null;
    endDate?: string | null;
    data?: { [key: string]: SdgDonationCampaignKpi; } | null;
    readonly hasData?: boolean;
}

