import { NgIf } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormArray, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { CompanyCsrApproachFormService } from 'app/shared/services/form/company-csr-approach.service';
import { CompanyCsrApproachActions } from 'app/store/company/csr-approach/csr-approach.actions';
import { isNil } from 'lodash-es';

@Component({
    selector: 'company-csr-approach-edit-date',
    templateUrl: './edit.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        BslkLengthInfoComponent,
        FuseDrawerComponent,
        NgIf,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatDatepickerModule,
        BslkClassicButtonComponent,
        MatIconModule,
    ],
})
export class CompanyCsrApproachEditDateComponent implements OnChanges {
    @ViewChild('editDateDrawer') drawer: FuseDrawerComponent;

    @Input() isCreating: boolean = false;
    @Input() editedDateIndex: number;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private formService: CompanyCsrApproachFormService,
        private store: Store
    ) {}

    get formArray() {
        return this.formService.getFormGroup.controls
            .timelineDates as FormArray;
    }

    get formGroup() {
        // Return edited date form group, or last form group if is creating
        return this.formArray.controls.at(
            this.editedDateIndex ?? this.formArray.controls.length - 1
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            !isNil(changes.editedDateIndex?.currentValue) ||
            changes.isCreating?.currentValue
        ) {
            this.drawer.open();
        }

        if (changes.isCreating?.currentValue) {
            this.formArray.controls.push(this.formService.createTimelineDate());
        }
    }

    openedChanged(isOpened: boolean) {
        if (!isOpened) {
            if (this.isCreating) {
                // Remove non-validated created form array
                this.formArray.controls.pop();
            }

            this.close.emit();
            this.isCreating = false;
            this.editedDateIndex = null;
        }
    }

    update() {
        this.store.dispatch(
            CompanyCsrApproachActions.updateCsrApproachRequest()
        );
        this.isCreating = false;
        this.editedDateIndex = null;
        this.drawer.close();
    }
}
