import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    CourseQuestionUserAnswerService,
    CourseService,
    CourseSurveyAnswerService,
} from 'app/api';
import { CourseReviewDialogService } from 'app/modules/regular/awareness/courses/course-review/course-rating-dialog/course-rating-dialog.service';
import { ERegularUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { AlertActions } from 'app/store/alert/alert.actions';
import { catchError, exhaustMap, filter, map, of, tap } from 'rxjs';
import { CourseActions } from './course.action';
import { CourseSelectors } from './course.selectors';

@Injectable()
export class CourseEffects {
    loadChapter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CourseActions.loadChapterRequest),
            exhaustMap(({ chapterId }) => {
                return this.courseService
                    .courseChapterChapterIdGet({
                        chapterId,
                    })
                    .pipe(
                        map((chapter) =>
                            CourseActions.loadChapterRequestSuccess({
                                chapter,
                            })
                        ),
                        catchError((httpResponse) =>
                            of(
                                CourseActions.loadChapterRequestFail({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    updateChapterProgression$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CourseActions.nextSection),
            concatLatestFrom(() => [
                this.store.select(CourseSelectors.selectActiveChapter),
                this.store.select(
                    CourseSelectors.selectActiveChapterSectionIndex
                ),
            ]),
            exhaustMap(([{ isLastSection }, chapter, currentSectionId]) => {
                // If last section, we have not advanced to next section so we take current one (last), else the one we just validated
                const previousSection = chapter.sections.at(
                    isLastSection ? currentSectionId : currentSectionId - 1
                );

                return this.courseService
                    .courseUpdateChapterCompletionPut({
                        courseSectionUserCompletionViewModel: {
                            chapterId: chapter.id,
                            lastSectionReadIndex:
                                chapter.sections.indexOf(previousSection),
                            lastReadSectionId: previousSection.id,
                            totalSections: chapter.sections.length,
                            isLastChapter: chapter.isLastChapter,
                            courseId: chapter.courseId,
                            thematic: chapter.thematic,
                            chapterIndex: chapter.index,
                        },
                    })
                    .pipe(
                        map((badges) => {
                            if (isLastSection && !chapter.isLastChapter) {
                                this.router.navigateByUrl(
                                    UrlHelpers.getRegularUrl(
                                        ERegularUrl.RegularCoursesPage,
                                        { id: chapter.courseId }
                                    )
                                );
                            }
                            return CourseActions.updateChapterCompletionRequestSuccess(
                                { badges }
                            );
                        }),
                        catchError((httpResponse) =>
                            of(
                                CourseActions.updateChapterCompletionRequestFail(
                                    {
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    sendUserAnswer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CourseActions.sendAnswerRequest),
            concatLatestFrom(() => [
                this.store.select(CourseSelectors.selectActiveChapter),
            ]),
            exhaustMap(([{ isCorrect, sectionId }, chapter]) => {
                return this.courseQuestionUserAnswerService
                    .courseQuestionUserAnswerPost({
                        courseQuestionUserAnswerViewModel: {
                            chapterId: chapter.id,
                            isCorrect,
                            sectionId,
                            courseId: chapter.courseId,
                        },
                    })
                    .pipe(
                        map((badges) =>
                            CourseActions.sendAnswerRequestSuccess({ badges })
                        ),
                        catchError((httpResponse) =>
                            of(
                                CourseActions.sendAnswerRequestFail({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    sendSurveyGuess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CourseActions.sendSurveyAnswerRequest),
            concatLatestFrom(() => [
                this.store.select(CourseSelectors.selectActiveChapter),
            ]),
            exhaustMap(
                ([{ sectionId, answer, hasFoundAllAnswers }, chapter]) => {
                    return this.courseSurveyAnswerService
                        .courseSurveyAnswerPost({
                            courseSurveyAnswerViewModel: {
                                chapterId: chapter.id,
                                sectionId,
                                answer,
                                hasFoundAllAnswers,
                            },
                        })
                        .pipe(
                            map((badges) =>
                                CourseActions.sendSurveyAnswerRequestSuccess({
                                    badges,
                                })
                            ),
                            catchError((httpResponse) =>
                                of(
                                    CourseActions.sendSurveyAnswerRequestFail({
                                        error:
                                            httpResponse?.error ??
                                            httpResponse.toString(),
                                    })
                                )
                            )
                        );
                }
            )
        )
    );

    redirectToCourseReviewAtEndOfCourse$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CourseActions.nextSection),
                concatLatestFrom(() =>
                    this.store.select(CourseSelectors.selectActiveChapter)
                ),
                filter(
                    ([{ isLastSection }, chapter]) =>
                        isLastSection && chapter.isLastChapter
                ),
                tap(([, chapter]) => {
                    this.router.navigateByUrl(
                        UrlHelpers.getRegularUrl(
                            ERegularUrl.RegularCourseUserReviewPage,
                            { id: chapter.courseId }
                        )
                    );
                })
            ),
        { dispatch: false }
    );

    loadReview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CourseActions.loadCourseReviewRequest),
            exhaustMap(({ courseId }) => {
                return this.courseService
                    .courseUserReviewCourseIdGet({
                        courseId,
                    })
                    .pipe(
                        map((review) =>
                            CourseActions.loadCourseReviewRequestSuccess({
                                review,
                            })
                        ),
                        catchError((httpResponse) =>
                            of(
                                CourseActions.loadCourseReviewRequestFail({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    openCourseRatingDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(CourseActions.loadCourseReviewRequestSuccess),
                filter(({ review }) => !review.hasLeftRating),
                tap(() => {
                    this.courseRatingDialogService.open();
                })
            ),
        { dispatch: false }
    );

    sendRating$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CourseActions.sendCourseRatingRequest),
            concatLatestFrom(() =>
                this.store.select(CourseSelectors.selectReview)
            ),
            filter(([, review]) => !review.hasLeftRating),
            exhaustMap(([{ rating, comment }, review]) => {
                const courseRatingViewModel = {
                    rating,
                    comment,
                    courseId: review.courseId,
                };
                return this.courseService
                    .courseRatingPost({
                        courseRatingViewModel,
                    })
                    .pipe(
                        map(() =>
                            CourseActions.sendCourseRatingRequestSuccess()
                        ),
                        catchError((httpResponse) =>
                            of(
                                CourseActions.sendCourseRatingRequestFail({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    closeDialogOnRatingSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CourseActions.sendCourseRatingRequestSuccess),
            tap(() => {
                this.courseRatingDialogService.close();
            }),
            map(() => AlertActions.displaySuccess({ key: 'course-rating' }))
        )
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private router: Router,
        private courseService: CourseService,
        private courseQuestionUserAnswerService: CourseQuestionUserAnswerService,
        private courseSurveyAnswerService: CourseSurveyAnswerService,
        private courseRatingDialogService: CourseReviewDialogService
    ) {}
}
