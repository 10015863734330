import { createSelector } from '@ngrx/store';
import { ProjectViewModel } from 'app/api';
import { MediaLibraryItem } from 'app/shared/components/media-library/media-library-dialog.component';
import { AppState } from '../..';
import {
    RessourceListSelectors,
    createRessourceListSelectors,
} from '../../shared/ressource-list/ressource-list-selectors.creator';

const selectState = (state: AppState) => state.projectList;

const selectRegions = createSelector(selectState, (state) => state.regions);

const selectIsLoadingRegions = createSelector(
    selectState,
    (state) => state.isLoadingRegions
);

const selectHasRegions = createSelector(
    selectState,
    (state) => state.regions.length > 0
);

const selectIsLoadingImages = createSelector(
    selectState,
    (state) => state.isLoadingImages
);

const selectProjectImages = (id: number) =>
    createSelector(
        selectState,
        (state) =>
            state.data
                .find((project) => project.id === id)
                ?.images.map((i) => ({ id: i.id, url: i.url })) || []
    );

interface AdditionalSelectors {
    selectRegions: (state: any) => string[];
    selectIsLoadingRegions: (state: any) => boolean;
    selectHasRegions: (state: any) => boolean;
    selectIsLoadingImages: (state: any) => boolean;
    selectProjectImages: (id: number) => (state: any) => MediaLibraryItem[];
}

type ProjectListSelectors = RessourceListSelectors<ProjectViewModel> &
    AdditionalSelectors;

export const ProjectListSelectors: ProjectListSelectors = {
    ...createRessourceListSelectors('projectList'),
    selectRegions,
    selectIsLoadingRegions,
    selectHasRegions,
    selectIsLoadingImages,
    selectProjectImages,
};
