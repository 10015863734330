<div
    *ngIf="!(isLoading$ | async); else loading"
    class="flex flex-col w-full p-3 xl:p-4 xxl:p-6 pb-6"
>
    <div class="grid grid-cols-12 gap-6">
        <chapter-progress-bar
            [activeSectionIndex]="activeSectionIndex$ | async"
            [sectionsCount]="sectionsCount$ | async"
            class="col-span-12 md:col-span-9"
        ></chapter-progress-bar>

        <div class="md:col-span-3 hidden md:flex justify-around gap-2">
            <chapter-gain
                [selector]="experienceGained$"
                [icon]="'basile-flat'"
                [color]="'tertiary'"
                class="flex"
            ></chapter-gain>
            <chapter-gain
                [selector]="purposeCoinsGained$"
                [icon]="'bslk_core:purpose-coins'"
                class="flex"
                [color]="'warn'"
            ></chapter-gain>
        </div>
    </div>

    <div class="grid grid-cols-12 gap-6">
        <div class="hidden md:flex md:col-span-3">
            <chapter-basile> </chapter-basile>
        </div>

        <div class="col-span-12 md:col-span-6">
            <chapter-card
                [activeSectionIndex]="activeSectionIndex$ | async"
                [sectionsCount]="sectionsCount$ | async"
            ></chapter-card>
        </div>

        <div class="col-span-12 md:col-span-3">
            <chapter-section-preview-comments></chapter-section-preview-comments>
        </div>
    </div>
</div>

<ng-template #loading>
    <course-loading class="w-full"></course-loading>
</ng-template>
