import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { DualQuizChallengesActions } from 'app/store/dual-quiz/challenges/challenges.action';
import { DualQuizChallengesSelectors } from 'app/store/dual-quiz/challenges/challenges.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NewChallengeDialogService } from '../header/new-challenge-dialog/new-challenge-dialog.service';
import { DualQuizChallengeCardComponent } from './card/card.component';

@Component({
    selector: 'dual-quiz-challenges',
    templateUrl: './challenges.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        DualQuizChallengeCardComponent,
        LetDirective,
        MatIconModule,
        MatPaginatorModule,
        MatButtonModule,
        NgFor,
        NgFor,
        NgIf,
        NgxSkeletonLoaderModule,
        TranslocoModule,
    ],
})
export class DualQuizChallengesComponent implements OnInit {
    isLoading$ = this.store.select(DualQuizChallengesSelectors.selectIsLoading);
    challenges$ = this.store.select(DualQuizChallengesSelectors.selectData);
    pageIndex$ = this.store.select(DualQuizChallengesSelectors.selectPageIndex);
    totalPages$ = this.store.select(
        DualQuizChallengesSelectors.selectTotalPages
    );
    totalCount$ = this.store.select(
        DualQuizChallengesSelectors.selectTotalCount
    );
    pageSize$ = this.store.select(DualQuizChallengesSelectors.selectPageSize);

    constructor(
        private store: Store,
        private newChallengeDialogService: NewChallengeDialogService
    ) {}

    ngOnInit() {
        this.store.dispatch(DualQuizChallengesActions.Initialize());
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            DualQuizChallengesActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
    }

    openNewChallengeDialog() {
        this.newChallengeDialogService.open();
    }
}
