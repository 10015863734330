<button
    class="fixed z-50 ring ring-white rounded-full bg-tertiary h-16 w-16 bottom-5 right-5 p-3 flex items-center justify-center hover:bg-warn text-white"
    (click)="openPanel()"
    #feedbackOrigin
>
    <mat-icon
        class="w-full h-full flex fill-current text-current"
        [svgIcon]="'heroicons_outline:megaphone'"
    ></mat-icon>
</button>

<!-- Feedback panel -->
<ng-template #feedbackPanel>
    <div
        *ngrxLet="isFeedbackSent$ as isFeedbackSent"
        @slideInBottom
        @slideOutBottom
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg"
    >
        <!-- Header -->
        <div
            class="flex justify-between items-center p-2 pl-4 bg-tertiary text-white"
        >
            <div class="text-lg font-medium leading-10 text-white">
                {{ "shared.feedback.something-to-signal" | transloco }}
            </div>
            <button mat-icon-button (click)="closePanel()">
                <mat-icon
                    class="icon-size-5 text-current"
                    [svgIcon]="'heroicons_solid:x-mark'"
                ></mat-icon>
            </button>
        </div>

        <!-- Content -->
        <div
            *ngIf="showPickFeedbackType"
            class="relative flex flex-col flex-auto divide-y bg-card text-lg font-semibold"
        >
            <div
                (click)="chooseFeedbackType(EFeedbackType.BugReport)"
                class="flex items-center p-3 hover:bg-tertiary-100 cursor-pointer"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:bug-ant'"
                ></mat-icon>
                <span class="ml-3">{{
                    "shared.feedback.bug" | transloco
                }}</span>
            </div>
            <div
                (click)="
                    chooseFeedbackType(EFeedbackType.ImprovementSuggestion)
                "
                class="flex items-center p-3 hover:bg-tertiary-100 cursor-pointer"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:archive-box'"
                ></mat-icon>
                <span class="ml-3">{{
                    "shared.feedback.improvementSuggestion" | transloco
                }}</span>
            </div>
            <div
                (click)="chooseFeedbackType(EFeedbackType.Question)"
                class="flex items-center p-3 hover:bg-tertiary-100 cursor-pointer"
            >
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:question-mark-circle'"
                ></mat-icon>
                <span class="ml-3">{{
                    "shared.feedback.question" | transloco
                }}</span>
            </div>
        </div>

        <div *ngIf="!showPickFeedbackType && !isFeedbackSent">
            <form
                class="relative p-6 bg-card flex flex-col sm:max-h-120"
                [formGroup]="formGroup"
            >
                <mat-form-field
                    appearance="outline"
                    floatLabel="always"
                    class="w-full"
                >
                    <mat-label>{{
                        "shared.feedback." + feedbackType + ".label" | transloco
                    }}</mat-label>
                    <textarea
                        class="h-20"
                        matInput
                        [formControlName]="'text'"
                        required
                        [placeholder]="
                            'shared.feedback.' + feedbackType + '.description'
                                | transloco
                        "
                    >
                    </textarea>
                    <mat-error>
                        {{ "bslk.form.required" | transloco }}
                    </mat-error>
                </mat-form-field>

                <div
                    *ngIf="!isScreenshotAdded"
                    class="flex flex-col gap-1 mb-5"
                >
                    <span class="text-sm">{{
                        "shared.feedback.add-screenshot-details" | transloco
                    }}</span>
                    <bslk-classic-button
                        class="flex justify-center"
                        [background]="'tertiary'"
                        *ngIf="feedbackType === EFeedbackType.BugReport"
                        (onClick)="openFileUploadDialog()"
                        [icon]="'heroicons_outline:photo'"
                        >{{
                            "shared.feedback.add-screenshot" | transloco
                        }}</bslk-classic-button
                    >
                </div>

                <div
                    *ngIf="isScreenshotAdded"
                    class="flex items-center gap-1 mb-5"
                >
                    <mat-icon
                        class="text-current"
                        [svgIcon]="'heroicons_outline:check'"
                    ></mat-icon>
                    <span>{{
                        "shared.feedback.screenshot-added" | transloco
                    }}</span>
                </div>

                <bslk-star-rating
                    [label]="'shared.feedback.rate-your-experience' | transloco"
                    [control]="formGroup.controls.rating"
                ></bslk-star-rating>

                <div class="flex mt-5 justify-end">
                    <bslk-classic-button
                        (onClick)="send()"
                        [background]="'tertiary'"
                        [isLoading]="isLoading$ | async"
                        [isDisabled]="formGroup.invalid && submitted"
                        [label]="'shared.feedback.send' | transloco"
                        [type]="'submit'"
                    ></bslk-classic-button>
                </div>
            </form>
        </div>

        <div class="p-4 bg-card" *ngIf="isFeedbackSent">
            <fuse-alert
                class="flex"
                [appearance]="'outline'"
                [type]="'success'"
                >{{
                    "shared.feedback." + feedbackType + ".thanks-message"
                        | transloco
                }}</fuse-alert
            >
        </div>
    </div>
</ng-template>
